import React from "react";
import NumberInput from "../../../../common/NumberInput";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

class Assolement extends React.Component {
  render() {
    const {
      indicateur,
      sousRubriqueIndex,
      libelleEnglobantIndex,
      changeIndicateur,
      valueInputProps,
      computedFields,
      getValueAssolement,
      displayRules,
    } = this.props;

    const isColumnDisplay = displayRules?.displayAssolement === "column";
    const isAssolementOnly = displayRules?.displayAssolement === "assolementOnly" ? true : false;

    const valueRendered = (
      <React.Fragment>
        <NumberInput
          size={this.props.size}
          disabled
          fullWidth={false}
          style={{ width: "8rem" }}
          label={"Valeur assolement"}
          value={getValueAssolement(indicateur?.codeNational) ?? ""}
          InputProps={valueInputProps}
        />
        {!isAssolementOnly && (
          <NumberInput
            helperText={this.props.helperText}
            error={this.props.error}
            className={isColumnDisplay ? "mt-1" : "ml-2"}
            fullWidth={false}
            style={{ width: "8rem" }}
            size={this.props.size}
            value={indicateur?.valeur.value ?? ""}
            InputLabelProps={{
              style: { fontWeight: indicateur?.afficherGras ? "bold" : "normal" },
            }}
            InputProps={valueInputProps}
            disabled={computedFields.includes(indicateur?.codeNational)}
            onChange={(newValeur) =>
              changeIndicateur(
                { ...indicateur, valeur: { value: newValeur === "" ? null : Number(newValeur) } },
                sousRubriqueIndex,
                libelleEnglobantIndex
              )
            }
          />
        )}
      </React.Fragment>
    );

    return <div className={isColumnDisplay ? "d-flex flex-column align-items-center" : ""}>{valueRendered}</div>;
  }
}

export default compose(withTranslation())(Assolement);
