/**
 * returns true if the input string represents a number (decimal or not)
 * @param {string} string the string to evaluate
 */

export const isNumber = (string) => ("" + string).test(/^\d*(.\d*)?$/g);

export const hasDecimals = (string, numberOfDecimals) =>
  isNumber(string) &&
  ("" + string).substring(("" + string).lastIndexOf(".") + 1, ("" + string).length).length <= numberOfDecimals;

export const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);

export const checkPassword = (password) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z\d\s]).{8,}/.test(password);

const exports = { isNumber, hasDecimals, isEmail, checkPassword };
export default exports;
