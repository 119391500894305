export const COMPUTED_FIELDS_FERTILISATION = {
  // RUBRIQUE FERTILISATION
  HVE4_FERTI_I_40: {
    columns: ["HVE4_FERTI_D_40", "HVE4_FERTI_D_400"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_40.columns[1]) > 0
              ? 100 *
                (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_40.columns[0]) /
                  getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_40.columns[1]))
              : 0,
        },
      };
    },
  },
  HVE4_FERTI_D_76: {
    columns: ["HVE4_PHYTO_D_90"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: getValueOrZero(state, "HVE4_PHYTO_D_90"),
      },
    }),
  },
  HVE4_FERTI_D_77: {
    columns: ["HVE4_PHYTO_D_91"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: getValueOrZero(state, "HVE4_PHYTO_D_91"),
      },
    }),
  },
  HVE4_FERTI_D_78: {
    columns: ["HVE4_PHYTO_D_92"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: getValueOrZero(state, "HVE4_PHYTO_D_92"),
      },
    }),
  },
  HVE4_FERTI_I_41: {
    columns: ["HVE4_FERTI_D_41", "HVE4_FERTI_D_400"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_41.columns[1]) > 0
            ? 100 *
              (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_41.columns[0]) /
                getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_41.columns[1]))
            : 0,
      },
    }),
  },
  HVE4_FERTI_I_42: {
    columns: ["HVE4_FERTI_D_42", "HVE4_FERTI_D_400"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_42.columns[1]) > 0
            ? 100 *
              (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_42.columns[0]) /
                getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_42.columns[1]))
            : 0,
      },
    }),
  },
  HVE4_FERTI_I_43: {
    columns: ["HVE4_FERTI_D_43", "HVE4_FERTI_D_48"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_43.columns[1]) > 0
            ? 100 *
              (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_43.columns[0]) /
                getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_43.columns[1]))
            : 0,
      },
    }),
  },
  HVE4_FERTI_D_48: {
    columns: ["HVE4_CULT_D_1", "HVE4_CULT_D_2"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_48.columns[0]) +
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_48.columns[1]),
      },
    }),
  },
  HVE4_FERTI_D_49: {
    columns: ["HVE4_FERTI_D_48", "HVE4_FERTI_D_400"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_49.columns[1]) > 0
            ? 100 *
              (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_49.columns[0]) /
                getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_49.columns[1]))
            : 0,
      },
    }),
  },
  HVE4_FERTI_S_430: {
    columns: ["HVE4_FERTI_S_43", "HVE4_FERTI_D_48", "HVE4_FERTI_D_400"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_430.columns[2]) > 0
            ? getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_430.columns[0]) *
              (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_430.columns[1]) /
                getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_430.columns[2]))
            : 0,
      },
    }),
  },
  HVE4_FERTI_D_45: {
    columns: ["HVE4_FERTI_D_45_1", "HVE4_FERTI_D_45_2"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_45.columns[0]) +
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_45.columns[1]),
      },
    }),
  },
  HVE4_FERTI_I_44: {
    columns: ["HVE4_FERTI_D_44", "HVE4_FERTI_D_46"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_44.columns[1]) > 0
              ? 100 *
                (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_44.columns[0]) /
                  getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_44.columns[1]))
              : 0,
        },
      };
    },
  },
  HVE4_FERTI_I_45: {
    columns: ["HVE4_FERTI_D_46", "HVE4_FERTI_D_45_1", "HVE4_FERTI_D_45_2"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, "HVE4_FERTI_D_46") > 0
            ? (100 * (getValueOrZero(state, "HVE4_FERTI_D_45_1") + getValueOrZero(state, "HVE4_FERTI_D_45_2"))) /
              getValueOrZero(state, "HVE4_FERTI_D_46")
            : 0,
      },
    }),
  },
  HVE4_FERTI_S_400: {
    columns: [
      "HVE4_FERTI_S_40",
      "HVE4_FERTI_S_41",
      "HVE4_FERTI_S_42",
      "HVE4_FERTI_S_430",
      "HVE4_FERTI_S_44",
      "HVE4_FERTI_S_45",
    ],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: Math.min(
          COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_400.columns.reduce(
            (sum, codeNational) => sum + getValueOrZero(state, codeNational),
            0
          ),
          3
        ),
      },
    }),
  },
  HVE4_FERTI_I_46: {
    columns: ["HVE4_FERTI_D_46_1", "HVE4_FERTI_D_105"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_46.columns[1]) > 0
              ? 100 *
                (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_46.columns[0]) /
                  getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_46.columns[1]))
              : 0,
        },
      };
    },
  },
  HVE4_FERTI_I_47: {
    columns: ["HVE4_FERTI_D_47", "HVE4_FERTI_D_105"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_47.columns[1]) > 0
            ? 100 *
              (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_47.columns[0]) /
                getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_47.columns[1]))
            : 0,
      },
    }),
  },
  HVE4_FERTI_S_401: {
    columns: ["HVE4_FERTI_S_46", "HVE4_FERTI_S_47"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_401.columns[0]) +
            getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_401.columns[1]),
        },
      };
    },
  },
  HVE4_FERTI_S_496: {
    columns: ["HVE4_FERTI_D_1050", "HVE4_FERTI_S_400"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_496.columns[0]) < 5
            ? getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_496.columns[1])
            : (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_496.columns[1]) *
                (100 - getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_496.columns[0]))) /
              100,
      },
    }),
  },
  HVE4_FERTI_S_497: {
    columns: ["HVE4_FERTI_D_1050", "HVE4_FERTI_S_401"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_497.columns[0]) < 5
              ? 0
              : (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_497.columns[1]) *
                  getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_497.columns[0])) /
                100,
        },
      };
    },
  },
  HVE4_FERTI_S_402: {
    columns: ["HVE4_FERTI_S_496", "HVE4_FERTI_S_497"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: roundFourDecimales(
            getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_402.columns[1]) +
              getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_402.columns[0])
          ),
        },
      };
    },
  },
  HVE4_FERTI_S_40: {
    columns: ["HVE4_FERTI_I_40"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_40.columns[0]) > 0 ? 1 : 0,
      },
    }),
  },
  HVE4_FERTI_S_41: {
    columns: ["HVE4_FERTI_I_40", "HVE4_FERTI_I_41"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_41.columns[0]) > 0 &&
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_41.columns[1]) >= 50
            ? 1
            : 0,
      },
    }),
  },
  HVE4_FERTI_S_42: {
    columns: ["HVE4_FERTI_I_40", "HVE4_FERTI_I_42"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_42.columns[0]) > 0 &&
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_42.columns[1]) >= 50
            ? 1
            : 0,
      },
    }),
  },
  HVE4_FERTI_S_43: {
    columns: ["HVE4_FERTI_I_43"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_43.columns[0]) > 0 ? 3 : 0,
      },
    }),
  },
  HVE4_FERTI_S_44: {
    columns: ["HVE4_FERTI_I_44", "HVE4_FERTI_D_44", "HVE4_FERTI_D_46"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_44.columns[2]) > 0
              ? getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_44.columns[0]) >= 50
                ? 1.5 *
                  (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_44.columns[1]) /
                    getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_44.columns[2]))
                : 0
              : 0,
        },
      };
    },
  },
  HVE4_FERTI_S_45: {
    columns: ["HVE4_FERTI_I_45", "HVE4_FERTI_D_45", "HVE4_FERTI_D_46"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_45.columns[2]) > 0
            ? getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_45.columns[0]) >= 50
              ? 1.5 *
                (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_45.columns[1]) /
                  getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_45.columns[2]))
              : 0
            : 0,
      },
    }),
  },
  HVE4_FERTI_S_46: {
    columns: ["HVE4_FERTI_I_46"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_46.columns[0]) <= 30
            ? 0
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_46.columns[0]) <= 40
            ? 1
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_46.columns[0]) <= 50
            ? 2
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_46.columns[0]) <= 60
            ? 3
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_46.columns[0]) <= 70
            ? 4
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_46.columns[0]) <= 80
            ? 5
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_46.columns[0]) <= 90
            ? 6
            : 7,
      },
    }),
  },
  HVE4_FERTI_S_47: {
    columns: ["HVE4_FERTI_I_47"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_47.columns[0]) >= 50 ? 1 : 0,
      },
    }),
  },
  HVE4_FERTI_D_32: {
    columns: ["HVE4_FERTI_D_30", "HVE4_FERTI_D_31"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_32.columns[0]) +
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_32.columns[1]),
      },
    }),
  },
  HVE4_FERTI_I_30: {
    columns: ["HVE4_FERTI_D_31", "HVE4_FERTI_D_32"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_30.columns[1]) > 0
            ? (100 * getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_30.columns[0])) /
              getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_30.columns[1])
            : 0,
      },
    }),
  },
  HVE4_FERTI_S_30: {
    columns: ["HVE4_FERTI_I_30"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_30.columns[0]) < 25
              ? 0
              : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_30.columns[0]) < 35
              ? 1
              : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_30.columns[0]) < 45
              ? 2
              : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_30.columns[0]) < 55
              ? 3
              : 4,
        },
      };
    },
  },
  HVE4_FERTI_D_26: {
    columns: ["HVE4_CULT_D_70", "HVE4_CULT_D_200"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_26.columns[1]) > 0
            ? 100 *
              (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_26.columns[0]) /
                getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_26.columns[1]))
            : 0,
      },
    }),
  },
  HVE4_FERTI_S_26: {
    columns: ["HVE4_FERTI_S_25", "HVE4_FERTI_D_26"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: roundFourDecimales(
          (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_26.columns[0]) *
            getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_26.columns[1])) /
            100
        ),
      },
    }),
  },
  HVE4_FERTI_D_1050: {
    columns: ["HVE4_FERTI_D_105", "HVE4_CULT_D_200"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_1050.columns[1]) > 0
            ? 100 *
              (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_1050.columns[0]) /
                getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_1050.columns[1]))
            : 0,
      },
    }),
  },
  HVE4_FERTI_D_1050_retenue: {
    columns: ["HVE4_FERTI_D_1050"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_1050_retenue.columns[0]) <= 5 ||
            100 - getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_1050_retenue.columns[0]) < 0
              ? 100
              : 100 - getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_1050_retenue.columns[0]),
        },
      };
    },
  },
  HVE4_FERTI_D_1051: {
    columns: ["HVE4_FERTI_D_102", "HVE4_FERTI_D_1050"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: getBoolOrFalse(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_1051.columns[0])
          ? false
          : !(getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_1051.columns[1]) <= 5),
      },
    }),
  },
  HVE4_FERTI_D_160: {
    columns: ["HVE4_FERTI_D_101"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getTextOrEmptyString(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_160.columns[0]) !== "autre" &&
          getTextOrEmptyString(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_160.columns[0]) !== "",
      },
    }),
  },
  HVE4_FERTI_I_1: {
    columns: ["HVE4_FERTI_D_103", "HVE4_FERTI_D_104", "HVE4_FERTI_D_121", "HVE4_FERTI_D_160", "HVE4_FERTI_D_159"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getTextOrEmptyString(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_1.columns[0]) ===
          "Balance Globale Azotée"
            ? getTextOrEmptyString(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_1.columns[1]) === "Année Courante"
              ? Math.max(
                  state.indicateurs[COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_1.columns[2]]?.valeur?.value?.n,
                  0
                )
              : getTextOrEmptyString(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_1.columns[1]) === "Biannuel"
              ? (state.indicateurs[COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_1.columns[2]]?.valeur?.value?.n +
                  Math.max(
                    state.indicateurs[COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_1.columns[2]]?.valeur?.value?.n_1
                  ) /
                    2,
                0)
              : getTextOrEmptyString(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_1.columns[1]) === "Triannuel"
              ? Math.max(
                  (state.indicateurs[COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_1.columns[2]]?.valeur?.value?.n +
                    state.indicateurs[COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_1.columns[2]]?.valeur?.value?.n_1 +
                    state.indicateurs[COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_1.columns[2]]?.valeur?.value?.n_2) /
                    3,
                  0
                )
              : null
            : getTextOrEmptyString(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_1.columns[0]) ===
                "Bilan Apparent" && getBoolOrFalse(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_1.columns[3])
            ? getTextOrEmptyString(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_1.columns[1]) === "Année Courante"
              ? Math.max(
                  state.indicateurs[COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_1.columns[4]]?.valeur?.value?.n,
                  0
                )
              : getTextOrEmptyString(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_1.columns[1]) === "Biannuel"
              ? Math.max(
                  (state.indicateurs[COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_1.columns[4]]?.valeur?.value?.n +
                    state.indicateurs[COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_1.columns[4]]?.valeur?.value?.n_1) /
                    2,
                  0
                )
              : getTextOrEmptyString(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_1.columns[1]) === "Triannuel"
              ? Math.max(
                  (state.indicateurs[COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_1.columns[4]]?.valeur?.value?.n +
                    state.indicateurs[COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_1.columns[4]]?.valeur?.value?.n_1 +
                    state.indicateurs[COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_1.columns[4]]?.valeur?.value?.n_2) /
                    3,
                  0
                )
              : null
            : null,
      },
    }),
  },
  HVE4_FERTI_S_1: {
    columns: ["HVE4_FERTI_I_1"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: roundFourDecimales(
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_1.columns[0]) <= 20
            ? 8
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_1.columns[0]) <= 30
            ? 7
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_1.columns[0]) <= 40
            ? 5
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_1.columns[0]) <= 50
            ? 2
            : 0
        ),
      },
    }),
  },
  HVE4_FERTI_S_11: {
    columns: ["HVE4_FERTI_D_1050_retenue", "HVE4_FERTI_S_1"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: roundFourDecimales(
          (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_11.columns[0]) *
            getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_11.columns[1])) /
            100
        ),
      },
    }),
  },
  HVE4_FERTI_I_2: {
    columns: ["HVE4_FERTI_D_103", "HVE4_FERTI_D_104", "HVE4_FERTI_D_159", "HVE4_FERTI_D_160"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getTextOrEmptyString(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_2.columns[0]) === "Bilan Apparent" &&
            getBoolOrFalse(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_2.columns[3])
              ? getTextOrEmptyString(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_2.columns[1]) ===
                "Année Courante"
                ? state.indicateurs[COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_2.columns[2]]?.valeur?.value?.n
                : getTextOrEmptyString(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_2.columns[1]) === "Biannuel"
                ? (state.indicateurs[COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_2.columns[2]]?.valeur?.value?.n +
                    state.indicateurs[COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_2.columns[2]]?.valeur?.value?.n_1) /
                  2
                : getTextOrEmptyString(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_2.columns[1]) === "Triannuel"
                ? (state.indicateurs[COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_2.columns[2]]?.valeur?.value?.n +
                    state.indicateurs[COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_2.columns[2]]?.valeur?.value?.n_1 +
                    state.indicateurs[COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_2.columns[2]]?.valeur?.value?.n_2) /
                  3
                : null
              : null,
        },
      };
    },
  },
  HVE4_FERTI_S_2: {
    columns: ["HVE4_FERTI_I_2"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: roundFourDecimales(
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_2.columns[0]) <= 30
            ? 8
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_2.columns[0]) <= 50
            ? 6
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_2.columns[0]) <= 70
            ? 4
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_2.columns[0]) <= 90
            ? 2
            : 0
        ),
      },
    }),
  },
  HVE4_FERTI_S_12: {
    columns: ["HVE4_FERTI_D_1050_retenue", "HVE4_FERTI_S_2"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: roundFourDecimales(
          (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_12.columns[0]) *
            getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_12.columns[1])) /
            100
        ),
      },
    }),
  },
  HVE4_FERTI_D_66: {
    columns: [
      "HVE4_FERTI_D_60",
      "HVE4_FERTI_D_61",
      "HVE4_FERTI_D_62",
      "HVE4_FERTI_D_63",
      "HVE4_FERTI_D_64",
      "HVE4_FERTI_D_65",
    ],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_66.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE4_FERTI_I_60: {
    columns: ["HVE4_FERTI_D_66", "HVE4_CULT_D_200"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_60.columns[1]) > 0
            ? (100 * getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_60.columns[0])) /
              getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_60.columns[1])
            : 0,
      },
    }),
  },
  HVE4_FERTI_S_60: {
    columns: ["HVE4_FERTI_I_60"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_60.columns[0]) < 5
            ? 0
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_60.columns[0]) < 10
            ? 2
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_60.columns[0]) < 15
            ? 3
            : 4,
      },
    }),
  },
  HVE4_FERTI_D_52: {
    columns: ["HVE4_FERTI_D_50", "HVE4_FERTI_D_51"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_52.columns[0]) +
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_52.columns[1]),
      },
    }),
  },
  HVE4_FERTI_I_50: {
    columns: ["HVE4_FERTI_D_52", "HVE4_CULT_D_200"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_50.columns[1]) > 0
            ? (100 * getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_50.columns[0])) /
              getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_50.columns[1])
            : 0,
      },
    }),
  },
  HVE4_FERTI_S_50: {
    columns: ["HVE4_FERTI_I_50"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_50.columns[0]) <= 5
            ? 0
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_50.columns[0]) <= 15
            ? 1
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_50.columns[0]) <= 25
            ? 2
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_50.columns[0]) <= 35
            ? 3
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_50.columns[0]) <= 45
            ? 4
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_50.columns[0]) <= 55
            ? 5
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_50.columns[0]) <= 65
            ? 6
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_50.columns[0]) <= 75
            ? 7
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_50.columns[0]) <= 85
            ? 8
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_50.columns[0]) <= 95
            ? 9
            : 10,
      },
    }),
  },
  HVE4_FERTI_D_74: {
    columns: ["HVE4_CULT_D_200", "HVE4_CULT_D_42", "HVE4_CULT_D_47", "HVE4_CULT_D_70", "HVE4_CULT_D_69"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_74.columns[0]) -
              getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_74.columns[1]) -
              getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_74.columns[2]) -
              getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_74.columns[3]) +
              getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_74.columns[4]) >
            0
              ? getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_74.columns[0]) -
                getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_74.columns[1]) -
                getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_74.columns[2]) -
                getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_74.columns[3]) +
                getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_74.columns[4])
              : 0,
        },
      };
    },
  },
  HVE4_FERTI_D_740_ZV: {
    columns: ["HVE4_FERTI_D_74_ZV", "HVE4_FERTI_D_74"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_740_ZV.columns[1]) > 0
              ? 100 *
                (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_740_ZV.columns[0]) /
                  getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_740_ZV.columns[1]))
              : 0,
        },
      };
    },
  },
  HVE4_FERTI_D_740_hors_ZV: {
    columns: ["HVE4_FERTI_D_74_hors_ZV", "HVE4_FERTI_D_74"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_740_hors_ZV.columns[1]) > 0
            ? 100 *
              (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_740_hors_ZV.columns[0]) /
                getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_740_hors_ZV.columns[1]))
            : 0,
      },
    }),
  },
  HVE4_FERTI_D_7400: {
    columns: ["HVE4_FERTI_D_74_ZV", "HVE4_FERTI_D_74_hors_ZV"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_FERTI_D_74_ZV") + getValueOrZero(state, "HVE4_FERTI_D_74_hors_ZV") > 0
              ? (getValueOrZero(state, "HVE4_FERTI_D_74_ZV") /
                  (getValueOrZero(state, "HVE4_FERTI_D_74_ZV") + getValueOrZero(state, "HVE4_FERTI_D_74_hors_ZV"))) *
                100
              : 0,
        },
      };
    },
  },
  HVE4_FERTI_I_70_ZV: {
    columns: ["HVE4_FERTI_D_73_ZV", "HVE4_FERTI_D_74_ZV"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_70_ZV.columns[1]) > 0
            ? 100 *
              (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_70_ZV.columns[0]) /
                getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_70_ZV.columns[1]))
            : 0,
      },
    }),
  },
  HVE4_FERTI_S_70_ZV: {
    columns: ["HVE4_FERTI_I_70_ZV", "HVE4_FERTI_D_72_ZV", "HVE4_FERTI_D_75_ZV"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_70_ZV.columns[1])
          ? getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_70_ZV.columns[2]) < 9
            ? 0
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_70_ZV.columns[2]) < 10
            ? getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_70_ZV.columns[0]) < 75
              ? 0
              : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_70_ZV.columns[0]) < 100
              ? 0.5
              : 1
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_70_ZV.columns[2]) < 11
            ? getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_70_ZV.columns[0]) < 75
              ? 0
              : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_70_ZV.columns[0]) < 100
              ? 1
              : 2
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_70_ZV.columns[2]) < 12
            ? getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_70_ZV.columns[0]) < 75
              ? 0
              : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_70_ZV.columns[0]) < 100
              ? 1.5
              : 3
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_70_ZV.columns[0]) < 75
            ? 0
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_70_ZV.columns[0]) < 100
            ? 2
            : 4
          : 0,
      },
    }),
  },
  HVE4_FERTI_S_71: {
    columns: ["HVE4_FERTI_S_70_ZV", "HVE4_FERTI_D_740_ZV"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_71.columns[0]) *
            getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_71.columns[1])) /
          100,
      },
    }),
  },
  HVE4_FERTI_I_70_hors_ZV: {
    columns: ["HVE4_FERTI_D_73_hors_ZV", "HVE4_FERTI_D_74_hors_ZV"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_70_hors_ZV.columns[1]) > 0
            ? (100 * getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_70_hors_ZV.columns[0])) /
              getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_70_hors_ZV.columns[1])
            : 0,
      },
    }),
  },
  HVE4_FERTI_S_70_hors_ZV: {
    columns: ["HVE4_FERTI_I_70_hors_ZV", "HVE4_FERTI_D_72_hors_ZV", "HVE4_FERTI_D_75_hors_ZV"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_70_hors_ZV.columns[1])
          ? getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_70_hors_ZV.columns[2]) < 7
            ? 0
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_70_hors_ZV.columns[2]) < 8
            ? getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_70_hors_ZV.columns[0]) < 75
              ? 0
              : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_70_hors_ZV.columns[0]) < 100
              ? 0.5
              : 1
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_70_hors_ZV.columns[2]) < 9
            ? getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_70_hors_ZV.columns[0]) < 75
              ? 0
              : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_70_hors_ZV.columns[0]) < 100
              ? 1
              : 2
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_70_hors_ZV.columns[2]) < 10
            ? getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_70_hors_ZV.columns[0]) < 75
              ? 0
              : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_70_hors_ZV.columns[0]) < 100
              ? 1.5
              : 3
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_70_hors_ZV.columns[0]) < 75
            ? 0
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_70_hors_ZV.columns[0]) < 100
            ? 2
            : 4
          : 0,
      },
    }),
  },
  HVE4_FERTI_S_72: {
    columns: ["HVE4_FERTI_S_70_hors_ZV", "HVE4_FERTI_D_740_hors_ZV"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_72.columns[0]) *
            getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_72.columns[1])) /
          100,
      },
    }),
  },
  HVE4_FERTI_I_76: {
    columns: ["HVE4_FERTI_D_76", "HVE4_CULT_D_44"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_76.columns[1]) > 0
            ? (100 * getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_76.columns[0])) /
              getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_76.columns[1])
            : 0,
      },
    }),
  },
  HVE4_FERTI_S_76: {
    columns: ["HVE4_FERTI_I_76"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_76.columns[0]) < 50
            ? 0
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_76.columns[0]) < 75
            ? 1
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_76.columns[0]) < 100
            ? 2
            : 3,
      },
    }),
  },
  HVE4_FERTI_D_761: {
    columns: ["HVE4_CULT_D_44", "HVE4_CULT_D_200"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_761.columns[1]) > 0
            ? 100 *
              (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_761.columns[0]) /
                getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_761.columns[1]))
            : 0,
      },
    }),
  },
  HVE4_FERTI_S_760: {
    columns: ["HVE4_FERTI_S_76", "HVE4_FERTI_D_761"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_760.columns[0]) *
            getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_760.columns[1])) /
          100,
      },
    }),
  },
  HVE4_FERTI_I_77: {
    columns: ["HVE4_FERTI_D_77", "HVE4_CULT_D_45", "HVE4_CULT_D_46"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_77.columns[1]) +
            getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_77.columns[2]) >
          0
            ? 100 *
              (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_77.columns[0]) /
                (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_77.columns[1]) +
                  getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_77.columns[2])))
            : 0,
      },
    }),
  },
  HVE4_FERTI_S_77: {
    columns: ["HVE4_FERTI_I_77"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_77.columns[0]) < 50
            ? 0
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_77.columns[0]) < 75
            ? 1
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_77.columns[0]) < 100
            ? 2
            : 3,
      },
    }),
  },
  HVE4_FERTI_S_78: {
    columns: ["HVE4_FERTI_I_78"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_78.columns[0]) < 50
            ? 0
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_78.columns[0]) < 75
            ? 1
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_78.columns[0]) < 100
            ? 2
            : 3,
      },
    }),
  },
  HVE4_FERTI_D_771: {
    columns: ["HVE4_CULT_D_45", "HVE4_CULT_D_46", "HVE4_CULT_D_200"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_771.columns[2]) > 0
              ? (100 *
                  (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_771.columns[0]) +
                    getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_771.columns[1]))) /
                getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_771.columns[2])
              : 0,
        },
      };
    },
  },
  HVE4_FERTI_S_770: {
    columns: ["HVE4_FERTI_S_77", "HVE4_FERTI_D_771"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_770.columns[0]) *
              getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_770.columns[1])) /
            100,
        },
      };
    },
  },
  HVE4_FERTI_I_78: {
    columns: ["HVE4_FERTI_D_78", "HVE4_CULT_D_70", "HVE4_CULT_D_69"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_78.columns[1]) -
            getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_78.columns[2]) >
          0
            ? (100 * getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_78.columns[0])) /
              (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_78.columns[1]) -
                getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_78.columns[2]))
            : 0,
      },
    }),
  },
  HVE4_FERTI_D_781: {
    columns: ["HVE4_CULT_D_70", "HVE4_CULT_D_69", "HVE4_CULT_D_200"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_781.columns[2]) > 0
            ? (100 *
                (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_781.columns[0]) -
                  getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_781.columns[1]))) /
              getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_781.columns[2])
            : 0,
      },
    }),
  },
  HVE4_FERTI_S_780: {
    columns: ["HVE4_FERTI_S_78", "HVE4_FERTI_D_781"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_780.columns[0]) *
            getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_780.columns[1])) /
          100,
      },
    }),
  },
  HVE4_FERTI_S_782: {
    columns: ["HVE4_FERTI_S_71", "HVE4_FERTI_S_72", "HVE4_FERTI_S_760", "HVE4_FERTI_S_770", "HVE4_FERTI_S_780"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: roundFourDecimales(
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_782.columns[0]) +
            getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_782.columns[1]) +
            getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_782.columns[2]) +
            getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_782.columns[3]) +
            getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_782.columns[4])
        ),
      },
    }),
  },
  HVE4_FERTI_I_80: {
    columns: ["HVE4_FERTI_D_80", "HVE4_CULT_D_70"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_80.columns[1]) > 0
            ? (100 * getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_80.columns[0])) /
              getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_80.columns[1])
            : 0,
      },
    }),
  },
  HVE4_FERTI_D_81: {
    columns: ["HVE4_CULT_D_70", "HVE4_CULT_D_200"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_81.columns[1]) > 0
            ? 100 *
              (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_81.columns[0]) /
                getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_81.columns[1]))
            : 0,
      },
    }),
  },
  HVE4_FERTI_S_81: {
    columns: ["HVE4_FERTI_S_80", "HVE4_FERTI_D_81"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: roundFourDecimales(
            (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_81.columns[0]) *
              getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_81.columns[1])) /
              100
          ),
        },
      };
    },
  },
  HVE4_FERTI_S_80: {
    columns: ["HVE4_FERTI_I_80"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_80.columns[0]) < 25
            ? 0
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_80.columns[0]) < 50
            ? 2
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_80.columns[0]) < 75
            ? 4
            : 6,
      },
    }),
  },
  HVE4_FERTI_I_90: {
    columns: ["HVE4_FERTI_D_90", "HVE4_CULT_D_69"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_90.columns[1]) > 0
            ? 100 *
              (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_90.columns[0]) /
                getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_90.columns[1]))
            : 0,
      },
    }),
  },
  HVE4_FERTI_S_90: {
    columns: ["HVE4_FERTI_I_90"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_90.columns[0]) === 0
            ? 0
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_90.columns[0]) < 50
            ? 2
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_90.columns[0]) < 75
            ? 4
            : 6,
      },
    }),
  },
  HVE4_FERTI_I_91: {
    columns: ["HVE4_FERTI_D_91", "HVE4_CULT_D_69"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_91.columns[1]) > 0
            ? 100 *
              (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_91.columns[0]) /
                getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_I_91.columns[1]))
            : 0,
      },
    }),
  },
  HVE4_FERTI_S_91: {
    columns: ["HVE4_FERTI_I_91"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_91.columns[0]) === 0
            ? 0
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_91.columns[0]) < 50
            ? 1
            : getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_91.columns[0]) < 75
            ? 2
            : 3,
      },
    }),
  },
  HVE4_FERTI_S_900: {
    columns: ["HVE4_FERTI_S_90", "HVE4_FERTI_S_91"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_900.columns[0]) +
          getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_900.columns[1]),
      },
    }),
  },
  HVE4_FERTI_D_92: {
    columns: ["HVE4_CULT_D_69", "HVE4_CULT_D_200"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_92.columns[1]) > 0
              ? 100 *
                (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_92.columns[0]) /
                  getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_92.columns[1]))
              : 0,
        },
      };
    },
  },
  HVE4_FERTI_S_920: {
    columns: ["HVE4_FERTI_S_900", "HVE4_FERTI_D_92"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: roundFourDecimales(
            (getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_920.columns[0]) *
              getValueOrZero(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_S_920.columns[1])) /
              100
          ),
        },
      };
    },
  },
  HVE4_FERTI_D_120: {
    columns: [
      "HVE4_FERTI_D_106",
      "HVE4_FERTI_D_107_effl",
      "HVE4_FERTI_D_107_orga",
      "HVE4_FERTI_D_109",
      "HVE4_FERTI_D_110_non_fourr",
      "HVE4_FERTI_D_110_fourr_hors_herbe",
      "HVE4_FERTI_D_110_herbe",
    ],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: {
            n:
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_120.columns[0])?.value?.n +
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_120.columns[1])?.value?.n +
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_120.columns[2])?.value?.n -
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_120.columns[3])?.value?.n -
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_120.columns[4])?.value?.n -
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_120.columns[5])?.value?.n -
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_120.columns[6])?.value?.n,
            n_1:
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_120.columns[0])?.value?.n_1 +
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_120.columns[1])?.value?.n_1 +
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_120.columns[2])?.value?.n_1 -
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_120.columns[3])?.value?.n_1 -
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_120.columns[4])?.value?.n_1 -
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_120.columns[5])?.value?.n_1 -
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_120.columns[6])?.value?.n_1,
            n_2:
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_120.columns[0])?.value?.n_2 +
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_120.columns[1])?.value?.n_2 +
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_120.columns[2])?.value?.n_2 -
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_120.columns[3])?.value?.n_2 -
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_120.columns[4])?.value?.n_2 -
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_120.columns[5])?.value?.n_2 -
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_120.columns[6])?.value?.n_2,
          },
        },
      };
    },
  },
  HVE4_FERTI_D_121: {
    columns: ["HVE4_FERTI_D_120", "HVE4_FERTI_D_108"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: {
            n:
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_121.columns[0])?.value?.n +
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_121.columns[1])?.value?.n,
            n_1:
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_121.columns[0])?.value?.n_1 +
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_121.columns[1])?.value?.n_1,
            n_2:
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_121.columns[0])?.value?.n_2 +
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_121.columns[1])?.value?.n_2,
          },
        },
      };
    },
  },
  HVE4_FERTI_D_158: {
    columns: [
      "HVE4_FERTI_D_150",
      "HVE4_FERTI_D_151_alim",
      "HVE4_FERTI_D_151_fourr",
      "HVE4_FERTI_D_151_paille",
      "HVE4_FERTI_D_152",
      "HVE4_FERTI_D_154",
      "HVE4_FERTI_D_155",
      "HVE4_FERTI_D_156",
      "HVE4_FERTI_D_157",
    ],
    disabled: true,
    computedValue: (state) => {
      const indic150to152 = [
        "HVE4_FERTI_D_150",
        "HVE4_FERTI_D_151_alim",
        "HVE4_FERTI_D_151_fourr",
        "HVE4_FERTI_D_151_paille",
        "HVE4_FERTI_D_152",
      ];

      const indic154to157 = ["HVE4_FERTI_D_154", "HVE4_FERTI_D_155", "HVE4_FERTI_D_156", "HVE4_FERTI_D_157"];
      return {
        valeur: {
          value: {
            n:
              indic150to152.reduce((sum, indic) => sum + getIndicOrNull(state, indic)?.value?.n, 0) -
              indic154to157.reduce((sum, indic) => sum + getIndicOrNull(state, indic)?.value?.n, 0),
            n_1:
              indic150to152.reduce((sum, indic) => sum + getIndicOrNull(state, indic)?.value?.n_1, 0) -
              indic154to157.reduce((sum, indic) => sum + getIndicOrNull(state, indic)?.value?.n_1, 0),
            n_2:
              indic150to152.reduce((sum, indic) => sum + getIndicOrNull(state, indic)?.value?.n_1, 0) -
              indic154to157.reduce((sum, indic) => sum + getIndicOrNull(state, indic)?.value?.n_1, 0),
          },
        },
      };
    },
  },
  HVE4_FERTI_D_159: {
    columns: ["HVE4_FERTI_D_158", "HVE4_FERTI_D_153"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: {
            n:
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_159.columns[0])?.value?.n +
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_159.columns[1])?.value?.n,
            n_1:
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_159.columns[0])?.value?.n_1 +
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_159.columns[1])?.value?.n_1,
            n_2:
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_159.columns[0])?.value?.n_2 +
              getIndicOrNull(state, COMPUTED_FIELDS_FERTILISATION.HVE4_FERTI_D_159.columns[1])?.value?.n_2,
          },
        },
      };
    },
  },
  HVE4_FERTI_S_10: {
    columns: [
      "HVE4_FERTI_D_101",
      "HVE4_FERTI_S_11",
      "HVE4_FERTI_S_12",
      "HVE4_FERTI_S_26",
      "HVE4_FERTI_S_30",
      "HVE4_FERTI_S_402",
      "HVE4_FERTI_S_50",
      "HVE4_FERTI_S_60",
      "HVE4_FERTI_S_782",
      "HVE4_FERTI_S_81",
      "HVE4_FERTI_S_920",
    ],
    disabled: true,
    computedValue: (state) => {
      let scoreBilanAzote =
        getTextOrEmptyString(state, "HVE4_FERTI_D_103") === "Balance Globale Azotée"
          ? getBoolOrFalse(state, "HVE4_FERTI_D_102")
            ? getValueOrZero(state, "HVE4_FERTI_S_1")
            : getValueOrZero(state, "HVE4_FERTI_S_11")
          : getBoolOrFalse(state, "HVE4_FERTI_D_102")
          ? getTextOrEmptyString(state, "HVE4_FERTI_D_101") === "Autre"
            ? getValueOrZero(state, "HVE4_FERTI_S_11")
            : getValueOrZero(state, "HVE4_FERTI_S_12")
          : getTextOrEmptyString(state, "HVE4_FERTI_D_101") === "Autre"
          ? getValueOrZero(state, "HVE4_FERTI_S_1")
          : getValueOrZero(state, "HVE4_FERTI_S_2");

      return {
        valeur: {
          value: {
            commentaire: getIndicOrNull(state, "HVE4_FERTI_S_10").value.commentaire ?? null,
            scoreTotal: roundFourDecimales(
              scoreBilanAzote +
                getValueOrZero(state, "HVE4_FERTI_S_26") +
                getValueOrZero(state, "HVE4_FERTI_S_30") +
                getValueOrZero(state, "HVE4_FERTI_S_402") +
                getValueOrZero(state, "HVE4_FERTI_S_50") +
                getValueOrZero(state, "HVE4_FERTI_S_60") +
                getValueOrZero(state, "HVE4_FERTI_S_782") +
                getValueOrZero(state, "HVE4_FERTI_S_81") +
                getValueOrZero(state, "HVE4_FERTI_S_920")
            ),
          },
        },
      };
    },
  },
  // FIN RUBRIQUE FERTILISATION
};
const getValueOrZero = (state, codeNational) => {
  return state.indicateurs[codeNational] && !isNaN(Number(state.indicateurs[codeNational].valeur.value))
    ? Number(state.indicateurs[codeNational].valeur.value)
    : 0;
};

const getBoolOrFalse = (state, codeNational) => {
  return state.indicateurs[codeNational] && state.indicateurs[codeNational] !== undefined
    ? state.indicateurs[codeNational].valeur.value
    : false;
};

const getTextOrEmptyString = (state, codeNational) => {
  return state.indicateurs[codeNational] && state.indicateurs[codeNational] !== undefined
    ? state.indicateurs[codeNational].valeur.value
    : "";
};

const getIndicOrNull = (state, codeNational) => {
  return state.indicateurs[codeNational] && state.indicateurs[codeNational] !== undefined
    ? state.indicateurs[codeNational].valeur
    : null;
};

const roundFourDecimales = (num) => {
  return Math.round((num + Number.EPSILON) * 10000) / 10000;
};

const exports = {
  COMPUTED_FIELDS_FERTILISATION: COMPUTED_FIELDS_FERTILISATION,
};
export default exports;
