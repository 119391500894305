import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import {
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
import NumeriqueEntier from "../indicateurs/NumeriqueEntier";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Percent from "../indicateurs/Percent";
import Boolean from "../indicateurs/Boolean";
import NumberInput from "../../../../common/NumberInput";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class PhytoSurvActive extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const indicToWatch = [
      "HVE4_PHYTO_I_50",
      "HVE4_PHYTO_S_50",
      "HVE4_PHYTO_I_51",
      "HVE4_PHYTO_S_51",
      "HVE4_PHYTO_I_52",
      "HVE4_PHYTO_D_52",
      "HVE4_PHYTO_D_50",
      "HVE4_PHYTO_D_51",
      "HVE4_PHYTO_I_53",
      "HVE4_PHYTO_D_53",
      "HVE4_PHYTO_S_56",
      "HVE4_PHYTO_D_540",
      "HVE4_PHYTO_S_57",
      "HVE4_PHYTO_S_58",
    ];
    return indicToWatch.some((indic) => this.props.indicateurs[indic] !== nextProps.indicateurs[indic]);
  }

  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };

  render() {
    const { indicateurs, computedFields } = this.props;
    return (
      <Grid container>
        <Grid item xs={12} className={"d-flex align-items-center mt-2"}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow className={"d-flex justify-center"}>
                  <TableCell colSpan={3} />
                  <TableCell style={{ textAlign: "center" }}>Surfaces concernées</TableCell>
                  <TableCell style={{ textAlign: "center" }}>Part des surfaces concernées</TableCell>
                  <TableCell style={{ textAlign: "center" }}>Points</TableCell>
                  <TableCell style={{ textAlign: "center" }}>Part de la SAU concernée part la pratique</TableCell>
                  <TableCell style={{ textAlign: "center" }}>Points</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow key={1}>
                  <TableCell>{indicateurs["HVE4_PHYTO_I_50"].libelle}</TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {indicateurs["HVE4_PHYTO_I_50"].messageAide != null && (
                      <Tooltip
                        title={
                          <span style={{ whiteSpace: "pre-line" }}>{indicateurs["HVE4_PHYTO_I_50"].messageAide}</span>
                        }
                      >
                        <IconButton>
                          <FontAwesomeIcon icon="info-circle" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Boolean
                      indicateur={indicateurs["HVE4_PHYTO_I_50"]}
                      key={"indicateur-" + indicateurs["HVE4_PHYTO_I_50"].idIndicateur}
                      changeIndicateur={this.handleChangeIndicateur}
                      valueInputProps={
                        indicateurs["HVE4_PHYTO_I_50"].libelleUnite != null && {
                          endAdornment: (
                            <InputAdornment position={"end"}>
                              {indicateurs["HVE4_PHYTO_I_50"].libelleUnite}
                            </InputAdornment>
                          ),
                        }
                      }
                      computedFields={computedFields}
                    />
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell style={{ textAlign: "center" }}>
                    <NumeriqueEntier
                      size={"small"}
                      indicateur={indicateurs["HVE4_PHYTO_S_50"]}
                      key={"indicateur-" + indicateurs["HVE4_PHYTO_S_50"].idIndicateur}
                      changeIndicateur={this.handleChangeIndicateur}
                      valueInputProps={
                        indicateurs["HVE4_PHYTO_S_50"].libelleUnite != null && {
                          endAdornment: (
                            <InputAdornment position={"end"}>
                              {indicateurs["HVE4_PHYTO_S_50"].libelleUnite}
                            </InputAdornment>
                          ),
                        }
                      }
                      computedFields={computedFields}
                    />
                  </TableCell>
                </TableRow>
                <TableRow key={2}>
                  <TableCell>{indicateurs["HVE4_PHYTO_I_51"].libelle}</TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    {indicateurs["HVE4_PHYTO_I_51"].messageAide != null && (
                      <Tooltip
                        title={
                          <span style={{ whiteSpace: "pre-line" }}>{indicateurs["HVE4_PHYTO_I_51"].messageAide}</span>
                        }
                      >
                        <IconButton>
                          <FontAwesomeIcon icon="info-circle" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Boolean
                      indicateur={indicateurs["HVE4_PHYTO_I_51"]}
                      key={"indicateur-" + indicateurs["HVE4_PHYTO_I_51"].idIndicateur}
                      changeIndicateur={this.handleChangeIndicateur}
                      valueInputProps={
                        indicateurs["HVE4_PHYTO_I_51"].libelleUnite != null && {
                          endAdornment: (
                            <InputAdornment position={"end"}>
                              {indicateurs["HVE4_PHYTO_I_51"].libelleUnite}
                            </InputAdornment>
                          ),
                        }
                      }
                      computedFields={computedFields}
                    />
                  </TableCell>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell style={{ textAlign: "center" }}>
                    <NumeriqueEntier
                      size={"small"}
                      indicateur={indicateurs["HVE4_PHYTO_S_51"]}
                      key={"indicateur-" + indicateurs["HVE4_PHYTO_S_51"].idIndicateur}
                      changeIndicateur={this.handleChangeIndicateur}
                      valueInputProps={
                        indicateurs["HVE4_PHYTO_S_51"].libelleUnite != null && {
                          endAdornment: (
                            <InputAdornment position={"end"}>
                              {indicateurs["HVE4_PHYTO_S_51"].libelleUnite}
                            </InputAdornment>
                          ),
                        }
                      }
                      computedFields={computedFields}
                    />
                  </TableCell>
                </TableRow>
                <TableRow key={3}>
                  <TableCell rowSpan={2}>
                    Participation active à un dispositif de collecte de données d'observation alimentant le bulletin de
                    santé du végétal dans le cadre du réseau national d'épidémio-surveilance
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>Cultures annuelles</TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Boolean
                      indicateur={indicateurs["HVE4_PHYTO_D_50"]}
                      key={"indicateur-" + indicateurs["HVE4_PHYTO_D_50"].idIndicateur}
                      changeIndicateur={this.handleChangeIndicateur}
                      valueInputProps={
                        indicateurs["HVE4_PHYTO_D_50"].libelleUnite != null && {
                          endAdornment: (
                            <InputAdornment position={"end"}>
                              {indicateurs["HVE4_PHYTO_D_50"].libelleUnite}
                            </InputAdornment>
                          ),
                        }
                      }
                      computedFields={computedFields}
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <NumberInput
                      error={
                        (indicateurs["HVE4_PHYTO_D_52"].valeur?.value ?? 0) >
                        (indicateurs["HVE4_PHYTO_D_541"].valeur?.value ?? 0)
                      }
                      helperText={
                        (indicateurs["HVE4_PHYTO_D_52"].valeur?.value ?? 0) >
                        (indicateurs["HVE4_PHYTO_D_541"].valeur?.value ?? 0)
                          ? "La valeur saisie doit être inférieur à la surface en cultures annuelles"
                          : ""
                      }
                      size={"small"}
                      value={indicateurs["HVE4_PHYTO_D_52"]?.valeur?.value}
                      InputLabelProps={{
                        style: { fontWeight: indicateurs["HVE4_PHYTO_D_52"]?.afficherGras ? "bold" : "normal" },
                      }}
                      style={{ width: "8rem" }}
                      InputProps={
                        indicateurs["HVE4_PHYTO_D_52"].libelleUnite != null && {
                          endAdornment: (
                            <InputAdornment position={"end"}>
                              {indicateurs["HVE4_PHYTO_D_52"].libelleUnite}
                            </InputAdornment>
                          ),
                        }
                      }
                      disabled={!indicateurs["HVE4_PHYTO_D_50"].valeur.value}
                      onChange={(value) =>
                        this.handleChangeIndicateur({
                          ...indicateurs["HVE4_PHYTO_D_52"],
                          valeur: { value: value === "" ? null : Number(value) },
                        })
                      }
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Percent
                      size={"small"}
                      indicateur={indicateurs["HVE4_PHYTO_I_52"]}
                      key={"indicateur-" + indicateurs["HVE4_PHYTO_I_52"].idIndicateur}
                      changeIndicateur={this.handleChangeIndicateur}
                      valueInputProps={
                        indicateurs["HVE4_PHYTO_I_52"].libelleUnite != null && {
                          endAdornment: (
                            <InputAdornment position={"end"}>
                              {indicateurs["HVE4_PHYTO_I_52"].libelleUnite}
                            </InputAdornment>
                          ),
                        }
                      }
                      computedFields={computedFields}
                    />
                  </TableCell>
                  <TableCell rowSpan={2} style={{ textAlign: "center" }}>
                    <NumeriqueEntier
                      size={"small"}
                      indicateur={indicateurs["HVE4_PHYTO_S_56"]}
                      key={"indicateur-" + indicateurs["HVE4_PHYTO_S_56"].idIndicateur}
                      changeIndicateur={this.handleChangeIndicateur}
                      valueInputProps={
                        indicateurs["HVE4_PHYTO_S_56"].libelleUnite != null && {
                          endAdornment: (
                            <InputAdornment position={"end"}>
                              {indicateurs["HVE4_PHYTO_S_56"].libelleUnite}
                            </InputAdornment>
                          ),
                        }
                      }
                      computedFields={computedFields}
                    />
                  </TableCell>
                  <TableCell rowSpan={2} style={{ textAlign: "center" }}>
                    <Percent
                      size={"small"}
                      indicateur={indicateurs["HVE4_PHYTO_D_540"]}
                      key={"indicateur-" + indicateurs["HVE4_PHYTO_D_540"].idIndicateur}
                      changeIndicateur={this.handleChangeIndicateur}
                      valueInputProps={
                        indicateurs["HVE4_PHYTO_D_540"].libelleUnite != null && {
                          endAdornment: (
                            <InputAdornment position={"end"}>
                              {indicateurs["HVE4_PHYTO_D_540"].libelleUnite}
                            </InputAdornment>
                          ),
                        }
                      }
                      computedFields={computedFields}
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }} rowSpan={2}>
                    <DecimalPositif
                      size={"small"}
                      indicateur={indicateurs["HVE4_PHYTO_S_57"]}
                      key={"indicateur-" + indicateurs["HVE4_PHYTO_S_57"].idIndicateur}
                      changeIndicateur={this.handleChangeIndicateur}
                      valueInputProps={
                        indicateurs["HVE4_PHYTO_S_57"].libelleUnite != null && {
                          endAdornment: (
                            <InputAdornment position={"end"}>
                              {indicateurs["HVE4_PHYTO_S_57"].libelleUnite}
                            </InputAdornment>
                          ),
                        }
                      }
                      computedFields={computedFields}
                    />
                  </TableCell>
                </TableRow>
                <TableRow key={4}>
                  <TableCell style={{ textAlign: "center" }}>Cultures perennes</TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Boolean
                      indicateur={indicateurs["HVE4_PHYTO_D_51"]}
                      key={"indicateur-" + indicateurs["HVE4_PHYTO_D_51"].idIndicateur}
                      changeIndicateur={this.handleChangeIndicateur}
                      valueInputProps={
                        indicateurs["HVE4_PHYTO_D_51"].libelleUnite != null && {
                          endAdornment: (
                            <InputAdornment position={"end"}>
                              {indicateurs["HVE4_PHYTO_D_51"].libelleUnite}
                            </InputAdornment>
                          ),
                        }
                      }
                      computedFields={computedFields}
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <NumberInput
                      error={
                        (indicateurs["HVE4_PHYTO_D_53"]?.valeur?.value ?? 0) >
                        (indicateurs["HVE4_CULT_D_47"]?.valeur?.value ?? 0)
                      }
                      helperText={
                        (indicateurs["HVE4_PHYTO_D_53"]?.valeur?.value ?? 0) >
                        (indicateurs["HVE4_CULT_D_47"]?.valeur?.value ?? 0)
                          ? "La valeur saisie doit être inférieur à la surface en cultures pérennes"
                          : ""
                      }
                      size={"small"}
                      value={indicateurs["HVE4_PHYTO_D_53"]?.valeur?.value}
                      InputLabelProps={{
                        style: { fontWeight: indicateurs["HVE4_PHYTO_D_53"]?.afficherGras ? "bold" : "normal" },
                      }}
                      style={{ width: "8rem" }}
                      InputProps={
                        indicateurs["HVE4_PHYTO_D_53"]?.libelleUnite != null && {
                          endAdornment: (
                            <InputAdornment position={"end"}>
                              {indicateurs["HVE4_PHYTO_D_53"]?.libelleUnite}
                            </InputAdornment>
                          ),
                        }
                      }
                      disabled={!indicateurs["HVE4_PHYTO_D_51"].valeur.value}
                      onChange={(value) =>
                        this.handleChangeIndicateur({
                          ...indicateurs["HVE4_PHYTO_D_53"],
                          valeur: { value: value === "" ? null : Number(value) },
                        })
                      }
                    />
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }}>
                    <Percent
                      size={"small"}
                      indicateur={indicateurs["HVE4_PHYTO_I_53"]}
                      key={"indicateur-" + indicateurs["HVE4_PHYTO_I_53"].idIndicateur}
                      changeIndicateur={this.handleChangeIndicateur}
                      valueInputProps={
                        indicateurs["HVE4_PHYTO_I_53"].libelleUnite != null && {
                          endAdornment: (
                            <InputAdornment position={"end"}>
                              {indicateurs["HVE4_PHYTO_I_53"].libelleUnite}
                            </InputAdornment>
                          ),
                        }
                      }
                      computedFields={computedFields}
                    />
                  </TableCell>
                </TableRow>
                <TableRow key={5}>
                  <TableCell colSpan={7} style={{ textAlign: "right" }}>
                    {indicateurs["HVE4_PHYTO_S_58"].libelle}
                  </TableCell>
                  <TableCell style={{ textAlign: "center" }} className={"total-point"}>
                    <DecimalPositif
                      size={"small"}
                      indicateur={indicateurs["HVE4_PHYTO_S_58"]}
                      key={"indicateur-" + indicateurs["HVE4_PHYTO_S_58"].idIndicateur}
                      changeIndicateur={this.handleChangeIndicateur}
                      valueInputProps={
                        indicateurs["HVE4_PHYTO_S_58"].libelleUnite != null && {
                          endAdornment: (
                            <InputAdornment position={"end"}>
                              {indicateurs["HVE4_PHYTO_S_58"].libelleUnite}
                            </InputAdornment>
                          ),
                        }
                      }
                      computedFields={computedFields}
                    />
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(PhytoSurvActive);
