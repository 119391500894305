import network from "./external/network";

export const SET_SELECTED_MILLESIME = "SET_SELECTED_MILLESIME";
export const setSelectedMillesime = (millesime) => ({ type: SET_SELECTED_MILLESIME, payload: millesime });

export const SET_MILLESIMES = "SET_MILLESIMES";
export const setMillesimes = (millesimes) => ({ type: SET_MILLESIMES, payload: millesimes });

export const loadMillesimes = () => async (dispatch) => {
  try {
    const millesimes = await network.fetch(`/api/millesime`);
    dispatch(setMillesimes(millesimes));
  } catch (e) {}
};
