import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Grid, InputAdornment } from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
import NumeriqueEntier from "../indicateurs/NumeriqueEntier";
import NumberInput from "../../../../common/NumberInput";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class BiodivNbVege extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const indicToWatch = [
      "HVE4_CULT_D_44",
      "HVE4_BIODIV_D_44",
      "HVE4_BIODIV_D_45",
      "HVE4_BIODIV_D_4445",
      "HVE4_BIODIV_D_46",
      "HVE4_BIODIV_D_47",
      "HVE4_BIODIV_D_470",
      "HVE4_BIODIV_D_48",
      "HVE4_BIODIV_D_480",
      "HVE4_BIODIV_D_49",
      "HVE4_BIODIV_D_490",
      "HVE4_BIODIV_D_38",
      "HVE4_BIODIV_D_380",
      "HVE4_BIODIV_D_3800",
      "HVE4_BIODIV_D_530",
      "HVE4_BIODIV_D_50",
      "HVE4_BIODIV_D_51",
      "HVE4_BIODIV_D_510",
      "HVE4_BIODIV_D_52",
      "HVE4_BIODIV_D_53",
      "HVE4_BIODIV_D_54",
      "HVE4_BIODIV_D_5154",
      "HVE4_BIODIV_I_54",
      "HVE4_BIODIV_S_54",
    ];
    return indicToWatch.some((indic) => this.props.indicateurs[indic] !== nextProps.indicateurs[indic]);
  }
  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    if (!["HVE4_BIODIV_D_38", "HVE4_BIODIV_D_380"].includes(newIndicateur.codeNational)) {
      changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
    }
  };
  render() {
    const { indicateurs, computedFields, getValueAssolement } = this.props;
    return (
      <React.Fragment>
        {indicateurs.length > 0 && <h3 className={"my-2"}>Nombre d'espèce végétales cultivées</h3>}
        <h2 className={"my-2"}>Cultures arables</h2>
        <div className={"d-flex mb-1 bold"}>
          <Grid container>
            <Grid item xs={7} />
            <Grid item xs={1}>
              Nbre espèces / équivalent nb d'espèces
            </Grid>
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            <div className={indicateurs["HVE4_BIODIV_D_44"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_D_44"].libelle}
            </div>
          </Grid>
          <Grid container item xs={4}>
            <NumeriqueEntier
              size={"small"}
              valueAssolement={getValueAssolement("HVE4_BIODIV_D_44") ?? ""}
              displayRules={{ displayAssolement: true }}
              indicateur={indicateurs["HVE4_BIODIV_D_44"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_44"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_44"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_44"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            <div className={indicateurs["HVE4_BIODIV_D_45"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_D_45"].libelle}
            </div>
          </Grid>
          <Grid container item xs={4}>
            <NumeriqueEntier
              size={"small"}
              valueAssolement={getValueAssolement("HVE4_BIODIV_D_45") ?? ""}
              displayRules={{ displayAssolement: true }}
              indicateur={indicateurs["HVE4_BIODIV_D_45"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_45"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_45"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_45"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <Grid container>
          <Grid item xs={7} />
          <Grid item xs={1}>
            <NumeriqueEntier
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_D_4445"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_4445"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_4445"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_4445"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </Grid>
        <h2 className={"my-2"}>Prairies temporaires de 5 ans et moins (PT)</h2>
        <div className={"d-flex mb-1 bold"}>
          <Grid container>
            <Grid item xs={7} />
            <Grid item xs={1}>
              Nbre espèces / équivalent nb d'espèces
            </Grid>
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            <div className={indicateurs["HVE4_BIODIV_D_46"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_D_46"].libelle}
            </div>
          </Grid>
          <Grid container item xs={3}>
            <div style={{ minWidth: "8rem" }} className={"mr-2"}></div>
            <NumeriqueEntier
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_D_46"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_46"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_46"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_46"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
          <Grid item xs={1}>
            <NumeriqueEntier
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_D_46"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_46"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_46"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_46"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            <div className={indicateurs["HVE4_BIODIV_D_47"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_D_47"].libelle}
            </div>
          </Grid>
          <Grid container item xs={3}>
            <DecimalPositif
              size={"small"}
              valueAssolement={getValueAssolement("HVE4_BIODIV_D_47") ?? ""}
              displayRules={{ displayAssolement: true }}
              indicateur={indicateurs["HVE4_BIODIV_D_47"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_47"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_47"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_47"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
          <Grid container item xs={1}>
            <NumeriqueEntier
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_D_470"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_470"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_470"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_470"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            <div className={indicateurs["HVE4_BIODIV_D_48"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_D_48"].libelle}
            </div>
          </Grid>
          <Grid container item xs={3}>
            <DecimalPositif
              size={"small"}
              valueAssolement={getValueAssolement("HVE4_BIODIV_D_48") ?? ""}
              displayRules={{ displayAssolement: true }}
              indicateur={indicateurs["HVE4_BIODIV_D_48"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_48"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_48"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_48"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
          <Grid container item xs={1}>
            <NumeriqueEntier
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_D_480"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_480"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_480"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_480"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            <div className={indicateurs["HVE4_BIODIV_D_49"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_D_49"].libelle}
            </div>
          </Grid>
          <Grid container item xs={3}>
            <DecimalPositif
              size={"small"}
              valueAssolement={getValueAssolement("HVE4_BIODIV_D_49") ?? ""}
              displayRules={{ displayAssolement: true }}
              indicateur={indicateurs["HVE4_BIODIV_D_49"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_49"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_49"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_49"].libelleUnite}</InputAdornment>
                  ),
                }
              }
            />
          </Grid>
          <Grid container item xs={1}>
            <NumeriqueEntier
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_D_490"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_490"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_490"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_490"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <h2 className={"my-2"}>Prairies permanentes</h2>
        <div className={"d-flex-end mb-1 bold"}>
          <Grid container>
            <Grid item xs={7} />
            <Grid item xs={1}>
              Nbre espèces / équivalent nb d'espèces
            </Grid>
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            <div className={indicateurs["HVE4_BIODIV_D_38"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_D_38"].libelle}
            </div>
          </Grid>
          <Grid container item xs={4}>
            <NumberInput
              size={"small"}
              fullWidth={false}
              value={indicateurs["HVE4_BIODIV_D_38"]?.valeur.value ?? ""}
              disabled
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_38"].idIndicateur}
              style={{ maxWidth: "8rem" }}
              InputProps={
                indicateurs["HVE4_BIODIV_D_38"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_38"].libelleUnite}</InputAdornment>
                  ),
                }
              }
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            <div className={indicateurs["HVE4_BIODIV_D_380"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_D_380"].libelle}
            </div>
          </Grid>
          <Grid container item xs={3}>
            <DecimalPositif
              size={"small"}
              displayRules={{ disabledEdit: true }}
              indicateur={indicateurs["HVE4_BIODIV_D_380"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_380"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_380"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_380"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
          <Grid container item xs={1}>
            <NumeriqueEntier
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_D_3800"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_3800"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_3800"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_3800"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <h2 className={"my-2"}>Autres cultures</h2>
        <div className={"d-flex mb-1 bold"}>
          <Grid container>
            <Grid item xs={7} />
            <Grid item xs={1}>
              Nbre espèces / équivalent nb d'espèces
            </Grid>
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            <div className={indicateurs["HVE4_BIODIV_D_50"]?.afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_D_50"]?.libelle}
            </div>
          </Grid>
          <Grid container item xs={3}>
            <DecimalPositif
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_D_50"]}
              valueAssolement={getValueAssolement("HVE4_BIODIV_D_50") ?? ""}
              displayRules={{ displayAssolement: true }}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_50"]?.idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_50"]?.libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_50"]?.libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>

          <Grid container item xs={1}>
            <NumeriqueEntier
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_D_530"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_530"]?.idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_530"]?.libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_530"]?.libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            <div className={indicateurs["HVE4_BIODIV_D_51"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_D_51"].libelle}
            </div>
          </Grid>
          <Grid container item xs={3}>
            <NumeriqueEntier
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_D_51"]}
              valueAssolement={getValueAssolement("HVE4_BIODIV_D_51") ?? ""}
              displayRules={{ displayAssolement: true }}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_51"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_51"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_51"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
          <Grid container item xs={1}>
            <NumeriqueEntier
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_D_510"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_510"]?.idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_510"]?.libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_510"]?.libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={7}>
            <div className={indicateurs["HVE4_BIODIV_D_52"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_D_52"].libelle}
            </div>
          </Grid>
          <Grid container item xs={1}>
            <NumeriqueEntier
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_D_52"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_52"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_52"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_52"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={7}>
            <div className={indicateurs["HVE4_BIODIV_D_53"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_D_53"].libelle}
            </div>
          </Grid>
          <Grid container item xs={1}>
            <NumeriqueEntier
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_D_53"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_53"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_53"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_53"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={7}>
            <div className={indicateurs["HVE4_BIODIV_D_54"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_D_54"].libelle}
            </div>
          </Grid>
          <Grid container item xs={1}>
            <NumeriqueEntier
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_D_54"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_54"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_54"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_54"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <Grid container>
          <Grid item xs={7} />
          <Grid item xs={1}>
            <div className={"bold mb-1"}>{indicateurs["HVE4_BIODIV_I_54"].libelle}</div>
          </Grid>
          <Grid item xs={2}>
            <div className={"bold mb-1"}>{indicateurs["HVE4_BIODIV_S_54"].libelle}</div>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={7} />
          <Grid item xs={1}>
            <NumeriqueEntier
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_I_54"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_I_54"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_I_54"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_I_54"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
          <Grid item xs={2} className={"total-point"}>
            <NumeriqueEntier
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_S_54"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_S_54"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_S_54"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_S_54"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(BiodivNbVege);
