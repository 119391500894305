import { connect } from "react-redux";
import React from "react";
import ModifExploitation from "./ModifExploitation";
import { Box, Button, Card, CardContent, Divider, Typography, withTheme } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation } from "react-i18next";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";

const mapStateToProps = (state) => ({
  exploitation: state.exploitation.selected,
});

class ModifExploitationPage extends React.Component {
  renderTitle = () => {
    const { t, exploitation, theme } = this.props;
    return (
      <div className="d-flex justify-between align-items-center mb-2">
        <span className="d-flex align-items-center">
          <Typography variant="h1">Modification de l'exploitation {exploitation.raisonSociale}</Typography>
        </span>
        <Box className="d-flex">
          <Button variant="text" onClick={this.goBack}>
            <FontAwesomeIcon color={theme.palette.primary.main} icon="arrow-left" className="mx-1" />
            <span className="link-navigation">{t("navigation.back", "Page précédente")}</span>
          </Button>
          <Divider className="mx-2" orientation="vertical" flexItem />
          <Button component={Link} to="/documents" variant="text">
            <span className="link-navigation">{t("navigation.go-to-docs", "Accéder à l'espace documentaire")}</span>
            <FontAwesomeIcon color={theme.palette.primary.main} icon="arrow-right" className="mx-1" />
          </Button>
        </Box>
      </div>
    );
  };

  goBack = () => {
    this.props.history.goBack();
  };

  afterSubmit = () => {
    this.props.history.push("/");
  };

  render() {
    const { exploitation } = this.props;
    return (
      <React.Fragment>
        {this.renderTitle()}
        <Box>
          <Card>
            <CardContent className="d-flex flex-column align-items-stretch">
              <ModifExploitation
                idExploitation={exploitation.idExploitation}
                onCancel={this.goBack}
                afterSubmit={this.afterSubmit}
              />
            </CardContent>
          </Card>
        </Box>
      </React.Fragment>
    );
  }
}

export default compose(withTranslation(), withTheme, connect(mapStateToProps), withRouter)(ModifExploitationPage);
