import { SET_SELECTED_EXPLOITATION, RESET_EXPLOITATIONS } from "../actions/exploitation";

// init config state
const exploitationInitialState = {
  selected: null,
  total: 0,
  offset: 0,
};

const exploitation = (state = exploitationInitialState, action) => {
  switch (action.type) {
    case RESET_EXPLOITATIONS:
      return exploitationInitialState;
    case SET_SELECTED_EXPLOITATION:
      return { ...state, selected: action.payload };
    default:
      return state;
  }
};
export default exploitation;
