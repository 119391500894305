import React from "react";
import Indicator from "../../Indicator";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Switch } from "@material-ui/core";

class Boolean extends React.Component {
  render() {
    const {
      indicateur,
      onChangeIndicator,
      sousRubriqueIndex,
      libelleEnglobantIndex,
      changeIndicateur,
      computedFields,
      demarche,
    } = this.props;

    const valeurCalculee = (
      <React.Fragment>
        <span style={{ alignSelf: "center" }}>Non</span>
        <Switch checked={!!indicateur.valeurCalculee?.value} color={"primary"} disabled />
        <span style={{ alignSelf: "center" }} className={"mr-2"}>
          Oui
        </span>
      </React.Fragment>
    );

    const valueRendered = (
      <React.Fragment>
        <div style={{ width: "8rem" }}>
          <span style={{ alignSelf: "center" }}>Non</span>
          <Switch
            checked={!!indicateur.valeur.value}
            color={"primary"}
            disabled={computedFields.includes(indicateur.codeNational)}
            onChange={(event) =>
              changeIndicateur(
                { ...indicateur, valeur: { value: event.target.checked } },
                sousRubriqueIndex,
                libelleEnglobantIndex
              )
            }
          />
          <span style={{ alignSelf: "center" }}>Oui</span>
        </div>
      </React.Fragment>
    );

    return (
      <Indicator indicateur={indicateur} onChange={onChangeIndicator} demarche={demarche}>
        {!!indicateur.valeurCalculee && valeurCalculee}
        {valueRendered}
      </Indicator>
    );
  }
}

export default compose(withTranslation())(Boolean);
