import React from "react";
import { Field, Form, withFormik } from "formik";
import { TextField, Switch } from "formik-material-ui";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {
  Button,
  CircularProgress,
  Box,
  IconButton,
  Tooltip,
  FormControlLabel,
  MenuItem,
  Chip,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./OrganismeForm.module.scss";
import { withTheme } from "@material-ui/core/styles";
import { toastr } from "react-redux-toastr";
import { fileToBase64 } from "../../../scripts/utils";

class OrganismeForm extends React.Component {
  constructor(props) {
    super(props);
    this.fileRef = React.createRef();
    this.dropZone = React.createRef();
    this.state = {
      dragging: false,
    };
  }

  isEditing = () => this.props.organisme != null;

  uploadFile = async (file) => {
    const b64 = await fileToBase64(file);
    this.props.setFieldValue("logoBase64", b64);
  };

  dragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ dragging: true });
    }
  };

  dragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  dragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ dragging: false });
  };

  handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({
      dragging: false,
    });

    const files = e.dataTransfer.files;
    if (files.length) {
      this.validateFile(files);
    }
  };

  validateFile = (files = [null]) => {
    const { t } = this.props;
    const file = files[0] || this.fileRef.current.files[0];
    const regex = /^image\/(gif|png|bmp|svg|svg\+xml|jpg|jpeg|\*)$/;
    if (!regex.test(file.type)) {
      toastr.error(
        t("error", "Erreur"),
        t(
          "admin.organization.upload-error-type",
          "Le type de fichier que vous souhaité uploader n'est pas accepté, veuiller réessayer"
        )
      );
      return;
    }
    if (file.size > 10000000) {
      toastr.error(
        t("error", "Erreur"),
        t(
          "admin.organization.upload-error-size",
          "Le fichier que vous tentez d'uploader est trop volumineux, veuillez réessayer"
        )
      );
      return;
    }
    this.uploadFile(file);
  };

  handleColor = (e) => {
    const colorPickerName = e.target.name;
    const colorPickerValue = e.target.value;

    this.props.setFieldValue(colorPickerName, colorPickerValue);
  };

  renderUpload() {
    const { t, theme, organisme, values } = this.props;
    return (
      <Box
        className={styles.dropzone}
        ref={this.dropZone}
        onDragEnter={this.dragEnter}
        onDragOver={this.dragOver}
        onDrop={this.handleDrop}
        onDragLeave={this.dragLeave}
        style={
          this.state.dragging
            ? { backgroundColor: theme.palette.background.paper }
            : { backgroundColor: theme.palette.action.hover }
        }
      >
        {values.logoBase64 && (
          <img
            src={values.logoBase64}
            style={{ maxHeight: "6em", margin: "1em" }}
            alt={organisme != null && organisme.nom}
          />
        )}

        <Box>
          <input
            accept="image/jpeg, image/gif, image/png, image/svg, image/bmp"
            id="icon-button-file"
            type="file"
            style={{ display: "none" }}
            onChange={this.validateFile}
            ref={this.fileRef}
          />
          <label htmlFor="icon-button-file">
            <Tooltip title={t("admin.organization.upload-logo", "Charger un logo")} arrow placement="bottom">
              <IconButton color="primary" aria-label="upload picture" component="span">
                <FontAwesomeIcon icon={"upload"} />
              </IconButton>
            </Tooltip>
          </label>
        </Box>
        <p
          style={{
            color: theme.palette.text.secondary,
            fontStyle: "italic",
            margin: "0.5em 0",
          }}
        >
          {t("admin.organization.logo-drag-drop", "Glissez déposez votre nouveau logo ici")}
        </p>
      </Box>
    );
  }

  renderNameAdresseWebsite = () => {
    const { t } = this.props;
    return (
      <Box>
        <Field
          component={TextField}
          name="nom"
          type="text"
          label={t("admin.organization.lastname", "Nom")}
          variant="outlined"
          required
          className="mb-1"
        />
        <Field
          component={TextField}
          name="adresse"
          type="text"
          label={t("admin.organization.address", "Adresse")}
          variant="outlined"
          className="mb-1"
          required
        />
        <Field
          component={TextField}
          name="siteInternet"
          type="text"
          label={t("admin.organization.website", "Site web")}
          variant="outlined"
          className="mb-1"
        />
      </Box>
    );
  };

  renderDemarches = () => {
    const { t, demarches } = this.props;

    return (
      <Field
        component={TextField}
        select
        name="demarches"
        label={t("admin.organization.demarche", "Démarches accessibles")}
        SelectProps={{
          multiple: true,
          renderValue: (selected) => (
            <div className="d-flex flex-wrap">
              {selected
                .filter((value) => demarches.find((demarche) => demarche.idDemarche === value))
                .map((value) => (
                  <Chip
                    key={value}
                    label={demarches.find((demarche) => demarche.idDemarche === value)?.libelle}
                    className={styles.wrapperChip}
                  />
                ))}
            </div>
          ),
        }}
        variant="outlined"
        className="mb-1"
        style={{ minWidth: 200 }}
      >
        {demarches.map((demarche) => (
          <MenuItem key={demarche.idDemarche} value={demarche.idDemarche}>
            {demarche.libelle}
          </MenuItem>
        ))}
      </Field>
    );
  };

  renderColorPicker = () => {
    const { t } = this.props;
    const colorPickerArray = [
      {
        libelle: t("admin.organization.primaryColor", "Couleur primaire hexa"),
        key: "couleurPrimaire",
      },
      {
        libelle: t("admin.organization.secondaryColor", "Couleur secondaire hexa"),
        key: "couleurSecondaire",
      },
      {
        libelle: t("admin.organization.thirdColor", "Couleur tertiaire hexa"),
        key: "couleurTertiaire",
      },
    ];

    return colorPickerArray.map((item) => (
      <Box key={item.key} className={styles.wrapperColorPicker}>
        <Box className={styles.colorPicker}>
          <label
            htmlFor={item.key}
            style={{ backgroundColor: this.props.values[item.key] }}
            className={styles.colorPickerBox}
          />
          <input
            value={this.props.values[item.key]}
            id={item.key}
            name={item.key}
            type="color"
            className={styles.colorPicker}
            onChange={this.handleColor}
          />
        </Box>
        <Field
          component={TextField}
          name={item.key}
          type="text"
          label={t(item.libelle)}
          required
          className={styles.colorHexa}
        />
      </Box>
    ));
  };

  renderSwitchAgrementSca = () => {
    const { t } = this.props;
    return (
      <FormControlLabel
        className="mb-1"
        control={<Field component={Switch} color="primary" name="agrementSca" type="checkbox" />}
        label={t("admin.organization.agrementSca", "Agrément SCA")}
      />
    );
  };

  renderTexteReglementaire = () => {
    const { t } = this.props;
    return (
      <Box>
        <Field
          component={TextField}
          multiline
          rows={6}
          name="texteReglementaire"
          type="text"
          label={t("admin.organization.texte-reglementaire", "Texte réglementaire")}
          variant="outlined"
          className="mb-1"
        />
      </Box>
    );
  };

  renderSubmitButton = () => {
    const { t, isSubmitting, isValid } = this.props;
    return (
      <Box>
        <Button disabled={!isValid || isSubmitting} color="primary" variant="contained" type="submit" className="mt-1">
          {isSubmitting ? <CircularProgress size={24} /> : t("admin.user-form.validate", "Valider")}
        </Button>
      </Box>
    );
  };

  render() {
    return (
      <Form className="d-flex flex-column">
        {this.renderNameAdresseWebsite()}
        {this.renderDemarches()}
        {this.renderSwitchAgrementSca()}
        <Box className="d-flex mb-2">
          {this.renderUpload()}
          <Box className="mt-1">{this.renderColorPicker()}</Box>
        </Box>
        {this.renderTexteReglementaire()}
        {this.renderSubmitButton()}
      </Form>
    );
  }
}

const validate = (values, props) => {
  const { t } = props;
  const errors = {};
  const regexHexa = /^#[0-9A-Fa-f]{6}$/;

  if (!values.nom) {
    errors.nom = t("admin.organization.lastname-required", "Le nom est obligatoire");
  }
  if (!values.adresse) {
    errors.adresse = t("admin.organization.address-required", "L'adresse est obligatoire");
  }
  if (!values.logoBase64) {
    errors.logoBase64 = t("admin.organization.logo-required", "Le logo est obligatoire");
  }
  if (!values.couleurPrimaire) {
    errors.couleurPrimaire = t("admin.organization.required-primaryColor", "La couleur primaire est obligatoire");
  } else if (!regexHexa.test(values.couleurPrimaire)) {
    errors.couleurPrimaire = t("admin.organization.required-hexa", "Le format hexa souhaité est #AABBCC");
  }
  if (!values.couleurSecondaire) {
    errors.couleurSecondaire = t("admin.organization.required-secondaryColor", "La couleur secondaire est obligatoire");
  } else if (!regexHexa.test(values.couleurSecondaire)) {
    errors.couleurSecondaire = t("admin.organization.required-hexa", "Le format hexa souhaité est #AABBCC");
  }
  if (!values.couleurTertiaire) {
    errors.couleurTertiaire = t("admin.organization.required-thirdColor", "La couleur tertiaire est obligatoire");
  } else if (!regexHexa.test(values.couleurTertiaire)) {
    errors.couleurTertiaire = t("admin.organization.required-hexa", "Le format hexa souhaité est #AABBCC");
  }

  return errors;
};

const mapPropsToValues = (props) => ({
  nom: "",
  adresse: "",
  logoBase64: props.config.logoBase64,
  siteInternet: props.config.titleSite,
  couleurPrimaire: props.config.couleurPrimaire,
  couleurSecondaire: props.config.couleurSecondaire,
  couleurTertiaire: props.config.couleurTertiaire,
  texteReglementaire: "",
  demarches: [],
  agrementSca: false,
  ...props.organisme,
});

const handleSubmit = async (values, { props, setSubmitting }) => {
  await props.onSubmit(values);
  setSubmitting(false);
};

export default withRouter(
  withTheme(
    withTranslation()(
      withFormik({
        mapPropsToValues: mapPropsToValues,
        validate: validate,
        handleSubmit: handleSubmit,
      })(OrganismeForm)
    )
  )
);
