import React from "react";
import Indicator from "../../Indicator";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";

class Default extends React.Component {
  render() {
    const {
      indicateur,
      onChangeIndicator,
      sousRubriqueIndex,
      libelleEnglobantIndex,
      changeIndicateur,
      computedFields,
      demarche,
    } = this.props;

    const valueRendered = (
      <TextField
        fullWidth={false}
        value={indicateur.valeur.value ?? ""}
        style={{
          fontWeight: indicateur.afficherGras ? "bold" : "normal",
          minWidth: "10rem",
          maxWidth: "12rem",
        }}
        disabled={computedFields.includes(indicateur.codeNational)}
        onChange={(event) =>
          changeIndicateur(
            { ...indicateur, valeur: { value: event.target.value } },
            sousRubriqueIndex,
            libelleEnglobantIndex
          )
        }
      />
    );

    return (
      <Indicator indicateur={indicateur} onChange={onChangeIndicator} demarche={demarche}>
        {valueRendered}
      </Indicator>
    );
  }
}

export default compose(withTranslation())(Default);
