import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import {
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
import NumeriqueEntier from "../indicateurs/NumeriqueEntier";
import Percent from "../indicateurs/Percent";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class PhytoVegInterrang extends React.Component {
  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };

  checkIfNull = (indicateursToCheck) => {
    return indicateursToCheck.some((value) => value == null);
  };

  render() {
    const { indicateurs, computedFields } = this.props;

    const indicateursViti = [
      "HVE4_PHYTO_D_90",
      "HVE4_PHYTO_I_90",
      "HVE4_PHYTO_S_90",
      "HVE4_PHYTO_D_800",
      "HVE4_PHYTO_S_900",
    ];

    const indicateursArbo = [
      "HVE4_PHYTO_D_91",
      "HVE4_PHYTO_I_91",
      "HVE4_PHYTO_S_91",
      "HVE4_PHYTO_D_810",
      "HVE4_PHYTO_S_910",
    ];

    const indicateursHortic = [
      "HVE4_PHYTO_D_92",
      "HVE4_PHYTO_I_92",
      "HVE4_PHYTO_S_92",
      "HVE4_PHYTO_D_920",
      "HVE4_PHYTO_S_920",
      "HVE4_CULT_D_70",
      "HVE4_CULT_D_69",
    ];

    const indicateursCouvertVege = ["HVE4_PHYTO_S_930"];

    const indicateursVitiToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateursViti.includes(key)))
    );

    const indicateursArboToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateursArbo.includes(key)))
    );

    const indicateursHorticToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateursHortic.includes(key)))
    );

    const indicateursCouvertVegeToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateursCouvertVege.includes(key)))
    );

    return (
      <Grid container>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow className={"d-flex justify-center"}>
                  <TableCell style={{ width: "15em" }} />
                  <TableCell style={{ textAlign: "center" }}>Surface couverte</TableCell>
                  <TableCell style={{ textAlign: "center" }}>Part de surface couverte</TableCell>
                  <TableCell style={{ textAlign: "center" }}>Points intermediaires</TableCell>
                  <TableCell style={{ textAlign: "center" }}>Part des surfaces des cultures sur la SAU</TableCell>
                  <TableCell style={{ textAlign: "center" }}>Points</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {indicateursVitiToDisplay.length > 0 && (
                  <TableRow key={1}>
                    <TableCell>Vigne</TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <DecimalPositif
                        error={
                          this.checkIfNull([indicateurs["HVE4_CULT_D_44"]])
                            ? false
                            : indicateurs["HVE4_PHYTO_D_90"].valeur.value > indicateurs["HVE4_CULT_D_44"].valeur.value
                        }
                        helperText={
                          this.checkIfNull([indicateurs["HVE4_CULT_D_44"]])
                            ? false
                            : indicateurs["HVE4_PHYTO_D_90"].valeur.value > indicateurs["HVE4_CULT_D_44"].valeur.value
                            ? "La valeur saisie ne doit pas dépasser la surface en vigne"
                            : ""
                        }
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_D_90"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_D_90"].idIndicateur}
                        displayRules={{ displayValeurMP: "column" }}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_D_90"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_D_90"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <Percent
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_I_90"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_I_90"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_I_90"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_I_90"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <NumeriqueEntier
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_S_90"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_S_90"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_S_90"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_S_90"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <Percent
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_D_800"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_D_800"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_D_800"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_D_800"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <DecimalPositif
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_S_900"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_S_900"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_S_900"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_S_900"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                  </TableRow>
                )}
                {indicateursArboToDisplay.length > 0 && (
                  <TableRow key={2}>
                    <TableCell>Arboriculture et autres cultures pérennes</TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <DecimalPositif
                        error={
                          indicateurs["HVE4_PHYTO_D_91"].valeur.value >
                          indicateurs["HVE4_CULT_D_45"].valeur.value + indicateurs["HVE4_CULT_D_46"].valeur.value
                        }
                        helperText={
                          indicateurs["HVE4_PHYTO_D_91"].valeur.value >
                          indicateurs["HVE4_CULT_D_45"].valeur.value + indicateurs["HVE4_CULT_D_46"].valeur.value
                            ? "La valeur saisie ne doit pas dépasser la surface en arboriculture et autres cultures pérennes"
                            : ""
                        }
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_D_91"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_D_91"].idIndicateur}
                        displayRules={{ displayValeurMP: "column" }}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_D_91"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_D_91"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <Percent
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_I_91"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_I_91"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_I_91"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_I_91"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <NumeriqueEntier
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_S_91"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_S_91"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_S_91"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_S_91"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <Percent
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_D_810"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_D_810"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_D_810"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_D_810"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <DecimalPositif
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_S_910"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_S_910"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_S_910"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_S_910"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                  </TableRow>
                )}
                {indicateursHorticToDisplay.length > 0 && (
                  <TableRow key={3}>
                    <TableCell>Horticulture - pépinières</TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <DecimalPositif
                        error={
                          (indicateurs["HVE4_PHYTO_D_92"]?.valeur?.value ?? 0) >
                          (indicateurs["HVE4_CULT_D_70"]?.valeur?.value ?? 0) -
                            (indicateurs["HVE4_CULT_D_69"]?.valeur?.value ?? 0)
                        }
                        helperText={
                          (indicateurs["HVE4_PHYTO_D_92"]?.valeur?.value ?? 0) >
                          (indicateurs["HVE4_CULT_D_70"]?.valeur?.value ??
                            0 - indicateurs["HVE4_CULT_D_69"]?.valeur.value ??
                            0)
                            ? "La valeur saisie ne doit pas dépasser la surface en horticulture pépinière"
                            : ""
                        }
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_D_92"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_D_92"].idIndicateur}
                        displayRules={{ displayValeurMP: "column" }}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_D_92"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_D_92"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <Percent
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_I_92"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_I_92"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_I_92"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_I_92"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <NumeriqueEntier
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_S_92"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_S_92"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_S_92"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_S_92"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <Percent
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_D_920"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_D_920"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_D_920"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_D_920"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <DecimalPositif
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_S_920"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_S_920"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_S_920"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_S_920"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                  </TableRow>
                )}
                {indicateursCouvertVegeToDisplay.length > 0 && (
                  <TableRow key={5}>
                    <TableCell colSpan={3} />
                    <TableCell style={{ textAlign: "center" }} />
                    <TableCell style={{ textAlign: "center" }}>Nombre de points "Couvert végétal Interrang"</TableCell>
                    <TableCell style={{ textAlign: "center" }} className={"total-point"}>
                      <DecimalPositif
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_S_930"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_S_930"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_S_930"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_S_930"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    );
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(PhytoVegInterrang);
