import React from "react";
import { Box, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography } from "@material-ui/core";
import NumberInput from "../../../../../common/NumberInput";
import PropTypes from "prop-types";
import Indicator from "../../Indicator";

class DiversiteVarietale extends React.Component {
  updateDiversiteVarietale = (indicateur) => {
    const { sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    this.props.changeIndicateur(indicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };

  renderViticulture = () => {
    const { indicateur, disabled } = this.props;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "50%" }}>Vigne</TableCell>
            <TableCell align={"right"}>Nombre</TableCell>
            <TableCell>Score</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Nombre de clones</TableCell>
            <TableCell align={"right"}>
              <NumberInput
                fullWidth={false}
                style={{ width: "4rem" }}
                value={indicateur.valeur?.vigne_nb_clones?.value || ""}
                disabled={disabled}
                onChange={(value) =>
                  this.updateDiversiteVarietale({
                    ...indicateur,
                    valeur: {
                      ...indicateur.valeur,
                      vigne_nb_clones: {
                        ...indicateur.valeur.vigne_nb_clones,
                        value,
                      },
                    },
                  })
                }
              />
            </TableCell>
            <TableCell>
              <NumberInput
                fullWidth={false}
                style={{ width: "4rem" }}
                disabled
                value={indicateur?.valeur?.vigne_nb_clones?.note}
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  };

  renderArboriculture = () => {
    const { indicateur, disabled } = this.props;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "50%" }}>Arboriculture et autres cultures pérennes</TableCell>
            <TableCell style={{ width: "25%" }} align={"right"}>
              Nombre
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Nombre total d'espèces</TableCell>
            <TableCell align={"right"}>
              <NumberInput
                fullWidth={false}
                style={{ width: "4rem" }}
                value={indicateur?.valeur?.arboriculture?.nb_especes}
                disabled={disabled}
                onChange={(value) =>
                  this.updateDiversiteVarietale({
                    ...indicateur,
                    valeur: {
                      ...indicateur.valeur,
                      arboriculture: {
                        ...indicateur.valeur?.arboriculture,
                        nb_especes: value,
                      },
                    },
                  })
                }
              />
            </TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell>Nombre total de variétés</TableCell>
            <TableCell align={"right"}>
              <NumberInput
                fullWidth={false}
                style={{ width: "4rem" }}
                value={indicateur?.valeur?.arboriculture?.nb_varietes}
                disabled={disabled}
                onChange={(value) =>
                  this.updateDiversiteVarietale({
                    ...indicateur,
                    valeur: {
                      ...indicateur.valeur,
                      arboriculture: {
                        ...indicateur.valeur?.arboriculture,
                        nb_varietes: value,
                      },
                    },
                  })
                }
              />
            </TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell>Nombre d'espèces avec 2 variétés cultivées</TableCell>
            <TableCell align={"right"}>
              <NumberInput
                fullWidth={false}
                style={{ width: "4rem" }}
                value={indicateur?.valeur?.arboriculture?.nb_especes_2_varietes}
                disabled={disabled}
                onChange={(value) =>
                  this.updateDiversiteVarietale({
                    ...indicateur,
                    valeur: {
                      ...indicateur.valeur,
                      arboriculture: {
                        ...indicateur.valeur?.arboriculture,
                        nb_especes_2_varietes: value,
                      },
                    },
                  })
                }
              />
            </TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell>Nombre d'espèces avec au moins 3 variétés cultivées</TableCell>
            <TableCell align={"right"}>
              <NumberInput
                fullWidth={false}
                style={{ width: "4rem" }}
                value={indicateur?.valeur?.arboriculture?.nb_especes_3_varietes}
                disabled={disabled}
                onChange={(value) =>
                  this.updateDiversiteVarietale({
                    ...indicateur,
                    valeur: {
                      ...indicateur.valeur,
                      arboriculture: {
                        ...indicateur.valeur?.arboriculture,
                        nb_especes_3_varietes: value,
                      },
                    },
                  })
                }
              />
            </TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell>Scoring Arboriculture</TableCell>
            <TableCell align={"right"}>
              <NumberInput
                fullWidth={false}
                style={{ width: "4rem" }}
                disabled
                value={indicateur?.valeur?.arboriculture?.note}
              />
            </TableCell>
            <TableCell />
          </TableRow>
        </TableFooter>
      </Table>
    );
  };

  renderLegumesFleursFruits = () => {
    const { indicateur, disabled } = this.props;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "50%" }}>
              Diversités spécifiques et variétales legumes, fleurs et fruits hors arboriculture
            </TableCell>
            <TableCell align={"right"} style={{ width: "25%" }}>
              Nombre
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Nombre total d'espèces</TableCell>
            <TableCell align={"right"}>
              <NumberInput
                fullWidth={false}
                style={{ width: "4rem" }}
                value={indicateur?.valeur?.legumes_fleurs_fruits?.nb_especes}
                disabled={disabled}
                onChange={(value) =>
                  this.updateDiversiteVarietale({
                    ...indicateur,
                    valeur: {
                      ...indicateur.valeur,
                      legumes_fleurs_fruits: {
                        ...indicateur.valeur?.legumes_fleurs_fruits,
                        nb_especes: value,
                      },
                    },
                  })
                }
              />
            </TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell>Nombre total de variétés</TableCell>
            <TableCell align={"right"}>
              <NumberInput
                fullWidth={false}
                style={{ width: "4rem" }}
                value={indicateur?.valeur?.legumes_fleurs_fruits?.nb_varietes}
                disabled={disabled}
                onChange={(value) =>
                  this.updateDiversiteVarietale({
                    ...indicateur,
                    valeur: {
                      ...indicateur.valeur,
                      legumes_fleurs_fruits: {
                        ...indicateur.valeur?.legumes_fleurs_fruits,
                        nb_varietes: value,
                      },
                    },
                  })
                }
              />
            </TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell>Nombre d'espèces avec 2 variétés cultivées</TableCell>
            <TableCell align={"right"}>
              <NumberInput
                fullWidth={false}
                style={{ width: "4rem" }}
                value={indicateur?.valeur?.legumes_fleurs_fruits?.nb_especes_2_varietes}
                disabled={disabled}
                onChange={(value) =>
                  this.updateDiversiteVarietale({
                    ...indicateur,
                    valeur: {
                      ...indicateur.valeur,
                      legumes_fleurs_fruits: {
                        ...indicateur.valeur?.legumes_fleurs_fruits,
                        nb_especes_2_varietes: value,
                      },
                    },
                  })
                }
              />
            </TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell>Nombre d'espèces avec au moins 3 variétés cultivées</TableCell>
            <TableCell align={"right"}>
              <NumberInput
                fullWidth={false}
                style={{ width: "4rem" }}
                value={indicateur?.valeur?.legumes_fleurs_fruits?.nb_especes_3_varietes}
                disabled={disabled}
                onChange={(value) =>
                  this.updateDiversiteVarietale({
                    ...indicateur,
                    valeur: {
                      ...indicateur.valeur,
                      legumes_fleurs_fruits: {
                        ...indicateur.valeur?.legumes_fleurs_fruits,
                        nb_especes_3_varietes: value,
                      },
                    },
                  })
                }
              />
            </TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell>Scoring Légumes, fleurs et fruits hors arboriculture</TableCell>
            <TableCell align={"right"}>
              <NumberInput
                fullWidth={false}
                style={{ width: "4rem" }}
                disabled
                value={indicateur.valeur?.legumes_fleurs_fruits?.note}
              />
            </TableCell>
            <TableCell />
          </TableRow>
        </TableFooter>
      </Table>
    );
  };

  renderHorsSol = () => {
    const { indicateur, disabled } = this.props;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: "50%" }}>Cultures hors-sol</TableCell>
            <TableCell align={"right"} style={{ width: "25%" }}>
              Nombre
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Nombre total d'espèces</TableCell>
            <TableCell align={"right"}>
              <NumberInput
                fullWidth={false}
                style={{ width: "4rem" }}
                value={indicateur.valeur?.hors_sol?.nb_especes}
                disabled={disabled}
                onChange={(value) =>
                  this.updateDiversiteVarietale({
                    ...indicateur,
                    valeur: {
                      ...indicateur.valeur,
                      hors_sol: {
                        ...indicateur.valeur?.hors_sol,
                        nb_especes: value,
                      },
                    },
                  })
                }
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Nombre total de variétés</TableCell>
            <TableCell align={"right"}>
              <NumberInput
                fullWidth={false}
                style={{ width: "4rem" }}
                value={indicateur.valeur?.hors_sol?.nb_varietes}
                disabled={disabled}
                onChange={(value) =>
                  this.updateDiversiteVarietale({
                    ...indicateur,
                    valeur: {
                      ...indicateur.valeur,
                      hors_sol: {
                        ...indicateur.valeur?.hors_sol,
                        nb_varietes: value,
                      },
                    },
                  })
                }
              />
            </TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell>Nombre d'espèces avec 2 variétés cultivées</TableCell>
            <TableCell align={"right"}>
              <NumberInput
                fullWidth={false}
                style={{ width: "4rem" }}
                value={indicateur.valeur?.hors_sol?.nb_especes_2_varietes}
                disabled={disabled}
                onChange={(value) =>
                  this.updateDiversiteVarietale({
                    ...indicateur,
                    valeur: {
                      ...indicateur.valeur,
                      hors_sol: {
                        ...indicateur.valeur?.hors_sol,
                        nb_especes_2_varietes: value,
                      },
                    },
                  })
                }
              />
            </TableCell>
            <TableCell />
          </TableRow>
          <TableRow>
            <TableCell>Nombre d'espèces avec au moins 3 variétés cultivées</TableCell>
            <TableCell align={"right"}>
              <NumberInput
                fullWidth={false}
                style={{ width: "4rem" }}
                value={indicateur.valeur?.hors_sol?.nb_especes_3_varietes}
                disabled={disabled}
                onChange={(value) =>
                  this.updateDiversiteVarietale({
                    ...indicateur,
                    valeur: {
                      ...indicateur.valeur,
                      hors_sol: {
                        ...indicateur.valeur?.hors_sol,
                        nb_especes_3_varietes: value,
                      },
                    },
                  })
                }
              />
            </TableCell>
            <TableCell />
          </TableRow>
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell>Scoring Cultures hors-sol</TableCell>
            <TableCell align={"right"}>
              <NumberInput
                fullWidth={false}
                style={{ width: "4rem" }}
                disabled
                value={indicateur.valeur?.hors_sol?.note}
              />
            </TableCell>
            <TableCell />
          </TableRow>
        </TableFooter>
      </Table>
    );
  };

  getValueRendered = () => {
    const { famillesCultures } = this.props,
      codesFamilles = famillesCultures.map((f) => f.codeNational),
      isVitiSelected = codesFamilles.includes("VITIC"),
      isArboSelected = codesFamilles.includes("ARBOR"),
      isLegumesFruitsSelected = codesFamilles.includes("LGFLHA"),
      isHorsSolSelected = codesFamilles.includes("HORSOL"),
      isMaraichage = codesFamilles.includes("MARAICH");
    return (
      <Box style={{ minWidth: "68vw" }}>
        {isVitiSelected && this.renderViticulture()}
        {isArboSelected && this.renderArboriculture()}
        {(isLegumesFruitsSelected || isMaraichage) && this.renderLegumesFleursFruits()}
        {isHorsSolSelected && this.renderHorsSol()}

        <Typography className="ml-1" style={{ fontWeight: "bold", position: "relative", top: "2rem" }}>
          Validité de la diversité variétale
        </Typography>
      </Box>
    );
  };

  render() {
    const { indicateur, onChangeIndicator } = this.props;

    return (
      <Indicator indicateur={indicateur} onChange={onChangeIndicator} alignScoreToTheEnd>
        {this.getValueRendered()}
      </Indicator>
    );
  }
}

DiversiteVarietale.propTypes = {
  indicateur: PropTypes.object.isRequired,
  changeIndicateur: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default DiversiteVarietale;
