import React from "react";
import AsyncSearch from "./AsyncSearch";
import network from "../../actions/external/network";
import PropTypes from "prop-types";
import i18next from "i18next";

class ExploitationSearch extends React.Component {
  static propTypes = {
    getOptionSelected: PropTypes.func,
    getOptionLabel: PropTypes.func,
    loadAsync: PropTypes.func,
    label: PropTypes.string,
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired,
  };

  static get defaultProps() {
    return {
      getOptionSelected: (option, value) => option.idExploitation === value.idExploitation,
      getOptionLabel: (option) => (option ? option.raisonSociale : ""),
      loadAsync: async (search) => {
        const urlSearchParams = new URLSearchParams([
          ["search", search],
          ["searchFields", "raisonSociale"],
        ]);
        const { data } = await network.fetch(`/api/exploitations?${urlSearchParams}`);
        return data;
      },
      label: i18next.t("farm", "Exploitation"),
    };
  }

  render() {
    return <AsyncSearch {...this.props} freeSolo={false} />;
  }
}

export default ExploitationSearch;
