import React from "react";
import { TextField } from "@material-ui/core";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
  idUtilisateur: state.auth.idUtilisateur,
});

class ValiditeSynthese extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      estValide: null,
      sousRubriqueIndex: props.sousRubriqueIndex,
      validiteSousRubrique: props.validite,
    };
  }

  handleCommentaireAuditeur = (event) => {
    const newValidite = {
      validiteSousRubrique: {
        ...this.state.validiteSousRubrique,
        commentaireAuditeur: event.target.value,
      },
    };
    this.setState(newValidite);
    this.props.onChange(newValidite, this.state.sousRubriqueIndex);
  };

  render() {
    const { validiteSousRubrique } = this.state;

    const inputStyle = {
      multiline: true,
      fullWidth: true,
      style: {
        width: "50%",
        marginBottom: "2vh",
        marginLeft: "1%",
      },
      rows: 4,
      rowsMax: 8,
    };

    return (
      <TextField
        {...inputStyle}
        value={validiteSousRubrique?.commentaireAuditeur || ""}
        label={"Commentaire de l'auditeur"}
        onInput={this.handleCommentaireAuditeur}
      />
    );
  }
}

export default connect(mapStateToProps)(ValiditeSynthese);
