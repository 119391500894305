import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Grid, InputAdornment, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
import clsx from "clsx";
import styles from "./sousrubriquestyles.module.scss";
import ScoreRubrique from "../indicateurs/ScoreRubrique";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class PhytoSynthese extends React.Component {
  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    if (newIndicateur.codeNational === "HVE4_PHYTO_S_1100") {
      changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
    }
  };

  render() {
    const { indicateurs, computedFields } = this.props;

    const indicateursKeys = [
      "HVE4_PHYTO_S_13",
      "HVE4_PHYTO_S_20",
      "HVE4_PHYTO_S_3",
      "HVE4_PHYTO_S_41",
      "HVE4_PHYTO_S_58",
      "HVE4_PHYTO_S_65",
      "HVE4_PHYTO_S_70",
      "HVE4_PHYTO_S_840",
      "HVE4_PHYTO_S_930",
      "HVE4_PHYTO_S_1004",
    ];

    const indicateursToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateursKeys.includes(key)))
    );

    if (indicateursToDisplay.length > 0)
      return (
        <Grid container>
          <Grid item xs={7}>
            <Table
              style={{
                mr: "100px",
                tableLayout: "fixed",
              }}
              className={"my-3"}
            >
              <TableBody>
                {indicateursKeys.map(
                  (indic, i) =>
                    indicateurs[indic] && (
                      <TableRow key={i}>
                        <TableCell className={clsx(styles.tab_cell_border_top, styles.bold_content)}>
                          {indicateurs[indic]?.libelle}
                        </TableCell>
                        <TableCell className={clsx(styles.tab_cell_border_top)} align="center">
                          <DecimalPositif
                            size={"small"}
                            indicateur={indicateurs[indic]}
                            key={"indicateur-" + indicateurs[indic]?.idIndicateur}
                            changeIndicateur={this.handleChangeIndicateur}
                            valueInputProps={
                              indicateurs[indic]?.libelleUnite != null && {
                                endAdornment: (
                                  <InputAdornment position={"end"}>{indicateurs[indic]?.libelleUnite}</InputAdornment>
                                ),
                              }
                            }
                            computedFields={computedFields}
                          />
                        </TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
            <ScoreRubrique
              size={"small"}
              indicateur={indicateurs["HVE4_PHYTO_S_1100"]}
              key={"indicateur-" + indicateurs["HVE4_PHYTO_S_1100"]?.idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              computedFields={computedFields}
            />
          </Grid>
        </Grid>
      );
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(PhytoSynthese);
