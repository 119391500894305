import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import {
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Percent from "../indicateurs/Percent";
import TexteMultiligne from "../indicateurs/TexteMultiligne";
import ListeMaterielMethodeAlternative from "../indicateurs/ListeMaterielMethodeAlternative";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class PhytoMethAlt extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const indicToWatch = [
      "HVE4_PHYTO_D_60",
      "HVE4_PHYTO_D_67",
      "HVE4_PHYTO_D_61",
      "HVE4_PHYTO_I_61",
      "HVE4_PHYTO_S_61",
      "HVE4_PHYTO_D_65",
      "HVE4_PHYTO_S_63",
      "HVE4_PHYTO_D_62",
      "HVE4_PHYTO_I_62",
      "HVE4_PHYTO_S_62",
      "HVE4_PHYTO_D_66",
      "HVE4_PHYTO_S_64",
      "HVE4_PHYTO_S_65",
    ];
    return indicToWatch.some((indic) => this.props.indicateurs[indic] !== nextProps.indicateurs[indic]);
  }

  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };
  render() {
    const { indicateurs, computedFields } = this.props;

    return (
      <React.Fragment>
        <div>
          <Grid container item spacing={2} className={"d-flex align-items-center"}>
            <Grid item xs={12} className={"d-flex align-items-center mb-1"}>
              <div className={indicateurs["HVE4_PHYTO_D_60"].afficherGras ? "mr-3 bold" : "mr-3"}>
                {indicateurs["HVE4_PHYTO_D_60"].libelle}
              </div>

              <Tooltip
                title={<span style={{ whiteSpace: "pre-line" }}>{indicateurs["HVE4_PHYTO_D_60"].messageAide}</span>}
              >
                <IconButton>
                  <FontAwesomeIcon icon="info-circle" />
                </IconButton>
              </Tooltip>
              <ListeMaterielMethodeAlternative
                indicateur={indicateurs["HVE4_PHYTO_D_60"]}
                key={"indicateur-" + indicateurs["HVE4_PHYTO_D_60"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_PHYTO_D_60"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_PHYTO_D_60"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
          </Grid>
          <Grid container item spacing={2} className={"d-flex align-items-center"}>
            <Grid item>
              <div className={indicateurs["HVE4_PHYTO_D_67"].afficherGras ? "mr-3 bold" : "mr-3"}>
                {indicateurs["HVE4_PHYTO_D_67"].libelle}
              </div>
            </Grid>
            <Grid item>
              <TexteMultiligne
                size={"small"}
                indicateur={indicateurs["HVE4_PHYTO_D_67"]}
                key={"indicateur-" + indicateurs["HVE4_PHYTO_D_67"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_PHYTO_D_67"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_PHYTO_D_67"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
          </Grid>
          <Grid container className={"mt-2"}>
            <Grid item xs={11}>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow className={"d-flex justify-center"}>
                      <TableCell style={{ width: "20rem" }} />
                      <TableCell style={{ textAlign: "center" }}>Surface avec méthodes alternatives</TableCell>
                      <TableCell style={{ textAlign: "center" }}>Part des surfaces concernées</TableCell>
                      <TableCell style={{ textAlign: "center" }}>Points intermédiaires</TableCell>
                      <TableCell style={{ textAlign: "center" }}>Pondération par rapport à la SAU</TableCell>
                      <TableCell style={{ textAlign: "center" }}>Points</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={1}>
                      <TableCell style={{ maxWidth: "10rem" }}>{indicateurs["HVE4_PHYTO_D_61"].libelle}</TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          error={
                            indicateurs["HVE4_PHYTO_D_64"].valeur.value < indicateurs["HVE4_PHYTO_D_61"].valeur.value
                          }
                          helperText={
                            indicateurs["HVE4_PHYTO_D_64"].valeur.value < indicateurs["HVE4_PHYTO_D_61"].valeur.value
                              ? "La surface saisie doit être inférieure à la surface hors horticulture"
                              : ""
                          }
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_D_61"]}
                          displayRules={{ displayValeurMP: "column" }}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_D_61"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_D_61"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_D_61"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <Percent
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_I_61"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_I_61"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_I_61"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_I_61"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_S_61"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_S_61"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_S_61"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_S_61"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <Percent
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_D_65"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_D_65"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_D_65"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_D_65"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_S_63"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_S_63"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_S_63"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_S_63"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow key={2}>
                      <TableCell style={{ maxWidth: "10rem" }}>{indicateurs["HVE4_PHYTO_D_62"].libelle}</TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          error={
                            (indicateurs["HVE4_CULT_D_70"]?.valeur?.value ?? 0) <
                            (indicateurs["HVE4_PHYTO_D_62"]?.valeur?.value ?? 0)
                          }
                          helperText={
                            (indicateurs["HVE4_CULT_D_70"]?.valeur?.value ?? 0) <
                            (indicateurs["HVE4_PHYTO_D_62"]?.valeur?.value ?? 0)
                              ? "La surface saisie doit être inférieure à la surface horticulture"
                              : ""
                          }
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_D_62"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_D_62"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_D_62"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_D_62"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <Percent
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_I_62"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_I_62"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_I_62"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_I_62"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_S_62"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_S_62"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_S_62"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_S_62"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <Percent
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_D_66"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_D_66"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_D_66"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_D_66"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_S_64"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_S_64"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_S_64"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_S_64"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow key={3} className={"total-point"}>
                      <TableCell colSpan={5} style={{ textAlign: "right" }}>
                        {indicateurs["HVE4_PHYTO_S_65"].libelle}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_S_65"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_S_65"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_S_65"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_S_65"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(PhytoMethAlt);
