export const RECAPTCHA_SITE_KEY = "6Lcga8oZAAAAAOQwdKr5AYGOE821UeupJRpY9kqK";

export const roles = {
  SUPERADMIN: "SUPERADMIN",
  ADMIN_ORGANISME: "ADMIN_ORGANISME",
  CONSEILLER: "CONSEILLER",
  AGRICULTEUR: "AGRICULTEUR",
};

export const procedureStateCodes = {
  SAISIE_EN_COURS: "SAISIE_EN_COURS",
  ATTENTE_VERIFICATION: "ATTENTE_VERIFICATION",
  VERIFICATION_EN_COURS: "VERIFICATION_EN_COURS",
  ATTENTE_CORRECTION: "ATTENTE_CORRECTION",
  VALIDE: "VALIDE",
  CERTIFIABLE: "CERTIFIABLE",
  NON_CERTIFIABLE: "NON_CERTIFIABLE",
};

export const procedureStates = Object.entries(procedureStateCodes).map(([key, value]) => ({
  libelle: value.toString().toLowerCase().replaceAll("_", " "),
  code: key,
}));

export const typesDocumentsCodes = {
  CONTRAT_ORGANISME: "CONTRAT_ORGANISME",
  FICHE_ANNUAIRE: "FICHE_ANNUAIRE",
  CERTIFICATION_EQUIVALENTE: "CERTIFICATION_EQUIVALENTE",
  CONTRAT_STRUCTURE_COLLECTIVE: "CONTRAT_STRUCTURE_COLLECTIVE",
};

const exports = {
  RECAPTCHA_SITE_KEY,
  roles,
  procedureStateCodes,
  typesDocumentsCodes,
  procedureStates,
};
export default exports;
