import React from "react";
import { TextField } from "@material-ui/core";
import { TextField as FMTextField } from "formik-material-ui";
import validators from "./validators";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

function componentFunction(props) {
  const { inputRef, onChange, onFocus, numberOfDecimals, allowNegative, thousandSeparator, ...other } = props;
  return (
    <NumberFormat
      {...other}
      onFocus={onFocus}
      getInputRef={inputRef}
      onValueChange={(values) => {
        if (onChange) {
          onChange(values.value);
        }
      }}
      decimalSeparator={"."}
      thousandSeparator={thousandSeparator != null ? thousandSeparator : " "}
      decimalScale={numberOfDecimals}
      allowNegative={allowNegative}
      allowLeadingZeros={false}
    />
  );
}

class NumberInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      touched: false,
    };
  }

  validate = (value) => {
    let errors = "";
    if (validators.isNumber(value)) {
      errors += "Doit être un nombre. ";
    }
    if (validators.hasDecimals(value, this.props.numberOfDecimals)) {
      errors += "Doit avoir le bon nombre de décimales. ";
    }
    if (!this.props.allowNegative && value < 0) {
      errors += "Ne doit pas être négatif. ";
    }
    return errors;
  };

  render() {
    const {
        allowNegative,
        numberOfDecimals,
        onChange,
        onBlur,
        form,
        field,
        value,
        onFocus,
        error,
        helperText,
        thousandSeparator,
        InputProps,
        ...otherProps
      } = this.props,
      { touched } = this.state;
    if (form && field) {
      return <FMTextField component={TextField} name={field.name} validate={this.validate} {...otherProps} />;
    } else {
      return (
        <TextField
          value={value}
          onChange={(value) => {
            this.setState({ touched: true });
            if (onChange) {
              onChange(value);
            }
          }}
          size={this.props.size}
          onFocus={onFocus}
          {...otherProps}
          error={error}
          helperText={touched || error ? helperText : ""}
          InputProps={{
            inputComponent: componentFunction,
            value: parseFloat(value),
            inputProps: {
              numberOfDecimals: numberOfDecimals,
              allowNegative: allowNegative,
              thousandSeparator: thousandSeparator,
            },
            ...InputProps,
          }}
        />
      );
    }
  }
}

NumberInput.propTypes = {
  numberOfDecimals: PropTypes.number,
  allowNegative: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};
NumberInput.defaultProps = {
  numberOfDecimals: 4,
  allowNegative: false,
  value: "",
  onFocus: (event) => event.target.select(),
};
export default NumberInput;
