import React from "react";
import DOMPurify from "dompurify";
import propTypes from "prop-types";
import {
  Badge,
  ClickAwayListener,
  Grid,
  IconButton,
  Paper,
  Popper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { roles } from "../../../common/codes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import NumberInput from "../../../common/NumberInput";
import * as moment from "moment";
import { withStyles } from "@material-ui/styles";
import clsx from "clsx";

const CustomNumberInput = withStyles((theme) => ({
  root: {
    "& .MuiFormLabel-root": {
      fontSize: "0.7em",
      textOverflow: "ellipsis",
    },
  },
}))(NumberInput);

const ToggleButtonNonValide = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    "&$selected": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
    },
    "&$selected:hover": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.dark,
    },
  },
  selected: {},
}))(ToggleButton);

const ToggleButtonValide = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    "&$selected": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.main,
    },
    "&$selected:hover": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.dark,
    },
  },
  selected: {},
}))(ToggleButton);

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
  idUtilisateur: state.auth.idUtilisateur,
});

class Indicator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.props.indicateur !== nextProps.indicateur || nextState.anchorEl !== this.state.anchorEl;
  }

  handleCommentaireAuditeur = (event) => {
    const newIndicateur = {
      ...this.props.indicateur,
      commentaireAuditeur: event.target.value,
    };
    this.props.onChange(newIndicateur);
  };

  handleEstValide = (event, value) => {
    const newIndicateur = {
      ...this.props.indicateur,
      estValide: value,
      valideDate: moment().format("YYYY-MM-DD"),
      valideIdUtilisateur: this.props.idUtilisateur,
    };
    this.props.onChange(newIndicateur);
  };

  handleChangeNote = (newNote) => {
    const newIndicateur = {
      ...this.props.indicateur,
      valeur: {
        ...this.props.indicateur.valeur,
        note: newNote === "" ? null : Number(newNote),
      },
    };
    this.props.onChange(newIndicateur);
  };

  handleOpenPopper = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClosePopper = () => {
    this.setState({ anchorEl: null });
  };

  filterNullChildren = (children) => React.Children.toArray(children).filter(Boolean);

  render() {
    const {
        indicateur: {
          libelle,
          codeNational,
          commentaireAuditeur,
          estValide,
          afficherGras,
          estAvecScoring,
          valeur,
          saisieScoreDirecte,
          messageAide,
          visible,
          jsonSchema: { $id },
        },
        children,
        userRole,
        alignScoreToTheEnd,
        showValidite,
        showCommentaire,
      } = this.props,
      maxScore = !saisieScoreDirecte ? null : Number($id.replace(/saisieDirecte/g, "")),
      { anchorEl } = this.state,
      myArray = this.filterNullChildren(children);

    if (!visible) {
      return null;
    }

    return (
      <Grid container spacing={3} className={"align-items-center"}>
        <Grid item xs={saisieScoreDirecte ? 6 : 4} className={"d-flex flex-column"}>
          <Typography
            style={{ fontWeight: afficherGras ? "bold" : "normal" }}
            className={clsx({ "ml-1": $id !== "scoring" })}
          >
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(libelle, { ALLOWED_TAGS: ["strong"] }) }} />
          </Typography>
        </Grid>

        {myArray.length > 0 && (
          <Grid
            item
            xs
            style={{
              display: "flex",
              justifyContent: "center",
              flex: "1 auto",
            }}
            className={clsx({ "w-100": codeNational === "HVE3A_I_21" })}
          >
            {children}
          </Grid>
        )}

        {messageAide ? (
          <Grid item xs={1} md={1}>
            <Tooltip title={<span style={{ whiteSpace: "pre-line" }}>{messageAide}</span>}>
              <IconButton>
                <FontAwesomeIcon icon="info-circle" />
              </IconButton>
            </Tooltip>
          </Grid>
        ) : (
          <Grid item xs />
        )}

        {userRole !== roles.AGRICULTEUR && (
          <React.Fragment>
            {alignScoreToTheEnd && <Grid item xs />}
            {estAvecScoring && (
              <Grid item xs={2} md={1}>
                <CustomNumberInput
                  fullWidth={false}
                  style={{ maxWidth: "4rem" }}
                  value={valeur.note === 0 || !!valeur.note ? valeur.note : ""}
                  label={"Scoring HVE"}
                  disabled={!saisieScoreDirecte}
                  inputProps={{
                    max: maxScore || 9999,
                    min: 0,
                  }}
                  onChange={this.handleChangeNote}
                  numberOfDecimals={2}
                />
              </Grid>
            )}
            <Grid item xs={3} className={"d-flex"}>
              {showValidite && (
                <ToggleButtonGroup
                  size={"small"}
                  value={estValide}
                  onChange={this.handleEstValide}
                  aria-label="est valide"
                  exclusive
                >
                  <ToggleButtonNonValide value={false} style={{ flexShrink: 0 }}>
                    Non-valide
                  </ToggleButtonNonValide>
                  <ToggleButtonValide value={true}>Valide</ToggleButtonValide>
                </ToggleButtonGroup>
              )}
              {showCommentaire && (
                <div className={"ml-1"}>
                  <Badge color="primary" variant="dot" invisible={!commentaireAuditeur} overlap={"circle"}>
                    <Tooltip title={"ouvrir les commentaires"} placement={"left"}>
                      <IconButton onClick={this.handleOpenPopper}>
                        <FontAwesomeIcon icon={"comment-alt"} />
                      </IconButton>
                    </Tooltip>
                  </Badge>
                  <Popper anchorEl={anchorEl} open={!!anchorEl} style={{ zIndex: 2 }}>
                    <ClickAwayListener onClickAway={this.handleClosePopper}>
                      <Paper>
                        <TextField
                          value={commentaireAuditeur}
                          label={"Commentaire auditeur"}
                          onChange={this.handleCommentaireAuditeur}
                          onBlur={this.handleClosePopper}
                          multiline
                          style={{
                            width: "20rem",
                          }}
                        />
                      </Paper>
                    </ClickAwayListener>
                  </Popper>
                </div>
              )}
            </Grid>
          </React.Fragment>
        )}
      </Grid>
    );
  }
}

Indicator.propTypes = {
  indicateur: propTypes.shape({
    libelle: propTypes.string.isRequired,
    valeur: propTypes.oneOfType([propTypes.object, propTypes.number, propTypes.string, propTypes.bool]),
    commentaireAuditeur: propTypes.string,
    estValide: propTypes.bool,
  }),
  onChange: propTypes.func.isRequired,
  children: propTypes.oneOfType([
    propTypes.element,
    propTypes.arrayOf(propTypes.oneOfType([propTypes.element, propTypes.bool])),
  ]),
  alignScoreToTheEnd: propTypes.bool.isRequired,
};

Indicator.defaultProps = {
  alignScoreToTheEnd: false,
  showValidite: true,
  showCommentaire: true,
};

export default connect(mapStateToProps)(Indicator);
