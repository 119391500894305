import React from "react";
import NumberInput from "../../../../../common/NumberInput";
import Indicator from "../../Indicator";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import HVE3AStatic from "../../HVE3A/HVE3AStatic";
import { InputAdornment } from "@material-ui/core";

class NumeriqueEntier extends React.Component {
  render() {
    const {
      t,
      indicateur,
      onChangeIndicator,
      sousRubriqueIndex,
      libelleEnglobantIndex,
      changeIndicateur,
      valueInputProps,
      computedFields,
    } = this.props;

    const valeurCalculee = (
      <NumberInput
        fullWidth={false}
        style={{
          maxWidth: "6rem",
        }}
        InputProps={valueInputProps}
        disabled
        value={indicateur.valeurCalculee?.value ?? ""}
        label={t("hve3a.valeur-mesparcelles", "Valeur MesParcelles")}
        numberOfDecimals={2}
        className={"mr-2"}
      />
    );

    const valueRendered = (
      <React.Fragment>
        <NumberInput
          fullWidth={false}
          value={indicateur.valeur.value ?? ""}
          style={{
            fontWeight: indicateur.afficherGras ? "bold" : "normal",
            maxWidth: "6rem",
          }}
          InputProps={valueInputProps}
          disabled={computedFields.includes(indicateur.codeNational)}
          numberOfDecimals={2}
          onChange={(newValeur) =>
            changeIndicateur(
              { ...indicateur, valeur: { value: newValeur === "" ? null : Number(newValeur) } },
              sousRubriqueIndex,
              libelleEnglobantIndex
            )
          }
        />
        <span
          style={{
            width: "6rem",
            textAlign: "center",
            alignSelf: "center",
          }}
          className={"mx-1"}
        >
          x {HVE3AStatic.coeffSET[indicateur.codeNational]} =
        </span>
        <NumberInput
          fullWidth={false}
          value={indicateur.valeur.value_SET ?? ""}
          disabled
          style={{ maxWidth: "7rem" }}
          numberOfDecimals={3}
          InputProps={{
            endAdornment: <InputAdornment position={"end"}>Ha SET</InputAdornment>,
          }}
        />
      </React.Fragment>
    );

    return (
      <Indicator indicateur={indicateur} onChange={onChangeIndicator}>
        {!!indicateur.valeurCalculee && valeurCalculee}
        {valueRendered}
      </Indicator>
    );
  }
}

export default compose(withTranslation())(NumeriqueEntier);
