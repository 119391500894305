import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import {
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
import clsx from "clsx";
import styles from "./sousrubriquestyles.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import withTheme from "@material-ui/styles/withTheme";
import Boolean from "../indicateurs/Boolean";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class BiodivIAE extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const indicToWatch = [
      "HVE4_BIODIV_D_1",
      "HVE4_BIODIV_D_2",
      "HVE4_BIODIV_D_3",
      "HVE4_BIODIV_D_4",
      "HVE4_BIODIV_D_5",
      "HVE4_BIODIV_D_6",
      "HVE4_BIODIV_D_7",
      "HVE4_BIODIV_D_8",
      "HVE4_BIODIV_D_9",
      "HVE4_BIODIV_D_10",
      "HVE4_BIODIV_D_11",
      "HVE4_BIODIV_D_12",
      "HVE4_BIODIV_D_13",
      "HVE4_BIODIV_D_14",
      "HVE4_BIODIV_D_15",
      "HVE4_BIODIV_D_16",
      "HVE4_BIODIV_D_17",
      "HVE4_BIODIV_D_18",
      "HVE4_BIODIV_D_19",
      "HVE4_BIODIV_D_20",
      "HVE4_BIODIV_D_21",
      "HVE4_BIODIV_D_22",
      "HVE4_BIODIV_D_220",
      "HVE4_BIODIV_D_23",
      "HVE4_BIODIV_D_24",
      "HVE4_BIODIV_D_25",
      "HVE4_BIODIV_D_26",
      "HVE4_BIODIV_D_27",
      "HVE4_BIODIV_D_28",
      "HVE4_BIODIV_D_29",
      "HVE4_BIODIV_D_30",
      "HVE4_BIODIV_D_31",
      "HVE4_BIODIV_D_293031",
      "HVE4_BIODIV_D_32",
      "HVE4_BIODIV_D_33",
      "HVE4_BIODIV_D_322933",
      "HVE4_BIODIV_D_1100",
      "HVE4_BIODIV_D_2300",
      "HVE4_BIODIV_D_2400",
      "HVE4_BIODIV_D_2500",
      "HVE4_BIODIV_D_2600",
      "HVE4_BIODIV_D_1200",
      "HVE4_BIODIV_D_2800",
      "HVE4_BIODIV_D_1400",
      "HVE4_BIODIV_D_1600",
      "HVE4_BIODIV_D_1700",
      "HVE4_BIODIV_D_1300",
      "HVE4_BIODIV_D_2700",
      "HVE4_BIODIV_D_1800",
      "HVE4_BIODIV_D_1900",
      "HVE4_BIODIV_D_2000",
      "HVE4_BIODIV_D_2100",
      "HVE4_BIODIV_D_2200",
      "HVE4_BIODIV_D_22000",
      "HVE4_BIODIV_D_100",
      "HVE4_BIODIV_D_200",
      "HVE4_BIODIV_D_300",
      "HVE4_BIODIV_D_400",
      "HVE4_BIODIV_D_500",
      "HVE4_BIODIV_D_600",
      "HVE4_BIODIV_D_700",
      "HVE4_BIODIV_D_800",
      "HVE4_BIODIV_D_900",
      "HVE4_BIODIV_D_1000",
      "HVE4_BIODIV_D_34",
      "HVE4_BIODIV_D_35",
      "HVE4_BIODIV_I_36",
      "HVE4_BIODIV_S_36",
      "HVE4_BIODIV_I_37",
      "HVE4_BIODIV_I_38",
      "HVE4_BIODIV_S_37",
      "HVE4_BIODIV_S_38",
      "HVE4_BIODIV_D_3800",
      "HVE4_BIODIV_D_39",
    ];
    return (
      indicToWatch.some((indic) => this.props.indicateurs[indic] !== nextProps.indicateurs[indic]) ||
      this.props !== nextProps
    );
  }

  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };

  renderMainHeader = () => {
    const { theme } = this.props;
    return (
      <TableHead>
        <TableRow>
          <TableCell
            className={
              theme.palette.type === "dark"
                ? clsx(styles.blank_background_dark, styles.tab_cell_border_right_dark)
                : clsx(styles.blank_background_light, styles.tab_cell_border_right_light)
            }
            colSpan={2}
          ></TableCell>
          <TableCell
            className={
              theme.palette.type === "dark"
                ? clsx(styles.tab_cell_border_top_dark)
                : clsx(styles.tab_cell_border_top_light)
            }
            colSpan={4}
            align="center"
          >
            Surface et linéaires réels
          </TableCell>
          <TableCell
            className={
              theme.palette.type === "dark"
                ? clsx(styles.iae_separation_cell_dark, styles.tab_cell_border_bottom_none)
                : clsx(styles.iae_separation_cell_light, styles.tab_cell_border_bottom_none)
            }
          ></TableCell>
          <TableCell
            className={
              theme.palette.type === "dark"
                ? clsx(styles.tab_cell_border_top_dark)
                : clsx(styles.tab_cell_border_top_light)
            }
            colSpan={2}
            align="center"
          >
            Conversion en hectare
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell align="center" colSpan={2}>
            Élément
          </TableCell>
          <TableCell align="center">Critère obligatoire IAE sur terre arable</TableCell>
          <TableCell align="center">Critère poids des IAE : IAE sur surface de l'exploitation</TableCell>
          <TableCell align="center">Unité</TableCell>
          <TableCell align="center">Coefficient d'équivalence en HA</TableCell>
          <TableCell
            className={
              theme.palette.type === "dark"
                ? clsx(styles.iae_separation_cell_dark)
                : clsx(styles.iae_separation_cell_light)
            }
          ></TableCell>
          <TableCell align="center">Critère obligatoire IAE sur terre arable</TableCell>
          <TableCell align="center">Critère poids des IAE : IAE sur surface de l'exploitation</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  renderObligationIAE(indicateurs, computedFields) {
    return (
      <div>
        {indicateurs["HVE4_BIODIV_D_29"].valeur.value < 10 ||
        indicateurs["HVE4_BIODIV_D_30"].valeur.value > 75 ||
        indicateurs["HVE4_BIODIV_D_31"].valeur.value > 75 ? (
          <div className={"d-flex justify-center mb-2 mt-2 bold"}>
            Votre exploitation n'est pas soumise à l'obligation des 4% d'IAE
          </div>
        ) : (
          <React.Fragment>
            <div className={"d-flex justify-center mb-2 mt-2 bold"}>
              Votre exploitation est soumise à l'obligation des 4% d'IAE
            </div>
            <div className={"d-flex align-items-center mb-1"}>
              <Grid item xs={4}>
                <div className={indicateurs["HVE4_BIODIV_D_32"].afficherGras ? "mr-3 bold" : "mr-3"}>
                  {indicateurs["HVE4_BIODIV_D_32"].libelle}
                </div>
              </Grid>
              <Grid item xs={8}>
                <DecimalPositif
                  size={"small"}
                  indicateur={indicateurs["HVE4_BIODIV_D_32"]}
                  key={"indicateur-" + indicateurs["HVE4_BIODIV_D_32"].idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indicateurs["HVE4_BIODIV_D_32"].libelleUnite != null && {
                      endAdornment: (
                        <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_32"].libelleUnite}</InputAdornment>
                      ),
                    }
                  }
                  computedFields={computedFields}
                />
              </Grid>
            </div>
            <div className={"d-flex align-items-center mb-1"}>
              <Grid item xs={4}>
                <div className={indicateurs["HVE4_BIODIV_D_29"].afficherGras ? "mr-3 bold" : "mr-3"}>
                  {indicateurs["HVE4_BIODIV_D_29"].libelle}
                </div>
              </Grid>
              <Grid item xs={8}>
                <DecimalPositif
                  size={"small"}
                  indicateur={indicateurs["HVE4_BIODIV_D_29"]}
                  key={"indicateur-" + indicateurs["HVE4_BIODIV_D_29"].idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indicateurs["HVE4_BIODIV_D_29"].libelleUnite != null && {
                      endAdornment: (
                        <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_29"].libelleUnite}</InputAdornment>
                      ),
                    }
                  }
                  computedFields={computedFields}
                />
              </Grid>
            </div>
            <div className={"d-flex align-items-center mb-1"}>
              <Grid item xs={4}>
                <div className={indicateurs["HVE4_BIODIV_D_33"].afficherGras ? "mr-3 bold" : "mr-3"}>
                  {indicateurs["HVE4_BIODIV_D_33"].libelle}
                </div>
              </Grid>
              <Grid item xs={8} className={"d-flex"}>
                <DecimalPositif
                  size={"small"}
                  indicateur={indicateurs["HVE4_BIODIV_D_33"]}
                  key={"indicateur-" + indicateurs["HVE4_BIODIV_D_33"].idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indicateurs["HVE4_BIODIV_D_33"].libelleUnite != null && {
                      endAdornment: (
                        <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_33"].libelleUnite}</InputAdornment>
                      ),
                    }
                  }
                  error={
                    indicateurs["HVE4_BIODIV_D_33"]?.valeur?.value != null &&
                    indicateurs["HVE4_BIODIV_D_33"]?.valeur?.value < 0.04
                  }
                  computedFields={computedFields}
                />
                {indicateurs["HVE4_BIODIV_D_33"]?.valeur?.value != null &&
                  indicateurs["HVE4_BIODIV_D_33"]?.valeur?.value < 0.04 && (
                    <div className={"ml-2 bold"} style={{ color: "#ff0000" }}>
                      Vous ne respectez pas le seuil de 4%
                    </div>
                  )}
              </Grid>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }

  renderScoringIAE(indicateurs, computedFields) {
    return (
      <div className={"mb-3 mt-3"}>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid item xs={4} className={"bold"}>
            {indicateurs["HVE4_BIODIV_D_34"].libelle}
          </Grid>
          <Grid item xs={2}>
            <DecimalPositif
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_D_34"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_34"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_34"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_34"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
          <Grid item xs={4}></Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid item xs={4} className={"bold"}>
            {indicateurs["HVE4_BIODIV_D_35"].libelle}
          </Grid>
          <Grid item xs={2}>
            <DecimalPositif
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_D_35"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_35"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_35"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_35"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
          <Grid item xs={4} className={"bold"}>
            Points
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid item xs={4} className={"bold"}>
            {indicateurs["HVE4_BIODIV_I_36"].libelle}
          </Grid>
          <Grid item xs={2}>
            <DecimalPositif
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_I_36"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_I_36"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_I_36"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_I_36"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
          <Grid item xs={4}>
            <DecimalPositif
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_S_36"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_S_36"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_S_36"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_S_36"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid item xs={4} className={"bold"}>
            {indicateurs["HVE4_BIODIV_I_37"].libelle}
          </Grid>
          <Grid item xs={2}>
            <DecimalPositif
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_I_37"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_I_37"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_I_37"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_I_37"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
          <Grid item xs={4}>
            <DecimalPositif
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_S_37"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_S_37"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_S_37"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_S_37"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid item xs={6} className={"bold"}>
            {indicateurs["HVE4_BIODIV_S_38"].libelle}
          </Grid>
          <Grid item xs={4} className={"total-point"}>
            <DecimalPositif
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_S_38"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_S_38"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_S_38"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_S_38"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid item xs={5}>
            {indicateurs["HVE4_BIODIV_D_39"].libelle}
          </Grid>
          <Boolean
            indicateur={indicateurs["HVE4_BIODIV_D_39"]}
            key={"indicateur-" + indicateurs["HVE4_BIODIV_D_39"].idIndicateur}
            changeIndicateur={this.handleChangeIndicateur}
            valueInputProps={
              indicateurs["HVE4_BIODIV_D_39"].libelleUnite != null && {
                endAdornment: (
                  <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_39"].libelleUnite}</InputAdornment>
                ),
              }
            }
            computedFields={computedFields}
          />
        </div>
      </div>
    );
  }

  render() {
    const { indicateurs, computedFields, theme } = this.props;
    return (
      <Grid container spacing={2} className={"w-100"}>
        <Grid item sm={12}>
          <Table
            style={{ mr: "100px", width: "auto" }}
            className={clsx({ "mb-3": this.props.userRole !== "AGRICULTEUR" })}
          >
            {this.renderMainHeader()}
            <TableBody>
              {/*Haie*/}
              <TableRow>
                <TableCell>Haie</TableCell>
                <TableCell>
                  <Tooltip
                    title={<span style={{ whiteSpace: "pre-line" }}>{indicateurs["HVE4_BIODIV_D_6"].messageAide}</span>}
                  >
                    <IconButton>
                      <FontAwesomeIcon icon="info-circle" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    displayRules={{ displayValeurMP: "column" }}
                    indicateur={indicateurs["HVE4_BIODIV_D_6"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_6"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_6"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_6"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    displayRules={{ displayValeurMP: "column" }}
                    indicateur={indicateurs["HVE4_BIODIV_D_23"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_23"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_23"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_23"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">m linéaire</TableCell>
                <TableCell align="center">x 0.002</TableCell>
                <TableCell
                  className={
                    theme.palette.type === "dark"
                      ? clsx(styles.iae_separation_cell_dark, styles.tab_cell_border_bottom_none)
                      : clsx(styles.iae_separation_cell_light, styles.tab_cell_border_bottom_none)
                  }
                ></TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_D_600"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_600"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_600"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_600"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_D_2300"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_2300"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_2300"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_2300"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
              </TableRow>
              {/*Alignement d'arbres*/}
              <TableRow>
                <TableCell>Alignement d'arbres</TableCell>
                <TableCell>
                  <Tooltip
                    title={<span style={{ whiteSpace: "pre-line" }}>{indicateurs["HVE4_BIODIV_D_7"].messageAide}</span>}
                  >
                    <IconButton>
                      <FontAwesomeIcon icon="info-circle" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    displayRules={{ displayValeurMP: "column" }}
                    indicateur={indicateurs["HVE4_BIODIV_D_7"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_7"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_7"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_7"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    displayRules={{ displayValeurMP: "column" }}
                    indicateur={indicateurs["HVE4_BIODIV_D_24"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_24"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_24"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_24"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">m linéaire</TableCell>
                <TableCell align="center">x 0.001</TableCell>
                <TableCell
                  className={
                    theme.palette.type === "dark"
                      ? clsx(styles.iae_separation_cell_dark, styles.tab_cell_border_bottom_none)
                      : clsx(styles.iae_separation_cell_light, styles.tab_cell_border_bottom_none)
                  }
                ></TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_D_700"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_700"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_700"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_700"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_D_2400"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_2400"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_2400"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_2400"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
              </TableRow>
              {/*Arbre isolé*/}
              <TableRow>
                <TableCell>Arbre isolé</TableCell>
                <TableCell>
                  <Tooltip
                    title={<span style={{ whiteSpace: "pre-line" }}>{indicateurs["HVE4_BIODIV_D_8"].messageAide}</span>}
                  >
                    <IconButton>
                      <FontAwesomeIcon icon="info-circle" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    displayRules={{ displayValeurMP: "column" }}
                    indicateur={indicateurs["HVE4_BIODIV_D_8"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_8"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_8"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_8"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    displayRules={{ displayValeurMP: "column" }}
                    indicateur={indicateurs["HVE4_BIODIV_D_25"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_25"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_25"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_25"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">arbre</TableCell>
                <TableCell align="center">x 0.003</TableCell>
                <TableCell
                  className={
                    theme.palette.type === "dark"
                      ? clsx(styles.iae_separation_cell_dark, styles.tab_cell_border_bottom_none)
                      : clsx(styles.iae_separation_cell_light, styles.tab_cell_border_bottom_none)
                  }
                ></TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_D_800"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_800"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_800"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_800"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_D_2500"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_2500"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_2500"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_2500"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
              </TableRow>
              {/*Bosquets*/}
              <TableRow>
                <TableCell>Bosquets</TableCell>
                <TableCell>
                  <Tooltip
                    title={<span style={{ whiteSpace: "pre-line" }}>{indicateurs["HVE4_BIODIV_D_9"].messageAide}</span>}
                  >
                    <IconButton>
                      <FontAwesomeIcon icon="info-circle" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    displayRules={{ displayValeurMP: "column" }}
                    indicateur={indicateurs["HVE4_BIODIV_D_9"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_9"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_9"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_9"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    displayRules={{ displayValeurMP: "column" }}
                    indicateur={indicateurs["HVE4_BIODIV_D_26"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_26"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_26"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_26"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">hectare</TableCell>
                <TableCell align="center">x 1.5</TableCell>
                <TableCell
                  className={
                    theme.palette.type === "dark"
                      ? clsx(styles.iae_separation_cell_dark, styles.tab_cell_border_bottom_none)
                      : clsx(styles.iae_separation_cell_light, styles.tab_cell_border_bottom_none)
                  }
                ></TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_D_900"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_900"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_900"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_900"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_D_2600"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_2600"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_2600"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_2600"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
              </TableRow>
              {/*Mare*/}
              <TableRow>
                <TableCell>Mare</TableCell>
                <TableCell>
                  <Tooltip
                    title={<span style={{ whiteSpace: "pre-line" }}>{indicateurs["HVE4_BIODIV_D_1"].messageAide}</span>}
                  >
                    <IconButton>
                      <FontAwesomeIcon icon="info-circle" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    displayRules={{ displayValeurMP: "column" }}
                    indicateur={indicateurs["HVE4_BIODIV_D_1"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_1"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_1"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_1"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    displayRules={{ displayValeurMP: "column" }}
                    indicateur={indicateurs["HVE4_BIODIV_D_11"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_11"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_11"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_11"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">hectare</TableCell>
                <TableCell align="center">x 1.5</TableCell>
                <TableCell
                  className={
                    theme.palette.type === "dark"
                      ? clsx(styles.iae_separation_cell_dark, styles.tab_cell_border_bottom_none)
                      : clsx(styles.iae_separation_cell_light, styles.tab_cell_border_bottom_none)
                  }
                ></TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_D_100"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_100"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_100"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_100"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_D_1100"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_1100"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_1100"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_1100"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
              </TableRow>
              {/*Fossé non maçonné*/}
              <TableRow>
                <TableCell>Fossé non maçonné</TableCell>
                <TableCell>
                  <Tooltip
                    title={<span style={{ whiteSpace: "pre-line" }}>{indicateurs["HVE4_BIODIV_D_2"].messageAide}</span>}
                  >
                    <IconButton>
                      <FontAwesomeIcon icon="info-circle" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    displayRules={{ displayValeurMP: "column" }}
                    indicateur={indicateurs["HVE4_BIODIV_D_2"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_2"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_2"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_2"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    displayRules={{ displayValeurMP: "column" }}
                    indicateur={indicateurs["HVE4_BIODIV_D_12"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_12"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_12"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_12"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">m linéaire</TableCell>
                <TableCell align="center">x 0.001</TableCell>
                <TableCell
                  className={
                    theme.palette.type === "dark"
                      ? clsx(styles.iae_separation_cell_dark, styles.tab_cell_border_bottom_none)
                      : clsx(styles.iae_separation_cell_light, styles.tab_cell_border_bottom_none)
                  }
                ></TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_D_200"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_200"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_200"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_200"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_D_1200"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_1200"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_1200"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_1200"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
              </TableRow>
              {/*Mur traditionnel en pierres*/}
              <TableRow>
                <TableCell>Mur traditionnel en pierres</TableCell>
                <TableCell>
                  <Tooltip
                    title={
                      <span style={{ whiteSpace: "pre-line" }}>{indicateurs["HVE4_BIODIV_D_10"].messageAide}</span>
                    }
                  >
                    <IconButton>
                      <FontAwesomeIcon icon="info-circle" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    displayRules={{ displayValeurMP: "column" }}
                    indicateur={indicateurs["HVE4_BIODIV_D_10"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_10"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_10"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_10"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    displayRules={{ displayValeurMP: "column" }}
                    indicateur={indicateurs["HVE4_BIODIV_D_28"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_28"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_28"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_28"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">m linéaire</TableCell>
                <TableCell align="center">x 0.0001</TableCell>
                <TableCell
                  className={
                    theme.palette.type === "dark"
                      ? clsx(styles.iae_separation_cell_dark, styles.tab_cell_border_bottom_none)
                      : clsx(styles.iae_separation_cell_light)
                  }
                ></TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_D_1000"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_1000"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_1000"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_1000"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_D_2800"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_2800"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_2800"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_2800"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
              </TableRow>
              {/*Bordures non productives - critères obligatoire*/}
              <TableRow>
                <TableCell>Bordures non productives - critères obligatoire</TableCell>
                <TableCell>
                  <Tooltip
                    title={<span style={{ whiteSpace: "pre-line" }}>{indicateurs["HVE4_BIODIV_D_3"].messageAide}</span>}
                  >
                    <IconButton>
                      <FontAwesomeIcon icon="info-circle" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    displayRules={{ displayValeurMP: "column" }}
                    indicateur={indicateurs["HVE4_BIODIV_D_3"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_3"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_3"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_3"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>

                <TableCell align="center"></TableCell>
                <TableCell align="center">m linéaire</TableCell>
                <TableCell align="center">x 0.0009</TableCell>
                <TableCell
                  className={
                    theme.palette.type === "dark"
                      ? clsx(styles.iae_separation_cell_dark, styles.tab_cell_border_bottom_none)
                      : clsx(styles.iae_separation_cell_light, styles.tab_cell_border_bottom_none)
                  }
                ></TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_D_300"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_300"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_300"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_300"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
              {/*Bordures non productives - critères poids des IAE*/}
              <TableRow>
                <TableCell>Bordures non productives - critères poids des IAE</TableCell>
                <TableCell>
                  <Tooltip
                    title={
                      <span style={{ whiteSpace: "pre-line" }}>{indicateurs["HVE4_BIODIV_D_14"].messageAide}</span>
                    }
                  >
                    <IconButton>
                      <FontAwesomeIcon icon="info-circle" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    displayRules={{ displayValeurMP: "column" }}
                    indicateur={indicateurs["HVE4_BIODIV_D_14"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_14"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_14"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_14"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>

                <TableCell align="center">m linéaire</TableCell>
                <TableCell align="center">x 0.0009</TableCell>
                <TableCell
                  className={
                    theme.palette.type === "dark"
                      ? clsx(styles.iae_separation_cell_dark, styles.tab_cell_border_bottom_none)
                      : clsx(styles.iae_separation_cell_light, styles.tab_cell_border_bottom_none)
                  }
                ></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_D_1400"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_1400"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_1400"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_1400"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
              </TableRow>
              {/*Jachère*/}
              <TableRow>
                <TableCell>Jachère</TableCell>
                <TableCell>
                  <Tooltip
                    title={<span style={{ whiteSpace: "pre-line" }}>{indicateurs["HVE4_BIODIV_D_4"].messageAide}</span>}
                  >
                    <IconButton>
                      <FontAwesomeIcon icon="info-circle" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    displayRules={{ displayValeurMP: "column" }}
                    indicateur={indicateurs["HVE4_BIODIV_D_4"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_4"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_4"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_4"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>

                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    displayRules={{ displayValeurMP: "column" }}
                    indicateur={indicateurs["HVE4_BIODIV_D_16"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_16"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_16"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_16"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">hectare</TableCell>
                <TableCell align="center">x 1.0</TableCell>
                <TableCell
                  className={
                    theme.palette.type === "dark"
                      ? clsx(styles.iae_separation_cell_dark, styles.tab_cell_border_bottom_none)
                      : clsx(styles.iae_separation_cell_light, styles.tab_cell_border_bottom_none)
                  }
                ></TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_D_400"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_400"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_400"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_400"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_D_1600"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_1600"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_1600"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_1600"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
              </TableRow>
              {/*Jachère mellifère*/}
              <TableRow>
                <TableCell>Jachère mellifère</TableCell>
                <TableCell>
                  <Tooltip
                    title={<span style={{ whiteSpace: "pre-line" }}>{indicateurs["HVE4_BIODIV_D_5"].messageAide}</span>}
                  >
                    <IconButton>
                      <FontAwesomeIcon icon="info-circle" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    displayRules={{ displayValeurMP: "column" }}
                    indicateur={indicateurs["HVE4_BIODIV_D_5"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_5"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_5"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_5"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    displayRules={{ displayValeurMP: "column" }}
                    indicateur={indicateurs["HVE4_BIODIV_D_17"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_17"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_17"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_17"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">hectare</TableCell>
                <TableCell align="center">x 1.5</TableCell>
                <TableCell
                  className={
                    theme.palette.type === "dark"
                      ? clsx(styles.iae_separation_cell_dark, styles.tab_cell_border_bottom_none)
                      : clsx(styles.iae_separation_cell_light, styles.tab_cell_border_bottom_none)
                  }
                ></TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_D_500"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_500"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_500"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_500"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_D_1700"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_1700"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_1700"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_1700"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
              </TableRow>
              {/*Zones humides*/}
              <TableRow>
                <TableCell>Zones humides</TableCell>
                <TableCell>
                  <Tooltip
                    title={
                      <span style={{ whiteSpace: "pre-line" }}>{indicateurs["HVE4_BIODIV_D_13"].messageAide}</span>
                    }
                  >
                    <IconButton>
                      <FontAwesomeIcon icon="info-circle" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell />
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    displayRules={{ displayValeurMP: "column" }}
                    indicateur={indicateurs["HVE4_BIODIV_D_13"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_13"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_13"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_13"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">hectare</TableCell>
                <TableCell align="center">x 2.0</TableCell>
                <TableCell
                  className={
                    theme.palette.type === "dark"
                      ? clsx(styles.iae_separation_cell_dark)
                      : clsx(styles.iae_separation_cell_light)
                  }
                ></TableCell>
                <TableCell align="center" />
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_D_1300"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_1300"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_1300"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_1300"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
              </TableRow>
              {/*Verger haute-tige = pré-verger*/}
              <TableRow>
                <TableCell>Verger haute-tige = pré-verger</TableCell>
                <TableCell>
                  <Tooltip
                    title={
                      <span style={{ whiteSpace: "pre-line" }}>{indicateurs["HVE4_BIODIV_D_27"].messageAide}</span>
                    }
                  >
                    <IconButton>
                      <FontAwesomeIcon icon="info-circle" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell />
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    displayRules={{ displayValeurMP: "column" }}
                    indicateur={indicateurs["HVE4_BIODIV_D_27"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_27"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_27"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_27"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">hectare</TableCell>
                <TableCell align="center">x 3.0</TableCell>
                <TableCell
                  className={
                    theme.palette.type === "dark"
                      ? clsx(styles.iae_separation_cell_dark)
                      : clsx(styles.iae_separation_cell_light)
                  }
                ></TableCell>
                <TableCell />
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_D_2700"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_2700"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_2700"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_2700"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
              </TableRow>
              {/*Bande enherbées intra-parcellaires*/}
              <TableRow>
                <TableCell>Bande enherbées intra-parcellaires</TableCell>
                <TableCell>
                  <Tooltip
                    title={
                      <span style={{ whiteSpace: "pre-line" }}>{indicateurs["HVE4_BIODIV_D_18"].messageAide}</span>
                    }
                  >
                    <IconButton>
                      <FontAwesomeIcon icon="info-circle" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell />
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    displayRules={{ displayValeurMP: "column" }}
                    indicateur={indicateurs["HVE4_BIODIV_D_18"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_18"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_18"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_18"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">m linéaire</TableCell>
                <TableCell align="center">x 9.0</TableCell>
                <TableCell
                  className={
                    theme.palette.type === "dark"
                      ? clsx(styles.iae_separation_cell_dark, styles.tab_cell_border_bottom_none)
                      : clsx(styles.iae_separation_cell_light, styles.tab_cell_border_bottom_none)
                  }
                ></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_D_1800"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_1800"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_1800"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_1800"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
              </TableRow>
              {/*Prairies permanentes, landes, parcours, alpages, estives situés en zone Natura 2000*/}
              <TableRow>
                <TableCell>
                  Prairies permanentes, landes, parcours, alpages, estives situés en zone Natura 2000
                </TableCell>
                <TableCell></TableCell>
                <TableCell />
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    displayRules={{ displayValeurMP: "column" }}
                    indicateur={indicateurs["HVE4_BIODIV_D_19"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_19"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_19"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_19"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">hectare</TableCell>
                <TableCell align="center">x 1.5</TableCell>
                <TableCell
                  className={
                    theme.palette.type === "dark"
                      ? clsx(styles.iae_separation_cell_dark, styles.tab_cell_border_bottom_none)
                      : clsx(styles.iae_separation_cell_light, styles.tab_cell_border_bottom_none)
                  }
                />
                <TableCell align="center"></TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_D_1900"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_1900"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_1900"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_1900"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
              </TableRow>
              {/*Zones herbacées mises en défens et retirées de la production */}
              <TableRow>
                <TableCell>Zones herbacées mises en défens et retirées de la production</TableCell>
                <TableCell>
                  <Tooltip
                    title={
                      <span style={{ whiteSpace: "pre-line" }}>{indicateurs["HVE4_BIODIV_D_20"].messageAide}</span>
                    }
                  >
                    <IconButton>
                      <FontAwesomeIcon icon="info-circle" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell />
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    displayRules={{ displayValeurMP: "column" }}
                    indicateur={indicateurs["HVE4_BIODIV_D_20"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_20"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_20"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_20"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">m linéaire</TableCell>
                <TableCell align="center">x 0.0009</TableCell>
                <TableCell
                  className={
                    theme.palette.type === "dark"
                      ? clsx(styles.iae_separation_cell_dark, styles.tab_cell_border_bottom_none)
                      : clsx(styles.iae_separation_cell_light, styles.tab_cell_border_bottom_none)
                  }
                ></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_D_2000"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_2000"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_2000"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_2000"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
              </TableRow>
              {/*Autres milieux surfaciques */}
              <TableRow>
                <TableCell>Autres milieux surfaciques</TableCell>
                <TableCell>
                  <Tooltip
                    title={
                      <span style={{ whiteSpace: "pre-line" }}>{indicateurs["HVE4_BIODIV_D_21"].messageAide}</span>
                    }
                  >
                    <IconButton>
                      <FontAwesomeIcon icon="info-circle" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell />
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    displayRules={{ displayValeurMP: "column" }}
                    indicateur={indicateurs["HVE4_BIODIV_D_21"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_21"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_21"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_21"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">hectare</TableCell>
                <TableCell align="center">x 1.0</TableCell>
                <TableCell
                  className={
                    theme.palette.type === "dark"
                      ? clsx(styles.iae_separation_cell_dark, styles.tab_cell_border_bottom_none)
                      : clsx(styles.iae_separation_cell_light, styles.tab_cell_border_bottom_none)
                  }
                ></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_D_2100"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_2100"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_2100"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_2100"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
              </TableRow>
              {/*Autres milieux linéaires : ruines */}
              <TableRow>
                <TableCell>Autres milieux linéaires : ruines</TableCell>
                <TableCell>
                  <Tooltip
                    title={
                      <span style={{ whiteSpace: "pre-line" }}>{indicateurs["HVE4_BIODIV_D_22"].messageAide}</span>
                    }
                  >
                    <IconButton>
                      <FontAwesomeIcon icon="info-circle" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell />
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    displayRules={{ displayValeurMP: "column" }}
                    indicateur={indicateurs["HVE4_BIODIV_D_22"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_22"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_22"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_22"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">m linéaire</TableCell>
                <TableCell align="center">x 0.0005</TableCell>
                <TableCell
                  className={
                    theme.palette.type === "dark"
                      ? clsx(styles.iae_separation_cell_dark, styles.tab_cell_border_bottom_none)
                      : clsx(styles.iae_separation_cell_light, styles.tab_cell_border_bottom_none)
                  }
                ></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_D_2200"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_2200"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_2200"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_2200"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
              </TableRow>
              {/*Autres milieux linéaires : hors ruines */}
              <TableRow>
                <TableCell>Autres milieux linéaires : hors ruines</TableCell>
                <TableCell>
                  <Tooltip
                    title={
                      <span style={{ whiteSpace: "pre-line" }}>{indicateurs["HVE4_BIODIV_D_220"].messageAide}</span>
                    }
                  >
                    <IconButton>
                      <FontAwesomeIcon icon="info-circle" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell />
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_D_220"]}
                    displayRules={{ displayValeurMP: "column" }}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_220"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_220"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_220"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell align="center">m linéaire</TableCell>
                <TableCell align="center">x 0.0005</TableCell>
                <TableCell
                  className={
                    theme.palette.type === "dark"
                      ? clsx(styles.iae_separation_cell_dark)
                      : clsx(styles.iae_separation_cell_light)
                  }
                ></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_D_22000"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_D_22000"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_D_22000"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_D_22000"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Grid>

        <Grid item sm={12}>
          <div className={"d-flex align-items-center mb-1"}>
            <Grid item xs={4}>
              <div className={indicateurs["HVE4_BIODIV_D_29"].afficherGras ? "mr-3 bold" : "mr-3"}>
                {indicateurs["HVE4_BIODIV_D_29"].libelle}
              </div>
            </Grid>
            <Grid item xs={8}>
              <DecimalPositif
                size={"small"}
                indicateur={indicateurs["HVE4_BIODIV_D_29"]}
                key={"indicateur-" + indicateurs["HVE4_BIODIV_D_29"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_BIODIV_D_29"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_29"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
          </div>
          <div className={"d-flex align-items-center mb-1"}>
            <Grid item xs={4}>
              <div className={indicateurs["HVE4_BIODIV_D_30"].afficherGras ? "mr-3 bold" : "mr-3"}>
                {indicateurs["HVE4_BIODIV_D_30"].libelle}
              </div>
            </Grid>
            <Grid item xs={8}>
              <DecimalPositif
                size={"small"}
                indicateur={indicateurs["HVE4_BIODIV_D_30"]}
                key={"indicateur-" + indicateurs["HVE4_BIODIV_D_30"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_BIODIV_D_30"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_30"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
          </div>

          <div className={"d-flex align-items-center mb-1"}>
            <Grid item xs={4}>
              <div className={indicateurs["HVE4_BIODIV_D_31"].afficherGras ? "mr-3 bold" : "mr-3"}>
                {indicateurs["HVE4_BIODIV_D_31"].libelle}
              </div>
            </Grid>
            <Grid item xs={8}>
              <DecimalPositif
                size={"small"}
                indicateur={indicateurs["HVE4_BIODIV_D_31"]}
                key={"indicateur-" + indicateurs["HVE4_BIODIV_D_31"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_BIODIV_D_31"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_31"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
          </div>
        </Grid>

        <Grid item sm={12}>
          {this.renderObligationIAE(indicateurs, computedFields)}
        </Grid>

        <Grid item sm={12}>
          {this.renderScoringIAE(indicateurs, computedFields)}
        </Grid>
      </Grid>
    );
  }
}

export default compose(withTranslation(), connect(mapStateToProps), withTheme)(BiodivIAE);
