import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Box, Button, CircularProgress, MenuItem, TextField, Typography, Card } from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import NumberInput from "../../common/NumberInput";
import { fileToBase64 } from "../../../scripts/utils";
import network from "../../../actions/external/network";
import { toastr } from "react-redux-toastr";

const mapStateToProps = (state) => ({
  idExploitation: state.exploitation.selected.idExploitation,
  idMillesime: state.millesime.selected.idMillesime,
});

class ImporterDocument extends React.Component {
  static MAX_FILE_SIZE = 1e7;
  static AUTHORIZED_TYPES = ["application/pdf"];

  constructor(props) {
    super(props);

    this.inputFileRef = React.createRef();

    this.state = {
      selectedFileName: "",
      selectedFile: null,
      isInterMillesime: "true",
      periodeValiditeDebut: props.idMillesime,
      periodeValiditeFin: props.idMillesime,
      uploading: false,
    };
  }

  onChangeFile = () => {
    const { t } = this.props;
    const selectedFile = this.inputFileRef.current.files[0];
    if (!selectedFile) {
      this.resetFile();
    } else if (!ImporterDocument.AUTHORIZED_TYPES.includes(selectedFile.type)) {
      this.resetFile();
      toastr.error(t("error", "Erreur"), t("documents.file-type-error", "Type de fichier non autorisé"));
    } else if (selectedFile.size > ImporterDocument.MAX_FILE_SIZE) {
      this.resetFile();
      toastr.error(
        t("error", "Erreur"),
        t("documents.size-file-error", "Le fichier sélectionné dépasse la taille maximale autorisée")
      );
    } else {
      this.setState({ selectedFile, selectedFileName: selectedFile.name });
    }
  };

  handleChangeName = (e) => {
    this.setState({ selectedFileName: e.target.value });
  };

  resetFile = () => {
    this.setState({ selectedFile: null });
    this.inputFileRef.current.value = "";
  };

  isFormValid = () => {
    const { idTypeDocument } = this.props;
    const { selectedFile, isInterMillesime, periodeValiditeDebut, periodeValiditeFin, selectedFileName } = this.state;

    return (
      selectedFile != null &&
      idTypeDocument !== "" &&
      selectedFileName !== "" &&
      (isInterMillesime === "true" ||
        (periodeValiditeDebut.toString().length === 4 && periodeValiditeFin.toString().length === 4))
    );
  };

  onSubmit = async () => {
    const { t, idExploitation, idTypeDocument } = this.props;
    const { selectedFile, isInterMillesime, periodeValiditeDebut, periodeValiditeFin, selectedFileName } = this.state;

    this.setState({ uploading: true });

    try {
      const response = await network.fetch(`/api/exploitations/${idExploitation}/documents`, {
        method: "POST",
        body: JSON.stringify({
          nom: selectedFileName,
          idTypeDocument: idTypeDocument,
          isInterMillesime: isInterMillesime,
          millesimesValidite:
            isInterMillesime === "true"
              ? null
              : Array.from(
                  { length: Number(periodeValiditeFin) - Number(periodeValiditeDebut) + 1 },
                  (_, i) => Number(periodeValiditeDebut) + i
                ),
          content: await fileToBase64(selectedFile),
        }),
      });

      this.resetFile();

      toastr.success("OK", t("documents.save-ok", `Document enregistré`));
      if (this.props.onSelect) {
        this.props.onSelect(response);
      }
    } catch (error) {
      toastr.error(
        t("error", "Erreur"),
        t("documents.save-error", "Une erreur est survenue lors de la sauvegarde du document")
      );
    }

    this.setState({ uploading: false });
  };

  renderInputFile = () => {
    const { selectedFile, selectedFileName } = this.state;
    const { t } = this.props;

    return (
      <React.Fragment>
        <Typography variant="h5" className="my-1">
          {t("documents.input-title", "Document (pdf < 10 MB)")}
        </Typography>
        <input type="file" id="inputFile" accept=".pdf" onChange={this.onChangeFile} ref={this.inputFileRef} hidden />
        <label
          htmlFor="inputFile"
          className="d-flex"
          style={{ border: "#b2b2b2 1px solid", borderRadius: 4, width: 400, cursor: "pointer" }}
        >
          {!selectedFile ? (
            <span className="flex-fill d-flex align-items-center px-1" />
          ) : (
            <TextField disabled={!selectedFile} value={selectedFileName} onChange={this.handleChangeName} />
          )}

          <Button
            color="primary"
            variant="contained"
            component="span"
            style={{
              borderTopRightRadius: "4px",
              borderBottomRightRadius: "4px",
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
              padding: "10px 20px",
            }}
          >
            {t("browse", "Parcourir")}
          </Button>
        </label>
      </React.Fragment>
    );
  };

  renderTypesDocuments = () => {
    const { t, typesDocuments, idTypeDocument } = this.props;

    return (
      <React.Fragment>
        <Typography variant="h5" className="my-1">
          {t("documents.type-document", "Type de document")}
        </Typography>
        <TextField
          select
          name="idTypeDocument"
          value={idTypeDocument}
          onChange={(event) => this.props.onChangeTypeDocument(event.target.value)}
          variant="outlined"
          style={{ maxWidth: 400 }}
        >
          {typesDocuments.map((typeDocument) => (
            <MenuItem key={typeDocument.idTypeDocument} value={typeDocument.idTypeDocument}>
              {typeDocument.libelle}
            </MenuItem>
          ))}
        </TextField>
      </React.Fragment>
    );
  };

  renderPeriodeValidite = () => {
    const { t } = this.props;
    const { isInterMillesime, periodeValiditeDebut, periodeValiditeFin } = this.state;

    const commonProps = {
      inputProps: {
        maxLength: 4,
        size: 4,
        className: "p-05",
      },
      disabled: isInterMillesime === "true",
      variant: "outlined",
      className: "mx-05",
    };

    const PeriodeValiditeInput = (
      <Box className="d-flex align-items-center">
        De
        <NumberInput
          value={periodeValiditeDebut}
          onChange={(value) => this.setState({ periodeValiditeDebut: value })}
          thousandSeparator={""}
          {...commonProps}
        />
        à
        <NumberInput
          value={periodeValiditeFin}
          onChange={(value) => this.setState({ periodeValiditeFin: value })}
          thousandSeparator={""}
          {...commonProps}
        />
      </Box>
    );

    return (
      <React.Fragment>
        <Typography variant="h5" className="mt-1">
          {t("documents.periode-validite", "Période de validité")}
        </Typography>
        <RadioGroup
          name="isInterMillesime"
          value={isInterMillesime}
          onChange={(event) => this.setState({ isInterMillesime: event.target.value })}
        >
          <FormControlLabel
            value="true"
            control={<Radio color="primary" />}
            label={t("documents.inter-millesime", "Inter-millésime")}
          />
          <FormControlLabel value="false" control={<Radio color="primary" />} label={PeriodeValiditeInput} />
        </RadioGroup>
      </React.Fragment>
    );
  };

  render() {
    const { t } = this.props;
    const { uploading } = this.state;

    return (
      <Card>
        <Box className="page d-flex flex-column">
          {this.renderInputFile()}
          <Box className="mt-1">{this.renderTypesDocuments()}</Box>
          <Box className="mt-1">{this.renderPeriodeValidite()}</Box>
          <Box>
            <Button
              onClick={this.onSubmit}
              disabled={!this.isFormValid() || uploading}
              color="primary"
              variant="contained"
              className="mt-2"
            >
              {uploading ? <CircularProgress size={24} /> : t("documents.import", "Importer")}
            </Button>
          </Box>
        </Box>
      </Card>
    );
  }
}

export default withRouter(connect(mapStateToProps, null)(withTranslation()(ImporterDocument)));
