import React from "react";
import Indicator from "../../Indicator";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { MenuItem, TextField } from "@material-ui/core";

class OADPPF extends React.Component {
  render() {
    const {
      indicateur,
      onChangeIndicator,
      sousRubriqueIndex,
      libelleEnglobantIndex,
      changeIndicateur,
      valueInputProps,
      computedFields,
    } = this.props;

    const choixOADPPF = [
      {
        libelle: "Logiciel Epiclès",
        value: "EPICLES",
      },
      {
        libelle: "Nutriplan",
        value: "NUTRIPLAN",
      },
      {
        libelle: "Extran-plan",
        value: "EXTRAN-PLAN",
      },
      {
        libelle: "Planfum",
        value: "PLANFUM",
      },
      {
        libelle: "Clé de Sol",
        value: "CLE",
      },
      {
        libelle: "MesParcelles",
        value: "MP",
      },
      {
        libelle: "Agrimap Isagri",
        value: "AGRIMAP",
      },
      {
        libelle: "Pratic",
        value: "PRATIC",
      },
      {
        libelle: "Azolys",
        value: "AZOLYS",
      },
      {
        libelle: "Planilys",
        value: "PLANILYS",
      },
      {
        libelle: "FertiPass",
        value: "FERTIPASS",
      },
      {
        libelle: "Autre",
        value: "AUTRE",
      },
    ];

    const valueRendered = (
      <React.Fragment>
        <TextField
          fullWidth={false}
          InputProps={valueInputProps}
          select
          value={indicateur.valeur.value}
          style={{
            fontWeight: indicateur.afficherGras ? "bold" : "normal",
            width: "20rem",
          }}
          disabled={computedFields.includes(indicateur.codeNational)}
          onChange={(event) =>
            changeIndicateur(
              { ...indicateur, valeur: { value: event.target.value } },
              sousRubriqueIndex,
              libelleEnglobantIndex
            )
          }
        >
          {choixOADPPF.map((element) => (
            <MenuItem key={element.value} value={element.value}>
              {element.libelle}
            </MenuItem>
          ))}
        </TextField>
        {indicateur.valeur.value === "AUTRE" && (
          <TextField
            fullWidth={false}
            style={{ maxWidth: "8rem" }}
            className="ml-1"
            value={indicateur.valeur.otherValue}
            onChange={(event) =>
              changeIndicateur(
                { ...indicateur, valeur: { ...indicateur.valeur, otherValue: event.target.value } },
                sousRubriqueIndex,
                libelleEnglobantIndex
              )
            }
          />
        )}
      </React.Fragment>
    );

    return (
      <Indicator indicateur={indicateur} onChange={onChangeIndicator}>
        {valueRendered}
      </Indicator>
    );
  }
}

export default compose(withTranslation())(OADPPF);
