import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { connect } from "react-redux";
import codes from "../../../../common/codes";
import DatePicker from "../../../../common/DatePicker";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class HVE3AResults extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scoresRubriques: [],
      etatDossier: "",
      resultatAuditComputed: "",
      dateAudit: null,
      commentaireAuditeur: "",
      commentaireExploitant: "",
    };
  }

  componentDidMount() {
    const { indicateurs, rubriques, exploitationDemarcheInfo } = this.props;

    const scoringIndicateurs = {
      BIO_INDICATEURS: "HVE3A_I_95",
      STRAT_PHYTO_INDICATEURS: "HVE3A_I_96",
      FERTI_INDICATEURS: "HVE3A_I_97",
      IRRIG_INDICATEURS: "HVE3A_I_98",
    };

    const scoresRubriques = rubriques.reduce((acc, r) => {
        const scoring = scoringIndicateurs[r.codeNational];
        if (scoring) {
          return [
            ...acc,
            {
              libelle: r.libelle,
              score: indicateurs[scoring].valeur.note,
            },
          ];
        } else {
          return acc;
        }
      }, []),
      resultatAuditComputed = scoresRubriques.every((scoreRubrique) => scoreRubrique.score >= 10)
        ? codes.procedureStateCodes.CERTIFIABLE
        : codes.procedureStateCodes.NON_CERTIFIABLE,
      etatDossier = exploitationDemarcheInfo?.exploitationDemarcheMillesime?.etatDossier ?? "";

    this.setState({
      scoresRubriques,
      etatDossier,
      resultatAuditComputed,
      dateAudit: exploitationDemarcheInfo.exploitationDemarcheMillesime.dateAudit ?? null,
      commentaireAuditeur: exploitationDemarcheInfo.exploitationDemarcheMillesime.commentaireAuditeur ?? "",
      commentaireExploitant: exploitationDemarcheInfo.exploitationDemarcheMillesime.commentaireExploitant ?? "",
    });
  }

  changeField = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    const { t, saveResult, userRole } = this.props,
      {
        scoresRubriques,
        etatDossier,
        commentaireAuditeur,
        commentaireExploitant,
        dateAudit,
        resultatAuditComputed,
      } = this.state;

    return (
      <Card className="d-flex flex-fill flex-column">
        <CardHeader id="scrollToTop" title={<Typography variant={"h3"}>Résultats</Typography>} />
        <CardContent style={{ overflowY: "auto" }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell colSpan={2}>
                  <Typography variant={"h4"}>Notes obtenues par module</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={"h4"}>Résultat de l'audit</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant={"h5"}>Module</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant={"h5"}>Note</Typography>
                </TableCell>
                <TableCell rowSpan={7} align={"center"}>
                  <Typography variant={"h1"}>
                    {resultatAuditComputed === "CERTIFIABLE" ? "Certifiable" : "Non Certifiable"}
                  </Typography>
                </TableCell>
              </TableRow>
              {scoresRubriques.map((rubrique, i) => (
                <React.Fragment>
                  <TableRow key={i}>
                    <TableCell>{rubrique.libelle}</TableCell>
                    <TableCell>{rubrique.score.toFixed(1)}</TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
              <TableRow>
                <TableCell colSpan={3}>
                  <Typography variant={"text"} style={{ fontStyle: "italic" }}>
                    L'exploitation est certifiée HVE si la note obtenue dans chaque module est supérieure ou égale à 10.
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Box className={"d-flex justify-around"}>
            <TextField
              select
              fullWidth={false}
              label={t("hve3a.etat-dossier", "État du dossier")}
              className={"mt-1 mr-1"}
              style={{ flex: "0 1 auto" }}
              name={"etatDossier"}
              value={etatDossier}
              onChange={this.changeField}
              disabled={userRole === codes.roles.AGRICULTEUR}
            >
              {codes.procedureStates.map(({ libelle, code }, i) => (
                <MenuItem key={i} value={code}>
                  {libelle}
                </MenuItem>
              ))}
            </TextField>
            <DatePicker
              disableToolbar
              variant="inline"
              format="DD/MM/yyyy"
              margin="normal"
              label={"Date de l'audit"}
              value={dateAudit}
              disabled={userRole === codes.roles.AGRICULTEUR}
              onChange={(value) =>
                this.changeField({
                  target: {
                    name: "dateAudit",
                    value: value?.format("YYYY-MM-DD") || null,
                  },
                })
              }
            />
          </Box>
          <Box className={"d-flex justify-around"}>
            <Tooltip title="Limité à 150 caractères">
              <TextField
                multiline={true}
                rows={3}
                label={"Commentaire de l'auditeur"}
                className={"mt-1 mr-1"}
                name={"commentaireAuditeur"}
                value={commentaireAuditeur}
                disabled={userRole === codes.roles.AGRICULTEUR}
                onChange={this.changeField}
              />
            </Tooltip>
            <TextField
              multiline={true}
              rows={3}
              label={"Commentaire de l'exploitant"}
              className={"mt-1"}
              name={"commentaireExploitant"}
              value={commentaireExploitant}
              onChange={this.changeField}
            />
          </Box>

          <div className={"d-flex justify-center"}>
            <Button
              variant={"contained"}
              color={"primary"}
              className={"mt-1"}
              onClick={() =>
                saveResult({
                  etatDossier,
                  dateAudit,
                  commentaireAuditeur,
                  commentaireExploitant,
                })
              }
            >
              Enregistrer
            </Button>
          </div>
        </CardContent>
      </Card>
    );
  }
}

HVE3AResults.propTypes = {
  indicateurs: PropTypes.object.isRequired,
  rubriques: PropTypes.array.isRequired,
  saveResult: PropTypes.func.isRequired,
  exploitationDemarcheInfo: PropTypes.object.isRequired,
};

export default compose(withTranslation(), connect(mapStateToProps))(HVE3AResults);
