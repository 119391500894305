import React from "react";
import { withRouter } from "react-router-dom";
import { Box, Card, Tab, Tabs, Typography } from "@material-ui/core";
import { useTranslation, withTranslation } from "react-i18next";
import ParUtilisateur from "./ParUtilisateur";
import SigaModal from "../../common/SigaModal";
import ParExploitation from "./ParExploitation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

let Acces = class extends React.Component {
  tabs = {
    ParUtilisateur: "ParUtilisateur",
    ParExploitation: "ParExploitation",
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedTab: props.idExploitation != null ? this.tabs.ParExploitation : this.tabs.ParUtilisateur,
    };
  }

  handleChangeTab = (event, value) => {
    this.setState({ selectedTab: value });
  };

  render() {
    const { t, showModal } = this.props;
    const { selectedTab } = this.state;

    return (
      <Box>
        {this.props.idUtilisateur == null && this.props.idExploitation == null && (
          <Tabs
            value={selectedTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.handleChangeTab}
            variant="fullWidth"
          >
            <Tab
              label={t("admin.access.by-user", "Par utilisateur")}
              icon={<FontAwesomeIcon className={"fa-2x"} icon={"user"} />}
              value={this.tabs.ParUtilisateur}
            />
            <Tab
              label={t("admin.access.by-farm", "Par exploitation")}
              icon={<FontAwesomeIcon className={"fa-2x"} icon={"tractor"} />}
              value={this.tabs.ParExploitation}
            />
          </Tabs>
        )}
        <Box className={!showModal && "page"}>
          <TabPanel value={selectedTab} index={this.tabs.ParUtilisateur}>
            <ParUtilisateur idUtilisateur={this.props.idUtilisateur} afterSubmit={this.props.afterSubmit} />
          </TabPanel>
          <TabPanel value={selectedTab} index={this.tabs.ParExploitation}>
            <ParExploitation idExploitation={this.props.idExploitation} afterSubmit={this.props.afterSubmit} />
          </TabPanel>
        </Box>
      </Box>
    );
  }
};

Acces = withRouter(withTranslation()(Acces));

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && children}
    </div>
  );
};

export const AccesPage = (props) => {
  const { t } = useTranslation();

  return (
    <Box className="ml-2 w-100">
      <Typography variant="h3" className="mb-2">
        {t("access", "Accès")}
      </Typography>
      <Card>
        <Acces showModal={false} {...props} />
      </Card>
    </Box>
  );
};

export const AccesModal = (props) => {
  const { t } = useTranslation();
  const { showModal, onCloseModal, ...other } = props;

  return (
    <SigaModal
      open={showModal}
      onClose={onCloseModal}
      maxWidth="md"
      title={<Typography variant="h3">{t("access", "Accès")}</Typography>}
    >
      <Acces showModal={showModal} {...other} />
    </SigaModal>
  );
};
