import { Toolbar } from "@material-ui/core";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import React from "react";
import { withStyles } from "@material-ui/styles";
import { withTranslation } from "react-i18next";
import {
  InputAdornment,
  TextField,
  Popover,
  ListItem,
  ListItemIcon,
  Checkbox,
  ListItemText,
  List,
  IconButton,
  Tooltip,
  Typography,
  Box,
} from "@material-ui/core";
import AdminTableHead from "./AdminTableHead";

class AdminTableToolbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorElFilters: null,
    };
  }

  handleClickFilters = (event) => {
    this.setState({ anchorElFilters: event.currentTarget });
  };

  handleCloseFilters = () => {
    this.setState({ anchorElFilters: null });
  };

  handleToggleFilter = (id) => {
    let newChecked = [];
    if (this.props.searchFields.includes(id)) {
      newChecked = this.props.searchFields.filter((field) => field !== id);
    } else {
      newChecked = [...this.props.searchFields, id];
    }
    this.props.handleSearchFieldsChange(newChecked);
  };

  renderDelete() {
    const { t, numSelected, classes } = this.props;
    return (
      <React.Fragment>
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} {t("admin.selected", "sélectionné", { count: numSelected })}
        </Typography>

        {this.props.canDelete && this.props.onDelete && (
          <Tooltip title={t("admin.delete", "supprimer")}>
            <IconButton aria-label="delete" onClick={this.props.onDelete}>
              <FontAwesomeIcon icon="trash-alt" size="sm" className={classes.deleteIcon} />
            </IconButton>
          </Tooltip>
        )}
      </React.Fragment>
    );
  }

  renderSearch() {
    const openFilters = Boolean(this.state.anchorElFilters);
    const { t, classes } = this.props;

    return (
      <React.Fragment>
        <Box className="d-flex">
          <TextField
            style={{ maxWidth: 400 }}
            variant="outlined"
            label={t("admin.search", "Rechercher")}
            value={this.props.search}
            onChange={(event) => this.props.handleSearchChange(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {this.props.search.length === 0 ? (
                    <FontAwesomeIcon icon="search" />
                  ) : (
                    <IconButton onClick={() => this.props.handleSearchChange("")} tabIndex="-1">
                      <FontAwesomeIcon icon="times" />
                    </IconButton>
                  )}
                </InputAdornment>
              ),
            }}
          />
          <Tooltip title={t("filter.search", "Préciser les champs sur lesquels appliquer la recherche")}>
            <IconButton onClick={this.handleClickFilters}>
              <FontAwesomeIcon
                icon="filter"
                className={this.props.searchFields.length > 0 ? classes.filterValidated : "inherit"}
              />
            </IconButton>
          </Tooltip>
          <Popover
            open={openFilters}
            anchorEl={this.state.anchorElFilters}
            onClose={this.handleCloseFilters}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <List className={classes.list}>
              {this.props.columns
                .filter((column) => column.type !== AdminTableHead.columnsTypes.ACTION)
                .map((column) => {
                  return (
                    <ListItem
                      key={column.id}
                      role={undefined}
                      dense
                      button
                      onClick={() => this.handleToggleFilter(column.id)}
                    >
                      <ListItemIcon>
                        <Checkbox
                          edge="start"
                          checked={this.props.searchFields.includes(column.id)}
                          tabIndex={-1}
                          disableRipple
                          color="primary"
                        />
                      </ListItemIcon>
                      <ListItemText primary={column.label} />
                    </ListItem>
                  );
                })}
            </List>
          </Popover>
        </Box>
        {this.props.renderHeaderOptions && this.props.renderHeaderOptions()}
        {this.props.canCreate && this.props.onCreate && (
          <Tooltip title={t("admin.create-new", "Ajouter")}>
            <IconButton onClick={this.props.onCreate}>
              <FontAwesomeIcon icon="plus" />
            </IconButton>
          </Tooltip>
        )}
      </React.Fragment>
    );
  }

  render() {
    const { numSelected, classes } = this.props;

    return (
      <Toolbar
        className={clsx(classes.head, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? this.renderDelete() : this.renderSearch()}
      </Toolbar>
    );
  }
}

AdminTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  search: PropTypes.string.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
  searchFields: PropTypes.array.isRequired,
  handleSearchFieldsChange: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  canDelete: PropTypes.bool.isRequired,
  onDelete: PropTypes.func,
  canCreate: PropTypes.bool.isRequired,
  onCreate: PropTypes.func,
  renderHeaderOptions: PropTypes.func,
};

const StyledAdminTableToolbar = withStyles((theme) => ({
  head: {
    display: "flex",
    justifyContent: "space-between",
    fontWeight: "bold",
    padding: theme.spacing(2),
    borderTopLeftRadius: "0.5em",
    borderTopRightRadius: "0.5em",
  },
  deleteIcon: {
    color: theme.palette.primary.contrastText,
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.primary.contrastText,
          backgroundColor: theme.palette.primary.main,
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.primary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
  list: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  filterValidated: {
    color: theme.palette.primary.main,
  },
}))(AdminTableToolbar);

export default withTranslation()(StyledAdminTableToolbar);
