import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { applyMiddleware, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { BrowserRouter } from "react-router-dom";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import moment from "moment";
import "moment/locale/fr";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import rootReducer from "./reducers";
import App from "./components/App";
import { loadState, saveState } from "./scripts/localStorage";
import "./index.css";
import en from "./resources/locales/en.json";
import fr from "./resources/locales/fr.json";
import CssBaseline from "@material-ui/core/CssBaseline";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/700.css";
import { initSentry, sentryReduxEnhancer } from "./sentry";

initSentry();

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: {
      fr: {
        translation: fr,
      },
      en: {
        translation: en,
      },
    },
    interpolation: {
      escapeValue: false, // react already safe from xss
    },
    detection: {
      order: ["localStorage", "navigator"],
    },
    whitelist: ["fr"],
    fallbackLng: "fr",
  });

moment.locale(i18next.language);

const persistedState = loadState();
export const store = createStore(
  rootReducer,
  persistedState,
  compose(applyMiddleware(thunkMiddleware), sentryReduxEnhancer)
);

store.subscribe(() => {
  const { network, ...stateToSave } = store.getState();
  saveState(stateToSave);
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <CssBaseline />
          <App />
        </MuiPickersUtilsProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
