import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Grid, InputAdornment } from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
import Boolean from "../indicateurs/Boolean";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class IrrigRecupEauxPluies extends React.Component {
  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };

  render() {
    const { indicateurs, getValueAssolement, computedFields } = this.props;

    const indicateursKeys = ["HVE4_IRRI_I_8", "HVE4_IRRI_S_8", "HVE4_IRRI_S_81", "HVE4_IRRI_D_80"];

    const indicateursToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateursKeys.includes(key)))
    );

    if (indicateursToDisplay.length > 0) {
      return (
        <React.Fragment>
          <Grid container spacing={2}>
            <Grid container item xs={6}>
              <div className={indicateurs["HVE4_IRRI_I_8"].afficherGras ? "mr-3 bold" : "mr-3"}>
                {indicateurs["HVE4_IRRI_I_8"].libelle}
              </div>
            </Grid>
            <Grid container item xs={4}>
              <Boolean
                getValueAssolement={getValueAssolement}
                indicateur={indicateurs["HVE4_IRRI_I_8"]}
                key={"indicateur-" + indicateurs["HVE4_IRRI_I_8"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_IRRI_I_8"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_I_8"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
            <Grid container item xs={4}>
              <div className={indicateurs["HVE4_IRRI_S_8"].afficherGras ? "mr-3 bold" : "mr-3"}>
                {indicateurs["HVE4_IRRI_S_8"].libelle}
              </div>
            </Grid>
            <Grid container item xs={4}>
              <div className={indicateurs["HVE4_IRRI_D_80"].afficherGras ? "mr-3 bold" : "mr-3"}>
                {indicateurs["HVE4_IRRI_D_80"].libelle}
              </div>
            </Grid>
            <Grid container item xs={4}>
              <div className={indicateurs["HVE4_IRRI_S_81"].afficherGras ? "mr-3 bold" : "mr-3"}>
                {indicateurs["HVE4_IRRI_S_81"].libelle}
              </div>
            </Grid>
            <Grid container item xs={4}>
              <DecimalPositif
                size={"small"}
                getValueAssolement={getValueAssolement}
                indicateur={indicateurs["HVE4_IRRI_S_8"]}
                key={"indicateur-" + indicateurs["HVE4_IRRI_S_8"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_IRRI_S_8"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_S_8"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
            <Grid container item xs={4}>
              <DecimalPositif
                size={"small"}
                getValueAssolement={getValueAssolement}
                indicateur={indicateurs["HVE4_IRRI_D_80"]}
                key={"indicateur-" + indicateurs["HVE4_IRRI_D_80"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_IRRI_D_80"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_D_80"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
            <Grid container item xs={4} className={"total-point"}>
              <DecimalPositif
                size={"small"}
                getValueAssolement={getValueAssolement}
                indicateur={indicateurs["HVE4_IRRI_S_81"]}
                key={"indicateur-" + indicateurs["HVE4_IRRI_S_81"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_IRRI_S_81"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_S_81"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
          </Grid>
        </React.Fragment>
      );
    }
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(IrrigRecupEauxPluies);
