import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import { Link, withRouter } from "react-router-dom";
import network from "../../../actions/external/network";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toastr } from "react-redux-toastr";
import { loadPartners } from "../../../actions/partners";

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
  partners: state.partners.partners,
});

const mapDispatchToProps = (dispatch) => ({
  loadPartners: () => dispatch(loadPartners()),
});

class Partners extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  canRemovePartner = () => {
    return this.props.auth.login != null || this.props.partners.length > 1;
  };

  handleClickDelete = async (partner) => {
    const { t, loadPartners } = this.props;

    try {
      await network.fetch(
        `/api/utilisateurs/${this.props.auth.idUtilisateur}/partners/${partner.idApplicationTierce}`,
        {
          method: "DELETE",
        }
      );

      toastr.success("OK", t("account.partners.remove-success", "L'application tierce a bien été supprimée"));

      loadPartners();
    } catch (error) {
      console.error("an error happened while removing partner", error);
    }
  };

  renderTable() {
    const { partners } = this.props;
    return (
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableBody>
            {partners.map((partner) => (
              <TableRow key={partner.libelle}>
                <TableCell component="th" scope="row">
                  {partner.libelle}
                </TableCell>
                <TableCell align="right" className="p-0">
                  {this.canRemovePartner() && (
                    <Tooltip title="Delete">
                      <IconButton aria-label="delete" onClick={() => this.handleClickDelete(partner)}>
                        <FontAwesomeIcon icon="trash-alt" size="sm" />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  render() {
    if (this.state.loading) {
      return <CircularProgress className="mt-2" />;
    }

    const { t } = this.props;
    return (
      <Card className="w-30 mt-2">
        <CardHeader title={<Typography variant="h3">{t("account.partner", "Application tierce")}</Typography>} />
        <CardContent>
          <Box className={"d-flex flex-column align-items-start"}>
            {this.props.partners.length === 0 && (
              <Button
                className="mb-1"
                color="primary"
                variant="contained"
                component={Link}
                to={{
                  pathname: "/login/ajouter-application-tierce",
                  search: "?redirect=/account",
                }}
              >
                <span>{t("account.add-partner", "Ajouter une connexion MesParcelles")}</span>
              </Button>
            )}
            {this.props.partners.length > 0 && this.renderTable()}
          </Box>
        </CardContent>
      </Card>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Partners)));
