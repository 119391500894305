import React from "react";
import {
  Box,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import NumberInput from "../../../../../common/NumberInput";
import propTypes from "prop-types";
import DatePicker from "../../../../../common/DatePicker";
import Indicator from "../../Indicator";
import clsx from "clsx";
import { connect } from "react-redux";

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
});

class IFT extends React.Component {
  onUpdateIFT = (indicateur) => {
    const { indicateurs, iftRef, sousRubriqueIndex, libelleEnglobantIndex } = this.props;

    const valeur = indicateur.valeur;
    [
      "IFT_herbicide_GC_PT_campagne",
      "IFT_hors_herbicide_GC_PT_campagne",
      "IFT_herbicide_vigne_campagne",
      "IFT_hors_herbicide_vigne_campagne",
    ].forEach((ift) => {
      if (valeur[ift]) {
        let valeurs;
        if (valeur.choix_calcul_moyenne === "triannuelle") {
          valeurs = [valeur[ift].n_2, valeur[ift].n_1, valeur[ift].n];
        } else if (valeur.choix_calcul_moyenne === "biannuelle") {
          valeurs = [valeur[ift].n_1, valeur[ift].n];
        } else {
          valeurs = [valeur[ift].n];
        }

        const valeursNotNull = valeurs.filter((n) => n != null);
        const sommeValeurs = valeursNotNull.reduce((sum, val) => sum + val, 0);
        valeur[ift].moyenne = valeursNotNull.length === 0 ? null : sommeValeurs / valeursNotNull.length;

        const valeurReference = (() => {
          switch (ift) {
            case "IFT_herbicide_GC_PT_campagne":
              return iftRef.GC_PT?.iftHerbicide;
            case "IFT_hors_herbicide_GC_PT_campagne":
              return iftRef.GC_PT?.iftHorsHerbicide;
            case "IFT_herbicide_vigne_campagne":
              return iftRef.vigne?.iftHerbicide;
            case "IFT_hors_herbicide_vigne_campagne":
              return iftRef.vigne?.iftHorsHerbicide;
            default:
              return;
          }
        })();

        if (valeurReference != null && valeur[ift].moyenne != null) {
          switch (ift) {
            case "IFT_herbicide_GC_PT_campagne":
            case "IFT_herbicide_vigne_campagne":
            case "IFT_hors_herbicide_vigne_campagne":
              valeur[ift].note =
                valeur[ift].moyenne >= valeurReference
                  ? 0
                  : valeur[ift].moyenne < valeurReference && valeur[ift].moyenne >= 0.875 * valeurReference
                  ? 1
                  : valeur[ift].moyenne < 0.875 * valeurReference && valeur[ift].moyenne >= 0.75 * valeurReference
                  ? 2
                  : valeur[ift].moyenne < 0.75 * valeurReference && valeur[ift].moyenne >= 0.625 * valeurReference
                  ? 3
                  : valeur[ift].moyenne < 0.625 * valeurReference && valeur[ift].moyenne >= 0.5 * valeurReference
                  ? 4
                  : 5;
              break;
            case "IFT_hors_herbicide_GC_PT_campagne":
              const ratioSAU =
                (indicateurs.HVE3A_D_53?.valeur?.value ?? 0) === 0
                  ? 0
                  : ((indicateurs.HVE3A_D_3?.valeur?.value ?? 0) +
                      (indicateurs.HVE3A_D_8?.valeur?.value ?? 0) +
                      (indicateurs.HVE3A_D_15?.valeur?.value ?? 0) +
                      (indicateurs.HVE3A_D_21?.valeur?.value ?? 0) +
                      (indicateurs.HVE3A_D_24?.valeur?.value ?? 0) +
                      (indicateurs.HVE3A_D_25?.valeur?.value ?? 0) +
                      (indicateurs.HVE3A_D_26?.valeur?.value ?? 0) +
                      (indicateurs.HVE3A_D_27?.valeur?.value ?? 0) +
                      (indicateurs.HVE3A_D_28?.valeur?.value ?? 0)) /
                    ((indicateurs.HVE3A_D_6?.valeur?.value ?? 0) +
                      (indicateurs.HVE3A_D_11?.valeur?.value ?? 0) +
                      (indicateurs.HVE3A_D_16?.valeur?.value ?? 0) +
                      (indicateurs.HVE3A_D_19?.valeur?.value ?? 0) +
                      (indicateurs.HVE3A_D_23?.valeur?.value ?? 0) +
                      (indicateurs.HVE3A_D_24?.valeur?.value ?? 0) +
                      (indicateurs.HVE3A_D_25?.valeur?.value ?? 0) +
                      (indicateurs.HVE3A_D_26?.valeur?.value ?? 0) +
                      (indicateurs.HVE3A_D_27?.valeur?.value ?? 0) +
                      (indicateurs.HVE3A_D_28?.valeur?.value ?? 0) +
                      (indicateurs.HVE3A_D_35?.valeur?.value ?? 0) +
                      (indicateurs.HVE3A_D_36?.valeur?.value ?? 0));
              valeur[ift].note =
                (valeur[ift].moyenne >= valeurReference
                  ? 0
                  : valeur[ift].moyenne < valeurReference && valeur[ift].moyenne >= 0.875 * valeurReference
                  ? 1
                  : valeur[ift].moyenne < 0.875 * valeurReference && valeur[ift].moyenne >= 0.75 * valeurReference
                  ? 2
                  : valeur[ift].moyenne < 0.75 * valeurReference && valeur[ift].moyenne >= 0.625 * valeurReference
                  ? 3
                  : valeur[ift].moyenne < 0.625 * valeurReference && valeur[ift].moyenne >= 0.5 * valeurReference
                  ? 4
                  : valeur[ift].moyenne < 0.5 * valeurReference && valeur[ift].moyenne >= 0.375 * valeurReference
                  ? 5
                  : valeur[ift].moyenne < 0.375 * valeurReference && valeur[ift].moyenne >= 0.25 * valeurReference
                  ? 6
                  : valeur[ift].moyenne < 0.25 * valeurReference && valeur[ift].moyenne >= 0.125 * valeurReference
                  ? 7
                  : 8) +
                /** ajustement forfaitaire :
                 * Si % SAU cultivé en maïs tournesol et prairie temporaire (B38) supérieur ou égal à 30% et inférieur à 60% : -1
                 * Si % SAU cultivé en maïs tournesol et prairie temporaire (B38) supérieur ou égal à 60% : -2
                 * 0 sinon
                 **/
                (ratioSAU >= 0.3 && ratioSAU < 0.6 ? -1 : ratioSAU >= 0.6 ? -2 : 0);
              valeur[ift].note = valeur[ift].note < 0 ? 0 : valeur[ift].note > 5 ? 5 : valeur[ift].note;
              break;
            default:
              break;
          }
        } else {
          delete valeur[ift].note;
        }
      }
    });

    this.props.changeIndicateur({ ...this.props.indicateur, valeur }, sousRubriqueIndex, libelleEnglobantIndex);
  };

  renderSelectRow = () => {
    const { indicateur, disabled, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    return (
      <Box className={"d-flex justify-around align-items-center mb-1"}>
        <TextField
          fullWidth={false}
          style={{ minWidth: "12rem" }}
          select
          label={"Choix du type de calcul de l'IFT"}
          value={indicateur.valeur.choix_type_calcul_IFT}
          disabled={disabled}
          onChange={(event) =>
            this.props.changeIndicateur(
              {
                ...indicateur,
                valeur: {
                  ...indicateur.valeur,
                  choix_type_calcul_IFT: event.target.value,
                },
              },
              sousRubriqueIndex,
              libelleEnglobantIndex
            )
          }
        >
          <MenuItem value={"MAEC"}>MAEC</MenuItem>
          <MenuItem value={"HVE"}>HVE</MenuItem>
        </TextField>
        <TextField
          select
          fullWidth={false}
          style={{ minWidth: "12rem" }}
          label={"Choix du calcul de la moyenne"}
          value={indicateur.valeur.choix_calcul_moyenne || "annee_courante"}
          disabled={disabled}
          onChange={(event) =>
            this.onUpdateIFT({
              ...indicateur,
              valeur: {
                ...indicateur.valeur,
                choix_calcul_moyenne: event.target.value,
              },
            })
          }
        >
          <MenuItem value={"annee_courante"}>Année courante</MenuItem>
          <MenuItem value={"biannuelle"}>Biannuelle</MenuItem>
          <MenuItem value={"triannuelle"}>Triannuelle</MenuItem>
        </TextField>
        <DatePicker
          fullWidth={false}
          disableToolbar
          variant="inline"
          label={"Date d'engagement"}
          disabled={disabled}
          value={indicateur.valeur.date_engagement || null}
          onChange={(value) =>
            this.props.changeIndicateur(
              {
                ...indicateur,
                valeur: {
                  ...indicateur.valeur,
                  date_engagement: value,
                },
              },
              sousRubriqueIndex,
              libelleEnglobantIndex
            )
          }
        />
      </Box>
    );
  };

  renderTableHeader = () => {
    const { indicateur } = this.props,
      showN2 = indicateur.valeur.choix_calcul_moyenne === "triannuelle",
      showN1 = ["triannuelle", "biannuelle"].includes(indicateur.valeur.choix_calcul_moyenne);
    return (
      <TableHead>
        <TableRow>
          <TableCell align="center">Indicateur</TableCell>
          {showN2 && <TableCell align="center">N-2</TableCell>}
          {showN1 && <TableCell align="center">N-1</TableCell>}
          <TableCell align="center">N</TableCell>
          <TableCell align="center">Moyenne</TableCell>
          <TableCell align="center">Valeur de référence régionale</TableCell>
          <TableCell align="center">Note</TableCell>
        </TableRow>
      </TableHead>
    );
  };

  renderGCPT = () => {
    const { indicateur, iftRef, disabled } = this.props,
      valeurCalculee = indicateur.valeurCalculee,
      showN2 = indicateur.valeur.choix_calcul_moyenne === "triannuelle",
      showN1 = ["triannuelle", "biannuelle"].includes(indicateur.valeur.choix_calcul_moyenne),
      showValeurCalculee = valeurCalculee !== null;
    if (valeurCalculee != null) {
      if (indicateur.valeur.choix_type_calcul_IFT === "HVE") {
        valeurCalculee.IFT_herbicide_GC_PT_campagne = valeurCalculee.IFT_herbicide_GC_PT_campagne_HVE;
        valeurCalculee.IFT_hors_herbicide_GC_PT_campagne = valeurCalculee.IFT_hors_herbicide_GC_PT_campagne_HVE;
      } else {
        valeurCalculee.IFT_herbicide_GC_PT_campagne = valeurCalculee.IFT_herbicide_GC_PT_campagne_MAEC;
        valeurCalculee.IFT_hors_herbicide_GC_PT_campagne = valeurCalculee.IFT_hors_herbicide_GC_PT_campagne_MAEC;
      }
    }

    return (
      <React.Fragment>
        <TableRow>
          <TableCell>IFT herbicide GC PT Campagne</TableCell>
          {showN2 && (
            <TableCell style={{ verticalAlign: "bottom" }}>
              {showValeurCalculee && valeurCalculee.IFT_herbicide_GC_PT_campagne?.n_2 !== null && (
                <NumberInput
                  disabled
                  className="mb-1"
                  label={"Valeur N-2 MesParcelles"}
                  value={valeurCalculee.IFT_herbicide_GC_PT_campagne?.n_2}
                  numberOfDecimals={2}
                />
              )}
              <NumberInput
                label={"Valeur N-2"}
                value={indicateur.valeur.IFT_herbicide_GC_PT_campagne?.n_2 ?? ""}
                disabled={disabled}
                onChange={(value) =>
                  this.onUpdateIFT({
                    ...indicateur,
                    valeur: {
                      ...indicateur.valeur,
                      IFT_herbicide_GC_PT_campagne: {
                        ...indicateur.valeur.IFT_herbicide_GC_PT_campagne,
                        n_2: value === "" || value == null ? null : Number(value),
                      },
                    },
                  })
                }
                numberOfDecimals={2}
              />
            </TableCell>
          )}
          {showN1 && (
            <TableCell style={{ verticalAlign: "bottom" }}>
              {showValeurCalculee && valeurCalculee.IFT_herbicide_GC_PT_campagne?.n_1 !== null && (
                <NumberInput
                  disabled
                  className="mb-1"
                  label={"Valeur N-1 MesParcelles"}
                  value={valeurCalculee.IFT_herbicide_GC_PT_campagne?.n_1}
                  numberOfDecimals={2}
                />
              )}
              <NumberInput
                label={"Valeur N-1"}
                value={indicateur.valeur.IFT_herbicide_GC_PT_campagne?.n_1 ?? ""}
                disabled={disabled}
                onChange={(value) =>
                  this.onUpdateIFT({
                    ...indicateur,
                    valeur: {
                      ...indicateur.valeur,
                      IFT_herbicide_GC_PT_campagne: {
                        ...indicateur.valeur.IFT_herbicide_GC_PT_campagne,
                        n_1: value === "" || value == null ? null : Number(value),
                      },
                    },
                  })
                }
                numberOfDecimals={2}
              />
            </TableCell>
          )}
          <TableCell style={{ verticalAlign: "bottom" }}>
            {showValeurCalculee && valeurCalculee.IFT_herbicide_GC_PT_campagne?.n !== null && (
              <NumberInput
                disabled
                className="mb-1"
                label={"Valeur N MesParcelles"}
                value={valeurCalculee.IFT_herbicide_GC_PT_campagne?.n}
                numberOfDecimals={2}
              />
            )}
            <NumberInput
              label={"Valeur N"}
              value={indicateur.valeur.IFT_herbicide_GC_PT_campagne?.n ?? ""}
              disabled={disabled}
              onChange={(value) =>
                this.onUpdateIFT({
                  ...indicateur,
                  valeur: {
                    ...indicateur.valeur,
                    IFT_herbicide_GC_PT_campagne: {
                      ...indicateur.valeur.IFT_herbicide_GC_PT_campagne,
                      n: value === "" || value == null ? null : Number(value),
                    },
                  },
                })
              }
              numberOfDecimals={2}
            />
          </TableCell>
          <TableCell style={{ verticalAlign: "bottom" }}>
            <NumberInput
              label={"Moyenne"}
              disabled
              value={indicateur.valeur.IFT_herbicide_GC_PT_campagne?.moyenne ?? ""}
              numberOfDecimals={2}
            />
          </TableCell>
          <TableCell style={{ verticalAlign: "bottom" }}>
            <NumberInput
              disabled
              label={"Valeur de référence"}
              value={iftRef.GC_PT?.iftHerbicide ?? ""}
              numberOfDecimals={2}
            />
          </TableCell>
          <TableCell style={{ verticalAlign: "bottom" }}>
            <TextField
              label={"Note"}
              disabled
              value={indicateur.valeur.IFT_herbicide_GC_PT_campagne?.note?.toString() ?? ""}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>IFT hors herbicide GC PT Campagne</TableCell>
          {showN2 && (
            <TableCell style={{ verticalAlign: "bottom" }}>
              {showValeurCalculee && valeurCalculee.IFT_hors_herbicide_GC_PT_campagne?.n_2 !== null && (
                <NumberInput
                  disabled
                  className="mb-1"
                  label={"Valeur N-2 MesParcelles"}
                  value={valeurCalculee.IFT_hors_herbicide_GC_PT_campagne?.n_2}
                  numberOfDecimals={2}
                />
              )}
              <NumberInput
                label={"Valeur N-2"}
                value={indicateur.valeur.IFT_hors_herbicide_GC_PT_campagne?.n_2 ?? ""}
                disabled={disabled}
                onChange={(value) =>
                  this.onUpdateIFT({
                    ...indicateur,
                    valeur: {
                      ...indicateur.valeur,
                      IFT_hors_herbicide_GC_PT_campagne: {
                        ...indicateur.valeur.IFT_hors_herbicide_GC_PT_campagne,
                        n_2: value === "" || value == null ? null : Number(value),
                      },
                    },
                  })
                }
                numberOfDecimals={2}
              />
            </TableCell>
          )}
          {showN1 && (
            <TableCell style={{ verticalAlign: "bottom" }}>
              {showValeurCalculee && valeurCalculee.IFT_hors_herbicide_GC_PT_campagne?.n_1 !== null && (
                <NumberInput
                  disabled
                  className="mb-1"
                  label={"Valeur N-1 MesParcelles"}
                  value={valeurCalculee.IFT_hors_herbicide_GC_PT_campagne?.n_1}
                  numberOfDecimals={2}
                />
              )}
              <NumberInput
                label={"Valeur N-1"}
                value={indicateur.valeur.IFT_hors_herbicide_GC_PT_campagne?.n_1 ?? ""}
                disabled={disabled}
                onChange={(value) =>
                  this.onUpdateIFT({
                    ...indicateur,
                    valeur: {
                      ...indicateur.valeur,
                      IFT_hors_herbicide_GC_PT_campagne: {
                        ...indicateur.valeur.IFT_hors_herbicide_GC_PT_campagne,
                        n_1: value === "" || value == null ? null : Number(value),
                      },
                    },
                  })
                }
                numberOfDecimals={2}
              />
            </TableCell>
          )}
          <TableCell style={{ verticalAlign: "bottom" }}>
            {showValeurCalculee && valeurCalculee.IFT_hors_herbicide_GC_PT_campagne?.n !== null && (
              <NumberInput
                disabled
                className="mb-1"
                label={"Valeur N MesParcelles"}
                value={valeurCalculee.IFT_hors_herbicide_GC_PT_campagne?.n}
                numberOfDecimals={2}
              />
            )}
            <NumberInput
              label={"Valeur N"}
              value={indicateur.valeur.IFT_hors_herbicide_GC_PT_campagne?.n ?? ""}
              disabled={disabled}
              onChange={(value) =>
                this.onUpdateIFT({
                  ...indicateur,
                  valeur: {
                    ...indicateur.valeur,
                    IFT_hors_herbicide_GC_PT_campagne: {
                      ...indicateur.valeur.IFT_hors_herbicide_GC_PT_campagne,
                      n: value === "" || value == null ? null : Number(value),
                    },
                  },
                })
              }
              numberOfDecimals={2}
            />
          </TableCell>
          <TableCell style={{ verticalAlign: "bottom" }}>
            <NumberInput
              label={"Moyenne"}
              disabled
              value={indicateur.valeur.IFT_hors_herbicide_GC_PT_campagne?.moyenne ?? ""}
              numberOfDecimals={2}
            />
          </TableCell>
          <TableCell style={{ verticalAlign: "bottom" }}>
            <NumberInput
              disabled
              label={"Valeur de référence"}
              value={iftRef.GC_PT?.iftHorsHerbicide ?? ""}
              numberOfDecimals={2}
            />
          </TableCell>
          <TableCell style={{ verticalAlign: "bottom" }}>
            <TextField
              label={"Note"}
              disabled
              value={indicateur.valeur.IFT_hors_herbicide_GC_PT_campagne?.note?.toString() ?? ""}
            />
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  renderViti = () => {
    const { indicateur, iftRef, disabled } = this.props,
      valeurCalculee = indicateur.valeurCalculee,
      showN2 = indicateur.valeur.choix_calcul_moyenne === "triannuelle",
      showN1 = ["triannuelle", "biannuelle"].includes(indicateur.valeur.choix_calcul_moyenne),
      showValeurCalculee = valeurCalculee !== null;

    if (valeurCalculee != null) {
      if (indicateur.valeur.choix_type_calcul_IFT === "HVE") {
        valeurCalculee.IFT_herbicide_vigne_campagne = valeurCalculee.IFT_herbicide_vigne_campagne_HVE;
        valeurCalculee.IFT_hors_herbicide_vigne_campagne = valeurCalculee.IFT_hors_herbicide_vigne_campagne_HVE;
      } else {
        valeurCalculee.IFT_herbicide_vigne_campagne = valeurCalculee.IFT_herbicide_vigne_campagne_MAEC;
        valeurCalculee.IFT_hors_herbicide_vigne_campagne = valeurCalculee.IFT_hors_herbicide_vigne_campagne_MAEC;
      }
    }
    return (
      <React.Fragment>
        <TableRow>
          <TableCell>IFT herbicide Vigne Campagne</TableCell>
          {showN2 && (
            <TableCell style={{ verticalAlign: "bottom" }}>
              {showValeurCalculee && valeurCalculee.IFT_herbicide_vigne_campagne?.n_2 !== null && (
                <NumberInput
                  disabled
                  className="mb-1"
                  label={"Valeur N-2 MesParcelles"}
                  value={valeurCalculee.IFT_herbicide_vigne_campagne?.n_2}
                  numberOfDecimals={2}
                />
              )}
              <NumberInput
                label={"Valeur N-2"}
                value={indicateur.valeur.IFT_herbicide_vigne_campagne?.n_2 ?? ""}
                disabled={disabled}
                onChange={(value) =>
                  this.onUpdateIFT({
                    ...indicateur,
                    valeur: {
                      ...indicateur.valeur,
                      IFT_herbicide_vigne_campagne: {
                        ...indicateur.valeur.IFT_herbicide_vigne_campagne,
                        n_2: value === "" || value == null ? null : Number(value),
                      },
                    },
                  })
                }
                numberOfDecimals={2}
              />
            </TableCell>
          )}
          {showN1 && (
            <TableCell style={{ verticalAlign: "bottom" }}>
              {showValeurCalculee && valeurCalculee.IFT_herbicide_vigne_campagne?.n_1 !== null && (
                <NumberInput
                  disabled
                  className="mb-1"
                  label={"Valeur N-1 MesParcelles"}
                  value={valeurCalculee.IFT_herbicide_vigne_campagne?.n_1}
                  numberOfDecimals={2}
                />
              )}
              <NumberInput
                label={"Valeur N-1"}
                value={indicateur.valeur.IFT_herbicide_vigne_campagne?.n_1 ?? ""}
                disabled={disabled}
                onChange={(value) =>
                  this.onUpdateIFT({
                    ...indicateur,
                    valeur: {
                      ...indicateur.valeur,
                      IFT_herbicide_vigne_campagne: {
                        ...indicateur.valeur.IFT_herbicide_vigne_campagne,
                        n_1: value === "" || value == null ? null : Number(value),
                      },
                    },
                  })
                }
                numberOfDecimals={2}
              />
            </TableCell>
          )}
          <TableCell style={{ verticalAlign: "bottom" }}>
            {showValeurCalculee && valeurCalculee.IFT_herbicide_vigne_campagne?.n !== null && (
              <NumberInput
                disabled
                className="mb-1"
                label={"Valeur N MesParcelles"}
                value={valeurCalculee.IFT_herbicide_vigne_campagne?.n}
                numberOfDecimals={2}
              />
            )}
            <NumberInput
              label={"Valeur N"}
              value={indicateur.valeur.IFT_herbicide_vigne_campagne?.n ?? ""}
              disabled={disabled}
              onChange={(value) =>
                this.onUpdateIFT({
                  ...indicateur,
                  valeur: {
                    ...indicateur.valeur,
                    IFT_herbicide_vigne_campagne: {
                      ...indicateur.valeur.IFT_herbicide_vigne_campagne,
                      n: value === "" || value == null ? null : Number(value),
                    },
                  },
                })
              }
              numberOfDecimals={2}
            />
          </TableCell>
          <TableCell style={{ verticalAlign: "bottom" }}>
            <NumberInput
              label={"Moyenne"}
              disabled
              value={indicateur.valeur.IFT_herbicide_vigne_campagne?.moyenne ?? ""}
              numberOfDecimals={2}
            />
          </TableCell>
          <TableCell style={{ verticalAlign: "bottom" }}>
            <NumberInput
              disabled
              label={"Valeur de référence"}
              value={iftRef.vigne?.iftHerbicide ?? ""}
              numberOfDecimals={2}
            />
          </TableCell>
          <TableCell style={{ verticalAlign: "bottom" }}>
            <TextField
              label={"Note"}
              disabled
              value={indicateur.valeur.IFT_herbicide_vigne_campagne?.note?.toString() ?? ""}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>IFT hors herbicide Vigne Campagne</TableCell>
          {showN2 && (
            <TableCell style={{ verticalAlign: "bottom" }}>
              {showValeurCalculee && valeurCalculee.IFT_hors_herbicide_vigne_campagne?.n_2 !== null && (
                <NumberInput
                  disabled
                  className="mb-1"
                  label={"Valeur N-2 MesParcelles"}
                  value={valeurCalculee.IFT_hors_herbicide_vigne_campagne?.n_2}
                  numberOfDecimals={2}
                />
              )}
              <NumberInput
                label={"Valeur N-2"}
                value={indicateur.valeur.IFT_hors_herbicide_vigne_campagne?.n_2 ?? ""}
                disabled={disabled}
                numberOfDecimals={2}
                onChange={(value) =>
                  this.onUpdateIFT({
                    ...indicateur,
                    valeur: {
                      ...indicateur.valeur,
                      IFT_hors_herbicide_vigne_campagne: {
                        ...indicateur.valeur.IFT_hors_herbicide_vigne_campagne,
                        n_2: value === "" || value == null ? null : Number(value),
                      },
                    },
                  })
                }
              />
            </TableCell>
          )}
          {showN1 && (
            <TableCell style={{ verticalAlign: "bottom" }}>
              {showValeurCalculee && valeurCalculee.IFT_hors_herbicide_vigne_campagne?.n_1 !== null && (
                <NumberInput
                  disabled
                  className="mb-1"
                  label={"Valeur N-1 MesParcelles"}
                  value={valeurCalculee.IFT_hors_herbicide_vigne_campagne?.n_1}
                  numberOfDecimals={2}
                />
              )}
              <NumberInput
                label={"Valeur N-1"}
                value={indicateur.valeur.IFT_hors_herbicide_vigne_campagne?.n_1 ?? ""}
                disabled={disabled}
                onChange={(value) =>
                  this.onUpdateIFT({
                    ...indicateur,
                    valeur: {
                      ...indicateur.valeur,
                      IFT_hors_herbicide_vigne_campagne: {
                        ...indicateur.valeur.IFT_hors_herbicide_vigne_campagne,
                        n_1: value === "" || value == null ? null : Number(value),
                      },
                    },
                  })
                }
                numberOfDecimals={2}
              />
            </TableCell>
          )}
          <TableCell style={{ verticalAlign: "bottom" }}>
            {showValeurCalculee && valeurCalculee.IFT_hors_herbicide_vigne_campagne?.n !== null && (
              <NumberInput
                disabled
                className="mb-1"
                label={"Valeur N MesParcelles"}
                value={valeurCalculee.IFT_hors_herbicide_vigne_campagne?.n}
                numberOfDecimals={2}
              />
            )}
            <NumberInput
              label={"Valeur N"}
              value={indicateur.valeur.IFT_hors_herbicide_vigne_campagne?.n ?? ""}
              disabled={disabled}
              onChange={(value) =>
                this.onUpdateIFT({
                  ...indicateur,
                  valeur: {
                    ...indicateur.valeur,
                    IFT_hors_herbicide_vigne_campagne: {
                      ...indicateur.valeur.IFT_hors_herbicide_vigne_campagne,
                      n: value === "" || value == null ? null : Number(value),
                    },
                  },
                })
              }
              numberOfDecimals={2}
            />
          </TableCell>
          <TableCell style={{ verticalAlign: "bottom" }}>
            <NumberInput
              label={"Moyenne"}
              disabled
              value={indicateur.valeur.IFT_hors_herbicide_vigne_campagne?.moyenne ?? ""}
              numberOfDecimals={2}
            />
          </TableCell>
          <TableCell style={{ verticalAlign: "bottom" }}>
            <NumberInput
              disabled
              label={"Valeur de référence"}
              value={iftRef.vigne?.iftHorsHerbicide ?? ""}
              numberOfDecimals={2}
            />
          </TableCell>
          <TableCell style={{ verticalAlign: "bottom" }}>
            <TextField
              label={"Note"}
              disabled
              value={indicateur.valeur.IFT_hors_herbicide_vigne_campagne?.note?.toString() ?? ""}
            />
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  };

  getValueRendered = () => {
    const { famillesCultures, auth } = this.props,
      codesFamilles = famillesCultures.map((f) => f.codeNational),
      isVitiSelected = codesFamilles.includes("VITIC"),
      isGC_PTSelected = codesFamilles.includes("GRANCULT") || codesFamilles.includes("PRAIRS");
    return (
      (isVitiSelected || isGC_PTSelected) && (
        <Box className={"w-100"}>
          {this.renderSelectRow()}
          <Table className={clsx({ "mb--3": auth.role !== "AGRICULTEUR" })}>
            {this.renderTableHeader()}
            <TableBody>
              {isGC_PTSelected && this.renderGCPT()}
              {isVitiSelected && this.renderViti()}
            </TableBody>

            {auth.role !== "AGRICULTEUR" && (
              <Typography
                className={clsx({ "mt-3": isGC_PTSelected || isVitiSelected }, "ml-1")}
                style={{ fontWeight: "bold", position: "relative" }}
              >
                Validité de l'IFT
              </Typography>
            )}
          </Table>
        </Box>
      )
    );
  };

  render() {
    const { indicateur, onChangeIndicator } = this.props;

    return (
      <Box
        boxShadow={0.2}
        border={0.5}
        borderColor={"lightgrey"}
        borderRadius={15}
        paddingTop={0.5}
        paddingBottom={1}
        marginBottom={0.5}
      >
        <Indicator indicateur={indicateur} onChange={onChangeIndicator} alignScoreToTheEnd>
          {this.getValueRendered()}
        </Indicator>
      </Box>
    );
  }
}

IFT.propTypes = {
  indicateur: propTypes.object.isRequired,
  disabled: propTypes.bool,
  indicateurs: propTypes.object.isRequired,
  changeIndicateur: propTypes.func.isRequired,
};

export default connect(mapStateToProps)(IFT);
