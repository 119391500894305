import React from "react";
import PropTypes from "prop-types";
import { getDateFormat } from "../../scripts/utils";
import { KeyboardDatePicker as FormikKeyboardDatePicker } from "formik-material-ui-pickers";
import { KeyboardDatePicker } from "@material-ui/pickers";

class DatePicker extends React.Component {
  render() {
    const isFormikComponent = !!this.props.field && !!this.props.form;
    const Component = isFormikComponent ? FormikKeyboardDatePicker : KeyboardDatePicker;

    return <Component {...this.props} format={this.props.format || getDateFormat()} />;
  }
}

DatePicker.propTypes = {
  format: PropTypes.string,
  variant: PropTypes.string,
  autoOk: PropTypes.bool,
  disableToolbar: PropTypes.bool,
  style: PropTypes.object,
};
DatePicker.defaultProps = {
  autoOk: true,
  disableToolbar: true,
  style: { width: 200 },
  variant: "inline",
};
export default DatePicker;
