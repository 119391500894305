import React from "react";
import NumberInput from "../../../../../common/NumberInput";
import Indicator from "../../Indicator";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

class Decimal extends React.Component {
  render() {
    const {
      t,
      indicateur,
      onChangeIndicator,
      sousRubriqueIndex,
      libelleEnglobantIndex,
      changeIndicateur,
      valueInputProps,
      computedFields,
      demarche,
    } = this.props;

    const valeurCalculee = (
      <NumberInput
        style={{ maxWidth: "8rem" }}
        fullWidth={false}
        InputProps={valueInputProps}
        disabled
        value={indicateur.valeurCalculee?.value ?? ""}
        label={t("hve3a.valeur-mesparcelles", "Valeur MesParcelles")}
        numberOfDecimals={2}
        className={"mr-2"}
      />
    );

    const valueRendered = (
      <NumberInput
        fullWidth={false}
        value={indicateur.valeur.value ?? ""}
        InputProps={valueInputProps}
        allowNegative={true}
        disabled={computedFields.includes(indicateur.codeNational)}
        style={{
          fontWeight: indicateur.afficherGras ? "bold" : "normal",
          maxWidth: "8rem",
        }}
        onChange={(newValeur) =>
          changeIndicateur(
            { ...indicateur, valeur: { value: newValeur === "" ? null : Number(newValeur) } },
            sousRubriqueIndex,
            libelleEnglobantIndex
          )
        }
        numberOfDecimals={2}
      />
    );

    return (
      <Indicator indicateur={indicateur} onChange={onChangeIndicator} demarche={demarche}>
        {!!indicateur.valeurCalculee && valeurCalculee}
        {valueRendered}
      </Indicator>
    );
  }
}

export default compose(withTranslation())(Decimal);
