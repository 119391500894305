import { SET_SELECTED_MILLESIME, SET_MILLESIMES } from "../actions/millesime";

// init config state
const millesimeInitialState = {
  selected: null,
  list: [],
};

const millesime = (state = millesimeInitialState, action) => {
  switch (action.type) {
    case SET_SELECTED_MILLESIME:
      return { ...state, selected: action.payload };
    case SET_MILLESIMES:
      return {
        ...state,
        list: action.payload,
        selected:
          state.selected == null || !state.list.some((m) => m.idMillesime === state.selected.idMillesime)
            ? action.payload[0]
            : state.selected,
      };
    default:
      return state;
  }
};
export default millesime;
