import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { CircularProgress } from "@material-ui/core";
import network from "../../../actions/external/network";
import DemarcheForm from "./DemarcheForm";
import { toastr } from "react-redux-toastr";
import { compose } from "redux";

const mapStateToProps = (state) => ({
  idExploitation: state.exploitation.selected.idExploitation,
  millesime: state.millesime.selected,
  millesimes: state.millesime.list,
  app: state.app,
});

class Demarche extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      demarche: null,
      exploitationDemarcheInfo: null,
      organismes: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { t, idExploitation, millesime, match } = this.props;
    try {
      const [demarche, exploitationDemarcheInfo, organismes, certificationsEquivalentes] = await Promise.all([
        network.fetch(`/api/demarches/${match.params.codeNational}`),
        network.fetch(
          `/api/exploitations/${idExploitation}/demarches/${match.params.codeNational}?millesime=${millesime.idMillesime}`
        ),
        network.fetch(`/api/demarches/${match.params.codeNational}/organismes`),
        network.fetch(`/api/certifications-equivalentes`),
      ]);
      organismes.sort((orga1, orga2) => (orga1.nom.toLowerCase() < orga2.nom.toLowerCase() ? -1 : 1));

      if (!this.props.showInfos) {
        // Démarche déjà commencée => rediriger vers les indicateurs
        // Test sur le statutLastChangeIdUtilisateur pour envoyer les exploits importées sur la page d'infos lors de la 1ère connection
        if (
          exploitationDemarcheInfo.exploitationDemarcheMillesime?.idMillesime === millesime.idMillesime &&
          exploitationDemarcheInfo.exploitationDemarcheMillesime?.statutLastChangeIdUtilisateur !== null
        ) {
          this.props.history.replace(`/demarches/${match.params.codeNational}/indicateurs`);
          return;
        }
        this.props.history.replace(`/demarches/${match.params.codeNational}/infos`);
      }

      this.setState({
        loading: false,
        demarche: demarche,
        exploitationDemarcheInfo: exploitationDemarcheInfo,
        organismes: organismes,
        certificationsEquivalentes: certificationsEquivalentes,
      });
    } catch (error) {
      toastr.error(t("error", "Erreur"), error.message);
      this.props.history.replace("/");
    }
  };

  onSubmit = async (values) => {
    const { t, idExploitation, millesime, match } = this.props;
    const idOrganisme = "";
    try {
      const newExploitationDemarcheInfo = await network.fetch(
        `/api/exploitations/${idExploitation}/demarches/${match.params.codeNational}?millesime=${millesime.idMillesime}${idOrganisme}`,
        {
          method: "POST",
          body: JSON.stringify(values),
        }
      );

      this.setState({ exploitationDemarcheInfo: newExploitationDemarcheInfo });
      toastr.success("OK", t("demarche.save-success", "La démarche a bien été sauvegardée"));
      this.props.history.replace(`/demarches/${match.params.codeNational}/indicateurs`);
    } catch (error) {
      toastr.error(t("error", "Erreur"), t("demarche.save-error", "Erreur lors de la sauvegarde de la démarche"));
    }
  };

  render() {
    if (this.state.loading) {
      return <CircularProgress className="mt-2" />;
    }

    return (
      <DemarcheForm
        demarche={this.state.demarche}
        exploitationDemarcheInfo={this.state.exploitationDemarcheInfo}
        organismes={this.state.organismes}
        certificationsEquivalentes={this.state.certificationsEquivalentes}
        onSubmit={this.onSubmit}
      />
    );
  }
}

export default compose(withTranslation(), connect(mapStateToProps), withRouter)(Demarche);
