import React from "react";
import PropTypes from "prop-types";
import { Dialog, IconButton, Card, CardHeader, CardContent } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class SigaModal extends React.Component {
  render() {
    const { onClose, showCloseButton, title, classes, heightModal, ...otherProps } = this.props;

    return (
      <Dialog onClose={onClose} classes={{ paper: classes.paper }} {...otherProps} style={{ height: heightModal }}>
        <Card className={classes.card}>
          <CardHeader
            title={title}
            subheader={
              showCloseButton && (
                <IconButton
                  type="button"
                  onClick={onClose}
                  style={{ position: "absolute", top: "0.5rem", right: "0.5rem" }}
                >
                  <FontAwesomeIcon icon="times" style={{ height: "20px", width: "20px" }} />
                </IconButton>
              )
            }
          />
          <CardContent>{this.props.children}</CardContent>
        </Card>
      </Dialog>
    );
  }
}
SigaModal.propTypes = {
  showCloseButton: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.node,
  heightModal: PropTypes.number,
};
SigaModal.defaultProps = {
  showCloseButton: true,
  maxWidth: "md",
  scroll: "paper",
};

export default withStyles((theme) => ({
  paper: {
    minWidth: 600,
    maxWidth: 1200,
  },
  card: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    overflow: "auto",
  },
}))(SigaModal);
