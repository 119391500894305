import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Grid, InputAdornment, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
import clsx from "clsx";
import styles from "./sousrubriquestyles.module.scss";
import ScoreRubrique from "../indicateurs/ScoreRubrique";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class FertiSynthese extends React.Component {
  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    if (newIndicateur.codeNational === "HVE4_FERTI_S_10") {
      changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
    }
  };

  render() {
    const { indicateurs, computedFields } = this.props;

    const indicBilanAzote =
      indicateurs["HVE4_FERTI_D_103"]?.valeur?.value === "Balance Globale Azotée"
        ? indicateurs["HVE4_FERTI_D_102"]?.valeur?.value
          ? "HVE4_FERTI_S_1"
          : "HVE4_FERTI_S_11"
        : indicateurs["HVE4_FERTI_D_102"]?.valeur?.value
        ? indicateurs["HVE4_FERTI_D_101"]?.valeur?.value === "Autre"
          ? "HVE4_FERTI_S_11"
          : "HVE4_FERTI_S_12"
        : indicateurs["HVE4_FERTI_D_101"]?.valeur?.value === "Autre"
        ? "HVE4_FERTI_S_1"
        : "HVE4_FERTI_S_2";

    const indicateursKeys = [
      indicBilanAzote,
      "HVE4_FERTI_S_26",
      "HVE4_FERTI_S_30",
      "HVE4_FERTI_S_402",
      "HVE4_FERTI_S_50",
      "HVE4_FERTI_S_60",
      "HVE4_FERTI_S_782",
      "HVE4_FERTI_S_81",
      "HVE4_FERTI_S_920",
    ];

    const indicateursToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateursKeys.includes(key)))
    );

    if (indicateursToDisplay.length > 0)
      return (
        <Grid container>
          <Grid item xs={7}>
            <Table
              style={{
                tableLayout: "fixed",
              }}
              className={"mt-3 mb-3"}
            >
              <TableBody>
                {indicateursKeys.map(
                  (indic, i) =>
                    indicateurs[indic] && (
                      <TableRow key={i}>
                        <TableCell className={clsx(styles.tab_cell_border_top, styles.bold_content)}>
                          {indicateurs[indic]?.libelle}
                        </TableCell>
                        <TableCell className={clsx(styles.tab_cell_border_top)} align="center">
                          <DecimalPositif
                            size={"small"}
                            indicateur={indicateurs[indic]}
                            key={"indicateur-" + indicateurs[indic]?.idIndicateur}
                            changeIndicateur={this.handleChangeIndicateur}
                            valueInputProps={
                              indicateurs[indic]?.libelleUnite != null && {
                                endAdornment: (
                                  <InputAdornment position={"end"}>{indicateurs[indic]?.libelleUnite}</InputAdornment>
                                ),
                              }
                            }
                            computedFields={computedFields}
                          />
                        </TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>

            <ScoreRubrique
              size={"small"}
              indicateur={indicateurs["HVE4_FERTI_S_10"]}
              key={"indicateur-" + indicateurs["HVE4_FERTI_S_10"]?.idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              computedFields={computedFields}
            />
          </Grid>
        </Grid>
      );
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(FertiSynthese);
