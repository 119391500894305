import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  InputAdornment,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";
import HVE3AStatic from "../HVE3A/HVE3AStatic";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withTheme from "@material-ui/styles/withTheme";
import confirm from "../../../../common/confirm";
import IFT from "./indicateurs/IFT";
import DiversiteVarietale from "./indicateurs/DiversiteVarietale";
import NumeriqueEntier from "./indicateurs/NumeriqueEntier";
import ListeMethodeAlternative from "./indicateurs/ListeMethodeAlternative";
import OverrideAssolementValue from "./indicateurs/OverrideAssolementValue";
import DecimalPositif from "./indicateurs/DecimalPositif";
import Decimal from "./indicateurs/Decimal";
import Assolement from "./indicateurs/Assolement";
import ListeMateriel from "./indicateurs/ListeMateriel";
import ListePrairiePermanente from "./indicateurs/ListePrairiePermanente";
import Percent from "./indicateurs/Percent";
import DecimalEntre0Et1 from "./indicateurs/DecimalEntre0Et1";
import OADPPF from "./indicateurs/OADPPF";
import OADMT from "./indicateurs/OADMT";
import Boolean from "./indicateurs/Boolean";
import ChoixBGABilanApparentBilanCorpen from "./indicateurs/ChoixBGABilanApparentBilanCorpen";
import Default from "./indicateurs/Default";
import TexteMultiligne from "./indicateurs/TexteMultiligne";
import BilanStrategiePhyto from "./indicateurs/BilanStrategiePhyto";
import SET from "./indicateurs/SET";
import Date from "./indicateurs/Date";
import SaisieDirecte from "./indicateurs/SaisieDirecte";
import Scoring from "./indicateurs/Scoring";
import ListeInterRang from "./indicateurs/ListeInterRang";
import ListeFourrage from "./indicateurs/ListeFourrage";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class Indicateurs extends React.Component {
  renderHeader = () => {
    const { t, typesRubriques, typeRubriqueSelected, rubriqueSelected, clickNext } = this.props;

    return (
      <CardHeader
        title={
          <Box className="d-flex justify-between">
            <Box className="d-flex">
              <Typography variant={"overline"} display={"inline"}>
                {typesRubriques.reduce(
                  (result, typeRubrique, index) =>
                    result + (index < typeRubriqueSelected ? typeRubrique.rubriques.length : 0),
                  0
                ) +
                  rubriqueSelected +
                  1}
                /{typesRubriques.reduce((result, typeRubrique) => result + typeRubrique.rubriques.length, 0)}
              </Typography>
              <Typography
                variant={"h3"}
                display={"inline"}
                className="mr-2"
                style={{
                  lineHeight: "1.5rem",
                  paddingTop: "0.5rem",
                }}
              >
                <Box style={{ paddingLeft: "0.4em" }}>
                  {typesRubriques[typeRubriqueSelected]?.rubriques[rubriqueSelected].libelle}
                </Box>
              </Typography>
              <Chip label={<Typography variant={"h4"}>{typesRubriques[typeRubriqueSelected]?.libelle}</Typography>} />
              {this.renderRepriseButton()}
            </Box>
            <Box>
              <Button variant="contained" className="mr-2 ml-2" onClick={() => this.props.history.goBack()}>
                Annuler
              </Button>
              <Tooltip
                title={
                  typeRubriqueSelected === typesRubriques.length - 1 &&
                  rubriqueSelected === typesRubriques[typeRubriqueSelected]?.rubriques.length - 1
                    ? t("tooltip.enregistrer", "Enregistrer les données")
                    : t("tooltip.etape-suivante", "Enregistrer et passer à l'étape suivante")
                }
                placement={"bottom"}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={clickNext}
                  endIcon={<FontAwesomeIcon icon="chevron-right" />}
                >
                  {typeRubriqueSelected === typesRubriques.length - 1 &&
                  rubriqueSelected === typesRubriques[typeRubriqueSelected]?.rubriques.length - 1
                    ? t("button.enregistrer", "Enregistrer")
                    : t("button.etape-suivante", "Étape suivante")}
                </Button>
              </Tooltip>
            </Box>
          </Box>
        }
        disableTypography={false}
      />
    );
  };

  renderRepriseButton = () => {
    const {
      t,
      doReprise,
      typesRubriques,
      typeRubriqueSelected,
      rubriqueSelected,
      exploitationsTierces,
      demarche,
    } = this.props;
    const currentRubrique = typesRubriques[typeRubriqueSelected]?.rubriques[rubriqueSelected];

    let showButton = null;
    if (demarche.code === HVE3AStatic.codeNational) {
      showButton =
        currentRubrique?.codeNational === "BIO_ASSOLEMENT_DONNEES" ||
        (exploitationsTierces.length > 0 &&
          [
            "BIO_SET_DONNEES",
            "STRAT_PHYTO_DONNEES",
            "FERTI_DONNEES",
            "IRRIG_DONNEES",
            "STRAT_PHYTO_INDICATEURS",
            "FERTI_INDICATEURS",
          ].includes(currentRubrique.codeNational));
    }

    const onClick = async () => {
      let message = "Les données saisies vont être mises à jour avec les valeurs de ";
      if (demarche.code === HVE3AStatic.codeNational) {
        if (currentRubrique.codeNational === "BIO_ASSOLEMENT_DONNEES" && exploitationsTierces.length > 0) {
          message += "MesParcelles et de l'assolement";
        } else if (currentRubrique.codeNational === "BIO_ASSOLEMENT_DONNEES") {
          message += "l'assolement";
        } else {
          message += "MesParcelles";
        }
        message += ", seules les données valides ne seront pas modifiées, confirmez-vous ?";
      }

      if (await confirm(message)) {
        doReprise();
      }
    };

    if (showButton) {
      return (
        <Button color="primary" className="ml-1" onClick={onClick}>
          <FontAwesomeIcon icon="arrow-alt-circle-right" className="mr-05" />
          {t("reprise-button", "Reprise des données")}
        </Button>
      );
    }
  };

  renderIndicateur = (indicateur, sousRubriqueIndex, libelleEnglobantIndex) => {
    const {
      indicateurs,
      iftRef,
      changeIndicateur,
      getValueAssolement,
      famillesCultures,
      demarche,
      cultures,
      culturesPac,
      precisions,
      assolement,
      exploitation,
    } = this.props;

    const file = HVE3AStatic;

    const computedFields = Object.entries(file.computedFields)
      .filter(([key, value]) => value.disabled)
      .map(([key, value]) => key);

    const Component =
      {
        numeriqueEntier: NumeriqueEntier,
        overrideAssolementValue: OverrideAssolementValue,
        decimalPositif: DecimalPositif,
        decimal: Decimal,
        percent: Percent,
        decimalEntre0Et1: DecimalEntre0Et1,
        boolean: Boolean,
        ChoixBGABilanApparentBilanCorpen: ChoixBGABilanApparentBilanCorpen,
        OADPPF: OADPPF,
        OADMT: OADMT,
        date: Date,
        SET: SET,
        assolement: Assolement,
        IFT: IFT,
        listeMateriel: ListeMateriel,
        listePrairiePermanente: ListePrairiePermanente,
        listeInterRang: ListeInterRang,
        listeFourrage: ListeFourrage,
        listeMethodeAlternative: ListeMethodeAlternative,
        diversiteVarietale: DiversiteVarietale,
        saisieDirecte5: SaisieDirecte,
        saisieDirecte6: SaisieDirecte,
        saisieDirecte10: SaisieDirecte,
        bilanStrategiePhyto: BilanStrategiePhyto,
        texteMultiligne: TexteMultiligne,
        scoring: Scoring,
      }[indicateur.jsonSchema.$id] ?? Default;

    return (
      <Component
        key={"indicateur-" + indicateur.idIndicateur}
        changeIndicateur={changeIndicateur}
        onChangeIndicator={(indicateur) => changeIndicateur(indicateur, sousRubriqueIndex, libelleEnglobantIndex)}
        getValueAssolement={getValueAssolement}
        indicateurs={indicateurs}
        indicateur={indicateur}
        sousRubriqueIndex={sousRubriqueIndex}
        libelleEnglobantIndex={libelleEnglobantIndex}
        valueInputProps={{
          endAdornment: indicateur.libelleUnite && (
            <InputAdornment position={"end"}>{indicateur.libelleUnite}</InputAdornment>
          ),
        }}
        computedFields={computedFields}
        iftRef={iftRef}
        famillesCultures={famillesCultures}
        demarche={demarche}
        cultures={cultures}
        culturesPac={culturesPac}
        precisions={precisions}
        assolement={assolement}
        exploitation={exploitation}
      />
    );
  };

  render() {
    const {
      typesRubriques,
      typeRubriqueSelected,
      rubriqueSelected,
      isIndicateurVisible,
      isLibelleEnglobantVisible,
    } = this.props;

    return (
      <Card className="d-flex flex-fill flex-column">
        {this.renderHeader()}
        <Divider className={"mt-2"} />
        <CardContent style={{ overflowY: "auto" }} id="scrollToTop">
          <div>
            {typesRubriques[typeRubriqueSelected]?.rubriques[rubriqueSelected]?.sousRubriques.map(
              (sousRubrique, sousRubriqueIndex) => (
                <React.Fragment key={"sous-rubrique-" + sousRubrique.idSousRubrique}>
                  {sousRubrique.libelle && (
                    <Typography variant={"h2"} className={clsx({ "mt-3": sousRubriqueIndex !== 0 })}>
                      {sousRubrique.libelle}
                    </Typography>
                  )}
                  {sousRubrique.libellesEnglobants
                    .filter((libelleEnglobant) => isLibelleEnglobantVisible(libelleEnglobant.codeNational))
                    .map((libelleEnglobant, libelleEnglobantIndex) => (
                      <div className={"mt-3"} key={"libelle-englobant-" + libelleEnglobant.idLibelleEnglobant}>
                        {libelleEnglobant.libelle && (
                          <Typography variant={"h4"} className="mb-2">
                            {libelleEnglobant.libelle}
                          </Typography>
                        )}
                        {libelleEnglobant.indicateurs
                          .filter((indicateur) => isIndicateurVisible(indicateur.codeNational))
                          .map((indicateur) =>
                            this.renderIndicateur(indicateur, sousRubriqueIndex, libelleEnglobantIndex)
                          )}
                      </div>
                    ))}
                </React.Fragment>
              )
            )}
          </div>
        </CardContent>
      </Card>
    );
  }
}

export default compose(withTranslation(), connect(mapStateToProps), withRouter, withTheme)(Indicateurs);
