import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Grid, InputAdornment } from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
import TexteMultiligne from "../indicateurs/TexteMultiligne";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class BiodivPoidsCult extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const indicToWatch = [
      "HVE4_BIODIV_D_40",
      "HVE4_BIODIV_D_41",
      "HVE4_BIODIV_D_380",
      "HVE4_BIODIV_D_42",
      "HVE4_BIODIV_I_43",
      "HVE4_BIODIV_S_43",
    ];
    return indicToWatch.some((indic) => this.props.indicateurs[indic] !== nextProps.indicateurs[indic]);
  }

  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };
  render() {
    const { indicateurs, getValueAssolement, computedFields } = this.props;
    return (
      <React.Fragment>
        {indicateurs.length > 0 && <h3 className={"my-2"}>Taille des parcelles</h3>}
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            <div className={indicateurs["HVE4_BIODIV_D_40"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_D_40"].libelle}
            </div>
          </Grid>
          <Grid container item xs={6}>
            <TexteMultiligne
              size={"small"}
              valueAssolement={getValueAssolement("HVE4_BIODIV_D_40") ?? ""}
              displayRules={{ displayAssolement: true, hasRightDisplay: true }}
              indicateur={indicateurs["HVE4_BIODIV_D_40"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_40"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_40"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_40"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            <div className={indicateurs["HVE4_BIODIV_D_41"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_D_41"].libelle}
            </div>
          </Grid>
          <Grid container item xs={6}>
            <DecimalPositif
              error={
                indicateurs["HVE4_CULT_D_200"] != null
                  ? indicateurs["HVE4_BIODIV_D_41"].valeur.value > indicateurs["HVE4_CULT_D_200"].valeur.value
                  : false
              }
              helperText={
                indicateurs["HVE4_CULT_D_200"] != null
                  ? indicateurs["HVE4_BIODIV_D_41"].valeur.value > indicateurs["HVE4_CULT_D_200"].valeur.value
                    ? "La valeur saisie doit être inférieur à la SAU"
                    : ""
                  : ""
              }
              size={"small"}
              valueAssolement={getValueAssolement("HVE4_BIODIV_D_41") ?? ""}
              displayRules={{ displayAssolement: true, hasRightDisplay: true }}
              indicateur={indicateurs["HVE4_BIODIV_D_41"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_41"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_41"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_41"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            <div className={indicateurs["HVE4_BIODIV_D_380"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_D_380"].libelle}
            </div>
          </Grid>
          <Grid container item xs={4}>
            <DecimalPositif
              size={"small"}
              displayRules={{ hasRightDisplay: true }}
              indicateur={indicateurs["HVE4_BIODIV_D_380"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_380"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_380"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_380"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            <div className={indicateurs["HVE4_BIODIV_D_42"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_D_42"].libelle}
            </div>
          </Grid>
          <Grid container item xs={3}>
            <DecimalPositif
              size={"small"}
              displayRules={{ hasRightDisplay: true }}
              indicateur={indicateurs["HVE4_BIODIV_D_42"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_42"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_42"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_42"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
          <Grid item xs={2} className={"bold"}>
            {indicateurs["HVE4_BIODIV_S_43"].libelle}
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            {indicateurs["HVE4_BIODIV_I_43"].libelle}
          </Grid>
          <Grid container item xs={3}>
            <DecimalPositif
              size={"small"}
              valueAssolement={getValueAssolement("HVE4_BIODIV_I_43") ?? ""}
              indicateur={indicateurs["HVE4_BIODIV_I_43"]}
              displayRules={{ hasRightDisplay: true }}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_I_43"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_I_43"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_I_43"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
          <Grid container item xs={4} className={"total-point"}>
            <DecimalPositif
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_S_43"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_S_43"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_S_43"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_S_43"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(BiodivPoidsCult);
