import React from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/styles";
import {
  TableCell,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel,
  Tooltip,
  Fab,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import ResultRow from "./ResultRow";
import { grey } from "@material-ui/core/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "../ChangeExploitation.module.scss";
import SigaModal from "../../../common/SigaModal";
import MultiEdition from "../MultiEdition";

const HeaderTableCell = withStyles((theme) => ({
  head: {
    fontWeight: "bold",
    backgroundColor: theme.palette.type === "light" && grey["200"],
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

class Results extends React.Component {
  constructor(props) {
    super(props);
    this.table = React.createRef();
    this.headCells = [
      {
        id: "raisonSociale",
        label: props.t("change-exploitation.table-headers.name", "Raison sociale"),
        align: "left",
      },
      { id: "pacage", label: props.t("change-exploitation.table-headers.pacage", "N° Pacage"), align: "left" },
      { id: "siret", label: props.t("change-exploitation.table-headers.siret", "SIRET"), align: "left" },
      { id: "adresse", label: props.t("change-exploitation.table-headers.address", "Adresse"), align: "left" },
      { id: "nomCommune", label: props.t("change-exploitation.table-headers.city", "Commune"), align: "left" },
    ];
    this.state = { openModalEdition: false };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.onScroll);
    this.sort();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScroll);
  }

  sort = () => {
    this.setState({
      list:
        // map (x => x) creates a deep copy of the array
        this.props.exploitations
          .map((x) => x)
          .sort(
            (a, b) => (a[this.state.orderBy] < b[this.state.orderBy] ? -1 : 1) * (this.state.order === "asc" ? 1 : -1)
          ),
    });
  };

  createSortHandler = (id) => {
    const newOrder =
      this.props.orderBy === id
        ? { orderBy: id, order: this.props.order === "asc" ? "desc" : "asc" }
        : { orderBy: id, order: "asc" };
    this.setState({ loading: true });
    this.props.sort(newOrder);
  };

  onScroll = (event) => {
    const el = event.target.scrollingElement;
    if (el.scrollHeight - el.scrollTop - el.clientHeight < 1) {
      if (this.props.exploitations.length < this.props.exploitationsTotal && !this.props.loading) {
        this.props.loadMore();
      }
    }
  };

  handleCloseModal = () => {
    this.setState({
      openModalEdition: false,
      modalData: {},
    });
  };

  onMultiEditionButtonClick = () => {
    this.setState({ openModalEdition: !this.state.openModalEdition });
  };

  render() {
    const { t, exploitations, loading, order, orderBy } = this.props;
    const { openModalEdition } = this.state;
    return (
      <div className={styles.list}>
        <TableContainer component="div" onScroll={this.onScroll} style={{ position: "relative", overflowX: "hidden" }}>
          <Table style={{ height: exploitations.length === 0 ? "100%" : "auto" }} ref={this.table}>
            <TableHead>
              <TableRow>
                {this.headCells.map((headCell) => (
                  <HeaderTableCell
                    style={{ position: "sticky", top: "0" }}
                    key={headCell.id}
                    align={headCell.align}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={() => this.createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  </HeaderTableCell>
                ))}
                <HeaderTableCell align="left">
                  {t("change-exploitation.table-headers.process", "Démarches")}
                </HeaderTableCell>
                <HeaderTableCell align="left">
                  {t("change-exploitation.table-headers.actions", "Actions")}
                </HeaderTableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ height: exploitations.length === 0 ? "100%" : "auto" }}>
              {exploitations.length === 0 && !loading ? (
                <TableRow>
                  <TableCell colSpan={7}>
                    <div className="d-flex justify-center align-items-center">
                      <Typography>
                        {t(
                          "change-exploitation.no-result-found",
                          "Pas de résultat trouvé. Essayez d'élargir les critères de recherche"
                        )}
                      </Typography>
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                exploitations.map((e) => (
                  <ResultRow
                    key={e.idExploitation}
                    exploitation={e}
                    selectExploitation={this.props.selectExploitation}
                  />
                ))
              )}
              {loading && (
                <TableRow>
                  {new Array(6).fill("").map((x, i) => (
                    <TableCell key={i}>
                      <Skeleton animation={i % 2 === 0 ? "wave" : false} />
                    </TableCell>
                  ))}
                  <TableCell />
                </TableRow>
              )}
            </TableBody>
          </Table>
          <Tooltip title={"Edition multi-exploitation HVE4"}>
            <Fab
              size={"small"}
              color={"primary"}
              aria-label={"export"}
              onClick={this.onMultiEditionButtonClick}
              style={{ position: "absolute", top: "0.5rem", right: "4rem", width: "40px", height: "40px" }}
            >
              <FontAwesomeIcon icon={"print"} />
            </Fab>
          </Tooltip>
          <Tooltip title={t("export-file.export", "Exporter")}>
            <Fab
              size="small"
              color="primary"
              aria-label="export"
              onClick={this.props.export}
              style={{ position: "absolute", top: "0.5rem", right: "0.5rem", width: "40px", height: "40px" }}
            >
              <FontAwesomeIcon icon="file-excel" />
            </Fab>
          </Tooltip>
        </TableContainer>
        <SigaModal
          open={openModalEdition}
          onClose={this.handleCloseModal}
          title={
            <Typography variant="h2" color={"primary"}>
              Configurez votre édition multi-exploitations
            </Typography>
          }
          fullWidth={true}
          maxWidth={"lg"}
        >
          <MultiEdition
            exploitations={exploitations}
            onClose={this.handleCloseModal}
            millesime={this.props.millesime}
          />
        </SigaModal>
      </div>
    );
  }
}

export default withTranslation()(Results);
