import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import {
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { connect } from "react-redux";
import DatePicker from "../../../../common/DatePicker";
import Boolean from "../indicateurs/Boolean";
import DecimalPositif from "../indicateurs/DecimalPositif";
import NumberInput from "../../../../common/NumberInput";
import network from "../../../../../actions/external/network";
import { toastr } from "react-redux-toastr";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListeBassinsViticoles from "../indicateurs/ListeBassinsViticoles";
import Percent from "../indicateurs/Percent";
import withTheme from "@material-ui/styles/withTheme";

const mapStateToProps = (state) => ({
  idMillesime: state.millesime.selected.idMillesime,
  idExploitation: state.exploitation.selected,
});

const indicToWatch = [
  "HVE4_PHYTO_D_10",
  "HVE4_PHYTO_D_101",
  "HVE4_PHYTO_D_302",
  "HVE4_PHYTO_D_303",
  "HVE4_PHYTO_D_3001",
  "HVE4_PHYTO_D_304_H",
  "HVE4_PHYTO_D_304_HH",
  "HVE4_PHYTO_D_305_H",
  "HVE4_PHYTO_D_305_HH",
  "HVE4_PHYTO_I_31",
  "HVE4_PHYTO_D_330",
  "HVE4_PHYTO_D_331",
  "HVE4_PHYTO_S_31",
  "HVE4_PHYTO_S_32",
  "HVE4_PHYTO_S_33",
  "HVE4_PHYTO_S_34",
  "HVE4_PHYTO_I_32",
  "HVE4_PHYTO_D_332",
  "HVE4_PHYTO_D_333",
  "HVE4_PHYTO_D_3002",
  "HVE4_PHYTO_D_306_H",
  "HVE4_PHYTO_D_306_HH",
  "HVE4_PHYTO_I_33",
  "HVE4_PHYTO_D_334",
  "HVE4_PHYTO_D_335",
  "HVE4_PHYTO_I_34",
  "HVE4_PHYTO_D_336",
  "HVE4_PHYTO_D_337",
  "HVE4_PHYTO_D_3003",
  "HVE4_PHYTO_D_307_H",
  "HVE4_PHYTO_D_307_HH",
  "HVE4_PHYTO_D_308_H",
  "HVE4_PHYTO_D_308_HH",
  "HVE4_PHYTO_I_35",
  "HVE4_PHYTO_D_338",
  "HVE4_PHYTO_D_339",
  "HVE4_PHYTO_I_36",
  "HVE4_PHYTO_D_340",
  "HVE4_PHYTO_D_341",
  "HVE4_PHYTO_D_3004",
];

class PhytoIFT extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    let check =
      indicToWatch.some((indic) => this.props.indicateurs[indic] !== nextProps.indicateurs[indic]) ||
      this.state.iftParams !== nextState.iftParams;
    return check;
  }

  constructor(props) {
    super(props);
    this.state = {
      iftParams: null,
    };
  }
  componentDidMount() {
    const { indicateurs } = this.props;
    const idBassinViti = indicateurs["HVE4_PHYTO_D_3004"].valeur.value.idBassinViti;
    this.loadFormData(idBassinViti ?? this.props.idExploitation.idBassinViti ?? -1);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.indicateurs["HVE4_PHYTO_D_3001"].valeur.value !==
      this.props.indicateurs["HVE4_PHYTO_D_3001"].valeur.value
    ) {
      this.updatePlafondPlancherGC();
    }
    if (
      prevProps.indicateurs["HVE4_PHYTO_D_3002"].valeur.value !==
        this.props.indicateurs["HVE4_PHYTO_D_3002"].valeur.value ||
      prevProps.indicateurs["HVE4_PHYTO_D_3004"].valeur.value !==
        this.props.indicateurs["HVE4_PHYTO_D_3004"].valeur.value
    ) {
      this.updatePlafondPlancherViti();
    }
    if (
      prevProps.indicateurs["HVE4_PHYTO_D_3003"].valeur.value !==
      this.props.indicateurs["HVE4_PHYTO_D_3003"].valeur.value
    ) {
      this.updatePlafondPlancherArbo();
    }
  }

  updatePlafondPlancherGC = () => {
    const { indicateurs } = this.props;
    this.handleChangeIndicateur({
      ...indicateurs["HVE4_PHYTO_D_330"],
      valeur: {
        ...indicateurs["HVE4_PHYTO_D_330"]?.valeur,
        value: this.findIft("GC", "PLAFOND", "H"),
      },
    });
    this.handleChangeIndicateur({
      ...indicateurs["HVE4_PHYTO_D_331"],
      valeur: {
        ...indicateurs["HVE4_PHYTO_D_331"]?.valeur,
        value: this.findIft("GC", "PLANCHER", "H"),
      },
    });
    this.handleChangeIndicateur({
      ...indicateurs["HVE4_PHYTO_D_332"],
      valeur: {
        ...indicateurs["HVE4_PHYTO_D_332"]?.valeur,
        value: this.findIft("GC", "PLAFOND", "HH"),
      },
    });
    this.handleChangeIndicateur({
      ...indicateurs["HVE4_PHYTO_D_333"],
      valeur: {
        ...indicateurs["HVE4_PHYTO_D_333"]?.valeur,
        value: this.findIft("GC", "PLANCHER", "HH"),
      },
    });
  };

  updatePlafondPlancherViti = () => {
    const { indicateurs } = this.props;
    this.handleChangeIndicateur({
      ...indicateurs["HVE4_PHYTO_D_334"],
      valeur: {
        ...indicateurs["HVE4_PHYTO_D_334"]?.valeur,
        value: this.findIft("VITI", "PLAFOND", "H"),
      },
    });
    this.handleChangeIndicateur({
      ...indicateurs["HVE4_PHYTO_D_335"],
      valeur: {
        ...indicateurs["HVE4_PHYTO_D_335"]?.valeur,
        value: this.findIft("VITI", "PLANCHER", "H"),
      },
    });
    this.handleChangeIndicateur({
      ...indicateurs["HVE4_PHYTO_D_336"],
      valeur: {
        ...indicateurs["HVE4_PHYTO_D_336"]?.valeur,
        value: this.findIft("VITI", "PLAFOND", "HH"),
      },
    });
    this.handleChangeIndicateur({
      ...indicateurs["HVE4_PHYTO_D_337"],
      valeur: {
        ...indicateurs["HVE4_PHYTO_D_337"]?.valeur,
        value: this.findIft("VITI", "PLANCHER", "HH"),
      },
    });
  };

  updatePlafondPlancherArbo = () => {
    const { indicateurs } = this.props;
    this.handleChangeIndicateur({
      ...indicateurs["HVE4_PHYTO_D_338"],
      valeur: {
        ...indicateurs["HVE4_PHYTO_D_338"]?.valeur,
        value: this.findIft("ARBO", "PLAFOND", "H"),
      },
    });
    this.handleChangeIndicateur({
      ...indicateurs["HVE4_PHYTO_D_339"],
      valeur: {
        ...indicateurs["HVE4_PHYTO_D_339"]?.valeur,
        value: this.findIft("ARBO", "PLANCHER", "H"),
      },
    });
    this.handleChangeIndicateur({
      ...indicateurs["HVE4_PHYTO_D_340"],
      valeur: {
        ...indicateurs["HVE4_PHYTO_D_340"]?.valeur,
        value: this.findIft("ARBO", "PLAFOND", "HH"),
      },
    });
    this.handleChangeIndicateur({
      ...indicateurs["HVE4_PHYTO_D_341"],
      valeur: {
        ...indicateurs["HVE4_PHYTO_D_341"]?.valeur,
        value: this.findIft("ARBO", "PLANCHER", "HH"),
      },
    });
  };

  loadFormData = async (idBassinViti) => {
    try {
      const [paramsIft] = await Promise.all([
        network.fetch(
          `/api/demarches/paramsift/${this.props.idExploitation.idExploitation}/${
            idBassinViti ?? this.props.idExploitation.idBassinViti
          }?millesime=${this.props.idMillesime}`
        ),
      ]);
      this.setState({
        iftParams: paramsIft,
      });
      if (paramsIft != null) {
        this.updatePlafondPlancherGC();
        this.updatePlafondPlancherViti();
        this.updatePlafondPlancherArbo();
      }
    } catch (error) {
      toastr.error("Erreur", error);
    }
  };
  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    if (newIndicateur.codeNational === "HVE4_PHYTO_D_3004") {
      this.loadFormData(newIndicateur.valeur.value.idBassinViti);
    }
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };

  renderInputIft = (indicateur, key) => {
    return (
      <React.Fragment>
        {indicateur?.valeurCalculee != null && (
          <NumberInput
            size={"small"}
            disabled
            className="mb-1"
            label={
              key === "n"
                ? "Valeur N MesParcelles"
                : key === "n_1"
                ? "Valeur N-1 MesParcelles"
                : key === "n_2" && "Valeur N-2 MesParcelles"
            }
            value={indicateur?.valeurCalculee?.value[key]}
          />
        )}
        <NumberInput
          disabled={
            indicateur.codeNational === "HVE4_PHYTO_D_305_H" || indicateur.codeNational === "HVE4_PHYTO_D_305_HH"
          }
          size={"small"}
          label={key === "n" ? "Valeur N" : key === "n_1" ? "Valeur N-1" : key === "n_2" && "Valeur N-2"}
          value={indicateur?.valeur?.value[key] ?? ""}
          onChange={(value) =>
            this.handleChangeIndicateur({
              ...indicateur,
              valeur: {
                ...indicateur?.valeur,
                value: {
                  ...indicateur?.valeur.value,
                  [key]: value === "" || value == null ? null : Number(value),
                },
              },
            })
          }
        />
      </React.Fragment>
    );
  };

  findIft = (culture, codeTypeValeur, codeTypeIft) => {
    const { indicateurs, assolement } = this.props;

    const { iftParams } = this.state;
    const exploitHaveCultPdt = (indicateurs["HVE4_CULT_D_38"]?.valeur.value ?? 0) > 0;
    const HVE4_PHYTO_D_3500 = indicateurs["HVE4_PHYTO_D_3500"]?.valeur.value ?? 0; //Surface en Grandes Cultures
    const HVE4_CULT_D_38 = indicateurs["HVE4_CULT_D_38"]?.valeur.value ?? 0; //surface en pomme de terre
    const HVE4_CULT_D_45 = indicateurs["HVE4_CULT_D_45"]?.valeur.value ?? 0; // surface en verger
    const gc_value = indicateurs["HVE4_PHYTO_D_3001"].valeur.value ? "valeur_prec" : "valeur";
    const viti_value = indicateurs["HVE4_PHYTO_D_3002"].valeur.value ? "valeur_prec" : "valeur";
    const arbo_value = indicateurs["HVE4_PHYTO_D_3003"].valeur.value ? "valeur_prec" : "valeur";

    if (culture === "GC") {
      if (!exploitHaveCultPdt) {
        return iftParams?.gc_hors_pdt
          .filter((item) => item.code_type_valeur === codeTypeValeur)
          .filter((item) => item.code_type_ift === codeTypeIft)[0][gc_value];
      }

      if (iftParams?.gc_pdt != null) {
        return (
          ((HVE4_PHYTO_D_3500 - HVE4_CULT_D_38) *
            iftParams?.gc_hors_pdt
              .filter((item) => item.code_type_valeur === codeTypeValeur)
              .filter((item) => item.code_type_ift === codeTypeIft)[0][gc_value] +
            HVE4_CULT_D_38 *
              iftParams?.gc_pdt
                .filter((item) => item.code_type_valeur === codeTypeValeur)
                .filter((item) => item.code_type_ift === codeTypeIft)[0][gc_value]) /
          HVE4_PHYTO_D_3500
        );
      }
    }

    if (culture === "VITI") {
      if (iftParams?.viti != null) {
        return iftParams?.viti
          .filter((item) => item.code_type_valeur === codeTypeValeur)
          .filter((item) => item.code_type_ift === codeTypeIft)[0][viti_value];
      } else {
        return 0;
      }
    }

    if (culture === "ARBO") {
      if (iftParams?.arbo != null) {
        const idsRegroupementVerger = assolement
          .filter((item) => item.codeCategorieCulture === "ARBO" && item.codeTypeCulture !== "VIGNES")
          .map((item) => item.idRegroupementVerger);

        const sommmeSurfacesXParams = iftParams?.arbo
          .filter((item) => idsRegroupementVerger.includes(item.id_regroupement_verger))
          .filter((item) => item.code_type_valeur === codeTypeValeur)
          .filter((item) => item.code_type_ift === codeTypeIft)
          .map(
            (el) =>
              el[arbo_value] *
              assolement
                .sort((a, b) => a.idRegroupementVerger - b.idRegroupementVerger)
                .filter((a) => a.idRegroupementVerger === el.id_regroupement_verger)[0].surface
          );
        return sommmeSurfacesXParams.reduce((sum, val) => sum + val, 0) / HVE4_CULT_D_45;
      } else {
        return 0;
      }
    }
  };

  render() {
    const {
      indicateurs,
      computedFields,
      changeIndicateur,
      sousRubriqueIndex,
      libelleEnglobantIndex,
      theme,
    } = this.props;
    const HVE4_ANNEES = [
      {
        code: "ANNEE_COURANTE",
        libelle: "Année courante",
      },
      {
        code: "BIANNUEL",
        libelle: "Bi-annuel",
      },
      {
        code: "TRIANNUEL",
        libelle: "Tri-annuel",
      },
    ];
    const showN2 = indicateurs["HVE4_PHYTO_D_302"].valeur.value === "TRIANNUEL";
    const showN1 = ["TRIANNUEL", "BIANNUEL"].includes(indicateurs["HVE4_PHYTO_D_302"].valeur.value);

    const indicateursToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicToWatch.includes(key)))
    );

    if (indicateursToDisplay.length > 0) {
      return (
        <React.Fragment>
          <Grid container className={"flex-column"}>
            <Grid xs={9} item className={"d-flex align-items-center mb-2"}>
              <TextField
                className={"d-flex mr-1"}
                size={"small"}
                label={"Choix du calcul de la moyenne"}
                fullWidth={false}
                select
                value={indicateurs["HVE4_PHYTO_D_302"].valeur.value || "ANNEE_COURANTE"}
                style={{
                  fontWeight: "normal",
                  width: "20rem",
                }}
                onChange={(event) =>
                  changeIndicateur(
                    {
                      ...indicateurs["HVE4_PHYTO_D_302"],
                      valeur: { value: event.target.value },
                    },
                    sousRubriqueIndex,
                    libelleEnglobantIndex
                  )
                }
              >
                {HVE4_ANNEES.map((element) => (
                  <MenuItem key={element.code} value={element.code}>
                    {element.libelle}
                  </MenuItem>
                ))}
              </TextField>

              {indicateurs["HVE4_PHYTO_D_302"].messageAide != null && (
                <Tooltip
                  title={<span style={{ whiteSpace: "pre-line" }}>{indicateurs["HVE4_PHYTO_D_302"].messageAide}</span>}
                >
                  <IconButton className={"d-flex mr-2"}>
                    <FontAwesomeIcon icon="info-circle" />
                  </IconButton>
                </Tooltip>
              )}

              <DatePicker
                className={"d-flex mr-2"}
                size={"small"}
                disableToolbar
                variant="inline"
                format="DD/MM/yyyy"
                label={"Date d'engagement"}
                value={indicateurs["HVE4_PHYTO_D_303"].valeur.value}
                onChange={(event) =>
                  changeIndicateur(
                    {
                      ...indicateurs["HVE4_PHYTO_D_303"],
                      valeur: {
                        value: typeof event === "object" && event !== null ? event.format("YYYY-MM-DD") : "",
                      },
                    },
                    sousRubriqueIndex,
                    libelleEnglobantIndex
                  )
                }
              />

              <TextField
                className={"d-flex mr-2"}
                size={"small"}
                disabled
                fullWidth={false}
                style={{ maxWidth: "8rem" }}
                label={"Région de référence"}
                value={this.props.idExploitation.region}
              />

              <TextField
                className={"d-flex mr-2"}
                size={"small"}
                disabled
                fullWidth={false}
                style={{ maxWidth: "8rem" }}
                label={"Département du siège social de l'exploitation"}
                value={this.props.idExploitation.departement}
              />
            </Grid>
            <Grid container className={"d-flex"} spacing={3}>
              {/* IFT Grands cultures hors pommes de terre*/}
              <Grid item xs={showN2 ? 5 : 4}>
                <div className={"d-flex align-items-center mb-2"}>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow className={"d-flex justify-center"}>
                          <TableCell colSpan={4}>
                            <div className={"d-flex justify-center bold"}>
                              IFT Grands cultures (hors pommes de terre)
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow className={"d-flex justify-center"}>
                          <TableCell />
                          <TableCell style={{ textAlign: "center" }} className={"d-flex justify-center"}>
                            N
                          </TableCell>
                          {showN1 && <TableCell style={{ textAlign: "center" }}>N-1</TableCell>}
                          {showN2 && <TableCell style={{ textAlign: "center" }}>N-2</TableCell>}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key={1}>
                          <TableCell>
                            <div>IFT herbicide</div>
                          </TableCell>

                          <TableCell style={{ textAlign: "center" }}>
                            {this.renderInputIft(indicateurs["HVE4_PHYTO_D_308_H"], "n")}
                          </TableCell>

                          {showN1 && (
                            <TableCell style={{ textAlign: "center" }}>
                              {this.renderInputIft(indicateurs["HVE4_PHYTO_D_308_H"], "n_1")}
                            </TableCell>
                          )}

                          {showN2 && (
                            <TableCell style={{ textAlign: "center" }}>
                              {this.renderInputIft(indicateurs["HVE4_PHYTO_D_308_H"], "n_2")}
                            </TableCell>
                          )}
                        </TableRow>
                        <TableRow key={2}>
                          <TableCell>
                            <div>IFT hors herbicide</div>
                          </TableCell>

                          <TableCell style={{ textAlign: "center" }}>
                            {this.renderInputIft(indicateurs["HVE4_PHYTO_D_308_HH"], "n")}
                          </TableCell>

                          {showN1 && (
                            <TableCell style={{ textAlign: "center" }}>
                              {this.renderInputIft(indicateurs["HVE4_PHYTO_D_308_HH"], "n_1")}
                            </TableCell>
                          )}

                          {showN2 && (
                            <TableCell style={{ textAlign: "center" }}>
                              {this.renderInputIft(indicateurs["HVE4_PHYTO_D_308_HH"], "n_2")}
                            </TableCell>
                          )}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>
              {/* IFT Pomme de terre*/}
              <Grid item xs={showN2 ? 5 : 4}>
                {(indicateurs["HVE4_CULT_D_38"]?.valeur.value ?? 0) > 0 && (
                  <div className={"d-flex align-items-center mb-2"}>
                    <TableContainer component={Paper}>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow className={"d-flex justify-center"}>
                            <TableCell colSpan={4}>
                              <div className={"d-flex justify-center bold"}>IFT Pomme de terre</div>
                            </TableCell>
                          </TableRow>
                          <TableRow className={"d-flex justify-center"}>
                            <TableCell />
                            <TableCell style={{ textAlign: "center" }} className={"d-flex justify-center"}>
                              N
                            </TableCell>
                            {showN1 && <TableCell style={{ textAlign: "center" }}>N-1</TableCell>}
                            {showN2 && <TableCell style={{ textAlign: "center" }}>N-2</TableCell>}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow key={1}>
                            <TableCell>
                              <div>IFT herbicide</div>
                            </TableCell>

                            <TableCell style={{ textAlign: "center" }}>
                              {this.renderInputIft(indicateurs["HVE4_PHYTO_D_304_H"], "n")}
                            </TableCell>

                            {showN1 && (
                              <TableCell style={{ textAlign: "center" }}>
                                {this.renderInputIft(indicateurs["HVE4_PHYTO_D_304_H"], "n_1")}
                              </TableCell>
                            )}

                            {showN2 && (
                              <TableCell style={{ textAlign: "center" }}>
                                {this.renderInputIft(indicateurs["HVE4_PHYTO_D_304_H"], "n_2")}
                              </TableCell>
                            )}
                          </TableRow>
                          <TableRow key={2}>
                            <TableCell>
                              <div>IFT hors herbicide</div>
                            </TableCell>

                            <TableCell style={{ textAlign: "center" }}>
                              {this.renderInputIft(indicateurs["HVE4_PHYTO_D_304_HH"], "n")}
                            </TableCell>

                            {showN1 && (
                              <TableCell style={{ textAlign: "center" }}>
                                {this.renderInputIft(indicateurs["HVE4_PHYTO_D_304_HH"], "n_1")}
                              </TableCell>
                            )}

                            {showN2 && (
                              <TableCell style={{ textAlign: "center" }}>
                                {this.renderInputIft(indicateurs["HVE4_PHYTO_D_304_HH"], "n_2")}
                              </TableCell>
                            )}
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                )}
              </Grid>
            </Grid>

            {(indicateurs["HVE4_PHYTO_D_3500"]?.valeur.value ?? 0) > 0 && (
              /*IFT Grandes cultures*/
              <Grid item xs={showN2 ? 10 : 8}>
                <div className={"d-flex align-items-center mb-2"}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={7}>
                            <div className={"d-flex align-items-center justify-between"}>
                              <span className={"bold"}>IFT Grandes cultures (dont pommes de terre)</span>
                              <div className={"d-flex align-items-center"}>
                                <span className={"mr-3"}>{indicateurs["HVE4_PHYTO_D_3001"].libelle}</span>

                                <Boolean
                                  indicateur={indicateurs["HVE4_PHYTO_D_3001"]}
                                  key={"indicateur-" + indicateurs["HVE4_PHYTO_D_3001"].idIndicateur}
                                  changeIndicateur={this.handleChangeIndicateur}
                                  valueInputProps={
                                    indicateurs["HVE4_PHYTO_D_3001"].libelleUnite != null && {
                                      endAdornment: (
                                        <InputAdornment position={"end"}>
                                          {indicateurs["HVE4_PHYTO_D_3001"].libelleUnite}
                                        </InputAdornment>
                                      ),
                                    }
                                  }
                                  computedFields={computedFields}
                                />
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow className={"d-flex justify-center"}>
                          <TableCell />
                          <TableCell style={{ textAlign: "center" }} className={"d-flex justify-center"}>
                            N
                          </TableCell>
                          {showN1 && <TableCell style={{ textAlign: "center" }}>N-1</TableCell>}
                          {showN2 && <TableCell style={{ textAlign: "center" }}>N-2</TableCell>}
                          <TableCell style={{ textAlign: "center" }} className={"d-flex justify-center"}>
                            Moyenne
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }} className={"d-flex justify-center"}>
                            {indicateurs["HVE4_PHYTO_D_3001"].valeur.value ? "Ancien plafond" : "Plafond"}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }} className={"d-flex justify-center"}>
                            {indicateurs["HVE4_PHYTO_D_3001"].valeur.value ? "Ancien plancher" : "Plancher"}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key={1}>
                          <TableCell>
                            <div>IFT herbicide</div>
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {this.renderInputIft(indicateurs["HVE4_PHYTO_D_305_H"], "n")}
                          </TableCell>
                          {showN1 && (
                            <TableCell style={{ textAlign: "center" }}>
                              {this.renderInputIft(indicateurs["HVE4_PHYTO_D_305_H"], "n_1")}
                            </TableCell>
                          )}
                          {showN2 && (
                            <TableCell style={{ textAlign: "center" }}>
                              {this.renderInputIft(indicateurs["HVE4_PHYTO_D_305_H"], "n_2")}
                            </TableCell>
                          )}
                          <TableCell style={{ textAlign: "center" }}>
                            <DecimalPositif
                              size={"small"}
                              indicateur={indicateurs["HVE4_PHYTO_I_31"]}
                              key={"indicateur-" + indicateurs["HVE4_PHYTO_I_31"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                              valueInputProps={
                                indicateurs["HVE4_PHYTO_I_31"].libelleUnite != null && {
                                  endAdornment: (
                                    <InputAdornment position={"end"}>
                                      {indicateurs["HVE4_PHYTO_I_31"].libelleUnite}
                                    </InputAdornment>
                                  ),
                                }
                              }
                              computedFields={computedFields}
                            />
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            <NumberInput
                              size={"small"}
                              disabled
                              value={indicateurs["HVE4_PHYTO_D_330"].valeur.value}
                              key={"indicateur-" + indicateurs["HVE4_PHYTO_D_330"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                            />
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            <NumberInput
                              size={"small"}
                              disabled
                              value={indicateurs["HVE4_PHYTO_D_331"].valeur.value}
                              key={"indicateur-" + indicateurs["HVE4_PHYTO_D_331"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow key={2}>
                          <TableCell>
                            <div>IFT hors herbicide</div>
                          </TableCell>

                          <TableCell style={{ textAlign: "center" }}>
                            {this.renderInputIft(indicateurs["HVE4_PHYTO_D_305_HH"], "n")}
                          </TableCell>

                          {showN1 && (
                            <TableCell style={{ textAlign: "center" }}>
                              {this.renderInputIft(indicateurs["HVE4_PHYTO_D_305_HH"], "n_1")}
                            </TableCell>
                          )}

                          {showN2 && (
                            <TableCell style={{ textAlign: "center" }}>
                              {this.renderInputIft(indicateurs["HVE4_PHYTO_D_305_HH"], "n_2")}
                            </TableCell>
                          )}
                          <TableCell style={{ textAlign: "center" }}>
                            <DecimalPositif
                              size={"small"}
                              indicateur={indicateurs["HVE4_PHYTO_I_32"]}
                              key={"indicateur-" + indicateurs["HVE4_PHYTO_I_32"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                              valueInputProps={
                                indicateurs["HVE4_PHYTO_I_32"].libelleUnite != null && {
                                  endAdornment: (
                                    <InputAdornment position={"end"}>
                                      {indicateurs["HVE4_PHYTO_I_32"].libelleUnite}
                                    </InputAdornment>
                                  ),
                                }
                              }
                              computedFields={computedFields}
                            />
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            <NumberInput
                              size={"small"}
                              disabled
                              value={indicateurs["HVE4_PHYTO_D_332"].valeur.value}
                              key={"indicateur-" + indicateurs["HVE4_PHYTO_D_332"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                            />
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            <NumberInput
                              size={"small"}
                              disabled
                              value={indicateurs["HVE4_PHYTO_D_333"].valeur.value}
                              key={"indicateur-" + indicateurs["HVE4_PHYTO_D_333"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>
            )}

            {(indicateurs["HVE4_CULT_D_44"]?.valeur.value ?? 0) > 0 && (
              /*Ligne sélection infos Viti*/
              <Grid item xs={showN2 ? 10 : 8}>
                <div className={"d-flex align-items-center mb-2"}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={7}>
                            <div className={"d-flex align-items-center justify-between"}>
                              <span className={"bold"}>IFT Viti</span>
                              <div className={"d-flex align-items-center"}>
                                <span className={"mr-3"}>{indicateurs["HVE4_PHYTO_D_3002"].libelle}</span>

                                <Boolean
                                  indicateur={indicateurs["HVE4_PHYTO_D_3002"]}
                                  key={"indicateur-" + indicateurs["HVE4_PHYTO_D_3002"].idIndicateur}
                                  changeIndicateur={this.handleChangeIndicateur}
                                  valueInputProps={
                                    indicateurs["HVE4_PHYTO_D_3002"].libelleUnite != null && {
                                      endAdornment: (
                                        <InputAdornment position={"end"}>
                                          {indicateurs["HVE4_PHYTO_D_3002"].libelleUnite}
                                        </InputAdornment>
                                      ),
                                    }
                                  }
                                  computedFields={computedFields}
                                />
                              </div>
                              <ListeBassinsViticoles
                                indicateur={indicateurs["HVE4_PHYTO_D_3004"]}
                                key={"indicateur-" + indicateurs["HVE4_PHYTO_D_3004"].idIndicateur}
                                changeIndicateur={this.handleChangeIndicateur}
                                computedFields={computedFields}
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow className={"d-flex justify-center"}>
                          <TableCell />
                          <TableCell style={{ textAlign: "center" }} className={"d-flex justify-center"}>
                            N
                          </TableCell>
                          {showN1 && <TableCell style={{ textAlign: "center" }}>N-1</TableCell>}
                          {showN2 && <TableCell style={{ textAlign: "center" }}>N-2</TableCell>}
                          <TableCell style={{ textAlign: "center" }} className={"d-flex justify-center"}>
                            Moyenne
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }} className={"d-flex justify-center"}>
                            {indicateurs["HVE4_PHYTO_D_3002"].valeur.value ? "Ancien plafond" : "Plafond"}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }} className={"d-flex justify-center"}>
                            {indicateurs["HVE4_PHYTO_D_3002"].valeur.value ? "Ancien plancher" : "Plancher"}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key={1}>
                          <TableCell>
                            <div>IFT herbicide</div>
                          </TableCell>

                          <TableCell style={{ textAlign: "center" }}>
                            {this.renderInputIft(indicateurs["HVE4_PHYTO_D_306_H"], "n")}
                          </TableCell>

                          {showN1 && (
                            <TableCell style={{ textAlign: "center" }}>
                              {this.renderInputIft(indicateurs["HVE4_PHYTO_D_306_H"], "n_1")}
                            </TableCell>
                          )}

                          {showN2 && (
                            <TableCell style={{ textAlign: "center" }}>
                              {this.renderInputIft(indicateurs["HVE4_PHYTO_D_306_H"], "n_2")}
                            </TableCell>
                          )}
                          <TableCell style={{ textAlign: "center" }}>
                            <DecimalPositif
                              size={"small"}
                              indicateur={indicateurs["HVE4_PHYTO_I_33"]}
                              key={"indicateur-" + indicateurs["HVE4_PHYTO_I_33"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                              valueInputProps={
                                indicateurs["HVE4_PHYTO_I_33"].libelleUnite != null && {
                                  endAdornment: (
                                    <InputAdornment position={"end"}>
                                      {indicateurs["HVE4_PHYTO_I_33"].libelleUnite}
                                    </InputAdornment>
                                  ),
                                }
                              }
                              computedFields={computedFields}
                            />
                          </TableCell>

                          <TableCell style={{ textAlign: "center" }}>
                            <NumberInput
                              size={"small"}
                              disabled
                              value={indicateurs["HVE4_PHYTO_D_334"].valeur.value}
                              key={"indicateur-" + indicateurs["HVE4_PHYTO_D_334"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                              numberOfDecimals={2}
                            />
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            <NumberInput
                              size={"small"}
                              disabled
                              value={indicateurs["HVE4_PHYTO_D_335"].valeur.value}
                              key={"indicateur-" + indicateurs["HVE4_PHYTO_D_335"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                              numberOfDecimals={2}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow key={2}>
                          <TableCell>
                            <div>IFT hors herbicide</div>
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {this.renderInputIft(indicateurs["HVE4_PHYTO_D_306_HH"], "n")}
                          </TableCell>
                          {showN1 && (
                            <TableCell style={{ textAlign: "center" }}>
                              {this.renderInputIft(indicateurs["HVE4_PHYTO_D_306_HH"], "n_1")}
                            </TableCell>
                          )}
                          {showN2 && (
                            <TableCell style={{ textAlign: "center" }}>
                              {this.renderInputIft(indicateurs["HVE4_PHYTO_D_306_HH"], "n_2")}
                            </TableCell>
                          )}
                          <TableCell style={{ textAlign: "center" }}>
                            <DecimalPositif
                              size={"small"}
                              indicateur={indicateurs["HVE4_PHYTO_I_34"]}
                              key={"indicateur-" + indicateurs["HVE4_PHYTO_I_34"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                              valueInputProps={
                                indicateurs["HVE4_PHYTO_I_34"].libelleUnite != null && {
                                  endAdornment: (
                                    <InputAdornment position={"end"}>
                                      {indicateurs["HVE4_PHYTO_I_34"].libelleUnite}
                                    </InputAdornment>
                                  ),
                                }
                              }
                              computedFields={computedFields}
                            />
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            <NumberInput
                              size={"small"}
                              disabled
                              value={indicateurs["HVE4_PHYTO_D_336"].valeur.value}
                              key={"indicateur-" + indicateurs["HVE4_PHYTO_D_336"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                              numberOfDecimals={2}
                            />
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            <NumberInput
                              size={"small"}
                              disabled
                              value={indicateurs["HVE4_PHYTO_D_337"].valeur.value}
                              key={"indicateur-" + indicateurs["HVE4_PHYTO_D_337"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                              numberOfDecimals={2}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>
            )}
            {(indicateurs["HVE4_CULT_D_45"]?.valeur.value > 0 || indicateurs["HVE4_CULT_D_46"]?.valeur.value > 0) && (
              /*Ligne Infos Arbo*/
              <Grid item xs={showN2 ? 10 : 8}>
                <div className={"d-flex align-items-center mb-2"}>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell colSpan={7}>
                            <div className={"d-flex align-items-center justify-between"}>
                              <span className={"bold"}>IFT Arbo</span>
                              <div className={"d-flex align-items-center"}>
                                <span className={"mr-3"}>{indicateurs["HVE4_PHYTO_D_3003"].libelle}</span>

                                <Boolean
                                  indicateur={indicateurs["HVE4_PHYTO_D_3003"]}
                                  key={"indicateur-" + indicateurs["HVE4_PHYTO_D_3003"].idIndicateur}
                                  changeIndicateur={this.handleChangeIndicateur}
                                  valueInputProps={
                                    indicateurs["HVE4_PHYTO_D_3002"].libelleUnite != null && {
                                      endAdornment: (
                                        <InputAdornment position={"end"}>
                                          {indicateurs["HVE4_PHYTO_D_3003"].libelleUnite}
                                        </InputAdornment>
                                      ),
                                    }
                                  }
                                  computedFields={computedFields}
                                />
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                        <TableRow className={"d-flex justify-center"}>
                          <TableCell />
                          <TableCell style={{ textAlign: "center" }} className={"d-flex justify-center"}>
                            N
                          </TableCell>
                          {showN1 && <TableCell style={{ textAlign: "center" }}>N-1</TableCell>}
                          {showN2 && <TableCell style={{ textAlign: "center" }}>N-2</TableCell>}
                          <TableCell style={{ textAlign: "center" }} className={"d-flex justify-center"}>
                            Moyenne
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }} className={"d-flex justify-center"}>
                            {indicateurs["HVE4_PHYTO_D_3003"].valeur.value ? "Ancien plafond" : "Plafond"}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }} className={"d-flex justify-center"}>
                            {indicateurs["HVE4_PHYTO_D_3003"].valeur.value ? "Ancien plancher" : "Plancher"}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key={1}>
                          <TableCell>
                            <div>IFT herbicide</div>
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {this.renderInputIft(indicateurs["HVE4_PHYTO_D_307_H"], "n")}
                          </TableCell>
                          {showN1 && (
                            <TableCell style={{ textAlign: "center" }}>
                              {this.renderInputIft(indicateurs["HVE4_PHYTO_D_307_H"], "n_1")}
                            </TableCell>
                          )}
                          {showN2 && (
                            <TableCell style={{ textAlign: "center" }}>
                              {this.renderInputIft(indicateurs["HVE4_PHYTO_D_307_H"], "n_2")}
                            </TableCell>
                          )}
                          <TableCell style={{ textAlign: "center" }}>
                            <DecimalPositif
                              size={"small"}
                              indicateur={indicateurs["HVE4_PHYTO_I_35"]}
                              key={"indicateur-" + indicateurs["HVE4_PHYTO_I_35"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                              valueInputProps={
                                indicateurs["HVE4_PHYTO_I_35"].libelleUnite != null && {
                                  endAdornment: (
                                    <InputAdornment position={"end"}>
                                      {indicateurs["HVE4_PHYTO_I_35"].libelleUnite}
                                    </InputAdornment>
                                  ),
                                }
                              }
                              computedFields={computedFields}
                            />
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            <NumberInput
                              size={"small"}
                              disabled
                              value={indicateurs["HVE4_PHYTO_D_338"].valeur.value}
                              key={"indicateur-" + indicateurs["HVE4_PHYTO_D_338"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                            />
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            <NumberInput
                              size={"small"}
                              disabled
                              value={indicateurs["HVE4_PHYTO_D_339"].valeur.value}
                              key={"indicateur-" + indicateurs["HVE4_PHYTO_D_339"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow key={2}>
                          <TableCell>
                            <div>IFT hors herbicide</div>
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {this.renderInputIft(indicateurs["HVE4_PHYTO_D_307_HH"], "n")}
                          </TableCell>
                          {showN1 && (
                            <TableCell style={{ textAlign: "center" }}>
                              {this.renderInputIft(indicateurs["HVE4_PHYTO_D_307_HH"], "n_1")}
                            </TableCell>
                          )}
                          {showN2 && (
                            <TableCell style={{ textAlign: "center" }}>
                              {this.renderInputIft(indicateurs["HVE4_PHYTO_D_307_HH"], "n_2")}
                            </TableCell>
                          )}
                          <TableCell style={{ textAlign: "center" }}>
                            <DecimalPositif
                              size={"small"}
                              indicateur={indicateurs["HVE4_PHYTO_I_36"]}
                              key={"indicateur-" + indicateurs["HVE4_PHYTO_I_36"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                              valueInputProps={
                                indicateurs["HVE4_PHYTO_I_36"].libelleUnite != null && {
                                  endAdornment: (
                                    <InputAdornment position={"end"}>
                                      {indicateurs["HVE4_PHYTO_I_36"].libelleUnite}
                                    </InputAdornment>
                                  ),
                                }
                              }
                              computedFields={computedFields}
                            />
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            <NumberInput
                              size={"small"}
                              disabled
                              value={indicateurs["HVE4_PHYTO_D_340"].valeur.value}
                              key={"indicateur-" + indicateurs["HVE4_PHYTO_D_340"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                            />
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            <NumberInput
                              size={"small"}
                              disabled
                              value={indicateurs["HVE4_PHYTO_D_341"].valeur.value}
                              key={"indicateur-" + indicateurs["HVE4_PHYTO_D_341"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Grid>
            )}
          </Grid>
          <h2>Scoring</h2>
          <Grid container>
            <Grid item xs={10}>
              <TableContainer component={Paper}>
                <Table style={{ tableLayout: "fixed" }}>
                  <TableHead>
                    <TableRow className={"d-flex justify-center"}>
                      <TableCell />
                      <TableCell style={{ textAlign: "center" }} className={"d-flex justify-center"}>
                        Points
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>Surface concernée</TableCell>
                      <TableCell style={{ textAlign: "center" }}>Part de la SAU des surfaces concernées</TableCell>
                      <TableCell style={{ textAlign: "center" }} className={"d-flex justify-center"}>
                        Points
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow key={0} className={"d-flex justify-center"}>
                      <TableCell colSpan={5}>
                        <div className={"d-flex justify-center bold"}>Grandes cultures</div>
                      </TableCell>
                    </TableRow>
                    <TableRow key={1}>
                      <TableCell>
                        <div>IFT herbicide</div>
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_S_31"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_S_31"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_S_31"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_S_31"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell rowSpan={2} style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_D_3500"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_D_3500"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_D_3500"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_D_3500"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell rowSpan={2} style={{ textAlign: "center" }}>
                        <Percent
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_D_350"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_D_350"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_D_350"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_D_350"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_S_310"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_S_310"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_S_310"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_S_310"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow key={2}>
                      <TableCell>
                        <div>IFT hors herbicide</div>
                      </TableCell>

                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_S_32"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_S_32"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_S_32"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_S_32"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_S_320"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_S_320"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_S_320"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_S_320"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow key={3} className={"d-flex justify-center"}>
                      <TableCell colSpan={5}>
                        <div className={"d-flex justify-center bold"}>Vigne</div>
                      </TableCell>
                    </TableRow>
                    <TableRow key={4}>
                      <TableCell>
                        <div>IFT herbicide</div>
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_S_33"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_S_33"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_S_33"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_S_33"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell rowSpan={2} style={{ textAlign: "center" }}>
                        <NumberInput
                          size={"small"}
                          className={"ml-2"}
                          fullWidth={false}
                          style={{ maxWidth: "8rem" }}
                          value={indicateurs["HVE4_CULT_D_44"]?.valeur.value ?? ""}
                          label={"Valeur assolement"}
                          InputProps={
                            indicateurs["HVE4_CULT_D_44"]?.libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_CULT_D_44"]?.libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          disabled
                        />
                      </TableCell>
                      <TableCell rowSpan={2} style={{ textAlign: "center" }}>
                        {indicateurs["HVE4_PHYTO_D_351"] != null && (
                          <Percent
                            size={"small"}
                            indicateur={indicateurs["HVE4_PHYTO_D_351"]}
                            key={"indicateur-" + indicateurs["HVE4_PHYTO_D_351"].idIndicateur}
                            changeIndicateur={this.handleChangeIndicateur}
                            valueInputProps={
                              indicateurs["HVE4_PHYTO_D_351"]?.libelleUnite != null && {
                                endAdornment: (
                                  <InputAdornment position={"end"}>
                                    {indicateurs["HVE4_PHYTO_D_351"].libelleUnite}
                                  </InputAdornment>
                                ),
                              }
                            }
                            computedFields={computedFields}
                          />
                        )}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_S_330"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_S_330"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_S_330"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_S_330"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow key={5}>
                      <TableCell>
                        <div>IFT hors herbicide</div>
                      </TableCell>

                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_S_34"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_S_34"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_S_34"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_S_34"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_S_340"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_S_340"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_S_340"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_S_340"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow key={6} className={"d-flex justify-center"}>
                      <TableCell colSpan={5}>
                        <div className={"d-flex justify-center bold"}>Arboriculture</div>
                      </TableCell>
                    </TableRow>
                    <TableRow key={7}>
                      <TableCell>
                        <div>IFT herbicide</div>
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_S_35"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_S_35"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_S_35"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_S_35"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell rowSpan={2} style={{ textAlign: "center" }}>
                        <NumberInput
                          size={"small"}
                          className={"ml-2"}
                          fullWidth={false}
                          style={{ maxWidth: "8rem" }}
                          value={indicateurs["HVE4_CULT_D_45"]?.valeur.value ?? ""}
                          InputProps={
                            indicateurs["HVE4_CULT_D_45"]?.libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_CULT_D_45"]?.libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          label={"Valeur assolement"}
                          disabled
                        />
                      </TableCell>
                      <TableCell rowSpan={2} style={{ textAlign: "center" }}>
                        {indicateurs["HVE4_PHYTO_D_352"] != null && (
                          <Percent
                            size={"small"}
                            indicateur={indicateurs["HVE4_PHYTO_D_352"]}
                            key={"indicateur-" + indicateurs["HVE4_PHYTO_D_352"].idIndicateur}
                            changeIndicateur={this.handleChangeIndicateur}
                            valueInputProps={
                              indicateurs["HVE4_PHYTO_D_352"]?.libelleUnite != null && {
                                endAdornment: (
                                  <InputAdornment position={"end"}>
                                    {indicateurs["HVE4_PHYTO_D_352"].libelleUnite}
                                  </InputAdornment>
                                ),
                              }
                            }
                            computedFields={computedFields}
                          />
                        )}
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_S_350"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_S_350"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_S_350"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_S_350"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow key={8}>
                      <TableCell>
                        <div>IFT hors herbicide</div>
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_S_36"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_S_36"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_S_36"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_S_36"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_S_360"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_S_360"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_S_360"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_S_360"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow
                      style={{
                        backgroundColor: theme.palette.background.default,
                      }}
                    >
                      <TableCell className={"d-flex bold"} colSpan={4}>
                        Nombre de points IFT
                      </TableCell>
                      <TableCell className={"total-point"} style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_S_3"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_S_3"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_S_3"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_S_3"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </React.Fragment>
      );
    }
  }
}

export default compose(withTranslation(), connect(mapStateToProps), withTheme)(PhytoIFT);
