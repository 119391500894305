import React from "react";
import { MenuItem, TextField, Tooltip } from "@material-ui/core";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import network from "../../../../../actions/external/network";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { setSelectedExploitation } from "../../../../../actions/exploitation";

const mapStateToProps = (state) => ({
  idExploitation: state.exploitation.selected,
});

const mapDispatchToProps = (dispatch) => ({
  updateExploitation: (newExploitation) => dispatch(setSelectedExploitation(newExploitation)),
});

class ListeBassinsViticoles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listeBassinsViticoles: [],
      loading: true,
    };
  }
  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { indicateur, sousRubriqueIndex, libelleEnglobantIndex, changeIndicateur } = this.props;
    const bassinVitiFromProps = this.state.listeBassinsViticoles.filter(
      (b) => b.idBassinViti === this.props?.idExploitation?.idBassinViti
    )[0];
    if (bassinVitiFromProps?.idBassinViti != null && indicateur.valeur.value.idBassinViti == null) {
      changeIndicateur(
        {
          ...indicateur,
          valeur: { value: bassinVitiFromProps },
        },
        sousRubriqueIndex,
        libelleEnglobantIndex
      );
    }
  }

  loadData = async () => {
    const { t } = this.props;
    try {
      const [listeBassinsViticoles] = await Promise.all([network.fetch("/api/demarches/listeBassinsViticoles")]);
      this.setState({
        listeBassinsViticoles,
        loading: false,
      });
    } catch (error) {
      toastr.error(t("error", "Erreur"), t("admin.users.load-error", "Erreur lors du chargement des données"));
    }
  };
  render() {
    const { indicateur, sousRubriqueIndex, libelleEnglobantIndex, changeIndicateur } = this.props;
    const { listeBassinsViticoles } = this.state;
    const valueRendered = (
      <TextField
        label={"Bassin viticole"}
        size={"small"}
        fullWidth={false}
        select
        SelectProps={{
          MenuProps: {
            PaperProps: {
              style: {
                maxHeight: 500,
                maxWidth: 700,
              },
            },
          },
          renderValue: (bassinViti) => {
            return (
              <Tooltip title={bassinViti.libelleBassinViti ?? ""} key={bassinViti.codeBassinViti}>
                <div>{bassinViti.libelleBassinViti}</div>
              </Tooltip>
            );
          },
        }}
        style={{ width: "25em" }}
        value={indicateur.valeur.value ?? ""}
        onChange={(event) => {
          return changeIndicateur(
            {
              ...indicateur,
              valeur: { value: event.target.value },
            },
            sousRubriqueIndex,
            libelleEnglobantIndex
          );
        }}
      >
        <MenuItem key={null} value={{ codeBassinViti: "", idBassinViti: -1, libelleBassinViti: "Non renseigné" }}>
          <i>Non renseigné</i>
        </MenuItem>
        {listeBassinsViticoles?.map((elem, i) => (
          <MenuItem key={i} value={elem}>
            {elem.libelleBassinViti}
          </MenuItem>
        ))}
      </TextField>
    );

    return <React.Fragment>{valueRendered}</React.Fragment>;
  }
}
export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps))(ListeBassinsViticoles);
