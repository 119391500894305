import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Box, Button, IconButton, MenuItem, TextField, Tooltip } from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import network from "../../../actions/external/network";
import { toastr } from "react-redux-toastr";
import AdminTable from "../../Admin/Table/AdminTable";
import AdminTableHead from "../../Admin/Table/AdminTableHead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import confirm from "../../common/confirm";
import DocumentDownload from "./DocumentDownload";
import Moment from "react-moment";

const mapStateToProps = (state) => ({
  idExploitation: state.exploitation.selected.idExploitation,
  idMillesime: state.millesime.selected.idMillesime,
});

class MesDocuments extends React.Component {
  config = {
    columns: [
      {
        id: "nom",
        label: "Nom",
      },
      {
        id: "dateCreation",
        label: "Date d'import",
      },
      {
        id: "validite",
        label: "Validité",
        ordering: false,
      },
      {
        id: "actions",
        label: "",
        type: AdminTableHead.columnsTypes.ACTION,
      },
    ],
  };

  constructor(props) {
    super(props);

    this.tableRef = React.createRef();

    this.state = {
      documents: [],
      validiteMillesime: true,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.idTypeDocument !== this.props.idTypeDocument ||
      prevState.validiteMillesime !== this.state.validiteMillesime
    ) {
      this.tableRef.current.loadData();
    }
  }

  loadDocuments = async ({ paramsUrl }) => {
    const { t, idExploitation, idMillesime, idTypeDocument } = this.props;

    try {
      if (idTypeDocument !== "") {
        paramsUrl.append("idTypeDocument", idTypeDocument);
      }
      if (this.state.validiteMillesime) {
        paramsUrl.append("millesime", idMillesime);
      }
      const documents = await network.fetch(`/api/exploitations/${idExploitation}/documents?${paramsUrl}`);
      this.setState({ documents });
    } catch (e) {
      toastr.error(
        t("error", "Erreur"),
        t("documents.load-error-multiple", "Une erreur est survenue lors du chargement des documents")
      );
    }
  };

  deleteDocument = async (idDocument) => {
    const { t, idExploitation } = this.props;

    if (!(await confirm(t("documents.confirm-delete", "Êtes-vous sûr de vouloir supprimer ce document ?")))) {
      return;
    }

    try {
      await network.fetch(`/api/exploitations/${idExploitation}/documents/${idDocument}`, {
        method: "DELETE",
      });
      toastr.success("OK", t("documents.delete-success", "Document supprimé"));
      this.tableRef.current.loadData();
    } catch (e) {
      toastr.error(
        t("error", "Erreur"),
        t("documents.delete-error", "Une erreur est survenue lors de la suppression du document")
      );
    }
  };

  renderFiltres = () => {
    const { validiteMillesime } = this.state;
    const { t, typesDocuments, idTypeDocument, idMillesime } = this.props;

    return (
      <Box className="d-flex">
        <TextField
          select
          SelectProps={{ displayEmpty: true }}
          name="idTypeDocument"
          value={idTypeDocument}
          onChange={(event) => this.props.onChangeTypeDocument(event.target.value)}
          variant="outlined"
          style={{ maxWidth: 400 }}
        >
          <MenuItem value={""}>{t("documents.all-types", `Tous les documents`)}</MenuItem>
          {typesDocuments.map((typeDocument) => (
            <MenuItem key={typeDocument.idTypeDocument} value={typeDocument.idTypeDocument}>
              {typeDocument.libelle}
            </MenuItem>
          ))}
        </TextField>
        <FormControlLabel
          control={
            <Switch
              checked={validiteMillesime}
              onChange={(event) => this.setState({ validiteMillesime: event.target.checked })}
              name="validiteMillesime"
              color="primary"
            />
          }
          label={`${t("documents.validite-millesime", "Valides pour le millésime ")} ${idMillesime}`}
          className="ml-1"
        />
      </Box>
    );
  };

  renderTable = () => {
    const { t, onSelect } = this.props;
    const { documents } = this.state;

    const rows = documents.map((document) => ({
      ...document,
      dateCreation: <Moment date={document.dateCreation} format="L" />,
      validite: document.isInterMillesime
        ? t("documents.all-millesimes", "Tous les millésimes")
        : document.millesimesValidite.length === 1
        ? document.millesimesValidite[0]
        : `${document.millesimesValidite[0]} - ${document.millesimesValidite[document.millesimesValidite.length - 1]}`,
      actions: (
        <React.Fragment>
          <DocumentDownload idDocument={document.idDocument} />
          <Tooltip title={t("admin.delete", "Supprimer")}>
            <IconButton onClick={() => this.deleteDocument(document.idDocument)}>
              <FontAwesomeIcon icon="trash-alt" />
            </IconButton>
          </Tooltip>
          {onSelect && (
            <Button type="button" color="primary" variant="contained" onClick={() => onSelect(document)}>
              {t("admin.select", "Sélectionner")}
            </Button>
          )}
        </React.Fragment>
      ),
    }));

    return (
      <AdminTable
        idColumn="idDocument"
        columns={this.config.columns}
        rows={rows}
        loadData={this.loadDocuments}
        showPagination={false}
        showToolbar={false}
        ref={this.tableRef}
      />
    );
  };

  render() {
    return (
      <Box className="page">
        {this.renderFiltres()}
        <Box className="mt-2">{this.renderTable()}</Box>
      </Box>
    );
  }
}

export default withRouter(connect(mapStateToProps, null)(withTranslation()(MesDocuments)));
