import network from "./external/network";

export const loadUniqueExploitation = () => async (dispatch, getState) => {
  const exploitations = await network.fetch(`/api/exploitations/search`);
  if (exploitations.count === 1) {
    dispatch(setSelectedExploitation(exploitations.data[0]));
  }
};

export const RESET_EXPLOITATIONS = "RESET_EXPLOITATIONS";
export const resetExploitations = () => ({ type: RESET_EXPLOITATIONS });

export const SET_SELECTED_EXPLOITATION = "SET_SELECTED_EXPLOITATION";
export const setSelectedExploitation = (exploitation) => ({
  type: SET_SELECTED_EXPLOITATION,
  payload: exploitation,
});
