import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import network from "../../../actions/external/network";
import { toastr } from "react-redux-toastr";
import { CircularProgress, Box, Typography } from "@material-ui/core";
import OrganismeForm from "./OrganismeForm";
import SigaModal from "../../common/SigaModal";
import { loadConfig } from "../../../actions/config";

const mapStateToProps = (state) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  loadConfig: () => dispatch(loadConfig()),
});

class Organisme extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      organisme: null,
      organismes: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  isEditing = () => this.props.idOrganisme != null;

  loadData = async () => {
    const { t } = this.props;
    try {
      const [organisme, demarches, config] = await Promise.all([
        this.isEditing() ? network.fetch(`/api/organismes/${this.props.idOrganisme}`) : Promise.resolve(null),
        network.fetch(`/api/demarches`),
        network.fetch(`/api/config/`),
      ]);
      this.setState({ organisme, demarches, config, loading: false });
    } catch (error) {
      toastr.error(t("error", "Erreur"), t("admin.farms.load-error", "Erreur lors du chargement des données"));
    }
  };

  onSubmit = async (values) => {
    const { t } = this.props;

    try {
      const response = await network.fetch(`/api/organismes${this.isEditing() ? `/${this.props.idOrganisme}` : ""}`, {
        method: this.isEditing() ? "PUT" : "POST",
        body: JSON.stringify(values),
      });
      toastr.success(
        "OK",
        this.isEditing()
          ? t("admin.organization.modify-success", "L'organisme a bien été modifié")
          : t("admin.organization.create-success", "L'organisme a bien été créé")
      );
      this.setState({ organisme: response });

      if (this.props.auth.idOrganismePrincipal === response.idOrganisme) {
        this.props.loadConfig();
      }

      this.props.afterSubmit && this.props.afterSubmit();
    } catch (error) {
      this.props.toastrActions({
        type: "error",
        title: "Erreur",
        attention: true,
        message: error.message,
        options: { showCloseButton: true },
      });
    }
  };

  render() {
    return (
      <Box className="w-100">
        {this.state.loading ? (
          <CircularProgress className="mt-2" />
        ) : (
          <OrganismeForm
            organisme={this.state.organisme}
            demarches={this.state.demarches}
            config={this.state.config}
            onSubmit={this.onSubmit}
          />
        )}
      </Box>
    );
  }
}

Organisme = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Organisme)));

export const OrganismeModal = (props) => {
  const { t } = useTranslation();
  const { showModal, onCloseModal, ...other } = props;

  return (
    <SigaModal
      open={showModal}
      onClose={onCloseModal}
      title={<Typography variant="h3">{t("admin.organization.title", "Organisme")}</Typography>}
    >
      <Organisme {...other} />
    </SigaModal>
  );
};
