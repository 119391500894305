import React from "react";
import { CircularProgress, IconButton, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation } from "react-i18next";
import network from "../../../actions/external/network";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { downloadBlob } from "../../../scripts/utils";

const mapStateToProps = (state) => ({
  idExploitation: state.exploitation.selected.idExploitation,
});

class DocumentDownload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      downloading: false,
    };
  }

  onDownload = async () => {
    const { t, idExploitation, idDocument } = this.props;

    this.setState({ downloading: true });

    try {
      const response = await network.fetch(`/api/exploitations/${idExploitation}/documents/${idDocument}`);
      await downloadBlob(response);
    } catch (error) {
      toastr.error(
        t("error", "Erreur"),
        t("documents.load-error", "Une erreur est survenue lors du chargement du document")
      );
    }

    this.setState({ downloading: false });
  };

  render() {
    const { t } = this.props;
    const { downloading } = this.state;

    return downloading ? (
      <CircularProgress size={24} />
    ) : (
      <Tooltip title={t("download", "Télécharger")}>
        <IconButton onClick={this.onDownload}>
          <FontAwesomeIcon icon="file-download" />
        </IconButton>
      </Tooltip>
    );
  }
}

export default connect(mapStateToProps, null)(withTranslation()(DocumentDownload));
