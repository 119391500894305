export const COMPUTED_FIELDS_BIODIV = {
  // RUBRIQUE BIODIVERSITE
  HVE4_BIODIV_D_100: {
    columns: ["HVE4_BIODIV_D_1"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 1.5 * getValueOrZero(state, "HVE4_BIODIV_D_1"),
      },
    }),
  },
  HVE4_BIODIV_D_1100: {
    columns: ["HVE4_BIODIV_D_11"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 1.5 * getValueOrZero(state, "HVE4_BIODIV_D_11"),
      },
    }),
  },
  HVE4_BIODIV_D_200: {
    columns: ["HVE4_BIODIV_D_2"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 0.001 * getValueOrZero(state, "HVE4_BIODIV_D_2"),
      },
    }),
  },
  HVE4_BIODIV_D_1200: {
    columns: ["HVE4_BIODIV_D_12"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 0.001 * getValueOrZero(state, "HVE4_BIODIV_D_12"),
      },
    }),
  },
  HVE4_BIODIV_D_1300: {
    columns: ["HVE4_BIODIV_D_13"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 2 * getValueOrZero(state, "HVE4_BIODIV_D_13"),
      },
    }),
  },
  HVE4_BIODIV_D_300: {
    columns: ["HVE4_BIODIV_D_3"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 0.0009 * getValueOrZero(state, "HVE4_BIODIV_D_3"),
      },
    }),
  },
  HVE4_BIODIV_D_1400: {
    columns: ["HVE4_BIODIV_D_14"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 0.0009 * getValueOrZero(state, "HVE4_BIODIV_D_14"),
      },
    }),
  },
  HVE4_BIODIV_D_400: {
    columns: ["HVE4_BIODIV_D_4"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 1.0 * getValueOrZero(state, "HVE4_BIODIV_D_4"),
      },
    }),
  },
  HVE4_BIODIV_D_1600: {
    columns: ["HVE4_BIODIV_D_16"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 1.0 * getValueOrZero(state, "HVE4_BIODIV_D_16"),
      },
    }),
  },
  HVE4_BIODIV_D_500: {
    columns: ["HVE4_BIODIV_D_5"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 1.5 * getValueOrZero(state, "HVE4_BIODIV_D_5"),
      },
    }),
  },
  HVE4_BIODIV_D_1700: {
    columns: ["HVE4_BIODIV_D_17"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 1.5 * getValueOrZero(state, "HVE4_BIODIV_D_17"),
      },
    }),
  },
  HVE4_BIODIV_D_1800: {
    columns: ["HVE4_BIODIV_D_18"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 0.0009 * getValueOrZero(state, "HVE4_BIODIV_D_18"),
      },
    }),
  },
  HVE4_BIODIV_D_1900: {
    columns: ["HVE4_BIODIV_D_19"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 1.5 * getValueOrZero(state, "HVE4_BIODIV_D_19"),
      },
    }),
  },
  HVE4_BIODIV_D_2000: {
    columns: ["HVE4_BIODIV_D_20"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 0.0009 * getValueOrZero(state, "HVE4_BIODIV_D_20"),
      },
    }),
  },
  HVE4_BIODIV_D_2100: {
    columns: ["HVE4_BIODIV_D_21"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 1.0 * getValueOrZero(state, "HVE4_BIODIV_D_21"),
      },
    }),
  },
  HVE4_BIODIV_D_2200: {
    columns: ["HVE4_BIODIV_D_22"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 0.0005 * getValueOrZero(state, "HVE4_BIODIV_D_22"),
      },
    }),
  },
  HVE4_BIODIV_D_22000: {
    columns: ["HVE4_BIODIV_D_220"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 0.0005 * getValueOrZero(state, "HVE4_BIODIV_D_220"),
      },
    }),
  },
  HVE4_BIODIV_D_600: {
    columns: ["HVE4_BIODIV_D_6"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 0.002 * getValueOrZero(state, "HVE4_BIODIV_D_6"),
      },
    }),
  },
  HVE4_BIODIV_D_2300: {
    columns: ["HVE4_BIODIV_D_23"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 0.002 * getValueOrZero(state, "HVE4_BIODIV_D_23"),
      },
    }),
  },
  HVE4_BIODIV_D_700: {
    columns: ["HVE4_BIODIV_D_7"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 0.001 * getValueOrZero(state, "HVE4_BIODIV_D_7"),
      },
    }),
  },
  HVE4_BIODIV_D_2400: {
    columns: ["HVE4_BIODIV_D_24"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 0.001 * getValueOrZero(state, "HVE4_BIODIV_D_24"),
      },
    }),
  },
  HVE4_BIODIV_D_800: {
    columns: ["HVE4_BIODIV_D_8"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 0.003 * getValueOrZero(state, "HVE4_BIODIV_D_8"),
      },
    }),
  },
  HVE4_BIODIV_D_2500: {
    columns: ["HVE4_BIODIV_D_25"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 0.003 * getValueOrZero(state, "HVE4_BIODIV_D_25"),
      },
    }),
  },
  HVE4_BIODIV_D_900: {
    columns: ["HVE4_BIODIV_D_9"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 1.5 * getValueOrZero(state, "HVE4_BIODIV_D_9"),
      },
    }),
  },
  HVE4_BIODIV_D_2600: {
    columns: ["HVE4_BIODIV_D_26"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 1.5 * getValueOrZero(state, "HVE4_BIODIV_D_26"),
      },
    }),
  },
  HVE4_BIODIV_D_2700: {
    columns: ["HVE4_BIODIV_D_27"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 3.0 * getValueOrZero(state, "HVE4_BIODIV_D_27"),
      },
    }),
  },
  HVE4_BIODIV_D_1000: {
    columns: ["HVE4_BIODIV_D_10"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 0.0001 * getValueOrZero(state, "HVE4_BIODIV_D_10"),
      },
    }),
  },
  HVE4_BIODIV_D_2800: {
    columns: ["HVE4_BIODIV_D_28"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 0.0001 * getValueOrZero(state, "HVE4_BIODIV_D_28"),
      },
    }),
  },
  HVE4_BIODIV_D_29: {
    columns: [
      "HVE4_CULT_D_8",
      "HVE4_CULT_D_13",
      "HVE4_CULT_D_18",
      "HVE4_CULT_D_19",
      "HVE4_CULT_D_25",
      "HVE4_CULT_D_26",
      "HVE4_CULT_D_27",
      "HVE4_CULT_D_28",
      "HVE4_CULT_D_29",
      "HVE4_CULT_D_30",
      "HVE4_CULT_D_37",
      "HVE4_CULT_D_38",
      "HVE4_CULT_D_39",
      "HVE4_CULT_D_40",
      "HVE4_CULT_D_41",
      "HVE4_CULT_D_20",
      "HVE4_CULT_D_20_1",
    ],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS_BIODIV.HVE4_BIODIV_D_29.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE4_BIODIV_D_30: {
    columns: [
      "HVE4_CULT_D_14",
      "HVE4_CULT_D_15",
      "HVE4_CULT_D_16",
      "HVE4_CULT_D_17",
      "HVE4_CULT_D_20",
      "HVE4_CULT_D_20_1",
      "HVE4_CULT_D_20_2",
      "HVE4_CULT_D_26",
      "HVE4_CULT_D_27",
      "HVE4_CULT_D_28",
      "HVE4_CULT_D_29",
      "HVE4_CULT_D_30",
      "HVE4_BIODIV_D_29",
    ],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          100 *
          (COMPUTED_FIELDS_BIODIV.HVE4_BIODIV_D_30.columns
            .filter((item) => item !== "HVE4_BIODIV_D_29")
            .reduce((sum, codeNational) => sum + getValueOrZero(state, codeNational), 0) /
            getValueOrZero(state, "HVE4_BIODIV_D_29")),
      },
    }),
  },
  HVE4_BIODIV_D_31: {
    columns: ["HVE4_CULT_D_6", "HVE4_CULT_D_34", "HVE4_CULT_D_200"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          (100 * (getValueOrZero(state, "HVE4_CULT_D_6") + getValueOrZero(state, "HVE4_CULT_D_34"))) /
          getValueOrZero(state, "HVE4_CULT_D_200"),
      },
    }),
  },
  HVE4_BIODIV_D_293031: {
    columns: ["HVE4_BIODIV_D_29", "HVE4_BIODIV_D_30", "HVE4_BIODIV_D_31"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: !(
          getValueOrZero(state, "HVE4_BIODIV_D_29") < 10 ||
          getValueOrZero(state, "HVE4_BIODIV_D_30") > 75 ||
          getValueOrZero(state, "HVE4_BIODIV_D_31") > 75
        ),
      },
    }),
  },
  HVE4_BIODIV_D_32: {
    columns: [
      "HVE4_BIODIV_D_100",
      "HVE4_BIODIV_D_200",
      "HVE4_BIODIV_D_300",
      "HVE4_BIODIV_D_400",
      "HVE4_BIODIV_D_500",
      "HVE4_BIODIV_D_600",
      "HVE4_BIODIV_D_700",
      "HVE4_BIODIV_D_800",
      "HVE4_BIODIV_D_900",
      "HVE4_BIODIV_D_1000",
    ],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS_BIODIV.HVE4_BIODIV_D_32.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE4_BIODIV_D_33: {
    columns: ["HVE4_BIODIV_D_32", "HVE4_BIODIV_D_29"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, "HVE4_BIODIV_D_29") !== 0
            ? (100 * getValueOrZero(state, "HVE4_BIODIV_D_32")) / getValueOrZero(state, "HVE4_BIODIV_D_29")
            : 0,
      },
    }),
  },
  HVE4_BIODIV_D_322933: {
    columns: ["HVE4_BIODIV_D_293031", "HVE4_BIODIV_D_33"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: !getValueOrZero(state, "HVE4_BIODIV_D_293031")
          ? "NON CONCERNEE"
          : getValueOrZero(state, "HVE4_BIODIV_D_33") >= 4
          ? "OK"
          : "KO",
      },
    }),
  },
  HVE4_BIODIV_D_34: {
    columns: [
      "HVE4_BIODIV_D_1100",
      "HVE4_BIODIV_D_1200",
      "HVE4_BIODIV_D_1300",
      "HVE4_BIODIV_D_1400",
      "HVE4_BIODIV_D_1600",
      "HVE4_BIODIV_D_1700",
      "HVE4_BIODIV_D_1800",
      "HVE4_BIODIV_D_1900",
      "HVE4_BIODIV_D_2000",
      "HVE4_BIODIV_D_2100",
      "HVE4_BIODIV_D_2200",
      "HVE4_BIODIV_D_2300",
      "HVE4_BIODIV_D_2400",
      "HVE4_BIODIV_D_2500",
      "HVE4_BIODIV_D_2600",
      "HVE4_BIODIV_D_2700",
      "HVE4_BIODIV_D_2800",
      "HVE4_BIODIV_D_22000",
    ],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS_BIODIV.HVE4_BIODIV_D_34.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE4_BIODIV_D_35: {
    columns: ["HVE4_CULT_D_205"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: getValueOrZero(state, "HVE4_CULT_D_205"),
      },
    }),
  },
  HVE4_BIODIV_I_36: {
    columns: ["HVE4_BIODIV_D_34", "HVE4_BIODIV_D_35"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, "HVE4_BIODIV_D_35") !== 0
            ? 100 * (getValueOrZero(state, "HVE4_BIODIV_D_34") / getValueOrZero(state, "HVE4_BIODIV_D_35"))
            : 0,
      },
    }),
  },
  HVE4_BIODIV_S_36: {
    columns: ["HVE4_BIODIV_I_36", "HVE4_BIODIV_D_33", "HVE4_BIODIV_D_293031"],
    disabled: true,
    computedValue: (state) => {
      let value = 0;
      let scoreIndicateur = getValueOrZero(state, "HVE4_BIODIV_I_36");
      if (getIndicOrNull(state, "HVE4_BIODIV_D_293031").value && getValueOrZero(state, "HVE4_BIODIV_D_33") < 4) {
        value = 0;
      } else {
        switch (true) {
          case 0 <= scoreIndicateur && scoreIndicateur < 4:
            value = 0;
            break;
          case 4 <= scoreIndicateur && scoreIndicateur < 5:
            value = 1;
            break;
          case 5 <= scoreIndicateur && scoreIndicateur < 6:
            value = 2;
            break;
          case 6 <= scoreIndicateur && scoreIndicateur < 7:
            value = 3;
            break;
          case 7 <= scoreIndicateur && scoreIndicateur < 8:
            value = 4;
            break;
          case 8 <= scoreIndicateur && scoreIndicateur < 9:
            value = 5;
            break;
          case 9 <= scoreIndicateur && scoreIndicateur < 10:
            value = 6;
            break;
          case 10 <= scoreIndicateur:
            value = 7;
            break;
          default:
            break;
        }
      }
      return {
        valeur: {
          value: value,
        },
      };
    },
  },
  HVE4_BIODIV_I_37: {
    columns: [
      "HVE4_BIODIV_D_11",
      "HVE4_BIODIV_D_12",
      "HVE4_BIODIV_D_13",
      "HVE4_BIODIV_D_14",
      "HVE4_BIODIV_D_16",
      "HVE4_BIODIV_D_17",
      "HVE4_BIODIV_D_18",
      "HVE4_BIODIV_D_19",
      "HVE4_BIODIV_D_20",
      "HVE4_BIODIV_D_21",
      "HVE4_BIODIV_D_22",
      "HVE4_BIODIV_D_220",
      "HVE4_BIODIV_D_23",
      "HVE4_BIODIV_D_24",
      "HVE4_BIODIV_D_25",
      "HVE4_BIODIV_D_26",
      "HVE4_BIODIV_D_27",
      "HVE4_BIODIV_D_28",
    ],
    disabled: true,
    computedValue: (state) => {
      //Calcul du nombre de type d'IAE existants
      const iaeAquaCodes = ["HVE4_BIODIV_D_11", "HVE4_BIODIV_D_12", "HVE4_BIODIV_D_13"];
      let countIAEAqua = 0;
      const iaeHerbeCodes = [
        "HVE4_BIODIV_D_14",
        "HVE4_BIODIV_D_16",
        "HVE4_BIODIV_D_17",
        "HVE4_BIODIV_D_18",
        "HVE4_BIODIV_D_19",
        "HVE4_BIODIV_D_20",
        "HVE4_BIODIV_D_21",
        "HVE4_BIODIV_D_220",
      ];
      let countIAEHerbe = 0;
      const iaeLigneCodes = [
        "HVE4_BIODIV_D_23",
        "HVE4_BIODIV_D_24",
        "HVE4_BIODIV_D_25",
        "HVE4_BIODIV_D_26",
        "HVE4_BIODIV_D_27",
      ];
      let countIAELigne = 0;
      const iaeRocheCodes = ["HVE4_BIODIV_D_28", "HVE4_BIODIV_D_22"];
      let countIAERoche = 0;

      countIAEAqua =
        iaeAquaCodes.reduce((sum, codeNational) => sum + getValueOrZero(state, codeNational), 0) > 0 ? 1 : 0;

      countIAEHerbe =
        iaeHerbeCodes.reduce((sum, codeNational) => sum + getValueOrZero(state, codeNational), 0) > 0 ? 1 : 0;

      countIAELigne =
        iaeLigneCodes.reduce((sum, codeNational) => sum + getValueOrZero(state, codeNational), 0) > 0 ? 1 : 0;

      countIAERoche =
        iaeRocheCodes.reduce((sum, codeNational) => sum + getValueOrZero(state, codeNational), 0) > 0 ? 1 : 0;

      return {
        valeur: {
          value: countIAEAqua + countIAEHerbe + countIAELigne + countIAERoche,
        },
      };
    },
  },
  HVE4_BIODIV_S_37: {
    columns: ["HVE4_BIODIV_I_37", "HVE4_BIODIV_D_33", "HVE4_BIODIV_D_293031"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getIndicOrNull(state, "HVE4_BIODIV_D_293031").value && getValueOrZero(state, "HVE4_BIODIV_D_33") < 4
            ? 0
            : getValueOrZero(state, "HVE4_BIODIV_I_37") >= 3
            ? 2
            : 0,
      },
    }),
  },
  HVE4_BIODIV_S_38: {
    columns: ["HVE4_BIODIV_S_36", "HVE4_BIODIV_S_37"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: roundFourDecimales(
          getValueOrZero(state, "HVE4_BIODIV_S_36") + getValueOrZero(state, "HVE4_BIODIV_S_37")
        ),
      },
    }),
  },
  HVE4_BIODIV_D_4000: {
    columns: ["HVE4_CULT_26", "HVE4_CULT_27", "HVE4_CULT_28", "HVE4_CULT_29", "HVE4_CULT_30", "HVE4_CULT_200"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, "HVE4_CULT_200") !== null
            ? (100 *
                (getValueOrZero(state, "HVE4_CULT_26") +
                  getValueOrZero(state, "HVE4_CULT_27") +
                  getValueOrZero(state, "HVE4_CULT_28") +
                  getValueOrZero(state, "HVE4_CULT_29") +
                  getValueOrZero(state, "HVE4_CULT_30"))) /
              getValueOrZero(state, "HVE4_CULT_200")
            : 0,
      },
    }),
  },
  HVE4_BIODIV_D_4001: {
    columns: ["HVE4_BIODIV_D_4000", "HVE4_CULT_200"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, "HVE4_BIODIV_D_4000") >= 0.1 * getValueOrZero(state, "HVE4_CULT_D_200")
            ? 0.1 * getValueOrZero(state, "HVE4_CULT_D_200")
            : 0,
      },
    }),
  },
  HVE4_BIODIV_I_43: {
    columns: ["HVE4_BIODIV_D_41", "HVE4_BIODIV_D_42"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_BIODIV_D_42") !== 0
              ? 100 * (getValueOrZero(state, "HVE4_BIODIV_D_41") / getValueOrZero(state, "HVE4_BIODIV_D_42"))
              : 0,
        },
      };
    },
  },
  HVE4_BIODIV_D_42: {
    columns: ["HVE4_CULT_D_200", "HVE4_CULT_D_31", "HVE4_CULT_D_32", "HVE4_CULT_D_33"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, "HVE4_CULT_D_200") -
          getValueOrZero(state, "HVE4_CULT_D_31") -
          getValueOrZero(state, "HVE4_CULT_D_32") -
          getValueOrZero(state, "HVE4_CULT_D_33"),
      },
    }),
  },
  HVE4_BIODIV_D_380: {
    columns: ["HVE4_CULT_D_200", "HVE4_CULT_D_31", "HVE4_CULT_D_32", "HVE4_CULT_D_33"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_CULT_D_200") > 0
              ? (100 *
                  (getValueOrZero(state, "HVE4_CULT_D_31") +
                    getValueOrZero(state, "HVE4_CULT_D_32") +
                    getValueOrZero(state, "HVE4_CULT_D_33"))) /
                getValueOrZero(state, "HVE4_CULT_D_200")
              : 0,
        },
      };
    },
  },
  HVE4_BIODIV_S_43: {
    columns: ["HVE4_BIODIV_D_380", "HVE4_BIODIV_I_43"],
    disabled: true,
    computedValue: (state) => {
      let value = 0;
      let scoreIndicateur = getValueOrZero(state, "HVE4_BIODIV_I_43");
      if (getValueOrZero(state, "HVE4_BIODIV_D_380") === 100) {
        value = 5;
      } else {
        switch (true) {
          case scoreIndicateur < 20:
            value = 5;
            break;
          case 20 <= scoreIndicateur && scoreIndicateur < 30:
            value = 4;
            break;
          case 30 <= scoreIndicateur && scoreIndicateur < 40:
            value = 3;
            break;
          case 40 <= scoreIndicateur && scoreIndicateur < 50:
            value = 2;
            break;
          case 50 <= scoreIndicateur && scoreIndicateur < 60:
            value = 1;
            break;
          case 60 <= scoreIndicateur:
            value = 0;
            break;
          default:
            break;
        }
      }
      return {
        valeur: {
          value: value,
        },
      };
    },
  },
  HVE4_BIODIV_D_4445: {
    columns: ["HVE4_BIODIV_D_44", "HVE4_BIODIV_D_45"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS_BIODIV.HVE4_BIODIV_D_4445.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE4_BIODIV_D_470: {
    columns: ["HVE4_BIODIV_D_47"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: getValueOrZero(state, "HVE4_BIODIV_D_47") > 0 ? 2 : 0,
      },
    }),
  },
  HVE4_BIODIV_D_480: {
    columns: ["HVE4_BIODIV_D_48"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: getValueOrZero(state, "HVE4_BIODIV_D_48") > 0 ? 2 : 0,
      },
    }),
  },
  HVE4_BIODIV_D_490: {
    columns: ["HVE4_BIODIV_D_49"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: getValueOrZero(state, "HVE4_BIODIV_D_49") > 0 ? 3 : 0,
      },
    }),
  },
  HVE4_BIODIV_D_3800: {
    columns: ["HVE4_BIODIV_D_380"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: Math.ceil(getValueOrZero(state, "HVE4_BIODIV_D_380") / 10),
      },
    }),
  },
  HVE4_BIODIV_I_54: {
    columns: [
      "HVE4_BIODIV_D_4445",
      "HVE4_BIODIV_D_46",
      "HVE4_BIODIV_D_470",
      "HVE4_BIODIV_D_480",
      "HVE4_BIODIV_D_490",
      "HVE4_BIODIV_D_3800",
      "HVE4_BIODIV_D_530",
      "HVE4_BIODIV_D_510",
      "HVE4_BIODIV_D_52",
      "HVE4_BIODIV_D_53",
      "HVE4_BIODIV_D_54",
    ],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS_BIODIV.HVE4_BIODIV_I_54.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE4_BIODIV_S_54: {
    columns: ["HVE4_BIODIV_I_43", "HVE4_BIODIV_D_380", "HVE4_BIODIV_I_54"],
    disabled: true,
    computedValue: (state) => {
      let value = 0;
      let scoreIndicateur = getValueOrZero(state, "HVE4_BIODIV_I_54");
      if (getValueOrZero(state, "HVE4_BIODIV_I_43") >= 60) {
        switch (true) {
          case scoreIndicateur <= 4:
            value = 0;
            break;
          case scoreIndicateur === 5:
            value = 0;
            break;
          case scoreIndicateur === 6:
            value = 1;
            break;
          case scoreIndicateur === 7:
            value = 2;
            break;
          case scoreIndicateur === 8:
            value = 3;
            break;
          case scoreIndicateur === 9:
            value = 4;
            break;
          case scoreIndicateur >= 10:
            value = 5;
            break;
          default:
            break;
        }
      } else if (
        getValueOrZero(state, "HVE4_BIODIV_I_43") <= 60 ||
        getValueOrZero(state, "HVE4_BIODIV_D_380") === 100
      ) {
        switch (true) {
          case scoreIndicateur <= 4:
            value = 0;
            break;
          case scoreIndicateur === 5:
            value = 1;
            break;
          case scoreIndicateur === 6:
            value = 2;
            break;
          case scoreIndicateur === 7:
            value = 3;
            break;
          case scoreIndicateur === 8:
            value = 4;
            break;
          case scoreIndicateur === 9:
            value = 5;
            break;
          case scoreIndicateur >= 10:
            value = 6;
            break;
          default:
            break;
        }
      }
      return {
        valeur: {
          value: value,
        },
      };
    },
  },
  HVE4_BIODIV_I_61: {
    columns: [
      "HVE4_BIODIV_D_55",
      "HVE4_BIODIV_D_56",
      "HVE4_BIODIV_D_57",
      "HVE4_BIODIV_D_58",
      "HVE4_BIODIV_D_59",
      "HVE4_BIODIV_D_60",
    ],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS_BIODIV.HVE4_BIODIV_I_61.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE4_BIODIV_S_61: {
    columns: ["HVE4_BIODIV_I_61"],
    disabled: true,
    computedValue: (state) => {
      let value = 0;
      let scoreIndicateur = getValueOrZero(state, "HVE4_BIODIV_I_61");
      switch (true) {
        case scoreIndicateur <= 1:
          value = 0;
          break;
        case scoreIndicateur === 2:
          value = 1;
          break;
        case scoreIndicateur === 3:
          value = 2;
          break;
        case scoreIndicateur >= 4:
          value = 3;
          break;
        default:
          break;
      }
      return {
        valeur: {
          value: value,
        },
      };
    },
  },
  HVE4_BIODIV_S_62: {
    columns: ["HVE4_BIODIV_I_62"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: getValueOrZero(state, "HVE4_BIODIV_I_62") >= 3 ? 1 : 0,
      },
    }),
  },
  HVE4_BIODIV_S_63: {
    columns: ["HVE4_BIODIV_I_63"],
    disabled: true,
    computedValue: (state) => {
      let value = 0;
      let scoreIndicateur = getValueOrZero(state, "HVE4_BIODIV_I_63");
      switch (true) {
        case scoreIndicateur === 0:
          value = 0;
          break;
        case scoreIndicateur === 1:
          value = 1;
          break;
        case scoreIndicateur === 2:
          value = 2;
          break;
        case scoreIndicateur >= 3:
          value = 3;
          break;
        default:
          break;
      }
      return {
        valeur: {
          value: value,
        },
      };
    },
  },
  HVE4_BIODIV_S_64: {
    columns: ["HVE4_BIODIV_I_64"],
    disabled: true,
    computedValue: (state) => {
      let value = 0;
      let scoreIndicateur = getValueOrZero(state, "HVE4_BIODIV_I_64");
      switch (true) {
        case scoreIndicateur === 0:
          value = 0;
          break;
        case scoreIndicateur === 1:
          value = 1;
          break;
        case scoreIndicateur === 2:
          value = 2;
          break;
        case scoreIndicateur >= 3:
          value = 3;
          break;
        default:
          break;
      }
      return {
        valeur: {
          value: value,
        },
      };
    },
  },
  HVE4_BIODIV_S_600: {
    columns: ["HVE4_BIODIV_S_63", "HVE4_BIODIV_S_64"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: roundFourDecimales(
            COMPUTED_FIELDS_BIODIV.HVE4_BIODIV_S_600.columns.reduce(
              (sum, codeNational) => sum + getValueOrZero(state, codeNational),
              0
            )
          ),
        },
      };
    },
  },
  HVE4_BIODIV_S_66: {
    columns: ["HVE4_BIODIV_I_66"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: getValueOrZero(state, "HVE4_BIODIV_I_66") ? 1 : 0,
      },
    }),
  },
  HVE4_BIODIV_S_67: {
    columns: ["HVE4_BIODIV_I_67"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: getValueOrZero(state, "HVE4_BIODIV_I_67") ? 1 : 0,
      },
    }),
  },
  HVE4_BIODIV_S_676: {
    columns: ["HVE4_BIODIV_I_67", "HVE4_BIODIV_I_67", "HVE4_BIODIV_S_66", "HVE4_BIODIV_S_67"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: roundFourDecimales(
          Math.min(1, getValueOrZero(state, "HVE4_BIODIV_S_66") + getValueOrZero(state, "HVE4_BIODIV_S_67"))
        ),
      },
    }),
  },
  HVE4_BIODIV_S_70: {
    columns: [
      "HVE4_BIODIV_S_38",
      "HVE4_BIODIV_S_39",
      "HVE4_BIODIV_S_43",
      "HVE4_BIODIV_S_54",
      "HVE4_BIODIV_S_61",
      "HVE4_BIODIV_S_62",
      "HVE4_BIODIV_S_600",
      "HVE4_BIODIV_S_676",
    ],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: {
            commentaire: getIndicOrNull(state, "HVE4_BIODIV_S_70").value.commentaire ?? null,
            scoreTotal: roundFourDecimales(
              COMPUTED_FIELDS_BIODIV.HVE4_BIODIV_S_70.columns.reduce(
                (sum, codeNational) => sum + getValueOrZero(state, codeNational),
                0
              )
            ),
          },
        },
      };
    },
  },
  // FIN RUBRIQUE BIODIVERSITE
};

const getValueOrZero = (state, codeNational) => {
  return state.indicateurs[codeNational] && !isNaN(Number(state.indicateurs[codeNational].valeur.value))
    ? Number(state.indicateurs[codeNational].valeur.value)
    : 0;
};

const roundFourDecimales = (num) => {
  return Math.round((num + Number.EPSILON) * 10000) / 10000;
};

const getIndicOrNull = (state, codeNational) => {
  return state.indicateurs[codeNational] && state.indicateurs[codeNational] !== undefined
    ? state.indicateurs[codeNational].valeur
    : null;
};

const exports = {
  COMPUTED_FIELDS_BIODIV: COMPUTED_FIELDS_BIODIV,
};
export default exports;
