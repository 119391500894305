import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import {
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
import Percent from "../indicateurs/Percent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Assolement from "../indicateurs/Assolement";
const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});
class FertiOAD extends React.Component {
  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    //On ne doit pas rechanger un indicateur qui est calculé et changé depuis une autre rubrique, provoque une boucle de changeIndicateur qui bloque les inputs.
    if (newIndicateur.codeNational !== "HVE4_FERTI_D_1050") {
      changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
    }
  };

  render() {
    const { indicateurs, computedFields, getValueAssolement } = this.props;

    const indicateursKeys = [
      "HVE4_FERTI_D_400",
      "HVE4_FERTI_I_40",
      "HVE4_FERTI_D_40",
      "HVE4_FERTI_S_40",
      "HVE4_FERTI_D_41",
      "HVE4_FERTI_I_41",
      "HVE4_FERTI_S_41",
      "HVE4_FERTI_D_42",
      "HVE4_FERTI_I_42",
      "HVE4_FERTI_S_42",
      "HVE4_FERTI_D_43",
      "HVE4_FERTI_I_43",
      "HVE4_FERTI_S_43",
      "HVE4_FERTI_D_48",
      "HVE4_FERTI_D_49",
      "HVE4_FERTI_S_430",
      "HVE4_FERTI_D_44",
      "HVE4_FERTI_D_45_1",
      "HVE4_FERTI_D_45_2",
      "HVE4_FERTI_D_45",
      "HVE4_FERTI_D_46",
      "HVE4_FERTI_I_44",
      "HVE4_FERTI_S_44",
      "HVE4_FERTI_I_45",
      "HVE4_FERTI_S_45",
      "HVE4_FERTI_S_400",
      "HVE4_FERTI_D_46_1",
      "HVE4_FERTI_D_47",
      "HVE4_FERTI_I_46",
      "HVE4_FERTI_S_46",
      "HVE4_FERTI_I_47",
      "HVE4_FERTI_S_47",
      "HVE4_FERTI_S_401",
      "HVE4_FERTI_S_496",
      "HVE4_FERTI_S_497",
      "HVE4_FERTI_S_402",
      "HVE4_FERTI_D_1050",
    ];

    const indicateursToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateursKeys.includes(key)))
    );

    if (indicateursToDisplay.length > 0)
      return (
        <React.Fragment>
          <Grid container>
            <Grid item xs={11}>
              <TableContainer component={Paper}>
                <Table style={{ tableLayout: "fixed" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <div className={"bold"}>CULTURES PRINCIPALES</div>
                      </TableCell>
                      <TableCell colSpan={4} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <div className={"bold"}>Grandes cultures</div>
                      </TableCell>
                      <TableCell align={"center"}>Surfaces concernées (ha)</TableCell>
                      <TableCell align={"center"}>Part surface concernée</TableCell>
                      <TableCell align={"center"}>Pondération de surface</TableCell>
                      <TableCell align={"center"}>Points</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <div>{indicateurs["HVE4_FERTI_D_400"].libelle}</div>
                      </TableCell>
                      <TableCell align={"center"}>
                        <Assolement
                          size={"small"}
                          displayRules={{ displayAssolement: "column" }}
                          getValueAssolement={getValueAssolement}
                          indicateur={indicateurs["HVE4_FERTI_D_400"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_D_400"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_D_400"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_D_400"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell colSpan={3} />
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Grid container>
                          <Grid item xs={10}>
                            <div>Utilisation d'un outil Plan Prévisionnel de Fumure</div>
                          </Grid>
                          <Grid item xs={2}>
                            <Tooltip
                              title={
                                <span style={{ whiteSpace: "pre-line" }}>
                                  {indicateurs["HVE4_FERTI_D_40"].messageAide}
                                </span>
                              }
                              interactive
                            >
                              <IconButton>
                                <FontAwesomeIcon icon="info-circle" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align={"center"}>
                        <DecimalPositif
                          error={
                            indicateurs["HVE4_FERTI_D_40"].valeur.value > indicateurs["HVE4_FERTI_D_400"].valeur.value
                          }
                          helperText={
                            indicateurs["HVE4_FERTI_D_40"].valeur.value > indicateurs["HVE4_FERTI_D_400"].valeur.value
                              ? "La valeur saisie ne doit pas dépasser la surface totale des cultures principales grandes cultures (y compris blé)"
                              : ""
                          }
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_D_40"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_D_40"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_D_40"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_D_40"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell align={"center"}>
                        <Percent
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_I_40"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_I_40"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_I_40"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_I_40"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell rowSpan={3} />
                      <TableCell align={"center"}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_S_40"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_S_40"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_S_40"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_S_40"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Grid container alignItems={"center"}>
                          <Grid item xs={10}>
                            <div>Utilisation d'un outil PPF Ajustement</div>
                          </Grid>
                          <Grid item xs={2}>
                            <Tooltip
                              title={
                                <span style={{ whiteSpace: "pre-line" }}>
                                  {indicateurs["HVE4_FERTI_D_41"].messageAide}
                                </span>
                              }
                            >
                              <IconButton>
                                <FontAwesomeIcon icon="info-circle" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align={"center"}>
                        <DecimalPositif
                          error={
                            indicateurs["HVE4_FERTI_D_41"].valeur.value > indicateurs["HVE4_FERTI_D_400"].valeur.value
                          }
                          helperText={
                            indicateurs["HVE4_FERTI_D_41"].valeur.value > indicateurs["HVE4_FERTI_D_400"].valeur.value
                              ? "La valeur saisie ne doit pas dépasser la surface totale des cultures principales grandes cultures (y compris blé)"
                              : ""
                          }
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_D_41"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_D_41"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_D_41"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_D_41"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell align={"center"}>
                        <Percent
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_I_41"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_I_41"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_I_41"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_I_41"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell align={"center"}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_S_41"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_S_41"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_S_41"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_S_41"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Grid container alignItems={"center"}>
                          <Grid item xs={10}>
                            <div>Utilisation d'un Outil de Pilotage</div>
                          </Grid>
                          <Grid item xs={2}>
                            <Tooltip
                              title={
                                <span style={{ whiteSpace: "pre-line" }}>
                                  {indicateurs["HVE4_FERTI_D_42"].messageAide}
                                </span>
                              }
                            >
                              <IconButton>
                                <FontAwesomeIcon icon="info-circle" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align={"center"}>
                        <DecimalPositif
                          error={
                            indicateurs["HVE4_FERTI_D_42"].valeur.value > indicateurs["HVE4_FERTI_D_400"].valeur.value
                          }
                          helperText={
                            indicateurs["HVE4_FERTI_D_42"].valeur.value > indicateurs["HVE4_FERTI_D_400"].valeur.value
                              ? "La valeur saisie ne doit pas dépasser la surface totale des cultures principales grandes cultures (y compris blé)"
                              : ""
                          }
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_D_42"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_D_42"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_D_42"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_D_42"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell align={"center"}>
                        <Percent
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_I_42"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_I_42"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_I_42"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_I_42"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell align={"center"}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_S_42"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_S_42"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_S_42"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_S_42"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Grid container alignItems={"center"}>
                          <Grid item xs={10}>
                            <div>Utilisation d'un Outil de Pilotage Intégral sur blé</div>
                          </Grid>
                          <Grid item xs={2}>
                            <Tooltip
                              title={
                                <span style={{ whiteSpace: "pre-line" }}>
                                  {indicateurs["HVE4_FERTI_D_43"].messageAide}
                                </span>
                              }
                            >
                              <IconButton>
                                <FontAwesomeIcon icon="info-circle" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align={"center"}>
                        <DecimalPositif
                          error={
                            indicateurs["HVE4_FERTI_D_43"].valeur.value > indicateurs["HVE4_FERTI_D_48"].valeur.value
                          }
                          helperText={
                            indicateurs["HVE4_FERTI_D_43"].valeur.value > indicateurs["HVE4_FERTI_D_48"].valeur.value
                              ? "La valeur saisie ne doit pas dépasser le total des surfaces de blé"
                              : ""
                          }
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_D_43"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_D_43"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_D_43"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_D_43"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell align={"center"}>
                        <Percent
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_I_43"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_I_43"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_I_43"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_I_43"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell align={"center"}>
                        <Percent
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_D_49"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_D_49"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_D_49"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_D_49"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell align={"center"}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_S_430"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_S_430"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_S_430"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_S_430"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <div className={"bold"}>Viticulture - Arboriculture - Maraîchage</div>
                      </TableCell>
                      <TableCell colSpan={4} />
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <div>Total surface en cultures principales vignes - arbo - maraîchage</div>
                      </TableCell>
                      <TableCell align={"center"}>
                        <Assolement
                          size={"small"}
                          displayRules={{ displayAssolement: "column" }}
                          getValueAssolement={getValueAssolement}
                          indicateur={indicateurs["HVE4_FERTI_D_46"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_D_46"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_D_46"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_D_46"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell colSpan={3} />
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Grid container alignItems={"center"}>
                          <Grid item xs={10}>
                            <div>Utilisation d'un outil Plan Prévisionnel de Fumure</div>
                          </Grid>
                          <Grid item xs={2}>
                            <Tooltip
                              title={
                                <span style={{ whiteSpace: "pre-line" }}>
                                  {indicateurs["HVE4_FERTI_D_44"].messageAide}
                                </span>
                              }
                            >
                              <IconButton>
                                <FontAwesomeIcon icon="info-circle" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align={"center"}>
                        <DecimalPositif
                          error={
                            indicateurs["HVE4_FERTI_D_44"].valeur.value > indicateurs["HVE4_FERTI_D_46"].valeur.value
                          }
                          helperText={
                            indicateurs["HVE4_FERTI_D_44"].valeur.value > indicateurs["HVE4_FERTI_D_46"].valeur.value
                              ? "La valeur saisie ne doit pas dépasser le total des surfaces en cultures principales vignes_arbo_maraichage"
                              : ""
                          }
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_D_44"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_D_44"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_D_44"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_D_44"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell align={"center"}>
                        <Percent
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_I_44"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_I_44"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_I_44"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_I_44"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell align={"center"}>
                        <Percent
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_I_44"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_I_44"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_I_44"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_I_44"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell align={"center"}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_S_44"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_S_44"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_S_44"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_S_44"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Grid container alignItems={"center"}>
                          <Grid item xs={10}>
                            <div>Utilisation d'un outil PPF Aj (ajustement du PPF)</div>
                          </Grid>
                          <Grid item xs={2}>
                            <Tooltip
                              title={
                                <span style={{ whiteSpace: "pre-line" }}>
                                  {indicateurs["HVE4_FERTI_D_45_1"].messageAide}
                                </span>
                              }
                              interactive
                            >
                              <IconButton>
                                <FontAwesomeIcon icon="info-circle" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align={"center"}>
                        <DecimalPositif
                          error={
                            indicateurs["HVE4_FERTI_D_45_1"].valeur.value > indicateurs["HVE4_FERTI_D_46"].valeur.value
                          }
                          helperText={
                            indicateurs["HVE4_FERTI_D_45_1"].valeur.value > indicateurs["HVE4_FERTI_D_46"].valeur.value
                              ? "La valeur saisie ne doit pas dépasser le total des surfaces en cultures principales vignes_arbo_maraichage"
                              : ""
                          }
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_D_45_1"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_D_45_1"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_D_45_1"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_D_45_1"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell rowSpan={2} align={"center"}>
                        <Percent
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_I_45"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_I_45"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_I_45"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_I_45"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell rowSpan={2} align={"center"}>
                        <Percent
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_I_45"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_I_45"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_I_45"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_I_45"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell rowSpan={2} align={"center"}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_S_45"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_S_45"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_S_45"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_S_45"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Grid container alignItems={"center"}>
                          <Grid item xs={10}>
                            <div>Utilisation d'un outil ODP (Outil De Pilotage)</div>
                          </Grid>
                          <Grid item xs={2}>
                            <Tooltip
                              title={
                                <span style={{ whiteSpace: "pre-line" }}>
                                  {indicateurs["HVE4_FERTI_D_45_2"].messageAide}
                                </span>
                              }
                              interactive
                            >
                              <IconButton>
                                <FontAwesomeIcon icon="info-circle" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell align={"center"}>
                        <DecimalPositif
                          error={
                            indicateurs["HVE4_FERTI_D_45_2"].valeur.value > indicateurs["HVE4_FERTI_D_46"].valeur.value
                          }
                          helperText={
                            indicateurs["HVE4_FERTI_D_45_2"].valeur.value > indicateurs["HVE4_FERTI_D_46"].valeur.value
                              ? "La valeur saisie ne doit pas dépasser le total des surfaces en cultures principales vignes_arbo_maraichage"
                              : ""
                          }
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_D_45_2"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_D_45_2"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_D_45_2"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_D_45_2"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow className={"total-point"}>
                      <TableCell colSpan={5} align={"right"}>
                        <div className={"bold"}>Points Cultures Principales</div>
                      </TableCell>
                      <TableCell align={"center"}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_S_400"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_S_400"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_S_400"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_S_400"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={11} className={"mt-3"}>
              <TableContainer component={Paper}>
                <Table style={{ tableLayout: "fixed" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <div className={"bold"}>CULTURES MINEURES</div>
                      </TableCell>
                      <TableCell colSpan={4} />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <div className={"bold"}>Grandes cultures</div>
                      </TableCell>
                      <TableCell align={"center"}>Surfaces concernées (ha)</TableCell>
                      <TableCell align={"center"}>Part surface concernée</TableCell>
                      <TableCell />
                      <TableCell align={"center"}>Points</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <div>Utilisation d'un outil OAD Aj (Ajustement du PPF) ou ODP (Outil De Pilotage)</div>
                      </TableCell>
                      <TableCell align={"center"}>
                        <DecimalPositif
                          error={
                            indicateurs["HVE4_FERTI_D_46_1"].valeur.value > indicateurs["HVE4_FERTI_D_105"].valeur.value
                          }
                          helperText={
                            indicateurs["HVE4_FERTI_D_46_1"].valeur.value > indicateurs["HVE4_FERTI_D_105"].valeur.value
                              ? "La valeur saisie ne doit pas dépasser la surface des cultures mineures"
                              : ""
                          }
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_D_46_1"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_D_46_1"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_D_46_1"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_D_46_1"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell align={"center"}>
                        <Percent
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_I_46"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_I_46"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_I_46"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_I_46"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell />
                      <TableCell align={"center"}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_S_46"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_S_46"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_S_46"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_S_46"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <div>Utilisation d'un outil PPF (Plan prévisionnel de fertilisation)</div>
                      </TableCell>
                      <TableCell align={"center"}>
                        <DecimalPositif
                          error={
                            indicateurs["HVE4_FERTI_D_47"].valeur.value > indicateurs["HVE4_FERTI_D_105"].valeur.value
                          }
                          helperText={
                            indicateurs["HVE4_FERTI_D_47"].valeur.value > indicateurs["HVE4_FERTI_D_105"].valeur.value
                              ? "La valeur saisie ne doit pas dépasser la surface des cultures mineures"
                              : ""
                          }
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_D_47"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_D_47"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_D_47"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_D_47"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell align={"center"}>
                        <Percent
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_I_47"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_I_47"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_I_47"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_I_47"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell />
                      <TableCell align={"center"}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_S_47"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_S_47"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_S_47"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_S_47"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow className={"total-point"}>
                      <TableCell colSpan={5} align={"right"}>
                        <div className={"bold"}>Points Cultures Mineures</div>
                      </TableCell>
                      <TableCell align={"center"}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_S_401"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_S_401"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_S_401"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_S_401"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid container item xs={11} justify={"flex-end"} className={"mt-3"}>
              <Grid item xs={4}>
                <TableContainer component={Paper}>
                  <Table style={{ tableLayout: "fixed" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Grid container alignItems={"center"}>
                            Rappel :
                            <Tooltip
                              title={
                                <span style={{ whiteSpace: "pre-line" }}>
                                  {indicateurs["HVE4_FERTI_D_1050"].messageAide}
                                </span>
                              }
                            >
                              <IconButton>
                                <FontAwesomeIcon icon="info-circle" />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                          <div>Part des cultures mineures de la SAU</div>
                        </TableCell>
                        <TableCell>
                          <div className={"bold"}>Nombre de points "Utilisation d'OAD"</div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align={"center"}>
                          <DecimalPositif
                            size={"small"}
                            indicateur={indicateurs["HVE4_FERTI_D_1050"]}
                            key={"indicateur-" + indicateurs["HVE4_FERTI_D_1050"].idIndicateur}
                            changeIndicateur={this.handleChangeIndicateur}
                            valueInputProps={
                              indicateurs["HVE4_FERTI_D_1050"].libelleUnite != null && {
                                endAdornment: (
                                  <InputAdornment position={"end"}>
                                    {indicateurs["HVE4_FERTI_D_1050"].libelleUnite}
                                  </InputAdornment>
                                ),
                              }
                            }
                            computedFields={computedFields}
                          />
                        </TableCell>
                        <TableCell align={"center"}>
                          <div className={"total-point"}>
                            <DecimalPositif
                              size={"small"}
                              indicateur={indicateurs["HVE4_FERTI_S_402"]}
                              key={"indicateur-" + indicateurs["HVE4_FERTI_S_402"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                              valueInputProps={
                                indicateurs["HVE4_FERTI_S_402"].libelleUnite != null && {
                                  endAdornment: (
                                    <InputAdornment position={"end"}>
                                      {indicateurs["HVE4_FERTI_S_402"].libelleUnite}
                                    </InputAdornment>
                                  ),
                                }
                              }
                              computedFields={computedFields}
                            />
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      );
  }
}
export default compose(withTranslation(), connect(mapStateToProps))(FertiOAD);
