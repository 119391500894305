import { HIDDEN_LIBELLE_ENGLOBANTS } from "../LegacyV1/HVE3A/HVE3AStatic";
import { COMPUTED_FIELDS_IRRIGATION } from "./ComputedFields/HVE4_Irrigation";
import { COMPUTED_FIELDS_PHYTO } from "./ComputedFields/HVE4_Phyto";
import { COMPUTED_FIELDS_BIODIV } from "./ComputedFields/HVE4_Biodiversite";
import { COMPUTED_FIELDS_FERTILISATION } from "./ComputedFields/HVE4_Fertilisation";
import { COMPUTED_FIELDS_SYNTHESE } from "./ComputedFields/HVE4_Recap";

export const CODE_NATIONAL = "HVE4";

export const COMPUTE_REDUCED_ASSOLEMENT = (assolement) => {
  const reducedAssolement = {
    BLEDUR: 0,
    BLETEND: 0,
    MAIS: 0,
    ORGE: 0,
    TRITI: 0,
    RIZ: 0,
    CEREAL: 0,
    COLZA: 0,
    TOURN: 0,
    SOJA: 0,
    OLEAG: 0,
    POIS: 0,
    PROTEAG: 0,
    LEGSEC: 0,
    MELANG: 0,
    CHANVR: 0,
    JACH_5: 0,
    JACH_6: 0,
    JACH_NOIR: 0,
    GEL_JACH_5: 0,
    GEL_JACH_6: 0,
    GEL_JACH_NOIR: 0,
    SURGEL: 0,
    DESHYDR: 0,
    MAISFOUR: 0,
    FOURR: 0,
    PTLEGPURE: 0,
    PTMLGLEG: 0,
    PTGRMNPUR: 0,
    PTMLGGRMN: 0,
    PTMLGCPLX: 0,
    PPNFERTI: 0,
    PPFERTINT: 0,
    PPAUTRES: 0,
    BETTERAV: 0,
    INDUS: 0,
    CULTPDT: 0,
    CULTPLEINCHAMP: 0,
    CULTTUNNEL: 0,
    CULTSERRE: 0,
    CULTHORSOL: 0,
    HORTPEP: 0,
    VIGNES: 0,
    VERGER: 0,
    CULTPERMA: 0,
    culturesMineures: 0,
    culturesMajeures: 0,
    culturePrincipale: {
      libelle: "",
      surface: 0,
    },
    secondeCulturePrincipale: {
      libelle: "",
      surface: 0,
    },
    surfacesInferieures6ha: 0,
  };

  const especesVerger = {};
  assolement.forEach((a) => {
    switch (a.codeTypeCulture) {
      case "SOJA":
        reducedAssolement[a.codeTypeCulture] += a.surface;
        break;
      default:
        a.regroupementCulture !== "soja"
          ? (reducedAssolement[a.codeTypeCulture] += a.surface)
          : (reducedAssolement["SOJA"] += a.surface);
        break;
    }
    switch (a.estMajeure) {
      case false:
        reducedAssolement.culturesMineures += a.surface;
        break;
      case true:
        reducedAssolement.culturesMajeures += a.surface;
        break;
      default:
        break;
    }
    if (a.codeTypeCulture === "VERGER") {
      especesVerger[a.regroupementCulture] = (especesVerger[a.regroupementCulture] ?? 0) + a.surface;
    }
    if (a.surface < 6) {
      reducedAssolement.surfacesInferieures6ha += a.surface;
    }
  });
  // détermination cultures principales
  // exclure PP non fertilisées","PP fertilisées mais non traitées","Autres PP"
  // pour VERGER => on descend au niveau de l'espèce
  const filterProperties = [
    "PPNFERTI",
    "PPFERTINT",
    "PPAUTRES",
    "VERGER",
    "culturesMineures",
    "culturesMajeures",
    "culturePrincipale",
    "secondeCulturePrincipale",
    "surfacesInferieures6ha",
  ];

  const [culturePrincipale, secondeCulturePrincipale] = [
    ...Object.entries(reducedAssolement)
      .filter(([code]) => !filterProperties.includes(code))
      .map(([code, surface]) => ({
        code,
        surface,
        isEspece: false,
      })),
    ...Object.entries(especesVerger).map(([code, surface]) => ({
      code,
      surface,
      isEspece: true,
    })),
  ].sort((a, b) => b.surface - a.surface);

  reducedAssolement.culturePrincipale = culturePrincipale;
  reducedAssolement.secondeCulturePrincipale = secondeCulturePrincipale;

  return reducedAssolement;
};

export const typesCulturesToCompare = [
  "BLEDUR",
  "BLETEND",
  "MAIS",
  "ORGE",
  "CEREAL",
  "COLZA",
  "TOURN",
  "SOJA",
  "OLEAG",
  "POIS",
  "PROTEAG",
  "LEGSEC",
  "MELANG",
  "CHANVR",
  "BETTERAV",
  "INDUS",
  "CULTPDT",
];

export const COMPUTED_FIELDS = {
  HVE4_CULT_D_200: {
    columns: [
      "HVE4_CULT_D_8",
      "HVE4_CULT_D_13",
      "HVE4_CULT_D_18",
      "HVE4_CULT_D_21",
      "HVE4_CULT_D_25",
      "HVE4_CULT_D_34",
      "HVE4_CULT_D_37",
      "HVE4_CULT_D_43",
      "HVE4_CULT_D_47",
    ],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE4_CULT_D_200.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE4_CULT_D_201: {
    columns: [
      "HVE4_CULT_D_71",
      "HVE4_CULT_D_72",
      "HVE4_CULT_D_73",
      "HVE4_CULT_D_74",
      "HVE4_CULT_D_75",
      "HVE4_CULT_D_76",
      "HVE4_CULT_D_77",
      "HVE4_CULT_D_80",
      "HVE4_CULT_D_81",
      "HVE4_CULT_D_82",
      "HVE4_CULT_D_83",
      "HVE4_CULT_D_84",
      "HVE4_CULT_D_85",
      "HVE4_CULT_D_86",
      "HVE4_CULT_D_89",
      "HVE4_CULT_D_90",
      "HVE4_CULT_D_91",
      "HVE4_CULT_D_92",
    ],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE4_CULT_D_201.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE4_CULT_D_202: {
    columns: ["HVE4_CULT_D_93", "HVE4_CULT_D_94", "HVE4_CULT_D_95"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE4_CULT_D_202.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE4_CULT_D_205: {
    columns: ["HVE4_CULT_D_200", "HVE4_CULT_D_201", "HVE4_CULT_D_202", "HVE4_CULT_D_203", "HVE4_CULT_D_206"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE4_CULT_D_205.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE4_CULT_D_8: {
    columns: [
      "HVE4_CULT_D_1",
      "HVE4_CULT_D_2",
      "HVE4_CULT_D_3",
      "HVE4_CULT_D_4",
      "HVE4_CULT_D_5",
      "HVE4_CULT_D_6",
      "HVE4_CULT_D_7",
    ],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE4_CULT_D_8.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE4_CULT_D_13: {
    columns: ["HVE4_CULT_D_9", "HVE4_CULT_D_10", "HVE4_CULT_D_11", "HVE4_CULT_D_12"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE4_CULT_D_13.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE4_CULT_D_18: {
    columns: ["HVE4_CULT_D_14", "HVE4_CULT_D_15", "HVE4_CULT_D_16", "HVE4_CULT_D_17"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE4_CULT_D_18.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE4_CULT_D_21: {
    columns: ["HVE4_CULT_D_19", "HVE4_CULT_D_20", "HVE4_CULT_D_20_1", "HVE4_CULT_D_20_2"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE4_CULT_D_21.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE4_CULT_D_25: {
    columns: ["HVE4_CULT_D_22", "HVE4_CULT_D_23", "HVE4_CULT_D_24"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE4_CULT_D_25.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE4_CULT_D_34: {
    columns: [
      "HVE4_CULT_D_26",
      "HVE4_CULT_D_27",
      "HVE4_CULT_D_28",
      "HVE4_CULT_D_29",
      "HVE4_CULT_D_30",
      "HVE4_CULT_D_31",
      "HVE4_CULT_D_32",
      "HVE4_CULT_D_33",
    ],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE4_CULT_D_34.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE4_CULT_D_37: {
    columns: ["HVE4_CULT_D_35", "HVE4_CULT_D_36"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE4_CULT_D_37.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE4_CULT_D_43: {
    columns: ["HVE4_CULT_D_38", "HVE4_CULT_D_39", "HVE4_CULT_D_40", "HVE4_CULT_D_41", "HVE4_CULT_D_42"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE4_CULT_D_43.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE4_CULT_D_45: {
    columns: [],
    computedValue: (state) => ({
      valeur: {
        value: state.reducedAssolement.VERGER,
      },
    }),
  },
  HVE4_CULT_D_47: {
    columns: ["HVE4_CULT_D_44", "HVE4_CULT_D_45", "HVE4_CULT_D_46"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE4_CULT_D_47.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE4_CULT_D_69: {
    columns: [
      "HVE4_CULT_D_48",
      "HVE4_CULT_D_49",
      "HVE4_CULT_D_50",
      "HVE4_CULT_D_51",
      "HVE4_CULT_D_52",
      "HVE4_CULT_D_54",
      "HVE4_CULT_D_56",
      "HVE4_CULT_D_58",
      "HVE4_CULT_D_59",
      "HVE4_CULT_D_60",
      "HVE4_CULT_D_61",
      "HVE4_CULT_D_62",
    ],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE4_CULT_D_69.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE4_CULT_D_70: {
    columns: [
      "HVE4_CULT_D_48",
      "HVE4_CULT_D_49",
      "HVE4_CULT_D_50",
      "HVE4_CULT_D_51",
      "HVE4_CULT_D_52",
      "HVE4_CULT_D_53",
      "HVE4_CULT_D_54",
      "HVE4_CULT_D_55",
      "HVE4_CULT_D_56",
      "HVE4_CULT_D_57",
      "HVE4_CULT_D_58",
      "HVE4_CULT_D_59",
      "HVE4_CULT_D_60",
      "HVE4_CULT_D_61",
      "HVE4_CULT_D_62",
      "HVE4_CULT_D_63",
      "HVE4_CULT_D_64",
      "HVE4_CULT_D_65",
      "HVE4_CULT_D_66",
      "HVE4_CULT_D_67",
      "HVE4_CULT_D_68",
    ],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE4_CULT_D_70.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE4_BIODIV_I_39: {
    columns: ["HVE4_BIODIV_D_37", "HVE4_BIODIV_D_38", "HVE4_CULT_D_200"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, "HVE4_CULT_D_200") !== 0
            ? (100 * (getValueOrZero(state, "HVE4_BIODIV_D_37") + getValueOrZero(state, "HVE4_BIODIV_D_38"))) /
              getValueOrZero(state, "HVE4_CULT_D_200")
            : 0,
      },
    }),
  },
  HVE4_BIODIV_S_39: {
    columns: ["HVE4_BIODIV_I_39"],
    disabled: true,
    computedValue: (state) => {
      let valueToPut = 0;
      let scoreIndicateur = getValueOrZero(state, "HVE4_BIODIV_I_39");
      switch (true) {
        case 0 <= scoreIndicateur && scoreIndicateur < 40:
          valueToPut = 0;
          break;
        case 40 <= scoreIndicateur && scoreIndicateur < 50:
          valueToPut = 1;
          break;
        case 50 <= scoreIndicateur && scoreIndicateur < 60:
          valueToPut = 2;
          break;
        case 60 <= scoreIndicateur && scoreIndicateur < 70:
          valueToPut = 3;
          break;
        case 70 <= scoreIndicateur && scoreIndicateur < 80:
          valueToPut = 4;
          break;
        case 80 <= scoreIndicateur:
          valueToPut = 5;
          break;
        default:
          break;
      }
      return {
        valeur: {
          value: valueToPut,
        },
      };
    },
  },
  HVE4_BIODIV_D_530: {
    columns: ["HVE4_BIODIV_D_50"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: getValueOrZero(state, "HVE4_BIODIV_D_50") > 0 ? 1 : 0,
      },
    }),
  },
  HVE4_BIODIV_D_510: {
    columns: ["HVE4_BIODIV_D_51"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: getValueOrZero(state, "HVE4_BIODIV_D_51") > 0 ? 1 : 0,
      },
    }),
  },

  ...COMPUTED_FIELDS_IRRIGATION,
  ...COMPUTED_FIELDS_PHYTO,
  ...COMPUTED_FIELDS_BIODIV,
  ...COMPUTED_FIELDS_FERTILISATION,
  ...COMPUTED_FIELDS_SYNTHESE,
};

const getValueOrZero = (state, codeNational) => {
  return state.indicateurs[codeNational] && !isNaN(Number(state.indicateurs[codeNational].valeur.value))
    ? Number(state.indicateurs[codeNational].valeur.value)
    : 0;
};

const getPrincipaleCulture = (state) => {
  const culturePrincipale = state.reducedAssolement.culturePrincipale,
    secondeCulturePrincipale = state.reducedAssolement.secondeCulturePrincipale,
    SAU = getValueOrZero(state, "HVE4_CULT_D_200"),
    culture =
      SAU === 0
        ? null
        : ["PTLEGPURE", "PTMLGLEG", "PTGRMNPUR", "PTMLGGRMN", "PTMLGCPLX"].includes(culturePrincipale.code) &&
          culturePrincipale.surface / SAU >= 0.1 &&
          secondeCulturePrincipale.surface / SAU >= 0.1
        ? secondeCulturePrincipale
        : culturePrincipale,
    value =
      culture === null
        ? ""
        : culture.isEspece
        ? culture.code
        : state.typesCultures.find((tc) => tc.code === culture.code).libelle;
  return {
    value: culturePrincipale.surface === 0 ? "" : value,
    surface: culture === null ? 0 : culture.surface,
  };
};

export const HVE4_ASSOLEMENT = {
  HVE4_CULT_D_1: (state) => state.reducedAssolement.BLETEND,
  HVE4_CULT_D_2: (state) => state.reducedAssolement.BLEDUR,
  HVE4_CULT_D_3: (state) => state.reducedAssolement.MAIS,
  HVE4_CULT_D_4: (state) => state.reducedAssolement.ORGE,
  HVE4_CULT_D_5: (state) => state.reducedAssolement.TRITI,
  HVE4_CULT_D_6: (state) => state.reducedAssolement.RIZ,
  HVE4_CULT_D_7: (state) => state.reducedAssolement.CEREAL,
  HVE4_CULT_D_8: (state) =>
    HVE4_ASSOLEMENT.HVE4_CULT_D_1(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_2(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_3(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_4(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_5(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_6(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_7(state),
  HVE4_CULT_D_9: (state) => state.reducedAssolement.COLZA,
  HVE4_CULT_D_10: (state) => state.reducedAssolement.TOURN,
  HVE4_CULT_D_11: (state) => state.reducedAssolement.SOJA,
  HVE4_CULT_D_12: (state) => state.reducedAssolement.OLEAG,
  HVE4_CULT_D_13: (state) =>
    HVE4_ASSOLEMENT.HVE4_CULT_D_9(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_10(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_11(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_12(state),
  HVE4_CULT_D_14: (state) => state.reducedAssolement.POIS,
  HVE4_CULT_D_15: (state) => state.reducedAssolement.PROTEAG,
  HVE4_FERTI_D_60: (state) => state.reducedAssolement.POIS,
  HVE4_FERTI_D_61: (state) => state.reducedAssolement.LEGSEC,
  HVE4_FERTI_D_62: (state) => state.reducedAssolement.MELANG,
  HVE4_FERTI_D_63: (state) =>
    state.reducedAssolement.PTLEGPURE + state.reducedAssolement.PTMLGLEG + state.reducedAssolement.PTMLGCPLX,
  HVE4_FERTI_D_64: (state) => state.reducedAssolement.SOJA,
  HVE4_CULT_D_16: (state) => state.reducedAssolement.LEGSEC,
  HVE4_CULT_D_17: (state) => state.reducedAssolement.MELANG,
  HVE4_CULT_D_18: (state) =>
    HVE4_ASSOLEMENT.HVE4_CULT_D_14(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_15(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_16(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_17(state),
  HVE4_CULT_D_19: (state) => state.reducedAssolement.CHANVR,
  HVE4_CULT_D_20: (state) => state.reducedAssolement.JACH_5 + state.reducedAssolement.GEL_JACH_5,
  HVE4_CULT_D_20_1: (state) => state.reducedAssolement.JACH_6 + state.reducedAssolement.GEL_JACH_6,
  HVE4_CULT_D_20_2: (state) => state.reducedAssolement.JACH_NOIR + state.reducedAssolement.GEL_JACH_NOIR,
  HVE4_CULT_D_21: (state) =>
    HVE4_ASSOLEMENT.HVE4_CULT_D_19(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_20(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_20_1(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_20_2(state),
  HVE4_CULT_D_22: (state) => state.reducedAssolement.DESHYDR,
  HVE4_CULT_D_23: (state) => state.reducedAssolement.MAISFOUR,
  HVE4_CULT_D_24: (state) => state.reducedAssolement.FOURR,
  HVE4_CULT_D_25: (state) =>
    HVE4_ASSOLEMENT.HVE4_CULT_D_22(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_23(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_24(state),
  HVE4_CULT_D_26: (state) => state.reducedAssolement.PTLEGPURE,
  HVE4_CULT_D_27: (state) => state.reducedAssolement.PTMLGLEG,
  HVE4_CULT_D_28: (state) => state.reducedAssolement.PTGRMNPUR,
  HVE4_CULT_D_29: (state) => state.reducedAssolement.PTMLGGRMN,
  HVE4_CULT_D_30: (state) => state.reducedAssolement.PTMLGCPLX,
  HVE4_CULT_D_31: (state) => state.reducedAssolement.PPNFERTI,
  HVE4_CULT_D_32: (state) => state.reducedAssolement.PPFERTINT,
  HVE4_CULT_D_33: (state) => state.reducedAssolement.PPAUTRES,
  HVE4_CULT_D_34: (state) =>
    HVE4_ASSOLEMENT.HVE4_CULT_D_26(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_27(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_28(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_29(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_30(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_31(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_32(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_33(state),
  HVE4_CULT_D_35: (state) => state.reducedAssolement.BETTERAV,
  HVE4_CULT_D_36: (state) => state.reducedAssolement.INDUS,
  HVE4_CULT_D_37: (state) => HVE4_ASSOLEMENT.HVE4_CULT_D_35(state) + HVE4_ASSOLEMENT.HVE4_CULT_D_36(state),
  HVE4_CULT_D_38: (state) => state.reducedAssolement.CULTPDT,
  HVE4_CULT_D_39: (state) => state.reducedAssolement.CULTPLEINCHAMP,
  HVE4_CULT_D_40: (state) => state.reducedAssolement.CULTTUNNEL,
  HVE4_CULT_D_41: (state) => state.reducedAssolement.CULTSERRE,
  HVE4_CULT_D_42: (state) => state.reducedAssolement.CULTHORSOL,
  HVE4_CULT_D_43: (state) =>
    HVE4_ASSOLEMENT.HVE4_CULT_D_38(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_39(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_40(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_41(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_42(state),
  HVE4_CULT_D_44: (state) => state.reducedAssolement.VIGNES,
  HVE4_CULT_D_45: (state) => state.reducedAssolement.VERGER,
  HVE4_CULT_D_46: (state) => state.reducedAssolement.CULTPERMA,
  HVE4_CULT_D_47: (state) =>
    HVE4_ASSOLEMENT.HVE4_CULT_D_44(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_45(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_46(state),
  HVE4_PHYTO_D_21: (state) => HVE4_ASSOLEMENT.HVE4_CULT_D_31(state) + HVE4_ASSOLEMENT.HVE4_CULT_D_32(state),
  //Partie Biodiv
  HVE4_BIODIV_D_38: (state) =>
    HVE4_ASSOLEMENT.HVE4_CULT_D_31(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_32(state) +
    HVE4_ASSOLEMENT.HVE4_CULT_D_33(state),
  HVE4_BIODIV_D_40: (state) => getPrincipaleCulture(state).value,
  HVE4_BIODIV_D_41: (state) => getPrincipaleCulture(state).surface,
  HVE4_BIODIV_D_42: (state) =>
    Math.max(
      0,
      getValueOrZero(state, "HVE4_CULT_D_200") -
        getValueOrZero(state, "HVE4_CULT_D_31") -
        getValueOrZero(state, "HVE4_CULT_D_32") -
        getValueOrZero(state, "HVE4_CULT_D_33")
    ) ?? 0,
  HVE4_BIODIV_D_380: (state) =>
    getValueOrZero(state, "HVE4_CULT_D_200") !== 0
      ? 100 * (getValueOrZero(state, "HVE4_BIODIV_D_38") / getValueOrZero(state, "HVE4_CULT_D_200"))
      : 0,
  HVE4_BIODIV_D_44: (state) =>
    Object.keys(
      state.assolement
        .filter((a) => ["DESHYDR", "MAISFOUR", "FOURR"].includes(a.codeTypeCulture))
        .reduce((acc, a) => {
          if (!acc[a.regroupementCulture]) {
            acc[a.regroupementCulture] = 1;
          } else {
            acc[a.regroupementCulture]++;
          }
          return acc;
        }, {})
    ).length,
  HVE4_BIODIV_D_45: (state) =>
    Object.keys(
      state.assolement
        .filter((a) => typesCulturesToCompare.includes(a.codeTypeCulture))
        .reduce((acc, a) => {
          if (!acc[a.regroupementCulture]) {
            acc[a.regroupementCulture] = 1;
          } else {
            acc[a.regroupementCulture]++;
          }
          return acc;
        }, {})
    ).length,
  HVE4_BIODIV_D_47: (state) => getValueOrZero(state, "HVE4_CULT_D_27"),
  HVE4_BIODIV_D_48: (state) => getValueOrZero(state, "HVE4_CULT_D_29"),
  HVE4_BIODIV_D_49: (state) => getValueOrZero(state, "HVE4_CULT_D_30"),
  HVE4_BIODIV_D_50: (state) =>
    state.assolement
      .filter((assolement) => assolement.codeTypeCulture === "VIGNES")
      .reduce((sum, assolement) => sum + assolement.surface, 0),
  HVE4_BIODIV_D_51: (state) =>
    state.assolement
      .filter((assolement) => assolement.codeCategorieCulture === "FLOR" && assolement.codeTypeCulture !== "CULTHORSOL")
      .reduce((sum, assolement) => sum + assolement.surface, 0),
  HVE4_BIODIV_D_53: (state) =>
    state.assolement
      .filter(
        (assolement) =>
          (assolement.codeCategorieCulture === "ARBO" || assolement.codeCategorieCulture === "CULTPERMA") &&
          assolement.codeTypeCulture !== "VIGNES"
      )
      .reduce((sum, assolement) => sum + assolement.surface, 0),
  HVE4_BIODIV_D_54: (state) =>
    state.assolement
      .filter((assolement) => assolement.codeCategorieCulture === "FLOR" && assolement.codeTypeCulture === "CULTHORSOL")
      .reduce((sum, assolement) => sum + assolement.surface, 0),
  //Partie ferti
  HVE4_FERTI_D_105: (state) => state.reducedAssolement.culturesMineures,
  HVE4_FERTI_D_50: (state) => state.reducedAssolement.PPNFERTI,
  HVE4_FERTI_D_400: (state) =>
    state.assolement
      .filter(
        (assolement) =>
          assolement.codeCategorieCulture !== "ARBO" &&
          assolement.codeCategorieCulture !== "CULTPERMA" &&
          assolement.codeCategorieCulture !== "FLOR"
      )
      .reduce((sum, assolement) => sum + assolement.surface, 0),
  HVE4_FERTI_D_46: (state) =>
    state.assolement
      .filter(
        (assolement) =>
          assolement.codeCategorieCulture === "ARBO" ||
          assolement.codeCategorieCulture === "CULTPERMA" ||
          assolement.codeCategorieCulture === "FLOR"
      )
      .reduce((sum, assolement) => sum + assolement.surface, 0),
};

const exports = {
  computeReducedAssolement: COMPUTE_REDUCED_ASSOLEMENT,
  codeNational: CODE_NATIONAL,
  computedFields: COMPUTED_FIELDS,
  hiddenLibelleEnglobants: HIDDEN_LIBELLE_ENGLOBANTS,
  assolement: HVE4_ASSOLEMENT,
};
export default exports;
