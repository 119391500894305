import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Grid, InputAdornment, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
import clsx from "clsx";
import styles from "./sousrubriquestyles.module.scss";
import ScoreRubrique from "../indicateurs/ScoreRubrique";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class BiodivSynthese extends React.Component {
  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    if (newIndicateur.codeNational === "HVE4_BIODIV_S_70") {
      changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
    }
  };
  render() {
    const { indicateurs, computedFields } = this.props;
    return (
      <Grid container>
        <Grid item xs={7}>
          <Table style={{ tableLayout: "fixed" }} className={"mt-3 mb-3"}>
            <TableBody>
              <TableRow>
                <TableCell className={clsx(styles.tab_cell_border_top, styles.bold_content)}>
                  Infrastructures Agro-écologiques
                </TableCell>
                <TableCell className={clsx(styles.tab_cell_border_top)} align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_S_38"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_S_38"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_S_38"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_S_38"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={clsx(styles.tab_cell_border_top, styles.bold_content)}>
                  Taille des parcelles
                </TableCell>
                <TableCell className={clsx(styles.tab_cell_border_top)} align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_S_39"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_S_39"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_BIODIV_S_39"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_BIODIV_S_39"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={clsx(styles.tab_cell_border_top, styles.bold_content)}>
                  Poids de la culture principale
                </TableCell>
                <TableCell className={clsx(styles.tab_cell_border_top)} align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_S_43"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_S_43"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    computedFields={computedFields}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={clsx(styles.tab_cell_border_top, styles.bold_content)}>
                  Nombre d'espèces végétales cultivées
                </TableCell>
                <TableCell className={clsx(styles.tab_cell_border_top)} align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_S_54"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_S_54"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    computedFields={computedFields}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={clsx(styles.tab_cell_border_top, styles.bold_content)}>
                  Nombre d'espèces animales élevées
                </TableCell>
                <TableCell className={clsx(styles.tab_cell_border_top)} align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_S_61"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_S_61"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    computedFields={computedFields}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={clsx(styles.tab_cell_border_top, styles.bold_content)}>
                  Présences de ruches
                </TableCell>
                <TableCell className={clsx(styles.tab_cell_border_top)} align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_S_62"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_S_62"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    computedFields={computedFields}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={clsx(styles.tab_cell_border_top, styles.bold_content)}>
                  Nombre de variétés, races ou espèces menacées
                </TableCell>
                <TableCell className={clsx(styles.tab_cell_border_top)} align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_S_600"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_S_600"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    computedFields={computedFields}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={clsx(styles.tab_cell_border_top, styles.bold_content)}>
                  Qualités biologique des sols
                </TableCell>
                <TableCell className={clsx(styles.tab_cell_border_top)} align="center">
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_BIODIV_S_676"]}
                    key={"indicateur-" + indicateurs["HVE4_BIODIV_S_676"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    computedFields={computedFields}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>

          <ScoreRubrique
            size={"small"}
            indicateur={indicateurs["HVE4_BIODIV_S_70"]}
            key={"indicateur-" + indicateurs["HVE4_BIODIV_S_70"].idIndicateur}
            changeIndicateur={this.handleChangeIndicateur}
            computedFields={computedFields}
          />
        </Grid>
      </Grid>
    );
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(BiodivSynthese);
