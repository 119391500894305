import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import clsx from "clsx";
import styles from "../SousRubriques/sousrubriquestyles.module.scss";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

class RecapScoreTab extends React.Component {
  render() {
    const { sousRubriquesScores, sousRubriquesScoresMax, titre, rubriqueScore, rubriqueScoreMax } = this.props;
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell className={clsx(styles.tab_cell_no_borders, styles.bold_content)} colSpan={3}>
              Tableau récapitulatif de la rubrique "{titre}"
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell className={clsx(styles.tab_cell_no_borders, styles.bold_content)} />
            <TableCell className={clsx(styles.tab_cell_no_borders, styles.bold_content)} align="center">
              Nb de points
            </TableCell>
            <TableCell className={clsx(styles.tab_cell_no_borders, styles.bold_content)} align="center">
              Max de points
            </TableCell>
          </TableRow>
          {sousRubriquesScores.map((score, i) => {
            return (
              <TableRow key={"ligne-" + i}>
                <TableCell key={"cell-libelle-" + i} className={clsx(styles.tab_cell_no_borders)}>
                  {score?.libelle}
                </TableCell>
                <TableCell
                  key={"cell-score-" + i}
                  className={clsx(styles.tab_cell_no_borders, styles.bold_content)}
                  align="center"
                >
                  {score?.value}
                </TableCell>
                <TableCell
                  key={"cell-score-max-" + i}
                  className={clsx(styles.tab_cell_no_borders, styles.bold_content)}
                  align="center"
                >
                  {sousRubriquesScoresMax[i]?.value}
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow className={clsx("tab_cell_no_borders", "bold_content")} style={{ height: "90px" }}>
            <TableCell className={clsx(styles.tab_cell_no_borders, styles.bold_content)}>
              Total de la rubrique {titre}
            </TableCell>
            <TableCell className={clsx(styles.tab_cell_no_borders, styles.bold_content)} align="center">
              {rubriqueScore != null ? rubriqueScore : 0}
            </TableCell>
            <TableCell className={clsx(styles.tab_cell_no_borders, styles.bold_content)} align="center">
              {rubriqueScoreMax != null ? rubriqueScoreMax : 0}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }
}

export default compose(withTranslation())(RecapScoreTab);
