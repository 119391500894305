import React from "react";
import { Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import clsx from "clsx";
import styles from "../SousRubriques/sousrubriquestyles.module.scss";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

class RecapScoreConclusion extends React.Component {
  render() {
    const { rubriquesScores, certifiable, irrigation } = this.props;
    return (
      <React.Fragment>
        <Grid item xs={6}>
          <Table style={{ tableLayout: "fixed" }}>
            <TableHead>
              <TableRow>
                <TableCell className={clsx(styles.tab_cell_no_borders, styles.bold_content)} align="left" colSpan={2}>
                  Notes par indicateur
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rubriquesScores.map((score, i) => {
                return (
                  <TableRow key={"ligne-" + i}>
                    <TableCell key={"cell-libelle-" + i} className={clsx(styles.tab_cell_no_borders)}>
                      {score?.libelle}
                    </TableCell>
                    <TableCell
                      key={"cell-score-" + i}
                      className={clsx(styles.tab_cell_no_borders, styles.bold_content)}
                      align="center"
                    >
                      {score?.libelle === "Irrigation" && !irrigation ? "Sans objet" : score?.score}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={6} className={"d-flex flex-column"}>
          <div className={clsx(styles.audit_certification_title, styles.bold_content)}>Résultat de l'audit</div>
          <div
            className={clsx(
              styles.audit_certification_result,
              certifiable ? styles.background_valid : styles.background_invalid
            )}
            style={{ marginTop: "2em" }}
          >
            <Typography variant={"h1"}>
              <div>{certifiable ? "Certifiable" : "Non Certifiable"}</div>
            </Typography>
          </div>
          <Typography variant={"h5"} style={{ fontStyle: "italic", marginTop: "2em" }} align={"center"}>
            L'exploitation est certifiée HVE si la note obtenue dans chaque module est supérieure ou égale à 10.
          </Typography>
        </Grid>
      </React.Fragment>
    );
  }
}

export default compose(withTranslation())(RecapScoreConclusion);
