import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Grid, InputAdornment } from "@material-ui/core";
import { connect } from "react-redux";
import Boolean from "../indicateurs/Boolean";
import DecimalPositif from "../indicateurs/DecimalPositif";
import TexteMultiligne from "../indicateurs/TexteMultiligne";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class IrrigDemCol extends React.Component {
  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };

  render() {
    const { indicateurs, computedFields, getValueAssolement } = this.props;

    return (
      <React.Fragment>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid item xs={4}>
            <div className={indicateurs["HVE4_IRRI_I_4"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_IRRI_I_4"].libelle}
            </div>
          </Grid>
          <Grid item>
            <Boolean
              getValueAssolement={getValueAssolement}
              indicateur={indicateurs["HVE4_IRRI_I_4"]}
              key={"indicateur-" + indicateurs["HVE4_IRRI_I_4"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_IRRI_I_4"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_I_4"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid item xs={4}>
            <div className={indicateurs["HVE4_IRRI_D_4"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_IRRI_D_4"].libelle}
            </div>
          </Grid>
          <Grid item>
            <TexteMultiligne
              size={"small"}
              getValueAssolement={getValueAssolement}
              indicateur={indicateurs["HVE4_IRRI_D_4"]}
              key={"indicateur-" + indicateurs["HVE4_IRRI_D_4"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_IRRI_D_4"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_D_4"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid item xs={4}>
            <div className={indicateurs["HVE4_IRRI_S_4"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_IRRI_S_4"].libelle}
            </div>
          </Grid>
          <Grid item className={"total-point"}>
            <DecimalPositif
              size={"small"}
              getValueAssolement={getValueAssolement}
              indicateur={indicateurs["HVE4_IRRI_S_4"]}
              key={"indicateur-" + indicateurs["HVE4_IRRI_S_4"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_IRRI_S_4"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_S_4"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(IrrigDemCol);
