import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import {
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { connect } from "react-redux";
import Assolement from "../indicateurs/Assolement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

const indicToWatch = [
  "HVE4_CULT_D_1",
  "HVE4_CULT_D_2",
  "HVE4_CULT_D_3",
  "HVE4_CULT_D_4",
  "HVE4_CULT_D_5",
  "HVE4_CULT_D_6",
  "HVE4_CULT_D_7",
  "HVE4_CULT_D_8",
  "HVE4_CULT_D_9",
  "HVE4_CULT_D_10",
  "HVE4_CULT_D_11",
  "HVE4_CULT_D_12",
  "HVE4_CULT_D_13",
  "HVE4_CULT_D_14",
  "HVE4_CULT_D_15",
  "HVE4_CULT_D_16",
  "HVE4_CULT_D_17",
  "HVE4_CULT_D_18",
  "HVE4_CULT_D_19",
  "HVE4_CULT_D_20",
  "HVE4_CULT_D_20_1",
  "HVE4_CULT_D_20_2",
  "HVE4_CULT_D_21",
  "HVE4_CULT_D_22",
  "HVE4_CULT_D_23",
  "HVE4_CULT_D_24",
  "HVE4_CULT_D_25",
  "HVE4_CULT_D_26",
  "HVE4_CULT_D_27",
  "HVE4_CULT_D_28",
  "HVE4_CULT_D_29",
  "HVE4_CULT_D_30",
  "HVE4_CULT_D_31",
  "HVE4_CULT_D_32",
  "HVE4_CULT_D_33",
  "HVE4_CULT_D_34",
  "HVE4_CULT_D_35",
  "HVE4_CULT_D_36",
  "HVE4_CULT_D_37",
  "HVE4_CULT_D_38",
  "HVE4_CULT_D_39",
  "HVE4_CULT_D_40",
  "HVE4_CULT_D_41",
  "HVE4_CULT_D_42",
  "HVE4_CULT_D_43",
  "HVE4_CULT_D_44",
  "HVE4_CULT_D_45",
  "HVE4_CULT_D_46",
  "HVE4_CULT_D_47",
];

class SurfaceSAU extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    let check = indicToWatch.some((indic) => this.props.indicateurs[indic] !== nextProps.indicateurs[indic]);
    return check;
  }
  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };
  render() {
    const { indicateurs, computedFields, assolement, getValueAssolement } = this.props;
    const cerealesKeys = [
      "HVE4_CULT_D_1",
      "HVE4_CULT_D_2",
      "HVE4_CULT_D_3",
      "HVE4_CULT_D_4",
      "HVE4_CULT_D_5",
      "HVE4_CULT_D_6",
      "HVE4_CULT_D_7",
      "HVE4_CULT_D_8",
    ];
    const oleagineuxKeys = ["HVE4_CULT_D_9", "HVE4_CULT_D_10", "HVE4_CULT_D_11", "HVE4_CULT_D_12", "HVE4_CULT_D_13"];
    const proteagineuxKeys = ["HVE4_CULT_D_14", "HVE4_CULT_D_15", "HVE4_CULT_D_16", "HVE4_CULT_D_17", "HVE4_CULT_D_18"];
    const fibresSurfacesGeleesKeys = [
      "HVE4_CULT_D_19",
      "HVE4_CULT_D_20",
      "HVE4_CULT_D_20_1",
      "HVE4_CULT_D_20_2",
      "HVE4_CULT_D_21",
    ];
    const fourragesKeys = ["HVE4_CULT_D_22", "HVE4_CULT_D_23", "HVE4_CULT_D_24", "HVE4_CULT_D_25"];
    const surfacesHerbeKey = [
      "HVE4_CULT_D_26",
      "HVE4_CULT_D_27",
      "HVE4_CULT_D_28",
      "HVE4_CULT_D_29",
      "HVE4_CULT_D_30",
      "HVE4_CULT_D_31",
      "HVE4_CULT_D_32",
      "HVE4_CULT_D_33",
      "HVE4_CULT_D_34",
    ];
    const culturesIndusKeys = ["HVE4_CULT_D_35", "HVE4_CULT_D_36", "HVE4_CULT_D_37"];
    const legumesFleursFruitsKeys = [
      "HVE4_CULT_D_38",
      "HVE4_CULT_D_39",
      "HVE4_CULT_D_40",
      "HVE4_CULT_D_41",
      "HVE4_CULT_D_42",
      "HVE4_CULT_D_43",
    ];
    const arboVitiKeys = ["HVE4_CULT_D_44", "HVE4_CULT_D_45"];
    const arboVitiKeysEnd = ["HVE4_CULT_D_46", "HVE4_CULT_D_47"];

    const cerealesToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => cerealesKeys.includes(key)))
    );

    const oleagineuxToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => oleagineuxKeys.includes(key)))
    );

    const proteagineuxToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => proteagineuxKeys.includes(key)))
    );

    const fibresSurfacesGeleesToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => fibresSurfacesGeleesKeys.includes(key)))
    );

    const fourragesToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => fourragesKeys.includes(key)))
    );

    const surfacesHerbeToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => surfacesHerbeKey.includes(key)))
    );

    const culturesIndusToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => culturesIndusKeys.includes(key)))
    );
    const legumesFleursFruitsToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => legumesFleursFruitsKeys.includes(key)))
    );
    const arboVitiToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => arboVitiKeys.includes(key)))
    );

    const arboVitiEndToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => arboVitiKeysEnd.includes(key)))
    );

    return (
      <React.Fragment>
        {cerealesToDisplay.length > 0 && <h2 className={"my-1"}>Céréales</h2>}
        <div>
          {cerealesToDisplay.map((indic) => (
            <div className={"d-flex align-items-center mb-1"} key={"indic-" + indic.idIndicateur}>
              <Grid item xs={4}>
                <div className={indic.afficherGras ? "mr-3 bold" : "mr-3"}>{indic.libelle}</div>
              </Grid>
              <Grid item>
                <Assolement
                  size={"small"}
                  getValueAssolement={getValueAssolement}
                  indicateur={indic}
                  key={"indicateur-" + indic.idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indic.libelleUnite != null && {
                      endAdornment: <InputAdornment position={"end"}>{indic.libelleUnite}</InputAdornment>,
                    }
                  }
                  computedFields={computedFields}
                />
              </Grid>
            </div>
          ))}
        </div>
        {oleagineuxToDisplay.length > 0 && <h2 className={"my-1"}>Oléagineux</h2>}
        <div>
          {oleagineuxToDisplay.map((indic) => (
            <div className={"d-flex align-items-center mb-1"} key={"indic-" + indic.idIndicateur}>
              <Grid item xs={4}>
                <div className={indic.afficherGras ? "mr-3 bold" : "mr-3"}>{indic.libelle}</div>
              </Grid>
              <Grid item>
                <Assolement
                  size={"small"}
                  getValueAssolement={getValueAssolement}
                  indicateur={indic}
                  key={"indicateur-" + indic.idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indic.libelleUnite != null && {
                      endAdornment: <InputAdornment position={"end"}>{indic.libelleUnite}</InputAdornment>,
                    }
                  }
                  computedFields={computedFields}
                />
              </Grid>
            </div>
          ))}
        </div>
        {proteagineuxToDisplay.length > 0 && <h2 className={"my-2"}>Proteagineux</h2>}
        <div>
          {proteagineuxToDisplay.map((indic) => (
            <div className={"d-flex align-items-center mb-1"} key={"indic-" + indic.idIndicateur}>
              <Grid item xs={4} key={"indic-" + indic.idIndicateur}>
                <div className={indic.afficherGras ? "mr-3 bold" : "mr-3"}>{indic.libelle}</div>
              </Grid>
              <Grid item>
                <Assolement
                  size={"small"}
                  getValueAssolement={getValueAssolement}
                  indicateur={indic}
                  key={"indicateur-" + indic.idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indic.libelleUnite != null && {
                      endAdornment: <InputAdornment position={"end"}>{indic.libelleUnite}</InputAdornment>,
                    }
                  }
                  computedFields={computedFields}
                />
              </Grid>
            </div>
          ))}
        </div>
        {fibresSurfacesGeleesToDisplay.length > 0 && <h2 className={"my-2"}>Fibres et surfaces gelées</h2>}
        <div>
          {fibresSurfacesGeleesToDisplay.map((indic) => (
            <div className={"d-flex align-items-center mb-1"} key={"indic-" + indic.idIndicateur}>
              <Grid item xs={4}>
                <div className={indic.afficherGras ? "mr-3 bold" : "mr-3"}>{indic.libelle}</div>
              </Grid>
              <Grid item>
                <Assolement
                  size={"small"}
                  getValueAssolement={getValueAssolement}
                  indicateur={indic}
                  key={"indicateur-" + indic.idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indic.libelleUnite != null && {
                      endAdornment: <InputAdornment position={"end"}>{indic.libelleUnite}</InputAdornment>,
                    }
                  }
                  computedFields={computedFields}
                />
              </Grid>
            </div>
          ))}
        </div>
        {fourragesToDisplay.length > 0 && <h2 className={"my-2"}>Fourrages</h2>}
        <div>
          {fourragesToDisplay.map((indic) => (
            <div className={"d-flex align-items-center mb-1"} key={"indic-" + indic.idIndicateur}>
              <Grid item xs={4}>
                <div className={indic.afficherGras ? "mr-3 bold" : "mr-3"}>{indic.libelle}</div>
              </Grid>
              <Grid item>
                <Assolement
                  size={"small"}
                  getValueAssolement={getValueAssolement}
                  indicateur={indic}
                  key={"indicateur-" + indic.idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indic.libelleUnite != null && {
                      endAdornment: <InputAdornment position={"end"}>{indic.libelleUnite}</InputAdornment>,
                    }
                  }
                  computedFields={computedFields}
                />
              </Grid>
            </div>
          ))}
        </div>
        {surfacesHerbeToDisplay.length > 0 && <h2 className={"my-2"}>Surfaces en herbe</h2>}
        <div>
          {surfacesHerbeToDisplay.map((indic) => (
            <div className={"d-flex align-items-center mb-1"} key={"indic-" + indic.idIndicateur}>
              <Grid item xs={4}>
                <div className={indic.afficherGras ? "mr-3 bold" : "mr-3"}>{indic.libelle}</div>
              </Grid>
              <Grid item>
                <Assolement
                  size={"small"}
                  getValueAssolement={getValueAssolement}
                  indicateur={indic}
                  key={"indicateur-" + indic.idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indic.libelleUnite != null && {
                      endAdornment: <InputAdornment position={"end"}>{indic.libelleUnite}</InputAdornment>,
                    }
                  }
                  computedFields={computedFields}
                />
              </Grid>
            </div>
          ))}
        </div>
        {culturesIndusToDisplay.length > 0 && <h2 className={"my-2"}>Cultures industrielles</h2>}
        <div>
          {culturesIndusToDisplay.map((indic) => (
            <div className={"d-flex align-items-center mb-1"} key={"indic-" + indic.idIndicateur}>
              <Grid item xs={4}>
                <div className={indic.afficherGras ? "mr-3 bold" : "mr-3"}>{indic.libelle}</div>
              </Grid>
              <Grid item>
                <Assolement
                  size={"small"}
                  getValueAssolement={getValueAssolement}
                  indicateur={indic}
                  key={"indicateur-" + indic.idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indic.libelleUnite != null && {
                      endAdornment: <InputAdornment position={"end"}>{indic.libelleUnite}</InputAdornment>,
                    }
                  }
                  computedFields={computedFields}
                />
              </Grid>
            </div>
          ))}
        </div>
        {legumesFleursFruitsToDisplay.length > 0 && (
          <h2 className={"my-2"}>Légumes, fleurs et fruits hors arboriculture</h2>
        )}
        <div>
          {legumesFleursFruitsToDisplay.map((indic) => (
            <div className={"d-flex align-items-center mb-1"} key={"indic-" + indic.idIndicateur}>
              <Grid item xs={4}>
                <div className={indic.afficherGras ? "mr-3 bold" : "mr-3"}>{indic.libelle}</div>
              </Grid>
              <Grid item>
                <Assolement
                  size={"small"}
                  getValueAssolement={getValueAssolement}
                  indicateur={indic}
                  key={"indicateur-" + indic.idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indic.libelleUnite != null && {
                      endAdornment: <InputAdornment position={"end"}>{indic.libelleUnite}</InputAdornment>,
                    }
                  }
                  computedFields={computedFields}
                />
              </Grid>
            </div>
          ))}
        </div>
        {arboVitiToDisplay.length > 0 && <h2 className={"my-2"}>Arboriculture et viticulture</h2>}
        <div>
          {arboVitiToDisplay.map((indic) => (
            <div className={"d-flex align-items-center mb-1"} key={"indic-" + indic.idIndicateur}>
              <Grid item xs={4}>
                <div className={indic.afficherGras ? "mr-3 bold" : "mr-3"}>{indic.libelle}</div>
              </Grid>
              {indic.codeNational === "HVE4_CULT_D_45" ? (
                <React.Fragment>
                  <Grid item>
                    <Assolement
                      size={"small"}
                      getValueAssolement={getValueAssolement}
                      indicateur={indic}
                      key={"indicateur-" + indic.idIndicateur}
                      changeIndicateur={this.handleChangeIndicateur}
                      valueInputProps={
                        indic.libelleUnite != null && {
                          endAdornment: <InputAdornment position={"end"}>{indic.libelleUnite}</InputAdornment>,
                        }
                      }
                      displayRules={{ displayAssolement: "assolementOnly" }}
                      computedFields={computedFields}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Tooltip title={<span style={{ whiteSpace: "pre-line" }}>{indic.messageAide}</span>}>
                      <IconButton>
                        <FontAwesomeIcon icon="info-circle" />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </React.Fragment>
              ) : (
                <Grid item>
                  <Assolement
                    size={"small"}
                    getValueAssolement={getValueAssolement}
                    indicateur={indic}
                    key={"indicateur-" + indic.idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indic.libelleUnite != null && {
                        endAdornment: <InputAdornment position={"end"}>{indic.libelleUnite}</InputAdornment>,
                      }
                    }
                    computedFields={computedFields}
                  />
                </Grid>
              )}
            </div>
          ))}
        </div>
        {arboVitiToDisplay.length > 0 && (
          <TableContainer>
            <Table style={{ width: "50%", marginBottom: "2em" }}>
              <TableBody>
                {assolement
                  .filter((a) => a.codeTypeCulture === "VERGER")
                  .map((a, i) => (
                    <TableRow key={"arbo-" + i}>
                      <TableCell style={{ width: "50%" }}>
                        <div className="mr-3">{a.regroupementCulture}</div>
                      </TableCell>
                      <TableCell align={"right"} style={{ width: "25%", paddingRight: "3em" }}>
                        {a.surface + " Ha"}
                      </TableCell>
                      <TableCell style={{ width: "25%" }} />
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <div>
          {arboVitiEndToDisplay.map((indic) => (
            <div className={"d-flex align-items-center mb-1"} key={"indic-" + indic.idIndicateur}>
              <Grid item xs={4}>
                <div className={indic.afficherGras ? "mr-3 bold" : "mr-3"}>{indic.libelle}</div>
              </Grid>
              <Grid item>
                <Assolement
                  size={"small"}
                  getValueAssolement={getValueAssolement}
                  indicateur={indic}
                  key={"indicateur-" + indic.idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indic.libelleUnite != null && {
                      endAdornment: <InputAdornment position={"end"}>{indic.libelleUnite}</InputAdornment>,
                    }
                  }
                  computedFields={computedFields}
                />
              </Grid>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(SurfaceSAU);
