import React from "react";
import { Box, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTheme } from "@material-ui/styles";
import { procedureStateCodes } from "../../../common/codes";

class FilterDemarche extends React.Component {
  handleInputChange = (event) => {
    const { target } = event;
    const { name, value } = target;
    this.props.update({
      ...this.props.filter,
      [name]: value,
    });
  };

  handleCheckedChange = (event) => {
    const { target } = event;
    const { name, checked } = target;
    this.props.update({
      ...this.props.filter,
      [name]: checked,
    });
  };

  render() {
    const {
      certifications,
      t,
      theme,
      filter: { procedureId, procedureState },
    } = this.props;
    const procedureStates = Object.values(procedureStateCodes).map((procedureStateCode) => ({
      code: procedureStateCode,
      libelle: t(`procedure.procedure-state.${procedureStateCode}`),
    }));

    return (
      <Box className="position-relative">
        <div className="d-flex flex-column align-items-stretch">
          <FormControl variant="outlined" className="mb-1">
            <InputLabel>{t("procedure.term.singular", "Démarche")}</InputLabel>
            <Select
              variant="outlined"
              label={t("procedure.term.singular", "Démarche")}
              value={procedureId}
              onChange={this.handleInputChange}
              name="procedureId"
            >
              <MenuItem value="">
                <em>{t("value.none", "Aucune")}</em>
              </MenuItem>
              {certifications
                .filter((c) => c.selectable)
                .map((c) => (
                  <MenuItem key={c.idDemarche} value={c.idDemarche}>
                    {c.libelle}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <div className="mb-1">
            <div className="d-flex">
              <TextField
                disabled={procedureId === ""}
                select
                label={t("procedure.procedure-state.name", "Etat du dossier")}
                name="procedureState"
                value={procedureState}
                onChange={this.handleInputChange}
              >
                <MenuItem value="">
                  <em>{t("value.none", "Aucune")}</em>
                </MenuItem>
                {procedureStates.map((procedureState) => (
                  <MenuItem key={procedureState.code} value={procedureState.code}>
                    {procedureState.libelle}
                  </MenuItem>
                ))}
              </TextField>
            </div>
          </div>
        </div>
        <Tooltip title={t("admin.delete", "Supprimer")}>
          <IconButton
            type="button"
            size={"small"}
            onClick={this.props.delete}
            style={{
              position: "absolute",
              top: "-0.3rem",
              right: "-1.5rem",
            }}
          >
            <FontAwesomeIcon
              icon="times"
              style={{
                height: "15px",
                width: "15px",
                color: theme.palette.error.main,
              }}
            />
          </IconButton>
        </Tooltip>
      </Box>
    );
  }
}

export default withTranslation()(withTheme(FilterDemarche));
