import React from "react";
import { withStyles } from "@material-ui/styles";
import logo1 from "../../resources/img/banner/1.jpg";
import Paper from "@material-ui/core/Paper";
import Card from "@material-ui/core/Card";
import { loadConfig } from "../../actions/config";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  config: state.config,
});

const mapDispatchToProps = (dispatch) => ({
  loadConfig: () => dispatch(loadConfig()),
});

let backgroundImage = logo1;

const CustomPaper = withStyles((theme) => ({
  root: {
    background: `no-repeat url(${backgroundImage})`,
    backgroundSize: "cover",
    "&::before": {
      content: "''",
      position: "absolute",
      left: "0",
      right: "0",
      top: "0",
      bottom: "0",
      background: `linear-gradient(45deg, ${theme.palette.secondary.main} 10%, ${theme.palette.third.main} 90%)`,
      opacity: 0.6,
    },
  },
}))(Paper);

class BackgroundPaper extends React.Component {
  render() {
    const { children, config } = this.props;
    const logo = config.logoBase64;
    return (
      <CustomPaper>
        <div
          className="height-100vh d-flex justify-center align-items-center p-2 flex-column"
          style={{ zIndex: 2, position: "relative" }}
        >
          <img src={logo} alt={config.titleSite} title={config.titleSite} style={{ maxHeight: "8em" }} />
          <Card className="mb-1 mt-2 mx-0 p-2 d-flex flex-column align-items-center" style={{ minWidth: "500px" }}>
            {children}
          </Card>
        </div>
      </CustomPaper>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(BackgroundPaper);
