import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import {
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
import Boolean from "../indicateurs/Boolean";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class FertiCouvSol extends React.Component {
  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };

  render() {
    const { indicateurs, computedFields } = this.props;

    const surfacesHorsVitiArboPepiZVKeys = [
      "HVE4_FERTI_I_70_ZV",
      "HVE4_FERTI_D_75_ZV",
      "HVE4_FERTI_S_70_ZV",
      "HVE4_FERTI_D_740_ZV",
      "HVE4_FERTI_S_71",
    ];

    const surfacesHorsVitiArboPepiHZVKeys = [
      "HVE4_FERTI_I_70_hors_ZV",
      "HVE4_FERTI_D_75_hors_ZV",
      "HVE4_FERTI_S_70_hors_ZV",
      "HVE4_FERTI_D_740_hors_ZV",
      "HVE4_FERTI_S_72",
    ];

    const surfacesEnVigneKeys = [
      "HVE4_FERTI_D_76",
      "HVE4_FERTI_I_76",
      "HVE4_FERTI_S_76",
      "HVE4_FERTI_D_761",
      "HVE4_FERTI_S_760",
    ];
    const surfacesEnArboKeys = [
      "HVE4_FERTI_D_77",
      "HVE4_FERTI_I_77",
      "HVE4_FERTI_S_77",
      "HVE4_FERTI_D_771",
      "HVE4_FERTI_S_770",
    ];
    const surfacesEnHortiPepiKeys = [
      "HVE4_FERTI_D_78",
      "HVE4_FERTI_I_78",
      "HVE4_FERTI_S_78",
      "HVE4_FERTI_D_781",
      "HVE4_FERTI_S_780",
    ];

    const indicateursKeys = [
      "HVE4_FERTI_D_71_ZV",
      "HVE4_FERTI_D_72_ZV",
      "HVE4_FERTI_D_72_hors_ZV",
      "HVE4_FERTI_D_73_ZV",
      "HVE4_FERTI_D_73_hors_ZV",
      "HVE4_FERTI_D_74",
      "HVE4_FERTI_D_74_ZV",
      "HVE4_FERTI_D_74_hors_ZV",
      "HVE4_FERTI_S_782",
      ...surfacesHorsVitiArboPepiZVKeys,
      ...surfacesHorsVitiArboPepiHZVKeys,
      ...surfacesEnVigneKeys,
      ...surfacesEnArboKeys,
      ...surfacesEnHortiPepiKeys,
    ];

    const indicateursToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateursKeys.includes(key)))
    );

    const surfacesEnVigneToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => surfacesEnVigneKeys.includes(key)))
    );

    const surfacesEnArboToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => surfacesEnArboKeys.includes(key)))
    );

    const surfacesEnHortiPepiToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => surfacesEnHortiPepiKeys.includes(key)))
    );

    const surfacesHorsVitiArboPepiHZVToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => surfacesHorsVitiArboPepiHZVKeys.includes(key)))
    );

    const surfacesHorsVitiArboPepiZVToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => surfacesHorsVitiArboPepiZVKeys.includes(key)))
    );

    if (indicateursToDisplay.length > 0)
      return (
        <React.Fragment>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div className={"mr-3 bold"}>Surfaces hors viti-arbo-horti-pépi localisées en zone vulnérable</div>
            </Grid>
            <Grid item xs={3}>
              <div className={"mr-3"}>{indicateurs["HVE4_FERTI_D_72_ZV"].libelle}</div>
            </Grid>
            <Grid item xs={2}>
              <Boolean
                indicateur={indicateurs["HVE4_FERTI_D_72_ZV"]}
                key={"indicateur-" + indicateurs["HVE4_FERTI_D_72_ZV"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_FERTI_D_72_ZV"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_FERTI_D_72_ZV"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
            {surfacesHorsVitiArboPepiZVToDisplay.length > 1 && (
              <Grid item xs={12} className={"mb-1"}>
                <TableContainer component={Paper}>
                  <Table style={{ tableLayout: "fixed" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <div>Surface couverte</div>
                        </TableCell>
                        <TableCell>
                          <div>Surface hors viti arbo pépi en ZV</div>
                        </TableCell>
                        {indicateurs["HVE4_FERTI_D_74_ZV"].valeur.value !== 0 && (
                          <React.Fragment>
                            <TableCell>
                              <div>Part de la surface couverte</div>
                            </TableCell>
                            <TableCell>
                              <div>Nombre de semaines de couvert y compris la durée réglementaire</div>
                            </TableCell>
                            <TableCell>
                              <div>Points intermédiaires</div>
                            </TableCell>
                            <TableCell>
                              <div>Pondération /SAU</div>
                            </TableCell>
                            <TableCell>
                              <div>Points</div>
                            </TableCell>
                          </React.Fragment>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <DecimalPositif
                            size={"small"}
                            indicateur={indicateurs["HVE4_FERTI_D_73_ZV"]}
                            key={"indicateur-" + indicateurs["HVE4_FERTI_D_73_ZV"].idIndicateur}
                            changeIndicateur={this.handleChangeIndicateur}
                            valueInputProps={
                              indicateurs["HVE4_FERTI_D_73_ZV"].libelleUnite != null && {
                                endAdornment: (
                                  <InputAdornment position={"end"}>
                                    {indicateurs["HVE4_FERTI_D_73_ZV"].libelleUnite}
                                  </InputAdornment>
                                ),
                              }
                            }
                            computedFields={computedFields}
                          />
                        </TableCell>
                        <TableCell>
                          <DecimalPositif
                            size={"small"}
                            indicateur={indicateurs["HVE4_FERTI_D_74_ZV"]}
                            key={"indicateur-" + indicateurs["HVE4_FERTI_D_74_ZV"].idIndicateur}
                            changeIndicateur={this.handleChangeIndicateur}
                            valueInputProps={
                              indicateurs["HVE4_FERTI_D_74_ZV"].libelleUnite != null && {
                                endAdornment: (
                                  <InputAdornment position={"end"}>
                                    {indicateurs["HVE4_FERTI_D_74_ZV"].libelleUnite}
                                  </InputAdornment>
                                ),
                              }
                            }
                            computedFields={computedFields}
                          />
                        </TableCell>
                        {indicateurs["HVE4_FERTI_D_74_ZV"].valeur.value !== 0 &&
                          surfacesHorsVitiArboPepiZVToDisplay.length > 0 &&
                          surfacesHorsVitiArboPepiZVKeys.map((indic, i) => (
                            <TableCell key={"input-" + i}>
                              <DecimalPositif
                                size={"small"}
                                indicateur={indicateurs[indic]}
                                key={"indicateur-" + indicateurs[indic].idIndicateur}
                                changeIndicateur={this.handleChangeIndicateur}
                                valueInputProps={
                                  indicateurs[indic].libelleUnite != null && {
                                    endAdornment: (
                                      <InputAdornment position={"end"}>
                                        {indicateurs[indic].libelleUnite}
                                      </InputAdornment>
                                    ),
                                  }
                                }
                                computedFields={computedFields}
                              />
                            </TableCell>
                          ))}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
            <Grid container className={"mt-2"}>
              <Grid item xs={4}>
                <div className={"mr-3 bold"}>Surfaces hors viti-arbo-horti-pépi localisées hors zone vulnérable</div>
              </Grid>
              <Grid item xs={3}>
                <div className={"mr-3"}>{indicateurs["HVE4_FERTI_D_72_hors_ZV"].libelle}</div>
              </Grid>
              <Grid item xs={2}>
                <Boolean
                  indicateur={indicateurs["HVE4_FERTI_D_72_hors_ZV"]}
                  key={"indicateur-" + indicateurs["HVE4_FERTI_D_72_hors_ZV"].idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indicateurs["HVE4_FERTI_D_72_hors_ZV"].libelleUnite != null && {
                      endAdornment: (
                        <InputAdornment position={"end"}>
                          {indicateurs["HVE4_FERTI_D_72_hors_ZV"].libelleUnite}
                        </InputAdornment>
                      ),
                    }
                  }
                  computedFields={computedFields}
                />
              </Grid>
            </Grid>
            {surfacesHorsVitiArboPepiHZVToDisplay.length > 1 && (
              <Grid item xs={12} className={"mb-1"}>
                <TableContainer component={Paper}>
                  <Table style={{ tableLayout: "fixed" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <div>Surface couverte</div>
                        </TableCell>
                        <TableCell>
                          <div>Surface concernée hors ZV</div>
                        </TableCell>
                        {indicateurs["HVE4_FERTI_D_74_hors_ZV"].valeur.value !== 0 && (
                          <React.Fragment>
                            <TableCell>
                              <div>Part de la surface couverte</div>
                            </TableCell>
                            <TableCell>
                              <div>Nombre de semaines de couvert y compris la durée réglementaire</div>
                            </TableCell>
                            <TableCell>
                              <div>Points intermédiaires</div>
                            </TableCell>
                            <TableCell>
                              <div>Pondération /SAU</div>
                            </TableCell>
                            <TableCell>
                              <div>Points</div>
                            </TableCell>
                          </React.Fragment>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <DecimalPositif
                            size={"small"}
                            indicateur={indicateurs["HVE4_FERTI_D_73_hors_ZV"]}
                            key={"indicateur-" + indicateurs["HVE4_FERTI_D_73_hors_ZV"].idIndicateur}
                            changeIndicateur={this.handleChangeIndicateur}
                            valueInputProps={
                              indicateurs["HVE4_FERTI_D_73_hors_ZV"].libelleUnite != null && {
                                endAdornment: (
                                  <InputAdornment position={"end"}>
                                    {indicateurs["HVE4_FERTI_D_73_hors_ZV"].libelleUnite}
                                  </InputAdornment>
                                ),
                              }
                            }
                            computedFields={computedFields}
                          />
                        </TableCell>
                        <TableCell>
                          <DecimalPositif
                            size={"small"}
                            indicateur={indicateurs["HVE4_FERTI_D_74_hors_ZV"]}
                            key={"indicateur-" + indicateurs["HVE4_FERTI_D_74_hors_ZV"].idIndicateur}
                            changeIndicateur={this.handleChangeIndicateur}
                            valueInputProps={
                              indicateurs["HVE4_FERTI_D_74_hors_ZV"].libelleUnite != null && {
                                endAdornment: (
                                  <InputAdornment position={"end"}>
                                    {indicateurs["HVE4_FERTI_D_74_hors_ZV"].libelleUnite}
                                  </InputAdornment>
                                ),
                              }
                            }
                            computedFields={computedFields}
                          />
                        </TableCell>
                        {indicateurs["HVE4_FERTI_D_74_hors_ZV"].valeur.value !== 0 &&
                          surfacesHorsVitiArboPepiHZVToDisplay.length > 0 &&
                          surfacesHorsVitiArboPepiHZVKeys.map((indic, i) => (
                            <TableCell key={"input-" + i}>
                              <DecimalPositif
                                size={"small"}
                                indicateur={indicateurs[indic]}
                                key={"indicateur-" + indicateurs[indic].idIndicateur}
                                changeIndicateur={this.handleChangeIndicateur}
                                valueInputProps={
                                  indicateurs[indic].libelleUnite != null && {
                                    endAdornment: (
                                      <InputAdornment position={"end"}>
                                        {indicateurs[indic].libelleUnite}
                                      </InputAdornment>
                                    ),
                                  }
                                }
                                computedFields={computedFields}
                              />
                            </TableCell>
                          ))}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
            {(surfacesEnVigneToDisplay.length > 0 ||
              surfacesEnArboToDisplay.length > 0 ||
              surfacesEnHortiPepiToDisplay.length > 0) && (
              <Grid item xs={12} className={"mb-2"}>
                <TableContainer component={Paper}>
                  <Table style={{ tableLayout: "fixed" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <div>Surface couverte</div>
                        </TableCell>
                        <TableCell>
                          <div>Part de la surface couverte</div>
                        </TableCell>
                        <TableCell>
                          <div>Points intermédiaires</div>
                        </TableCell>
                        <TableCell>
                          <div>Pondération /SAU</div>
                        </TableCell>
                        <TableCell>
                          <div>Points</div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {surfacesEnVigneToDisplay.length > 0 && (
                        <React.Fragment>
                          <TableRow>
                            <TableCell colSpan={5}>
                              <div className={"mr-3 bold"}>Surfaces en vigne</div>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            {surfacesEnVigneKeys.map((indic, i) => {
                              return (
                                <TableCell key={"input-" + i}>
                                  <DecimalPositif
                                    size={"small"}
                                    indicateur={indicateurs[indic]}
                                    key={"indicateur-" + indicateurs[indic]?.idIndicateur}
                                    changeIndicateur={this.handleChangeIndicateur}
                                    valueInputProps={
                                      indicateurs[indic]?.libelleUnite != null && {
                                        endAdornment: (
                                          <InputAdornment position={"end"}>
                                            {indicateurs[indic]?.libelleUnite}
                                          </InputAdornment>
                                        ),
                                      }
                                    }
                                    computedFields={computedFields}
                                  />
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        </React.Fragment>
                      )}
                      {surfacesEnArboToDisplay.length > 0 && (
                        <React.Fragment>
                          <TableRow>
                            <TableCell colSpan={5}>
                              <div className={"mr-3 bold"}>Surfaces en arboriculture et autres cultures pérennes</div>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            {surfacesEnArboKeys.map((indic, i) => (
                              <TableCell key={"input-" + i}>
                                <DecimalPositif
                                  size={"small"}
                                  indicateur={indicateurs[indic]}
                                  key={"indicateur-" + indicateurs[indic]?.idIndicateur}
                                  changeIndicateur={this.handleChangeIndicateur}
                                  valueInputProps={
                                    indicateurs[indic]?.libelleUnite != null && {
                                      endAdornment: (
                                        <InputAdornment position={"end"}>
                                          {indicateurs[indic]?.libelleUnite}
                                        </InputAdornment>
                                      ),
                                    }
                                  }
                                  computedFields={computedFields}
                                />
                              </TableCell>
                            ))}
                          </TableRow>
                        </React.Fragment>
                      )}
                      {surfacesEnHortiPepiToDisplay.length > 0 && (
                        <React.Fragment>
                          <TableRow>
                            <TableCell colSpan={5}>
                              <div className={"mr-3 bold"}>Surfaces en horti-pépi</div>
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            {surfacesEnHortiPepiKeys.map((indic, i) => (
                              <TableCell key={"input-" + i}>
                                <DecimalPositif
                                  size={"small"}
                                  indicateur={indicateurs[indic]}
                                  key={"indicateur-" + indicateurs[indic].idIndicateur}
                                  changeIndicateur={this.handleChangeIndicateur}
                                  valueInputProps={
                                    indicateurs[indic].libelleUnite != null && {
                                      endAdornment: (
                                        <InputAdornment position={"end"}>
                                          {indicateurs[indic].libelleUnite}
                                        </InputAdornment>
                                      ),
                                    }
                                  }
                                  computedFields={computedFields}
                                />
                              </TableCell>
                            ))}
                          </TableRow>
                        </React.Fragment>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
            <Grid container>
              <Grid item xs={3}>
                <div className={"mr-3 bold"}>{indicateurs["HVE4_FERTI_S_782"].libelle}</div>
              </Grid>
              <Grid item xs={2} className={"total-point"}>
                <DecimalPositif
                  size={"small"}
                  indicateur={indicateurs["HVE4_FERTI_S_782"]}
                  key={"indicateur-" + indicateurs["HVE4_FERTI_S_782"].idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indicateurs["HVE4_FERTI_S_782"].libelleUnite != null && {
                      endAdornment: (
                        <InputAdornment position={"end"}>{indicateurs["HVE4_FERTI_S_782"].libelleUnite}</InputAdornment>
                      ),
                    }
                  }
                  computedFields={computedFields}
                />
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      );
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(FertiCouvSol);
