export const COMPUTED_FIELDS_PHYTO = {
  // RUBRIQUE PHYTO
  HVE4_PHYTO_D_3500: {
    columns: [
      "HVE4_CULT_D_8",
      "HVE4_CULT_D_13",
      "HVE4_CULT_D_18",
      "HVE4_CULT_D_21",
      "HVE4_CULT_D_25",
      "HVE4_CULT_D_26",
      "HVE4_CULT_D_27",
      "HVE4_CULT_D_28",
      "HVE4_CULT_D_29",
      "HVE4_CULT_D_30",
      "HVE4_CULT_D_37",
      "HVE4_CULT_D_38",
    ],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS_PHYTO.HVE4_PHYTO_D_3500.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE4_PHYTO_S_11: {
    columns: ["HVE4_PHYTO_I_11"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: getValue(state, "HVE4_PHYTO_I_11") === true ? 0 : 1,
        },
      };
    },
  },
  HVE4_PHYTO_S_12: {
    columns: ["HVE4_PHYTO_I_12"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: getValue(state, "HVE4_PHYTO_I_12") === true ? 0 : 1,
        },
      };
    },
  },
  HVE4_PHYTO_S_13: {
    columns: ["HVE4_PHYTO_S_11", "HVE4_PHYTO_S_12", "HVE4_PHYTO_D_10", "HVE4_PHYTO_D_101"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValue(state, "HVE4_PHYTO_D_10") && !getValue(state, "HVE4_PHYTO_D_101")
            ? 0
            : getValueOrZero(state, "HVE4_PHYTO_S_11") + getValueOrZero(state, "HVE4_PHYTO_S_12"),
      },
    }),
  },
  HVE4_PHYTO_D_23: {
    columns: ["HVE4_PHYTO_D_20", "HVE4_PHYTO_D_21", "HVE4_PHYTO_D_22"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, "HVE4_PHYTO_D_20") +
          getValueOrZero(state, "HVE4_PHYTO_D_21") +
          getValueOrZero(state, "HVE4_PHYTO_D_22"),
      },
    }),
  },
  HVE4_PHYTO_I_20: {
    columns: ["HVE4_PHYTO_D_23", "HVE4_CULT_D_200"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_CULT_D_200") > 0
              ? roundFourDecimales(
                  (getValueOrZero(state, "HVE4_PHYTO_D_23") / getValueOrZero(state, "HVE4_CULT_D_200")) * 100
                )
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_S_20: {
    columns: ["HVE4_PHYTO_I_20", "HVE4_PHYTO_D_10", "HVE4_PHYTO_D_101"],
    disabled: true,
    computedValue: (state) => {
      const value = getValueOrZero(state, "HVE4_PHYTO_I_20"),
        note =
          getValue(state, "HVE4_PHYTO_D_10") && !getValue(state, "HVE4_PHYTO_D_101")
            ? 0
            : value <= 5
            ? 0
            : value > 5 && value <= 15
            ? 1
            : value > 15 && value <= 25
            ? 2
            : value > 25 && value <= 35
            ? 3
            : value > 35 && value <= 45
            ? 4
            : value > 45 && value <= 55
            ? 5
            : value > 55 && value <= 65
            ? 6
            : value > 65 && value <= 75
            ? 7
            : value > 75 && value <= 85
            ? 8
            : value > 85 && value <= 95
            ? 9
            : 10;
      return {
        valeur: {
          value: note,
        },
      };
    },
  },
  HVE4_PHYTO_I_29: {
    columns: ["HVE4_PHYTO_D_304_H", "HVE4_PHYTO_D_302"],
    disabled: true,
    computedValue: (state) => {
      const HVE4_PHYTO_D_302 = getValue(state, "HVE4_PHYTO_D_302");
      const diviseur = HVE4_PHYTO_D_302 === "TRIANNUEL" ? 3 : HVE4_PHYTO_D_302 === "BIANNUEL" ? 2 : 1;
      return {
        valeur: {
          value:
            ((state.indicateurs.HVE4_PHYTO_D_304_H?.valeur.value.n ?? 0) +
              (state.indicateurs.HVE4_PHYTO_D_304_H?.valeur.value.n_1 ?? 0) +
              (state.indicateurs.HVE4_PHYTO_D_304_H?.valeur.value.n_2 ?? 0)) /
            diviseur,
        },
      };
    },
  },
  HVE4_PHYTO_I_30: {
    columns: ["HVE4_PHYTO_D_304_HH", "HVE4_PHYTO_D_302"],
    disabled: true,
    computedValue: (state) => {
      const HVE4_PHYTO_D_302 = getValue(state, "HVE4_PHYTO_D_302");
      const diviseur = HVE4_PHYTO_D_302 === "TRIANNUEL" ? 3 : HVE4_PHYTO_D_302 === "BIANNUEL" ? 2 : 1;
      return {
        valeur: {
          value:
            ((state.indicateurs.HVE4_PHYTO_D_304_HH?.valeur.value.n ?? 0) +
              (state.indicateurs.HVE4_PHYTO_D_304_HH?.valeur.value.n_1 ?? 0) +
              (state.indicateurs.HVE4_PHYTO_D_304_HH?.valeur.value.n_2 ?? 0)) /
            diviseur,
        },
      };
    },
  },
  HVE4_PHYTO_I_31: {
    columns: ["HVE4_PHYTO_D_305_H", "HVE4_PHYTO_D_302"],
    disabled: true,
    computedValue: (state) => {
      const HVE4_PHYTO_D_302 = getValue(state, "HVE4_PHYTO_D_302");
      const choix_annee = HVE4_PHYTO_D_302 === "TRIANNUEL" ? 3 : HVE4_PHYTO_D_302 === "BIANNUEL" ? 2 : 1;
      return {
        valeur: {
          value:
            choix_annee === 3
              ? ((state.indicateurs.HVE4_PHYTO_D_305_H?.valeur.value.n ?? 0) +
                  (state.indicateurs.HVE4_PHYTO_D_305_H?.valeur.value.n_1 ?? 0) +
                  (state.indicateurs.HVE4_PHYTO_D_305_H?.valeur.value.n_2 ?? 0)) /
                choix_annee
              : choix_annee === 2
              ? ((state.indicateurs.HVE4_PHYTO_D_305_H?.valeur.value.n ?? 0) +
                  (state.indicateurs.HVE4_PHYTO_D_305_H?.valeur.value.n_1 ?? 0)) /
                choix_annee
              : state.indicateurs.HVE4_PHYTO_D_305_H?.valeur.value.n ?? 0,
        },
      };
    },
  },
  HVE4_PHYTO_D_305_H: {
    columns: ["HVE4_PHYTO_D_308_H", "HVE4_PHYTO_D_304_H", "HVE4_PHYTO_D_302", "HVE4_CULT_D_38"],
    disabled: true,
    computedValue: (state) => {
      const surf_pdt = getValueOrZero(state, "HVE4_CULT_D_38");
      const surf_gc = getValueOrZero(state, "HVE4_PHYTO_D_3500");
      if (surf_pdt && surf_gc > 0) {
        return {
          valeur: {
            value: {
              n:
                (state.indicateurs.HVE4_PHYTO_D_308_H?.valeur.value.n * (surf_gc - surf_pdt) +
                  state.indicateurs.HVE4_PHYTO_D_304_H?.valeur.value.n * surf_pdt) /
                surf_gc,
              n_1:
                (state.indicateurs.HVE4_PHYTO_D_308_H?.valeur.value.n_1 * (surf_gc - surf_pdt) +
                  state.indicateurs.HVE4_PHYTO_D_304_H?.valeur.value.n_1 * surf_pdt) /
                surf_gc,
              n_2:
                (state.indicateurs.HVE4_PHYTO_D_308_H?.valeur.value.n_2 * (surf_gc - surf_pdt) +
                  state.indicateurs.HVE4_PHYTO_D_304_H?.valeur.value.n_2 * surf_pdt) /
                surf_gc,
            },
          },
        };
      } else {
        return {
          valeur: {
            value: {
              n: state.indicateurs.HVE4_PHYTO_D_308_H?.valeur.value.n,
              n_1: state.indicateurs.HVE4_PHYTO_D_308_H?.valeur.value.n_1,
              n_2: state.indicateurs.HVE4_PHYTO_D_308_H?.valeur.value.n_2,
            },
          },
        };
      }
    },
  },
  HVE4_PHYTO_D_305_HH: {
    columns: ["HVE4_PHYTO_D_308_HH", "HVE4_PHYTO_D_304_HH", "HVE4_PHYTO_D_302", "HVE4_CULT_D_38"],
    disabled: true,
    computedValue: (state) => {
      const surf_pdt = getValue(state, "HVE4_CULT_D_38");
      const surf_gc = getValueOrZero(state, "HVE4_PHYTO_D_3500");
      if (surf_pdt > 0) {
        return {
          valeur: {
            value: {
              n:
                (state.indicateurs.HVE4_PHYTO_D_308_HH?.valeur.value.n * (surf_gc - surf_pdt) +
                  state.indicateurs.HVE4_PHYTO_D_304_HH?.valeur.value.n * surf_pdt) /
                surf_gc,
              n_1:
                (state.indicateurs.HVE4_PHYTO_D_308_HH?.valeur.value.n_1 * (surf_gc - surf_pdt) +
                  state.indicateurs.HVE4_PHYTO_D_304_HH?.valeur.value.n_1 * surf_pdt) /
                surf_gc,
              n_2:
                (state.indicateurs.HVE4_PHYTO_D_308_HH?.valeur.value.n_2 * (surf_gc - surf_pdt) +
                  state.indicateurs.HVE4_PHYTO_D_304_HH?.valeur.value.n_2 * surf_pdt) /
                surf_gc,
            },
          },
        };
      } else {
        return {
          valeur: {
            value: {
              n: state.indicateurs.HVE4_PHYTO_D_308_HH?.valeur.value.n,
              n_1: state.indicateurs.HVE4_PHYTO_D_308_HH?.valeur.value.n_1,
              n_2: state.indicateurs.HVE4_PHYTO_D_308_HH?.valeur.value.n_2,
            },
          },
        };
      }
    },
  },
  HVE4_PHYTO_I_32: {
    columns: ["HVE4_PHYTO_D_305_HH", "HVE4_PHYTO_D_302"],
    disabled: true,
    computedValue: (state) => {
      const HVE4_PHYTO_D_302 = getValue(state, "HVE4_PHYTO_D_302");
      const choix_annee = HVE4_PHYTO_D_302 === "TRIANNUEL" ? 3 : HVE4_PHYTO_D_302 === "BIANNUEL" ? 2 : 1;
      return {
        valeur: {
          value:
            choix_annee === 3
              ? ((state.indicateurs.HVE4_PHYTO_D_305_HH?.valeur.value.n ?? 0) +
                  (state.indicateurs.HVE4_PHYTO_D_305_HH?.valeur.value.n_1 ?? 0) +
                  (state.indicateurs.HVE4_PHYTO_D_305_HH?.valeur.value.n_2 ?? 0)) /
                choix_annee
              : choix_annee === 2
              ? ((state.indicateurs.HVE4_PHYTO_D_305_HH?.valeur.value.n ?? 0) +
                  (state.indicateurs.HVE4_PHYTO_D_305_HH?.valeur.value.n_1 ?? 0)) /
                choix_annee
              : state.indicateurs.HVE4_PHYTO_D_305_HH?.valeur.value.n ?? 0,
        },
      };
    },
  },
  HVE4_PHYTO_I_33: {
    columns: ["HVE4_PHYTO_D_306_H", "HVE4_PHYTO_D_302"],
    disabled: true,
    computedValue: (state) => {
      const HVE4_PHYTO_D_302 = getValue(state, "HVE4_PHYTO_D_302");
      const choix_annee = HVE4_PHYTO_D_302 === "TRIANNUEL" ? 3 : HVE4_PHYTO_D_302 === "BIANNUEL" ? 2 : 1;
      return {
        valeur: {
          value:
            choix_annee === 3
              ? ((state.indicateurs.HVE4_PHYTO_D_306_H?.valeur.value.n ?? 0) +
                  (state.indicateurs.HVE4_PHYTO_D_306_H?.valeur.value.n_1 ?? 0) +
                  (state.indicateurs.HVE4_PHYTO_D_306_H?.valeur.value.n_2 ?? 0)) /
                choix_annee
              : choix_annee === 2
              ? ((state.indicateurs.HVE4_PHYTO_D_306_H?.valeur.value.n ?? 0) +
                  (state.indicateurs.HVE4_PHYTO_D_306_H?.valeur.value.n_1 ?? 0)) /
                choix_annee
              : state.indicateurs.HVE4_PHYTO_D_306_H?.valeur.value.n ?? 0,
        },
      };
    },
  },
  HVE4_PHYTO_I_34: {
    columns: ["HVE4_PHYTO_D_306_HH", "HVE4_PHYTO_D_302"],
    disabled: true,
    computedValue: (state) => {
      const HVE4_PHYTO_D_302 = getValue(state, "HVE4_PHYTO_D_302");
      const choix_annee = HVE4_PHYTO_D_302 === "TRIANNUEL" ? 3 : HVE4_PHYTO_D_302 === "BIANNUEL" ? 2 : 1;
      return {
        valeur: {
          value:
            choix_annee === 3
              ? ((state.indicateurs.HVE4_PHYTO_D_306_HH?.valeur.value.n ?? 0) +
                  (state.indicateurs.HVE4_PHYTO_D_306_HH?.valeur.value.n_1 ?? 0) +
                  (state.indicateurs.HVE4_PHYTO_D_306_HH?.valeur.value.n_2 ?? 0)) /
                choix_annee
              : choix_annee === 2
              ? ((state.indicateurs.HVE4_PHYTO_D_306_HH?.valeur.value.n ?? 0) +
                  (state.indicateurs.HVE4_PHYTO_D_306_HH?.valeur.value.n_1 ?? 0)) /
                choix_annee
              : state.indicateurs.HVE4_PHYTO_D_306_HH?.valeur.value.n ?? 0,
        },
      };
    },
  },
  HVE4_PHYTO_I_35: {
    columns: ["HVE4_PHYTO_D_307_H", "HVE4_PHYTO_D_302"],
    disabled: true,
    computedValue: (state) => {
      const HVE4_PHYTO_D_302 = getValue(state, "HVE4_PHYTO_D_302");
      const choix_annee = HVE4_PHYTO_D_302 === "TRIANNUEL" ? 3 : HVE4_PHYTO_D_302 === "BIANNUEL" ? 2 : 1;
      return {
        valeur: {
          value:
            choix_annee === 3
              ? ((state.indicateurs.HVE4_PHYTO_D_307_H?.valeur.value.n ?? 0) +
                  (state.indicateurs.HVE4_PHYTO_D_307_H?.valeur.value.n_1 ?? 0) +
                  (state.indicateurs.HVE4_PHYTO_D_307_H?.valeur.value.n_2 ?? 0)) /
                choix_annee
              : choix_annee === 2
              ? ((state.indicateurs.HVE4_PHYTO_D_307_H?.valeur.value.n ?? 0) +
                  (state.indicateurs.HVE4_PHYTO_D_307_H?.valeur.value.n_1 ?? 0)) /
                choix_annee
              : state.indicateurs.HVE4_PHYTO_D_307_H?.valeur.value.n ?? 0,
        },
      };
    },
  },
  HVE4_PHYTO_I_36: {
    columns: ["HVE4_PHYTO_D_307_HH", "HVE4_PHYTO_D_302"],
    disabled: true,
    computedValue: (state) => {
      const HVE4_PHYTO_D_302 = getValue(state, "HVE4_PHYTO_D_302");
      const choix_annee = HVE4_PHYTO_D_302 === "TRIANNUEL" ? 3 : HVE4_PHYTO_D_302 === "BIANNUEL" ? 2 : 1;
      return {
        valeur: {
          value:
            choix_annee === 3
              ? ((state.indicateurs.HVE4_PHYTO_D_307_HH?.valeur.value.n ?? 0) +
                  (state.indicateurs.HVE4_PHYTO_D_307_HH?.valeur.value.n_1 ?? 0) +
                  (state.indicateurs.HVE4_PHYTO_D_307_HH?.valeur.value.n_2 ?? 0)) /
                choix_annee
              : choix_annee === 2
              ? ((state.indicateurs.HVE4_PHYTO_D_307_HH?.valeur.value.n ?? 0) +
                  (state.indicateurs.HVE4_PHYTO_D_307_HH?.valeur.value.n_1 ?? 0)) /
                choix_annee
              : state.indicateurs.HVE4_PHYTO_D_307_HH?.valeur.value.n ?? 0,
        },
      };
    },
  },
  HVE4_PHYTO_S_31: {
    columns: ["HVE4_PHYTO_I_31", "HVE4_PHYTO_D_330", "HVE4_PHYTO_D_331"],
    disabled: true,
    computedValue: (state) => {
      const HVE4_PHYTO_D_330 = getValue(state, "HVE4_PHYTO_D_330");
      const HVE4_PHYTO_D_331 = getValue(state, "HVE4_PHYTO_D_331");
      const HVE4_PHYTO_I_31 = getValue(state, "HVE4_PHYTO_I_31");
      return {
        valeur: {
          value:
            HVE4_PHYTO_D_330 == null || HVE4_PHYTO_D_331 == null
              ? 0
              : HVE4_PHYTO_I_31 > HVE4_PHYTO_D_330
              ? 0
              : HVE4_PHYTO_I_31 > HVE4_PHYTO_D_331 + (3 * (HVE4_PHYTO_D_330 - HVE4_PHYTO_D_331)) / 4 &&
                HVE4_PHYTO_I_31 <= HVE4_PHYTO_D_330
              ? 1
              : HVE4_PHYTO_I_31 > HVE4_PHYTO_D_331 + (2 * (HVE4_PHYTO_D_330 - HVE4_PHYTO_D_331)) / 4 &&
                HVE4_PHYTO_I_31 <= HVE4_PHYTO_D_331 + (3 * (HVE4_PHYTO_D_330 - HVE4_PHYTO_D_331)) / 4
              ? 2
              : HVE4_PHYTO_I_31 > HVE4_PHYTO_D_331 + (HVE4_PHYTO_D_330 - HVE4_PHYTO_D_331) / 4 &&
                HVE4_PHYTO_I_31 <= HVE4_PHYTO_D_331 + (2 * (HVE4_PHYTO_D_330 - HVE4_PHYTO_D_331)) / 4
              ? 3
              : HVE4_PHYTO_I_31 > HVE4_PHYTO_D_331 &&
                HVE4_PHYTO_I_31 <= HVE4_PHYTO_D_331 + (HVE4_PHYTO_D_330 - HVE4_PHYTO_D_331) / 4
              ? 4
              : HVE4_PHYTO_I_31 <= HVE4_PHYTO_D_331
              ? 5
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_S_32: {
    columns: ["HVE4_PHYTO_I_32", "HVE4_PHYTO_D_332", "HVE4_PHYTO_D_333"],
    disabled: true,
    computedValue: (state) => {
      const HVE4_PHYTO_D_332 = getValue(state, "HVE4_PHYTO_D_332");
      const HVE4_PHYTO_D_333 = getValue(state, "HVE4_PHYTO_D_333");
      const HVE4_PHYTO_I_32 = getValue(state, "HVE4_PHYTO_I_32");
      return {
        valeur: {
          value:
            HVE4_PHYTO_D_332 == null || HVE4_PHYTO_D_333 == null
              ? 0
              : HVE4_PHYTO_I_32 > HVE4_PHYTO_D_332
              ? 0
              : HVE4_PHYTO_I_32 > HVE4_PHYTO_D_333 + (3 * (HVE4_PHYTO_D_332 - HVE4_PHYTO_D_333)) / 4 &&
                HVE4_PHYTO_I_32 <= HVE4_PHYTO_D_332
              ? 1
              : HVE4_PHYTO_I_32 > HVE4_PHYTO_D_333 + (2 * (HVE4_PHYTO_D_332 - HVE4_PHYTO_D_333)) / 4 &&
                HVE4_PHYTO_I_32 <= HVE4_PHYTO_D_333 + (3 * (HVE4_PHYTO_D_332 - HVE4_PHYTO_D_333)) / 4
              ? 2
              : HVE4_PHYTO_I_32 > HVE4_PHYTO_D_333 + (HVE4_PHYTO_D_332 - HVE4_PHYTO_D_333) / 4 &&
                HVE4_PHYTO_I_32 <= HVE4_PHYTO_D_333 + (2 * (HVE4_PHYTO_D_332 - HVE4_PHYTO_D_333)) / 4
              ? 3
              : HVE4_PHYTO_I_32 > HVE4_PHYTO_D_333 &&
                HVE4_PHYTO_I_32 <= HVE4_PHYTO_D_333 + (HVE4_PHYTO_D_332 - HVE4_PHYTO_D_333) / 4
              ? 4
              : HVE4_PHYTO_I_32 <= HVE4_PHYTO_D_333
              ? 5
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_S_33: {
    columns: ["HVE4_PHYTO_I_33", "HVE4_PHYTO_D_334", "HVE4_PHYTO_D_333"],
    disabled: true,
    computedValue: (state) => {
      const HVE4_PHYTO_D_334 = getValue(state, "HVE4_PHYTO_D_334");
      const HVE4_PHYTO_D_335 = getValue(state, "HVE4_PHYTO_D_335");
      const HVE4_PHYTO_I_33 = getValue(state, "HVE4_PHYTO_I_33");

      return {
        valeur: {
          value:
            HVE4_PHYTO_D_334 == null || HVE4_PHYTO_D_335 == null
              ? 0
              : HVE4_PHYTO_I_33 > HVE4_PHYTO_D_334
              ? 0
              : HVE4_PHYTO_I_33 > HVE4_PHYTO_D_335 + (3 * (HVE4_PHYTO_D_334 - HVE4_PHYTO_D_335)) / 4 &&
                HVE4_PHYTO_I_33 <= HVE4_PHYTO_D_334
              ? 1
              : HVE4_PHYTO_I_33 > HVE4_PHYTO_D_335 + (2 * (HVE4_PHYTO_D_334 - HVE4_PHYTO_D_335)) / 4 &&
                HVE4_PHYTO_I_33 <= HVE4_PHYTO_D_335 + (3 * (HVE4_PHYTO_D_334 - HVE4_PHYTO_D_335)) / 4
              ? 2
              : HVE4_PHYTO_I_33 > HVE4_PHYTO_D_335 + (HVE4_PHYTO_D_334 - HVE4_PHYTO_D_335) / 4 &&
                HVE4_PHYTO_I_33 <= HVE4_PHYTO_D_335 + (2 * (HVE4_PHYTO_D_334 - HVE4_PHYTO_D_335)) / 4
              ? 3
              : HVE4_PHYTO_I_33 > HVE4_PHYTO_D_335 &&
                HVE4_PHYTO_I_33 <= HVE4_PHYTO_D_335 + (HVE4_PHYTO_D_334 - HVE4_PHYTO_D_335) / 4
              ? 4
              : HVE4_PHYTO_I_33 <= HVE4_PHYTO_D_335
              ? 5
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_S_34: {
    columns: ["HVE4_PHYTO_I_34", "HVE4_PHYTO_D_336", "HVE4_PHYTO_D_337"],
    disabled: true,
    computedValue: (state) => {
      const HVE4_PHYTO_D_336 = getValue(state, "HVE4_PHYTO_D_336");
      const HVE4_PHYTO_D_337 = getValue(state, "HVE4_PHYTO_D_337");
      const HVE4_PHYTO_I_34 = getValue(state, "HVE4_PHYTO_I_34");
      return {
        valeur: {
          value:
            HVE4_PHYTO_D_336 == null || HVE4_PHYTO_D_337 == null
              ? 0
              : HVE4_PHYTO_I_34 > HVE4_PHYTO_D_336
              ? 0
              : HVE4_PHYTO_I_34 > HVE4_PHYTO_D_337 + (3 * (HVE4_PHYTO_D_336 - HVE4_PHYTO_D_337)) / 4 &&
                HVE4_PHYTO_I_34 <= HVE4_PHYTO_D_336
              ? 1
              : HVE4_PHYTO_I_34 > HVE4_PHYTO_D_337 + (2 * (HVE4_PHYTO_D_336 - HVE4_PHYTO_D_337)) / 4 &&
                HVE4_PHYTO_I_34 <= HVE4_PHYTO_D_337 + (3 * (HVE4_PHYTO_D_336 - HVE4_PHYTO_D_337)) / 4
              ? 2
              : HVE4_PHYTO_I_34 > HVE4_PHYTO_D_337 + (HVE4_PHYTO_D_336 - HVE4_PHYTO_D_337) / 4 &&
                HVE4_PHYTO_I_34 <= HVE4_PHYTO_D_337 + (2 * (HVE4_PHYTO_D_336 - HVE4_PHYTO_D_337)) / 4
              ? 3
              : HVE4_PHYTO_I_34 > HVE4_PHYTO_D_337 &&
                HVE4_PHYTO_I_34 <= HVE4_PHYTO_D_337 + (HVE4_PHYTO_D_336 - HVE4_PHYTO_D_337) / 4
              ? 4
              : HVE4_PHYTO_I_34 <= HVE4_PHYTO_D_337
              ? 5
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_S_35: {
    columns: ["HVE4_PHYTO_I_35", "HVE4_PHYTO_D_338", "HVE4_PHYTO_D_339"],
    disabled: true,
    computedValue: (state) => {
      const HVE4_PHYTO_D_338 = getValue(state, "HVE4_PHYTO_D_338");
      const HVE4_PHYTO_D_339 = getValue(state, "HVE4_PHYTO_D_339");
      const HVE4_PHYTO_I_35 = getValue(state, "HVE4_PHYTO_I_35");
      return {
        valeur: {
          value:
            HVE4_PHYTO_D_338 == null || HVE4_PHYTO_D_339 == null
              ? 0
              : HVE4_PHYTO_I_35 > HVE4_PHYTO_D_338
              ? 0
              : HVE4_PHYTO_I_35 > HVE4_PHYTO_D_339 + (3 * (HVE4_PHYTO_D_338 - HVE4_PHYTO_D_339)) / 4 &&
                HVE4_PHYTO_I_35 <= HVE4_PHYTO_D_338
              ? 0.6
              : HVE4_PHYTO_I_35 > HVE4_PHYTO_D_339 + (2 * (HVE4_PHYTO_D_338 - HVE4_PHYTO_D_339)) / 4 &&
                HVE4_PHYTO_I_35 <= HVE4_PHYTO_D_339 + (3 * (HVE4_PHYTO_D_338 - HVE4_PHYTO_D_339)) / 4
              ? 1.2
              : HVE4_PHYTO_I_35 > HVE4_PHYTO_D_339 + (HVE4_PHYTO_D_338 - HVE4_PHYTO_D_339) / 4 &&
                HVE4_PHYTO_I_35 <= HVE4_PHYTO_D_339 + (2 * (HVE4_PHYTO_D_338 - HVE4_PHYTO_D_339)) / 4
              ? 1.8
              : HVE4_PHYTO_I_35 > HVE4_PHYTO_D_339 &&
                HVE4_PHYTO_I_35 <= HVE4_PHYTO_D_339 + (HVE4_PHYTO_D_338 - HVE4_PHYTO_D_339) / 4
              ? 2.4
              : HVE4_PHYTO_I_35 <= HVE4_PHYTO_D_339
              ? 3
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_S_36: {
    columns: ["HVE4_PHYTO_I_36", "HVE4_PHYTO_D_340", "HVE4_PHYTO_D_341"],
    disabled: true,
    computedValue: (state) => {
      const HVE4_PHYTO_D_340 = getValue(state, "HVE4_PHYTO_D_340");
      const HVE4_PHYTO_D_341 = getValue(state, "HVE4_PHYTO_D_341");
      const HVE4_PHYTO_I_36 = getValue(state, "HVE4_PHYTO_I_36");
      return {
        valeur: {
          value:
            HVE4_PHYTO_D_340 == null || HVE4_PHYTO_D_341 == null
              ? 0
              : HVE4_PHYTO_I_36 > HVE4_PHYTO_D_340
              ? 0
              : HVE4_PHYTO_I_36 > HVE4_PHYTO_D_341 + (3 * (HVE4_PHYTO_D_340 - HVE4_PHYTO_D_341)) / 4 &&
                HVE4_PHYTO_I_36 <= HVE4_PHYTO_D_340
              ? 0.6
              : HVE4_PHYTO_I_36 > HVE4_PHYTO_D_341 + (2 * (HVE4_PHYTO_D_340 - HVE4_PHYTO_D_341)) / 4 &&
                HVE4_PHYTO_I_36 <= HVE4_PHYTO_D_341 + (3 * (HVE4_PHYTO_D_340 - HVE4_PHYTO_D_341)) / 4
              ? 1.2
              : HVE4_PHYTO_I_36 > HVE4_PHYTO_D_341 + (HVE4_PHYTO_D_340 - HVE4_PHYTO_D_341) / 4 &&
                HVE4_PHYTO_I_36 <= HVE4_PHYTO_D_341 + (2 * (HVE4_PHYTO_D_340 - HVE4_PHYTO_D_341)) / 4
              ? 1.8
              : HVE4_PHYTO_I_36 > HVE4_PHYTO_D_341 &&
                HVE4_PHYTO_I_36 <= HVE4_PHYTO_D_341 + (HVE4_PHYTO_D_340 - HVE4_PHYTO_D_341) / 4
              ? 2.4
              : HVE4_PHYTO_I_36 <= HVE4_PHYTO_D_341
              ? 3
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_D_350: {
    columns: ["HVE4_PHYTO_D_3500", "HVE4_CULT_D_200"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_CULT_D_200") > 0
              ? 100 * (getValueOrZero(state, "HVE4_PHYTO_D_3500") / state.indicateurs.HVE4_CULT_D_200.valeur.value)
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_D_351: {
    columns: ["HVE4_CULT_D_44", "HVE4_CULT_D_200"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_CULT_D_200") > 0
              ? 100 * (state.indicateurs.HVE4_CULT_D_44?.valeur.value / state.indicateurs.HVE4_CULT_D_200.valeur.value)
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_D_352: {
    columns: ["HVE4_CULT_D_45", "HVE4_CULT_D_200"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_CULT_D_200") > 0
              ? 100 * (state.indicateurs.HVE4_CULT_D_45?.valeur.value / state.indicateurs.HVE4_CULT_D_200.valeur.value)
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_S_310: {
    columns: ["HVE4_PHYTO_S_31", "HVE4_PHYTO_D_350"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: (getValueOrZero(state, "HVE4_PHYTO_S_31") * getValueOrZero(state, "HVE4_PHYTO_D_350")) / 100,
        },
      };
    },
  },
  HVE4_PHYTO_S_320: {
    columns: ["HVE4_PHYTO_S_32", "HVE4_PHYTO_D_350"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: (getValueOrZero(state, "HVE4_PHYTO_S_32") * getValueOrZero(state, "HVE4_PHYTO_D_350")) / 100,
        },
      };
    },
  },
  HVE4_PHYTO_S_330: {
    columns: ["HVE4_PHYTO_S_33", "HVE4_PHYTO_D_351"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: (getValueOrZero(state, "HVE4_PHYTO_S_33") * getValueOrZero(state, "HVE4_PHYTO_D_351")) / 100,
        },
      };
    },
  },
  HVE4_PHYTO_S_340: {
    columns: ["HVE4_PHYTO_S_34", "HVE4_PHYTO_D_351"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: (getValueOrZero(state, "HVE4_PHYTO_S_34") * getValueOrZero(state, "HVE4_PHYTO_D_351")) / 100,
        },
      };
    },
  },
  HVE4_PHYTO_S_350: {
    columns: ["HVE4_PHYTO_S_35", "HVE4_PHYTO_D_352"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: (getValueOrZero(state, "HVE4_PHYTO_S_35") * getValueOrZero(state, "HVE4_PHYTO_D_352")) / 100,
        },
      };
    },
  },
  HVE4_PHYTO_S_360: {
    columns: ["HVE4_PHYTO_S_36", "HVE4_PHYTO_D_352"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: (getValueOrZero(state, "HVE4_PHYTO_S_36") * getValueOrZero(state, "HVE4_PHYTO_D_352")) / 100,
        },
      };
    },
  },
  HVE4_PHYTO_S_3: {
    columns: [
      "HVE4_PHYTO_S_310",
      "HVE4_PHYTO_S_320",
      "HVE4_PHYTO_S_330",
      "HVE4_PHYTO_S_340",
      "HVE4_PHYTO_S_350",
      "HVE4_PHYTO_S_360",
      "HVE4_PHYTO_D_10",
      "HVE4_PHYTO_D_101",
    ],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValue(state, "HVE4_PHYTO_D_10") && !getValue(state, "HVE4_PHYTO_D_101")
              ? 0
              : roundFourDecimales(
                  getValueOrZero(state, "HVE4_PHYTO_S_310") +
                    getValueOrZero(state, "HVE4_PHYTO_S_320") +
                    getValueOrZero(state, "HVE4_PHYTO_S_330") +
                    getValueOrZero(state, "HVE4_PHYTO_S_340") +
                    getValueOrZero(state, "HVE4_PHYTO_S_350") +
                    getValueOrZero(state, "HVE4_PHYTO_S_360")
                ),
        },
      };
    },
  },
  HVE4_PHYTO_D_42: {
    columns: ["HVE4_PHYTO_D_40", "HVE4_PHYTO_D_41"],
    disabled: true,
    computedValue: (state) => {
      const nbCampagnes = state.indicateurs["HVE4_PHYTO_D_40"]?.valeur.value;
      return {
        valeur: {
          value:
            nbCampagnes === 1
              ? state.indicateurs["HVE4_PHYTO_D_41"]?.valeur.value.n ?? 0
              : nbCampagnes === 2
              ? (state.indicateurs["HVE4_PHYTO_D_41"]?.valeur.value.n ?? 0) +
                (state.indicateurs["HVE4_PHYTO_D_41"]?.valeur.value.n_1 ?? 0)
              : nbCampagnes >= 3
              ? (state.indicateurs["HVE4_PHYTO_D_41"]?.valeur.value.n ?? 0) +
                (state.indicateurs["HVE4_PHYTO_D_41"]?.valeur.value.n_1 ?? 0) +
                (state.indicateurs["HVE4_PHYTO_D_41"]?.valeur.value.n_2 ?? 0)
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_D_43: {
    columns: ["HVE4_PHYTO_D_40", "HVE4_PHYTO_D_41_PLAFOND"],
    disabled: true,
    computedValue: (state) => {
      const nbCampagnes = state.indicateurs["HVE4_PHYTO_D_40"]?.valeur.value;
      return {
        valeur: {
          value:
            nbCampagnes === 1
              ? state.indicateurs["HVE4_PHYTO_D_41_PLAFOND"]?.valeur.value.n ?? 0
              : nbCampagnes === 2
              ? (state.indicateurs["HVE4_PHYTO_D_41_PLAFOND"]?.valeur.value.n ?? 0) +
                (state.indicateurs["HVE4_PHYTO_D_41_PLAFOND"]?.valeur.value.n_1 ?? 0)
              : nbCampagnes >= 3
              ? (state.indicateurs["HVE4_PHYTO_D_41_PLAFOND"]?.valeur.value.n ?? 0) +
                (state.indicateurs["HVE4_PHYTO_D_41_PLAFOND"]?.valeur.value.n_1 ?? 0) +
                (state.indicateurs["HVE4_PHYTO_D_41_PLAFOND"]?.valeur.value.n_2 ?? 0)
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_D_44: {
    columns: ["HVE4_PHYTO_D_40", "HVE4_PHYTO_D_41_PLANCHER"],
    disabled: true,
    computedValue: (state) => {
      const nbCampagnes = state.indicateurs["HVE4_PHYTO_D_40"]?.valeur.value;
      return {
        valeur: {
          value:
            nbCampagnes === 1
              ? state.indicateurs["HVE4_PHYTO_D_41_PLANCHER"]?.valeur.value.n ?? 0
              : nbCampagnes === 2
              ? (state.indicateurs["HVE4_PHYTO_D_41_PLANCHER"]?.valeur.value.n ?? 0) +
                (state.indicateurs["HVE4_PHYTO_D_41_PLANCHER"]?.valeur.value.n_1 ?? 0)
              : nbCampagnes >= 3
              ? (state.indicateurs["HVE4_PHYTO_D_41_PLANCHER"]?.valeur.value.n ?? 0) +
                (state.indicateurs["HVE4_PHYTO_D_41_PLANCHER"]?.valeur.value.n_1 ?? 0) +
                (state.indicateurs["HVE4_PHYTO_D_41_PLANCHER"]?.valeur.value.n_2 ?? 0)
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_D_45: {
    columns: ["HVE4_CULT_D_70", "HVE4_CULT_D_200"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_CULT_D_200") > 0
              ? (getValueOrZero(state, "HVE4_CULT_D_70") / getValueOrZero(state, "HVE4_CULT_D_200")) * 100
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_S_41: {
    columns: ["HVE4_PHYTO_S_40", "HVE4_PHYTO_D_45", "HVE4_PHYTO_D_10", "HVE4_PHYTO_D_101"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValue(state, "HVE4_PHYTO_D_10") && !getValue(state, "HVE4_PHYTO_D_101")
              ? 0
              : roundFourDecimales(
                  (getValueOrZero(state, "HVE4_PHYTO_S_40") * getValueOrZero(state, "HVE4_PHYTO_D_45")) / 100
                ),
        },
      };
    },
  },
  HVE4_PHYTO_S_50: {
    columns: ["HVE4_PHYTO_I_50"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: getValue(state, "HVE4_PHYTO_I_50") ? 1 : 0,
        },
      };
    },
  },
  HVE4_PHYTO_S_51: {
    columns: ["HVE4_PHYTO_I_51"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: getValue(state, "HVE4_PHYTO_I_51") ? 1 : 0,
        },
      };
    },
  },
  HVE4_PHYTO_S_52: {
    columns: ["HVE4_PHYTO_I_52"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: getValue(state, "HVE4_PHYTO_I_52") <= 50 ? 0 : 1,
        },
      };
    },
  },
  HVE4_PHYTO_S_53: {
    columns: ["HVE4_PHYTO_I_53"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: getValue(state, "HVE4_PHYTO_I_53") <= 75 ? 0 : 1,
        },
      };
    },
  },
  HVE4_PHYTO_S_56: {
    columns: ["HVE4_PHYTO_S_52", "HVE4_PHYTO_S_53"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: getValue(state, "HVE4_PHYTO_S_52") + getValue(state, "HVE4_PHYTO_S_53") === 0 ? 0 : 2,
        },
      };
    },
  },
  HVE4_PHYTO_S_57: {
    columns: ["HVE4_PHYTO_D_50", "HVE4_PHYTO_D_51", "HVE4_PHYTO_S_56", "HVE4_PHYTO_D_540"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            !getValue(state, "HVE4_PHYTO_D_50") && !getValue(state, "HVE4_PHYTO_D_51")
              ? 0
              : (getValue(state, "HVE4_PHYTO_S_56") * getValue(state, "HVE4_PHYTO_D_540")) / 100,
        },
      };
    },
  },
  HVE4_PHYTO_D_52: {
    columns: ["HVE4_PHYTO_D_50"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: !getValue(state, "HVE4_PHYTO_D_50") ? null : getValue(state, "HVE4_PHYTO_D_52"),
        },
      };
    },
  },
  HVE4_PHYTO_D_53: {
    columns: ["HVE4_PHYTO_D_51"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: !getValue(state, "HVE4_PHYTO_D_51") ? null : getValue(state, "HVE4_PHYTO_D_53"),
        },
      };
    },
  },
  HVE4_PHYTO_S_58: {
    columns: ["HVE4_PHYTO_S_57", "HVE4_PHYTO_S_50", "HVE4_PHYTO_S_51", "HVE4_PHYTO_D_10", "HVE4_PHYTO_D_101"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValue(state, "HVE4_PHYTO_D_10") && !getValue(state, "HVE4_PHYTO_D_101")
              ? 0
              : roundFourDecimales(
                  Math.min(
                    getValue(state, "HVE4_PHYTO_S_57") +
                      getValue(state, "HVE4_PHYTO_S_50") +
                      getValue(state, "HVE4_PHYTO_S_51"),
                    3
                  )
                ),
        },
      };
    },
  },
  HVE4_PHYTO_D_541: {
    columns: [
      "HVE4_CULT_D_8",
      "HVE4_CULT_D_13",
      "HVE4_CULT_D_18",
      "HVE4_CULT_D_19",
      "HVE4_CULT_D_25",
      "HVE4_CULT_D_37",
      "HVE4_CULT_D_43",
    ],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS_PHYTO.HVE4_PHYTO_D_541.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE4_PHYTO_I_52: {
    columns: ["HVE4_PHYTO_D_50", "HVE4_PHYTO_D_52", "HVE4_PHYTO_D_541"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: !getValue(state, "HVE4_PHYTO_D_50")
            ? null
            : getValueOrZero(state, "HVE4_PHYTO_D_541") !== 0
            ? (getValueOrZero(state, "HVE4_PHYTO_D_52") / getValueOrZero(state, "HVE4_PHYTO_D_541")) * 100
            : 0,
        },
      };
    },
  },
  HVE4_PHYTO_I_53: {
    columns: ["HVE4_PHYTO_D_51", "HVE4_PHYTO_D_53", "HVE4_CULT_D_47"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: !getValue(state, "HVE4_PHYTO_D_51")
          ? null
          : getValueOrZero(state, "HVE4_CULT_D_47") !== 0
          ? (getValueOrZero(state, "HVE4_PHYTO_D_53") / getValueOrZero(state, "HVE4_CULT_D_47")) * 100
          : 0,
      },
    }),
  },
  HVE4_PHYTO_D_540: {
    columns: ["HVE4_PHYTO_D_54", "HVE4_CULT_D_200"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_CULT_D_200") !== 0
              ? 100 * (getValueOrZero(state, "HVE4_PHYTO_D_54") / getValueOrZero(state, "HVE4_CULT_D_200"))
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_D_54: {
    columns: ["HVE4_PHYTO_D_52", "HVE4_PHYTO_D_53"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: getValueOrZero(state, "HVE4_PHYTO_D_52") + getValueOrZero(state, "HVE4_PHYTO_D_53"),
      },
    }),
  },
  HVE4_PHYTO_I_61: {
    columns: ["HVE4_PHYTO_D_61", "HVE4_CULT_D_200", "HVE4_CULT_D_70"],
    disabled: true,
    computedValue: (state) => {
      const diviseur = getValueOrZero(state, "HVE4_CULT_D_200") - getValueOrZero(state, "HVE4_CULT_D_70");
      return {
        valeur: {
          value: diviseur > 0 ? 100 * (getValueOrZero(state, "HVE4_PHYTO_D_61") / diviseur) : 0,
        },
      };
    },
  },
  HVE4_PHYTO_S_61: {
    columns: ["HVE4_PHYTO_I_61", "HVE4_CULT_D_70"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_PHYTO_I_61") < 25
              ? 0
              : getValueOrZero(state, "HVE4_PHYTO_I_61") >= 25 && getValueOrZero(state, "HVE4_PHYTO_I_61") < 37.5
              ? 1
              : getValueOrZero(state, "HVE4_PHYTO_I_61") >= 37.5 && getValueOrZero(state, "HVE4_PHYTO_I_61") < 50
              ? 1.5
              : getValueOrZero(state, "HVE4_PHYTO_I_61") >= 50 && getValueOrZero(state, "HVE4_PHYTO_I_61") < 62.5
              ? 2
              : getValueOrZero(state, "HVE4_PHYTO_I_61") >= 62.5 && getValueOrZero(state, "HVE4_PHYTO_I_61") < 75
              ? 2.5
              : getValueOrZero(state, "HVE4_PHYTO_I_61") >= 75
              ? 3
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_D_65: {
    columns: ["HVE4_PHYTO_D_61", "HVE4_CULT_D_200", "HVE4_CULT_D_70"],
    disabled: true,
    computedValue: (state) => {
      const HVE4_CULT_D_200 = getValueOrZero(state, "HVE4_CULT_D_200");
      return {
        valeur: {
          value:
            HVE4_CULT_D_200 && HVE4_CULT_D_200 - getValueOrZero(state, "HVE4_CULT_D_70") > 0
              ? 100 * ((HVE4_CULT_D_200 - getValueOrZero(state, "HVE4_CULT_D_70")) / HVE4_CULT_D_200)
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_S_63: {
    columns: ["HVE4_PHYTO_S_61", "HVE4_PHYTO_D_65"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: (getValueOrZero(state, "HVE4_PHYTO_S_61") * getValueOrZero(state, "HVE4_PHYTO_D_65")) / 100,
        },
      };
    },
  },
  HVE4_PHYTO_I_62: {
    columns: ["HVE4_PHYTO_D_62", "HVE4_CULT_D_70"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: (getValueOrZero(state, "HVE4_PHYTO_D_62") / getValueOrZero(state, "HVE4_CULT_D_70")) * 100,
        },
      };
    },
  },
  HVE4_PHYTO_S_62: {
    columns: ["HVE4_PHYTO_I_62"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_PHYTO_I_62") < 25
              ? 0
              : getValueOrZero(state, "HVE4_PHYTO_I_62") >= 25 && getValueOrZero(state, "HVE4_PHYTO_I_62") < 50
              ? 2
              : getValueOrZero(state, "HVE4_PHYTO_I_62") >= 50 && getValueOrZero(state, "HVE4_PHYTO_I_62") < 75
              ? 4
              : getValueOrZero(state, "HVE4_PHYTO_I_62") >= 75
              ? 6
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_D_66: {
    columns: ["HVE4_CULT_D_70", "HVE4_CULT_D_200"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_CULT_D_200") > 0
              ? (getValueOrZero(state, "HVE4_CULT_D_70") / getValueOrZero(state, "HVE4_CULT_D_200")) * 100
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_S_64: {
    columns: ["HVE4_PHYTO_S_62", "HVE4_PHYTO_D_66"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: (getValueOrZero(state, "HVE4_PHYTO_S_62") * getValueOrZero(state, "HVE4_PHYTO_D_66")) / 100,
        },
      };
    },
  },
  HVE4_PHYTO_S_65: {
    columns: ["HVE4_PHYTO_S_63", "HVE4_PHYTO_S_64", "HVE4_PHYTO_D_10", "HVE4_PHYTO_D_101"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValue(state, "HVE4_PHYTO_D_10") && !getValue(state, "HVE4_PHYTO_D_101")
              ? 0
              : roundFourDecimales(getValueOrZero(state, "HVE4_PHYTO_S_63") + getValueOrZero(state, "HVE4_PHYTO_S_64")),
        },
      };
    },
  },
  HVE4_PHYTO_S_80: {
    columns: ["HVE4_PHYTO_I_80"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_PHYTO_I_80") <= 1
              ? 0
              : getValueOrZero(state, "HVE4_PHYTO_I_80") === 2
              ? 1
              : getValueOrZero(state, "HVE4_PHYTO_I_80") > 2
              ? 2
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_D_800: {
    columns: ["HVE4_CULT_D_44", "HVE4_CULT_D_200"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_CULT_D_200") > 0
              ? 100 * (getValueOrZero(state, "HVE4_CULT_D_44") / getValueOrZero(state, "HVE4_CULT_D_200"))
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_S_800: {
    columns: ["HVE4_PHYTO_S_80", "HVE4_PHYTO_D_800"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: (getValueOrZero(state, "HVE4_PHYTO_S_80") * getValueOrZero(state, "HVE4_PHYTO_D_800")) / 100,
        },
      };
    },
  },
  HVE4_PHYTO_I_81: {
    columns: ["HVE4_PHYTO_D_81", "HVE4_PHYTO_D_82"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: Math.min(getValueOrZero(state, "HVE4_PHYTO_D_81") + 2 * getValueOrZero(state, "HVE4_PHYTO_D_82"), 6),
        },
      };
    },
  },
  HVE4_PHYTO_I_82: {
    columns: ["HVE4_PHYTO_D_83", "HVE4_PHYTO_D_84"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: Math.min(getValueOrZero(state, "HVE4_PHYTO_D_83") + 2 * getValueOrZero(state, "HVE4_PHYTO_D_84"), 6),
        },
      };
    },
  },
  HVE4_PHYTO_I_83: {
    columns: ["HVE4_PHYTO_D_85", "HVE4_PHYTO_D_86"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: Math.min(getValueOrZero(state, "HVE4_PHYTO_D_85") + 2 * getValueOrZero(state, "HVE4_PHYTO_D_86"), 6),
        },
      };
    },
  },
  HVE4_PHYTO_S_81: {
    columns: ["HVE4_PHYTO_I_81"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_PHYTO_I_81") === 0
              ? 0
              : getValueOrZero(state, "HVE4_PHYTO_I_81") === 1
              ? 1
              : getValueOrZero(state, "HVE4_PHYTO_I_81") === 2
              ? 2
              : getValueOrZero(state, "HVE4_PHYTO_I_81") === 3
              ? 3
              : getValueOrZero(state, "HVE4_PHYTO_I_81") === 4
              ? 4
              : getValueOrZero(state, "HVE4_PHYTO_I_81") === 5
              ? 5
              : getValueOrZero(state, "HVE4_PHYTO_I_81") >= 6
              ? 6
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_S_82: {
    columns: ["HVE4_PHYTO_I_82"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_PHYTO_I_82") === 0
              ? 0
              : getValueOrZero(state, "HVE4_PHYTO_I_82") === 1
              ? 1
              : getValueOrZero(state, "HVE4_PHYTO_I_82") === 2
              ? 2
              : getValueOrZero(state, "HVE4_PHYTO_I_82") === 3
              ? 3
              : getValueOrZero(state, "HVE4_PHYTO_I_82") === 4
              ? 4
              : getValueOrZero(state, "HVE4_PHYTO_I_82") === 5
              ? 5
              : getValueOrZero(state, "HVE4_PHYTO_I_82") >= 6
              ? 6
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_S_83: {
    columns: ["HVE4_PHYTO_I_83"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_PHYTO_I_83") === 0
              ? 0
              : getValueOrZero(state, "HVE4_PHYTO_I_83") === 1
              ? 1
              : getValueOrZero(state, "HVE4_PHYTO_I_83") === 2
              ? 2
              : getValueOrZero(state, "HVE4_PHYTO_I_83") === 3
              ? 3
              : getValueOrZero(state, "HVE4_PHYTO_I_83") === 4
              ? 4
              : getValueOrZero(state, "HVE4_PHYTO_I_83") === 5
              ? 5
              : getValueOrZero(state, "HVE4_PHYTO_I_83") >= 6
              ? 6
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_D_810: {
    columns: ["HVE4_CULT_D_45", "HVE4_CULT_D_46", "HVE4_CULT_D_200"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_CULT_D_200") > 0
              ? 100 *
                ((getValueOrZero(state, "HVE4_CULT_D_45") + getValueOrZero(state, "HVE4_CULT_D_46")) /
                  getValueOrZero(state, "HVE4_CULT_D_200"))
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_D_820: {
    columns: ["HVE4_CULT_D_39", "HVE4_CULT_D_40", "HVE4_CULT_D_41", "HVE4_CULT_D_200"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_CULT_D_200") > 0
              ? (100 *
                  (getValueOrZero(state, "HVE4_CULT_D_39") +
                    getValueOrZero(state, "HVE4_CULT_D_40") +
                    getValueOrZero(state, "HVE4_CULT_D_41"))) /
                getValueOrZero(state, "HVE4_CULT_D_200")
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_D_830: {
    columns: ["HVE4_CULT_D_42", "HVE4_CULT_D_200"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_CULT_D_200") > 0
              ? (100 * getValueOrZero(state, "HVE4_CULT_D_42")) / getValueOrZero(state, "HVE4_CULT_D_200")
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_S_810: {
    columns: ["HVE4_PHYTO_S_81", "HVE4_PHYTO_D_810"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: (getValueOrZero(state, "HVE4_PHYTO_S_81") * getValueOrZero(state, "HVE4_PHYTO_D_810")) / 100,
        },
      };
    },
  },
  HVE4_PHYTO_S_820: {
    columns: ["HVE4_PHYTO_S_82", "HVE4_PHYTO_D_820"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: (getValueOrZero(state, "HVE4_PHYTO_S_82") * getValueOrZero(state, "HVE4_PHYTO_D_820")) / 100,
        },
      };
    },
  },
  HVE4_PHYTO_S_830: {
    columns: ["HVE4_PHYTO_S_83", "HVE4_PHYTO_D_830"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: (getValueOrZero(state, "HVE4_PHYTO_S_83") * getValueOrZero(state, "HVE4_PHYTO_D_830")) / 100,
        },
      };
    },
  },
  HVE4_PHYTO_S_840: {
    columns: [
      "HVE4_PHYTO_S_800",
      "HVE4_PHYTO_S_810",
      "HVE4_PHYTO_S_820",
      "HVE4_PHYTO_S_830",
      "HVE4_PHYTO_D_10",
      "HVE4_PHYTO_D_101",
    ],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValue(state, "HVE4_PHYTO_D_10") && !getValue(state, "HVE4_PHYTO_D_101")
              ? 0
              : roundFourDecimales(
                  getValueOrZero(state, "HVE4_PHYTO_S_800") +
                    getValueOrZero(state, "HVE4_PHYTO_S_810") +
                    getValueOrZero(state, "HVE4_PHYTO_S_820") +
                    getValueOrZero(state, "HVE4_PHYTO_S_830")
                ),
        },
      };
    },
  },
  HVE4_PHYTO_S_70: {
    columns: ["HVE4_PHYTO_I_70", "HVE4_PHYTO_D_10", "HVE4_PHYTO_D_101"],
    disabled: true,
    computedValue: (state) => {
      const HVE4_I_70 = getValueOrZero(state, "HVE4_PHYTO_I_70");
      return {
        valeur: {
          value:
            getValue(state, "HVE4_PHYTO_D_10") && !getValue(state, "HVE4_PHYTO_D_101")
              ? 0
              : HVE4_I_70 === 0
              ? 0
              : HVE4_I_70 === 1
              ? 1
              : HVE4_I_70 >= 2
              ? 2
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_I_90: {
    columns: ["HVE4_CULT_D_44", "HVE4_PHYTO_D_90"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_CULT_D_44") > 0
              ? 100 * (getValueOrZero(state, "HVE4_PHYTO_D_90") / getValueOrZero(state, "HVE4_CULT_D_44"))
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_I_91: {
    columns: ["HVE4_CULT_D_45", "HVE4_CULT_D_46", "HVE4_PHYTO_D_91"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_CULT_D_45") + getValueOrZero(state, "HVE4_CULT_D_46") > 0
              ? 100 *
                (getValueOrZero(state, "HVE4_PHYTO_D_91") /
                  (getValueOrZero(state, "HVE4_CULT_D_45") + getValueOrZero(state, "HVE4_CULT_D_46")))
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_I_92: {
    columns: ["HVE4_CULT_D_69", "HVE4_CULT_D_70", "HVE4_PHYTO_D_92"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_CULT_D_70") - getValueOrZero(state, "HVE4_CULT_D_69") > 0
              ? (100 * getValueOrZero(state, "HVE4_PHYTO_D_92")) /
                (getValueOrZero(state, "HVE4_CULT_D_70") - getValueOrZero(state, "HVE4_CULT_D_69"))
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_S_90: {
    columns: ["HVE4_PHYTO_I_90"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_PHYTO_I_90") < 50
              ? 0
              : getValueOrZero(state, "HVE4_PHYTO_I_90") >= 50 && getValueOrZero(state, "HVE4_PHYTO_I_90") < 75
              ? 1
              : getValueOrZero(state, "HVE4_PHYTO_I_90") >= 75 && getValueOrZero(state, "HVE4_PHYTO_I_90") < 100
              ? 2
              : getValueOrZero(state, "HVE4_PHYTO_I_90") >= 100
              ? 3
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_S_91: {
    columns: ["HVE4_PHYTO_I_91"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_PHYTO_I_91") < 50
              ? 0
              : getValueOrZero(state, "HVE4_PHYTO_I_91") >= 50 && getValueOrZero(state, "HVE4_PHYTO_I_91") < 75
              ? 1
              : getValueOrZero(state, "HVE4_PHYTO_I_91") >= 75 && getValueOrZero(state, "HVE4_PHYTO_I_91") < 100
              ? 2
              : getValueOrZero(state, "HVE4_PHYTO_I_91") >= 100
              ? 3
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_S_92: {
    columns: ["HVE4_PHYTO_I_92"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_PHYTO_I_92") < 50
              ? 0
              : getValueOrZero(state, "HVE4_PHYTO_I_92") >= 50 && getValueOrZero(state, "HVE4_PHYTO_I_92") < 75
              ? 1
              : getValueOrZero(state, "HVE4_PHYTO_I_92") >= 75 && getValueOrZero(state, "HVE4_PHYTO_I_92") < 100
              ? 2
              : getValueOrZero(state, "HVE4_PHYTO_I_92") >= 100
              ? 3
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_S_900: {
    columns: ["HVE4_PHYTO_D_800", "HVE4_PHYTO_S_90"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: (getValueOrZero(state, "HVE4_PHYTO_S_90") * getValueOrZero(state, "HVE4_PHYTO_D_800")) / 100,
        },
      };
    },
  },
  HVE4_PHYTO_S_910: {
    columns: ["HVE4_PHYTO_D_810", "HVE4_PHYTO_S_91"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: (getValueOrZero(state, "HVE4_PHYTO_S_91") * getValueOrZero(state, "HVE4_PHYTO_D_810")) / 100,
        },
      };
    },
  },
  HVE4_PHYTO_D_920: {
    columns: ["HVE4_CULT_D_70", "HVE4_CULT_D_69", "HVE4_CULT_D_200"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_CULT_D_200") > 0
              ? 100 *
                ((getValueOrZero(state, "HVE4_CULT_D_70") - getValueOrZero(state, "HVE4_CULT_D_69")) /
                  getValueOrZero(state, "HVE4_CULT_D_200"))
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_S_920: {
    columns: ["HVE4_PHYTO_D_920", "HVE4_PHYTO_S_92"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: (getValueOrZero(state, "HVE4_PHYTO_S_92") * getValueOrZero(state, "HVE4_PHYTO_D_920")) / 100,
        },
      };
    },
  },
  HVE4_PHYTO_S_930: {
    columns: ["HVE4_PHYTO_S_900", "HVE4_PHYTO_S_910", "HVE4_PHYTO_S_920", "HVE4_PHYTO_D_10", "HVE4_PHYTO_D_101"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValue(state, "HVE4_PHYTO_D_10") && !getValue(state, "HVE4_PHYTO_D_101")
              ? 0
              : roundFourDecimales(
                  getValueOrZero(state, "HVE4_PHYTO_S_900") +
                    getValueOrZero(state, "HVE4_PHYTO_S_910") +
                    getValueOrZero(state, "HVE4_PHYTO_S_920")
                ),
        },
      };
    },
  },
  HVE4_PHYTO_I_100: {
    columns: ["HVE4_PHYTO_D_101_1", "HVE4_PHYTO_D_100"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_PHYTO_D_100") > 0
              ? 100 * (getValueOrZero(state, "HVE4_PHYTO_D_101_1") / getValueOrZero(state, "HVE4_PHYTO_D_100"))
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_S_100: {
    columns: ["HVE4_PHYTO_I_100"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_PHYTO_I_100") === 0
              ? 0
              : getValueOrZero(state, "HVE4_PHYTO_I_100") <= 10
              ? 1
              : getValueOrZero(state, "HVE4_PHYTO_I_100") <= 20
              ? 2
              : getValueOrZero(state, "HVE4_PHYTO_I_100") <= 30
              ? 3
              : getValueOrZero(state, "HVE4_PHYTO_I_100") <= 40
              ? 4
              : getValueOrZero(state, "HVE4_PHYTO_I_100") <= 50
              ? 5
              : getValueOrZero(state, "HVE4_PHYTO_I_100") <= 60
              ? 6
              : getValueOrZero(state, "HVE4_PHYTO_I_100") <= 70
              ? 7
              : getValueOrZero(state, "HVE4_PHYTO_I_100") <= 80
              ? 8
              : getValueOrZero(state, "HVE4_PHYTO_I_100") <= 90
              ? 9
              : getValueOrZero(state, "HVE4_PHYTO_I_100") <= 100
              ? 10
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_D_1000: {
    columns: ["HVE4_CULT_D_42", "HVE4_CULT_D_69", "HVE4_CULT_D_200"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_CULT_D_200") <= 0 ||
            getValueOrZero(state, "HVE4_CULT_D_42") - getValueOrZero(state, "HVE4_CULT_D_69") <= 0
              ? 0
              : 100 *
                ((getValueOrZero(state, "HVE4_CULT_D_42") - getValueOrZero(state, "HVE4_CULT_D_69")) /
                  getValueOrZero(state, "HVE4_CULT_D_200")),
        },
      };
    },
  },
  HVE4_PHYTO_D_1001: {
    columns: ["HVE4_CULT_D_69", "HVE4_CULT_D_200"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_CULT_D_200") > 0
              ? 100 * (getValueOrZero(state, "HVE4_CULT_D_69") / getValueOrZero(state, "HVE4_CULT_D_200"))
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_S_1001: {
    columns: ["HVE4_PHYTO_S_100", "HVE4_PHYTO_D_1000"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: (getValueOrZero(state, "HVE4_PHYTO_S_100") * getValueOrZero(state, "HVE4_PHYTO_D_1000")) / 100,
        },
      };
    },
  },
  HVE4_PHYTO_S_1002: {
    columns: ["HVE4_PHYTO_S_102", "HVE4_PHYTO_D_1001"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: (getValueOrZero(state, "HVE4_PHYTO_S_102") * getValueOrZero(state, "HVE4_PHYTO_D_1001")) / 100,
        },
      };
    },
  },
  HVE4_PHYTO_S_1003: {
    columns: ["HVE4_PHYTO_S_103", "HVE4_PHYTO_D_1001"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: (getValueOrZero(state, "HVE4_PHYTO_S_103") * getValueOrZero(state, "HVE4_PHYTO_D_1001")) / 100,
        },
      };
    },
  },
  HVE4_PHYTO_I_102: {
    columns: ["HVE4_CULT_D_69", "HVE4_PHYTO_D_102"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_CULT_D_69") > 0
              ? 100 * (getValueOrZero(state, "HVE4_PHYTO_D_102") / getValueOrZero(state, "HVE4_CULT_D_69"))
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_I_103: {
    columns: ["HVE4_CULT_D_69", "HVE4_PHYTO_D_103"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_CULT_D_69") > 0
              ? 100 * (getValueOrZero(state, "HVE4_PHYTO_D_103") / getValueOrZero(state, "HVE4_CULT_D_69"))
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_S_102: {
    columns: ["HVE4_PHYTO_I_102"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_PHYTO_I_102") === 0
              ? 0
              : getValueOrZero(state, "HVE4_PHYTO_I_102") < 50 && getValueOrZero(state, "HVE4_PHYTO_I_102") > 0
              ? 2
              : getValueOrZero(state, "HVE4_PHYTO_I_102") >= 50 && getValueOrZero(state, "HVE4_PHYTO_I_102") < 75
              ? 4
              : getValueOrZero(state, "HVE4_PHYTO_I_102") >= 75
              ? 6
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_S_103: {
    columns: ["HVE4_PHYTO_I_103"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_PHYTO_I_103") === 0
              ? 0
              : getValueOrZero(state, "HVE4_PHYTO_I_103") < 50
              ? 1
              : getValueOrZero(state, "HVE4_PHYTO_I_103") >= 50 && getValueOrZero(state, "HVE4_PHYTO_I_103") < 75
              ? 2
              : getValueOrZero(state, "HVE4_PHYTO_I_103") >= 75
              ? 3
              : 0,
        },
      };
    },
  },
  HVE4_PHYTO_S_1004: {
    columns: ["HVE4_PHYTO_S_1001", "HVE4_PHYTO_S_1002", "HVE4_PHYTO_S_1003", "HVE4_PHYTO_D_10", "HVE4_PHYTO_D_101"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValue(state, "HVE4_PHYTO_D_10") && !getValue(state, "HVE4_PHYTO_D_101")
              ? 0
              : roundFourDecimales(
                  getValueOrZero(state, "HVE4_PHYTO_S_1001") +
                    getValueOrZero(state, "HVE4_PHYTO_S_1002") +
                    getValueOrZero(state, "HVE4_PHYTO_S_1003")
                ),
        },
      };
    },
  },
  HVE4_PHYTO_S_1100: {
    columns: [
      "HVE4_PHYTO_S_13",
      "HVE4_PHYTO_S_20",
      "HVE4_PHYTO_S_3",
      "HVE4_PHYTO_S_41",
      "HVE4_PHYTO_S_58",
      "HVE4_PHYTO_S_65",
      "HVE4_PHYTO_S_70",
      "HVE4_PHYTO_S_840",
      "HVE4_PHYTO_S_930",
      "HVE4_PHYTO_S_1004",
    ],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: {
            commentaire: getIndicOrNull(state, "HVE4_PHYTO_S_1100").value.commentaire ?? null,
            scoreTotal: roundFourDecimales(
              COMPUTED_FIELDS_PHYTO.HVE4_PHYTO_S_1100.columns.reduce(
                (sum, codeNational) => sum + getValueOrZero(state, codeNational),
                0
              )
            ),
          },
        },
      };
    },
  },
  HVE4_PHYTO_D_64: {
    columns: ["HVE4_CULT_D_200", "HVE4_CULT_D_70"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_CULT_D_200") - getValueOrZero(state, "HVE4_CULT_D_70") > 0
              ? getValueOrZero(state, "HVE4_CULT_D_200") - getValueOrZero(state, "HVE4_CULT_D_70")
              : 0,
        },
      };
    },
  },
  // FIN RUBRIQUE PHYTO
};
const getValueOrZero = (state, codeNational) => {
  return state.indicateurs[codeNational] && !isNaN(Number(state.indicateurs[codeNational].valeur.value))
    ? Number(state.indicateurs[codeNational].valeur.value)
    : 0;
};

const getIndicOrNull = (state, codeNational) => {
  return state.indicateurs[codeNational] && state.indicateurs[codeNational] !== undefined
    ? state.indicateurs[codeNational].valeur
    : null;
};

const getValue = (state, codeNational) => {
  return state.indicateurs[codeNational]?.valeur?.value;
};

const roundFourDecimales = (num) => {
  return Math.round((num + Number.EPSILON) * 10000) / 10000;
};

const exports = {
  COMPUTED_FIELDS_PHYTO: COMPUTED_FIELDS_PHYTO,
};
export default exports;
