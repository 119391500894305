import React from "react";
import { withFormik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import { CircularProgress, Link, Typography, Button } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { isEmail } from "../common/validators";
import { Link as RouterLink, withRouter } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../common/codes";
import { withTheme } from "@material-ui/core/styles";

const mapStateToProps = (state) => ({
  config: state.config,
});

class EmailForm extends React.Component {
  constructor(props) {
    super(props);
    this.recaptchaRef = React.createRef();
  }

  onSubmit = async (e) => {
    e.preventDefault();
    const token = await this.recaptchaRef.current.executeAsync();
    this.props.setFieldValue("recaptcha", token);
    this.props.handleSubmit(e);
  };

  render() {
    const { t, isSubmitting, isValid, theme } = this.props;

    return (
      <div className="w-100 d-flex flex-column align-items-center">
        <Typography variant="h3" className="mt-1">
          {t("login.reset-password.form-title", "Mot de passe oublié")}
        </Typography>
        <Form onSubmit={this.onSubmit} className="w-100">
          <Field
            component={TextField}
            name="email"
            type="email"
            label={t("register.email", "Email :")}
            variant="outlined"
            className="mt-2"
            required
            fullWidth
          />
          <ReCAPTCHA ref={this.recaptchaRef} size="invisible" sitekey={RECAPTCHA_SITE_KEY} theme={theme.palette.type} />
          <Button
            disabled={!isValid || isSubmitting}
            color="primary"
            variant="contained"
            className="mt-2"
            type="submit"
            fullWidth
          >
            {isSubmitting ? <CircularProgress size={24} /> : t("register.validate", "Valider")}
          </Button>
        </Form>
        <Link component={RouterLink} to="/login" className="mt-1">
          {t("register.login", "Connexion")}
        </Link>
      </div>
    );
  }
}

const validate = (values, props) => {
  const { t } = props;
  const errors = {};

  if (!values.email) {
    errors.email = t("register.error.required", "Champ obligatoire");
  } else if (!isEmail(values.email)) {
    errors.email = t("register.error.invalid-email", "L'adresse email est invalide");
  }

  return errors;
};

const mapPropsToValues = () => ({
  email: "",
});

const handleSubmit = async (values, { props, setSubmitting }) => {
  await props.onSubmit(values);
  setSubmitting(false);
};

export default connect(mapStateToProps)(
  withRouter(
    withTheme(
      withTranslation()(
        withFormik({
          mapPropsToValues: mapPropsToValues,
          validate: validate,
          handleSubmit: handleSubmit,
        })(EmailForm)
      )
    )
  )
);
