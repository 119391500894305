import React from "react";
import { withTranslation } from "react-i18next";
import network from "../../../actions/external/network";
import TransferList from "./TransferList";
import { Box, Button, Typography } from "@material-ui/core";
import ExploitationSearch from "../../common/ExploitationSearch";
import { withRouter } from "react-router-dom";
import { toastr } from "react-redux-toastr";

class ParExploitation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      exploitation: null,
      listeUtilisateurs: [],
      exploitationUtilisateurs: [],
      loading: false,
    };
  }

  componentDidMount() {
    if (this.props.idExploitation != null) {
      this.loadExploitation(this.props.idExploitation);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.exploitation?.idExploitation !== this.state.exploitation?.idExploitation) {
      this.loadExploitationUtilisateurs();
    }
  }

  loadExploitation = async (idExploitation) => {
    const { t } = this.props;
    try {
      const exploitation = await network.fetch(`/api/exploitations/${idExploitation}`);
      this.onChangeExploitation({ value: exploitation });
    } catch (error) {
      toastr.error(
        t("error", "Erreur"),
        t("admin.acces.load-farm-error", "Erreur lors du chargement de l'exploitation")
      );
    }
  };

  onChangeExploitation = ({ value }) => {
    this.setState({ exploitation: value });
  };

  onChangeSearchUtilisateur = async (search) => {
    if (search.length < 3) {
      this.setState({ listeUtilisateurs: [] });
    } else {
      try {
        this.setState({ loading: true });
        const urlSearchParams = new URLSearchParams([
          ["search", search],
          ["searchFields", "prenom"],
          ["searchFields", "nom"],
          ["orderBy", "prenom"],
          ["orderBy", "nom"],
        ]);
        const { data } = await network.fetch(`/api/utilisateurs?${urlSearchParams}`);
        this.setState({
          listeUtilisateurs: data,
          loading: false,
        });
      } catch (error) {
        this.setState({ loading: false });
      }
    }
  };

  loadExploitationUtilisateurs = async () => {
    if (!this.state.exploitation) {
      this.setState({ exploitationUtilisateurs: [] });
    } else {
      try {
        const utilisateurs = await network.fetch(
          `/api/exploitations/${this.state.exploitation.idExploitation}/utilisateurs`
        );
        this.setState({ exploitationUtilisateurs: utilisateurs });
      } catch (error) {}
    }
  };

  onAddUtilisateur = (idsUtilisateurs) => {
    const utilisateurs = this.state.listeUtilisateurs.filter((utilisateur) =>
      idsUtilisateurs.includes(utilisateur.idUtilisateur)
    );
    this.setState((prevState) => ({
      exploitationUtilisateurs: [...prevState.exploitationUtilisateurs, ...utilisateurs],
    }));
  };

  onRemoveUtilisateur = (idsUtilisateurs) => {
    const newSelection = this.state.exploitationUtilisateurs.filter(
      (utilisateur) => !idsUtilisateurs.includes(utilisateur.idUtilisateur)
    );
    this.setState({ exploitationUtilisateurs: newSelection });
  };

  getLeftList = () => {
    return this.state.listeUtilisateurs.map((utilisateur) => ({
      key: utilisateur.idUtilisateur,
      value: utilisateur.prenom + " " + utilisateur.nom,
      disabled: this.state.exploitationUtilisateurs.find((u) => u.idUtilisateur === utilisateur.idUtilisateur) != null,
    }));
  };

  getRightList = () => {
    return this.state.exploitationUtilisateurs.map((utilisateur) => ({
      key: utilisateur.idUtilisateur,
      value: utilisateur.prenom + " " + utilisateur.nom,
    }));
  };

  onSubmit = async () => {
    const { t } = this.props;
    const idsUtilisateurs = this.state.exploitationUtilisateurs.map((utilisateur) => utilisateur.idUtilisateur);

    try {
      await network.fetch(`/api/exploitations/${this.state.exploitation.idExploitation}/utilisateurs`, {
        method: "PUT",
        body: JSON.stringify(idsUtilisateurs),
      });

      toastr.success("OK", t("admin.access.save-success", "Accès enregistrés"));

      this.props.afterSubmit && this.props.afterSubmit();
    } catch (error) {
      toastr.error(t("error", "Erreur"), t("admin.access.save-error", "Erreur lors de l'enregistrement des accès"));
    }
  };

  render() {
    const { t, idExploitation } = this.props,
      { loading, exploitation } = this.state;

    return (
      <React.Fragment>
        {idExploitation == null && (
          <Box className="d-flex">
            <ExploitationSearch
              className="mb-1"
              style={{ width: 400 }}
              value={this.state.exploitation}
              onChange={this.onChangeExploitation}
            />
          </Box>
        )}

        {exploitation && (
          <React.Fragment>
            {idExploitation != null && (
              <Box className="d-flex mb-2">
                <Typography variant="h4">{exploitation.raisonSociale}</Typography>
              </Box>
            )}
            <TransferList
              left={this.getLeftList()}
              right={this.getRightList()}
              onClickLeft={this.onAddUtilisateur}
              onClickRight={this.onRemoveUtilisateur}
              leftTitle={t("admin.access.search-users", "Rechercher des utilisateurs")}
              onChangeLeftSearch={this.onChangeSearchUtilisateur}
              loading={loading}
            />
            <Box className="d-flex mt-2">
              <Button color="primary" variant="contained" onClick={this.onSubmit}>
                {t("validate", "Valider")}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(ParExploitation));
