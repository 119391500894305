import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import {
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
import NumeriqueEntier from "../indicateurs/NumeriqueEntier";
import Percent from "../indicateurs/Percent";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class PhytoDiversiteSpecifique extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const indicToWatch = [
      "HVE4_PHYTO_I_80",
      "HVE4_PHYTO_S_80",
      "HVE4_PHYTO_D_800",
      "HVE4_PHYTO_S_800",
      "HVE4_PHYTO_D_81",
      "HVE4_PHYTO_D_82",
      "HVE4_PHYTO_S_81",
      "HVE4_PHYTO_D_810",
      "HVE4_PHYTO_S_810",
      "HVE4_PHYTO_D_83",
      "HVE4_PHYTO_D_84",
      "HVE4_PHYTO_S_83",
      "HVE4_PHYTO_D_820",
      "HVE4_PHYTO_S_820",
      "HVE4_PHYTO_D_85",
      "HVE4_PHYTO_D_86",
      "HVE4_PHYTO_S_83",
      "HVE4_PHYTO_D_830",
      "HVE4_PHYTO_S_830",
      "HVE4_PHYTO_S_840",
    ];
    return indicToWatch.some((indic) => this.props.indicateurs[indic] !== nextProps.indicateurs[indic]);
  }

  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };

  render() {
    const { indicateurs, computedFields } = this.props;

    const vitiKeys = ["HVE4_PHYTO_I_80", "HVE4_PHYTO_S_80", "HVE4_PHYTO_D_800", "HVE4_PHYTO_S_800"];
    const arboKeys = [
      "HVE4_PHYTO_D_81",
      "HVE4_PHYTO_D_82",
      "HVE4_PHYTO_I_81",
      "HVE4_PHYTO_S_81",
      "HVE4_PHYTO_D_810",
      "HVE4_PHYTO_S_810",
    ];
    const legMaraichKeys = [
      "HVE4_PHYTO_D_83",
      "HVE4_PHYTO_D_84",
      "HVE4_PHYTO_I_82",
      "HVE4_PHYTO_S_82",
      "HVE4_PHYTO_D_820",
      "HVE4_PHYTO_S_820",
    ];
    const maraichHorsSolKeys = [
      "HVE4_PHYTO_D_85",
      "HVE4_PHYTO_D_86",
      "HVE4_PHYTO_I_83",
      "HVE4_PHYTO_S_83",
      "HVE4_PHYTO_D_830",
      "HVE4_PHYTO_S_830",
    ];
    const totalPointsKeys = ["HVE4_PHYTO_S_840"];

    const vitiToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => vitiKeys.includes(key)))
    );
    const arboToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => arboKeys.includes(key)))
    );
    const legMaraichToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => legMaraichKeys.includes(key)))
    );
    const maraichHorsSolToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => maraichHorsSolKeys.includes(key)))
    );
    const totalPointsToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => totalPointsKeys.includes(key)))
    );

    return (
      <React.Fragment>
        <Grid container className={"d-flex align-items-center mt-2"}>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow className={"d-flex justify-center"}>
                    <TableCell style={{ width: "15em" }} />
                    <TableCell style={{ textAlign: "center" }}>Nombre de clônes</TableCell>
                    <TableCell style={{ textAlign: "center" }}>Nombre d'espèces avec 2 variétés cultivées</TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      Nombre d'espèces avec au moins 3 variétés cultivées
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>Points intermédiaires</TableCell>
                    <TableCell style={{ textAlign: "center" }}>Pondération par rapport à la SAU</TableCell>
                    <TableCell style={{ textAlign: "center" }}>Points</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {vitiToDisplay.length > 0 && (
                    <TableRow key={1}>
                      <TableCell>Vigne</TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_I_80"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_I_80"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_I_80"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_I_80"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell colSpan={2} />
                      <TableCell style={{ textAlign: "center" }}>
                        <NumeriqueEntier
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_S_80"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_S_80"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_S_80"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_S_80"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <Percent
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_D_800"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_D_800"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_D_800"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_D_800"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_S_800"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_S_800"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_S_800"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_S_800"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                  {arboToDisplay.length > 0 && (
                    <TableRow key={2}>
                      <TableCell>Arboriculture et autres cultures pérennes</TableCell>
                      <TableCell style={{ textAlign: "center" }} />
                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_D_81"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_D_81"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_D_81"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_D_81"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_D_82"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_D_82"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_D_82"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_D_82"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <NumeriqueEntier
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_S_81"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_S_81"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_S_81"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_S_81"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <Percent
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_D_810"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_D_810"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_D_810"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_D_810"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_S_810"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_S_810"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_S_810"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_S_810"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                  {legMaraichToDisplay.length > 0 && (
                    <TableRow key={3}>
                      <TableCell>Légumes, fleurs et fruits hors arboriculture</TableCell>
                      <TableCell style={{ textAlign: "center" }} />
                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_D_83"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_D_83"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_D_83"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_D_83"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_D_84"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_D_84"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_D_84"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_D_84"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <NumeriqueEntier
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_S_82"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_S_82"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_S_82"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_S_82"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <Percent
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_D_820"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_D_820"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_D_820"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_D_820"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_S_820"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_S_820"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_S_820"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_S_820"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                  {maraichHorsSolToDisplay.length > 0 && (
                    <TableRow key={4}>
                      <TableCell>Culture hors sol</TableCell>
                      <TableCell style={{ textAlign: "center" }} />
                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_D_85"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_D_85"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_D_85"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_D_85"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_D_86"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_D_86"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_D_86"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_D_86"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <NumeriqueEntier
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_S_83"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_S_83"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_S_83"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_S_83"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <Percent
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_D_830"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_D_830"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_D_830"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_D_830"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_S_830"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_S_830"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_S_830"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_S_830"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                  {totalPointsToDisplay.length > 0 && (
                    <TableRow key={5}>
                      <TableCell colSpan={4} />
                      <TableCell style={{ textAlign: "center" }} />
                      <TableCell style={{ textAlign: "center" }}>
                        Nombre de points "Diversité spécifique variétale"
                      </TableCell>
                      <TableCell style={{ textAlign: "center" }} className={"total-point"}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_PHYTO_S_840"]}
                          key={"indicateur-" + indicateurs["HVE4_PHYTO_S_840"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_PHYTO_S_840"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_PHYTO_S_840"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(PhytoDiversiteSpecifique);
