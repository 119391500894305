import React from "react";
import { withRouter, NavLink } from "react-router-dom";
import { Drawer, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  auth: state.auth,
  app: state.app,
});

const drawerWidth = 240;
const MyDrawer = withStyles((theme) => ({
  root: {
    width: drawerWidth,
    flexShrink: 0,
    zIndex: theme.zIndex.appBar - 1,
  },
  paper: {
    width: drawerWidth,
    position: "sticky",
  },
}))(Drawer);

class SideMenu extends React.Component {
  render() {
    const { t } = this.props;

    let menuItems = new Array({
      libelle: t("admin.home", "Accueil"),
      url: "/",
      icon: "home",
    });

    menuItems = menuItems.concat(
      {
        libelle: t("users", "Utilisateurs"),
        url: "/utilisateurs",
        icon: "users",
      },
      {
        libelle: t("farms", "Exploitations"),
        url: "/exploitations",
        icon: "tractor",
      },
      {
        libelle: t("access", "Accès"),
        url: "/acces",
        icon: "key",
      },
      {
        libelle: t("admin.organizations.title", "Organismes"),
        url: "/organismes",
        icon: "sitemap",
      }
    );

    return (
      <MyDrawer variant="permanent">
        <div style={{ overflow: "auto" }}>
          <List>
            {menuItems.map((item) => (
              <ListItem
                component={NavLink}
                to={`/admin${item.url}`}
                exact
                activeStyle={{ textDecoration: "underline" }}
                button
                key={item.libelle}
              >
                <ListItemIcon>
                  <FontAwesomeIcon icon={item.icon} />
                </ListItemIcon>
                <ListItemText primary={item.libelle} />
              </ListItem>
            ))}
          </List>
        </div>
      </MyDrawer>
    );
  }
}

export default connect(mapStateToProps)(withRouter(withTranslation()(SideMenu)));
