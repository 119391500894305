import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Grid, IconButton, InputAdornment, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import TexteMultiligne from "../indicateurs/TexteMultiligne";
import DecimalPositif from "../indicateurs/DecimalPositif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Percent from "../indicateurs/Percent";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});
class IrrigMaterielOptimisant extends React.Component {
  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };

  render() {
    const { indicateurs, computedFields, getValueAssolement } = this.props;

    const indicateursKeys = ["HVE4_IRRI_D_31", "HVE4_IRRI_I_3", "HVE4_IRRI_S_3"];

    const indicateurToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateursKeys.includes(key)))
    );

    if (indicateurToDisplay.length > 0)
      return (
        <React.Fragment>
          <Grid container item spacing={2} className={"mb-1"}>
            <Grid item xs={12} className={"d-flex align-items-center"}>
              <div className={indicateurs["HVE4_IRRI_D_30"].afficherGras ? "mr-3 bold" : "mr-3"}>
                {indicateurs["HVE4_IRRI_D_30"].libelle}
              </div>
              <Tooltip
                title={<span style={{ whiteSpace: "pre-line" }}>{indicateurs["HVE4_IRRI_D_30"].messageAide}</span>}
              >
                <IconButton>
                  <FontAwesomeIcon icon="info-circle" />
                </IconButton>
              </Tooltip>
              <TexteMultiligne
                getValueAssolement={getValueAssolement}
                indicateur={indicateurs["HVE4_IRRI_D_30"]}
                key={"indicateur-" + indicateurs["HVE4_IRRI_D_30"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                inputProps={{
                  multiline: true,
                  fullWidth: true,
                  style: {
                    minWidth: "10rem",
                    maxWidth: "55rem",
                  },
                  rows: 4,
                  rowsMax: 4,
                  fontWeight: indicateurs["HVE4_IRRI_D_30"].afficherGras ? "bold" : "normal",
                }}
                valueInputProps={
                  indicateurs["HVE4_IRRI_D_30"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_D_30"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>

            <Grid item xs={4}>
              <div className={indicateurs["HVE4_IRRI_D_31"].afficherGras ? "mr-3 bold" : "mr-3"}>
                {indicateurs["HVE4_IRRI_D_31"].libelle}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={indicateurs["HVE4_IRRI_I_3"].afficherGras ? "mr-3 bold" : "mr-3"}>
                {indicateurs["HVE4_IRRI_I_3"].libelle}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={indicateurs["HVE4_IRRI_S_3"].afficherGras ? "mr-3 bold" : "mr-3"}>
                {indicateurs["HVE4_IRRI_S_3"].libelle}
              </div>
            </Grid>

            <Grid item xs={4}>
              <DecimalPositif
                error={
                  (indicateurs["HVE4_IRRI_D_31"]?.valeur?.value ?? 0) >
                  (indicateurs["HVE4_IRRI_D_102"]?.valeur?.value ?? 0)
                }
                helperText={
                  (indicateurs["HVE4_IRRI_D_31"]?.valeur?.value ?? 0) >
                  (indicateurs["HVE4_IRRI_D_102"]?.valeur?.value ?? 0)
                    ? "La valeur saisie ne doit pas dépasser la surface irriguée"
                    : ""
                }
                size={"small"}
                getValueAssolement={getValueAssolement}
                indicateur={indicateurs["HVE4_IRRI_D_31"]}
                key={"indicateur-" + indicateurs["HVE4_IRRI_D_31"]?.idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_IRRI_D_31"]?.libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_D_31"]?.libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
            <Grid item xs={4}>
              <Percent
                size={"small"}
                getValueAssolement={getValueAssolement}
                indicateur={indicateurs["HVE4_IRRI_I_3"]}
                key={"indicateur-" + indicateurs["HVE4_IRRI_I_3"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_IRRI_I_3"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_I_3"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
            <Grid item xs={4} className={"total-point"}>
              <DecimalPositif
                size={"small"}
                getValueAssolement={getValueAssolement}
                indicateur={indicateurs["HVE4_IRRI_S_3"]}
                key={"indicateur-" + indicateurs["HVE4_IRRI_S_3"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_IRRI_S_3"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_S_3"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
          </Grid>
        </React.Fragment>
      );
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(IrrigMaterielOptimisant);
