import React from "react";
import Indicator from "../../Indicator";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class SaisieDirecte extends React.Component {
  render() {
    const { indicateur, onChangeIndicator, userRole } = this.props;
    return (
      userRole !== "AGRICULTEUR" && (
        <Indicator indicateur={indicateur} onChange={onChangeIndicator} alignScoreToTheEnd />
      )
    );
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(SaisieDirecte);
