import React from "react";
import NumberInput from "../../../../common/NumberInput";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

class NumeriqueEntier extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return this.props.indicateur !== nextProps.indicateur;
  }

  render() {
    const {
      t,
      indicateur,
      sousRubriqueIndex,
      libelleEnglobantIndex,
      changeIndicateur,
      valueInputProps,
      computedFields,
      displayRules = null,
      valueAssolement = null,
    } = this.props;

    const isColumnDisplay = displayRules?.calculatedFieldPosition === "column" ?? false;

    const hasRightDisplay = displayRules?.hasRightDisplay ?? false;

    const valeurCalculee = (
      <NumberInput
        size={this.props.size}
        fullWidth={false}
        style={{ maxWidth: "8rem" }}
        disabled
        value={indicateur.valeurCalculee?.value ?? ""}
        label={t("hve3a.valeur-mesparcelles", "Valeur MesParcelles")}
        InputProps={valueInputProps}
        className={"mr-2"}
      />
    );

    const valeurAssolement = (
      <NumberInput
        size={this.props.size}
        disabled
        fullWidth={false}
        style={{ maxWidth: "8rem" }}
        label={"Valeur assolement"}
        value={valueAssolement ?? ""}
        InputProps={valueInputProps}
        className={isColumnDisplay ? "mb-1 mr-2" : "mr-2"}
      />
    );

    const valueRendered = (
      <NumberInput
        size={this.props.size}
        fullWidth={false}
        value={indicateur.valeur.value ?? ""}
        inputProps={{
          step: 1,
        }}
        style={{ width: "8rem" }}
        InputLabelProps={{
          style: { fontWeight: indicateur.afficherGras ? "bold" : "normal" },
        }}
        InputProps={valueInputProps}
        disabled={computedFields.includes(indicateur.codeNational)}
        onChange={(newValeur) =>
          changeIndicateur(
            { ...indicateur, valeur: { value: newValeur === "" ? null : Number(newValeur) } },
            sousRubriqueIndex,
            libelleEnglobantIndex
          )
        }
      />
    );

    const emptyCalculatedValuePlacement = <div style={{ minWidth: "8rem" }} className={"mr-2"}></div>;

    return (
      <React.Fragment>
        {hasRightDisplay &&
          !indicateur?.valeurCalculee &&
          !displayRules?.displayAssolement &&
          emptyCalculatedValuePlacement}
        <div className={isColumnDisplay ? "d-flex flex-column" : ""}>
          {!!indicateur.valeurCalculee && valeurCalculee}
          {!!displayRules?.displayAssolement && valeurAssolement}
          {valueRendered}
        </div>
      </React.Fragment>
    );
  }
}

export default compose(withTranslation())(NumeriqueEntier);
