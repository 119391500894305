import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Grid, InputAdornment } from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class SurfaceHortiPepi extends React.Component {
  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };
  render() {
    const { indicateurs, computedFields } = this.props;

    const prodCouvertesKeys = [
      "HVE4_CULT_D_48",
      "HVE4_CULT_D_49",
      "HVE4_CULT_D_50",
      "HVE4_CULT_D_51",
      "HVE4_CULT_D_52",
      "HVE4_CULT_D_53",
      "HVE4_CULT_D_54",
      "HVE4_CULT_D_55",
    ];
    const prodExtKeys = [
      "HVE4_CULT_D_56",
      "HVE4_CULT_D_57",
      "HVE4_CULT_D_58",
      "HVE4_CULT_D_59",
      "HVE4_CULT_D_60",
      "HVE4_CULT_D_61",
      "HVE4_CULT_D_62",
      "HVE4_CULT_D_63",
      "HVE4_CULT_D_64",
      "HVE4_CULT_D_65",
      "HVE4_CULT_D_66",
      "HVE4_CULT_D_67",
      "HVE4_CULT_D_68",
    ];

    const totauxKeys = ["HVE4_CULT_D_69", "HVE4_CULT_D_70"];
    const prodCouvertesToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => prodCouvertesKeys.includes(key)))
    );
    const prodExtToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => prodExtKeys.includes(key)))
    );
    const totauxToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => totauxKeys.includes(key)))
    );
    return (
      <React.Fragment>
        {prodCouvertesToDisplay.length > 0 && <h2 className={"my-2"}>Productions couvertes</h2>}
        <div>
          {prodCouvertesToDisplay.map((indic) => (
            <div className={"d-flex align-items-center mb-1"} key={"indic-" + indic.idIndicateur}>
              <Grid item xs={4}>
                <div className={indic.afficherGras ? "mr-3 bold" : "mr-3"}>{indic.libelle}</div>
              </Grid>
              <Grid item>
                <DecimalPositif
                  size={"small"}
                  indicateur={indic}
                  key={"indicateur-" + indic.idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indic.libelleUnite != null && {
                      endAdornment: <InputAdornment position={"end"}>{indic.libelleUnite}</InputAdornment>,
                    }
                  }
                  computedFields={computedFields}
                />
              </Grid>
            </div>
          ))}
        </div>
        {prodExtToDisplay.length > 0 && <h2 className={"my-2"}>Productions extérieurs</h2>}
        <div>
          {prodExtToDisplay.map((indic) => (
            <div className={"d-flex align-items-center mb-1"} key={"indic-" + indic.idIndicateur}>
              <Grid item xs={4}>
                <div className={indic.afficherGras ? "mr-3 bold" : "mr-3"}>{indic.libelle}</div>
              </Grid>
              <Grid item>
                <DecimalPositif
                  size={"small"}
                  indicateur={indic}
                  key={"indicateur-" + indic.idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indic.libelleUnite != null && {
                      endAdornment: <InputAdornment position={"end"}>{indic.libelleUnite}</InputAdornment>,
                    }
                  }
                  computedFields={computedFields}
                />
              </Grid>
            </div>
          ))}
        </div>
        <div>
          {totauxToDisplay.map((indic) => (
            <div className={"d-flex align-items-center mb-1"} key={"indic-" + indic.idIndicateur}>
              <Grid item xs={4}>
                <div className={indic.afficherGras ? "mr-3 bold" : "mr-3"}>{indic.libelle}</div>
              </Grid>
              <Grid item>
                <DecimalPositif
                  size={"small"}
                  indicateur={indic}
                  key={"indicateur-" + indic.idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indic.libelleUnite != null && {
                      endAdornment: <InputAdornment position={"end"}>{indic.libelleUnite}</InputAdornment>,
                    }
                  }
                  computedFields={computedFields}
                />
              </Grid>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(SurfaceHortiPepi);
