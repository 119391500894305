import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import { Box, Card, CircularProgress, Typography } from "@material-ui/core";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import SigaModal from "../../common/SigaModal";
import ImporterDocument from "./ImporterDocument";
import network from "../../../actions/external/network";
import MesDocuments from "./MesDocuments";
import Impressions from "./Impressions/Impressions";
import { compose } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const mapStateToProps = (state) => ({
  organisme: state.organisme.selected,
});

let Documents = class extends React.Component {
  tabs = {
    MesDocuments: "MesDocuments",
    ImporterDocument: "ImporterDocument",
    Impressions: "Impressions",
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      selectedTab: this.tabs.MesDocuments,
      typesDocuments: [],
      idTypeDocument: "",
    };
  }

  componentDidMount() {
    this.loadTypesDocuments();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps?.organisme?.idOrganisme !== this.props?.organisme?.idOrganisme) {
      this.props.history.push("/change-exploitation");
    }
  }

  loadTypesDocuments = async () => {
    try {
      const typesDocuments = await network.fetch("/api/types-documents");
      const idTypeDocument =
        this.props.typeDocumentCode != null
          ? typesDocuments.find((typeDocument) => typeDocument.codeNational === this.props.typeDocumentCode)
              ?.idTypeDocument
          : "";
      this.setState({
        typesDocuments,
        idTypeDocument: idTypeDocument,
        loading: false,
      });
    } catch (e) {}
  };

  onChangeTypeDocument = (idTypeDocument) => {
    this.setState({ idTypeDocument });
  };

  handleChangeTab = (event, value) => {
    this.setState({ selectedTab: value });
  };

  render() {
    if (this.state.loading) {
      return <CircularProgress className="mt-2" />;
    }

    const { t, onSelect, showImpressions } = this.props;
    const { selectedTab, typesDocuments, idTypeDocument } = this.state;

    return (
      <Card>
        <Tabs
          value={this.state.selectedTab}
          onChange={this.handleChangeTab}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab
            label={t("documents.documents-exploitation", "Documents de l'exploitation")}
            icon={<FontAwesomeIcon className={"fa-2x"} icon={"folder-open"} />}
            value={this.tabs.MesDocuments}
          />
          <Tab
            label={t("documents.importer-document", "Importer un nouveau document")}
            icon={<FontAwesomeIcon className={"fa-2x"} icon={"file-upload"} />}
            value={this.tabs.ImporterDocument}
          />
          {showImpressions && (
            <Tab
              label={t("documents.prints", "Impressions")}
              value={this.tabs.Impressions}
              icon={<FontAwesomeIcon className={"fa-2x"} icon={"print"} />}
            />
          )}
        </Tabs>
        <TabPanel value={selectedTab} index={this.tabs.MesDocuments}>
          <MesDocuments
            typesDocuments={typesDocuments}
            idTypeDocument={idTypeDocument}
            onChangeTypeDocument={this.onChangeTypeDocument}
            onSelect={onSelect}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={this.tabs.ImporterDocument}>
          <ImporterDocument
            typesDocuments={typesDocuments}
            idTypeDocument={idTypeDocument}
            onChangeTypeDocument={this.onChangeTypeDocument}
            onSelect={onSelect}
          />
        </TabPanel>
        <TabPanel value={selectedTab} index={this.tabs.Impressions}>
          <Impressions />
        </TabPanel>
      </Card>
    );
  }
};

Documents = compose(withTranslation(), connect(mapStateToProps), withRouter)(Documents);

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div hidden={value !== index} {...other}>
      {value === index && children}
    </div>
  );
};

export const DocumentsPage = (props) => {
  const { t } = useTranslation();

  return (
    <Box className="w-100">
      <Typography variant="h1" className="mb-2">
        {t("documents.title", "Documents")}
      </Typography>
      <Documents {...props} showImpressions={true} />
    </Box>
  );
};

export const DocumentsModal = (props) => {
  const { t } = useTranslation();
  const { showModal, onCloseModal, ...other } = props;

  return (
    <SigaModal
      open={showModal}
      onClose={onCloseModal}
      title={<Typography variant="h1">{t("documents.title", "Documents")}</Typography>}
      fullWidth
    >
      <Documents {...other} />
    </SigaModal>
  );
};
