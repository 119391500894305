import React from "react";
import { withTranslation } from "react-i18next";
import {
  Box,
  Card,
  CircularProgress,
  debounce,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { grey } from "@material-ui/core/colors";

class TransferList extends React.Component {
  handleChangeSearch = debounce(() => {
    this.props.onChangeLeftSearch(this.state.search);
  });

  constructor(props) {
    super(props);

    this.state = {
      search: "",
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.search !== this.state.search) {
      this.handleChangeSearch();
    }
  }

  onChangeSearch = (search) => {
    this.setState({ search });
  };

  renderLeftList = () => {
    const { t, left: items, loading } = this.props;

    return (
      <Card className={this.props.classes.card + " d-flex flex-column align-items-stretch"}>
        <Paper elevation={0} square={true}>
          <Box className={"d-flex justify-between align-items-center"} my={1} mx={2}>
            <Typography variant={"h5"}>{this.props.leftTitle}</Typography>
            <IconButton
              onClick={() => this.props.onClickLeft(items.filter((item) => !item.disabled).map((item) => item.key))}
            >
              <FontAwesomeIcon icon="arrow-right" />
            </IconButton>
          </Box>
          <Box mx={1} mb={1}>
            <TextField
              variant="outlined"
              value={this.state.search}
              onChange={(event) => this.onChangeSearch(event.target.value)}
              placeholder={t("search", "Rechercher")}
              helperText={t("admin.transfer-list.search-length", "Veuillez entrer au moins 3 caractères")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FontAwesomeIcon icon="search" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Paper>

        <Box className={"flex-fill d-flex flex-column align-items-stretch"} style={{ overflowY: "auto" }}>
          <Divider />
          {loading && (
            <Box className={"flex-fill d-flex justify-center align-items-center"}>
              <CircularProgress color={"secondary"} />
            </Box>
          )}
          {!loading && (
            <List
              className={this.props.classes.list}
              dense
              component="div"
              role="list"
              style={{
                maxHeight: "100%",
                overflow: "auto",
              }}
            >
              {items.length === 0 && this.state.search.length >= 3 && (
                <ListItem>{t("no-result", "Pas de résultats.")}</ListItem>
              )}
              {items.map((item) => {
                return (
                  <ListItem
                    key={item.key.toString()}
                    button
                    onClick={() => this.props.onClickLeft([item.key])}
                    disabled={item.disabled}
                  >
                    <ListItemText primary={item.value} />
                    {!item.disabled && <FontAwesomeIcon icon="arrow-right" />}
                  </ListItem>
                );
              })}
              <ListItem />
            </List>
          )}
        </Box>
      </Card>
    );
  };

  renderRightList = () => {
    const { t, right: items } = this.props;

    return (
      <Card className={this.props.classes.card + " d-flex flex-column align-items-stretch"}>
        <Paper elevation={0} square={true}>
          <Box className={"d-flex justify-between align-items-center"} my={1} mx={2}>
            <Typography variant="h5">{t("access", "Accès")}</Typography>
            <IconButton onClick={() => this.props.onClickRight(items.map((item) => item.key))}>
              <FontAwesomeIcon icon="times" />
            </IconButton>
          </Box>
        </Paper>
        <Box className={"flex-fill d-flex flex-column align-items-stretch"} style={{ overflowY: "auto" }}>
          <List className={this.props.classes.list} dense component="div" role="list">
            {items.map((item) => {
              return (
                <ListItem key={item.key.toString()} button onClick={() => this.props.onClickRight([item.key])}>
                  <ListItemText primary={item.value} />
                  <FontAwesomeIcon icon="times" />
                </ListItem>
              );
            })}
            <ListItem />
          </List>
        </Box>
      </Card>
    );
  };

  render() {
    return (
      <Box className="d-flex mt-1">
        <Box className="flex-fill">{this.renderLeftList()}</Box>
        <Box className="flex-fill ml-2">{this.renderRightList()}</Box>
      </Box>
    );
  }
}

export default withStyles((theme) => ({
  card: {
    width: "100%",
    height: "25rem",
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    backgroundColor: theme.palette.type === "light" ? grey["100"] : "#454545",
  },
  list: {
    overflow: "auto",
  },
}))(withTranslation()(TransferList));
