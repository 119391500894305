import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import {
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
import Percent from "../indicateurs/Percent";
import NumeriqueEntier from "../indicateurs/NumeriqueEntier";
import Assolement from "../indicateurs/Assolement";
import withTheme from "@material-ui/styles/withTheme";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class PhytoSurfNonTrt extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const indicToWatch = [
      "HVE4_PHYTO_D_20",
      "HVE4_PHYTO_D_21",
      "HVE4_PHYTO_D_22",
      "HVE4_PHYTO_D_23",
      "HVE4_PHYTO_I_20",
      "HVE4_PHYTO_S_20",
    ];
    return indicToWatch.some((indic) => this.props.indicateurs[indic] !== nextProps.indicateurs[indic]);
  }

  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };
  render() {
    const { indicateurs, computedFields, getValueAssolement, theme } = this.props;

    const indicateursKeys = [
      "HVE4_PHYTO_D_20",
      "HVE4_PHYTO_D_21",
      "HVE4_PHYTO_D_22",
      "HVE4_PHYTO_D_23",
      "HVE4_PHYTO_I_20",
      "HVE4_PHYTO_S_20",
    ];
    const indicateursToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateursKeys.includes(key)))
    );
    if (indicateursToDisplay.length > 0) {
      return (
        <Grid container>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table style={{ tableLayout: "fixed" }}>
                <TableHead>
                  <TableRow>
                    <TableCell width="25%">Libellé</TableCell>
                    <TableCell width="30%" align={"center"}>
                      Surfaces non traitées
                    </TableCell>
                    <TableCell width="30%" align={"center"}>
                      Part de la SAU non traitée
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <div className={"mr-1"}>{indicateurs["HVE4_PHYTO_D_20"].libelle}</div>
                    </TableCell>
                    <TableCell style={{ display: "flex" }}>
                      <DecimalPositif
                        displayRules={{ hasRightDisplay: true }}
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_D_20"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_D_20"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_D_20"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_D_20"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell />
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <div className={"mr-1"}>{indicateurs["HVE4_PHYTO_D_21"].libelle}</div>
                    </TableCell>
                    <TableCell>
                      <Assolement
                        error={
                          (indicateurs["HVE4_PHYTO_D_21"]?.valeur?.value ?? 0) >
                          (indicateurs["HVE4_CULT_D_31"]?.valeur?.value ?? 0) +
                            (indicateurs["HVE4_CULT_D_32"]?.valeur?.value ?? 0) +
                            (indicateurs["HVE4_CULT_D_33"]?.valeur?.value ?? 0)
                        }
                        helperText={
                          (indicateurs["HVE4_PHYTO_D_21"]?.valeur?.value ?? 0) >
                          (indicateurs["HVE4_CULT_D_31"]?.valeur?.value ?? 0) +
                            (indicateurs["HVE4_CULT_D_32"]?.valeur?.value ?? 0) +
                            (indicateurs["HVE4_CULT_D_33"]?.valeur?.value ?? 0)
                            ? "La valeur saisie doit être inférieur à la surface en prairie permanente"
                            : ""
                        }
                        size={"small"}
                        getValueAssolement={getValueAssolement}
                        indicateur={indicateurs["HVE4_PHYTO_D_21"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_D_21"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_D_21"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_D_21"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell />
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <div className={"mr-1"}>{indicateurs["HVE4_PHYTO_D_22"].libelle}</div>
                    </TableCell>
                    <TableCell style={{ display: "flex" }}>
                      <DecimalPositif
                        error={
                          (indicateurs["HVE4_PHYTO_D_22"]?.valeur?.value ?? 0) >
                          (indicateurs["HVE4_CULT_D_200"]?.valeur?.value ?? 0) -
                            ((indicateurs["HVE4_CULT_D_31"]?.valeur?.value ?? 0) +
                              (indicateurs["HVE4_CULT_D_32"]?.valeur?.value ?? 0) +
                              (indicateurs["HVE4_CULT_D_33"]?.valeur?.value ?? 0))
                        }
                        helperText={
                          (indicateurs["HVE4_PHYTO_D_22"]?.valeur?.value ?? 0) >
                          (indicateurs["HVE4_CULT_D_200"]?.valeur?.value ?? 0) -
                            ((indicateurs["HVE4_CULT_D_31"]?.valeur?.value ?? 0) +
                              (indicateurs["HVE4_CULT_D_32"]?.valeur?.value ?? 0) +
                              (indicateurs["HVE4_CULT_D_33"]?.valeur?.value ?? 0))
                            ? "La valeur saisie doit être inférieur à la SAU moins la surface en prairie permanente"
                            : ""
                        }
                        displayRules={{ hasRightDisplay: true }}
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_D_22"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_D_22"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_D_22"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_D_22"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell />
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <div className={"mr-1"}>{indicateurs["HVE4_PHYTO_D_23"].libelle}</div>
                    </TableCell>
                    <TableCell style={{ display: "flex", padding: "16px" }}>
                      <DecimalPositif
                        displayRules={{ hasRightDisplay: true }}
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_D_23"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_D_23"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_D_23"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_D_23"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell align={"center"}>
                      <Percent
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_I_20"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_I_20"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_I_20"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_I_20"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow
                    style={{
                      backgroundColor: theme.palette.background.default,
                    }}
                  >
                    <TableCell />
                    <TableCell>
                      <span className={"bold"}>Nombre de points "Surfaces non traitées"</span>
                    </TableCell>
                    <TableCell className={"total-point"} align={"center"}>
                      <NumeriqueEntier
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_S_20"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_S_20"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_S_20"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_S_20"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      );
    }
  }
}

export default compose(withTranslation(), connect(mapStateToProps), withTheme)(PhytoSurfNonTrt);
