import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Login from "../Login/Login";
import PrivateRoute from "./PrivateRoute";
import Main from "../Main/Main";
import Error404 from "../Main/Error404";
import Account from "../Main/Account/Account";
import Home from "../Main/Home/Home";
import ChangeExploitation from "../Main/ChangeExploitation/ChangeExploitation";
import WithExploitationRoute from "./WithExploitationRoute";
import AdminRouter from "./AdminRouter";
import AdminRoute from "./AdminRoute";
import ModifExploitationPage from "../Main/ModifExploitation/ModifExploitationPage";
import ConfirmEmail from "../Register/ConfirmEmail";
import ResetPassword from "../Login/ResetPassword";
import ModifAssolement from "../Main/ModifAssolement/ModifAssolement";
import Demarche from "../Main/Demarches/Demarche";
import { DocumentsPage } from "../Main/Documents/Documents";
import HVE3A from "../Main/Demarches/LegacyV1/HVE3A/HVE3A";
import HVE4 from "../Main/Demarches/HVE4/HVE4";
import Cgu from "../Main/Cgu/Cgu";

class Router extends React.Component {
  render() {
    return (
      <Switch>
        <AdminRoute path="/admin">
          <AdminRouter />
        </AdminRoute>

        <Route exact path="/login/ajouter-application-tierce">
          <Login linkExternalAccount />
        </Route>

        <Route
          exact
          path={`/login/connexion-application-tierce/:clientId`}
          children={({ match }) => <Login connectWithPartnerClientId={match.params.clientId} />}
        />

        <Route exact path="/login">
          <Login />
        </Route>

        {/*<Route exact path="/register">
          <Register />
        </Route>*/}

        <Route exact path="/reset-password/:confirmKey?">
          <ResetPassword />
        </Route>

        <Route exact path="/confirm-email/:confirmKey/:email?">
          <ConfirmEmail />
        </Route>

        <PrivateRoute exact path="/account">
          <Main>
            <Account />
          </Main>
        </PrivateRoute>

        <PrivateRoute exact path="/change-exploitation">
          <Main>
            <ChangeExploitation />
          </Main>
        </PrivateRoute>

        <WithExploitationRoute exact path="/">
          <Main>
            <Home />
          </Main>
        </WithExploitationRoute>

        <WithExploitationRoute exact path="/modif-exploitation">
          <Main>
            <ModifExploitationPage />
          </Main>
        </WithExploitationRoute>

        <WithExploitationRoute exact path="/modif-assolement">
          <Main>
            <ModifAssolement />
          </Main>
        </WithExploitationRoute>

        <WithExploitationRoute exact path="/demarches/:codeNational">
          <Main>
            <Demarche />
          </Main>
        </WithExploitationRoute>

        <WithExploitationRoute exact path="/demarches/:codeNational/infos">
          <Main>
            <Demarche showInfos={true} />
          </Main>
        </WithExploitationRoute>

        <WithExploitationRoute exact path="/demarches/HVE3A/indicateurs">
          <Main>
            <HVE3A />
          </Main>
        </WithExploitationRoute>

        <WithExploitationRoute exact path="/demarches/HVE4/indicateurs">
          <Main>
            <HVE4 />
          </Main>
        </WithExploitationRoute>

        <WithExploitationRoute exact path="/documents">
          <Main>
            <DocumentsPage />
          </Main>
        </WithExploitationRoute>

        <PrivateRoute exact path="/cgu">
          <Main>
            <Cgu />
          </Main>
        </PrivateRoute>

        <Route>
          <Error404 />
        </Route>
      </Switch>
    );
  }
}

export default withRouter(Router);
