import React from "react";
import PropTypes from "prop-types";
import { Box, Button, Grid, MenuItem, TextField, Tooltip, Typography } from "@material-ui/core";
import { connect } from "react-redux";
import codes from "./../../../../common/codes";
import DatePicker from "./../../../../common/DatePicker";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import RecapScoreConclusion from "../indicateurs/RecapScoreConclusion";
import RecapScoreTab from "../indicateurs/RecapScoreTab";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
  idExploitation: state.exploitation.selected,
});

class SyntheseRecap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      scoresRubriques: [],
      scoresMaxRubriques: [],
      etatDossier: "",
      resultatAuditComputed: "",
      dateAudit: null,
      commentaireAuditeur: "",
      commentaireExploitant: "",
      sousRubriquesScoresByRubrique: {},
      sousRubriquesScoresMaxByRubrique: {},
    };
  }

  componentDidMount() {
    const { indicateurs, rubriques, exploitationDemarcheInfo } = this.props;
    //pas de distinction spécifiques sur les indicateurs de type scores de chaque sous rubriques, on doit faire l'association à la main.

    //Le résultat du bilan azoté pouvant être défini par des indicateurs différents
    const indicBilanAzote =
      indicateurs["HVE4_FERTI_D_103"]?.valeur?.value === "Balance Globale Azotée"
        ? indicateurs["HVE4_FERTI_D_102"]?.valeur?.value
          ? "HVE4_FERTI_S_1"
          : "HVE4_FERTI_S_11"
        : indicateurs["HVE4_FERTI_D_102"]?.valeur?.value
        ? indicateurs["HVE4_FERTI_D_101"]?.valeur?.value === "Autre"
          ? "HVE4_FERTI_S_11"
          : "HVE4_FERTI_S_12"
        : indicateurs["HVE4_FERTI_D_101"]?.valeur?.value === "Autre"
        ? "HVE4_FERTI_S_1"
        : "HVE4_FERTI_S_2";

    const associationSousRubIndicsScores = {
      HVE4_BIODIV_INFRA: "HVE4_BIODIV_S_38",
      HVE4_BIODIV_PARC: "HVE4_BIODIV_S_39",
      HVE4_BIODIV_POIDS_CULT: "HVE4_BIODIV_S_43",
      HVE4_BIODIV_NB_VEGE: "HVE4_BIODIV_S_54",
      HVE4_BIODIV_NB_ANIM: "HVE4_BIODIV_S_61",
      HVE4_BIODIV_RUCHE: "HVE4_BIODIV_S_62",
      HVE4_BIODIV_MENACEES: "HVE4_BIODIV_S_600",
      HVE4_BIODIV_QUALITE_SOL: "HVE4_BIODIV_S_676",
      HVE4_PHYTO_LIMITE_CMR: "HVE4_PHYTO_S_13",
      HVE4_PHYTO_SURF_NON_TRAIT: "HVE4_PHYTO_S_20",
      HVE4_PHYTO_IFT: "HVE4_PHYTO_S_3",
      HVE4_PHYTO_SUBS_ACT: "HVE4_PHYTO_S_41",
      HVE4_PHYTO_SURV_ACTIVE: "HVE4_PHYTO_S_58",
      HVE4_PHYTO_METH_ALT: "HVE4_PHYTO_S_65",
      HVE4_PHYTO_APPLI_TRAIT: "HVE4_PHYTO_S_70",
      HVE4_PHYTO_DIVERSITE: "HVE4_PHYTO_S_840",
      HVE4_PHYTO_VEG_INTERRANG: "HVE4_PHYTO_S_930",
      HVE4_PHYTO_RECYCL_EAUX: "HVE4_PHYTO_S_1004",
      HVE4_FERTI_BILAN_AZOTE: indicBilanAzote,
      HVE4_FERTI_APPORT_AZOTE: "HVE4_FERTI_S_26",
      HVE4_FERTI_PART_AZOTE: "HVE4_FERTI_S_30",
      HVE4_FERTI_OAD: "HVE4_FERTI_S_402",
      HVE4_FERTI_SAU_NON_FERTI: "HVE4_FERTI_S_50",
      HVE4_FERTI_SURF_LEGUM: "HVE4_FERTI_S_60",
      HVE4_FERTI_COUV_SOLS: "HVE4_FERTI_S_782",
      HVE4_FERTI_MATERIELS_APPORT: "HVE4_FERTI_S_81",
      HVE4_FERTI_RECYCL_EAUX: "HVE4_FERTI_S_920",
      HVE4_IRRIG_PRATIQUES: "HVE4_IRRI_S_1",
      HVE4_IRRIG_OAD: "HVE4_IRRI_S_201",
      HVE4_IRRIG_MATERIELS_APPORT: "HVE4_IRRI_S_3",
      HVE4_IRRIG_DEM_COLL: "HVE4_IRRI_S_4",
      HVE4_IRRIG_AGRO_ECO: "HVE4_IRRI_S_5",
      HVE4_IRRIG_PRELEV_ETIAGE: "HVE4_IRRI_S_6",
      HVE4_IRRIG_RECYCL_EAUX: "HVE4_IRRI_S_74",
      HVE4_IRRIG_RECUP_PLUIE: "HVE4_IRRI_S_81",
    };
    const rubriqueScoringIndicateurs = {
      HVE4_BIODIVERSITE: "HVE4_BIODIV_S_70",
      HVE4_PHYTOSANITAIRE: "HVE4_PHYTO_S_1100",
      HVE4_FERTILISATION: "HVE4_FERTI_S_10",
      HVE4_IRRIGATION: "HVE4_IRRI_S_9",
    };
    const associationSousRubIndicsScoresMax = {
      HVE4_BIODIV_INFRA: "HVE4_SYN_D_1",
      HVE4_BIODIV_PARC: "HVE4_SYN_D_2",
      HVE4_BIODIV_POIDS_CULT: "HVE4_SYN_D_3",
      HVE4_BIODIV_NB_VEGE: "HVE4_SYN_D_4",
      HVE4_BIODIV_NB_ANIM: "HVE4_SYN_D_5",
      HVE4_BIODIV_RUCHE: "HVE4_SYN_D_6",
      HVE4_BIODIV_MENACEES: "HVE4_SYN_D_7",
      HVE4_BIODIV_QUALITE_SOL: "HVE4_SYN_D_8",
      HVE4_PHYTO_LIMITE_CMR: "HVE4_SYN_D_9",
      HVE4_PHYTO_SURF_NON_TRAIT: "HVE4_SYN_D_10",
      HVE4_PHYTO_IFT: "HVE4_SYN_D_11",
      HVE4_PHYTO_SUBS_ACT: "HVE4_SYN_D_12",
      HVE4_PHYTO_SURV_ACTIVE: "HVE4_SYN_D_13",
      HVE4_PHYTO_METH_ALT: "HVE4_SYN_D_14",
      HVE4_PHYTO_APPLI_TRAIT: "HVE4_SYN_D_15",
      HVE4_PHYTO_DIVERSITE: "HVE4_SYN_D_16",
      HVE4_PHYTO_VEG_INTERRANG: "HVE4_SYN_D_17",
      HVE4_PHYTO_RECYCL_EAUX: "HVE4_SYN_D_18",
      HVE4_FERTI_BILAN_AZOTE: "HVE4_SYN_D_19",
      HVE4_FERTI_APPORT_AZOTE: "HVE4_SYN_D_20",
      HVE4_FERTI_PART_AZOTE: "HVE4_SYN_D_21",
      HVE4_FERTI_OAD: "HVE4_SYN_D_22",
      HVE4_FERTI_SAU_NON_FERTI: "HVE4_SYN_D_23",
      HVE4_FERTI_SURF_LEGUM: "HVE4_SYN_D_24",
      HVE4_FERTI_COUV_SOLS: "HVE4_SYN_D_25",
      HVE4_FERTI_MATERIELS_APPORT: "HVE4_SYN_D_26",
      HVE4_FERTI_RECYCL_EAUX: "HVE4_SYN_D_27",
      HVE4_IRRIG_PRATIQUES: "HVE4_SYN_D_28",
      HVE4_IRRIG_OAD: "HVE4_SYN_D_29",
      HVE4_IRRIG_MATERIELS_APPORT: "HVE4_SYN_D_30",
      HVE4_IRRIG_DEM_COLL: "HVE4_SYN_D_31",
      HVE4_IRRIG_AGRO_ECO: "HVE4_SYN_D_32",
      HVE4_IRRIG_PRELEV_ETIAGE: "HVE4_SYN_D_33",
      HVE4_IRRIG_RECYCL_EAUX: "HVE4_SYN_D_34",
      HVE4_IRRIG_RECUP_PLUIE: "HVE4_SYN_D_35",
    };
    const rubriqueScoringMaxIndicateurs = {
      HVE4_BIODIVERSITE: "HVE4_SYN_D_100",
      HVE4_PHYTOSANITAIRE: "HVE4_SYN_D_200",
      HVE4_FERTILISATION: "HVE4_SYN_D_300",
      HVE4_IRRIGATION: "HVE4_SYN_D_400",
    };
    const sRubs = new Map();
    const sRubsMaxScore = new Map();
    let scoresRubriques = rubriques.reduce((acc, r) => {
      //PARTIE SCORE
      const scoring = rubriqueScoringIndicateurs[r.codeNational];
      //On trie d'abord sur les scores qu'on souhaite récupérés puis on map les valeurs des scores.
      sRubs.set(
        r.codeNational,
        r.sousRubriques
          .filter((sr) => associationSousRubIndicsScores.hasOwnProperty(sr?.codeNational))
          .map((item) => ({
            libelle: item.libelle,
            value: indicateurs[associationSousRubIndicsScores[item.codeNational]]?.valeur.value,
          }))
      );
      if (scoring) {
        return {
          ...acc,
          [r.codeNational]: {
            libelle: r.libelle,
            score: indicateurs[scoring]?.valeur.value?.scoreTotal ?? indicateurs[scoring]?.valeur.value ?? 0,
          },
        };
      } else {
        return acc;
      }
    }, {});
    let resultatAuditComputed;
    if (!this.props.idExploitation.irrigation) {
      delete scoresRubriques.HVE4_IRRIGATION;
    }
    if (indicateurs["HVE4_PHYTO_D_10"]?.valeur?.value && !indicateurs["HVE4_PHYTO_D_101"]?.valeur?.value) {
      scoresRubriques = {
        ...scoresRubriques,
        HVE4_PHYTOSANITAIRE: { ...scoresRubriques.HVE4_PHYTOSANITAIRE, score: 0 },
      };
    }
    resultatAuditComputed =
      indicateurs["HVE4_PHYTO_D_10"]?.valeur?.value && !indicateurs["HVE4_PHYTO_D_101"]?.valeur?.value
        ? codes.procedureStateCodes.NON_CERTIFIABLE
        : Object.values(scoresRubriques).every((scoreRubrique) => scoreRubrique.score >= 10)
        ? codes.procedureStateCodes.CERTIFIABLE
        : codes.procedureStateCodes.NON_CERTIFIABLE;

    const etatDossier = exploitationDemarcheInfo?.exploitationDemarcheMillesime?.etatDossier ?? "";
    const scoresMaxRubriques = rubriques.reduce((acc, r) => {
      //PARTIE SCORE MAX
      const scoringMax = rubriqueScoringMaxIndicateurs[r.codeNational];
      //On trie d'abord sur les scores qu'on souhaite récupérés puis on map les valeurs des scores.
      sRubsMaxScore.set(
        r.codeNational,
        r.sousRubriques
          .filter((sr) => associationSousRubIndicsScoresMax.hasOwnProperty(sr?.codeNational))
          .map((item) => ({
            libelle: indicateurs[associationSousRubIndicsScoresMax[item.codeNational]].libelle,
            value: indicateurs[associationSousRubIndicsScoresMax[item.codeNational]]?.valeur.value,
          }))
      );
      if (scoringMax) {
        return {
          ...acc,
          [r.codeNational]: {
            libelle: r.libelle,
            score: indicateurs[scoringMax]?.valeur?.value ?? 0,
          },
        };
      } else {
        return acc;
      }
    }, {});
    const sousRubriquesScoresByRubrique = Object.fromEntries(sRubs);
    const sousRubriquesScoresMaxByRubrique = Object.fromEntries(sRubsMaxScore);
    this.setState({
      scoresRubriques,
      scoresMaxRubriques,
      etatDossier,
      resultatAuditComputed,
      dateAudit: exploitationDemarcheInfo.exploitationDemarcheMillesime.dateAudit ?? null,
      commentaireAuditeur: exploitationDemarcheInfo.exploitationDemarcheMillesime.commentaireAuditeur ?? "",
      commentaireExploitant: exploitationDemarcheInfo.exploitationDemarcheMillesime.commentaireExploitant ?? "",
      sousRubriquesScoresByRubrique: sousRubriquesScoresByRubrique,
      sousRubriquesScoresMaxByRubrique: sousRubriquesScoresMaxByRubrique,
    });
    this.props.changeSyntheseRecap({
      dateAudit: exploitationDemarcheInfo.exploitationDemarcheMillesime.dateAudit ?? null,
      etatDossier,
      commentaireAuditeur: exploitationDemarcheInfo.exploitationDemarcheMillesime.commentaireAuditeur ?? "",
      commentaireExploitant: exploitationDemarcheInfo.exploitationDemarcheMillesime.commentaireExploitant ?? "",
    });
  }

  changeField = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    this.props.changeSyntheseRecap(this.state);
  };

  render() {
    const { t, userRole, saveResult } = this.props,
      {
        scoresRubriques,
        scoresMaxRubriques,
        etatDossier,
        commentaireAuditeur,
        commentaireExploitant,
        dateAudit,
        resultatAuditComputed,
        sousRubriquesScoresByRubrique,
        sousRubriquesScoresMaxByRubrique,
      } = this.state;
    return (
      <div className="d-flex flex-fill flex-column">
        <Typography id="scrollToTop" variant={"h3"} className={"mb-1"}>
          Résultats
        </Typography>
        <div>
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <RecapScoreTab
                sousRubriquesScores={sousRubriquesScoresByRubrique["HVE4_BIODIVERSITE"] || []}
                sousRubriquesScoresMax={sousRubriquesScoresMaxByRubrique["HVE4_BIODIVERSITE"] || []}
                titre={"Biodiversité"}
                rubriqueScore={scoresRubriques["HVE4_BIODIVERSITE"]?.score ?? 0}
                rubriqueScoreMax={scoresMaxRubriques["HVE4_BIODIVERSITE"]?.score ?? 0}
              />
            </Grid>
            <Grid item xs={6}>
              <RecapScoreTab
                sousRubriquesScores={sousRubriquesScoresByRubrique["HVE4_PHYTOSANITAIRE"] || []}
                sousRubriquesScoresMax={sousRubriquesScoresMaxByRubrique["HVE4_PHYTOSANITAIRE"] || []}
                titre={"Phytosanitaire"}
                rubriqueScore={scoresRubriques["HVE4_PHYTOSANITAIRE"]?.score ?? 0}
                rubriqueScoreMax={scoresMaxRubriques["HVE4_PHYTOSANITAIRE"]?.score ?? 0}
              />
            </Grid>
            <Grid item xs={6}>
              <RecapScoreTab
                sousRubriquesScores={sousRubriquesScoresByRubrique["HVE4_FERTILISATION"] || []}
                sousRubriquesScoresMax={sousRubriquesScoresMaxByRubrique["HVE4_FERTILISATION"] || []}
                titre={"Fertilisation"}
                rubriqueScore={scoresRubriques["HVE4_FERTILISATION"]?.score ?? 0}
                rubriqueScoreMax={scoresMaxRubriques["HVE4_FERTILISATION"]?.score ?? 0}
              />
            </Grid>
            {this.props.idExploitation.irrigation ? (
              <Grid item xs={6}>
                <RecapScoreTab
                  sousRubriquesScores={sousRubriquesScoresByRubrique["HVE4_IRRIGATION"] || []}
                  sousRubriquesScoresMax={sousRubriquesScoresMaxByRubrique["HVE4_IRRIGATION"] || []}
                  titre={"Irrigation"}
                  rubriqueScore={scoresRubriques["HVE4_IRRIGATION"]?.score ?? 0}
                  rubriqueScoreMax={scoresMaxRubriques["HVE4_IRRIGATION"]?.score ?? 0}
                />
              </Grid>
            ) : (
              <Grid item xs={6} />
            )}
            <RecapScoreConclusion
              rubriquesScores={Object.values(scoresRubriques)}
              irrigation={this.props.idExploitation.irrigation}
              certifiable={resultatAuditComputed === "CERTIFIABLE"}
            />
            <Grid item xs={6} className={"d-flex justify-center"}>
              <TextField
                select
                fullWidth={false}
                label={t("hve4.etat-dossier", "État du dossier")}
                className={"mt-1 mr-1"}
                style={{ flex: "0 1 auto" }}
                name={"etatDossier"}
                value={etatDossier}
                onChange={this.changeField}
                disabled={userRole === codes.roles.AGRICULTEUR}
              >
                {codes.procedureStates.map(({ libelle, code }, i) => (
                  <MenuItem key={i} value={code}>
                    {libelle}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={6} className={"d-flex justify-center"}>
              <DatePicker
                disableToolbar
                variant="inline"
                format="DD/MM/yyyy"
                margin="normal"
                label={"Date de l'audit"}
                value={dateAudit}
                disabled={userRole === codes.roles.AGRICULTEUR}
                onChange={(value) =>
                  this.changeField({
                    target: {
                      name: "dateAudit",
                      value: value?.format("YYYY-MM-DD") || null,
                    },
                  })
                }
              />
            </Grid>
          </Grid>

          <Box className={"d-flex justify-around"}>
            <Tooltip title="Limité à 150 caractères">
              <TextField
                multiline={true}
                rows={3}
                label={"Commentaire de l'auditeur"}
                className={"mt-1 mr-1"}
                name={"commentaireAuditeur"}
                value={commentaireAuditeur}
                disabled={userRole === codes.roles.AGRICULTEUR}
                onChange={this.changeField}
              />
            </Tooltip>
            <TextField
              multiline={true}
              rows={3}
              label={"Commentaire de l'exploitant"}
              className={"mt-1"}
              name={"commentaireExploitant"}
              value={commentaireExploitant}
              onChange={this.changeField}
            />
          </Box>

          <div className={"d-flex justify-center"}>
            <Button
              variant={"contained"}
              color={"primary"}
              className={"mt-1"}
              onClick={() =>
                saveResult({
                  etatDossier,
                  dateAudit,
                  commentaireAuditeur,
                  commentaireExploitant,
                })
              }
            >
              Enregistrer
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

SyntheseRecap.propTypes = {
  indicateurs: PropTypes.object.isRequired,
  rubriques: PropTypes.array.isRequired,
  saveResult: PropTypes.func.isRequired,
  exploitationDemarcheInfo: PropTypes.object.isRequired,
};

export default compose(withTranslation(), connect(mapStateToProps))(SyntheseRecap);
