import React from "react";
import AsyncSearch from "./AsyncSearch";
import network from "../../actions/external/network";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

class CitySearch extends React.Component {
  loadAsync = async (search) => {
    return await network.fetch(`/api/communes?${new URLSearchParams({ search })}`);
  };

  render() {
    const { onChange, t, i18n, tReady, getOptionLabel, getOptionSelected, ...rest } = this.props;

    return (
      <AsyncSearch
        onChange={onChange}
        label={t("change-exploitation.advanced-search.city", "Commune")}
        loadAsync={this.loadAsync}
        getOptionSelected={getOptionSelected}
        getOptionLabel={getOptionLabel}
        {...rest}
      />
    );
  }
}

CitySearch.propTypes = {
  onChange: PropTypes.func.isRequired,
};

CitySearch.defaultProps = {
  getOptionSelected: (option, value) => option.idcommune === value.idcommune,
  getOptionLabel: (option) =>
    option == null
      ? ""
      : option.codepostal != null && option.codepostal !== ""
      ? option.codepostal + " " + option.nom
      : option.nom,
  onChange: ({ event, value, reason }) => {
    console.log(`CitySearch got thoses values :\r\n`, event, value, reason);
    console.log(`set onChange prop to get value into your component`);
  },
};

export default withTranslation()(CitySearch);
