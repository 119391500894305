import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { CircularProgress } from "@material-ui/core";
import network from "../../actions/external/network";
import { toastr } from "react-redux-toastr";
import EmailForm from "./EmailForm";
import BackgroundPaper from "../common/BackgroundPaper";
import PasswordForm from "./PasswordForm";

const mapStateToProps = (state) => ({
  config: state.config,
});

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  askResetPassword = async ({ email, recaptcha }) => {
    this.setState({ loading: true });
    const { t } = this.props;

    try {
      await network.fetch(`/api/utilisateurs/reset-password?${new URLSearchParams({ recaptcha })}`, {
        method: "POST",
        body: email,
      });

      toastr.success(
        "OK",
        t(
          "login.reset-password.email-success",
          `Un email contenant un lien permettant de redéfinir votre mot de passe a été envoyé`
        )
      );

      this.redirectToLogin();
    } catch (error) {
      // l'API ne renvoie pas d'erreur (par exemple lorsque cette adresse email n'existe pas)
      this.setState({ loading: false });
    }
  };

  doResetPassword = async ({ password }) => {
    this.setState({ loading: true });
    const { t } = this.props;

    try {
      await network.fetch(`/api/utilisateurs/reset-password/${this.props.match.params.confirmKey}`, {
        method: "POST",
        body: password,
      });

      toastr.success(
        "OK",
        t(
          "login.reset-password.password-success",
          `Votre nouveau mot de passe a été enregistré, vous pouvez désormais vous connecter.`
        )
      );

      this.redirectToLogin();
    } catch (error) {
      toastr.error(
        t("error", "Erreur"),
        t(
          "login.reset-password.password-error",
          `Erreur lors de l'enregistrement de votre nouveau mot de passe, veuillez réessayer.`
        )
      );
      this.setState({ loading: false });
    }
  };

  redirectToLogin = () => {
    setTimeout(() => {
      this.props.history.push("/login");
    }, 1000);
  };

  render() {
    return (
      <BackgroundPaper className="paper" square>
        {this.state.loading ? (
          <CircularProgress />
        ) : this.props.match.params.confirmKey ? (
          <PasswordForm onSubmit={this.doResetPassword} />
        ) : (
          <EmailForm onSubmit={this.askResetPassword} />
        )}
      </BackgroundPaper>
    );
  }
}

export default withRouter(connect(mapStateToProps, null)(withTranslation()(ResetPassword)));
