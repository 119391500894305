import React from "react";
import Indicator from "../../Indicator";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import DatePicker from "../../../../../common/DatePicker";

class NumeriqueEntier extends React.Component {
  render() {
    const {
      indicateur,
      onChangeIndicator,
      sousRubriqueIndex,
      libelleEnglobantIndex,
      changeIndicateur,
      demarche,
    } = this.props;

    const valueRendered = (
      <DatePicker
        disableToolbar
        variant="inline"
        format="DD/MM/yyyy"
        margin="normal"
        label={"Date"}
        value={indicateur.valeur.value}
        onChange={(event) =>
          changeIndicateur(
            {
              ...indicateur,
              valeur: { value: typeof event === "object" && event !== null ? event.format("MM/DD/yyyy") : "" },
            },
            sousRubriqueIndex,
            libelleEnglobantIndex
          )
        }
      />
    );

    return (
      <Indicator indicateur={indicateur} onChange={onChangeIndicator} demarche={demarche}>
        {valueRendered}
      </Indicator>
    );
  }
}

export default compose(withTranslation())(NumeriqueEntier);
