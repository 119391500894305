import { SET_CONFIG, SET_DARKMODE } from "../actions/config";

// init config state
const configInitialState = {
  logoBase64: null,
  titleSite: null,
  primaryColor: null,
  secondaryColor: null,
  errorColor: null,
  infoColor: null,
  successColor: null,
  textColor: null,
  thirdColor: null,
  darkMode: false,
};

const config = (state = configInitialState, action) => {
  switch (action.type) {
    case SET_CONFIG:
      return { ...state, ...action.payload };
    case SET_DARKMODE:
      return { ...state, darkMode: action.payload };
    default:
      return state;
  }
};

export default config;
