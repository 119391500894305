import React from "react";
import AdminTableToolbar from "./AdminTableToolbar";
import AdminTableHead from "./AdminTableHead";
import PropTypes from "prop-types";
import {
  debounce,
  Card,
  TableRow,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
} from "@material-ui/core";
import { darken, lighten, withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

class AdminTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: "asc",
      orderBy: props.columns[0].id,
      selected: [],
      page: 0,
      rowsPerPage: 20,
      search: "",
      searchFields: [],
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevState.page !== this.state.page ||
      prevState.rowsPerPage !== this.state.rowsPerPage ||
      prevState.orderBy !== this.state.orderBy ||
      prevState.order !== this.state.order ||
      prevState.search !== this.state.search ||
      prevState.searchFields !== this.state.searchFields
    ) {
      this.loadData();
    }
  }

  loadData = debounce(() => {
    const params = {
      orderBy: this.state.orderBy,
      order: this.state.order,
      ...(this.props.showPagination && {
        offset: this.state.page * this.state.rowsPerPage,
        limit: this.state.rowsPerPage,
      }),
    };
    const paramsUrl = new URLSearchParams(params);

    if (this.state.search.length > 0) {
      params.search = this.state.search;
      if (this.state.searchFields.length > 0) {
        params.searchFields = this.state.searchFields;
      } else {
        params.searchFields = this.props.columns
          .filter((column) => column.type !== AdminTableHead.columnsTypes.ACTION)
          .map((column) => column.id);
      }

      paramsUrl.append("search", params.search);
      params.searchFields.forEach((field) => {
        paramsUrl.append("searchFields", field);
      });
    }

    this.props.loadData({
      params,
      paramsUrl,
    });
  });

  handleSearchChange = (search) => {
    this.setState({ search });
  };

  handleSearchFieldsChange = (searchFields) => {
    this.setState({ searchFields });
  };

  handleDeleteMultiple = async (ids) => {
    await this.props.onDelete(ids);
    this.loadData();
  };

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = this.props.rows.map((n) => n[this.props.idColumn]);
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };

  handleSelectClick = (event, id) => {
    event.stopPropagation();

    const isSelected = this.state.selected.find((n) => n === id);
    let newSelected = [];

    if (isSelected) {
      newSelected = this.state.selected.filter((n) => n !== id);
    } else {
      newSelected = [...this.state.selected, id];
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: parseInt(event.target.value, 10),
      page: 0,
    });
  };

  isSelected = (id) => this.state.selected.includes(id);

  render() {
    const { t, classes } = this.props;
    return (
      <Card className={classes.root}>
        {this.props.showToolbar && (
          <AdminTableToolbar
            numSelected={this.state.selected.length}
            search={this.state.search}
            handleSearchChange={this.handleSearchChange}
            searchFields={this.state.searchFields}
            handleSearchFieldsChange={this.handleSearchFieldsChange}
            canDelete={this.props.canDelete}
            onDelete={() => this.handleDeleteMultiple(this.state.selected)}
            onCreate={this.props.onCreate}
            canCreate={this.props.canCreate}
            columns={this.props.columns}
            renderHeaderOptions={this.props.renderHeaderOptions}
          />
        )}
        <TableContainer className={classes.container}>
          <Table stickyHeader className={classes.table} size="medium">
            <AdminTableHead
              numSelected={this.state.selected.length}
              order={this.state.order}
              columns={this.props.columns}
              orderBy={this.state.orderBy}
              rowCount={this.props.rows.length}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              multipleSelection={this.props.multipleSelection}
            />
            <TableBody>
              {this.props.rows.map((row, index) => {
                const isItemSelected = this.isSelected(row[this.props.idColumn]);
                return (
                  <TableRow
                    classes={{ selected: classes.selected }}
                    className={classes.tableRow}
                    onClick={() => this.props.onSelect && this.props.onSelect(row[this.props.idColumn])}
                    style={{ cursor: this.props.onSelect ? "pointer" : "default" }}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row[this.props.idColumn]}
                    selected={isItemSelected}
                  >
                    {this.props.multipleSelection && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onClick={(event) => this.handleSelectClick(event, row[this.props.idColumn])}
                          color="primary"
                        />
                      </TableCell>
                    )}
                    {this.props.columns.map((column) => (
                      <MyTableCell key={column.id}>{row[column.id]}</MyTableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {this.props.showPagination && (
          <TablePagination
            rowsPerPageOptions={[20, 50, 100]}
            component="div"
            count={this.props.count}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            labelRowsPerPage={t("admin.pagination-label-rows-per-page", "Lignes affichées")}
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} ${t("admin.pagination-label-displayed-rows", "sur")} ${count}`
            }
          />
        )}
      </Card>
    );
  }
}

AdminTable.defaultProps = {
  multipleSelection: false,
  showPagination: true,
  showToolbar: true,
  canDelete: true,
  canCreate: true,
};

AdminTable.propTypes = {
  idColumn: PropTypes.string.isRequired,
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  loadData: PropTypes.func.isRequired,
  canDelete: PropTypes.bool,
  onDelete: PropTypes.func,
  onSelect: PropTypes.func,
  canCreate: PropTypes.bool,
  onCreate: PropTypes.func,
  multipleSelection: PropTypes.bool,
  showPagination: PropTypes.bool,
  count: PropTypes.number,
  showToolbar: PropTypes.bool,
  renderHeaderOptions: PropTypes.func,
};

const StyledAdminTable = withStyles((theme) => ({
  root: {
    width: "100%",
  },
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor:
        theme.palette.type === "light"
          ? lighten(theme.palette.primary.light, 0.85)
          : darken(theme.palette.primary.dark, 0.4),
    },
  },
  selected: {},
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  container: {
    height: "55vh",
  },
}))(AdminTable);

const MyTableCell = withStyles((theme) => ({
  root: {
    "&:last-child": {
      textAlign: "right",
    },
  },
}))(TableCell);

export default withTranslation(null, { withRef: true })(StyledAdminTable);
