export const COMPUTED_FIELDS_IRRIGATION = {
  // RUBRIQUE IRRIGATION
  HVE4_IRRI_I_70: {
    columns: ["HVE4_IRRI_D_70", "HVE4_CULT_D_69"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_I_70.columns[1]) > 0
            ? 100 *
              (getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_I_70.columns[0]) /
                getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_I_70.columns[1]))
            : 0,
      },
    }),
  },
  HVE4_IRRI_I_71: {
    columns: ["HVE4_IRRI_D_71", "HVE4_CULT_D_69"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_I_71.columns[1]) > 0
            ? 100 *
              (getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_I_71.columns[0]) /
                getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_I_71.columns[1]))
            : 0,
      },
    }),
  },
  HVE4_IRRI_S_70: {
    columns: ["HVE4_IRRI_I_70"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_70.columns[0]) === 0
            ? 0
            : getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_70.columns[0]) < 50
            ? 2
            : getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_70.columns[0]) < 75
            ? 4
            : 6,
      },
    }),
  },
  HVE4_IRRI_S_71: {
    columns: ["HVE4_IRRI_I_71"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_71.columns[0]) === 0
            ? 0
            : getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_71.columns[0]) < 50
            ? 1
            : getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_71.columns[0]) < 75
            ? 2
            : 3,
      },
    }),
  },
  HVE4_IRRI_S_72: {
    columns: ["HVE4_IRRI_S_70", "HVE4_IRRI_S_71"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_72.columns[0]) +
          getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_72.columns[1]),
      },
    }),
  },
  HVE4_IRRI_D_73: {
    columns: ["HVE4_CULT_D_69", "HVE4_CULT_D_200"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_D_73.columns[1]) > 0
            ? 100 *
              (getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_D_73.columns[0]) /
                getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_D_73.columns[1]))
            : 0,
      },
    }),
  },
  HVE4_IRRI_S_74: {
    columns: ["HVE4_IRRI_S_72", "HVE4_IRRI_D_73"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: roundFourDecimales(
          (getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_74.columns[0]) *
            getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_74.columns[1])) /
            100
        ),
      },
    }),
  },
  HVE4_IRRI_S_4: {
    columns: ["HVE4_IRRI_I_4"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: getBoolOrFalse(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_4.columns[0]) ? 2 : 0,
      },
    }),
  },
  HVE4_IRRI_D_62: {
    columns: ["HVE4_IRRI_D_60", "HVE4_IRRI_D_61"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_D_62.columns[0]) +
            getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_D_62.columns[1]),
        },
      };
    },
  },
  HVE4_IRRI_I_6: {
    columns: ["HVE4_IRRI_D_61", "HVE4_IRRI_D_60"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_I_6.columns[0]) +
              getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_I_6.columns[1]) >
            0
              ? (100 * getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_I_6.columns[0])) /
                (getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_I_6.columns[0]) +
                  getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_I_6.columns[1]))
              : 0,
        },
      };
    },
  },
  HVE4_IRRI_S_6: {
    columns: ["HVE4_IRRI_I_6"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_6.columns[0]) < 20
            ? 5
            : getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_6.columns[0]) < 40
            ? 4
            : getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_6.columns[0]) < 60
            ? 3
            : getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_6.columns[0]) < 80
            ? 2
            : getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_6.columns[0]) < 90
            ? 1
            : 0,
      },
    }),
  },
  HVE4_IRRI_I_5: {
    columns: ["HVE4_IRRI_D_51", "HVE4_IRRI_D_102"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_I_5.columns[1]) > 0
            ? 100 *
              (getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_I_5.columns[0]) /
                getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_I_5.columns[1]))
            : 0,
      },
    }),
  },
  HVE4_IRRI_S_5: {
    columns: ["HVE4_IRRI_I_5"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_5.columns[0]) < 25
            ? 0
            : getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_5.columns[0]) < 50
            ? 2
            : getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_5.columns[0]) < 75
            ? 4
            : 6,
      },
    }),
  },
  HVE4_IRRI_I_3: {
    columns: ["HVE4_IRRI_D_31", "HVE4_IRRI_D_102"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_I_3.columns[1]) > 0
            ? 100 *
              (getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_I_3.columns[0]) /
                getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_I_3.columns[1]))
            : 0,
      },
    }),
  },
  HVE4_IRRI_S_3: {
    columns: ["HVE4_IRRI_I_3"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_3.columns[0]) < 25
            ? 0
            : getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_3.columns[0]) < 50
            ? 2
            : getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_3.columns[0]) < 75
            ? 4
            : 6,
      },
    }),
  },
  HVE4_IRRI_D_106: {
    columns: ["HVE4_IRRI_D_104", "HVE4_IRRI_D_105"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          5 * getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_D_106.columns[0]) +
          7 * getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_D_106.columns[1]),
      },
    }),
  },
  HVE4_IRRI_D_107: {
    columns: [
      "HVE4_IRRI_D_107_1",
      "HVE4_IRRI_D_107_2",
      "HVE4_IRRI_D_107_3",
      "HVE4_IRRI_D_107_4",
      "HVE4_IRRI_D_107_5",
      "HVE4_IRRI_D_107_6",
      "HVE4_IRRI_D_107_7",
    ],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_D_107.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE4_IRRI_D_108: {
    columns: ["HVE4_IRRI_D_108_1", "HVE4_IRRI_D_108_2", "HVE4_IRRI_D_108_3", "HVE4_IRRI_D_108_4", "HVE4_IRRI_D_108_5"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_D_108.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE4_IRRI_D_109: {
    columns: ["HVE4_IRRI_D_107", "HVE4_IRRI_D_108"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_D_109.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE4_IRRI_I_1: {
    columns: ["HVE4_IRRI_D_103", "HVE4_IRRI_D_109", "HVE4_IRRI_D_106"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: getBoolOrFalse(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_I_1.columns[0])
          ? getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_I_1.columns[2]) > 0
            ? 100 *
              (getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_I_1.columns[1]) /
                getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_I_1.columns[2]))
            : 0
          : 100,
      },
    }),
  },
  HVE4_IRRI_S_1: {
    columns: ["HVE4_IRRI_I_1"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getBoolOrFalse(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_1.columns[0]) < 10
            ? 6
            : getBoolOrFalse(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_1.columns[0]) < 20
            ? 4
            : getBoolOrFalse(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_1.columns[0]) < 30
            ? 2
            : 0,
      },
    }),
  },
  HVE4_IRRI_S_20: {
    columns: ["HVE4_IRRI_I_20"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_20.columns[0]) >= 1 ? 1 : 0,
      },
    }),
  },
  HVE4_IRRI_S_21: {
    columns: ["HVE4_IRRI_I_21"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_21.columns[0]) >= 1 ? 1 : 0,
      },
    }),
  },
  HVE4_IRRI_S_22: {
    columns: ["HVE4_IRRI_I_22"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_22.columns[0]) >= 1 ? 2 : 0,
      },
    }),
  },
  HVE4_IRRI_S_23: {
    columns: ["HVE4_IRRI_I_23"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_23.columns[0]) < 50
            ? 0
            : getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_23.columns[0]) < 75
            ? 1
            : 2,
      },
    }),
  },
  HVE4_IRRI_S_200: {
    columns: ["HVE4_IRRI_S_20", "HVE4_IRRI_S_21", "HVE4_IRRI_S_22"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: Math.min(
          getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_200.columns[0]) +
            getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_200.columns[1]) +
            getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_200.columns[2]),
          2
        ),
      },
    }),
  },
  HVE4_IRRI_I_23: {
    columns: ["HVE4_IRRI_D_201", "HVE4_CULT_D_70"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_I_23.columns[1]) > 0
            ? 100 *
              (getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_I_23.columns[0]) /
                getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_I_23.columns[1]))
            : 0,
      },
    }),
  },
  HVE4_IRRI_D_202: {
    columns: ["HVE4_CULT_D_200", "HVE4_CULT_D_70"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_D_202.columns[0]) > 0
              ? 100 *
                (getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_D_202.columns[1]) /
                  getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_D_202.columns[0]))
              : 0,
        },
      };
    },
  },
  HVE4_IRRI_S_202: {
    columns: ["HVE4_IRRI_S_23", "HVE4_IRRI_D_202"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          (getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_202.columns[0]) *
            getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_202.columns[1])) /
          100,
      },
    }),
  },
  HVE4_IRRI_D_203: {
    columns: ["HVE4_IRRI_D_202"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          100 - getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_D_203.columns[0]) > 0
            ? 100 - getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_D_203.columns[0])
            : 0,
      },
    }),
  },
  HVE4_IRRI_S_203: {
    columns: ["HVE4_IRRI_S_200", "HVE4_IRRI_D_203"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          (getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_203.columns[0]) *
            getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_203.columns[1])) /
          100,
      },
    }),
  },
  HVE4_IRRI_S_201: {
    columns: ["HVE4_IRRI_S_202", "HVE4_IRRI_S_203"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: roundFourDecimales(
          getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_201.columns[0]) +
            getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_201.columns[1])
        ),
      },
    }),
  },
  HVE4_IRRI_S_8: {
    columns: ["HVE4_IRRI_I_8"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: state.indicateurs[COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_8.columns[0]].valeur.value ? 1 : 0,
      },
    }),
  },
  HVE4_IRRI_D_80: {
    columns: ["HVE4_CULT_D_42", "HVE4_CULT_D_200"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_D_80.columns[1]) > 0
              ? 100 *
                (getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_D_80.columns[0]) /
                  getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_D_80.columns[1]))
              : 0,
        },
      };
    },
  },
  HVE4_IRRI_S_81: {
    columns: ["HVE4_IRRI_S_8", "HVE4_IRRI_D_80"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: roundFourDecimales(
            (getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_81.columns[0]) *
              getValueOrZero(state, COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_81.columns[1])) /
              100
          ),
        },
      };
    },
  },
  HVE4_IRRI_S_9: {
    columns: [
      "HVE4_IRRI_S_1",
      "HVE4_IRRI_S_201",
      "HVE4_IRRI_S_3",
      "HVE4_IRRI_S_4",
      "HVE4_IRRI_S_5",
      "HVE4_IRRI_S_6",
      "HVE4_IRRI_S_74",
      "HVE4_IRRI_S_81",
    ],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: {
            commentaire: getIndicOrNull(state, "HVE4_IRRI_S_9").value.commentaire ?? null,
            scoreTotal: roundFourDecimales(
              COMPUTED_FIELDS_IRRIGATION.HVE4_IRRI_S_9.columns.reduce(
                (sum, codeNational) => sum + getValueOrZero(state, codeNational),
                0
              )
            ),
          },
        },
      };
    },
  },
  // FIN RUBRIQUE IRRIGATION
};
const getValueOrZero = (state, codeNational) => {
  return state.indicateurs[codeNational] && !isNaN(Number(state.indicateurs[codeNational].valeur.value))
    ? Number(state.indicateurs[codeNational].valeur.value)
    : 0;
};

const getBoolOrFalse = (state, codeNational) => {
  return state.indicateurs[codeNational] && state.indicateurs[codeNational] !== undefined
    ? state.indicateurs[codeNational].valeur.value
    : false;
};

const getIndicOrNull = (state, codeNational) => {
  return state.indicateurs[codeNational] && state.indicateurs[codeNational] !== undefined
    ? state.indicateurs[codeNational].valeur
    : null;
};

const roundFourDecimales = (num) => {
  return Math.round((num + Number.EPSILON) * 10000) / 10000;
};

const exports = {
  COMPUTED_FIELDS_IRRIGATION: COMPUTED_FIELDS_IRRIGATION,
};
export default exports;
