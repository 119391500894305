import React from "react";
import { CircularProgress } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import network from "../../../actions/external/network";
import { withTheme } from "@material-ui/core/styles";
import ModifExploitationForm from "./ModifExploitationForm";
import propTypes from "prop-types";

const mapStateToProps = (state) => ({
  idMillesime: state.millesime.selected.idMillesime,
});

class ModifExploitation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      saving: false,
      activites: [],
      familleCultures: [],
      form: this.formInitState(),
      expInfosMillesimePrecedent: {},
    };
  }

  formInitState = () => ({
    siret: "",
    raisonSociale: "",
    pacage: "",
    adresse: "",
    adresse2: "",
    commune: {
      idcommune: "",
      nom: "",
      codepostal: "",
      departement: "",
    },
    departement: "",
    region: "",
    adresseMail: "",
    numeroPortable: "",
    venteDirecte: false,
    irrigation: false,
    engagementValorisation: "",
    tracabilite: "",
    commentaire: "",
    idActivitePrincipale: [],
    idActiviteAnimalePrincipale: "",
    idActiviteVegetalePrincipale: "",
    idActiviteSecondaire: [],
    idActiviteAnimaleSecondaire: "",
    idActiviteVegetaleSecondaire: "",
    idsActivitesAutres: [],
    exploitationFamilleCultures: {},
    siteInternet: "",
    anneeCreation: "",
    statutJuridique: "",
    autorisationReferencement: false,
    cvi: "",
    idBassinViti: "",
  });

  componentDidMount() {
    this.loadFormData();
  }

  loadFormData = async () => {
    try {
      const [
        familleCultures,
        activites,
        bassinViti,
        exploitation,
        expActivites,
        expFamilleCultures,
        expInfos,
        expInfosMillesimePrecedent,
      ] = await Promise.all([
        network.fetch("/api/familles-cultures"),
        network.fetch("/api/activites"),
        network.fetch("/api/bassinViticole"),
        !!this.props.idExploitation
          ? network.fetch(`/api/exploitations/${this.props.idExploitation}`)
          : Promise.resolve({ commune: { idcommune: "", nom: "", codepostal: "" } }),
        !!this.props.idExploitation
          ? network.fetch(
              `/api/exploitations/${this.props.idExploitation}/activities?millesime=${this.props.idMillesime}`
            )
          : Promise.resolve({
              principal: [],
              secondaire: [],
              autres: [],
            }),
        !!this.props.idExploitation
          ? network.fetch(
              `/api/exploitations/${this.props.idExploitation}/familles-cultures?millesime=${this.props.idMillesime}`
            )
          : Promise.resolve([]),
        !!this.props.idExploitation
          ? network.fetch(`/api/exploitations/${this.props.idExploitation}/infos?millesime=${this.props.idMillesime}`)
          : Promise.resolve({}),
        !!this.props.idExploitation
          ? network.fetch(
              `/api/exploitations/${this.props.idExploitation}/infos?millesime=${this.props.idMillesime - 1}`
            )
          : Promise.resolve({}),
      ]);
      this.setState({
        loading: false,
        activites,
        bassinViti,
        familleCultures,
        form: {
          ...this.state.form,
          ...expInfos,
          idActiviteVegetalePrincipale: expActivites.principal.filter(
            (e) =>
              activites.filter((a) => a.typeActivite === "VEGETALE").findIndex((a) => a.idActivite === e.idActivite) !==
              -1
          )[0]?.idActivite,
          idActiviteAnimalePrincipale: expActivites.principal.filter(
            (e) =>
              activites.filter((a) => a.typeActivite === "ANIMALE").findIndex((a) => a.idActivite === e.idActivite) !==
              -1
          )[0]?.idActivite,
          idActivitePrincipale: expActivites.principal.map((e) => e.idActivite),
          idActiviteVegetaleSecondaire: expActivites.secondaire.filter(
            (e) =>
              activites.filter((a) => a.typeActivite === "VEGETALE").findIndex((a) => a.idActivite === e.idActivite) !==
              -1
          )[0]?.idActivite,
          idActiviteAnimaleSecondaire: expActivites.secondaire.filter(
            (e) =>
              activites.filter((a) => a.typeActivite === "ANIMALE").findIndex((a) => a.idActivite === e.idActivite) !==
              -1
          )[0]?.idActivite,
          idActiviteSecondaire: expActivites.secondaire.map((e) => e.idActivite),
          idsActivitesAutres: expActivites.autres.map((e) => e.idActivite),
          ...exploitation,
          exploitationFamilleCultures: {
            ...familleCultures.reduce((r, f) => {
              r[f.codeNational] = {
                checked: !this.props.idExploitation,
                code: "",
              };
              return r;
            }, {}),
            ...expFamilleCultures.reduce((r, f) => {
              r[f.codeNational] = {
                checked: true,
                code: f.codeNational !== "VITIC" ? "" : f.estCvi ? "CVI" : "SAU",
              };
              return r;
            }, {}),
          },
        },
        expInfosMillesimePrecedent: expInfosMillesimePrecedent || {},
      });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { idExploitation, idMillesime, onCancel, afterSubmit } = this.props;
    const { form, loading, activites, bassinViti, familleCultures, expInfosMillesimePrecedent } = this.state;
    return (
      <div>
        {loading ? (
          <CircularProgress
            style={{
              position: "fixed",
              top: "50%",
              bottom: "50%",
              left: "50%",
              right: "50%",
            }}
          />
        ) : (
          <ModifExploitationForm
            idExploitation={idExploitation}
            idMillesime={idMillesime}
            form={form}
            bassinViti={bassinViti}
            activites={activites}
            familleCultures={familleCultures}
            afterSubmit={afterSubmit}
            expInfosMillesimePrecedent={expInfosMillesimePrecedent}
            onCancel={onCancel}
          />
        )}
      </div>
    );
  }
}

ModifExploitation.propTypes = {
  onCancel: propTypes.func,
  afterSubmit: propTypes.func,
};

export default withTheme(connect(mapStateToProps, null)(withTranslation()(ModifExploitation)));
