import React from "react";
import NumberInput from "../../../../common/NumberInput";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import clsx from "clsx";
import styles from "../SousRubriques/sousrubriquestyles.module.scss";

class ScoreRubrique extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return this.props.indicateur !== nextProps.indicateur;
  }

  render() {
    const { indicateur, changeIndicateur } = this.props;

    const valueRendered = (
      <NumberInput
        size={"small"}
        fullWidth={false}
        value={indicateur.valeur.value.scoreTotal ?? ""}
        InputLabelProps={{
          style: { fontWeight: indicateur.afficherGras ? "bold" : "normal" },
        }}
        style={{ maxWidth: "8rem" }}
        disabled={true}
        onChange={(newValeur) => {
          changeIndicateur({
            ...indicateur,
            valeur: { value: { ...indicateur.valeur.value, scoreTotal: newValeur } },
          });
        }}
      />
    );

    return (
      <React.Fragment>
        <Table style={{ mr: "100px", tableLayout: "fixed" }} className={"mt-3 mb-3"}>
          <TableBody>
            <TableRow>
              <TableCell className={clsx(styles.tab_cell_no_borders, styles.bold_content)}>
                {indicateur.libelle}
              </TableCell>
              <TableCell
                className={clsx(styles.tab_cell_no_borders, styles.bold_content, "total-point")}
                align={"center"}
              >
                {valueRendered}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </React.Fragment>
    );
  }
}

export default compose(withTranslation())(ScoreRubrique);
