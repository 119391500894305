import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import {
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NumberInput from "../../../../common/NumberInput";
import Boolean from "../indicateurs/Boolean";
import Assolement from "../indicateurs/Assolement";
const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});
class FertiBilanAzote extends React.Component {
  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };

  //init des valeurs par défaut pour éviter les erreurs dans la console
  componentDidMount() {
    const { indicateurs } = this.props;
    if (indicateurs["HVE4_FERTI_D_103"].valeur.value == null)
      this.handleChangeIndicateur({ ...indicateurs["HVE4_FERTI_D_103"], valeur: { value: "Balance Globale Azotée" } });
    if (indicateurs["HVE4_FERTI_D_104"].valeur.value == null)
      this.handleChangeIndicateur({ ...indicateurs["HVE4_FERTI_D_104"], valeur: { value: "Année Courante" } });
    if (indicateurs["HVE4_FERTI_D_102"].valeur.value == null)
      this.handleChangeIndicateur({ ...indicateurs["HVE4_FERTI_D_102"], valeur: { value: false } });
    if (indicateurs["HVE4_FERTI_D_101"].valeur.value == null)
      this.handleChangeIndicateur({ ...indicateurs["HVE4_FERTI_D_101"], valeur: { value: "Autre" } });
  }

  renderInputIft = (codeNationalIndicateur, key) => {
    return (
      <React.Fragment>
        {codeNationalIndicateur?.valeurCalculee?.value[key] != null && (
          <NumberInput
            size={"small"}
            disabled
            className="mb-1"
            label={"Valeur N MesParcelles"}
            value={codeNationalIndicateur?.valeurCalculee?.value[key]}
            allowNegative={true}
          />
        )}
        <NumberInput
          //disabled si dans la liste des champs calculés
          size={"small"}
          disabled={
            this.props.computedFields.findIndex((indic) => indic === codeNationalIndicateur.codeNational) !== -1
          }
          label={key === "n" ? "Valeur N" : key === "n_1" ? "Valeur N-1" : key === "n_2" && "Valeur N-2"}
          value={codeNationalIndicateur?.valeur?.value[key] ?? ""}
          onChange={(value) => {
            this.handleChangeIndicateur({
              ...codeNationalIndicateur,
              valeur: {
                ...codeNationalIndicateur?.valeur,
                value: {
                  ...codeNationalIndicateur?.valeur.value,
                  [key]: value === "" || value == null ? null : Number(value),
                },
              },
            });
          }}
          allowNegative={true}
        />
      </React.Fragment>
    );
  };

  render() {
    const { indicateurs, computedFields, getValueAssolement } = this.props;
    const indicateursEntreesBilanApparentKeys = [
      "HVE4_FERTI_D_150",
      "HVE4_FERTI_D_151_alim",
      "HVE4_FERTI_D_151_fourr",
      "HVE4_FERTI_D_151_paille",
      "HVE4_FERTI_D_152",
      "HVE4_FERTI_D_153",
    ];
    const indicateursEntreesBGAKeys = [
      "HVE4_FERTI_D_106",
      "HVE4_FERTI_D_107_effl",
      "HVE4_FERTI_D_107_orga",
      "HVE4_FERTI_D_108",
    ];
    const indicateursSortiesBilanApparentKeys = [
      "HVE4_FERTI_D_154",
      "HVE4_FERTI_D_155",
      "HVE4_FERTI_D_156",
      "HVE4_FERTI_D_157",
    ];
    const indicateursSortiesBGAKeys = [
      "HVE4_FERTI_D_109",
      "HVE4_FERTI_D_110_non_fourr",
      "HVE4_FERTI_D_110_fourr_hors_herbe",
      "HVE4_FERTI_D_110_herbe",
    ];
    const indicateursBasTableauBilanApparentKeys = ["HVE4_FERTI_D_158", "HVE4_FERTI_D_159"];
    const indicateursBasTableauBGAKeys = ["HVE4_FERTI_D_120", "HVE4_FERTI_D_121"];
    const indicateursDeroulantsKeys = ["HVE4_FERTI_D_103", "HVE4_FERTI_D_104", "HVE4_FERTI_D_102", "HVE4_FERTI_D_101"];
    const indicateursKeys = [
      ...indicateursDeroulantsKeys,
      ...indicateursEntreesBilanApparentKeys,
      ...indicateursEntreesBGAKeys,
      ...indicateursSortiesBilanApparentKeys,
      ...indicateursSortiesBGAKeys,
      ...indicateursBasTableauBilanApparentKeys,
      ...indicateursBasTableauBGAKeys,
    ];
    const indicateursToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateursKeys.includes(key)))
    );
    const METHODE_CALCUL = [
      {
        libelle: "Balance Globale Azotée",
        code: "Balance Globale Azotée",
      },
      {
        libelle: "Bilan Apparent",
        code: "Bilan Apparent",
      },
    ];
    const NB_CAMPAGNE = [
      {
        libelle: "Année Courante",
        code: "Année Courante",
      },
      {
        libelle: "Biannuel",
        code: "Biannuel",
      },
      {
        libelle: "Triannuel",
        code: "Triannuel",
      },
    ];
    const CULT_MIN_CALCUL = [
      {
        libelle: "Non",
        code: false,
      },
      {
        libelle: "Oui",
        code: true,
      },
    ];
    const OTEX = [
      {
        libelle: "Autre",
        code: "Autre",
      },
      {
        libelle: "4500",
        code: "4500",
      },
      {
        libelle: "4600",
        code: "4600",
      },
      {
        libelle: "4700",
        code: "4700",
      },
      {
        libelle: "4810",
        code: "4810",
      },
      {
        libelle: "4820",
        code: "4820",
      },
      {
        libelle: "4830",
        code: "4830",
      },
      {
        libelle: "4840",
        code: "4840",
      },
      {
        libelle: "8320",
        code: "8320",
      },
      {
        libelle: "8330",
        code: "8330",
      },
      {
        libelle: "8340",
        code: "8340",
      },
      {
        libelle: "8420",
        code: "8420",
      },
    ];
    const ENUMS = [];
    ENUMS["HVE4_FERTI_D_103"] = METHODE_CALCUL;
    ENUMS["HVE4_FERTI_D_104"] = NB_CAMPAGNE;
    ENUMS["HVE4_FERTI_D_102"] = CULT_MIN_CALCUL;
    ENUMS["HVE4_FERTI_D_101"] = OTEX;

    if (indicateursToDisplay.length > 0)
      return (
        <React.Fragment>
          <Grid container spacing={2}>
            {indicateursDeroulantsKeys.map((indicKey, i) => (
              <React.Fragment key={"indics " + i}>
                <Grid item xs={4}>
                  <div className={indicateurs[indicKey].afficherGras ? "bold" : ""}>
                    {indicateurs[indicKey].libelle}
                  </div>
                </Grid>
                <Grid container item xs={6}>
                  <Grid container item xs={4}>
                    <TextField
                      size={"small"}
                      fullWidth={false}
                      select
                      value={indicateurs[indicKey].valeur.value || ENUMS[indicKey][0].code}
                      style={{
                        fontWeight: "normal",
                        width: "20rem",
                      }}
                      onChange={(event) =>
                        this.handleChangeIndicateur({
                          ...indicateurs[indicKey],
                          valeur: { value: event.target.value },
                        })
                      }
                    >
                      {ENUMS[indicKey].map((element) => (
                        <MenuItem key={element.code} value={element.code}>
                          {element.libelle}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  {indicateurs[indicKey].messageAide !== null && (
                    <Grid item xs={2}>
                      <Tooltip
                        title={<span style={{ whiteSpace: "pre-line" }}>{indicateurs[indicKey].messageAide}</span>}
                      >
                        <IconButton>
                          <FontAwesomeIcon icon="info-circle" />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              </React.Fragment>
            ))}
            <Grid item xs={4}>
              <div>{indicateurs["HVE4_FERTI_D_105"].libelle}</div>
            </Grid>
            <Grid container item xs={6}>
              <Grid container item xs={8}>
                <Assolement
                  size={"small"}
                  getValueAssolement={getValueAssolement}
                  indicateur={indicateurs["HVE4_FERTI_D_105"]}
                  key={"indicateur-" + indicateurs["HVE4_FERTI_D_105"].idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indicateurs["HVE4_FERTI_D_105"].libelleUnite != null && {
                      endAdornment: (
                        <InputAdornment position={"end"}>{indicateurs["HVE4_FERTI_D_105"].libelleUnite}</InputAdornment>
                      ),
                    }
                  }
                  computedFields={computedFields}
                />
              </Grid>
            </Grid>
            <Grid container item className={"mt-2"}>
              <Grid
                item
                xs={
                  indicateurs["HVE4_FERTI_D_104"].valeur.value === "Année Courante" ||
                  indicateurs["HVE4_FERTI_D_104"].valeur.value == null
                    ? 4
                    : indicateurs["HVE4_FERTI_D_104"].valeur.value === "Biannuel"
                    ? 6
                    : 8
                }
              >
                <TableContainer component={Paper}>
                  <Table style={{ tableLayout: "fixed" }}>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        {indicateurs["HVE4_FERTI_D_104"].valeur.value === "Année Courante" ||
                        indicateurs["HVE4_FERTI_D_104"].valeur.value == null ? (
                          <TableCell align={"center"}>
                            <div>N</div>
                          </TableCell>
                        ) : indicateurs["HVE4_FERTI_D_104"].valeur.value === "Biannuel" ? (
                          <React.Fragment>
                            <TableCell align={"center"}>
                              <div>N</div>
                            </TableCell>
                            <TableCell align={"center"}>
                              <div>N-1</div>
                            </TableCell>
                          </React.Fragment>
                        ) : (
                          <React.Fragment>
                            <TableCell align={"center"}>
                              <div>N</div>
                            </TableCell>
                            <TableCell align={"center"}>
                              <div>N-1</div>
                            </TableCell>
                            <TableCell align={"center"}>
                              <div>N-2</div>
                            </TableCell>
                          </React.Fragment>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={1}>
                        <TableCell
                          colSpan={
                            indicateurs["HVE4_FERTI_D_104"].valeur.value === "Année Courante" ||
                            indicateurs["HVE4_FERTI_D_104"].valeur.value == null
                              ? 2
                              : indicateurs["HVE4_FERTI_D_104"].valeur.value === "Biannuel"
                              ? 3
                              : 4
                          }
                        >
                          <div className={"bold"}>Entrées</div>
                        </TableCell>
                      </TableRow>
                      {(indicateurs["HVE4_FERTI_D_103"].valeur.value === "Balance Globale Azotée"
                        ? indicateursEntreesBGAKeys
                        : indicateursEntreesBilanApparentKeys
                      ).map((indicKey, i) => (
                        <TableRow key={"row-entree-" + i}>
                          <TableCell>
                            <div>{indicateurs[indicKey].libelle}</div>
                          </TableCell>
                          {indicateurs["HVE4_FERTI_D_104"].valeur.value === "Année Courante" ||
                          indicateurs["HVE4_FERTI_D_104"].valeur.value == null ? (
                            <TableCell>
                              <div>{this.renderInputIft(indicateurs[indicKey], "n")}</div>
                            </TableCell>
                          ) : indicateurs["HVE4_FERTI_D_104"].valeur.value === "Biannuel" ? (
                            <React.Fragment>
                              <TableCell>
                                <div>{this.renderInputIft(indicateurs[indicKey], "n")}</div>
                              </TableCell>
                              <TableCell>
                                <div>{this.renderInputIft(indicateurs[indicKey], "n_1")}</div>
                              </TableCell>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <TableCell>
                                <div>{this.renderInputIft(indicateurs[indicKey], "n")}</div>
                              </TableCell>
                              <TableCell>
                                <div>{this.renderInputIft(indicateurs[indicKey], "n_1")}</div>
                              </TableCell>
                              <TableCell>
                                <div>{this.renderInputIft(indicateurs[indicKey], "n_2")}</div>
                              </TableCell>
                            </React.Fragment>
                          )}
                        </TableRow>
                      ))}
                      <TableRow key={2}>
                        <TableCell
                          colSpan={
                            indicateurs["HVE4_FERTI_D_104"].valeur.value === "Année Courante" ||
                            indicateurs["HVE4_FERTI_D_104"].valeur.value == null
                              ? 2
                              : indicateurs["HVE4_FERTI_D_104"].valeur.value === "Biannuel"
                              ? 3
                              : 4
                          }
                        >
                          <div className={"bold"}>Sorties</div>
                        </TableCell>
                      </TableRow>
                      {(indicateurs["HVE4_FERTI_D_103"].valeur.value === "Balance Globale Azotée"
                        ? indicateursSortiesBGAKeys
                        : indicateursSortiesBilanApparentKeys
                      ).map((indicKey, i) => (
                        <TableRow key={"row-sortie-" + i}>
                          <TableCell>
                            <div>{indicateurs[indicKey].libelle}</div>
                          </TableCell>
                          {indicateurs["HVE4_FERTI_D_104"].valeur.value === "Année Courante" ||
                          indicateurs["HVE4_FERTI_D_104"].valeur.value == null ? (
                            <TableCell>
                              <div>{this.renderInputIft(indicateurs[indicKey], "n")}</div>
                            </TableCell>
                          ) : indicateurs["HVE4_FERTI_D_104"].valeur.value === "Biannuel" ? (
                            <React.Fragment>
                              <TableCell>
                                <div>{this.renderInputIft(indicateurs[indicKey], "n")}</div>
                              </TableCell>
                              <TableCell>
                                <div>{this.renderInputIft(indicateurs[indicKey], "n_1")}</div>
                              </TableCell>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <TableCell>
                                <div>{this.renderInputIft(indicateurs[indicKey], "n")}</div>
                              </TableCell>
                              <TableCell>
                                <div>{this.renderInputIft(indicateurs[indicKey], "n_1")}</div>
                              </TableCell>
                              <TableCell>
                                <div>{this.renderInputIft(indicateurs[indicKey], "n_2")}</div>
                              </TableCell>
                            </React.Fragment>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Grid container item className={"mt-2"}>
              <Grid
                item
                xs={
                  indicateurs["HVE4_FERTI_D_104"].valeur.value === "Année Courante" ||
                  indicateurs["HVE4_FERTI_D_104"].valeur.value == null
                    ? 4
                    : indicateurs["HVE4_FERTI_D_104"].valeur.value === "Biannuel"
                    ? 6
                    : 8
                }
              >
                <TableContainer component={Paper}>
                  <Table style={{ tableLayout: "fixed" }}>
                    <TableBody>
                      {(indicateurs["HVE4_FERTI_D_103"].valeur.value === "Balance Globale Azotée"
                        ? indicateursBasTableauBGAKeys
                        : indicateursBasTableauBilanApparentKeys
                      ).map((indicKey, i) => (
                        <TableRow key={"row-bas" + i}>
                          <TableCell>
                            <div>{indicateurs[indicKey].libelle}</div>
                          </TableCell>
                          {indicateurs["HVE4_FERTI_D_104"].valeur.value === "Année Courante" ||
                          indicateurs["HVE4_FERTI_D_104"].valeur.value == null ? (
                            <TableCell>
                              <div>{this.renderInputIft(indicateurs[indicKey], "n")}</div>
                            </TableCell>
                          ) : indicateurs["HVE4_FERTI_D_104"].valeur.value === "Biannuel" ? (
                            <React.Fragment>
                              <TableCell>
                                <div>{this.renderInputIft(indicateurs[indicKey], "n")}</div>
                              </TableCell>
                              <TableCell>
                                <div>{this.renderInputIft(indicateurs[indicKey], "n_1")}</div>
                              </TableCell>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <TableCell>
                                <div>{this.renderInputIft(indicateurs[indicKey], "n")}</div>
                              </TableCell>
                              <TableCell>
                                <div>{this.renderInputIft(indicateurs[indicKey], "n_1")}</div>
                              </TableCell>
                              <TableCell>
                                <div>{this.renderInputIft(indicateurs[indicKey], "n_2")}</div>
                              </TableCell>
                            </React.Fragment>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
            <Grid container item>
              {
                //Cas n°1
                indicateurs["HVE4_FERTI_D_103"].valeur.value === "Balance Globale Azotée" &&
                indicateurs["HVE4_FERTI_D_102"].valeur.value === false ? (
                  <Grid item xs={12} className={"mt-2"}>
                    <TableContainer component={Paper}>
                      <Table style={{ tableLayout: "fixed" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <div>Moyenne du Bilan azoté</div>
                            </TableCell>
                            <TableCell>
                              <div>Points</div>
                            </TableCell>
                            <TableCell>
                              <div>Surface des cultures mineures</div>
                            </TableCell>
                            <TableCell>
                              <div>Parts des cultures mineures</div>
                            </TableCell>
                            <TableCell>
                              <div>Pondération liée aux cultures mineures</div>
                            </TableCell>
                            <TableCell>
                              <div>Part des cultures majeures sur la SAU</div>
                            </TableCell>
                            <TableCell>
                              <div>Nombre de points "Bilan Azoté"</div>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <DecimalPositif
                                size={"small"}
                                indicateur={indicateurs["HVE4_FERTI_I_1"]}
                                key={"indicateur-" + indicateurs["HVE4_FERTI_I_1"].idIndicateur}
                                changeIndicateur={this.handleChangeIndicateur}
                                valueInputProps={
                                  indicateurs["HVE4_FERTI_I_1"].libelleUnite != null && {
                                    endAdornment: (
                                      <InputAdornment position={"end"}>
                                        {indicateurs["HVE4_FERTI_I_1"].libelleUnite}
                                      </InputAdornment>
                                    ),
                                  }
                                }
                                computedFields={computedFields}
                              />
                            </TableCell>
                            <TableCell>
                              <DecimalPositif
                                size={"small"}
                                indicateur={indicateurs["HVE4_FERTI_S_1"]}
                                key={"indicateur-" + indicateurs["HVE4_FERTI_S_1"].idIndicateur}
                                changeIndicateur={this.handleChangeIndicateur}
                                valueInputProps={
                                  indicateurs["HVE4_FERTI_S_1"].libelleUnite != null && {
                                    endAdornment: (
                                      <InputAdornment position={"end"}>
                                        {indicateurs["HVE4_FERTI_S_1"].libelleUnite}
                                      </InputAdornment>
                                    ),
                                  }
                                }
                                computedFields={computedFields}
                              />
                            </TableCell>
                            <TableCell>
                              <NumberInput
                                size={"small"}
                                fullWidth={false}
                                value={indicateurs["HVE4_FERTI_D_105"]?.valeur.value ?? ""}
                                disabled
                                key={"HVE4_FERTI_D_105-" + indicateurs["HVE4_FERTI_D_105"].idIndicateur}
                                style={{ maxWidth: "8rem" }}
                                InputProps={
                                  indicateurs["HVE4_FERTI_D_105"].libelleUnite != null && {
                                    endAdornment: (
                                      <InputAdornment position={"end"}>
                                        {indicateurs["HVE4_FERTI_D_105"].libelleUnite}
                                      </InputAdornment>
                                    ),
                                  }
                                }
                              />
                            </TableCell>
                            <TableCell>
                              <DecimalPositif
                                size={"small"}
                                indicateur={indicateurs["HVE4_FERTI_D_1050"]}
                                key={"indicateur-" + indicateurs["HVE4_FERTI_D_1050"].idIndicateur}
                                changeIndicateur={this.handleChangeIndicateur}
                                valueInputProps={
                                  indicateurs["HVE4_FERTI_D_1050"].libelleUnite != null && {
                                    endAdornment: (
                                      <InputAdornment position={"end"}>
                                        {indicateurs["HVE4_FERTI_D_1050"].libelleUnite}
                                      </InputAdornment>
                                    ),
                                  }
                                }
                                computedFields={computedFields}
                              />
                            </TableCell>
                            <TableCell>
                              <Boolean
                                indicateur={indicateurs["HVE4_FERTI_D_1051"]}
                                key={"indicateur-" + indicateurs["HVE4_FERTI_D_1051"].idIndicateur}
                                changeIndicateur={this.handleChangeIndicateur}
                                valueInputProps={
                                  indicateurs["HVE4_FERTI_D_1051"].libelleUnite != null && {
                                    endAdornment: (
                                      <InputAdornment position={"end"}>
                                        {indicateurs["HVE4_FERTI_D_1051"].libelleUnite}
                                      </InputAdornment>
                                    ),
                                  }
                                }
                                computedFields={computedFields}
                              />
                            </TableCell>
                            <TableCell>
                              <DecimalPositif
                                size={"small"}
                                indicateur={indicateurs["HVE4_FERTI_D_1050_retenue"]}
                                key={"indicateur-" + indicateurs["HVE4_FERTI_D_1050_retenue"].idIndicateur}
                                changeIndicateur={this.handleChangeIndicateur}
                                valueInputProps={
                                  indicateurs["HVE4_FERTI_D_1050_retenue"].libelleUnite != null && {
                                    endAdornment: (
                                      <InputAdornment position={"end"}>
                                        {indicateurs["HVE4_FERTI_D_1050_retenue"].libelleUnite}
                                      </InputAdornment>
                                    ),
                                  }
                                }
                                computedFields={computedFields}
                              />
                            </TableCell>
                            <TableCell className={"total-point"}>
                              <DecimalPositif
                                size={"small"}
                                indicateur={indicateurs["HVE4_FERTI_S_11"]}
                                key={"indicateur-" + indicateurs["HVE4_FERTI_S_11"].idIndicateur}
                                changeIndicateur={this.handleChangeIndicateur}
                                valueInputProps={
                                  indicateurs["HVE4_FERTI_S_11"].libelleUnite != null && {
                                    endAdornment: (
                                      <InputAdornment position={"end"}>
                                        {indicateurs["HVE4_FERTI_S_11"].libelleUnite}
                                      </InputAdornment>
                                    ),
                                  }
                                }
                                computedFields={computedFields}
                              />
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>
                ) : //Cas n°2
                indicateurs["HVE4_FERTI_D_103"].valeur.value === "Balance Globale Azotée" &&
                  indicateurs["HVE4_FERTI_D_102"].valeur.value === true ? (
                  <TableContainer component={Paper}>
                    <Table style={{ tableLayout: "fixed" }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <div>Moyenne du Bilan azoté</div>
                          </TableCell>
                          <TableCell>
                            <div>Nombre de points "Bilan Azoté"</div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <DecimalPositif
                              size={"small"}
                              indicateur={indicateurs["HVE4_FERTI_I_1"]}
                              key={"indicateur-" + indicateurs["HVE4_FERTI_I_1"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                              valueInputProps={
                                indicateurs["HVE4_FERTI_I_1"].libelleUnite != null && {
                                  endAdornment: (
                                    <InputAdornment position={"end"}>
                                      {indicateurs["HVE4_FERTI_I_1"].libelleUnite}
                                    </InputAdornment>
                                  ),
                                }
                              }
                              computedFields={computedFields}
                            />
                          </TableCell>
                          <TableCell className={"total-point"}>
                            <DecimalPositif
                              size={"small"}
                              indicateur={indicateurs["HVE4_FERTI_S_1"]}
                              key={"indicateur-" + indicateurs["HVE4_FERTI_S_1"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                              valueInputProps={
                                indicateurs["HVE4_FERTI_S_1"].libelleUnite != null && {
                                  endAdornment: (
                                    <InputAdornment position={"end"}>
                                      {indicateurs["HVE4_FERTI_S_1"].libelleUnite}
                                    </InputAdornment>
                                  ),
                                }
                              }
                              computedFields={computedFields}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : //Cas n°3
                indicateurs["HVE4_FERTI_D_103"].valeur.value === "Bilan Apparent" &&
                  indicateurs["HVE4_FERTI_D_102"].valeur.value === false &&
                  indicateurs["HVE4_FERTI_D_101"].valeur.value === "Autre" ? (
                  <TableContainer component={Paper}>
                    <Table style={{ tableLayout: "fixed" }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <div>Moyenne du Bilan azoté</div>
                          </TableCell>
                          <TableCell>
                            <div>Otex</div>
                          </TableCell>
                          <TableCell>
                            <div>Points</div>
                          </TableCell>
                          <TableCell>
                            <div>Surface des cultures mineures</div>
                          </TableCell>
                          <TableCell>
                            <div>Parts des cultures mineures</div>
                          </TableCell>
                          <TableCell>
                            <div>Pondération liée aux cultures mineures</div>
                          </TableCell>
                          <TableCell>
                            <div>Part des cultures majeures sur la SAU</div>
                          </TableCell>
                          <TableCell>
                            <div>Nombre de points "Bilan Azoté"</div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <DecimalPositif
                              size={"small"}
                              indicateur={indicateurs["HVE4_FERTI_I_1"]}
                              key={"indicateur-" + indicateurs["HVE4_FERTI_I_1"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                              valueInputProps={
                                indicateurs["HVE4_FERTI_I_1"].libelleUnite != null && {
                                  endAdornment: (
                                    <InputAdornment position={"end"}>
                                      {indicateurs["HVE4_FERTI_I_1"].libelleUnite}
                                    </InputAdornment>
                                  ),
                                }
                              }
                              computedFields={computedFields}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              size={"small"}
                              fullWidth={false}
                              select
                              disabled
                              value={indicateurs["HVE4_FERTI_D_101"].valeur.value || 0}
                              style={{
                                fontWeight: "normal",
                                width: "8rem",
                              }}
                              onChange={(event) =>
                                this.handleChangeIndicateur({
                                  ...indicateurs["HVE4_FERTI_D_101"],
                                  valeur: { value: event.target.value },
                                })
                              }
                            >
                              {ENUMS["HVE4_FERTI_D_101"].map((element) => (
                                <MenuItem key={element.code} value={element.code}>
                                  {element.libelle}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell>
                            <DecimalPositif
                              size={"small"}
                              indicateur={indicateurs["HVE4_FERTI_S_1"]}
                              key={"indicateur-" + indicateurs["HVE4_FERTI_S_1"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                              valueInputProps={
                                indicateurs["HVE4_FERTI_S_1"].libelleUnite != null && {
                                  endAdornment: (
                                    <InputAdornment position={"end"}>
                                      {indicateurs["HVE4_FERTI_S_1"].libelleUnite}
                                    </InputAdornment>
                                  ),
                                }
                              }
                              computedFields={computedFields}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberInput
                              size={"small"}
                              fullWidth={false}
                              value={indicateurs["HVE4_FERTI_D_105"]?.valeur.value ?? ""}
                              disabled
                              key={"HVE4_FERTI_D_105-" + indicateurs["HVE4_FERTI_D_105"].idIndicateur}
                              style={{ maxWidth: "8rem" }}
                              InputProps={
                                indicateurs["HVE4_FERTI_D_105"].libelleUnite != null && {
                                  endAdornment: (
                                    <InputAdornment position={"end"}>
                                      {indicateurs["HVE4_FERTI_D_105"].libelleUnite}
                                    </InputAdornment>
                                  ),
                                }
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <DecimalPositif
                              size={"small"}
                              indicateur={indicateurs["HVE4_FERTI_D_1050"]}
                              key={"indicateur-" + indicateurs["HVE4_FERTI_D_1050"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                              valueInputProps={
                                indicateurs["HVE4_FERTI_D_1050"].libelleUnite != null && {
                                  endAdornment: (
                                    <InputAdornment position={"end"}>
                                      {indicateurs["HVE4_FERTI_D_1050"].libelleUnite}
                                    </InputAdornment>
                                  ),
                                }
                              }
                              computedFields={computedFields}
                            />
                          </TableCell>
                          <TableCell>
                            <Boolean
                              indicateur={indicateurs["HVE4_FERTI_D_1051"]}
                              key={"indicateur-" + indicateurs["HVE4_FERTI_D_1051"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                              valueInputProps={
                                indicateurs["HVE4_FERTI_D_1051"].libelleUnite != null && {
                                  endAdornment: (
                                    <InputAdornment position={"end"}>
                                      {indicateurs["HVE4_FERTI_D_1051"].libelleUnite}
                                    </InputAdornment>
                                  ),
                                }
                              }
                              computedFields={computedFields}
                            />
                          </TableCell>
                          <TableCell>
                            <DecimalPositif
                              size={"small"}
                              indicateur={indicateurs["HVE4_FERTI_D_1050_retenue"]}
                              key={"indicateur-" + indicateurs["HVE4_FERTI_D_1050_retenue"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                              valueInputProps={
                                indicateurs["HVE4_FERTI_D_1050_retenue"].libelleUnite != null && {
                                  endAdornment: (
                                    <InputAdornment position={"end"}>
                                      {indicateurs["HVE4_FERTI_D_1050_retenue"].libelleUnite}
                                    </InputAdornment>
                                  ),
                                }
                              }
                              computedFields={computedFields}
                            />
                          </TableCell>
                          <TableCell className={"total-point"}>
                            <DecimalPositif
                              size={"small"}
                              indicateur={indicateurs["HVE4_FERTI_S_11"]}
                              key={"indicateur-" + indicateurs["HVE4_FERTI_S_11"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                              valueInputProps={
                                indicateurs["HVE4_FERTI_S_11"].libelleUnite != null && {
                                  endAdornment: (
                                    <InputAdornment position={"end"}>
                                      {indicateurs["HVE4_FERTI_S_11"].libelleUnite}
                                    </InputAdornment>
                                  ),
                                }
                              }
                              computedFields={computedFields}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : //Cas n°4
                indicateurs["HVE4_FERTI_D_103"].valeur.value === "Bilan Apparent" &&
                  indicateurs["HVE4_FERTI_D_102"].valeur.value === true &&
                  indicateurs["HVE4_FERTI_D_101"].valeur.value === "Autre" ? (
                  <TableContainer component={Paper}>
                    <Table style={{ tableLayout: "fixed" }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <div>Moyenne du Bilan azoté</div>
                          </TableCell>
                          <TableCell>
                            <div>Otex</div>
                          </TableCell>
                          <TableCell>
                            <div>Nombre de points "Bilan Azoté"</div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <DecimalPositif
                              size={"small"}
                              indicateur={indicateurs["HVE4_FERTI_I_1"]}
                              key={"indicateur-" + indicateurs["HVE4_FERTI_I_1"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                              valueInputProps={
                                indicateurs["HVE4_FERTI_I_1"].libelleUnite != null && {
                                  endAdornment: (
                                    <InputAdornment position={"end"}>
                                      {indicateurs["HVE4_FERTI_I_1"].libelleUnite}
                                    </InputAdornment>
                                  ),
                                }
                              }
                              computedFields={computedFields}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              size={"small"}
                              fullWidth={false}
                              select
                              disabled
                              value={indicateurs["HVE4_FERTI_D_101"].valeur.value || 0}
                              style={{
                                fontWeight: "normal",
                                width: "8rem",
                              }}
                              onChange={(event) =>
                                this.handleChangeIndicateur({
                                  ...indicateurs["HVE4_FERTI_D_101"],
                                  valeur: { value: event.target.value },
                                })
                              }
                            >
                              {ENUMS["HVE4_FERTI_D_101"].map((element) => (
                                <MenuItem key={element.code} value={element.code}>
                                  {element.libelle}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell className={"total-point"}>
                            <DecimalPositif
                              size={"small"}
                              indicateur={indicateurs["HVE4_FERTI_S_1"]}
                              key={"indicateur-" + indicateurs["HVE4_FERTI_S_1"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                              valueInputProps={
                                indicateurs["HVE4_FERTI_S_1"].libelleUnite != null && {
                                  endAdornment: (
                                    <InputAdornment position={"end"}>
                                      {indicateurs["HVE4_FERTI_S_1"].libelleUnite}
                                    </InputAdornment>
                                  ),
                                }
                              }
                              computedFields={computedFields}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : //Cas n°5
                indicateurs["HVE4_FERTI_D_103"].valeur.value === "Bilan Apparent" &&
                  indicateurs["HVE4_FERTI_D_102"].valeur.value === false &&
                  indicateurs["HVE4_FERTI_D_101"].valeur.value !== "Autre" ? (
                  <TableContainer component={Paper}>
                    <Table style={{ tableLayout: "fixed" }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <div>Moyenne du Bilan azoté</div>
                          </TableCell>
                          <TableCell>
                            <div>OTEX Concerné</div>
                          </TableCell>
                          <TableCell>
                            <div>Points</div>
                          </TableCell>
                          <TableCell>
                            <div>Surface des cultures mineures</div>
                          </TableCell>
                          <TableCell>
                            <div>Parts des cultures mineures</div>
                          </TableCell>
                          <TableCell>
                            <div>Pondération liée aux cultures mineures</div>
                          </TableCell>
                          <TableCell>
                            <div>Part des cultures majeures sur la SAU</div>
                          </TableCell>
                          <TableCell>
                            <div>Nombre de points "Bilan Azoté"</div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <DecimalPositif
                              size={"small"}
                              indicateur={indicateurs["HVE4_FERTI_I_2"]}
                              key={"indicateur-" + indicateurs["HVE4_FERTI_I_2"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                              valueInputProps={
                                indicateurs["HVE4_FERTI_I_2"].libelleUnite != null && {
                                  endAdornment: (
                                    <InputAdornment position={"end"}>
                                      {indicateurs["HVE4_FERTI_I_2"].libelleUnite}
                                    </InputAdornment>
                                  ),
                                }
                              }
                              computedFields={computedFields}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              size={"small"}
                              fullWidth={false}
                              select
                              disabled
                              value={indicateurs["HVE4_FERTI_D_101"].valeur.value || 0}
                              style={{
                                fontWeight: "normal",
                                width: "8rem",
                              }}
                              onChange={(event) =>
                                this.handleChangeIndicateur({
                                  ...indicateurs["HVE4_FERTI_D_101"],
                                  valeur: { value: event.target.value },
                                })
                              }
                            >
                              {ENUMS["HVE4_FERTI_D_101"].map((element) => (
                                <MenuItem key={element.code} value={element.code}>
                                  {element.libelle}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell>
                            <DecimalPositif
                              size={"small"}
                              indicateur={indicateurs["HVE4_FERTI_S_2"]}
                              key={"indicateur-" + indicateurs["HVE4_FERTI_S_2"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                              valueInputProps={
                                indicateurs["HVE4_FERTI_S_2"].libelleUnite != null && {
                                  endAdornment: (
                                    <InputAdornment position={"end"}>
                                      {indicateurs["HVE4_FERTI_S_2"].libelleUnite}
                                    </InputAdornment>
                                  ),
                                }
                              }
                              computedFields={computedFields}
                            />
                          </TableCell>
                          <TableCell>
                            <NumberInput
                              size={"small"}
                              fullWidth={false}
                              value={indicateurs["HVE4_FERTI_D_105"]?.valeur.value ?? ""}
                              disabled
                              key={"HVE4_FERTI_D_105-" + indicateurs["HVE4_FERTI_D_105"].idIndicateur}
                              style={{ maxWidth: "8rem" }}
                              InputProps={
                                indicateurs["HVE4_FERTI_D_105"].libelleUnite != null && {
                                  endAdornment: (
                                    <InputAdornment position={"end"}>
                                      {indicateurs["HVE4_FERTI_D_105"].libelleUnite}
                                    </InputAdornment>
                                  ),
                                }
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <DecimalPositif
                              size={"small"}
                              indicateur={indicateurs["HVE4_FERTI_D_1050"]}
                              key={"indicateur-" + indicateurs["HVE4_FERTI_D_1050"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                              valueInputProps={
                                indicateurs["HVE4_FERTI_D_1050"].libelleUnite != null && {
                                  endAdornment: (
                                    <InputAdornment position={"end"}>
                                      {indicateurs["HVE4_FERTI_D_1050"].libelleUnite}
                                    </InputAdornment>
                                  ),
                                }
                              }
                              computedFields={computedFields}
                            />
                          </TableCell>
                          <TableCell>
                            <Boolean
                              indicateur={indicateurs["HVE4_FERTI_D_1051"]}
                              key={"indicateur-" + indicateurs["HVE4_FERTI_D_1051"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                              valueInputProps={
                                indicateurs["HVE4_FERTI_D_1051"].libelleUnite != null && {
                                  endAdornment: (
                                    <InputAdornment position={"end"}>
                                      {indicateurs["HVE4_FERTI_D_1051"].libelleUnite}
                                    </InputAdornment>
                                  ),
                                }
                              }
                              computedFields={computedFields}
                            />
                          </TableCell>
                          <TableCell>
                            <DecimalPositif
                              size={"small"}
                              indicateur={indicateurs["HVE4_FERTI_D_1050_retenue"]}
                              key={"indicateur-" + indicateurs["HVE4_FERTI_D_1050_retenue"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                              valueInputProps={
                                indicateurs["HVE4_FERTI_D_1050_retenue"].libelleUnite != null && {
                                  endAdornment: (
                                    <InputAdornment position={"end"}>
                                      {indicateurs["HVE4_FERTI_D_1050_retenue"].libelleUnite}
                                    </InputAdornment>
                                  ),
                                }
                              }
                              computedFields={computedFields}
                            />
                          </TableCell>
                          <TableCell className={"total-point"}>
                            <DecimalPositif
                              size={"small"}
                              indicateur={indicateurs["HVE4_FERTI_S_12"]}
                              key={"indicateur-" + indicateurs["HVE4_FERTI_S_12"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                              valueInputProps={
                                indicateurs["HVE4_FERTI_S_12"].libelleUnite != null && {
                                  endAdornment: (
                                    <InputAdornment position={"end"}>
                                      {indicateurs["HVE4_FERTI_S_12"].libelleUnite}
                                    </InputAdornment>
                                  ),
                                }
                              }
                              computedFields={computedFields}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : //Cas n°6
                indicateurs["HVE4_FERTI_D_103"].valeur.value === "Bilan Apparent" &&
                  indicateurs["HVE4_FERTI_D_102"].valeur.value === true &&
                  indicateurs["HVE4_FERTI_D_101"].valeur.value !== "Autre" ? (
                  <TableContainer component={Paper}>
                    <Table style={{ tableLayout: "fixed" }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <div>Moyenne du Bilan azoté</div>
                          </TableCell>
                          <TableCell>
                            <div>OTEX Concerné</div>
                          </TableCell>
                          <TableCell>
                            <div>Nombre de points "Bilan Azoté"</div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <DecimalPositif
                              size={"small"}
                              indicateur={indicateurs["HVE4_FERTI_I_2"]}
                              key={"indicateur-" + indicateurs["HVE4_FERTI_I_2"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                              valueInputProps={
                                indicateurs["HVE4_FERTI_I_2"].libelleUnite != null && {
                                  endAdornment: (
                                    <InputAdornment position={"end"}>
                                      {indicateurs["HVE4_FERTI_I_2"].libelleUnite}
                                    </InputAdornment>
                                  ),
                                }
                              }
                              computedFields={computedFields}
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              size={"small"}
                              fullWidth={false}
                              select
                              disabled
                              value={indicateurs["HVE4_FERTI_D_101"].valeur.value || 0}
                              style={{
                                fontWeight: "normal",
                                width: "8rem",
                              }}
                              onChange={(event) =>
                                this.handleChangeIndicateur({
                                  ...indicateurs["HVE4_FERTI_D_101"],
                                  valeur: { value: event.target.value },
                                })
                              }
                            >
                              {ENUMS["HVE4_FERTI_D_101"].map((element) => (
                                <MenuItem key={element.code} value={element.code}>
                                  {element.libelle}
                                </MenuItem>
                              ))}
                            </TextField>
                          </TableCell>
                          <TableCell className={"total-point"}>
                            <DecimalPositif
                              size={"small"}
                              indicateur={indicateurs["HVE4_FERTI_S_2"]}
                              key={"indicateur-" + indicateurs["HVE4_FERTI_S_2"].idIndicateur}
                              changeIndicateur={this.handleChangeIndicateur}
                              valueInputProps={
                                indicateurs["HVE4_FERTI_S_2"].libelleUnite != null && {
                                  endAdornment: (
                                    <InputAdornment position={"end"}>
                                      {indicateurs["HVE4_FERTI_S_2"].libelleUnite}
                                    </InputAdornment>
                                  ),
                                }
                              }
                              computedFields={computedFields}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : null
              }
            </Grid>
          </Grid>
        </React.Fragment>
      );
  }
}
export default compose(withTranslation(), connect(mapStateToProps))(FertiBilanAzote);
