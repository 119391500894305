import React from "react";
import Indicator from "../../Indicator";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";

class TexteMultiligne extends React.Component {
  render() {
    const {
      t,
      indicateur,
      onChangeIndicator,
      sousRubriqueIndex,
      libelleEnglobantIndex,
      changeIndicateur,
      computedFields,
      demarche,
    } = this.props;

    const inputProps = {
      multiline: true,
      fullWidth: false,
      style: {
        minWidth: "20rem",
        maxWidth: "25rem",
      },
      rows: 2,
      rowsMax: 4,
      fontWeight: indicateur.afficherGras ? "bold" : "normal",
    };

    const valeurCalculee = (
      <TextField
        {...inputProps}
        disabled
        value={indicateur.valeurCalculee?.value}
        className={"mr-2"}
        label={t("hve3a.valeur-mesparcelles", "Valeur MesParcelles")}
      />
    );

    const valueRendered = (
      <TextField
        {...inputProps}
        value={indicateur.valeur.value}
        disabled={computedFields.includes(indicateur.codeNational)}
        onChange={(event) =>
          changeIndicateur(
            { ...indicateur, valeur: { value: event.target.value } },
            sousRubriqueIndex,
            libelleEnglobantIndex
          )
        }
      />
    );

    return (
      <Indicator indicateur={indicateur} onChange={onChangeIndicator} demarche={demarche}>
        {!!indicateur.valeurCalculee && valeurCalculee}
        {valueRendered}
      </Indicator>
    );
  }
}

export default compose(withTranslation())(TexteMultiligne);
