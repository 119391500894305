import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Grid, InputAdornment } from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class SurfaceSynthese extends React.Component {
  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };
  render() {
    const { indicateurs, computedFields } = this.props;

    const indicateursKeys = [
      "HVE4_CULT_D_200",
      "HVE4_CULT_D_201",
      "HVE4_CULT_D_202",
      "HVE4_CULT_D_203",
      "HVE4_CULT_D_204",
      "HVE4_CULT_D_205",
      "HVE4_CULT_D_206",
    ];
    const indicateursToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateursKeys.includes(key)))
    );
    return indicateursToDisplay.map((indic) => {
      return (
        <div className={"d-flex align-items-center mb-1"} key={"indic-" + indic.idIndicateur}>
          <Grid item xs={4}>
            <div className={indic.afficherGras ? "mr-3 bold" : "mr-3"}>{indic.libelle}</div>
          </Grid>
          <Grid item>
            <DecimalPositif
              size={"small"}
              indicateur={indic}
              key={"indicateur-" + indic.idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indic.libelleUnite != null && {
                  endAdornment: <InputAdornment position={"end"}>{indic.libelleUnite}</InputAdornment>,
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
      );
    });
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(SurfaceSynthese);
