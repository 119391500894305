import { round } from "../../../../../scripts/utils";

export const CODE_NATIONAL = "HVE3A";

export const COMPUTED_FIELDS = {
  HVE3A_D_6: {
    columns: ["HVE3A_D_1", "HVE3A_D_2", "HVE3A_D_3", "HVE3A_D_4", "HVE3A_D_5"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE3A_D_6.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE3A_D_11: {
    columns: ["HVE3A_D_7", "HVE3A_D_8", "HVE3A_D_9", "HVE3A_D_10"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE3A_D_11.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE3A_D_16: {
    columns: ["HVE3A_D_12", "HVE3A_D_13", "HVE3A_D_14", "HVE3A_D_15"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE3A_D_16.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE3A_D_19: {
    columns: ["HVE3A_D_17", "HVE3A_D_18"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE3A_D_19.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE3A_D_23: {
    columns: ["HVE3A_D_20", "HVE3A_D_21", "HVE3A_D_22"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE3A_D_23.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE3A_D_32: {
    columns: [
      "HVE3A_D_24",
      "HVE3A_D_25",
      "HVE3A_D_26",
      "HVE3A_D_27",
      "HVE3A_D_28",
      "HVE3A_D_29",
      "HVE3A_D_30",
      "HVE3A_D_31",
    ],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE3A_D_32.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE3A_D_35: {
    columns: ["HVE3A_D_33", "HVE3A_D_34"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE3A_D_35.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE3A_D_41: {
    columns: ["HVE3A_D_36", "HVE3A_D_37", "HVE3A_D_38", "HVE3A_D_39", "HVE3A_D_40", "HVE3A_D_171"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE3A_D_41.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },

  HVE3A_D_45: {
    columns: ["HVE3A_D_42", "HVE3A_D_43", "HVE3A_D_44"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE3A_D_45.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE3A_D_48: {
    columns: ["HVE3A_D_47", "HVE3A_D_46"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE3A_D_48.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE3A_D_53: {
    columns: [
      "HVE3A_D_6",
      "HVE3A_D_11",
      "HVE3A_D_16",
      "HVE3A_D_19",
      "HVE3A_D_23",
      "HVE3A_D_32",
      "HVE3A_D_35",
      "HVE3A_D_41",
      "HVE3A_D_45",
    ],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE3A_D_53.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE3A_D_61: {
    columns: ["HVE3A_D_54", "HVE3A_D_55", "HVE3A_D_56", "HVE3A_D_57", "HVE3A_D_58", "HVE3A_D_59"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: COMPUTED_FIELDS.HVE3A_D_61.columns.reduce(
            (sum, codeNational) => sum + getValueOrZero(state, codeNational),
            0
          ),
        },
      };
    },
  },
  HVE3A_D_64: {
    columns: ["HVE3A_D_64"],
    disabled: false,
    computedValue: (state) => handleSET("HVE3A_D_64", state),
  },
  HVE3A_D_65: {
    columns: ["HVE3A_D_65"],
    disabled: false,
    computedValue: (state) => handleSET("HVE3A_D_65", state),
  },
  HVE3A_D_66: {
    columns: ["HVE3A_D_66"],
    disabled: false,
    computedValue: (state) => handleSET("HVE3A_D_66", state),
  },
  HVE3A_D_67: {
    columns: ["HVE3A_D_67"],
    disabled: false,
    computedValue: (state) => handleSET("HVE3A_D_67", state),
  },
  HVE3A_D_68: {
    columns: ["HVE3A_D_68"],
    disabled: false,
    computedValue: (state) => handleSET("HVE3A_D_68", state),
  },
  HVE3A_D_69: {
    columns: ["HVE3A_D_69"],
    disabled: false,
    computedValue: (state) => handleSET("HVE3A_D_69", state),
  },
  HVE3A_D_70: {
    columns: ["HVE3A_D_70"],
    disabled: false,
    computedValue: (state) => handleSET("HVE3A_D_70", state),
  },
  HVE3A_D_71: {
    columns: ["HVE3A_D_71"],
    disabled: false,
    computedValue: (state) => handleSET("HVE3A_D_71", state),
  },
  HVE3A_D_72: {
    columns: ["HVE3A_D_72"],
    disabled: false,
    computedValue: (state) => handleSET("HVE3A_D_72", state),
  },
  HVE3A_D_73: {
    columns: ["HVE3A_D_73"],
    disabled: false,
    computedValue: (state) => handleSET("HVE3A_D_73", state),
  },
  HVE3A_D_74: {
    columns: ["HVE3A_D_74"],
    disabled: false,
    computedValue: (state) => handleSET("HVE3A_D_74", state),
  },
  HVE3A_D_75: {
    columns: ["HVE3A_D_75"],
    disabled: false,
    computedValue: (state) => handleSET("HVE3A_D_75", state),
  },
  HVE3A_D_76: {
    columns: ["HVE3A_D_76"],
    disabled: false,
    computedValue: (state) => handleSET("HVE3A_D_76", state),
  },
  HVE3A_D_77: {
    columns: ["HVE3A_D_77"],
    disabled: false,
    computedValue: (state) => handleSET("HVE3A_D_77", state),
  },
  HVE3A_D_78: {
    columns: ["HVE3A_D_78"],
    disabled: false,
    computedValue: (state) => handleSET("HVE3A_D_78", state),
  },
  HVE3A_D_79: {
    columns: ["HVE3A_D_79"],
    disabled: false,
    computedValue: (state) => handleSET("HVE3A_D_79", state),
  },
  HVE3A_D_80: {
    columns: ["HVE3A_D_80"],
    disabled: false,
    computedValue: (state) => handleSET("HVE3A_D_80", state),
  },
  HVE3A_D_81: {
    columns: ["HVE3A_D_81"],
    disabled: false,
    computedValue: (state) => handleSET("HVE3A_D_81", state),
  },
  HVE3A_D_82: {
    columns: ["HVE3A_D_82"],
    disabled: false,
    computedValue: (state) => handleSET("HVE3A_D_82", state),
  },

  HVE3A_D_83: {
    columns: [
      "HVE3A_D_64",
      "HVE3A_D_65",
      "HVE3A_D_66",
      "HVE3A_D_67",
      "HVE3A_D_68",
      "HVE3A_D_69",
      "HVE3A_D_70",
      "HVE3A_D_71",
      "HVE3A_D_72",
      "HVE3A_D_73",
      "HVE3A_D_74",
      "HVE3A_D_75",
      "HVE3A_D_76",
      "HVE3A_D_77",
      "HVE3A_D_78",
      "HVE3A_D_79",
      "HVE3A_D_80",
      "HVE3A_D_81",
      "HVE3A_D_82",
    ],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE3A_D_83.columns.reduce(
          (sum, codeNational) => sum + Number(state.indicateurs[codeNational]?.valeur?.value_SET ?? 0),
          0
        ),
      },
    }),
  },
  HVE3A_D_84: {
    columns: ["HVE3A_D_29", "HVE3A_D_30"],
    disabled: true,
    computedValue: (state) => {
      const HVE3A_D_29 = getValueOrZero(state, "HVE3A_D_29"),
        HVE3A_D_30 = getValueOrZero(state, "HVE3A_D_30"),
        valeur = HVE3A_D_29 + HVE3A_D_30;
      return {
        valeur: {
          value: valeur,
        },
      };
    },
  },
  HVE3A_D_168: {
    columns: ["HVE3A_D_83", "HVE3A_D_53"],
    disabled: true,
    computedValue: (state) => {
      const HVE3A_D_83 = getValueOrZero(state, "HVE3A_D_83"),
        SAU = getValueOrZero(state, "HVE3A_D_53"),
        val = SAU === 0 ? 0 : (HVE3A_D_83 / SAU) * 100;
      return {
        valeur: {
          value: val > 100 ? 100 : val,
        },
      };
    },
  },
  HVE3A_D_98: {
    columns: [],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        ...state.indicateurs.HVE3A_D_98.valeur,
        value: state.reducedAssolement.culturesMineures,
      },
    }),
  },
  HVE3A_D_99: {
    columns: ["HVE3A_D_53", "HVE3A_D_98"],
    disabled: true,
    computedValue: (state) => {
      const SAU = getValueOrZero(state, "HVE3A_D_53"),
        culturesMineures = getValueOrNewValue(state, "HVE3A_D_98"),
        culturesMajeures = SAU - culturesMineures,
        val = culturesMajeures < 0 || isNaN(SAU) || SAU === 0 ? 0 : (culturesMajeures / SAU) * 100;
      return {
        valeur: {
          value: val > 100 ? 100 : val,
        },
      };
    },
  },
  HVE3A_D_108: {
    columns: ["HVE3A_D_102", "HVE3A_D_103", "HVE3A_D_104", "HVE3A_D_105", "HVE3A_D_106", "HVE3A_D_107"],
    disabled: true,
    computedValue: (state) => {
      const valueAllNull = !COMPUTED_FIELDS.HVE3A_D_108.columns
        .map((codeNational) => getValue(state, codeNational))
        .some((value) => value != null);
      return {
        valeur: {
          value:
            state.indicateurs.HVE3A_D_100.valeur.value === "Bilan apparent" && !valueAllNull
              ? COMPUTED_FIELDS.HVE3A_D_108.columns.reduce(
                  (sum, codeNational) => sum + getValueOrZero(state, codeNational),
                  0
                )
              : null,
        },
      };
    },
  },
  HVE3A_D_112: {
    columns: ["HVE3A_D_109", "HVE3A_D_110", "HVE3A_D_111"],
    disabled: true,
    computedValue: (state) => {
      const valueAllNull = !COMPUTED_FIELDS.HVE3A_D_112.columns
        .map((codeNational) => getValue(state, codeNational))
        .some((value) => value != null);
      return {
        valeur: {
          value:
            state.indicateurs.HVE3A_D_100.valeur.value === "Bilan apparent" && !valueAllNull
              ? COMPUTED_FIELDS.HVE3A_D_112.columns.reduce(
                  (sum, codeNational) => sum + getValueOrZero(state, codeNational),
                  0
                )
              : null,
        },
      };
    },
  },
  HVE3A_D_116: {
    columns: ["HVE3A_D_113", "HVE3A_D_114", "HVE3A_D_115"],
    disabled: true,
    computedValue: (state) => {
      const valueAllNull = !COMPUTED_FIELDS.HVE3A_D_116.columns
        .map((codeNational) => getValue(state, codeNational))
        .some((value) => value != null);
      return {
        valeur: {
          value:
            state.indicateurs.HVE3A_D_100.valeur.value === "Bilan apparent" && !valueAllNull
              ? COMPUTED_FIELDS.HVE3A_D_116.columns.reduce(
                  (sum, codeNational) => sum + getValueOrZero(state, codeNational),
                  0
                )
              : null,
        },
      };
    },
  },
  HVE3A_D_117: {
    columns: ["HVE3A_D_108", "HVE3A_D_112", "HVE3A_D_116"],
    disabled: true,
    computedValue: (state) => {
      const HVE3A_D_108 = getValue(state, "HVE3A_D_108"),
        HVE3A_D_112 = getValue(state, "HVE3A_D_112"),
        HVE3A_D_116 = getValue(state, "HVE3A_D_116");
      const valueAllNull = HVE3A_D_108 == null && HVE3A_D_112 == null && HVE3A_D_116 == null;
      const valeur =
        state.indicateurs.HVE3A_D_100.valeur.value === "Bilan apparent" && !valueAllNull
          ? getValueOrZero(state, "HVE3A_D_108") +
            getValueOrZero(state, "HVE3A_D_112") -
            getValueOrZero(state, "HVE3A_D_116")
          : null;
      return {
        valeur: {
          value: valeur,
        },
      };
    },
  },
  HVE3A_D_121: {
    columns: ["HVE3A_D_118", "HVE3A_D_119", "HVE3A_D_120"],
    disabled: true,
    computedValue: (state) => {
      const valueAllNull = !COMPUTED_FIELDS.HVE3A_D_121.columns
        .map((codeNational) => getValue(state, codeNational))
        .some((value) => value != null);
      return {
        valeur: {
          value:
            state.indicateurs.HVE3A_D_100.valeur.value !== "Bilan apparent" && !valueAllNull
              ? COMPUTED_FIELDS.HVE3A_D_121.columns.reduce(
                  (sum, codeNational) => sum + getValueOrZero(state, codeNational),
                  0
                )
              : null,
        },
      };
    },
  },
  HVE3A_D_125: {
    columns: ["HVE3A_D_122", "HVE3A_D_123", "HVE3A_D_124"],
    disabled: true,
    computedValue: (state) => {
      const valueAllNull = !COMPUTED_FIELDS.HVE3A_D_125.columns
        .map((codeNational) => getValue(state, codeNational))
        .some((value) => value != null);
      return {
        valeur: {
          value:
            state.indicateurs.HVE3A_D_100.valeur.value !== "Bilan apparent" && !valueAllNull
              ? COMPUTED_FIELDS.HVE3A_D_125.columns.reduce(
                  (sum, codeNational) => sum + getValueOrZero(state, codeNational),
                  0
                )
              : null,
        },
      };
    },
  },
  HVE3A_D_126: {
    columns: ["HVE3A_D_100", "HVE3A_D_121", "HVE3A_D_125"],
    disabled: true,
    computedValue: (state) => {
      const HVE3A_D_121 = getValue(state, "HVE3A_D_121"),
        HVE3A_D_125 = getValue(state, "HVE3A_D_125"),
        valueAllNull = HVE3A_D_121 == null && HVE3A_D_125 == null;
      const valeur =
        state.indicateurs.HVE3A_D_100.valeur.value !== "Bilan apparent" && !valueAllNull
          ? getValueOrZero(state, "HVE3A_D_121") - getValueOrZero(state, "HVE3A_D_125")
          : null;

      return {
        valeur: {
          value: valeur,
        },
      };
    },
  },
  HVE3A_D_135: {
    columns: ["HVE3A_D_133", "HVE3A_D_134"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE3A_D_135.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE3A_D_136: {
    columns: ["HVE3A_D_12", "HVE3A_D_13", "HVE3A_D_14", "HVE3A_D_20", "HVE3A_D_24", "HVE3A_D_25"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE3A_D_136.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE3A_D_137: {
    columns: ["HVE3A_D_15", "HVE3A_D_28"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE3A_D_137.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE3A_D_148: {
    columns: ["HVE3A_D_146", "HVE3A_D_147"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 5 * getValueOrZero(state, "HVE3A_D_146") + 7 * getValueOrZero(state, "HVE3A_D_147"),
      },
    }),
  },
  HVE3A_D_149: {
    columns: ["HVE3A_D_150", "HVE3A_D_151", "HVE3A_D_152", "HVE3A_D_153", "HVE3A_D_154", "HVE3A_D_155", "HVE3A_D_156"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE3A_D_149.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE3A_D_157: {
    columns: ["HVE3A_D_158", "HVE3A_D_159", "HVE3A_D_160", "HVE3A_D_161", "HVE3A_D_162"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: COMPUTED_FIELDS.HVE3A_D_157.columns.reduce(
          (sum, codeNational) => sum + getValueOrZero(state, codeNational),
          0
        ),
      },
    }),
  },
  HVE3A_I_1: {
    columns: ["HVE3A_D_53"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: getValueOrZero(state, COMPUTED_FIELDS.HVE3A_I_1.columns[0]),
      },
    }),
  },
  HVE3A_I_2: {
    columns: [
      "HVE3A_D_6",
      "HVE3A_D_11",
      "HVE3A_D_16",
      "HVE3A_D_19",
      "HVE3A_D_23",
      "HVE3A_D_32",
      "HVE3A_D_35",
      "HVE3A_D_36",
      "HVE3A_D_29",
      "HVE3A_D_30",
      "HVE3A_D_31",
    ],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, "HVE3A_D_6") +
          getValueOrZero(state, "HVE3A_D_11") +
          getValueOrZero(state, "HVE3A_D_16") +
          getValueOrZero(state, "HVE3A_D_19") +
          getValueOrZero(state, "HVE3A_D_23") +
          getValueOrZero(state, "HVE3A_D_32") +
          getValueOrZero(state, "HVE3A_D_35") +
          getValueOrZero(state, "HVE3A_D_36") -
          (getValueOrZero(state, "HVE3A_D_29") +
            getValueOrZero(state, "HVE3A_D_30") +
            getValueOrZero(state, "HVE3A_D_31")),
      },
    }),
  },
  HVE3A_I_3: {
    columns: ["HVE3A_D_42", "HVE3A_D_45"],
    disabled: true,
    computedValue: (state) => {
      const D_45 = getValueOrZero(state, "HVE3A_D_45"),
        D_42 = getValueOrZero(state, "HVE3A_D_42");
      return {
        valeur: {
          value: D_45 - D_42,
        },
      };
    },
  },
  HVE3A_I_4: {
    columns: ["HVE3A_D_42"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: getValueOrZero(state, "HVE3A_D_42"),
      },
    }),
  },
  HVE3A_I_5: {
    columns: ["HVE3A_D_36", "HVE3A_D_40", "HVE3A_D_41"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value:
          getValueOrZero(state, "HVE3A_D_41") -
          getValueOrZero(state, "HVE3A_D_40") -
          getValueOrZero(state, "HVE3A_D_36"),
      },
    }),
  },
  HVE3A_I_6: {
    columns: ["HVE3A_D_40"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: getValueOrZero(state, "HVE3A_D_40"),
      },
    }),
  },
  HVE3A_I_7: {
    columns: ["HVE3A_D_171"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: getValueOrZero(state, "HVE3A_D_171"),
      },
    }),
  },
  HVE3A_I_8: {
    columns: ["HVE3A_D_168"],
    disabled: true,
    computedValue: (state) => {
      const value = getValueOrZero(state, "HVE3A_D_168"),
        note =
          value < 5
            ? 0
            : value >= 5 && value < 6
            ? 2
            : value >= 6 && value < 7
            ? 4
            : value >= 7 && value < 8
            ? 6
            : value >= 8 && value < 9
            ? 8
            : 10;
      return {
        valeur: {
          value,
          note,
        },
      };
    },
  },
  HVE3A_I_9: {
    columns: ["HVE3A_D_53"],
    disabled: true,
    computedValue: (state) => {
      const culturePrincipale = state.reducedAssolement.culturePrincipale,
        secondeCulturePrincipale = state.reducedAssolement.secondeCulturePrincipale,
        SAU = getValueOrZero(state, "HVE3A_D_53"),
        culture =
          SAU === 0
            ? null
            : ["PTLEGPURE", "PTMLGLEG", "PTGRMNPUR", "PTMLGGRMN", "PTMLGCPLX"].includes(culturePrincipale.code) &&
              culturePrincipale.surface / SAU >= 0.1 &&
              secondeCulturePrincipale.surface / SAU >= 0.1
            ? secondeCulturePrincipale
            : culturePrincipale,
        value =
          culture === null
            ? ""
            : culture.isEspece
            ? culture.code
            : state.typesCultures.find((tc) => tc.code === culture.code).libelle;
      return {
        valeur: {
          value: culturePrincipale.surface === 0 ? "" : value,
          surface: culture === null ? 0 : culture.surface,
        },
      };
    },
  },
  HVE3A_I_10: {
    columns: ["HVE3A_I_9", "HVE3A_I_10", "HVE3A_D_53"],
    disabled: true,
    computedValue: (state) => {
      const surfaceCulturePrincipale = Number(state.indicateurs.HVE3A_I_9.valeur.surface),
        culturePrincipale = getValue(state, "HVE3A_I_9"),
        cultureSecondaire = state.reducedAssolement.secondeCulturePrincipale,
        { PPNFERTI, PPFERTINT, PPAUTRES } = state.reducedAssolement,
        SAU = getValueOrZero(state, "HVE3A_D_53") - (PPNFERTI + PPFERTINT + PPAUTRES);
      let val =
        isNaN(surfaceCulturePrincipale) || SAU === 0 || surfaceCulturePrincipale > SAU
          ? 0
          : (surfaceCulturePrincipale / SAU) * 100;
      const computedValue = state.indicateurs.HVE3A_I_10.valeur.newValue ?? val;

      const regexPT = /PT/g;
      if (culturePrincipale.match(regexPT) !== null && val > 10) {
        if (cultureSecondaire.surface > 10) {
          // si autre culture à plus de 10% on prend sa valeur
          val = cultureSecondaire.surface;
        } else {
          val = 10;
        }
      }
      return {
        valeur: {
          ...state.indicateurs.HVE3A_I_10.valeur,
          value: val > 100 ? 100 : val,
          note:
            computedValue === 0
              ? 0
              : computedValue >= 70
              ? 0
              : computedValue < 70 && computedValue >= 60
              ? 1
              : computedValue < 60 && computedValue >= 50
              ? 2
              : computedValue < 50 && computedValue >= 40
              ? 3
              : computedValue < 40 && computedValue >= 30
              ? 4
              : computedValue < 30 && computedValue >= 20
              ? 5
              : 6,
        },
      };
    },
  },
  HVE3A_I_11: {
    columns: [
      "HVE3A_D_24",
      "HVE3A_D_26",
      "HVE3A_D_48",
      "HVE3A_I_11",
      "HVE3A_I_12",
      "HVE3A_I_13",
      "HVE3A_I_14",
      "HVE3A_I_15",
      "HVE3A_I_93",
    ],
    disabled: true,
    computedValue: (state) => {
      const HVE3A_I_13 = getValue(state, "HVE3A_I_13") === true ? 2 : 0,
        HVE3A_I_14 = getValue(state, "HVE3A_I_14") === true ? 3 : 0,
        HVE3A_I_15 = Math.ceil(getValueOrZero(state, "HVE3A_I_15") * 0.1),
        HVE3A_I_93 = getValue(state, "HVE3A_I_93") === true ? 2 : 0,
        HVE3A_D_42 = getValueOrZero(state, "HVE3A_D_42") > 0 ? 1 : 0,
        duplicateMais =
          state.assolement.filter((a) => ["MAIS", "MAISFOUR"].includes(a.codeTypeCulture)).length === 2 ? 1 : 0,
        value =
          getValueOrZero(state, "HVE3A_D_48") +
          getValueOrZero(state, "HVE3A_I_12") +
          HVE3A_I_13 +
          HVE3A_I_14 +
          HVE3A_I_93 +
          HVE3A_I_15 +
          HVE3A_D_42 +
          getValueOrZero(state, "HVE3A_D_49") +
          getValueOrZero(state, "HVE3A_D_50") +
          getValueOrZero(state, "HVE3A_D_51") +
          getValueOrZero(state, "HVE3A_D_52") -
          duplicateMais;
      const computedValue = state.indicateurs.HVE3A_I_11.valeur.newValue ?? value;
      return {
        valeur: {
          ...state.indicateurs.HVE3A_I_11.valeur,
          value: value,
          note:
            computedValue <= 3
              ? 0
              : computedValue === 4
              ? 1
              : computedValue === 5
              ? 2
              : computedValue === 6
              ? 3
              : computedValue === 7
              ? 4
              : computedValue === 8
              ? 5
              : computedValue === 9
              ? 6
              : computedValue >= 10
              ? 7
              : 0,
        },
      };
    },
  },
  HVE3A_I_13: {
    columns: ["HVE3A_D_27"],
    disabled: true,
    computedValue: (state) => {
      const value = getValue(state, "HVE3A_D_27") > 0;
      return {
        valeur: {
          ...state.indicateurs.HVE3A_I_13.valeur,
          value,
        },
      };
    },
  },
  HVE3A_I_93: {
    columns: ["HVE3A_D_25"],
    disabled: true,
    computedValue: (state) => {
      const value = getValue(state, "HVE3A_D_25") > 0;
      return {
        valeur: {
          ...state.indicateurs.HVE3A_I_93.valeur,
          value,
        },
      };
    },
  },
  HVE3A_I_14: {
    columns: ["HVE3A_D_28"],
    disabled: true,
    computedValue: (state) => {
      const value = getValue(state, "HVE3A_D_28") > 0;
      return {
        valeur: {
          ...state.indicateurs.HVE3A_I_14.valeur,
          value,
        },
      };
    },
  },
  HVE3A_I_15: {
    disabled: true,
    columns: ["HVE3A_D_29", "HVE3A_D_30", "HVE3A_D_31", "HVE3A_D_53"],
    computedValue: (state) => {
      const HVE3A_D_53 = getValueOrZero(state, "HVE3A_D_53"),
        HVE3A_D_29 = getValueOrZero(state, "HVE3A_D_29"),
        HVE3A_D_30 = getValueOrZero(state, "HVE3A_D_30"),
        HVE3A_D_31 = getValueOrZero(state, "HVE3A_D_31"),
        val = HVE3A_D_53 === 0 ? null : ((HVE3A_D_29 + HVE3A_D_30 + HVE3A_D_31) / HVE3A_D_53) * 100;
      return {
        valeur: {
          ...state.indicateurs.HVE3A_I_15.valeur,
          value: val > 100 ? 100 : val,
        },
      };
    },
  },
  HVE3A_I_16: {
    columns: ["HVE3A_D_61"],
    disabled: true,
    computedValue: (state) => {
      const value = getValueOrZero(state, "HVE3A_D_61"),
        note = value >= 3 ? 3 : value < 3 && value >= 2 ? 2 : value < 2 && value >= 1 ? 1 : 0;
      return {
        valeur: {
          ...state.indicateurs.HVE3A_I_16.valeur,
          value,
          note,
        },
      };
    },
  },
  HVE3A_I_17: {
    columns: ["HVE3A_D_60"],
    disabled: true,
    computedValue: (state) => {
      const value = getValueOrZero(state, "HVE3A_D_60") > 0,
        note = value ? 1 : 0;
      return {
        valeur: {
          ...state.indicateurs.HVE3A_I_17.valeur,
          value,
          note,
        },
      };
    },
  },
  HVE3A_I_18: {
    columns: ["HVE3A_D_62"],
    disabled: true,
    computedValue: (state) => {
      const HVE3A_D_62 = getValueOrZero(state, "HVE3A_D_62");
      return {
        valeur: {
          value: HVE3A_D_62,
          note: HVE3A_D_62 > 3 ? 3 : HVE3A_D_62,
        },
      };
    },
  },
  HVE3A_I_19: {
    columns: ["HVE3A_D_172"],
    disabled: true,
    computedValue: (state) => {
      const HVE3A_D_172 = getValueOrZero(state, "HVE3A_D_172");
      return {
        valeur: {
          value: HVE3A_D_172,
          note: Math.min(3, HVE3A_D_172),
        },
      };
    },
  },
  HVE3A_I_20: {
    columns: ["HVE3A_D_53", "HVE3A_D_84", "HVE3A_D_85", "HVE3A_D_86"],
    disabled: true,
    computedValue: (state) => {
      const SAU = getValueOrZero(state, "HVE3A_D_53"),
        HVE3A_D_84 = getValueOrZero(state, "HVE3A_D_84"),
        HVE3A_D_85 = getValueOrZero(state, "HVE3A_D_85"),
        HVE3A_D_86 = getValueOrZero(state, "HVE3A_D_86");
      const surfacesNonTraitees = HVE3A_D_84 + HVE3A_D_85 + HVE3A_D_86;
      const val = SAU === 0 || surfacesNonTraitees === 0 ? null : (surfacesNonTraitees / SAU) * 100;
      return {
        valeur: {
          value: val > 100 ? 100 : val,
          note:
            0 < val && val <= 10
              ? 1
              : 10 < val && val <= 20
              ? 2
              : 20 < val && val <= 30
              ? 3
              : 30 < val && val <= 40
              ? 4
              : 40 < val && val <= 50
              ? 5
              : 50 < val && val <= 60
              ? 6
              : 60 < val && val <= 70
              ? 7
              : 70 < val && val <= 80
              ? 8
              : 80 < val && val <= 90
              ? 9
              : 90 < val
              ? 10
              : 0,
        },
      };
    },
  },
  HVE3A_I_41: {
    columns: ["HVE3A_I_1", "HVE3A_D_88"],
    disabled: true,
    computedValue: (state) => {
      const HVE3A_D_88 = getValueOrZero(state, "HVE3A_D_88");
      const SAU = getValueOrZero(state, "HVE3A_I_1");
      const val = SAU > 0 ? (HVE3A_D_88 / SAU) * 100 : null;
      return {
        valeur: {
          value: val > 100 ? 100 : val,
          note: val < 25 ? 0 : val >= 25 && val < 50 ? 1 : val >= 50 && val < 75 ? 2 : 3,
        },
      };
    },
  },
  HVE3A_I_43: {
    columns: ["HVE3A_D_90", "HVE3A_D_53"],
    disabled: true,
    computedValue: (state) => {
      const HVE3A_D_90 = getValueOrZero(state, "HVE3A_D_90"),
        SAU = getValueOrZero(state, "HVE3A_D_53"),
        val = SAU !== 0 ? (HVE3A_D_90 / SAU) * 100 : null;
      return {
        valeur: {
          value: val > 100 ? 100 : val,
          note:
            0 < val && val <= 10
              ? 1
              : 10 < val && val <= 20
              ? 2
              : 20 < val && val <= 30
              ? 3
              : 30 < val && val <= 40
              ? 4
              : 40 < val && val <= 50
              ? 5
              : 50 < val && val <= 60
              ? 6
              : 60 < val && val <= 70
              ? 7
              : 70 < val && val <= 80
              ? 8
              : 80 < val && val <= 90
              ? 9
              : 90 < val
              ? 10
              : 0,
        },
      };
    },
  },
  HVE3A_I_44: {
    columns: ["HVE3A_D_93"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          ...state.indicateurs.HVE3A_D_93.valeur,
          note:
            state.indicateurs.HVE3A_D_92.valeur.value === true
              ? 2
              : state.indicateurs.HVE3A_D_93.valeur.value.length === 1
              ? 1
              : state.indicateurs.HVE3A_D_93.valeur.value.length >= 2
              ? 2
              : 0,
        },
      };
    },
  },
  HVE3A_I_45: {
    columns: ["HVE3A_I_45"],
    disabled: false,
    computedValue: (state) => {
      const valeur = state.indicateurs.HVE3A_I_45.valeur,
        nbClones = Number(valeur.vigne_nb_clones?.value ?? 0),
        horsSolNbVarietes = Number(valeur.hors_sol?.nb_varietes ?? 0),
        pointsArbo =
          Number(valeur.arboriculture?.nb_especes_2_varietes ?? 0) +
          Number(valeur.arboriculture?.nb_especes_3_varietes ?? 0) * 2,
        noteArbo = Math.min(pointsArbo, 6),
        pointsLegumes =
          Number(valeur.legumes_fleurs_fruits?.nb_especes_2_varietes ?? 0) +
          Number(valeur.legumes_fleurs_fruits?.nb_especes_3_varietes ?? 0) * 2,
        noteLegumes = Math.min(pointsLegumes, 6);
      return {
        valeur: {
          ...valeur,
          vigne_nb_clones: {
            ...valeur.vigne_nb_clones,
            note: nbClones === 2 ? 1 : nbClones >= 3 ? 2 : 0,
          },
          arboriculture: {
            ...valeur.arboriculture,
            note: noteArbo,
          },
          legumes_fleurs_fruits: {
            ...valeur.legumes_fleurs_fruits,
            note: noteLegumes,
          },
          hors_sol: {
            ...valeur.hors_sol,
            note: horsSolNbVarietes >= 3 ? 2 : horsSolNbVarietes === 2 ? 1 : 0,
          },
        },
      };
    },
  },

  HVE3A_I_49: {
    columns: ["HVE3A_D_169", "HVE3A_I_4"],
    disabled: true,
    computedValue: (state) => {
      const HVE3A_D_169 = getValueOrZero(state, "HVE3A_D_169"),
        HVE3A_I_4 = getValueOrZero(state, "HVE3A_I_4"),
        val = HVE3A_I_4 <= 0 ? 0 : (HVE3A_D_169 / HVE3A_I_4) * 100;
      return {
        valeur: {
          ...state.indicateurs.HVE3A_I_49.valeur,
          value: val > 100 ? 100 : val,
          note: val < 25 ? 0 : val >= 25 && val < 50 ? 1 : val >= 50 && val < 75 ? 2 : 3,
        },
      };
    },
  },
  HVE3A_I_50: {
    columns: ["HVE3A_D_170", "HVE3A_I_3"],
    disabled: true,
    computedValue: (state) => {
      const HVE3A_D_170 = getValueOrZero(state, "HVE3A_D_170"),
        HVE3A_I_3 = getValueOrZero(state, "HVE3A_I_3"),
        val = HVE3A_I_3 <= 0 ? 0 : (HVE3A_D_170 / HVE3A_I_3) * 100;
      return {
        valeur: {
          ...state.indicateurs.HVE3A_I_50.valeur,
          value: val > 100 ? 100 : val,
          note: val < 25 ? 0 : val >= 25 && val < 50 ? 1 : val >= 50 && val < 75 ? 2 : 3,
        },
      };
    },
  },
  HVE3A_I_51: {
    columns: ["HVE3A_I_51"],
    disabled: false,
    computedValue: (state) => {
      const HVE3A_I_51 = getValueOrZero(state, "HVE3A_I_51");
      return {
        valeur: {
          ...state.indicateurs.HVE3A_I_51.valeur,
          note:
            HVE3A_I_51 < 25 ? 0 : HVE3A_I_51 >= 25 && HVE3A_I_51 < 50 ? 1 : HVE3A_I_51 >= 50 && HVE3A_I_51 < 7 ? 2 : 3,
        },
      };
    },
  },
  HVE3A_I_52: {
    columns: ["HVE3A_I_52"],
    disabled: false,
    computedValue: (state) => {
      const value = getValueOrZero(state, "HVE3A_I_52") > 100 ? 100 : getValue(state, "HVE3A_I_52");
      return {
        valeur: {
          value,
          note: Math.ceil(value * 0.1),
        },
      };
    },
  },
  HVE3A_I_53: {
    columns: ["HVE3A_I_53"],
    disabled: false,
    computedValue: (state) => {
      const HVE3A_I_53 = getValueOrZero(state, "HVE3A_I_53");
      return {
        valeur: {
          ...state.indicateurs.HVE3A_I_53.valeur,
          note:
            getValue(state, "HVE3A_I_53") == null
              ? 0
              : HVE3A_I_53 <= 50
              ? 2
              : HVE3A_I_53 > 50 && HVE3A_I_53 <= 75
              ? 4
              : 6,
        },
      };
    },
  },
  HVE3A_I_77: {
    columns: ["HVE3A_I_77"],
    disabled: false,
    computedValue: (state) => {
      const HVE3A_I_77 = getValueOrZero(state, "HVE3A_I_77");
      return {
        valeur: {
          ...state.indicateurs.HVE3A_I_77.valeur,
          note:
            getValue(state, "HVE3A_I_77") == null
              ? 0
              : HVE3A_I_77 <= 50
              ? 1
              : HVE3A_I_77 > 50 && HVE3A_I_77 <= 75
              ? 2
              : 3,
        },
      };
    },
  },
  HVE3A_I_54: {
    columns: ["HVE3A_D_117", "HVE3A_D_126", "HVE3A_D_100"],
    disabled: true,
    computedValue: (state) => {
      const HVE3A_D_117 = getValue(state, "HVE3A_D_117");
      const HVE3A_D_126 = getValue(state, "HVE3A_D_126");
      const HVE3A_D_100 = state.indicateurs.HVE3A_D_100.valeur.value;
      const val = HVE3A_D_100 === "Bilan apparent" ? HVE3A_D_117 : HVE3A_D_126;
      return {
        valeur: {
          ...state.indicateurs.HVE3A_I_54.valeur,
          value: val,
          note:
            HVE3A_D_100 !== "Bilan apparent"
              ? // calcul note selon méthode "BGA ou Bilan Corpen" ou pas de choix
                val == null
                ? null
                : val > 60
                ? 0
                : val <= 60 && val > 40
                ? 5
                : 10
              : // calcul note selon méthode "Bilan apparent"
              val == null
              ? null
              : val > 80
              ? 0
              : val <= 80 && val > 60
              ? 5
              : 10,
        },
      };
    },
  },
  HVE3A_I_55: {
    columns: ["HVE3A_D_100"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        ...state.indicateurs.HVE3A_I_55.valeur,
        value: state.indicateurs.HVE3A_D_100.valeur.value,
      },
    }),
  },
  HVE3A_I_58: {
    columns: ["HVE3A_I_1", "HVE3A_D_98", "HVE3A_D_128"],
    disabled: true,
    computedValue: (state) => {
      const HVE3A_I_1 = getValueOrZero(state, "HVE3A_I_1"),
        HVE3A_D_98 = getValueOrNewValue(state, "HVE3A_D_98"),
        HVE3A_D_128 = getValueOrZero(state, "HVE3A_D_128"),
        val = HVE3A_I_1 - HVE3A_D_98 > 0 ? (HVE3A_D_128 / (HVE3A_I_1 - HVE3A_D_98)) * 100 : null;
      return {
        valeur: {
          ...state.indicateurs.HVE3A_I_58.valeur,
          value: val > 100 ? 100 : val,
          note: val < 50 ? 0 : 1,
        },
      };
    },
  },
  HVE3A_I_59: {
    columns: ["HVE3A_I_1", "HVE3A_D_98", "HVE3A_D_131"],
    disabled: true,
    computedValue: (state) => {
      const HVE3A_I_1 = getValueOrZero(state, "HVE3A_I_1"),
        HVE3A_D_98 = getValueOrNewValue(state, "HVE3A_D_98"),
        HVE3A_D_131 = getValueOrZero(state, "HVE3A_D_131"),
        val = HVE3A_I_1 - HVE3A_D_98 !== 0 ? (HVE3A_D_131 / (HVE3A_I_1 - HVE3A_D_98)) * 100 : null;
      return {
        valeur: {
          ...state.indicateurs.HVE3A_I_59.valeur,
          value: val > 100 ? 100 : val,
          note: val < 50 ? 0 : 2,
        },
      };
    },
  },
  HVE3A_I_60: {
    columns: ["HVE3A_D_98", "HVE3A_D_129"],
    disabled: true,
    computedValue: (state) => {
      const HVE3A_D_98 = getValueOrNewValue(state, "HVE3A_D_98"),
        HVE3A_D_129 = getValueOrZero(state, "HVE3A_D_129"),
        SAU = getValueOrZero(state, "HVE3A_D_53"),
        pourcentageSurface = HVE3A_D_98 < 0 || SAU === 0 ? 0 : (HVE3A_D_98 / SAU) * 100,
        val = HVE3A_D_98 !== 0 ? (HVE3A_D_129 / HVE3A_D_98) * 100 : null;
      return {
        valeur: {
          ...state.indicateurs.HVE3A_I_60.valeur,
          value: val > 100 ? 100 : val,
          note: val < 50 || pourcentageSurface < 5 ? 0 : 1,
        },
      };
    },
  },
  HVE3A_I_61: {
    columns: ["HVE3A_D_98", "HVE3A_D_132"],
    disabled: true,
    computedValue: (state) => {
      const HVE3A_D_98 = getValueOrNewValue(state, "HVE3A_D_98"),
        HVE3A_D_132 = getValueOrZero(state, "HVE3A_D_132"),
        SAU = getValueOrZero(state, "HVE3A_D_53"),
        pourcentageSurface = HVE3A_D_98 < 0 || SAU === 0 ? 0 : (HVE3A_D_98 / SAU) * 100,
        val = HVE3A_D_98 === 0 ? 0 : (HVE3A_D_132 / HVE3A_D_98) * 100;
      return {
        valeur: {
          ...state.indicateurs.HVE3A_I_61.valeur,
          value: val > 100 ? 100 : val,
          note:
            val <= 30 || pourcentageSurface < 5
              ? 0
              : val > 30 && val <= 40
              ? 1
              : val > 40 && val <= 50
              ? 2
              : val > 50 && val <= 60
              ? 3
              : val > 60 && val <= 70
              ? 4
              : val > 70 && val <= 80
              ? 5
              : val > 80 && val <= 90
              ? 6
              : 7,
        },
      };
    },
  },
  HVE3A_I_62: {
    columns: ["HVE3A_D_133", "HVE3A_D_134", "HVE3A_I_1"],
    disabled: true,
    computedValue: (state) => {
      const HVE3A_D_133 = getValueOrZero(state, "HVE3A_D_133"),
        HVE3A_D_134 = getValueOrZero(state, "HVE3A_D_134"),
        HVE3A_I_1 = getValueOrZero(state, "HVE3A_I_1"),
        val = HVE3A_I_1 <= 0 ? 0 : ((HVE3A_D_133 + HVE3A_D_134) / HVE3A_I_1) * 100;
      return {
        valeur: {
          ...state.indicateurs.HVE3A_I_62.valeur,
          value: val,
          note:
            val === 0
              ? 0
              : val <= 10
              ? 1
              : val > 10 && val <= 20
              ? 2
              : val > 20 && val <= 30
              ? 3
              : val > 30 && val <= 40
              ? 4
              : val > 40 && val <= 50
              ? 5
              : val > 50 && val <= 60
              ? 6
              : val > 60 && val <= 70
              ? 7
              : val > 70 && val <= 80
              ? 8
              : val > 80 && val <= 90
              ? 9
              : 10,
        },
      };
    },
  },
  HVE3A_I_63: {
    columns: ["HVE3A_D_136", "HVE3A_D_53"],
    disabled: true,
    computedValue: (state) => {
      const SAU = getValueOrZero(state, "HVE3A_D_53");
      const HVE3A_D_136 = getValueOrZero(state, "HVE3A_D_136");
      const val = SAU === 0 ? 0 : (HVE3A_D_136 / SAU) * 100;
      return {
        valeur: {
          value: val > 100 ? 100 : val,
          note: val < 5 ? 0 : 2,
        },
      };
    },
  },
  HVE3A_I_64: {
    columns: ["HVE3A_D_137", "HVE3A_D_53"],
    disabled: true,
    computedValue: (state) => {
      const SAU = getValueOrZero(state, "HVE3A_D_53");
      const HVE3A_D_137 = getValueOrZero(state, "HVE3A_D_137");
      const val = SAU === 0 ? 0 : (HVE3A_D_137 / SAU) * 100;
      return {
        valeur: {
          value: val > 100 ? 100 : val,
          note: val < 5 ? 0 : val >= 5 && val < 10 ? 1 : 2,
        },
      };
    },
  },
  HVE3A_I_65: {
    columns: ["HVE3A_D_139", "HVE3A_I_1", "HVE3A_I_3", "HVE3A_I_4"],
    disabled: true,
    computedValue: (state) => {
      const HVE3A_D_139 = getValueOrZero(state, "HVE3A_D_139"),
        HVE3A_I_1 = getValueOrZero(state, "HVE3A_I_1"),
        HVE3A_I_3 = getValueOrZero(state, "HVE3A_I_3"),
        HVE3A_I_4 = getValueOrZero(state, "HVE3A_I_4"),
        val = HVE3A_I_1 - HVE3A_I_3 - HVE3A_I_4 === 0 ? 0 : (HVE3A_D_139 / (HVE3A_I_1 - HVE3A_I_3 - HVE3A_I_4)) * 100;
      return {
        valeur: {
          value: round(val, 1),
          note: val < 75 ? 0 : val >= 75 && val < 100 ? 1 : 3,
        },
      };
    },
  },
  HVE3A_I_66: {
    columns: ["HVE3A_D_140", "HVE3A_I_3", "HVE3A_I_4"],
    disabled: true,
    computedValue: (state) => {
      const HVE3A_D_140 = getValueOrZero(state, "HVE3A_D_140"),
        HVE3A_I_3 = getValueOrZero(state, "HVE3A_I_3"),
        HVE3A_I_4 = getValueOrZero(state, "HVE3A_I_4"),
        val = Math.floor((HVE3A_D_140 / (HVE3A_I_4 + HVE3A_I_3)) * 100);
      return {
        valeur: {
          value: val,
          note: val < 50 ? 0 : val >= 50 && val < 75 ? 1 : val >= 75 && val < 100 ? 2 : 3,
        },
      };
    },
  },
  HVE3A_I_68: {
    columns: ["HVE3A_D_157"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        ...state.indicateurs.HVE3A_I_68.valeur,
        value: getValueOrZero(state, "HVE3A_D_157"),
      },
    }),
  },
  HVE3A_I_69: {
    columns: ["HVE3A_D_149"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        ...state.indicateurs.HVE3A_I_69.valeur,
        value: getValueOrZero(state, "HVE3A_D_149"),
      },
    }),
  },
  HVE3A_I_70: {
    columns: ["HVE3A_D_145", "HVE3A_D_146", "HVE3A_D_147", "HVE3A_D_149", "HVE3A_D_157"],
    disabled: true,
    computedValue: (state) => {
      const HVE3A_D_145 = getValue(state, "HVE3A_D_145"),
        HVE3A_D_146 = getValueOrZero(state, "HVE3A_D_146"),
        HVE3A_D_147 = getValueOrZero(state, "HVE3A_D_147"),
        HVE3A_D_149 = getValueOrZero(state, "HVE3A_D_149"),
        HVE3A_D_157 = getValueOrZero(state, "HVE3A_D_157"),
        val =
          (HVE3A_D_146 !== 0 || HVE3A_D_147 !== 0) && HVE3A_D_145
            ? (100 * (HVE3A_D_157 + HVE3A_D_149)) / (HVE3A_D_146 * 5 + HVE3A_D_147 * 7)
            : null;
      return {
        valeur: {
          value: val > 100 ? 100 : val,
          note: val >= 30 ? 0 : val < 30 && val >= 20 ? 2 : val < 20 && val >= 10 ? 4 : val == null ? 0 : 6,
        },
      };
    },
  },
  HVE3A_I_71: {
    columns: ["HVE3A_I_71"],
    disabled: false,
    computedValue: (state) => {
      const HVE3A_I_71 = getValueOrZero(state, "HVE3A_I_71");
      return {
        valeur: {
          ...state.indicateurs.HVE3A_I_71.valeur,
          note: HVE3A_I_71 < 50 ? 0 : HVE3A_I_71 >= 50 && HVE3A_I_71 < 75 ? 1 : 2,
        },
      };
    },
  },
  HVE3A_I_72: {
    columns: ["HVE3A_D_165", "HVE3A_D_142"],
    disabled: true,
    computedValue: (state) => {
      const HVE3A_D_165 = getValueOrZero(state, "HVE3A_D_165");
      const HVE3A_D_142 = getValueOrZero(state, "HVE3A_D_142");
      const val = HVE3A_D_142 > 0 ? (HVE3A_D_165 / HVE3A_D_142) * 100 : null;
      return {
        valeur: {
          value: val > 100 ? 100 : val,
          note: val < 25 ? 0 : val >= 25 && val < 50 ? 2 : val >= 50 && val < 75 ? 4 : 6,
        },
      };
    },
  },
  HVE3A_I_73: {
    columns: ["HVE3A_I_73"],
    disabled: false,
    computedValue: (state) => ({
      valeur: {
        ...state.indicateurs.HVE3A_I_73.valeur,
        note: !!state.indicateurs.HVE3A_I_73.valeur.value ? 2 : 0,
      },
    }),
  },
  HVE3A_I_74: {
    columns: ["HVE3A_D_167", "HVE3A_D_142"],
    disabled: true,
    computedValue: (state) => {
      const HVE3A_D_167 = getValueOrZero(state, "HVE3A_D_167");
      const HVE3A_D_142 = getValueOrZero(state, "HVE3A_D_142");
      const val = HVE3A_D_142 > 0 ? (HVE3A_D_167 / HVE3A_D_142) * 100 : null;
      return {
        valeur: {
          value: val > 100 ? 100 : val,
          note: val < 25 ? 0 : val >= 25 && val < 50 ? 2 : val >= 50 && val < 75 ? 4 : 6,
        },
      };
    },
  },
  HVE3A_I_75: {
    columns: ["HVE3A_D_143", "HVE3A_D_144"],
    disabled: true,
    computedValue: (state) => {
      const HVE3A_D_143 = getValueOrZero(state, "HVE3A_D_143");
      const HVE3A_D_144 = getValueOrZero(state, "HVE3A_D_144");
      const val = HVE3A_D_144 + HVE3A_D_143 <= 0 ? 0 : (HVE3A_D_144 / (HVE3A_D_143 + HVE3A_D_144)) * 100;
      return {
        valeur: {
          value: val > 100 ? 100 : val,
          note:
            val >= 90
              ? 0
              : val < 90 && val >= 80
              ? 1
              : val < 80 && val >= 60
              ? 2
              : val < 60 && val >= 40
              ? 3
              : val < 40 && val >= 20
              ? 4
              : 5,
        },
      };
    },
  },
  HVE3A_I_76: {
    columns: ["HVE3A_I_76"],
    disabled: false,
    computedValue: (state) => ({
      valeur: {
        ...state.indicateurs.HVE3A_I_76.valeur,
        note: !!state.indicateurs.HVE3A_I_76.valeur.value ? 1 : 0,
      },
    }),
  },
  // Items GC PT
  HVE3A_I_78: {
    // gérer le HVE3A_I_21 pour ne prendre que l'IFT des GC
    columns: ["HVE3A_I_1", "HVE3A_I_2", "HVE3A_I_21"],
    disabled: true,
    computedValue: (state) => {
      const IFT = state.indicateurs.HVE3A_I_21?.valeur,
        value = (IFT?.IFT_hors_herbicide_GC_PT_campagne?.note ?? 0) + (IFT?.IFT_herbicide_GC_PT_campagne?.note ?? 0),
        SAU = getValueOrZero(state, "HVE3A_I_1"),
        HVE3A_I_2 = getValueOrZero(state, "HVE3A_I_2"),
        coeff = SAU === 0 ? 0 : Math.min(1, HVE3A_I_2 / SAU),
        note = value * coeff;
      return {
        valeur: {
          ...state.indicateurs.HVE3A_I_78.valeur,
          value,
          coeff,
          note,
        },
      };
    },
  },

  // Items Horti et Pépinière
  HVE3A_I_80: {
    columns: [
      "HVE3A_I_1",
      "HVE3A_I_7",
      "HVE3A_I_40",
      "HVE3A_I_42",
      "HVE3A_I_51",
      "HVE3A_I_52",
      "HVE3A_I_53",
      "HVE3A_I_77",
    ],
    disabled: true,
    computedValue: (state) => {
      const HVE3A_I_40 = getNoteOrZero(state, "HVE3A_I_40"),
        HVE3A_I_42 = getNoteOrZero(state, "HVE3A_I_42"),
        HVE3A_I_51 = getNoteOrZero(state, "HVE3A_I_51"),
        HVE3A_I_52 = getNoteOrZero(state, "HVE3A_I_52"),
        HVE3A_I_53 = getNoteOrZero(state, "HVE3A_I_53"),
        HVE3A_I_77 = getNoteOrZero(state, "HVE3A_I_77");
      const value = HVE3A_I_40 + HVE3A_I_42 + HVE3A_I_51 + HVE3A_I_52 + HVE3A_I_53 + HVE3A_I_77,
        SAU = getValueOrZero(state, "HVE3A_I_1"),
        HVE3A_I_7 = getValueOrZero(state, "HVE3A_I_7"),
        coeff = SAU === 0 ? 0 : Math.min(1, HVE3A_I_7 / SAU),
        note = value * coeff;
      return {
        valeur: {
          ...state.indicateurs.HVE3A_I_80.valeur,
          value,
          coeff,
          note,
        },
      };
    },
  },
  // Items Vignes
  HVE3A_I_82: {
    // Gérer le HVE3A_I_21 et le HVE3A_I_45 qui sont des méta-indicateurs (prendre que les items viti)
    columns: ["HVE3A_I_1", "HVE3A_I_4", "HVE3A_I_21", "HVE3A_I_45", "HVE3A_I_49"],
    disabled: true,
    computedValue: (state) => {
      const IFT = state.indicateurs.HVE3A_I_21?.valeur,
        diversiteVarietale = state.indicateurs.HVE3A_I_45?.valeur,
        value =
          (IFT?.IFT_herbicide_vigne_campagne?.note ?? 0) +
          (IFT?.IFT_hors_herbicide_vigne_campagne?.note ?? 0) +
          (diversiteVarietale?.vigne_nb_clones?.note ?? 0) +
          getNoteOrZero(state, "HVE3A_I_49"),
        SAU = getValueOrZero(state, "HVE3A_I_1"),
        HVE3A_I_4 = getValueOrZero(state, "HVE3A_I_4"),
        coeff = SAU === 0 ? 0 : Math.min(1, HVE3A_I_4 / SAU),
        note = value * coeff;
      return {
        valeur: {
          ...state.indicateurs.HVE3A_I_82.valeur,
          value,
          note,
          coeff,
        },
      };
    },
  },
  // Items Légumes Fruits hors arbo
  HVE3A_I_83: {
    // Gérer le HVE3A_I_45 qui est un méta-indicateur (prendre que les diversités du Légumes Fruits hors arbo)
    columns: ["HVE3A_I_1", "HVE3A_I_5", "HVE3A_I_45"],
    disabled: true,
    computedValue: (state) => {
      const value = Number(state.indicateurs.HVE3A_I_45?.valeur?.legumes_fleurs_fruits?.note ?? 0),
        SAU = getValueOrZero(state, "HVE3A_I_1"),
        HVE3A_I_5 = getValueOrZero(state, "HVE3A_I_5"),
        coeff = SAU === 0 ? 0 : Math.min(1, HVE3A_I_5 / SAU),
        note = value * coeff;
      return {
        valeur: {
          ...state.indicateurs.HVE3A_I_83.valeur,
          value,
          coeff,
          note,
        },
      };
    },
  },
  // Items Arboriculture
  HVE3A_I_84: {
    // Gérer le HVE3A_I_45 qui est un méta-indicateur (prendre que les diversités arbo)
    columns: ["HVE3A_I_1", "HVE3A_D_43", "HVE3A_D_44", "HVE3A_I_45", "HVE3A_I_50"],
    disabled: true,
    computedValue: (state) => {
      const value =
          getNoteOrZero(state, "HVE3A_I_50") + Number(state.indicateurs.HVE3A_I_45?.valeur?.arboriculture?.note ?? 0),
        SAU = getValueOrZero(state, "HVE3A_I_1"),
        HVE3A_D_43 = getValueOrZero(state, "HVE3A_D_43"),
        HVE3A_D_44 = getValueOrZero(state, "HVE3A_D_44"),
        coeff = SAU === 0 ? 0 : Math.min(1, (HVE3A_D_43 + HVE3A_D_44) / SAU),
        note = value * coeff;
      return {
        valeur: {
          ...state.indicateurs.HVE3A_I_84.valeur,
          value,
          coeff,
          note,
        },
      };
    },
  },
  // Items Commun
  HVE3A_I_85: {
    // Gérer le HVE3A_I_45 qui est un méta-indicateur (prendre que les diversités du hors sol)
    columns: ["HVE3A_I_1", "HVE3A_I_20", "HVE3A_I_41", "HVE3A_I_43", "HVE3A_I_44", "HVE3A_I_45"],
    disabled: true,
    computedValue: (state) => {
      const noteHorsSol = Number(state.indicateurs["HVE3A_I_45"]?.valeur?.hors_sol?.note ?? 0),
        SAU = getValueOrZero(state, "HVE3A_I_1"),
        value =
          getNoteOrZero(state, "HVE3A_I_20") +
          getNoteOrZero(state, "HVE3A_I_41") +
          getNoteOrZero(state, "HVE3A_I_43") +
          getNoteOrZero(state, "HVE3A_I_44") +
          // Diversité variétale part du hors sol
          noteHorsSol,
        coeff = SAU === 0 ? 0 : 1,
        note = value * coeff;
      return {
        valeur: {
          ...state.indicateurs.HVE3A_I_85.valeur,
          value,
          coeff,
          note,
        },
      };
    },
  },
  HVE3A_I_92: {
    columns: ["HVE3A_D_163"],
    disabled: true,
    computedValue: (state) => {
      const val = getValue(state, "HVE3A_I_92");
      const val_irri_oad = getValue(state, "HVE3A_D_163");
      return {
        valeur: {
          value: val_irri_oad !== "",
          note: val === true ? 2 : 0,
        },
      };
    },
  },
  HVE3A_I_86: {
    columns: ["HVE3A_D_36"],
    disabled: true,
    computedValue: (state) => {
      const value = getValueOrZero(state, "HVE3A_D_36");
      return {
        valeur: {
          value,
        },
      };
    },
  },
  HVE3A_I_87: {
    columns: ["HVE3A_D_8"],
    disabled: true,
    computedValue: (state) => {
      const value = getValueOrZero(state, "HVE3A_D_8");
      return {
        valeur: {
          value,
        },
      };
    },
  },
  HVE3A_I_88: {
    columns: ["HVE3A_D_3", "HVE3A_D_21"],
    disabled: true,
    computedValue: (state) => {
      const value = round(getValueOrZero(state, "HVE3A_D_3") + getValueOrZero(state, "HVE3A_D_21"), 2);
      return {
        valeur: {
          value,
        },
      };
    },
  },
  HVE3A_I_89: {
    columns: ["HVE3A_D_15", "HVE3A_D_24", "HVE3A_D_25", "HVE3A_D_26", "HVE3A_D_27", "HVE3A_D_28"],
    disabled: true,
    computedValue: (state) => {
      const HVE3A_D_15 = getValueOrZero(state, "HVE3A_D_15"),
        HVE3A_D_24 = getValueOrZero(state, "HVE3A_D_24"),
        HVE3A_D_25 = getValueOrZero(state, "HVE3A_D_25"),
        HVE3A_D_26 = getValueOrZero(state, "HVE3A_D_26"),
        HVE3A_D_27 = getValueOrZero(state, "HVE3A_D_27"),
        HVE3A_D_28 = getValueOrZero(state, "HVE3A_D_28"),
        value = round(HVE3A_D_15 + HVE3A_D_24 + HVE3A_D_25 + HVE3A_D_26 + HVE3A_D_27 + HVE3A_D_28, 2);
      return {
        valeur: {
          value,
        },
      };
    },
  },
  HVE3A_I_90: {
    columns: ["HVE3A_I_2", "HVE3A_I_86", "HVE3A_I_87", "HVE3A_I_88", "HVE3A_I_89"],
    disabled: true,
    computedValue: (state) => {
      const HVE3A_I_2 = getValueOrZero(state, "HVE3A_I_2"),
        HVE3A_I_86 = getValueOrZero(state, "HVE3A_I_86"),
        HVE3A_I_87 = getValueOrZero(state, "HVE3A_I_87"),
        HVE3A_I_88 = getValueOrZero(state, "HVE3A_I_88"),
        HVE3A_I_89 = getValueOrZero(state, "HVE3A_I_89"),
        val = round(HVE3A_I_2 - (HVE3A_I_86 + HVE3A_I_87 + HVE3A_I_88 + HVE3A_I_89), 2);
      return {
        valeur: {
          value: val <= 0 ? 0 : val,
        },
      };
    },
  },
  HVE3A_I_91: {
    columns: ["HVE3A_I_2", "HVE3A_I_87", "HVE3A_I_88", "HVE3A_I_89"],
    disabled: true,
    computedValue: (state) => {
      const HVE3A_I_2 = getValueOrZero(state, "HVE3A_I_2"),
        HVE3A_I_87 = getValueOrZero(state, "HVE3A_I_87"),
        HVE3A_I_88 = getValueOrZero(state, "HVE3A_I_88"),
        HVE3A_I_89 = getValueOrZero(state, "HVE3A_I_89"),
        val = HVE3A_I_2 <= 0 ? 0 : ((HVE3A_I_87 + HVE3A_I_88 + HVE3A_I_89) / HVE3A_I_2) * 100;
      return {
        valeur: {
          value: val > 100 ? 100 : val,
        },
      };
    },
  },
  HVE3A_I_94: {
    columns: ["HVE3A_I_65", "HVE3A_I_66", "HVE3A_I_1", "HVE3A_D_42", "HVE3A_D_43", "HVE3A_D_139", "HVE3A_D_140"],
    disabled: true,
    computedValue: (state) => {
      const HVE3A_D_139 = getValueOrZero(state, "HVE3A_D_139"),
        HVE3A_D_140 = getValueOrZero(state, "HVE3A_D_140"),
        SAU = getValueOrZero(state, "HVE3A_I_1"),
        surf_vignes = getValueOrZero(state, "HVE3A_D_42"),
        surf_vergers = getValueOrZero(state, "HVE3A_D_43"),
        score_HVE3A_I_65 = getNoteOrZero(state, "HVE3A_I_65"),
        score_HVE3A_I_66 = getNoteOrZero(state, "HVE3A_I_66"),
        val = SAU !== 0 ? ((HVE3A_D_139 + HVE3A_D_140) / SAU) * 100 : null;
      return {
        valeur: {
          value: val > 100 ? 100 : val,
          note:
            SAU > 0
              ? (score_HVE3A_I_65 * (SAU - surf_vergers - surf_vignes)) / SAU +
                (score_HVE3A_I_66 * (surf_vergers + surf_vignes)) / SAU
              : 0,
        },
      };
    },
  },
  HVE3A_I_95: {
    columns: ["HVE3A_I_8", "HVE3A_I_10", "HVE3A_I_11", "HVE3A_I_16", "HVE3A_I_17", "HVE3A_I_18", "HVE3A_I_19"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          note: COMPUTED_FIELDS.HVE3A_I_95.columns.reduce(
            (sum, codeNational) => sum + getNoteOrZero(state, codeNational),
            0
          ),
        },
      };
    },
  },
  HVE3A_I_96: {
    columns: ["HVE3A_I_78", "HVE3A_I_80", "HVE3A_I_82", "HVE3A_I_83", "HVE3A_I_84", "HVE3A_I_85"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          note: COMPUTED_FIELDS.HVE3A_I_96.columns.reduce(
            (sum, codeNational) => sum + getNoteOrZero(state, codeNational),
            0
          ),
        },
      };
    },
  },
  HVE3A_I_97: {
    columns: [
      "HVE3A_D_99",
      "HVE3A_D_100",
      "HVE3A_I_1",
      "HVE3A_I_3",
      "HVE3A_I_4",
      "HVE3A_I_54",
      "HVE3A_I_62",
      "HVE3A_I_63",
      "HVE3A_I_64",
      "HVE3A_I_65",
      "HVE3A_I_66",
      "HVE3A_I_86",
      "HVE3A_I_112",
      "HVE3A_I_113",
    ],
    disabled: true,
    computedValue: (state) => {
      const note =
        (getNoteOrZero(state, "HVE3A_I_54") + getNoteOrZero(state, "HVE3A_I_112")) *
          (getValueOrZero(state, "HVE3A_D_99") / 100) +
        getNoteOrZero(state, "HVE3A_I_113") * (1 - getValueOrZero(state, "HVE3A_D_99") / 100) +
        getNoteOrZero(state, "HVE3A_I_62") +
        getNoteOrZero(state, "HVE3A_I_63") +
        getNoteOrZero(state, "HVE3A_I_64") +
        getNoteOrZero(state, "HVE3A_I_86") +
        (getValueOrZero(state, "HVE3A_I_1") === 0
          ? 0
          : Math.min(
              3,
              getNoteOrZero(state, "HVE3A_I_65") *
                ((getValueOrZero(state, "HVE3A_I_1") -
                  getValueOrZero(state, "HVE3A_I_3") -
                  getValueOrZero(state, "HVE3A_I_4")) /
                  getValueOrZero(state, "HVE3A_I_1")) +
                getNoteOrZero(state, "HVE3A_I_66") *
                  ((getValueOrZero(state, "HVE3A_I_3") + getValueOrZero(state, "HVE3A_I_4")) /
                    getValueOrZero(state, "HVE3A_I_1"))
            ));
      return {
        valeur: {
          note,
        },
      };
    },
  },
  HVE3A_I_98: {
    columns: [
      "HVE3A_I_70",
      "HVE3A_I_71",
      "HVE3A_I_72",
      "HVE3A_I_73",
      "HVE3A_I_74",
      "HVE3A_I_75",
      "HVE3A_I_76",
      "HVE3A_I_92",
    ],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          note: COMPUTED_FIELDS.HVE3A_I_98.columns.reduce(
            (sum, codeNational) => sum + getNoteOrZero(state, codeNational),
            0
          ),
        },
      };
    },
  },
  HVE3A_I_110: {
    columns: ["HVE3A_D_29", "HVE3A_D_30", "HVE3A_D_31"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE3A_D_29") +
            getValueOrZero(state, "HVE3A_D_30") +
            getValueOrZero(state, "HVE3A_D_31"),
        },
      };
    },
  },
  HVE3A_I_111: {
    columns: ["HVE3A_D_99", "HVE3A_D_128", "HVE3A_D_131", "HVE3A_I_1"],
    disabled: true,
    computedValue: (state) => {
      const n = getValueOrZero(state, "HVE3A_D_131") + getValueOrZero(state, "HVE3A_D_128"),
        d = getValueOrZero(state, "HVE3A_I_1") * (getValueOrZero(state, "HVE3A_D_99") / 100),
        value = d === 0 ? 0 : (n / d) * 100,
        note = value < 50 ? 0 : 1;
      return {
        valeur: {
          value,
          note,
        },
      };
    },
  },
  HVE3A_I_112: {
    columns: ["HVE3A_I_59", "HVE3A_I_111"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          note: Math.min(2, getNoteOrZero(state, "HVE3A_I_59") + getNoteOrZero(state, "HVE3A_I_111")),
        },
      };
    },
  },
  HVE3A_I_113: {
    columns: ["HVE3A_I_60", "HVE3A_I_61"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          note: Math.min(7, getNoteOrZero(state, "HVE3A_I_60") + getNoteOrZero(state, "HVE3A_I_61")),
        },
      };
    },
  },
};

const handleSET = (codeNational, state) => ({
  valeur: {
    ...state.indicateurs[codeNational].valeur,
    value_SET:
      state.indicateurs[codeNational].valeur.value == null
        ? null
        : state.indicateurs[codeNational].valeur.value * SET_COEFFS[codeNational],
  },
});

export const SET_COEFFS = {
  HVE3A_D_64: 2,
  HVE3A_D_65: 2,
  HVE3A_D_66: 1,
  HVE3A_D_67: 2,
  HVE3A_D_68: 1,
  HVE3A_D_69: 0.01,
  HVE3A_D_70: 5,
  HVE3A_D_71: 20,
  HVE3A_D_72: 0.01,
  HVE3A_D_73: 0.001,
  HVE3A_D_74: 0.005,
  HVE3A_D_75: 0.01,
  HVE3A_D_76: 1,
  HVE3A_D_77: 0.001,
  HVE3A_D_78: 0.01,
  HVE3A_D_79: 0.005,
  HVE3A_D_80: 1,
  HVE3A_D_81: 1,
  HVE3A_D_82: 0.001,
};
/**
 * computes assolement in order to return a pre-computed value object to be used by assolement indicateur
 * @param assolement array of assolement lines
 */
export const COMPUTE_REDUCED_ASSOLEMENT = (assolement) => {
  const reducedAssolement = {
    BLEDUR: 0,
    BLETEND: 0,
    MAIS: 0,
    ORGE: 0,
    CEREAL: 0,
    COLZA: 0,
    TOURN: 0,
    SOJA: 0,
    OLEAG: 0,
    POIS: 0,
    PROTEAG: 0,
    LEGSEC: 0,
    MELANG: 0,
    CHANVR: 0,
    SURGEL: 0,
    DESHYDR: 0,
    MAISFOUR: 0,
    FOURR: 0,
    PTLEGPURE: 0,
    PTMLGLEG: 0,
    PTGRMNPUR: 0,
    PTMLGGRMN: 0,
    PTMLGCPLX: 0,
    PPNFERTI: 0,
    PPFERTINT: 0,
    PPAUTRES: 0,
    BETTERAV: 0,
    INDUS: 0,
    CULTPDT: 0,
    CULTPLEINCHAMP: 0,
    CULTTUNNEL: 0,
    CULTSERRE: 0,
    CULTHORSOL: 0,
    HORTPEP: 0,
    VIGNES: 0,
    VERGER: 0,
    CULTPERMA: 0,
    culturesMineures: 0,
    culturesMajeures: 0,
    culturePrincipale: {
      libelle: "",
      surface: 0,
    },
    secondeCulturePrincipale: {
      libelle: "",
      surface: 0,
    },
  };

  const especesVerger = {};
  assolement.forEach((a) => {
    reducedAssolement[a.codeTypeCulture] += a.surface;
    switch (a.estMajeure) {
      case false:
        reducedAssolement.culturesMineures += a.surface;
        break;
      case true:
        reducedAssolement.culturesMajeures += a.surface;
        break;
      default:
        break;
    }
    if (a.codeTypeCulture === "VERGER") {
      especesVerger[a.regroupementCulture] = (especesVerger[a.regroupementCulture] ?? 0) + a.surface;
    }
  });
  // détermination cultures principales
  // exclure PP non fertilisées","PP fertilisées mais non traitées","Autres PP"
  // pour VERGER => on descend au niveau de l'espèce
  const filterProperties = [
    "PPNFERTI",
    "PPFERTINT",
    "PPAUTRES",
    "VERGER",
    "culturesMineures",
    "culturesMajeures",
    "culturePrincipale",
    "secondeCulturePrincipale",
  ];

  const [culturePrincipale, secondeCulturePrincipale] = [
    ...Object.entries(reducedAssolement)
      .filter(([code]) => !filterProperties.includes(code))
      .map(([code, surface]) => ({
        code,
        surface,
        isEspece: false,
      })),
    ...Object.entries(especesVerger).map(([code, surface]) => ({
      code,
      surface,
      isEspece: true,
    })),
  ].sort((a, b) => b.surface - a.surface);

  reducedAssolement.culturePrincipale = culturePrincipale;
  reducedAssolement.secondeCulturePrincipale = secondeCulturePrincipale;

  return reducedAssolement;
};

export const typesCulturesToCompare = [
  "BLEDUR",
  "BLETEND",
  "MAIS",
  "ORGE",
  "CEREAL",
  "COLZA",
  "TOURN",
  "SOJA",
  "OLEAG",
  "POIS",
  "PROTEAG",
  "LEGSEC",
  "MELANG",
  "CHANVR",
  "BETTERAV",
  "INDUS",
  "CULTPDT",
];

export const HVE3A_ASSOLEMENT = {
  HVE3A_D_1: (state) => state.reducedAssolement.BLETEND,
  HVE3A_D_2: (state) => state.reducedAssolement.BLEDUR,
  HVE3A_D_3: (state) => state.reducedAssolement.MAIS,
  HVE3A_D_4: (state) => state.reducedAssolement.ORGE,
  HVE3A_D_5: (state) => state.reducedAssolement.CEREAL,
  HVE3A_D_6: (state) =>
    HVE3A_ASSOLEMENT.HVE3A_D_1(state) +
    HVE3A_ASSOLEMENT.HVE3A_D_2(state) +
    HVE3A_ASSOLEMENT.HVE3A_D_3(state) +
    HVE3A_ASSOLEMENT.HVE3A_D_4(state) +
    HVE3A_ASSOLEMENT.HVE3A_D_5(state),
  HVE3A_D_7: (state) => state.reducedAssolement.COLZA,
  HVE3A_D_8: (state) => state.reducedAssolement.TOURN,
  HVE3A_D_9: (state) => state.reducedAssolement.SOJA,
  HVE3A_D_10: (state) => state.reducedAssolement.OLEAG,
  HVE3A_D_11: (state) =>
    HVE3A_ASSOLEMENT.HVE3A_D_7(state) +
    HVE3A_ASSOLEMENT.HVE3A_D_8(state) +
    HVE3A_ASSOLEMENT.HVE3A_D_9(state) +
    HVE3A_ASSOLEMENT.HVE3A_D_10(state),
  HVE3A_D_12: (state) => state.reducedAssolement.POIS,
  HVE3A_D_13: (state) => state.reducedAssolement.PROTEAG,
  HVE3A_D_14: (state) => state.reducedAssolement.LEGSEC,
  HVE3A_D_15: (state) => state.reducedAssolement.MELANG,
  HVE3A_D_16: (state) =>
    HVE3A_ASSOLEMENT.HVE3A_D_12(state) +
    HVE3A_ASSOLEMENT.HVE3A_D_13(state) +
    HVE3A_ASSOLEMENT.HVE3A_D_14(state) +
    HVE3A_ASSOLEMENT.HVE3A_D_15(state),
  HVE3A_D_17: (state) => state.reducedAssolement.CHANVR,
  HVE3A_D_18: (state) => state.reducedAssolement.SURGEL,
  HVE3A_D_19: (state) => HVE3A_ASSOLEMENT.HVE3A_D_17(state) + HVE3A_ASSOLEMENT.HVE3A_D_18(state),
  HVE3A_D_20: (state) => state.reducedAssolement.DESHYDR,
  HVE3A_D_21: (state) => state.reducedAssolement.MAISFOUR,
  HVE3A_D_22: (state) => state.reducedAssolement.FOURR,
  HVE3A_D_23: (state) =>
    HVE3A_ASSOLEMENT.HVE3A_D_20(state) + HVE3A_ASSOLEMENT.HVE3A_D_21(state) + HVE3A_ASSOLEMENT.HVE3A_D_22(state),
  HVE3A_D_24: (state) => state.reducedAssolement.PTLEGPURE,
  HVE3A_D_25: (state) => state.reducedAssolement.PTMLGLEG,
  HVE3A_D_26: (state) => state.reducedAssolement.PTGRMNPUR,
  HVE3A_D_27: (state) => state.reducedAssolement.PTMLGGRMN,
  HVE3A_D_28: (state) => state.reducedAssolement.PTMLGCPLX,
  HVE3A_D_29: (state) => state.reducedAssolement.PPNFERTI,
  HVE3A_D_30: (state) => state.reducedAssolement.PPFERTINT,
  HVE3A_D_31: (state) => state.reducedAssolement.PPAUTRES,
  HVE3A_D_32: (state) =>
    HVE3A_ASSOLEMENT.HVE3A_D_24(state) +
    HVE3A_ASSOLEMENT.HVE3A_D_25(state) +
    HVE3A_ASSOLEMENT.HVE3A_D_26(state) +
    HVE3A_ASSOLEMENT.HVE3A_D_27(state) +
    HVE3A_ASSOLEMENT.HVE3A_D_28(state) +
    HVE3A_ASSOLEMENT.HVE3A_D_29(state) +
    HVE3A_ASSOLEMENT.HVE3A_D_30(state) +
    HVE3A_ASSOLEMENT.HVE3A_D_31(state),
  HVE3A_D_33: (state) => state.reducedAssolement.BETTERAV,
  HVE3A_D_34: (state) => state.reducedAssolement.INDUS,
  HVE3A_D_35: (state) => HVE3A_ASSOLEMENT.HVE3A_D_33(state) + HVE3A_ASSOLEMENT.HVE3A_D_34(state),
  HVE3A_D_36: (state) => state.reducedAssolement.CULTPDT,
  HVE3A_D_37: (state) => state.reducedAssolement.CULTPLEINCHAMP,
  HVE3A_D_38: (state) => state.reducedAssolement.CULTTUNNEL,
  HVE3A_D_39: (state) => state.reducedAssolement.CULTSERRE,
  HVE3A_D_40: (state) => state.reducedAssolement.CULTHORSOL,
  HVE3A_D_171: (state) => state.reducedAssolement.HORTPEP,
  HVE3A_D_41: (state) =>
    HVE3A_ASSOLEMENT.HVE3A_D_36(state) +
    HVE3A_ASSOLEMENT.HVE3A_D_37(state) +
    HVE3A_ASSOLEMENT.HVE3A_D_38(state) +
    HVE3A_ASSOLEMENT.HVE3A_D_39(state) +
    HVE3A_ASSOLEMENT.HVE3A_D_40(state) +
    HVE3A_ASSOLEMENT.HVE3A_D_171(state),
  HVE3A_D_42: (state) => state.reducedAssolement.VIGNES,
  HVE3A_D_43: (state) => state.reducedAssolement.VERGER,
  HVE3A_D_44: (state) => state.reducedAssolement.CULTPERMA,
  HVE3A_D_45: (state) =>
    HVE3A_ASSOLEMENT.HVE3A_D_42(state) + HVE3A_ASSOLEMENT.HVE3A_D_43(state) + HVE3A_ASSOLEMENT.HVE3A_D_44(state),
  HVE3A_D_46: (state) =>
    Object.keys(
      state.assolement
        .filter((a) => ["DESHYDR", "MAISFOUR", "FOURR"].includes(a.codeTypeCulture))
        .reduce((acc, a) => {
          if (!acc[a.regroupementCulture]) {
            acc[a.regroupementCulture] = 1;
          } else {
            acc[a.regroupementCulture]++;
          }
          return acc;
        }, {})
    ).length,
  HVE3A_D_47: (state) =>
    Object.keys(
      state.assolement
        .filter((a) => typesCulturesToCompare.includes(a.codeTypeCulture))
        .reduce((acc, a) => {
          if (!acc[a.regroupementCulture]) {
            acc[a.regroupementCulture] = 1;
          } else {
            acc[a.regroupementCulture]++;
          }
          return acc;
        }, {})
    ).length,
  HVE3A_D_48: (state) => HVE3A_ASSOLEMENT.HVE3A_D_46(state) + HVE3A_ASSOLEMENT.HVE3A_D_47(state),
};

const getValueOrZero = (state, codeNational) =>
  state.indicateurs[codeNational] && !isNaN(Number(state.indicateurs[codeNational].valeur.value))
    ? Number(state.indicateurs[codeNational].valeur.value)
    : 0;

const getNoteOrZero = (state, codeNational) =>
  state.indicateurs[codeNational] && !isNaN(Number(state.indicateurs[codeNational].valeur.note))
    ? Number(state.indicateurs[codeNational].valeur.note)
    : 0;

const getValue = (state, codeNational) => {
  return state.indicateurs[codeNational]?.valeur?.value;
};

const getValueOrNewValue = (state, codeNational) => {
  return state.indicateurs[codeNational]?.valeur?.newValue != null
    ? state.indicateurs[codeNational]?.valeur?.newValue
    : getValueOrZero(state, codeNational);
};

const hideBGACorpen = (state) => state.indicateurs.HVE3A_D_100.valeur.value === "Bilan apparent";

const hideBilanApparent = (state) =>
  state.indicateurs.HVE3A_D_100.valeur.value === "" ||
  state.indicateurs.HVE3A_D_100.valeur.value === "Choix BGA" ||
  state.indicateurs.HVE3A_D_100.valeur.value === "Bilan Corpen";

const methodeBilanNIsEmpty = (state) => state.indicateurs.HVE3A_D_100.valeur.value === "";

export const HIDDEN_FIELDS = {
  HVE3A_D_102: hideBilanApparent,
  HVE3A_D_103: hideBilanApparent,
  HVE3A_D_104: hideBilanApparent,
  HVE3A_D_105: hideBilanApparent,
  HVE3A_D_106: hideBilanApparent,
  HVE3A_D_107: hideBilanApparent,
  HVE3A_D_108: hideBilanApparent,
  HVE3A_D_109: hideBilanApparent,
  HVE3A_D_110: hideBilanApparent,
  HVE3A_D_111: hideBilanApparent,
  HVE3A_D_112: hideBilanApparent,
  HVE3A_D_113: hideBilanApparent,
  HVE3A_D_114: hideBilanApparent,
  HVE3A_D_115: hideBilanApparent,
  HVE3A_D_116: hideBilanApparent,
  HVE3A_D_117: hideBilanApparent,
  HVE3A_D_118: hideBGACorpen,
  HVE3A_D_119: hideBGACorpen,
  HVE3A_D_120: hideBGACorpen,
  HVE3A_D_121: hideBGACorpen,
  HVE3A_D_122: hideBGACorpen,
  HVE3A_D_123: hideBGACorpen,
  HVE3A_D_124: hideBGACorpen,
  HVE3A_D_125: hideBGACorpen,
  HVE3A_D_126: hideBGACorpen,
};

export const HIDDEN_LIBELLE_ENGLOBANTS = {
  FERTI_IMPORT_AZOTE: methodeBilanNIsEmpty,
  FERTI_EXPORT_AZOTE: methodeBilanNIsEmpty,
};

const exports = {
  codeNational: CODE_NATIONAL,
  computedFields: COMPUTED_FIELDS,
  coeffSET: SET_COEFFS,
  assolement: HVE3A_ASSOLEMENT,
  computeReducedAssolement: COMPUTE_REDUCED_ASSOLEMENT,
  hiddenFields: HIDDEN_FIELDS,
  hiddenLibelleEnglobants: HIDDEN_LIBELLE_ENGLOBANTS,
};
export default exports;
