import { withTranslation } from "react-i18next";
import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  IconButton,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import network from "../../../actions/external/network";
import { downloadBlob } from "../../../scripts/utils";
import { toastr } from "react-redux-toastr";

class MultiEdition extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      exploitationsToExport: [],
      mainCheck: true,
      orderBy: "asc",
      searchContent: "",
      anchorEl: null,
    };
  }

  componentDidMount() {
    this.setState({
      exploitationsToExport: this.props.exploitations.filter((exploitation) =>
        exploitation.demarches.some((demarche) => demarche.idMillesime === this.props.millesime)
      ),
      mainCheck: true,
      orderBy: "asc",
      searchContent: "",
      anchorEl: null,
    });
  }

  handleExploitationToExport = (event, checked, exploitation) => {
    const { exploitationsToExport } = this.state;
    if (checked) {
      exploitationsToExport.push(exploitation);
      this.setState({ exploitationsToExport: exploitationsToExport });
    } else {
      this.setState({
        exploitationsToExport: exploitationsToExport.filter(
          (exploitationToRemove) => exploitationToRemove.idExploitation !== exploitation.idExploitation
        ),
      });
    }
  };

  sortHandler = () => {
    this.setState({
      orderBy: this.state.orderBy === "asc" ? "desc" : "asc",
    });
  };

  handleMainCheck = (checked) => {
    checked
      ? this.setState({ exploitationsToExport: this.props.exploitations })
      : this.setState({ exploitationsToExport: [] });
  };

  printEdition = async (name, format) => {
    const { exploitationsToExport } = this.state;
    const ids_exploitations = exploitationsToExport.map((exploit) => {
      return exploit.idExploitation;
    });

    const params = new URLSearchParams({
      id_millesime: this.props.millesime,
      format,
    });

    ids_exploitations.forEach((id) => params.append("ids_exploitations", id));

    try {
      const response = await network.fetch(`/api/editions/${name}/multiexploitation?${params}`);
      await downloadBlob(response);
    } catch (error) {
      toastr.error("Erreur", error.message);
    }
    this.props.onClose();
  };

  handleOpenPopper = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClosePopper = () => {
    this.setState({ anchorEl: null });
  };

  handleSearch = (event) => {
    this.setState({ searchContent: event.target.value });
  };

  render() {
    const { exploitations, onClose } = this.props;
    const { anchorEl } = this.state;

    return (
      <React.Fragment>
        <div>Vous vous apprêtez à lancer une édition pour la démarche MesCertifications HVE4.</div>
        <div>
          Cette éditions porte par défaut sur l'ensemble de votre portefeuille d'exploitations. Si vous souhaitez
          exclure certaines exploitations, supprimez-les de la liste ci-dessous :
        </div>
        <TableContainer
          component={Paper}
          style={{ maxWidth: "60%", maxHeight: "60vh", minHeight: "60vh" }}
          className={"mt-2"}
        >
          <Table stickyHeader={true}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    color={"primary"}
                    indeterminate={
                      this.state.exploitationsToExport.length !== this.props.exploitations.length &&
                      this.state.exploitationsToExport.length > 0
                    }
                    checked={Boolean(
                      (this.state.exploitationsToExport.length === this.props.exploitations.length) ^ this.mainCheck
                    )}
                    onChange={(event, checked) => this.handleMainCheck(checked)}
                  />
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    className={"bold"}
                    active={true}
                    direction={this.state.orderBy}
                    onClick={() => this.sortHandler()}
                  >
                    Raison sociale
                  </TableSortLabel>
                  <Tooltip title={"Rechercher une exploitation"} placement={"top"}>
                    <IconButton onClick={this.handleOpenPopper}>
                      <FontAwesomeIcon icon={"search"} />
                    </IconButton>
                  </Tooltip>
                  <Popover anchorEl={anchorEl} open={!!anchorEl} placement={"top"}>
                    <ClickAwayListener onClickAway={this.handleClosePopper}>
                      <Paper>
                        <TextField
                          onChange={(event) => this.handleSearch(event)}
                          placeholder={"Rechercher une exploitation"}
                          value={this.state.searchContent}
                          style={{
                            width: "20rem",
                          }}
                        />
                      </Paper>
                    </ClickAwayListener>
                  </Popover>
                </TableCell>
                <TableCell>
                  <div className={"bold"}>SIRET</div>
                </TableCell>
                <TableCell>
                  <div className={"bold"}>Commune</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {exploitations.filter((exploitation) =>
                exploitation.demarches.some((demarche) => demarche.idMillesime === this.props.millesime)
              ).length === 0 ? (
                <TableRow className={"d-flex justify-center"}>
                  <TableCell align={"center"} colSpan={4}>
                    Aucune exploitation ayant une démarche réalisée en {this.props.millesime} n'est disponible
                  </TableCell>
                </TableRow>
              ) : (
                exploitations
                  .filter((exploitation) =>
                    exploitation.demarches.some((demarche) => demarche.idMillesime === this.props.millesime)
                  )
                  .toSorted(
                    (exploitA, exploitB) =>
                      exploitA.raisonSociale.localeCompare(exploitB.raisonSociale) *
                      (this.state.orderBy === "asc" ? 1 : -1)
                  )
                  .filter((exploit) =>
                    exploit.raisonSociale.toLowerCase().includes(this.state.searchContent.toLowerCase())
                  )
                  .map((exploitation) => (
                    <TableRow key={`exploitation-${exploitation.idExploitation}`}>
                      <TableCell>
                        <Checkbox
                          color={"primary"}
                          checked={this.state.exploitationsToExport.includes(exploitation)}
                          onChange={(event, checked) => this.handleExploitationToExport(event, checked, exploitation)}
                        />
                      </TableCell>
                      <TableCell>{exploitation.raisonSociale}</TableCell>
                      <TableCell>{exploitation.siret}</TableCell>
                      <TableCell>{exploitation.nomCommune}</TableCell>
                    </TableRow>
                  ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box className={"d-flex justify-center mt-2"}>
          <Button className={"mr-1"} variant={"contained"} onClick={onClose}>
            <FontAwesomeIcon icon={"times"} style={{ height: "20px", width: "20px" }} className={"mr-05"} />
            Annuler
          </Button>
          <Button
            color={"primary"}
            variant={"contained"}
            onClick={() => this.printEdition("HVE4", "xlsx")}
            disabled={this.state.exploitationsToExport.length === 0}
          >
            <FontAwesomeIcon icon={"print"} style={{ height: "20px", width: "20px" }} className={"mr-05"} />
            Lancer l'édition
          </Button>
        </Box>
      </React.Fragment>
    );
  }
}

export default withTranslation()(MultiEdition);
