import React from "react";
import { Badge, ClickAwayListener, Grid, IconButton, Paper, Popper, TextField, Tooltip } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/styles";

const ToggleButtonNonValide = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    "&$selected": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
    },
    "&$selected:hover": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.dark,
    },
  },
  selected: {},
}))(ToggleButton);

const ToggleButtonValide = withStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    "&$selected": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.main,
    },
    "&$selected:hover": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.success.dark,
    },
  },
  selected: {},
}))(ToggleButton);

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
  idUtilisateur: state.auth.idUtilisateur,
});

class ValideNonValide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      estValide: null,
      sousRubriqueIndex: props.sousRubriqueIndex,
      validiteSousRubrique: props.validite,
    };
  }

  handleCommentaireAuditeur = (event) => {
    const newValidite = {
      validiteSousRubrique: {
        ...this.state.validiteSousRubrique,
        commentaireAuditeur: event.target.value,
      },
    };
    this.setState(newValidite);
    this.props.onChange(newValidite, this.state.sousRubriqueIndex);
  };

  handleEstValide = (event, value) => {
    const newValidite = {
      validiteSousRubrique: {
        ...this.state.validiteSousRubrique,
        estValide: value,
      },
    };
    this.setState(newValidite);
    this.props.onChange(newValidite, this.state.sousRubriqueIndex);
  };

  handleOpenPopper = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClosePopper = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { validiteSousRubrique } = this.state,
      { anchorEl } = this.state;
    return (
      <Grid container spacing={3} className={"d-flex justify-center align-items-center mt-2"}>
        <React.Fragment>
          <Grid item xs={3} className={"d-flex"}>
            <ToggleButtonGroup
              size={"small"}
              value={validiteSousRubrique?.estValide}
              onChange={this.handleEstValide}
              aria-label="est valide"
              exclusive
            >
              <ToggleButtonNonValide value={false} style={{ flexShrink: 0 }}>
                Non-valide
              </ToggleButtonNonValide>
              <ToggleButtonValide value={true}>Valide</ToggleButtonValide>
            </ToggleButtonGroup>
            <div className={"ml-1"}>
              <Badge
                color="primary"
                variant="dot"
                invisible={!validiteSousRubrique?.commentaireAuditeur}
                overlap={"circle"}
              >
                <Tooltip title={"ouvrir les commentaires"} placement={"right"}>
                  <IconButton onClick={this.handleOpenPopper}>
                    <FontAwesomeIcon icon={"comment-alt"} />
                  </IconButton>
                </Tooltip>
              </Badge>
              <Popper anchorEl={anchorEl} open={!!anchorEl} style={{ zIndex: 2 }} placement={"right-start"}>
                <ClickAwayListener onClickAway={this.handleClosePopper}>
                  <Paper>
                    <TextField
                      value={validiteSousRubrique?.commentaireAuditeur}
                      label={"Commentaire auditeur"}
                      onInput={this.handleCommentaireAuditeur}
                      onBlur={this.handleClosePopper}
                      multiline
                      rows={4}
                      style={{
                        width: "35rem",
                      }}
                    />
                  </Paper>
                </ClickAwayListener>
              </Popper>
            </div>
          </Grid>
        </React.Fragment>
      </Grid>
    );
  }
}

export default connect(mapStateToProps)(ValideNonValide);
