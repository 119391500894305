import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Grid, IconButton, InputAdornment, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class SurfaceSneSna extends React.Component {
  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };
  render() {
    const { indicateurs, computedFields } = this.props;

    const surfNonExplKeys = [
      "HVE4_CULT_D_71",
      "HVE4_CULT_D_77",
      "HVE4_CULT_D_72",
      "HVE4_CULT_D_73",
      "HVE4_CULT_D_74",
      "HVE4_CULT_D_75",
      "HVE4_CULT_D_76",
      "HVE4_CULT_D_80",
      "HVE4_CULT_D_81",
      "HVE4_CULT_D_82",
      "HVE4_CULT_D_83",
      "HVE4_CULT_D_84",
      "HVE4_CULT_D_85",
      "HVE4_CULT_D_86",
      "HVE4_CULT_D_89",
      "HVE4_CULT_D_90",
      "HVE4_CULT_D_91",
      "HVE4_CULT_D_92",
    ];

    const surfNonAgriKeys = ["HVE4_CULT_D_93", "HVE4_CULT_D_94", "HVE4_CULT_D_95"];

    const surfNonExplToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => surfNonExplKeys.includes(key)))
    );
    const surfNonAgriToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => surfNonAgriKeys.includes(key)))
    );
    return (
      <React.Fragment>
        {surfNonExplToDisplay.length > 0 && <h3 className={"my-2"}>SNE - Surface non exploitée</h3>}
        <div>
          {surfNonExplToDisplay.map((indic) => (
            <div className={"d-flex align-items-center mb-1"} key={"indic-" + indic.idIndicateur}>
              <Grid container item xs={6}>
                <div className={indic.afficherGras ? "mr-3 bold" : "mr-3"}>{indic.libelle}</div>
              </Grid>
              <Grid container item xs={1}>
                <DecimalPositif
                  size={"small"}
                  indicateur={indic}
                  key={"indicateur-" + indic.idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indic.libelleUnite != null && {
                      endAdornment: <InputAdornment position={"end"}>{indic.libelleUnite}</InputAdornment>,
                    }
                  }
                  computedFields={computedFields}
                />
              </Grid>
              {indic.messageAide != null && (
                <Grid container item xs={1}>
                  <Tooltip title={<span style={{ whiteSpace: "pre-line" }}>{indic.messageAide}</span>}>
                    <IconButton>
                      <FontAwesomeIcon icon="info-circle" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </div>
          ))}
        </div>
        {surfNonAgriToDisplay.length > 0 && <h3 className={"my-2"}>SNA - Surface non agricole</h3>}
        <div>
          {surfNonAgriToDisplay.map((indic) => (
            <div className={"d-flex align-items-center mb-1"} key={"indic-" + indic.idIndicateur}>
              <Grid container item xs={6}>
                <div className={indic.afficherGras ? "mr-3 bold" : "mr-3"}>{indic.libelle}</div>
              </Grid>
              <Grid container item xs={1}>
                <DecimalPositif
                  size={"small"}
                  indicateur={indic}
                  key={"indicateur-" + indic.idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indic.libelleUnite != null && {
                      endAdornment: <InputAdornment position={"end"}>{indic.libelleUnite}</InputAdornment>,
                    }
                  }
                  computedFields={computedFields}
                />
              </Grid>
              {indic.messageAide != null && (
                <Grid container item xs={1}>
                  <Tooltip title={<span style={{ whiteSpace: "pre-line" }}>{indic.messageAide}</span>}>
                    <IconButton>
                      <FontAwesomeIcon icon="info-circle" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(SurfaceSneSna);
