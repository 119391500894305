import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { FormHelperText, Switch } from "@material-ui/core";

class Boolean extends React.Component {
  render() {
    const { indicateur, sousRubriqueIndex, libelleEnglobantIndex, changeIndicateur, computedFields } = this.props;

    const valeurCalculee = (
      <div className={"d-flex flex-column"}>
        <FormHelperText>Valeur MesParcelles</FormHelperText>
        <div className={"d-flex align-items-center"}>
          <span style={{ alignSelf: "center" }}>Non</span>
          <Switch checked={!!indicateur.valeurCalculee?.value} color={"primary"} disabled />
          <span style={{ alignSelf: "center" }} className={"mr-2"}>
            Oui
          </span>
        </div>
      </div>
    );

    const valueRendered = (
      <div className={"d-flex flex-column justify-end"}>
        <FormHelperText />
        <div className={"d-flex align-items-center"}>
          <div style={{ width: "8rem" }}>
            <span style={{ alignSelf: "center" }}>Non</span>
            <Switch
              checked={!!indicateur.valeur.value}
              color={"primary"}
              disabled={computedFields.includes(indicateur.codeNational)}
              onChange={(event) =>
                changeIndicateur(
                  { ...indicateur, valeur: { value: event.target.checked } },
                  sousRubriqueIndex,
                  libelleEnglobantIndex
                )
              }
            />
            <span style={{ alignSelf: "center" }}>Oui</span>
          </div>
        </div>
      </div>
    );

    return (
      <React.Fragment>
        {!!indicateur.valeurCalculee && valeurCalculee}
        {valueRendered}
      </React.Fragment>
    );
  }
}

export default compose(withTranslation())(Boolean);
