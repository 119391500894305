import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Grid, InputAdornment, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
import withTheme from "@material-ui/styles/withTheme";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class FertiAzoteOrga extends React.Component {
  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };

  render() {
    const { indicateurs, getValueAssolement, computedFields, theme } = this.props;

    const indicateurKeys = [
      "HVE4_FERTI_D_30",
      "HVE4_FERTI_D_31",
      "HVE4_FERTI_D_32",
      "HVE4_FERTI_I_30",
      "HVE4_FERTI_S_30",
    ];

    const indicateursToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateurKeys.includes(key)))
    );

    if (indicateursToDisplay.length > 0)
      return (
        <React.Fragment>
          <Grid container>
            <Grid item xs={8}>
              <TableContainer component={Paper}>
                <Table style={{ tableLayout: "fixed" }}>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <div className={indicateurs["HVE4_FERTI_D_30"].afficherGras ? "bold" : ""}>
                          {indicateurs["HVE4_FERTI_D_30"].libelle}
                        </div>
                      </TableCell>
                      <TableCell align={"center"}>
                        <DecimalPositif
                          size={"small"}
                          getValueAssolement={getValueAssolement}
                          indicateur={indicateurs["HVE4_FERTI_D_30"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_D_30"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_D_30"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_D_30"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <div className={indicateurs["HVE4_FERTI_D_31"].afficherGras ? "bold" : ""}>
                          {indicateurs["HVE4_FERTI_D_31"].libelle}
                        </div>
                      </TableCell>
                      <TableCell align={"center"}>
                        <DecimalPositif
                          size={"small"}
                          getValueAssolement={getValueAssolement}
                          indicateur={indicateurs["HVE4_FERTI_D_31"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_D_31"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_D_31"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_D_31"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={1} className={"mt-2"}>
              {/*Obligé pour le layout désiré*/}
            </Grid>
            <Grid item xs={8} className={"mt-2"}>
              <TableContainer component={Paper}>
                <Table style={{ tableLayout: "fixed" }}>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <div className={indicateurs["HVE4_FERTI_D_32"].afficherGras ? "bold" : ""}>
                          {indicateurs["HVE4_FERTI_D_32"].libelle}
                        </div>
                      </TableCell>
                      <TableCell align={"center"}>
                        <DecimalPositif
                          size={"small"}
                          getValueAssolement={getValueAssolement}
                          indicateur={indicateurs["HVE4_FERTI_D_32"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_D_32"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_D_32"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_D_32"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <div className={indicateurs["HVE4_FERTI_I_30"].afficherGras ? "bold" : ""}>
                          {indicateurs["HVE4_FERTI_I_30"].libelle}
                        </div>
                      </TableCell>
                      <TableCell align={"center"}>
                        <DecimalPositif
                          size={"small"}
                          getValueAssolement={getValueAssolement}
                          indicateur={indicateurs["HVE4_FERTI_I_30"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_I_30"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_I_30"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_I_30"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={8} className={"mt-2"}>
              <TableContainer component={Paper}>
                <Table style={{ tableLayout: "fixed" }}>
                  <TableBody>
                    <TableRow
                      className={"total-point"}
                      style={{
                        backgroundColor: theme.palette.background.default,
                      }}
                    >
                      <TableCell>
                        <div className={indicateurs["HVE4_FERTI_S_30"].afficherGras ? "bold" : ""}>
                          {indicateurs["HVE4_FERTI_S_30"].libelle}
                        </div>
                      </TableCell>
                      <TableCell align={"center"}>
                        <DecimalPositif
                          size={"small"}
                          getValueAssolement={getValueAssolement}
                          indicateur={indicateurs["HVE4_FERTI_S_30"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_S_30"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_S_30"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_S_30"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </React.Fragment>
      );
  }
}

export default compose(withTranslation(), connect(mapStateToProps), withTheme)(FertiAzoteOrga);
