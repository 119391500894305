import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Grid, InputAdornment } from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class IrrigEtiage extends React.Component {
  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };

  render() {
    const { indicateurs, computedFields, getValueAssolement } = this.props;

    const indicateursKeys = ["HVE4_IRRI_D_61", "HVE4_IRRI_D_60", "HVE4_IRRI_I_6", "HVE4_IRRI_S_6"];
    const indicateursToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateursKeys.includes(key)))
    );
    if (indicateursToDisplay.length > 0)
      return (
        <React.Fragment>
          <Grid container spacing={2} className={"mb-1"}>
            <Grid item xs={3}>
              <div className={indicateurs["HVE4_IRRI_D_61"].afficherGras ? "bold" : ""}>
                {indicateurs["HVE4_IRRI_D_61"].libelle}
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={indicateurs["HVE4_IRRI_D_60"].afficherGras ? "bold" : ""}>
                {indicateurs["HVE4_IRRI_D_60"].libelle}
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={indicateurs["HVE4_IRRI_I_6"].afficherGras ? "bold" : ""}>
                {indicateurs["HVE4_IRRI_I_6"].libelle}
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={indicateurs["HVE4_IRRI_S_6"].afficherGras ? "bold" : ""}>
                {indicateurs["HVE4_IRRI_S_6"].libelle}
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={"d-flex flex-column"}>
                <DecimalPositif
                  size={"small"}
                  getValueAssolement={getValueAssolement}
                  indicateur={indicateurs["HVE4_IRRI_D_61"]}
                  key={"indicateur-" + indicateurs["HVE4_IRRI_D_61"].idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indicateurs["HVE4_IRRI_D_61"].libelleUnite != null && {
                      endAdornment: (
                        <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_D_61"].libelleUnite}</InputAdornment>
                      ),
                    }
                  }
                  computedFields={computedFields}
                />
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={"d-flex flex-column"}>
                <DecimalPositif
                  size={"small"}
                  getValueAssolement={getValueAssolement}
                  indicateur={indicateurs["HVE4_IRRI_D_60"]}
                  key={"indicateur-" + indicateurs["HVE4_IRRI_D_60"].idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indicateurs["HVE4_IRRI_D_60"].libelleUnite != null && {
                      endAdornment: (
                        <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_D_60"].libelleUnite}</InputAdornment>
                      ),
                    }
                  }
                  computedFields={computedFields}
                />
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={"d-flex flex-column"}>
                <DecimalPositif
                  size={"small"}
                  getValueAssolement={getValueAssolement}
                  indicateur={indicateurs["HVE4_IRRI_I_6"]}
                  key={"indicateur-" + indicateurs["HVE4_IRRI_I_6"].idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indicateurs["HVE4_IRRI_I_6"].libelleUnite != null && {
                      endAdornment: (
                        <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_I_6"].libelleUnite}</InputAdornment>
                      ),
                    }
                  }
                  computedFields={computedFields}
                />
              </div>
            </Grid>
            <Grid item xs={3}>
              <div className={"d-flex flex-column total-point"}>
                <DecimalPositif
                  size={"small"}
                  getValueAssolement={getValueAssolement}
                  indicateur={indicateurs["HVE4_IRRI_S_6"]}
                  key={"indicateur-" + indicateurs["HVE4_IRRI_S_6"].idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indicateurs["HVE4_IRRI_S_6"].libelleUnite != null && {
                      endAdornment: (
                        <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_S_6"].libelleUnite}</InputAdornment>
                      ),
                    }
                  }
                  computedFields={computedFields}
                />
              </div>
            </Grid>
          </Grid>
        </React.Fragment>
      );
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(IrrigEtiage);
