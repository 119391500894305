import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Grid, Typography } from "@material-ui/core";
import network from "../../../actions/external/network";
import { toastr } from "react-redux-toastr";

class Cgu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cgu: {},
    };
  }

  componentDidMount() {
    // load exp activities
    this.loadData();
  }

  loadData = async () => {
    const { t } = this.props;
    try {
      const [cgu] = await Promise.all([network.fetch("/api/cgu")]);
      this.setState({
        cgu,
      });
    } catch (error) {
      console.error("an error occured while loading exploitation activities", error);
      toastr.error(t("error", "Erreur"), error.message);
    }
  };

  render() {
    const { t } = this.props;
    const { cgu } = this.state;
    return (
      <React.Fragment>
        <Typography variant="h1" className="pb-2">
          {t("cgu", "Conditions générales d'utilisation")}
        </Typography>
        <Grid item xs className="mb-1">
          <span style={{ whiteSpace: "pre-line" }}>{cgu.texte}</span>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(Cgu));
