import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Grid, InputAdornment } from "@material-ui/core";
import { connect } from "react-redux";
import Boolean from "../indicateurs/Boolean";
import TexteMultiligne from "../indicateurs/TexteMultiligne";
import NumeriqueEntier from "../indicateurs/NumeriqueEntier";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class PhytoLimiteCMR extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const indicToWatch = [
      "HVE4_PHYTO_D_10",
      "HVE4_PHYTO_D_101",
      "HVE4_PHYTO_D_11",
      "HVE4_PHYTO_I_11",
      "HVE4_PHYTO_S_11",
      "HVE4_PHYTO_I_12",
      "HVE4_PHYTO_S_12",
      "HVE4_PHYTO_S_13",
    ];
    return indicToWatch.some((indic) => this.props.indicateurs[indic] !== nextProps.indicateurs[indic]);
  }

  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };
  render() {
    const { indicateurs, computedFields } = this.props;

    const indicateursKeys = [
      "HVE4_PHYTO_D_10",
      "HVE4_PHYTO_D_101",
      "HVE4_PHYTO_D_11",
      "HVE4_PHYTO_I_11",
      "HVE4_PHYTO_S_11",
      "HVE4_PHYTO_I_12",
      "HVE4_PHYTO_S_12",
      "HVE4_PHYTO_S_13",
    ];
    const indicateursToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateursKeys.includes(key)))
    );
    if (indicateursToDisplay.length > 0) {
      return (
        <React.Fragment>
          <div className={"d-flex align-items-center mb-1"}>
            <Grid container item xs={4}>
              <div className={"mr-3"}>{indicateurs["HVE4_PHYTO_D_10"].libelle}</div>
            </Grid>
            <Grid container item xs={4}>
              <Boolean
                indicateur={indicateurs["HVE4_PHYTO_D_10"]}
                key={"indicateur-" + indicateurs["HVE4_PHYTO_D_10"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_PHYTO_D_10"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_PHYTO_D_10"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
          </div>
          {indicateurs["HVE4_PHYTO_D_10"].valeur.value && (
            <div className={"d-flex align-items-center mb-1"}>
              <Grid container item xs={4}>
                <div className={"mr-3"}>{indicateurs["HVE4_PHYTO_D_101"].libelle}</div>
              </Grid>
              <Grid container item xs={4}>
                <Boolean
                  indicateur={indicateurs["HVE4_PHYTO_D_101"]}
                  key={"indicateur-" + indicateurs["HVE4_PHYTO_D_101"].idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indicateurs["HVE4_PHYTO_D_101"].libelleUnite != null && {
                      endAdornment: (
                        <InputAdornment position={"end"}>{indicateurs["HVE4_PHYTO_D_101"].libelleUnite}</InputAdornment>
                      ),
                    }
                  }
                  computedFields={computedFields}
                />
              </Grid>
              {!indicateurs["HVE4_PHYTO_D_101"].valeur.value && (
                <Grid container item xs={2}>
                  <div className={"mr-3"} style={{ color: "#ff0000" }}>
                    INVALIDATION DE L'AUDIT
                  </div>
                </Grid>
              )}
            </div>
          )}
          {indicateurs["HVE4_PHYTO_D_101"].valeur.value && indicateurs["HVE4_PHYTO_D_10"].valeur.value && (
            <div className={"d-flex align-items-center mb-1"}>
              <Grid container item xs={4}>
                <div className={"mr-3"}>{indicateurs["HVE4_PHYTO_D_11"].libelle}</div>
              </Grid>
              <Grid container item xs={4}>
                <TexteMultiligne
                  indicateur={indicateurs["HVE4_PHYTO_D_11"]}
                  key={"indicateur-" + indicateurs["HVE4_PHYTO_D_11"].idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indicateurs["HVE4_PHYTO_D_11"].libelleUnite != null && {
                      endAdornment: (
                        <InputAdornment position={"end"}>{indicateurs["HVE4_PHYTO_D_11"].libelleUnite}</InputAdornment>
                      ),
                    }
                  }
                  computedFields={computedFields}
                />
              </Grid>
            </div>
          )}

          <div className={"d-flex align-items-center mb-1"}>
            <Grid container item xs={4}>
              <div className={"mr-3"}>{indicateurs["HVE4_PHYTO_I_11"].libelle}</div>
            </Grid>
            <Grid container item xs={4}>
              <Boolean
                indicateur={indicateurs["HVE4_PHYTO_I_11"]}
                key={"indicateur-" + indicateurs["HVE4_PHYTO_I_11"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_PHYTO_I_11"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_PHYTO_I_11"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
            <Grid container item xs={1}>
              <div className={"mr-3"}>{indicateurs["HVE4_PHYTO_S_12"].libelle}</div>
            </Grid>
            <Grid container item xs={2}>
              <NumeriqueEntier
                size={"small"}
                indicateur={indicateurs["HVE4_PHYTO_S_11"]}
                key={"indicateur-" + indicateurs["HVE4_PHYTO_S_11"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_PHYTO_S_11"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_PHYTO_S_11"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
          </div>
          <div className={"d-flex align-items-center mb-1"}>
            <Grid container item xs={4}>
              <div className={"mr-3"}>{indicateurs["HVE4_PHYTO_I_12"].libelle}</div>
            </Grid>
            <Grid container item xs={4}>
              <Boolean
                indicateur={indicateurs["HVE4_PHYTO_I_12"]}
                key={"indicateur-" + indicateurs["HVE4_PHYTO_I_12"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_PHYTO_I_12"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_PHYTO_I_12"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
            <Grid container item xs={1}>
              <div className={"mr-3"}>{indicateurs["HVE4_PHYTO_S_12"].libelle}</div>
            </Grid>
            <Grid container item xs={2}>
              <NumeriqueEntier
                size={"small"}
                indicateur={indicateurs["HVE4_PHYTO_S_12"]}
                key={"indicateur-" + indicateurs["HVE4_PHYTO_S_12"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_PHYTO_S_12"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_PHYTO_S_12"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
          </div>
          <div className={"d-flex align-items-center mb-1"}>
            <Grid container item xs={8}>
              <div className={"mr-3"}>{indicateurs["HVE4_PHYTO_S_13"].libelle}</div>
            </Grid>
            <Grid container item xs={1}>
              <div className={"mr-3 bold"}>Points</div>
            </Grid>
            <Grid container item xs={2} className={"total-point"}>
              <NumeriqueEntier
                size={"small"}
                indicateur={indicateurs["HVE4_PHYTO_S_13"]}
                key={"indicateur-" + indicateurs["HVE4_PHYTO_S_13"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_PHYTO_S_13"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_PHYTO_S_13"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(PhytoLimiteCMR);
