import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Grid, InputAdornment } from "@material-ui/core";
import { connect } from "react-redux";
import NumeriqueEntier from "../indicateurs/NumeriqueEntier";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class BiodivNbAnim extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const indicToWatch = [
      "HVE4_BIODIV_D_55",
      "HVE4_BIODIV_D_56",
      "HVE4_BIODIV_D_57",
      "HVE4_BIODIV_D_58",
      "HVE4_BIODIV_D_59",
      "HVE4_BIODIV_D_60",
      "HVE4_BIODIV_I_61",
      "HVE4_BIODIV_S_61",
    ];
    return indicToWatch.some((indic) => this.props.indicateurs[indic] !== nextProps.indicateurs[indic]);
  }

  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };
  render() {
    const { indicateurs, computedFields } = this.props;
    return (
      <React.Fragment>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            <div className={indicateurs["HVE4_BIODIV_D_55"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_D_55"].libelle}
            </div>
          </Grid>
          <Grid container item xs={4}>
            <NumeriqueEntier
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_D_55"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_55"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_55"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_55"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            <div className={indicateurs["HVE4_BIODIV_D_56"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_D_56"].libelle}
            </div>
          </Grid>
          <Grid container item xs={4}>
            <NumeriqueEntier
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_D_56"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_56"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_56"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_56"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            <div className={indicateurs["HVE4_BIODIV_D_57"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_D_57"].libelle}
            </div>
          </Grid>
          <Grid container item xs={4}>
            <NumeriqueEntier
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_D_57"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_57"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_57"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_57"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            <div className={indicateurs["HVE4_BIODIV_D_58"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_D_58"].libelle}
            </div>
          </Grid>
          <Grid container item xs={4}>
            <NumeriqueEntier
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_D_58"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_58"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_58"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_58"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            <div className={indicateurs["HVE4_BIODIV_D_59"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_D_59"].libelle}
            </div>
          </Grid>
          <Grid container item xs={4}>
            <NumeriqueEntier
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_D_59"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_59"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_59"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_59"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            <div className={indicateurs["HVE4_BIODIV_D_60"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_D_60"].libelle}
            </div>
          </Grid>
          <Grid container item xs={3}>
            <NumeriqueEntier
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_D_60"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_60"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_60"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_60"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
          <Grid container item xs={4}>
            <div className={"d-flex align-items-center bold"}>
              <Grid item xs={6}>
                <div>{indicateurs["HVE4_BIODIV_S_61"].libelle}</div>
              </Grid>
            </div>
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            <div className={indicateurs["HVE4_BIODIV_I_61"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_I_61"].libelle}
            </div>
          </Grid>
          <Grid container item xs={4}>
            <NumeriqueEntier
              size={"small"}
              displayRules={{ hasRightDisplay: true }}
              indicateur={indicateurs["HVE4_BIODIV_I_61"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_I_61"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_I_61"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_I_61"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
          <Grid container item xs={4} className={"total-point"}>
            <NumeriqueEntier
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_S_61"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_S_61"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_S_61"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_S_61"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(BiodivNbAnim);
