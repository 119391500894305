import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Grid, InputAdornment } from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
import Boolean from "../indicateurs/Boolean";
import Percent from "../indicateurs/Percent";
const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});
class IrrigPratiquesIrrigations extends React.Component {
  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };
  render() {
    const { indicateurs, getValueAssolement, computedFields } = this.props;

    const indicateurKeys = [
      "HVE4_IRRI_D_104",
      "HVE4_IRRI_D_105",
      "HVE4_IRRI_D_106",
      "HVE4_IRRI_D_107",
      "HVE4_IRRI_D_107_1",
      "HVE4_IRRI_D_107_2",
      "HVE4_IRRI_D_107_3",
      "HVE4_IRRI_D_107_4",
      "HVE4_IRRI_D_107_5",
      "HVE4_IRRI_D_107_6",
      "HVE4_IRRI_D_107_7",
      "HVE4_IRRI_D_108",
      "HVE4_IRRI_D_108_1",
      "HVE4_IRRI_D_108_2",
      "HVE4_IRRI_D_108_3",
      "HVE4_IRRI_D_108_4",
      "HVE4_IRRI_D_108_5",
      "HVE4_IRRI_D_109",
    ];

    const indicateursToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateurKeys.includes(key)))
    );

    if (indicateursToDisplay.length > 0)
      return (
        <React.Fragment>
          {indicateurs["HVE4_IRRI_D_103"] && (
            <div className={"d-flex align-items-center mb-1"}>
              <Grid item xs={4}>
                <div className="mr-3">{indicateurs["HVE4_IRRI_D_103"].libelle}</div>
              </Grid>
              <Grid item>
                <Boolean
                  indicateur={indicateurs["HVE4_IRRI_D_103"]}
                  key={"indicateur-" + indicateurs["HVE4_IRRI_D_103"].idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indicateurs["HVE4_IRRI_D_103"].libelleUnite != null && {
                      endAdornment: (
                        <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_D_103"].libelleUnite}</InputAdornment>
                      ),
                    }
                  }
                  computedFields={computedFields}
                />
              </Grid>
            </div>
          )}
          {indicateursToDisplay.map((indic, i) =>
            ["HVE4_IRRI_D_107", "HVE4_IRRI_D_108"].includes(indic.codeNational) ? (
              <h2 className={"my-2"} key={"libelle" + i}>
                {indic.libelle}
              </h2>
            ) : (
              <div className={"d-flex align-items-center mb-1"} key={"indic" + i}>
                <Grid item xs={4}>
                  <div className={indic.afficherGras ? "mr-3 bold" : "mr-3"}>{indic.libelle}</div>
                </Grid>
                {["HVE4_IRRI_D_108_4", "HVE4_IRRI_D_109"].includes(indic.codeNational) &&
                  indicateurs["HVE4_IRRI_D_108_1"]?.valeurCalculee != null && (
                    <Grid item key={"spacer" + i}>
                      <div style={{ width: "8rem", marginRight: "2em" }}></div>
                    </Grid>
                  )}
                <Grid item key={"input" + i}>
                  <DecimalPositif
                    size={"small"}
                    disabled
                    getValueAssolement={getValueAssolement}
                    indicateur={indic}
                    key={"indicateur-" + indic.idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indic.libelleUnite != null && {
                        endAdornment: <InputAdornment position={"end"}>{indic.libelleUnite}</InputAdornment>,
                      }
                    }
                    computedFields={computedFields}
                  />
                </Grid>
              </div>
            )
          )}
          <div className={"d-flex align-items-center mb-1"}>
            <Grid item xs={4}>
              <div className="mr-3">{indicateurs["HVE4_IRRI_I_1"].libelle}</div>
            </Grid>
            {indicateurs["HVE4_IRRI_D_108_1"]?.valeurCalculee != null && (
              <Grid item>
                <div style={{ width: "8rem", marginRight: "2em" }}></div>
              </Grid>
            )}
            <Grid item>
              <Percent
                size={"small"}
                indicateur={indicateurs["HVE4_IRRI_I_1"]}
                key={"indicateur-" + indicateurs["HVE4_IRRI_I_1"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_IRRI_I_1"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_I_1"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
          </div>
          <div className={"d-flex align-items-center mb-1"}>
            <Grid item xs={4}>
              <div className="mr-3">{indicateurs["HVE4_IRRI_S_1"].libelle}</div>
            </Grid>
            {indicateurs["HVE4_IRRI_D_108_1"]?.valeurCalculee != null && (
              <Grid item>
                <div style={{ width: "8rem", marginRight: "2em" }}></div>
              </Grid>
            )}
            <Grid item className={"total-point"}>
              <DecimalPositif
                size={"small"}
                indicateur={indicateurs["HVE4_IRRI_S_1"]}
                key={"indicateur-" + indicateurs["HVE4_IRRI_S_1"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_IRRI_S_1"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_S_1"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
          </div>
        </React.Fragment>
      );
  }
}
export default compose(withTranslation(), connect(mapStateToProps))(IrrigPratiquesIrrigations);
