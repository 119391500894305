import React from "react";
import { compose } from "redux";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import { toastr } from "react-redux-toastr";
import network from "../../../../actions/external/network";
import HVE4Static from "../HVE4/HVE4Static";
import { procedureStateCodes, roles } from "../../../common/codes";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withTheme from "@material-ui/styles/withTheme";
import SousRubriques from "./SousRubriques";
import SigaModal from "../../../common/SigaModal";
import MenuHve4 from "./MenuHVE4";

const mapStateToProps = (state) => ({
  idExploitation: state.exploitation.selected.idExploitation,
  millesime: state.millesime.selected.idMillesime,
  userRole: state.auth.role,
  idUtilisateur: state.auth.idUtilisateur,
});

class HVE4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmDialog: false,
      saving: false,
      loading: true,
      demarche: null,
      exploitationDemarcheInfo: null,
      famillesCultures: [],
      exploitationsTierces: [],
      typesRubriques: [],
      indicateurs: {},
      demarcheOrganisme: null,
      typeRubriqueSelected: 0,
      rubriqueSelected: 0,
      validitesRubriques: [],
      assolement: [],
      typesCultures: [],
      showResults: false,
      commentairesSyntheseResultats: {},
    };
  }
  componentDidMount = () => {
    this.loadData();
  };

  /**
   * Load Demarche data
   */
  loadData = async () => {
    try {
      let [
        demarche,
        exploitationDemarcheInfo,
        typesRubriques,
        { assolement },
        typesCultures,
        famillesCultures,
        exploitationsTierces,
        exploitationsActivites,
      ] = await Promise.all([
        network.fetch(`/api/demarches/${HVE4Static.codeNational}`),
        network.fetch(
          `/api/exploitations/${this.props.idExploitation}/demarches/${HVE4Static.codeNational}?millesime=${this.props.millesime}`
        ),
        network.fetch(
          `/api/demarches/${HVE4Static.codeNational}/indicateurs/${this.props.idExploitation}?millesime=${this.props.millesime}`
        ),
        network.fetch(`/api/exploitations/${this.props.idExploitation}/assolement?millesime=${this.props.millesime}`),
        network.fetch(`/api/types-cultures`),
        network.fetch(
          `/api/exploitations/${this.props.idExploitation}/familles-cultures?millesime=${this.props.millesime}`
        ),
        network.fetch(`/api/exploitations/${this.props.idExploitation}/applications-tierces-exploitations`),
        network.fetch(`/api/exploitations/${this.props.idExploitation}/activities?millesime=${this.props.millesime}`),
      ]);

      if (
        assolement.length === 0 ||
        assolement.filter((a) => a.codeTypeCulture == null || a.codeCategorieCulture == null).length > 0
      ) {
        toastr.error("Erreur", "L'assolement n'a pas été défini sur cette exploitation / ce millésime");
        this.props.history.replace("/modif-assolement");
        return;
      }

      if (typesRubriques.length === 0) {
        throw new Error(
          "Aucun indicateur n'est sélectionné - indiquez au moins 1 famille de culture sur l'exploitation / le millésime"
        );
      }

      if (exploitationsActivites?.principal?.length === 0) {
        throw new Error(
          "Aucune activité principale n'est sélectionnée - sélectionnez au moins 1 activité principale sur l'exploitation / le millésime"
        );
      }

      let demarcheOrganisme = null;
      if (exploitationDemarcheInfo.exploitationDemarcheMillesime.idOrganisme != null) {
        demarcheOrganisme = await network.fetch(
          `/api/organismes/${exploitationDemarcheInfo.exploitationDemarcheMillesime.idOrganisme}`
        );
      }

      // Set etatDossier to VERIFICATION_EN_COURS if user opens a ATTENTE_VERIFICATION demarche
      if (
        exploitationDemarcheInfo.exploitationDemarcheMillesime.etatDossier ===
          procedureStateCodes.ATTENTE_VERIFICATION &&
        [roles.CONSEILLER, roles.SUPERADMIN].includes(this.props.userRole)
      ) {
        exploitationDemarcheInfo = {
          ...exploitationDemarcheInfo,
          exploitationDemarcheMillesime: {
            ...exploitationDemarcheInfo.exploitationDemarcheMillesime,
            etatDossier: procedureStateCodes.VERIFICATION_EN_COURS,
          },
        };
        await network.fetch(
          `/api/exploitations/${this.props.idExploitation}/demarches/${HVE4Static.codeNational}?millesime=${this.props.millesime}`,
          {
            method: "POST",
            body: JSON.stringify(exploitationDemarcheInfo),
          }
        );
      }

      // Set etatDossier to SAISIE_EN_COURS if user opens a ATTENTE_CORRECTION demarche
      if (
        exploitationDemarcheInfo.exploitationDemarcheMillesime.etatDossier === procedureStateCodes.ATTENTE_CORRECTION &&
        [roles.AGRICULTEUR].includes(this.props.userRole)
      ) {
        exploitationDemarcheInfo = {
          ...exploitationDemarcheInfo,
          exploitationDemarcheMillesime: {
            ...exploitationDemarcheInfo.exploitationDemarcheMillesime,
            etatDossier: procedureStateCodes.SAISIE_EN_COURS,
          },
        };
        await network.fetch(
          `/api/exploitations/${this.props.idExploitation}/demarches/${HVE4Static.codeNational}?millesime=${this.props.millesime}`,
          {
            method: "POST",
            body: JSON.stringify(exploitationDemarcheInfo),
          }
        );
      }
      const validitiesByRubriques = typesRubriques.flatMap((trub) => {
        return trub.rubriques.map((rub) => {
          const allValidities = rub.sousRubriques.map((srub) => {
            return srub.validiteSousRubrique !== null
              ? srub.validiteSousRubrique
              : {
                  idSousRubrique: srub.idSousRubrique,
                  estValide: null,
                  commentaireAuditeur: null,
                  valideIdUtilisateur: this.props.idUtilisateur,
                  valideDate: null,
                };
          });
          return {
            codeNational: rub.codeNational,
            validites: allValidities,
          };
        });
      });

      let state = {
        loading: false,
        demarche,
        exploitationDemarcheInfo,
        demarcheOrganisme,
        typesRubriques,
        indicateurs: this.flattenIndicateurs(typesRubriques),
        typeRubriqueSelected: 0,
        rubriqueSelected: 0,
        assolement,
        typesCultures,
        famillesCultures,
        exploitationsTierces,
        validitesRubriques: validitiesByRubriques,
        reducedAssolement: HVE4Static.computeReducedAssolement(assolement),
      };

      const computedFields = Object.entries(HVE4Static.computedFields);
      computedFields
        .filter(([key, value]) => state.indicateurs.hasOwnProperty(key))
        .forEach(([key, { columns, computedValue }]) => {
          state = this.changeIndicateurByCode(key, computedValue(state), true, state);
        });

      this.setState(state);
    } catch (e) {
      console.error("error while loading data", e);
      toastr.error("Erreur", `Une erreur a été rencontrée lors du chargement des données : ${e.message}`);
      this.props.history.push("/");
    }
  };

  /**
   * Show results tab
   */
  showResults = () => {
    this.scrollToTop();
    this.setState({
      showResults: true,
      typeRubriqueSelected: -1,
      rubriqueSelected: -1,
    });
  };

  /**
   * saves results, then goes back to homepage
   */
  saveResults = async ({ dateAudit, etatDossier, commentaireAuditeur, commentaireExploitant }) => {
    await network.fetch(
      `/api/exploitations/${this.props.idExploitation}/demarches/${HVE4Static.codeNational}?millesime=${this.props.millesime}`,
      {
        body: JSON.stringify({
          ...this.state.exploitationDemarcheInfo,
          exploitationDemarche: {
            ...this.state.exploitationDemarcheInfo.exploitationDemarche,
            dateAudit,
          },
          exploitationDemarcheMillesime: {
            ...this.state.exploitationDemarcheInfo.exploitationDemarcheMillesime,
            etatDossier,
            commentaireAuditeur,
            commentaireExploitant,
            dateAudit,
          },
        }),
        method: "POST",
      }
    );
    toastr.success("Enregistré", "La démarche a bien été enregistrée");
    this.props.history.push("/");
  };

  /**
   * Handles user click on the "next" button
   * Saves data, and goes to next rubrique, or go back to home if last rubrique accessed
   */
  clickNext = async () => {
    // save indicator values
    try {
      await this.saveIndicateurs();
      await this.saveValiditeRubrique();
      if (
        this.state.rubriqueSelected + 1 !==
        this.state.typesRubriques[this.state.typeRubriqueSelected].rubriques.length
      ) {
        this.setState({
          rubriqueSelected: this.state.rubriqueSelected + 1,
        });
        this.scrollToTop();
      } else if (this.state.typeRubriqueSelected + 1 !== this.state.typesRubriques.length) {
        this.setState({
          typeRubriqueSelected: this.state.typeRubriqueSelected + 1,
          rubriqueSelected: 0,
        });
        this.scrollToTop();
      } else {
        // last step : show results, or change etatDossier
        if (this.props.userRole === roles.AGRICULTEUR) {
          switch (this.state.exploitationDemarcheInfo.exploitationDemarcheMillesime.etatDossier) {
            case procedureStateCodes.SAISIE_EN_COURS:
            case procedureStateCodes.ATTENTE_CORRECTION:
              // show dialog to confirm save to EN_ATTENTE_VERIFICATION state
              this.setState({
                showConfirmDialog: true,
              });
              break;
            default:
              // show results
              this.showResults();
              break;
          }
        } else {
          // show results to change etatDossier
          this.showResults();
        }
      }
    } catch (error) {
      // nothing to do
    }
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
    document.getElementById("scrollToTop").scrollTop = 0;
  };

  /** find typeRubrique / rubrique / sousRubrique / libelleEnglobant indexes,
   * then call this.handleIndicateurChange() to set value
   * @param indicateurCode codeNational to search
   * @param indicateurNewValue value to set
   * @param checkForDependant indicates if we have to check for dependencies on this indicator
   *        (might be false to prevent infinite recursive check)
   * @param state
   */
  changeIndicateurByCode = (indicateurCode, indicateurNewValue, checkForDependant = true, state) => {
    const indicateur = state.indicateurs[indicateurCode];
    if (!indicateur) {
      return state;
    }
    let libelleEnglobantIndex, sousRubriqueIndex, rubriqueIndex, typeRubriqueIndex;
    // as we know that an indicator is present in 1 and only one libelleEnglobant, and so on,
    // we know that this search with findIndex will retrieve the correct indexes
    typeRubriqueIndex = state.typesRubriques.findIndex((typeRubrique) => {
      rubriqueIndex = typeRubrique.rubriques.findIndex((rubrique) => {
        sousRubriqueIndex = rubrique.sousRubriques.findIndex((sousRubrique) => {
          libelleEnglobantIndex = sousRubrique.libellesEnglobants.findIndex(
            (libelleEnglobant) => libelleEnglobant.idLibelleEnglobant === indicateur.idLibelleEnglobant
          );
          return libelleEnglobantIndex !== -1;
        });
        return sousRubriqueIndex !== -1;
      });
      return rubriqueIndex !== -1;
    });
    const newIndicateur = {
      ...indicateur,
      ...indicateurNewValue,
    };

    // noinspection JSUnusedAssignment
    state = this.handleIndicateurChange(
      typeRubriqueIndex,
      rubriqueIndex,
      sousRubriqueIndex,
      libelleEnglobantIndex,
      newIndicateur,
      state
    );
    return checkForDependant ? this.checkForComputedDependent(newIndicateur, state) : state;
  };

  /**
   * Method invoked to check if this indicateur is a dependent of a computed value indicator, and recompute it if needed
   * @param indicateurToCheck the indicateur to check
   * @param state state to use
   */
  checkForComputedDependent = (indicateurToCheck, state) => {
    Object.entries(HVE4Static.computedFields)
      .filter(
        ([key, { columns }]) =>
          columns.includes(indicateurToCheck.codeNational) && state.indicateurs.hasOwnProperty(key)
      )
      .forEach(([key, { columns, computedValue }]) => {
        const newIndicateur = {
          ...state.indicateurs[key],
          ...computedValue(state),
        };
        state = this.changeIndicateurByCode(key, newIndicateur, !columns.includes(key), state);
      });
    return state;
  };

  /**
   * handle change in an indicateur object
   * @param typeRubriqueIndex
   * @param rubriqueIndex
   * @param sousRubriqueIndex
   * @param libelleEnglobantIndex
   * @param indicateurValue
   * @param state input state to use
   * @returns {{typesRubriques: *[], indicateurs: *}} the state update to do
   */
  handleIndicateurChange = (
    typeRubriqueIndex,
    rubriqueIndex,
    sousRubriqueIndex,
    libelleEnglobantIndex,
    indicateurValue,
    state
  ) => {
    // Force update state object
    const libelleEnglobant =
        state.typesRubriques[typeRubriqueIndex].rubriques[rubriqueIndex].sousRubriques[sousRubriqueIndex]
          .libellesEnglobants[libelleEnglobantIndex],
      indicateurIndex = libelleEnglobant.indicateurs.findIndex((i) => i.codeNational === indicateurValue.codeNational);
    // TODO find a better way to handle this change (no-mutation)
    libelleEnglobant.indicateurs = [
      ...libelleEnglobant.indicateurs.slice(0, indicateurIndex),
      indicateurValue,
      ...libelleEnglobant.indicateurs.slice(indicateurIndex + 1),
    ];
    return {
      ...state,
      typesRubriques: [...state.typesRubriques],
      indicateurs: this.flattenIndicateurs(state.typesRubriques),
    };
  };

  /**
   * handle change in an validite object
   * @param typeRubriqueIndex
   * @param rubriqueIndex
   * @param sousRubriqueIndex
   * @param validiteValue
   * @param indicateurValue
   */
  handleValiditeChange = (typeRubriqueIndex, rubriqueIndex, sousRubriqueIndex, newValiditeValue) => {
    // Force update state object
    const idSousRubriqueToChange = this.state.typesRubriques[typeRubriqueIndex].rubriques[rubriqueIndex].sousRubriques[
      sousRubriqueIndex
    ].idSousRubrique;

    const codeRubriqueToChange = this.state.typesRubriques[typeRubriqueIndex].rubriques[rubriqueIndex].codeNational;

    let validitiesToChange = this.state.validitesRubriques.find(
      (rubVal) => rubVal.codeNational === codeRubriqueToChange
    )?.validites;
    validitiesToChange = validitiesToChange.map((item) =>
      item?.idSousRubrique === idSousRubriqueToChange ? newValiditeValue : item
    );
    this.setState((prevState) => {
      return {
        ...prevState,
        validitesRubriques: prevState.validitesRubriques.map((rubVal) =>
          rubVal.codeNational === codeRubriqueToChange ? { ...rubVal, validites: validitiesToChange } : { ...rubVal }
        ),
      };
    });
  };

  /**
   * Flattens all indicateurs from structured typesRubriques object and reduces it into an object
   * indexed by codeNational, for easier access
   * @param typesRubriques
   * @returns {T}
   */
  flattenIndicateurs = (typesRubriques) => {
    return typesRubriques
      .flatMap((typeRubrique) =>
        typeRubrique.rubriques.flatMap((rubrique) =>
          rubrique.sousRubriques.flatMap((sousRubrique) =>
            sousRubrique.libellesEnglobants.flatMap((libelleEnglobant) => libelleEnglobant.indicateurs.flat())
          )
        )
      )
      .reduce((acc, current) => {
        acc[current.codeNational] = current;
        return acc;
      }, {});
  };

  /**
   * Set which sousRubrique to display inside main view
   * @param indexTypeRubrique
   * @param indexRubrique
   */
  setSousRubriqueSelected = async (indexTypeRubrique, indexRubrique) => {
    if (this.state.rubriqueSelected !== indexRubrique) {
      await this.saveIndicateurs();
      this.scrollToTop();
      this.setState({
        rubriqueSelected: indexRubrique,
        typeRubriqueSelected: indexTypeRubrique,
        showResults: false,
      });
    }
  };

  /**
   * Saves indicateurs values
   */
  saveIndicateurs = async () => {
    try {
      await network.fetch(
        `/api/demarches/${HVE4Static.codeNational}/indicateurs/${this.props.idExploitation}?millesime=${this.props.millesime}`,
        {
          method: "PUT",
          body: JSON.stringify(Object.values(this.state.indicateurs)),
        }
      );
    } catch (error) {
      toastr.error("erreur", "Une erreur a été rencontrée lors de la sauvegarde." + error.message);
      throw error;
    }
  };

  /**
   * Saves validity and commentaries values
   */
  saveValiditeRubrique = async () => {
    try {
      const codeRubToSave = this.state.typesRubriques[this.state.typeRubriqueSelected].rubriques[
        this.state.rubriqueSelected
      ]?.codeNational;
      const rubriqueValidites = this.state.validitesRubriques
        .find((item) => item.codeNational === codeRubToSave)
        ?.validites.filter(Boolean);
      await network.fetch(
        `/api/demarches/${HVE4Static.codeNational}/validites/${this.props.idExploitation}?millesime=${this.props.millesime}`,
        {
          method: "PUT",
          body: JSON.stringify(rubriqueValidites),
        }
      );
    } catch (error) {
      toastr.error("erreur", "Une erreur a été rencontrée lors de la sauvegarde." + error.message);
      throw error;
    }
  };

  /**
   * Handles indicateur value changes inside render
   * @param newIndicateur
   * @param sousRubriqueIndex
   * @param libelleEnglobantIndex
   * @param checkForDependant
   */
  changeIndicateur = (newIndicateur, sousRubriqueIndex, libelleEnglobantIndex, checkForDependant = true) => {
    let state = this.handleIndicateurChange(
      this.state.typeRubriqueSelected,
      this.state.rubriqueSelected,
      sousRubriqueIndex,
      libelleEnglobantIndex,
      newIndicateur,
      this.state
    );
    if (checkForDependant) {
      state = this.checkForComputedDependent(newIndicateur, state);
    }

    this.setState(state);
  };

  changeSyntheseRecap = (value) => {
    this.setState({
      commentairesSyntheseResultats: value,
    });
  };

  /**
   * Handles validity value changes inside render
   * @param sousRubriqueIndex
   * @param libelleEnglobantIndex
   * @param checkForDependant
   */
  changeValidite = (newValidite, sousRubriqueIndex) => {
    this.handleValiditeChange(
      this.state.typeRubriqueSelected,
      this.state.rubriqueSelected,
      sousRubriqueIndex,
      newValidite.validiteSousRubrique
    );
  };

  getValueAssolement = (codeNational) => {
    return HVE4Static.assolement[codeNational] ? HVE4Static.assolement[codeNational](this.state) : null;
  };

  isIndicateurVisible = (codeNational) =>
    !(HVE4Static.hiddenFields[codeNational] && HVE4Static.hiddenFields[codeNational](this.state));

  isLibelleEnglobantVisible = (codeNational) =>
    !(HVE4Static.hiddenLibelleEnglobants[codeNational] && HVE4Static.hiddenLibelleEnglobants[codeNational](this.state));

  doReprise = () => {
    const { typesRubriques, typeRubriqueSelected, rubriqueSelected, validitesRubriques } = this.state;

    const sousRubriqueValides =
      validitesRubriques
        .find(
          (item) => item.codeNational === typesRubriques[typeRubriqueSelected].rubriques[rubriqueSelected].codeNational
        )
        .validites.filter((validite) => validite.estValide)
        .flatMap((x) => x.idSousRubrique) ?? [];
    //Filtre sur les inddicateurs dont la sous rubriques n'est pas validée
    const indicateursRubrique = typesRubriques[typeRubriqueSelected].rubriques[rubriqueSelected].sousRubriques
      .filter((checkedItem) => !sousRubriqueValides.includes(checkedItem.idSousRubrique))
      .flatMap((sr) => sr.libellesEnglobants.flatMap((l) => l.indicateurs));

    let state = this.state;

    for (const indicateur of indicateursRubrique) {
      if (!indicateur.estValide && !HVE4Static.computedFields[indicateur.codeNational]?.disabled) {
        let newValeur = null;

        if (indicateur.jsonSchema.$id === "assolement") {
          newValeur = {
            ...indicateur.valeur,
            value: this.getValueAssolement(indicateur.codeNational),
          };
        } else if (indicateur.jsonSchema.$id === "IFT") {
          newValeur = {
            ...indicateur.valeur,
            ...Object.entries(indicateur.valeurCalculee)
              .filter(([keyIft]) => keyIft.endsWith(indicateur.valeur.choix_type_calcul_IFT))
              .reduce(
                (acc1, [keyIft, valueIft]) => ({
                  ...acc1,
                  [keyIft.substring(0, keyIft.lastIndexOf("_"))]: Object.entries(valueIft).reduce(
                    (acc2, [keyMillesime, valueMillesime]) => {
                      return {
                        ...acc2,
                        [keyMillesime]:
                          valueMillesime ??
                          indicateur.valeur[keyIft.substring(0, keyIft.lastIndexOf("_"))][keyMillesime],
                      };
                    },
                    {}
                  ),
                }),
                {}
              ),
          };
        } else if (indicateur.valeurCalculee != null) {
          newValeur = {
            ...indicateur.valeur,
            ...indicateur.valeurCalculee,
          };
        } else if (this.getValueAssolement(indicateur.codeNational) != null) {
          newValeur = {
            ...indicateur.valeur,
            value: this.getValueAssolement(indicateur.codeNational),
          };
        }

        if (newValeur != null) {
          state = this.changeIndicateurByCode(
            indicateur.codeNational,
            {
              ...indicateur,
              valeur: newValeur,
            },
            true,
            state
          );
        }
      }
    }

    this.setState(state);
  };

  renderConfirmDialog = () => {
    const { showConfirmDialog, exploitationDemarcheInfo } = this.state;
    return (
      <SigaModal
        open={showConfirmDialog}
        showCloseButton={true}
        onClose={() => this.setState({ showConfirmDialog: false })}
        title={<Typography variant={"h2"}>Avertissement</Typography>}
      >
        <Typography variant={"p"}>
          Vous allez valider votre saisie et changer l'état du dossier en "attente de vérification". Continuer ?
        </Typography>
        <div className={"d-flex justify-center mt-1"}>
          <Button
            variant={"contained"}
            color={"primary"}
            className={"mr-1"}
            onClick={() => {
              this.saveResults({
                dateAudit: exploitationDemarcheInfo.exploitationDemarche.dateAudit,
                etatDossier: procedureStateCodes.ATTENTE_VERIFICATION,
                commentaireExploitant: exploitationDemarcheInfo.exploitationDemarcheMillesime.commentaireExploitant,
                commentaireAuditeur: exploitationDemarcheInfo.exploitationDemarcheMillesime.commentaireAuditeur,
              });
            }}
          >
            Oui
          </Button>
          <Button variant={"contained"} onClick={() => this.setState({ showConfirmDialog: false })}>
            Non
          </Button>
        </div>
      </SigaModal>
    );
  };

  /**
   * Render component
   * @returns {JSX.Element}
   */
  render() {
    const {
      loading,
      demarche,
      indicateurs,
      typesRubriques,
      typeRubriqueSelected,
      rubriqueSelected,
      demarcheOrganisme,
      exploitationDemarcheInfo,
      showResults,
      exploitationsTierces,
      famillesCultures,
      validitesRubriques,
    } = this.state;

    if (loading) {
      return <CircularProgress className="mt-2" />;
    }
    return (
      <React.Fragment>
        <Grid
          container
          spacing={3}
          className="d-flex flex-fill"
          style={{ maxHeight: "calc(100vh - 7rem)", flexWrap: "nowrap" }}
        >
          <Grid item xs={12} className="d-flex flex-column">
            <MenuHve4
              demarche={demarche}
              demarcheOrganisme={demarcheOrganisme}
              exploitationDemarcheInfo={exploitationDemarcheInfo}
              typesRubriques={typesRubriques}
              typeRubriqueSelected={typeRubriqueSelected}
              rubriqueSelected={rubriqueSelected}
              showResults={showResults}
              onShowResults={this.showResults}
              setSousRubriqueSelected={this.setSousRubriqueSelected}
              clickNext={this.clickNext}
              commentairesSyntheseResultats={this.state.commentairesSyntheseResultats}
              saveResult={this.saveResults}
            />
            <SousRubriques
              indicateurs={indicateurs}
              typesRubriques={typesRubriques}
              typeRubriqueSelected={typeRubriqueSelected}
              rubriqueSelected={rubriqueSelected}
              exploitationsTierces={exploitationsTierces}
              doReprise={this.doReprise}
              clickNext={this.clickNext}
              changeIndicateur={this.changeIndicateur}
              changeValidite={this.changeValidite}
              changeSyntheseRecap={this.changeSyntheseRecap}
              getValueAssolement={this.getValueAssolement}
              isIndicateurVisible={this.isIndicateurVisible}
              isLibelleEnglobantVisible={this.isLibelleEnglobantVisible}
              saveResult={this.saveResults}
              validitesRubriques={validitesRubriques}
              famillesCultures={famillesCultures}
              demarche={demarche}
              exploitationDemarcheInfo={exploitationDemarcheInfo}
              assolement={this.state.assolement}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }
}

export default compose(withTranslation(), connect(mapStateToProps), withRouter, withTheme)(HVE4);
