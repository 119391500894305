import React from "react";
import { withTranslation } from "react-i18next";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grid,
  Grow,
  MenuList,
  MenuItem,
  Paper,
  Popper,
  CircularProgress,
  Link,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import network from "../../actions/external/network";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { setSelectedPartner } from "../../actions/partners";

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
  app: state.app,
  selectedPartner: state.partners.selectedPartner,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedPartner: (a) => dispatch(setSelectedPartner(a)),
});

class ExternalLogin extends React.Component {
  constructor(props) {
    super(props);

    this.anchorRef = React.createRef();

    this.state = {
      partners: [],
      open: false,
      loading: false,
    };
  }

  componentDidMount() {
    this.loadPartners();
  }

  loadPartners = async () => {
    this.setState({ loading: true });
    try {
      let partners = await network.fetch("/api/applications-tierces");

      if (this.props.connectWithPartnerClientId != null) {
        const partner = partners.find((partner) => partner.clientId === this.props.connectWithPartnerClientId);
        this.props.onClick(partner);
      } else {
        if (this.props.linkExternalAccount) {
          const currentPartners = await network.fetch(`/api/utilisateurs/${this.props.auth.idUtilisateur}/partners`);
          partners = partners.filter(
            (partner) =>
              !currentPartners.some(
                (currentPartner) => currentPartner.idApplicationTierce === partner.idApplicationTierce
              )
          );
        }

        if (partners.length === 1) {
          this.props.setSelectedPartner(partners[0].idApplicationTierce);
        }

        this.setState({ partners });
      }
    } catch (error) {
      console.error("an error happened while loading partners", error);
    }
    this.setState({ loading: false });
  };

  handleClick = () => {
    const selectedPartner = this.state.partners.find(
      (partner) => partner.idApplicationTierce === this.props.selectedPartner
    );
    if (selectedPartner == null) {
      this.handleToggle();
    } else {
      this.props.onClick(selectedPartner);
    }
  };

  handleMenuItemClick = (event, index) => {
    const selectedPartner = this.state.partners[index];
    this.props.setSelectedPartner(selectedPartner.idApplicationTierce);
    this.setState({
      open: false,
    });
    this.props.onClick(selectedPartner);
  };

  handleToggle = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  };

  handleClose = (event) => {
    if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  renderList() {
    const { t, linkExternalAccount, app } = this.props;
    const { partners } = this.state;

    if (partners.length === 0) {
      if (linkExternalAccount) {
        return t("login.no-partner-available", "Aucune application tierce disponible");
      } else {
        return null;
      }
    }

    const partner = partners.find((partner) => partner.idApplicationTierce === this.props.selectedPartner);
    return (
      <Grid container direction="column" alignItems="center" className="external-login w-100">
        {app.showBtnMPConnection && (
          <ButtonGroup color="primary" variant="contained" ref={this.anchorRef} className="w-100">
            <Button onClick={this.handleClick} className="w-100">
              {partner != null
                ? `${t("login.log-in-with", "Se connecter avec")} ${partner.libelle}`
                : t("login.log-in-with-partner", "Se connecter avec MesParcelles")}
            </Button>
            {partners.length > 1 && (
              <Button size="small" onClick={this.handleToggle}>
                <FontAwesomeIcon icon="sort-down" />
              </Button>
            )}
          </ButtonGroup>
        )}

        <Popper open={this.state.open} anchorEl={this.anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList>
                    {this.state.partners.map((option, index) => (
                      <MenuItem
                        key={option.libelle}
                        selected={option.idApplicationTierce === this.props.selectedPartner}
                        onClick={(event) => this.handleMenuItemClick(event, index)}
                      >
                        {option.libelle}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    );
  }

  render() {
    if (this.state.loading) {
      return <CircularProgress className="mt-2" />;
    }

    const { t } = this.props;

    return (
      <React.Fragment>
        {this.renderList()}
        {this.props.linkExternalAccount && (
          <Link
            href="#"
            className="mt-1"
            onClick={(event) => {
              event.preventDefault();
              this.props.history.goBack();
            }}
          >
            {t("login.back", "Retour")}
          </Link>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ExternalLogin)));
