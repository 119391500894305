import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Grid } from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
import NumeriqueEntier from "../indicateurs/NumeriqueEntier";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class BiodivRuche extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const indicToWatch = ["HVE4_BIODIV_I_62", "HVE4_BIODIV_S_62"];
    return indicToWatch.some((indic) => this.props.indicateurs[indic] !== nextProps.indicateurs[indic]);
  }

  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };
  render() {
    const { indicateurs, computedFields } = this.props;
    return (
      <React.Fragment>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={2}>
            <div className={indicateurs["HVE4_BIODIV_I_62"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_I_62"].libelle}
            </div>
          </Grid>
          <Grid container item xs={2}>
            <div className={"d-flex align-items-center bold"}>
              <div>{indicateurs["HVE4_BIODIV_S_62"].libelle}</div>
            </div>
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={2}>
            <NumeriqueEntier
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_I_62"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_I_62"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              computedFields={computedFields}
            />
          </Grid>
          <Grid container item xs={2} className={"total-point"}>
            <DecimalPositif
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_S_62"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_S_62"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              computedFields={computedFields}
            />
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(BiodivRuche);
