import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Grid, InputAdornment } from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
import Percent from "../indicateurs/Percent";
import Assolement from "../indicateurs/Assolement";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class FertiSurfLegum extends React.Component {
  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };

  render() {
    const { indicateurs, computedFields, getValueAssolement } = this.props;

    const indicateursKeys = [
      "HVE4_FERTI_D_60",
      "HVE4_FERTI_D_61",
      "HVE4_FERTI_D_62",
      "HVE4_FERTI_D_63",
      "HVE4_FERTI_D_64",
      "HVE4_FERTI_D_65",
      "HVE4_FERTI_D_66",
      "HVE4_FERTI_I_60",
      "HVE4_FERTI_S_60",
    ];

    const surfacesKeys = [
      "HVE4_FERTI_D_60",
      "HVE4_FERTI_D_61",
      "HVE4_FERTI_D_62",
      "HVE4_FERTI_D_63",
      "HVE4_FERTI_D_64",
    ];

    const indicateursToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateursKeys.includes(key)))
    );

    const surfacesToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => surfacesKeys.includes(key)))
    );

    if (indicateursToDisplay.length > 0)
      return (
        <React.Fragment>
          <Grid container spacing={2}>
            {surfacesToDisplay.map((indic, i) => (
              <React.Fragment key={"div-main-" + i}>
                <Grid item xs={5}>
                  <div className={indic.afficherGras ? "mr-3 bold" : "mr-3"}>{indic.libelle}</div>
                </Grid>
                <Grid item xs={4}>
                  <Assolement
                    size={"small"}
                    getValueAssolement={getValueAssolement}
                    indicateur={indic}
                    key={"indicateur-" + indic.idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indic.libelleUnite != null && {
                        endAdornment: <InputAdornment position={"end"}>{indic.libelleUnite}</InputAdornment>,
                      }
                    }
                    computedFields={computedFields}
                  />
                </Grid>
              </React.Fragment>
            ))}
            <Grid container item xs={1}>
              <div className={"mr-3"}>Part des légumineuses sur la SAU</div>
            </Grid>
            <Grid container item xs={1}>
              <div className={"mr-3"}>Total points "Légumineuses"</div>
            </Grid>
            <Grid container item xs={5}>
              <div className={"mr-3"}>{indicateurs["HVE4_FERTI_D_65"].libelle}</div>
            </Grid>
            <Grid container item xs={4}>
              <DecimalPositif
                size={"small"}
                indicateur={indicateurs["HVE4_FERTI_D_65"]}
                displayRules={{ hasRightDisplay: true }}
                key={"indicateur-" + indicateurs["HVE4_FERTI_D_65"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_FERTI_D_65"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_FERTI_D_65"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
            <Grid container item xs={5}>
              <div className={"mr-3"}>Surface totale en légumineuses</div>
            </Grid>
            <Grid container item xs={4}>
              <DecimalPositif
                displayRules={{ hasRightDisplay: true }}
                size={"small"}
                indicateur={indicateurs["HVE4_FERTI_D_66"]}
                key={"indicateur-" + indicateurs["HVE4_FERTI_D_66"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_FERTI_D_66"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_FERTI_D_66"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
            <Grid container item xs={1}>
              <Percent
                size={"small"}
                indicateur={indicateurs["HVE4_FERTI_I_60"]}
                key={"indicateur-" + indicateurs["HVE4_FERTI_I_60"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_FERTI_I_60"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_FERTI_I_60"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
            <Grid container item xs={1} className={"total-point"}>
              <DecimalPositif
                size={"small"}
                indicateur={indicateurs["HVE4_FERTI_S_60"]}
                key={"indicateur-" + indicateurs["HVE4_FERTI_S_60"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_FERTI_S_60"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_FERTI_S_60"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
          </Grid>
        </React.Fragment>
      );
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(FertiSurfLegum);
