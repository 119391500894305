import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ConditionalRoute from "./ConditionalRoute";

const mapStateToProps = (state, ownProps) => ({
  isAuthenticated: state.auth.isAuthenticated,
  exploitationSelected: state.exploitation.selected,
});

const WithExploitationRoute = ({ children, isAuthenticated, exploitationSelected, ...rest }) => {
  const conditions = [
    {
      test: () => isAuthenticated,
      redirectTo: "/login",
    },
    {
      test: () => !!exploitationSelected,
      redirectTo: "/change-exploitation",
    },
  ];
  return (
    <ConditionalRoute {...rest} conditions={conditions}>
      {children}
    </ConditionalRoute>
  );
};

export default withRouter(connect(mapStateToProps)(WithExploitationRoute));
