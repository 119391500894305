import * as Sentry from "@sentry/react";
import { SET_USER_INFOS } from "./actions/auth";

export const initSentry = () => {
  const sentryConfig = window.sentryConfig;
  if (sentryConfig) {
    Sentry.init({
      dsn: sentryConfig.dsn,
      environment: sentryConfig.environment,
      release: sentryConfig.release,
    });
    Sentry.setTag("frontend", true);
  }
};

const sentryFilter = (obj, keys) => ({ ...obj, ...keys.reduce((acc, key) => ({ ...acc, [key]: null }), {}) });

const filterUserInfos = (obj) => sentryFilter(obj, ["token", "login", "email", "nextEmail", "prenom", "nom"]);

export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  actionTransformer: (action) => {
    if (action.type === SET_USER_INFOS) {
      return {
        ...action,
        payload: filterUserInfos(action.payload),
      };
    }
    return action;
  },
  stateTransformer: (state) => {
    return {
      ...state,
      auth: filterUserInfos(state.auth),
    };
  },
});
