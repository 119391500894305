import React from "react";
import NumberInput from "../../../../../common/NumberInput";
import Indicator from "../../Indicator";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

class Assolement extends React.Component {
  render() {
    const {
      indicateur,
      onChangeIndicator,
      sousRubriqueIndex,
      libelleEnglobantIndex,
      changeIndicateur,
      valueInputProps,
      computedFields,
      getValueAssolement,
    } = this.props;

    const valueRendered = (
      <React.Fragment>
        <NumberInput
          disabled
          fullWidth={false}
          style={{ maxWidth: "8rem" }}
          label={"Valeur assolement"}
          value={getValueAssolement(indicateur.codeNational) ?? ""}
          InputProps={valueInputProps}
          numberOfDecimals={2}
        />
        <NumberInput
          className={"ml-2"}
          fullWidth={false}
          style={{ maxWidth: "8rem" }}
          value={indicateur.valeur.value ?? ""}
          InputLabelProps={{
            style: { fontWeight: indicateur.afficherGras ? "bold" : "normal" },
          }}
          InputProps={valueInputProps}
          disabled={computedFields.includes(indicateur.codeNational)}
          onChange={(newValeur) =>
            changeIndicateur(
              { ...indicateur, valeur: { value: newValeur === "" ? null : Number(newValeur) } },
              sousRubriqueIndex,
              libelleEnglobantIndex
            )
          }
          numberOfDecimals={2}
        />
      </React.Fragment>
    );

    return (
      <Indicator indicateur={indicateur} onChange={onChangeIndicator}>
        {valueRendered}
      </Indicator>
    );
  }
}

export default compose(withTranslation())(Assolement);
