import React from "react";
import { Box, Button, IconButton, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DocumentsModal } from "./Documents";
import { withTranslation } from "react-i18next";
import DocumentDownload from "./DocumentDownload";

class DocumentInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
    };
  }

  openModal = () => {
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  onSelect = (document) => {
    this.props.onSelect(document);
    this.closeModal();
  };

  render() {
    const { t, document, typeDocumentCode, onRemove, name } = this.props;
    const { showModal } = this.state;

    return (
      <Box className="d-flex">
        <Button color="primary" style={{ height: "fit-content" }} variant="contained" onClick={this.openModal}>
          {t("demarche.ajouter-fichier", "Ajouter un fichier")}
        </Button>
        {document &&
          // Gestion des cas avec fichiers multiples
          ((name === "exploitationDemarche.documentHve1" || name === "exploitationDemarche.documentHve2") &&
          document.nom === undefined ? (
            <div>
              {Object.entries(document).map((data, key) => {
                return (
                  <Box className="ml-2">
                    {data[1].nom}
                    <DocumentDownload idDocument={data[1].idDocument} />
                    <Tooltip title={t("delete.term", "Supprimer")}>
                      <IconButton onClick={() => onRemove(key)}>
                        <FontAwesomeIcon icon="times" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                );
              })}
            </div>
          ) : (
            <Box className="ml-2">
              {document.nom}
              <DocumentDownload idDocument={document.idDocument} />
              <Tooltip title={t("delete.term", "Supprimer")}>
                <IconButton onClick={() => onRemove()}>
                  <FontAwesomeIcon icon="times" />
                </IconButton>
              </Tooltip>
            </Box>
          ))}

        <DocumentsModal
          showModal={showModal}
          onCloseModal={this.closeModal}
          typeDocumentCode={typeDocumentCode}
          onSelect={this.onSelect}
        />
      </Box>
    );
  }
}

export default withTranslation()(DocumentInput);
