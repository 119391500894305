import React from "react";
import { TextField, CircularProgress, debounce } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";

class AsyncSearch extends React.Component {
  loadResult = debounce(async (userInput) => {
    try {
      const result = userInput.length ? await this.props.loadAsync(userInput) : [];
      this.setState({
        loading: false,
        options: result,
      });
    } catch (error) {
      if (this._mounted) {
        // if unable to fetch, reset component
        this.setState({
          open: false,
          loading: false,
          options: [],
        });
      }
    }
  }, this.props.debounceTime);

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      options: [],
    };
  }

  componentDidMount() {
    this._mounted = true;
  }

  componentWillUnmount() {
    this._mounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (this._mounted) {
      if (this.props.value !== null && this.props.value !== "" && this.state.options.length === 0) {
        this.setState({ options: [this.props.value] });
      }
    }
  }

  handleInputChange = (event, value) => {
    if (event && event.type !== "blur") {
      this.loadResult(value);
    }
  };

  render() {
    const {
        t,
        tReady,
        i18n,
        onChange,
        className,
        label,
        getOptionSelected,
        getOptionLabel,
        loadAsync,
        helperText,
        error,
        value,
        required,
        debounceTime,
        freeSolo,
        ...rest
      } = this.props,
      { open, options, loading } = this.state;
    /**
     * TODO check this issue for handling popup indicator (causes dropdown list to show when we don't need it)
     * that causes to set onMouseDownCapture dummy interceptor as a workaround, and to set the loading state before we effectively fetch something.
     *  see if Material UI is reactive on https://github.com/mui-org/material-ui/issues/20286
     */
    return (
      <Autocomplete
        openOnFocus={false}
        open={open}
        onOpen={() => this.setState({ open: true })}
        onClose={() => this.setState({ open: false })}
        value={value}
        freeSolo={freeSolo}
        onChange={(event, value, reason) =>
          onChange({
            event,
            value,
            reason,
          })
        }
        clearOnBlur
        getOptionSelected={getOptionSelected}
        getOptionLabel={getOptionLabel}
        options={options}
        onInputChange={this.handleInputChange}
        loading={loading}
        loadingText={t("loading", "Chargement...")}
        noOptionsText={t("no-result", "Pas de résultats.")}
        {...rest}
        renderInput={(params) => (
          <TextField
            className={className}
            {...params}
            onMouseDownCapture={(e) => e.stopPropagation()}
            label={label}
            variant="outlined"
            error={error}
            helperText={helperText}
            required={required}
            InputProps={{
              ...params.InputProps,
              endAdornment: loading ? <CircularProgress color="inherit" size={20} /> : null,
            }}
          />
        )}
      />
    );
  }
}
AsyncSearch.propTypes = {
  label: PropTypes.string.isRequired,
  getOptionSelected: PropTypes.func.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  loadAsync: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  debounceTime: PropTypes.number,
  freeSolo: PropTypes.bool,
};
AsyncSearch.defaultProps = {
  value: "",
  debounceTime: 500,
  freeSolo: true,
  filterOptions: (options) => options,
};
export default withTranslation()(AsyncSearch);
