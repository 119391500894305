import React from "react";
import Indicator from "../../Indicator";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Chip, ListSubheader, MenuItem, TextField, Tooltip } from "@material-ui/core";
import styles from "../style.module.scss";

class ListeMateriel extends React.Component {
  render() {
    const {
      indicateur,
      onChangeIndicator,
      sousRubriqueIndex,
      libelleEnglobantIndex,
      changeIndicateur,
      computedFields,
    } = this.props;

    const HVE3A_MATERIELS = [
      {
        title: "Equipements sur le site de l’exploitation",
        items: [
          {
            code: "AMGAIR",
            libelle:
              "Aménagement de l’aire de remplissage et de lavage étanche avec système de récupération de débordements accidentels",
          },
          {
            code: "POTENC",
            libelle: "Potence, réserve d’eau surélevée",
          },
          {
            code: "PLATEAU",
            libelle: "Plateau de stockage avec bac de rétention pour le local phytosanitaire",
          },
          {
            code: "AMPAIL",
            libelle:
              "Aménagement d’une paillasse ou plate-forme stable pour préparer les bouillies, matériel de pesée etoutils de dosage",
          },
          {
            code: "RESCOL",
            libelle:
              "Réserves de collecte des eaux de pluie et réseau correspondant (équipements à l’échelle des bâtiments de l’exploitation)",
          },
          {
            code: "VOLUC",
            libelle: "Volu-compteur programmable non embarqué pour éviter les débordements decuve Equipement Pulvé",
          },
        ],
      },
      {
        title: "Equipements spécifiques du pulvérisateur",
        items: [
          {
            code: "MATPRELOC",
            libelle: "Matériel de précision permettant de localiser le traitement",
          },
          {
            code: "VOLUCPRO",
            libelle: "Volucompteur programmable pour éviter le débordement des cuves",
          },
          {
            code: "SYSTANTIGT",
            libelle: "Système anti-gouttes (à la rampe pour la régularité de la pulvérisation)",
          },
          {
            code: "SYSTCONF",
            libelle:
              "Système de confinement et de récupération des excédents de bouillie sur lesappareils de traitement fixes",
          },
          {
            code: "SYSTINJ",
            libelle: "Système d’injection directe de la matière active, système de circulation continue des bouillies",
          },
          {
            code: "PANRECUP",
            libelle: "Panneaux récupérateurs de bouillie",
          },
          {
            code: "MATPREDOS",
            libelle:
              "Matériel de précision permettant de réduire les doses de produits phytosanitaires (traitement face par face)",
          },
          {
            code: "CUVRINC",
            libelle:
              "Cuve de rinçage embarquée sur le pulvérisateur (ou sur le tracteur) avec kit de rinçage intérieur des cuves ou kit d’automisation de rinçage des cuves",
          },
          {
            code: "KITENV",
            libelle:
              "« kit environnement » système antidébordement, buses anti-dérives, les rampes équipées de systèmes anti-gouttes et la cuve de rinçage",
          },
        ],
      },
    ];

    const valueRendered = (
      <TextField
        fullWidth={false}
        select
        disabled={computedFields.includes(indicateur.codeNational)}
        SelectProps={{
          multiple: true,
          renderValue: (selected) => (
            <div className="d-flex flex-wrap">
              {selected.map((value) => {
                const libelle = HVE3A_MATERIELS.flatMap((m) => m.items).find((materiel) => materiel.code === value)
                  ?.libelle;
                return (
                  <Tooltip title={libelle} key={value}>
                    <Chip key={value} label={libelle} className={styles.wrapperChip} />
                  </Tooltip>
                );
              })}
            </div>
          ),
        }}
        style={{ width: "20rem" }}
        value={indicateur.valeur.value || []}
        onChange={(event) =>
          changeIndicateur(
            { ...indicateur, valeur: { value: event.target.value.filter((i) => i !== undefined) } },
            sousRubriqueIndex,
            libelleEnglobantIndex
          )
        }
      >
        {HVE3A_MATERIELS.flatMap((listeMateriel) => [
          {
            type: "head",
            title: listeMateriel.title,
          },
          ...listeMateriel.items.map((materiel) => ({
            type: "item",
            materiel,
          })),
        ]).map((elem, i) =>
          elem.type === "head" ? (
            <ListSubheader key={i}>{elem.title}</ListSubheader>
          ) : (
            <MenuItem key={i} value={elem.materiel.code}>
              {elem.materiel.libelle}
            </MenuItem>
          )
        )}
      </TextField>
    );

    return (
      <Indicator indicateur={indicateur} onChange={onChangeIndicator}>
        {valueRendered}
      </Indicator>
    );
  }
}

export default compose(withTranslation())(ListeMateriel);
