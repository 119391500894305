import React from "react";
import AsyncSearch from "./AsyncSearch";
import network from "../../actions/external/network";
import PropTypes from "prop-types";
import i18next from "i18next";

class UserSearch extends React.Component {
  static propTypes = {
    getOptionSelected: PropTypes.func,
    getOptionLabel: PropTypes.func,
    loadAsync: PropTypes.func,
    label: PropTypes.string,
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired,
  };

  static get defaultProps() {
    return {
      getOptionSelected: (option, value) => option.idUtilisateur === value.idUtilisateur,
      getOptionLabel: (option) => (option ? option.prenom + " " + option.nom : ""),
      loadAsync: async (search) => {
        const urlSearchParams = new URLSearchParams([
          ["search", search],
          ["searchFields", "nom"],
          ["searchFields", "prenom"],
        ]);
        const { data } = await network.fetch(`/api/utilisateurs?${urlSearchParams}`);
        return data;
      },
      label: i18next.t("user", "Utilisateur"),
    };
  }

  render() {
    return <AsyncSearch {...this.props} freeSolo={false} />;
  }
}

export default UserSearch;
