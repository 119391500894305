import React from "react";
import { withRouter } from "react-router-dom";
import { useTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import network from "../../../actions/external/network";
import { toastr } from "react-redux-toastr";
import { Box, Card, CardContent, CardHeader, CircularProgress, Typography } from "@material-ui/core";
import UtilisateurForm from "./UtilisateurForm";
import SigaModal from "../../common/SigaModal";

const mapStateToProps = (state) => ({
  auth: state.auth,
});

let Utilisateur = class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      utilisateur: null,
      typesUtilisateurs: [],
      organismes: [],
      demarches: [],
      entites: [],
      entitesUtilisateur: [],
      loading: true,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  isEditing = () => this.props.idUtilisateur != null;

  loadData = async () => {
    const { t } = this.props;
    try {
      const [utilisateur, typesUtilisateurs, organismes, demarches, entites, entitesUtilisateur] = await Promise.all([
        this.isEditing() ? network.fetch(`/api/utilisateurs/${this.props.idUtilisateur}`) : Promise.resolve(null),
        network.fetch("/api/types-utilisateurs"),
        network.fetch(
          "/api/organismes?context=admin/utilisateur" +
            (this.isEditing() ? `&idUtilisateur=${this.props.idUtilisateur}` : "")
        ),
        network.fetch(`/api/demarches`),
        network.fetch("/api/entites"),
        network.fetch(`/api/utilisateurs/${this.props.auth.idUtilisateur}/entites`),
      ]);

      this.setState({
        utilisateur,
        typesUtilisateurs,
        organismes,
        demarches,
        entites,
        entitesUtilisateur,
        loading: false,
      });
    } catch (error) {
      toastr.error(t("error", "Erreur"), t("admin.users.load-error", "Erreur lors du chargement des données"));
    }
  };

  onSubmit = async (values) => {
    const { t } = this.props;

    try {
      const response = await network.fetch(
        `/api/utilisateurs${this.isEditing() ? `/${this.props.idUtilisateur}` : ""}`,
        {
          method: this.isEditing() ? "PUT" : "POST",
          body: JSON.stringify(values),
        }
      );

      toastr.success(
        "OK",
        this.isEditing()
          ? t("admin.users.modify-success", "Compte modifié avec succès")
          : t("admin.users.create-success", "Compte créé avec succès")
      );

      this.setState({ utilisateur: response.userInfo });

      this.props.afterSubmit && this.props.afterSubmit();
    } catch (error) {
      toastr.error(t("error", "Erreur"), error.message);
    }
  };

  render() {
    return (
      <Box className="w-100">
        {this.state.loading ? (
          <CircularProgress className="mt-2" />
        ) : (
          <UtilisateurForm
            utilisateur={this.state.utilisateur}
            typesUtilisateurs={this.state.typesUtilisateurs}
            organismes={this.state.organismes}
            demarches={this.state.demarches}
            entites={this.state.entites}
            entitesUtilisateur={this.state.entitesUtilisateur}
            onSubmit={this.onSubmit}
          />
        )}
      </Box>
    );
  }
};

Utilisateur = compose(withTranslation(), connect(mapStateToProps), withRouter)(Utilisateur);

export const UtilisateurPage = (props) => {
  const { t } = useTranslation();

  return (
    <Card className="ml-2">
      <CardHeader title={<Typography variant="h3">{t("user", "Utilisateur")}</Typography>} />
      <CardContent>
        <Utilisateur {...props} />
      </CardContent>
    </Card>
  );
};

export const UtilisateurModal = (props) => {
  const { t } = useTranslation();
  const { showModal, onCloseModal, ...other } = props;

  return (
    <SigaModal
      open={showModal}
      onClose={onCloseModal}
      title={<Typography variant="h3">{t("user", "Utilisateur")}</Typography>}
    >
      <Utilisateur {...other} />
    </SigaModal>
  );
};
