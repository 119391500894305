import React from "react";
import { Box, Button, Card, CardContent, CardHeader, Divider, Typography } from "@material-ui/core";
import clsx from "clsx";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import withTheme from "@material-ui/styles/withTheme";
import SurfaceSynthese from "./SousRubriques/SurfaceSynthese";
import DefaultSousRubrique from "./SousRubriques/DefaultSousRubrique";
import HVE4Static from "./HVE4Static";
import SAU from "./SousRubriques/SurfaceSAU";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import confirm from "../../../common/confirm";
import ValideNonValide from "./ValideNonValide";
import HortiPepi from "./SousRubriques/SurfaceHortiPepi";
import SneSna from "./SousRubriques/SurfaceSneSna";
import LimiteCMR from "./SousRubriques/PhytoLimiteCMR";
import IrrigInfos from "./SousRubriques/IrrigInfos";
import PratiquesIrrigations from "./SousRubriques/IrrigPratiquesIrrigations";
import SurfNonTrt from "./SousRubriques/PhytoSurfNonTrt";
import OutilsMesure from "./SousRubriques/IrrigOutilsMesure";
import BiodivIAE from "./SousRubriques/BiodivIAE";
import BiodivParc from "./SousRubriques/BiodivParc";
import MaterielOptimisant from "./SousRubriques/IrrigMaterielOptimisant";
import BiodivPoidsCult from "./SousRubriques/BiodivPoidsCult";
import IrrigDemCol from "./SousRubriques/IrrigDemCol";
import IFT from "./SousRubriques/PhytoIFT";
import BiodivNbVege from "./SousRubriques/BiodivNbVege";
import IrrigPratiquesAgro from "./SousRubriques/IrrigPratiquesAgro";
import IrriEtiage from "./SousRubriques/IrrigEtiage";
import BiodivNbAnim from "./SousRubriques/BiodivNbAnim";
import BiodivRuche from "./SousRubriques/BiodivRuche";
import SubsActives from "./SousRubriques/PhytoSubsActives";
import BiodivEspecesMenacees from "./SousRubriques/BiodivEspecesMenacees";
import BiodivQualite from "./SousRubriques/BiodivQualite";
import RecycleEaux from "./SousRubriques/IrrigRecycleEaux";
import SurvActive from "./SousRubriques/PhytoSurvActive";
import MethAlt from "./SousRubriques/PhytoMethAlt";
import FertiBilanAzote from "./SousRubriques/FertiBilanAzote";
import RecupEauxPluies from "./SousRubriques/IrrigRecupEauxPluies";
import FertiApportAzote from "./SousRubriques/FertiApportAzote";
import FertiAzoteOrga from "./SousRubriques/FertiAzoteOrga";
import FertiOAD from "./SousRubriques/FertiOAD";
import SauNonFerti from "./SousRubriques/FertiSauNonFerti";
import FertiSurfLegum from "./SousRubriques/FertiSurfLegum";
import FertiCouvSol from "./SousRubriques/FertiCouvSol";
import FertiMaterielOptimisant from "./SousRubriques/FertiMaterielOptimisant";
import FertiRecyclEauxIrrig from "./SousRubriques/FertiRecyclEauxIrrig";
import DiversiteSpecifique from "./SousRubriques/PhytoDiversiteSpecifique";
import AppliTrait from "./SousRubriques/PhytoAppliTrait";
import BiodivSynthese from "./SousRubriques/BiodivSynthese";
import VegInterrang from "./SousRubriques/PhytoVegInterrang";
import RecycleTraitEaux from "./SousRubriques/PhytoRecycleTraitEaux";
import FertiSynthese from "./SousRubriques/FertiSynthese";
import IrriSynthese from "./SousRubriques/IrrigSynthese";
import PhytoSynthese from "./SousRubriques/PhytoSynthese";
import SyntheseRecap from "./SousRubriques/SyntheseRecap";
import ValiditeSynthese from "./ValiditeSynthese";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class SousRubriques extends React.Component {
  constructor(props) {
    super(props);
    this.props.typesRubriques[0]?.rubriques[this.props.rubriqueSelected]?.sousRubriques.map((sr) => {
      return (this[`anchor-${sr.codeNational}`] = React.createRef());
    });
    this.state = {
      sousRubriqueSelected: this.props.typesRubriques[0].rubriques[this.props.rubriqueSelected].sousRubriques[0],
    };
  }
  componentDidUpdate(prevProps, PrevState) {
    const { typesRubriques, rubriqueSelected } = this.props;
    if (prevProps.rubriqueSelected !== rubriqueSelected) {
      this.setState({
        sousRubriqueSelected: typesRubriques[0].rubriques[rubriqueSelected].sousRubriques[0],
      });
      typesRubriques[0]?.rubriques[rubriqueSelected]?.sousRubriques.map((sr) => {
        return (this[`anchor-${sr.codeNational}`] = React.createRef());
      });
    }
  }

  handleSelect = (sr) => {
    const targetElement = this[`anchor-${sr.codeNational}`];
    targetElement?.current.scrollIntoView({ behavior: "smooth" });
    this.setState({
      sousRubriqueSelected: sr,
    });
  };
  isScoreRubrique(codeNational) {
    const sousRubriquesSynthese = [
      "HVE4_PHYTO_SYNTHESE",
      "HVE4_BIODIV_SYNTHESE",
      "HVE4_FERTI_SYNTHESE",
      "HVE4_IRRIG_SYNTHESE",
      "HVE4_SYNTHESE_RECAP",
    ];
    return sousRubriquesSynthese.includes(codeNational);
  }

  renderSousRubrique = (sousRubrique, sousRubriqueIndex) => {
    const {
      changeIndicateur,
      changeSyntheseRecap,
      getValueAssolement,
      assolement,
      indicateurs,
      famillesCultures,
      saveResult,
      demarche,
    } = this.props;

    const computedFields = Object.entries(HVE4Static.computedFields)
      .filter(([key, value]) => value.disabled)
      .map(([key, value]) => key);

    const Component =
      {
        HVE4_SURFACE_SYNTHESE: SurfaceSynthese,
        HVE4_SURFACE_SAU: SAU,
        HVE4_SURFACE_HORTI_PEPI: HortiPepi,
        HVE4_SURFACE_SNE_SNA: SneSna,
        HVE4_IRRIG_AGRO_ECO: IrrigPratiquesAgro,
        HVE4_IRRIG_INFOS: IrrigInfos,
        HVE4_IRRIG_PRATIQUES: PratiquesIrrigations,
        HVE4_IRRIG_OAD: OutilsMesure,
        HVE4_IRRIG_MATERIELS_APPORT: MaterielOptimisant,
        HVE4_IRRIG_PRELEV_ETIAGE: IrriEtiage,
        HVE4_IRRIG_DEM_COLL: IrrigDemCol,
        HVE4_IRRIG_RECYCL_EAUX: RecycleEaux,
        HVE4_IRRIG_RECUP_PLUIE: RecupEauxPluies,
        HVE4_BIODIV_INFRA: BiodivIAE,
        HVE4_BIODIV_PARC: BiodivParc,
        HVE4_BIODIV_POIDS_CULT: BiodivPoidsCult,
        HVE4_BIODIV_NB_VEGE: BiodivNbVege,
        HVE4_BIODIV_NB_ANIM: BiodivNbAnim,
        HVE4_BIODIV_RUCHE: BiodivRuche,
        HVE4_BIODIV_MENACEES: BiodivEspecesMenacees,
        HVE4_BIODIV_QUALITE_SOL: BiodivQualite,
        HVE4_PHYTO_SURF_NON_TRAIT: SurfNonTrt,
        HVE4_PHYTO_LIMITE_CMR: LimiteCMR,
        HVE4_PHYTO_IFT: IFT,
        HVE4_PHYTO_SUBS_ACT: SubsActives,
        HVE4_PHYTO_SURV_ACTIVE: SurvActive,
        HVE4_PHYTO_METH_ALT: MethAlt,
        HVE4_PHYTO_DIVERSITE: DiversiteSpecifique,
        HVE4_PHYTO_APPLI_TRAIT: AppliTrait,
        HVE4_PHYTO_VEG_INTERRANG: VegInterrang,
        HVE4_PHYTO_RECYCL_EAUX: RecycleTraitEaux,
        HVE4_FERTI_BILAN_AZOTE: FertiBilanAzote,
        HVE4_FERTI_APPORT_AZOTE: FertiApportAzote,
        HVE4_FERTI_PART_AZOTE: FertiAzoteOrga,
        HVE4_FERTI_OAD: FertiOAD,
        HVE4_FERTI_SAU_NON_FERTI: SauNonFerti,
        HVE4_FERTI_SURF_LEGUM: FertiSurfLegum,
        HVE4_FERTI_COUV_SOLS: FertiCouvSol,
        HVE4_FERTI_MATERIELS_APPORT: FertiMaterielOptimisant,
        HVE4_FERTI_RECYCL_EAUX: FertiRecyclEauxIrrig,
        HVE4_BIODIV_SYNTHESE: BiodivSynthese,
        HVE4_PHYTO_SYNTHESE: PhytoSynthese,
        HVE4_FERTI_SYNTHESE: FertiSynthese,
        HVE4_IRRIG_SYNTHESE: IrriSynthese,
      }[sousRubrique.codeNational] ?? DefaultSousRubrique;

    if (sousRubrique.codeNational === "HVE4_SYNTHESE_RECAP") {
      return (
        <SyntheseRecap
          rubriques={this.props.typesRubriques.flatMap((tr) => tr.rubriques)}
          indicateurs={indicateurs}
          exploitationDemarcheInfo={this.props.exploitationDemarcheInfo}
          saveResult={saveResult}
          changeSyntheseRecap={changeSyntheseRecap}
        />
      );
    } else {
      return (
        <Component
          changeIndicateur={changeIndicateur}
          getValueAssolement={getValueAssolement}
          indicateurs={indicateurs}
          sousRubriqueIndex={sousRubriqueIndex}
          libelleEnglobantIndex={0}
          computedFields={computedFields}
          assolement={assolement}
          famillesCultures={famillesCultures}
          demarche={demarche}
        />
      );
    }
  };

  renderHeader = () => {
    const { typesRubriques, rubriqueSelected, theme } = this.props;
    if (typesRubriques[0].rubriques[rubriqueSelected].sousRubriques.length > 1) {
      return (
        <CardHeader
          style={{ padding: "1em" }}
          title={
            <React.Fragment>
              <Box className="d-flex justify-between align-items-center">
                <Box>
                  {typesRubriques[0].rubriques[rubriqueSelected].sousRubriques.map((sr, i) => (
                    <Button
                      key={"sousrubrique-" + i}
                      style={{
                        fontSize: "13px",
                        backgroundColor:
                          this.state.sousRubriqueSelected.codeNational === sr.codeNational
                            ? theme.palette.primary.main
                            : theme.palette.type === "light"
                            ? "#eeeeee"
                            : theme.palette.background.default,
                        color: theme.palette.text.primary,
                        minHeight: "30px",
                        margin: "3px",
                        textTransform: "none",
                      }}
                      onClick={() => this.handleSelect(sr)}
                    >
                      {sr.libelleShort || sr.libelle}
                    </Button>
                  ))}
                </Box>
                <Box style={{ whiteSpace: "nowrap" }}>{this.renderRepriseButton()}</Box>
              </Box>
            </React.Fragment>
          }
          disableTypography={false}
        />
      );
    } else {
      return null;
    }
  };

  renderRepriseButton = () => {
    const {
      t,
      doReprise,
      typesRubriques,
      typeRubriqueSelected,
      rubriqueSelected,
      exploitationsTierces,
      demarche,
    } = this.props;
    const currentRubrique = typesRubriques[typeRubriqueSelected]?.rubriques[rubriqueSelected];
    let showButton = [
      "HVE4_SURFACE",
      "HVE4_BIODIVERSITE",
      "HVE4_PHYTOSANITAIRE",
      "HVE4_FERTILISATION",
      "HVE4_IRRIGATION",
    ].includes(currentRubrique.codeNational);

    const onClick = async () => {
      let message = "Les données saisies vont être mises à jour avec les valeurs de ";
      if (demarche.code === HVE4Static.codeNational) {
        if (currentRubrique.codeNational === "HVE4_SURFACE" && exploitationsTierces.length > 0) {
          message += "MesParcelles et de l'assolement";
        } else if (currentRubrique.codeNational === "HVE4_SURFACE") {
          message += "l'assolement";
        } else {
          message += "MesParcelles";
        }
        message += ", seules les données valides ne seront pas modifiées, confirmez-vous ?";
      }

      if (await confirm(message)) {
        doReprise();
      }
    };

    if (showButton) {
      return (
        <Button color="primary" className="ml-1" onClick={onClick}>
          <FontAwesomeIcon icon="arrow-alt-circle-right" className="mr-05" />
          {t("reprise-button", "Reprise des données")}
        </Button>
      );
    }
  };

  render() {
    const { typesRubriques, typeRubriqueSelected, rubriqueSelected, changeValidite, validitesRubriques } = this.props;
    return (
      <Card className={"d-flex flex-fill flex-column"}>
        {this.renderHeader()}
        <Divider />
        <CardContent style={{ overflowY: "auto" }} id="scrollToTop">
          <div>
            {typesRubriques[typeRubriqueSelected]?.rubriques[rubriqueSelected]?.sousRubriques.map(
              (sousRubrique, sousRubriqueIndex) => (
                <React.Fragment key={"sous-rubrique-" + sousRubrique.idSousRubrique}>
                  {sousRubrique.libelle && (
                    <Typography
                      ref={this[`anchor-${sousRubrique.codeNational}`]}
                      variant={"h2"}
                      className={clsx({ "mt-2": sousRubriqueIndex !== 0 })}
                    >
                      {sousRubrique.libelle}
                    </Typography>
                  )}
                  <div className={"my-1"}>{this.renderSousRubrique(sousRubrique, sousRubriqueIndex)}</div>
                  {!this.isScoreRubrique(sousRubrique?.codeNational) ? (
                    <ValideNonValide
                      onChange={changeValidite}
                      sousRubriqueIndex={sousRubriqueIndex}
                      validite={validitesRubriques
                        .find((x) => {
                          return (
                            x.codeNational ===
                            typesRubriques[typeRubriqueSelected]?.rubriques[rubriqueSelected]?.codeNational
                          );
                        })
                        ?.validites.find((validity) => validity?.idSousRubrique === sousRubrique?.idSousRubrique)}
                    />
                  ) : (
                    sousRubrique?.codeNational !== "HVE4_SYNTHESE_RECAP" && (
                      <ValiditeSynthese
                        onChange={changeValidite}
                        sousRubriqueIndex={sousRubriqueIndex}
                        validite={validitesRubriques
                          .find((x) => {
                            return (
                              x.codeNational ===
                              typesRubriques[typeRubriqueSelected]?.rubriques[rubriqueSelected]?.codeNational
                            );
                          })
                          ?.validites.find((validity) => validity?.idSousRubrique === sousRubrique?.idSousRubrique)}
                      />
                    )
                  )}
                </React.Fragment>
              )
            )}
          </div>
        </CardContent>
      </Card>
    );
  }
}

export default compose(withTranslation(), connect(mapStateToProps), withRouter, withTheme)(SousRubriques);
