import { combineReducers } from "redux";
import login from "./login";
import auth from "./auth";
import config from "./config";
import millesime from "./millesime";
import organisme from "./organisme";
import exploitation from "./exploitation";
import { reducer as toastrReducer } from "react-redux-toastr";
import network from "./network";
import app from "./app";
import partners from "./partners";

export default combineReducers({
  login,
  auth,
  app,
  config,
  millesime,
  exploitation,
  network,
  organisme,
  partners,
  toastr: toastrReducer,
});
