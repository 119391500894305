import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Grid, IconButton, InputAdornment, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class BiodivParc extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const indicToWatch = ["HVE4_BIODIV_D_37", "HVE4_BIODIV_D_38", "HVE4_BIODIV_I_39", "HVE4_BIODIV_S_39"];
    return indicToWatch.some((indic) => this.props.indicateurs[indic] !== nextProps.indicateurs[indic]);
  }

  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };
  render() {
    const { indicateurs, computedFields, getValueAssolement } = this.props;
    return (
      <React.Fragment>
        {indicateurs.length > 0 && <h3 className={"my-2"}>Taille des parcelles</h3>}
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            <div className={indicateurs["HVE4_BIODIV_D_37"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_D_37"].libelle}
              {indicateurs["HVE4_BIODIV_D_37"].messageAide != null && (
                <Tooltip
                  title={<span style={{ whiteSpace: "pre-line" }}>{indicateurs["HVE4_BIODIV_D_37"].messageAide}</span>}
                >
                  <IconButton>
                    <FontAwesomeIcon icon="info-circle" />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </Grid>
          <Grid container item xs={4}>
            <DecimalPositif
              error={indicateurs["HVE4_BIODIV_D_37"].valeur.value > indicateurs["HVE4_CULT_D_200"].valeur.value}
              helperText={
                indicateurs["HVE4_BIODIV_D_37"].valeur.value > indicateurs["HVE4_CULT_D_200"].valeur.value
                  ? "La valeur saisie doit être inférieur à la SAU"
                  : ""
              }
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_D_37"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_37"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_37"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_37"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            <div className={indicateurs["HVE4_BIODIV_D_38"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_D_38"].libelle}
            </div>
          </Grid>
          <Grid container item xs={6}>
            <DecimalPositif
              error={indicateurs["HVE4_BIODIV_D_38"].valeur.value > indicateurs["HVE4_CULT_D_200"].valeur.value}
              helperText={
                indicateurs["HVE4_BIODIV_D_38"].valeur.value > indicateurs["HVE4_CULT_D_200"].valeur.value
                  ? "La valeur saisie doit être inférieur à la SAU"
                  : ""
              }
              size={"small"}
              valueAssolement={getValueAssolement("HVE4_BIODIV_D_38") ?? ""}
              indicateur={indicateurs["HVE4_BIODIV_D_38"]}
              displayRules={{ displayAssolement: true }}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_38"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_D_38"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_D_38"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            <div className={indicateurs["HVE4_CULT_D_200"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_CULT_D_200"].libelle}
            </div>
          </Grid>
          <Grid container item xs={4}>
            <DecimalPositif
              size={"small"}
              indicateur={indicateurs["HVE4_CULT_D_200"]}
              key={"indicateur-" + indicateurs["HVE4_CULT_D_200"].idIndicateur}
              displayRules={{ hasRightDisplay: true }}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_CULT_D_200"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_CULT_D_200"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            <div className={indicateurs["HVE4_BIODIV_I_39"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_I_39"].libelle}
            </div>
          </Grid>
          <Grid container item xs={3}>
            <DecimalPositif
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_I_39"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_I_39"].idIndicateur}
              displayRules={{ hasRightDisplay: true }}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_I_39"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_I_39"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
          <Grid item xs={2} className={"bold"}>
            {indicateurs["HVE4_BIODIV_S_39"].libelle}
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={7}></Grid>

          <Grid container item xs={4} className={"total-point"}>
            <DecimalPositif
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_S_39"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_S_39"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_S_39"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_S_39"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(BiodivParc);
