import { Buffer } from "buffer";
import Hashes from "jshashes";
import randomString from "random-string";
import moment from "moment";

export const base64Decode = (b64Encoded) => {
  return new Buffer(b64Encoded, "base64").toString("binary");
};

export const getSha256base64UrlEncode = (str) => {
  return new Hashes.SHA256().b64(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+/g, "");
};

export const getRandomString = (length) => {
  return randomString({ length });
};

export const replaceNullByEmptyString = (obj, keysToReplace) => {
  obj = { ...obj };
  keysToReplace.forEach((key) => {
    if (obj.hasOwnProperty(key)) {
      obj[key] = obj[key] == null ? "" : obj[key];
    }
  });
  return obj;
};

export const replaceEmptyStringByNull = (obj, keysToReplace = []) => {
  obj = { ...obj };
  keysToReplace.forEach((key) => {
    if (obj.hasOwnProperty(key)) {
      obj[key] = obj[key] === "" ? null : obj[key];
    }
  });
  return obj;
};

export const replaceMomentObjectByString = (obj, mask = "YYYY-MM-DD") => {
  return Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      [key]: obj[key]?._isAMomentObject ? obj[key].format(mask) : obj[key],
    }),
    {}
  );
};

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const downloadBlob = async (response) => {
  const filename = response.headers.get("content-disposition").split("filename=")[1];
  const blob = await response.blob();

  const url = window.URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.download = filename;
  link.click();
  window.URL.revokeObjectURL(url);
};

export const getDateFormat = () => {
  return {
    fr: "DD/MM/yyyy",
    en: "MM/DD/yyyy",
  }[moment.locale()];
};

export const round = (value, decimals = 0) => Number(Number(value).toFixed(decimals));
