import { resetExploitations } from "./exploitation";

export const SET_AUTHENTICATION = "SET_AUTHENTICATION";
export const setAuthentication = (authenticationInfos) => ({
  type: SET_AUTHENTICATION,
  payload: authenticationInfos,
});

export const SET_USER_INFOS = "SET_USER_INFOS";
export const setUserInfos = (userInfos) => ({
  type: SET_USER_INFOS,
  payload: userInfos,
});

export const LOGOUT = "LOGOUT";
export const logout = () => (dispatch) => {
  dispatch(resetExploitations());
  dispatch({
    type: LOGOUT,
  });
};
