import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Grid, InputAdornment } from "@material-ui/core";
import { connect } from "react-redux";
import NumeriqueEntier from "../indicateurs/NumeriqueEntier";
import DecimalPositif from "../indicateurs/DecimalPositif";
import Assolement from "../indicateurs/Assolement";
import Percent from "../indicateurs/Percent";
const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});
class FertiSauNonFerti extends React.Component {
  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };
  render() {
    const { indicateurs, getValueAssolement, computedFields } = this.props;
    const indicateurKeys = [
      "HVE4_FERTI_D_50",
      "HVE4_FERTI_D_51",
      "HVE4_FERTI_D_52",
      "HVE4_FERTI_I_50",
      "HVE4_FERTI_S_50",
    ];
    const indicateursToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateurKeys.includes(key)))
    );
    if (indicateursToDisplay.length > 0)
      return (
        <React.Fragment>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <div className={indicateurs["HVE4_FERTI_D_50"].afficherGras ? "mr-3 bold" : "mr-3"}>
                {indicateurs["HVE4_FERTI_D_50"].libelle}
              </div>
            </Grid>
            <Grid item xs={5}>
              <Assolement
                size={"small"}
                getValueAssolement={getValueAssolement}
                indicateur={indicateurs["HVE4_FERTI_D_50"]}
                key={"indicateur-" + indicateurs["HVE4_FERTI_D_50"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_FERTI_D_50"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_FERTI_D_50"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
            <Grid item xs={4}>
              <div>{indicateurs["HVE4_FERTI_D_51"].libelle}</div>
            </Grid>
            <Grid item xs={3}>
              <DecimalPositif
                size={"small"}
                getValueAssolement={getValueAssolement}
                indicateur={indicateurs["HVE4_FERTI_D_51"]}
                key={"indicateur-" + indicateurs["HVE4_FERTI_D_51"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_FERTI_D_51"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_FERTI_D_51"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>

            <Grid item xs={2}>
              <div className={indicateurs["HVE4_FERTI_I_50"].afficherGras ? "mr-3 bold" : "mr-3"}>
                {indicateurs["HVE4_FERTI_I_50"].libelle}
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className={indicateurs["HVE4_FERTI_S_50"].afficherGras ? "mr-3 bold" : "mr-3"}>
                {indicateurs["HVE4_FERTI_S_50"].libelle}
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={indicateurs["HVE4_FERTI_D_52"].afficherGras ? "mr-3 bold" : "mr-3"}>
                {indicateurs["HVE4_FERTI_D_52"].libelle}
              </div>
            </Grid>
            <Grid item xs={3} className={"d-flex"}>
              <DecimalPositif
                size={"small"}
                indicateur={indicateurs["HVE4_FERTI_D_52"]}
                displayRules={{ hasRightDisplay: true }}
                key={"indicateur-" + indicateurs["HVE4_FERTI_D_52"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_FERTI_D_52"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_FERTI_D_52"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
            <Grid item xs={2}>
              <Percent
                size={"small"}
                getValueAssolement={getValueAssolement}
                indicateur={indicateurs["HVE4_FERTI_I_50"]}
                key={"indicateur-" + indicateurs["HVE4_FERTI_I_50"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_FERTI_I_50"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_FERTI_I_50"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
            <Grid item xs={2} className={"total-point"}>
              <NumeriqueEntier
                size={"small"}
                getValueAssolement={getValueAssolement}
                indicateur={indicateurs["HVE4_FERTI_S_50"]}
                key={"indicateur-" + indicateurs["HVE4_FERTI_S_50"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_FERTI_S_50"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_FERTI_S_50"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
          </Grid>
        </React.Fragment>
      );
  }
}
export default compose(withTranslation(), connect(mapStateToProps))(FertiSauNonFerti);
