import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import {
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
import Percent from "../indicateurs/Percent";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class FertiMaterielOptimisant extends React.Component {
  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };
  render() {
    const { indicateurs, computedFields } = this.props;

    const indicateursKeys = [
      "HVE4_FERTI_D_80",
      "HVE4_CULT_D_70",
      "HVE4_FERTI_I_80",
      "HVE4_FERTI_S_80",
      "HVE4_FERTI_D_81",
      "HVE4_FERTI_S_81",
    ];

    let indicateursToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateursKeys.includes(key)))
    );
    let ordering = {};
    for (let i = 0; i < indicateursKeys.length; i++) {
      ordering[indicateursKeys[i]] = i;
    }

    indicateursToDisplay.sort((a, b) => {
      return ordering[a.codeNational] - ordering[b.codeNational];
    });

    if (indicateursToDisplay.length > 0) {
      return (
        <Grid container>
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {indicateursToDisplay.map((indic, i) => {
                      return (
                        <TableCell key={"libelle-" + i}>
                          <div className="mr-3">{indic.libelle}</div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={1}>
                    <TableCell key={1}>
                      <DecimalPositif
                        error={
                          indicateurs["HVE4_CULT_D_70"] != null
                            ? indicateurs["HVE4_FERTI_D_80"].valeur.value > indicateurs["HVE4_CULT_D_70"].valeur.value
                            : false
                        }
                        helperText={
                          indicateurs["HVE4_CULT_D_70"] != null
                            ? indicateurs["HVE4_FERTI_D_80"].valeur.value > indicateurs["HVE4_CULT_D_70"].valeur.value
                              ? "La valeur saisie ne doit pas dépasser la surface en horticulture et pépinière (hors sol et plein champ)"
                              : ""
                            : ""
                        }
                        size={"small"}
                        indicateur={indicateurs["HVE4_FERTI_D_80"]}
                        key={"indicateur-" + indicateurs["HVE4_FERTI_D_80"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_FERTI_D_80"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_FERTI_D_80"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell key={2}>
                      <DecimalPositif
                        size={"small"}
                        indicateur={indicateurs["HVE4_CULT_D_70"]}
                        key={"indicateur-" + indicateurs["HVE4_CULT_D_70"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_CULT_D_70"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_CULT_D_70"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell key={3}>
                      <Percent
                        size={"small"}
                        indicateur={indicateurs["HVE4_FERTI_I_80"]}
                        key={"indicateur-" + indicateurs["HVE4_FERTI_I_80"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_FERTI_I_80"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_FERTI_I_80"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell key={4}>
                      <DecimalPositif
                        size={"small"}
                        indicateur={indicateurs["HVE4_FERTI_S_80"]}
                        key={"indicateur-" + indicateurs["HVE4_FERTI_S_80"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_FERTI_S_80"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_FERTI_S_80"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell key={5}>
                      <DecimalPositif
                        size={"small"}
                        indicateur={indicateurs["HVE4_FERTI_D_81"]}
                        key={"indicateur-" + indicateurs["HVE4_FERTI_D_81"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_FERTI_D_81"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_FERTI_D_81"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell key={5} className={"total-point"}>
                      <DecimalPositif
                        size={"small"}
                        indicateur={indicateurs["HVE4_FERTI_S_81"]}
                        key={"indicateur-" + indicateurs["HVE4_FERTI_S_81"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_FERTI_S_81"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_FERTI_S_81"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      );
    }
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(FertiMaterielOptimisant);
