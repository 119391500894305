import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Grid, InputAdornment } from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
import TexteMultiligne from "../indicateurs/TexteMultiligne";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class BiodivEspecesMenacees extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const indicToWatch = [
      "HVE4_BIODIV_I_63",
      "HVE4_BIODIV_S_63",
      "HVE4_BIODIV_I_64",
      "HVE4_BIODIV_S_64",
      "HVE4_BIODIV_S_600",
      "HVE4_BIODIV_D_65",
    ];
    return indicToWatch.some((indic) => this.props.indicateurs[indic] !== nextProps.indicateurs[indic]);
  }

  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };
  render() {
    const { indicateurs, computedFields } = this.props;

    return (
      <React.Fragment>
        <Grid container>
          <Grid item xs={7} />
          <Grid item xs={4} className={"bold"}>
            Points
          </Grid>
        </Grid>

        <div className={"d-flex align-items-center my-1"}>
          <Grid container item xs={4}>
            <div className={indicateurs["HVE4_BIODIV_I_63"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_I_63"].libelle}
            </div>
          </Grid>
          <Grid container item xs={3}>
            <DecimalPositif
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_I_63"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_I_63"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_I_63"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_I_63"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
          <Grid container item xs={4}>
            <DecimalPositif
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_S_63"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_S_63"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_S_63"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_S_63"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            <div className={indicateurs["HVE4_BIODIV_I_64"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_I_64"].libelle}
            </div>
          </Grid>
          <Grid container item xs={3}>
            <DecimalPositif
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_I_64"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_I_64"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_I_64"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_I_64"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
          <Grid container item xs={4}>
            <DecimalPositif
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_S_64"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_S_64"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_S_64"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_S_64"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}></Grid>
          <Grid container item xs={3}>
            <div className={"d-flex align-items-center bold"}>{indicateurs["HVE4_BIODIV_S_600"].libelle}</div>
          </Grid>
          <Grid container item xs={4} className={"total-point"}>
            <DecimalPositif
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_S_600"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_S_600"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_BIODIV_S_600"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_BIODIV_S_600"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            <div className={indicateurs["HVE4_BIODIV_D_65"].afficherGras ? "mr-3 bold" : "mr-3"}>
              {indicateurs["HVE4_BIODIV_D_65"].libelle}
            </div>
          </Grid>
          <Grid container item xs={4}>
            <TexteMultiligne
              size={"small"}
              indicateur={indicateurs["HVE4_BIODIV_D_65"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_D_65"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              computedFields={computedFields}
            />
          </Grid>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(BiodivEspecesMenacees);
