import { SET_SELECTED_ORGANISME, SET_ORGANISMES } from "../actions/organisme";

// init config state
const organismeInitialState = {
  selected: null,
  list: [],
};

const organisme = (state = organismeInitialState, action) => {
  switch (action.type) {
    case SET_SELECTED_ORGANISME:
      return { ...state, selected: action.payload };
    case SET_ORGANISMES:
      return {
        ...state,
        list: action.payload,
        selected: state.selected == null ? action.payload[0] : state.selected,
      };
    default:
      return state;
  }
};
export default organisme;
