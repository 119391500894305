import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { InputAdornment, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { connect } from "react-redux";
import clsx from "clsx";
import styles from "./sousrubriquestyles.module.scss";
import DecimalPositif from "../indicateurs/DecimalPositif";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class IrrigRecycleEaux extends React.Component {
  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };

  render() {
    const { indicateurs, getValueAssolement, computedFields } = this.props;

    const indicateursKeys = [
      "HVE4_IRRI_D_70",
      "HVE4_IRRI_D_71",
      "HVE4_IRRI_I_70",
      "HVE4_IRRI_I_71",
      "HVE4_IRRI_S_70",
      "HVE4_IRRI_S_71",
      "HVE4_IRRI_S_72",
      "HVE4_IRRI_D_73",
      "HVE4_IRRI_S_74",
    ];

    const indicateursToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateursKeys.includes(key)))
    );

    if (indicateursToDisplay.length > 0) {
      return (
        <React.Fragment>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={clsx(styles.tab_cell_no_borders)} />
                <TableCell className={clsx(styles.tab_cell_no_borders)}>Surfaces en horticulture hors sol</TableCell>
                <TableCell className={clsx(styles.tab_cell_no_borders)}>Part des surfaces concernées</TableCell>
                <TableCell className={clsx(styles.tab_cell_no_borders)}>Nombre de points</TableCell>
                <TableCell colSpan={2} rowSpan={2} className={clsx(styles.tab_cell_no_borders)} />
              </TableRow>
              <TableRow>
                <TableCell className={clsx(styles.tab_cell_no_borders)}>Avec un système de recyclage total</TableCell>
                <TableCell className={clsx(styles.tab_cell_no_borders)}>
                  <DecimalPositif
                    error={
                      indicateurs["HVE4_IRRI_D_70"] != null && indicateurs["HVE4_BIODIV_D_38"] != null
                        ? indicateurs["HVE4_IRRI_D_70"].valeur.value > indicateurs["HVE4_BIODIV_D_38"].valeur.value
                        : false
                    }
                    helperText={
                      indicateurs["HVE4_IRRI_D_70"] != null && indicateurs["HVE4_BIODIV_D_38"] != null
                        ? indicateurs["HVE4_IRRI_D_70"].valeur.value > indicateurs["HVE4_BIODIV_D_38"].valeur.value
                          ? "La valeur saisie doit être inférieur à la surface en cultures annuelles"
                          : ""
                        : ""
                    }
                    size={"small"}
                    getValueAssolement={getValueAssolement}
                    indicateur={indicateurs["HVE4_IRRI_D_70"]}
                    key={"indicateur-" + indicateurs["HVE4_IRRI_D_70"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_IRRI_D_70"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_D_70"].libelleUnite}</InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell className={clsx(styles.tab_cell_no_borders)}>
                  <DecimalPositif
                    size={"small"}
                    getValueAssolement={getValueAssolement}
                    indicateur={indicateurs["HVE4_IRRI_I_70"]}
                    key={"indicateur-" + indicateurs["HVE4_IRRI_I_70"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_IRRI_I_70"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_I_70"].libelleUnite}</InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell className={clsx(styles.tab_cell_no_borders)}>
                  <DecimalPositif
                    size={"small"}
                    getValueAssolement={getValueAssolement}
                    indicateur={indicateurs["HVE4_IRRI_S_70"]}
                    key={"indicateur-" + indicateurs["HVE4_IRRI_S_70"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_IRRI_S_70"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_S_70"].libelleUnite}</InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={clsx(styles.tab_cell_no_borders)}>Avec un système de recyclage partiel</TableCell>
                <TableCell className={clsx(styles.tab_cell_no_borders)}>
                  <DecimalPositif
                    size={"small"}
                    getValueAssolement={getValueAssolement}
                    indicateur={indicateurs["HVE4_IRRI_D_71"]}
                    key={"indicateur-" + indicateurs["HVE4_IRRI_D_71"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_IRRI_D_71"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_D_71"].libelleUnite}</InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell className={clsx(styles.tab_cell_no_borders)}>
                  <DecimalPositif
                    error={indicateurs["HVE4_IRRI_D_71"].valeur.value > indicateurs["HVE4_BIODIV_D_38"].valeur.value}
                    helperText={
                      indicateurs["HVE4_IRRI_D_71"].valeur.value > indicateurs["HVE4_BIODIV_D_38"].valeur.value
                        ? "La valeur saisie doit être inférieur à la surface en cultures annuelles"
                        : ""
                    }
                    size={"small"}
                    getValueAssolement={getValueAssolement}
                    indicateur={indicateurs["HVE4_IRRI_I_71"]}
                    key={"indicateur-" + indicateurs["HVE4_IRRI_I_71"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_IRRI_I_71"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_I_71"].libelleUnite}</InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell className={clsx(styles.tab_cell_no_borders)}>
                  <DecimalPositif
                    size={"small"}
                    getValueAssolement={getValueAssolement}
                    indicateur={indicateurs["HVE4_IRRI_S_71"]}
                    key={"indicateur-" + indicateurs["HVE4_IRRI_S_71"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_IRRI_S_71"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_S_71"].libelleUnite}</InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell className={clsx(styles.tab_cell_no_borders)}>Pondération par rapport à la SAU</TableCell>
                <TableCell className={clsx(styles.tab_cell_no_borders)}>
                  Nombre de points "Recyclage et traitement des eaux d'irrigations"
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} className={clsx(styles.tab_cell_no_borders)} />
                <TableCell className={clsx(styles.tab_cell_no_borders)}>Note intermédiaire</TableCell>
                <TableCell className={clsx(styles.tab_cell_no_borders)}>
                  <DecimalPositif
                    size={"small"}
                    getValueAssolement={getValueAssolement}
                    indicateur={indicateurs["HVE4_IRRI_S_72"]}
                    key={"indicateur-" + indicateurs["HVE4_IRRI_S_72"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_IRRI_S_72"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_S_72"].libelleUnite}</InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell className={clsx(styles.tab_cell_no_borders)}>
                  <DecimalPositif
                    size={"small"}
                    getValueAssolement={getValueAssolement}
                    indicateur={indicateurs["HVE4_IRRI_D_73"]}
                    key={"indicateur-" + indicateurs["HVE4_IRRI_D_73"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_IRRI_D_73"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_D_73"].libelleUnite}</InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell className={(clsx(styles.tab_cell_no_borders), "total-point")}>
                  <DecimalPositif
                    size={"small"}
                    getValueAssolement={getValueAssolement}
                    indicateur={indicateurs["HVE4_IRRI_S_74"]}
                    key={"indicateur-" + indicateurs["HVE4_IRRI_S_74"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_IRRI_S_74"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_S_74"].libelleUnite}</InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </React.Fragment>
      );
    }
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(IrrigRecycleEaux);
