import React from "react";
import NumberInput from "../../../../common/NumberInput";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

class DecimalPositif extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    return this.props.indicateur !== nextProps.indicateur;
  }

  render() {
    const {
      t,
      indicateur,
      changeIndicateur,
      valueInputProps,
      computedFields,
      displayRules = null,
      valueAssolement = null,
    } = this.props;

    const isColumnDisplay =
      displayRules?.calculatedFieldPosition === "column" || displayRules?.displayValeurMP === "column";

    const hasRightDisplay = displayRules?.hasRightDisplay ?? false;

    const valeurCalculee = (
      <NumberInput
        size={this.props.size}
        fullWidth={false}
        disabled
        style={isColumnDisplay ? { maxWidth: "8rem", marginBottom: "1rem" } : { maxWidth: "8rem", marginRight: "2rem" }}
        value={indicateur?.valeurCalculee?.value ?? ""}
        label={t("hve3a.valeur-mesparcelles", "Valeur MesParcelles")}
        InputProps={valueInputProps}
      />
    );

    const valeurAssolement = (
      <NumberInput
        size={this.props.size}
        disabled
        fullWidth={false}
        style={{ maxWidth: "8rem" }}
        label={"Valeur assolement"}
        value={valueAssolement ?? ""}
        InputProps={valueInputProps}
        className={isColumnDisplay ? "mb-1 mr-2" : "mr-2"}
      />
    );

    const valueRendered = (
      <NumberInput
        helperText={this.props.helperText}
        error={this.props.error}
        size={this.props.size}
        fullWidth={false}
        value={indicateur?.valeur.value ?? ""}
        InputLabelProps={{
          style: { fontWeight: indicateur?.afficherGras ? "bold" : "normal" },
        }}
        style={{ width: "8rem" }}
        InputProps={valueInputProps}
        disabled={computedFields?.includes(indicateur?.codeNational) || displayRules?.disabledEdit}
        onChange={(newValeur) => {
          changeIndicateur({ ...indicateur, valeur: { value: newValeur === "" ? null : Number(newValeur) } });
        }}
      />
    );

    const emptyCalculatedValuePlacement = <div style={{ minWidth: "8rem" }} className={"mr-2"}></div>;

    return (
      <React.Fragment>
        {hasRightDisplay &&
          !indicateur?.valeurCalculee &&
          !displayRules?.displayAssolement &&
          emptyCalculatedValuePlacement}
        <div className={isColumnDisplay ? "d-flex flex-column align-items-center" : ""}>
          {!!indicateur?.valeurCalculee && valeurCalculee}
          {!!displayRules?.displayAssolement && valeurAssolement}
          {valueRendered}
        </div>
      </React.Fragment>
    );
  }
}

export default compose(withTranslation())(DecimalPositif);
