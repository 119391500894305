import React from "react";
import Indicator from "../../Indicator";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { InputAdornment, TextField, Typography } from "@material-ui/core";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class BilanStrategiePhyto extends React.Component {
  render() {
    const { indicateur, onChangeIndicator, userRole } = this.props;

    const valueRendered = (
      <React.Fragment>
        <TextField fullWidth={false} disabled value={indicateur.valeur?.value ?? ""} style={{ maxWidth: "4rem" }} />
        <Typography className={"mx-1"} style={{ alignSelf: "center" }}>
          x
        </Typography>
        <TextField
          fullWidth={false}
          inputProps={{
            readOnly: true,
            contentEditable: "false",
          }}
          value={Math.round(Number((indicateur.valeur?.coeff ?? 0) * 100))}
          InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
          disabled={true}
          style={{ maxWidth: "4rem" }}
        />
        {userRole !== "AGRICULTEUR" && (
          <Typography className={"mx-1"} style={{ alignSelf: "center" }}>
            =
          </Typography>
        )}
      </React.Fragment>
    );

    return (
      <Indicator indicateur={indicateur} onChange={onChangeIndicator}>
        {valueRendered}
      </Indicator>
    );
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(BilanStrategiePhyto);
