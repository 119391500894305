import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { CircularProgress } from "@material-ui/core";
import network from "../../actions/external/network";
import { toastr } from "react-redux-toastr";
import BackgroundPaper from "../common/BackgroundPaper";

const mapStateToProps = (state) => ({
  config: state.config,
});

class ConfirmEmail extends React.Component {
  componentDidMount() {
    this.doConfirmRequest();
  }

  doConfirmRequest = async () => {
    const { t } = this.props;

    try {
      const response = await network.fetch(`/api/utilisateurs/confirm-email/${this.props.match.params.confirmKey}`, {
        method: "POST",
        body: this.props.match.params.email,
      });

      if (response.resetPassword) {
        this.props.history.push(`/reset-password/${this.props.match.params.confirmKey}`);
      } else {
        toastr.success(
          "OK",
          t(
            "register.confirm-email.success",
            `Votre adresse email a été validée, vous pouvez désormais vous connecter.`
          )
        );
        this.redirectToLogin();
      }
    } catch (error) {
      toastr.error(t("error", "Erreur"), error.message);
      this.redirectToLogin();
    }
  };

  redirectToLogin = () => {
    setTimeout(() => {
      this.props.history.push("/login");
    }, 1000);
  };

  render() {
    return (
      <BackgroundPaper className="paper">
        <div
          className="height-100vh d-flex justify-center align-items-center p-2"
          style={{ zIndex: 2, position: "relative" }}
        >
          <CircularProgress />
        </div>
      </BackgroundPaper>
    );
  }
}

export default withRouter(connect(mapStateToProps, null)(withTranslation()(ConfirmEmail)));
