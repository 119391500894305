import network from "./external/network";

export const SET_APP_INFO = "SET_APP_INFO";
export const setAppInfo = (appInfo) => ({ type: SET_APP_INFO, payload: appInfo });

export const loadAppInfo = () => async (dispatch) => {
  try {
    const appInfo = await network.fetch(`/api/application/info`);
    dispatch(setAppInfo(appInfo));
  } catch (e) {}
};
