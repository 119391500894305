import React from "react";
import { Chip, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation } from "react-i18next";
import Box from "@material-ui/core/Box";
import styles from "./DragAndDrop.module.scss";
import { withTheme } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import propTypes from "prop-types";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  idExploitation: state.exploitation.selected,
});

class DragAndDrop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dragging: false,
    };
  }

  dragStart = (e, item) => {
    e.dataTransfer.setData("application/json", JSON.stringify(item));
  };

  dragOver = (e) => {
    e.preventDefault();
  };

  dragEnter = (e) => {
    e.preventDefault();
    this.setState({ dragging: true });
  };

  dragLeave = (e) => {
    e.preventDefault();
    this.setState({ dragging: false });
  };

  handleDrop = (e) => {
    const data = e.dataTransfer.getData("application/json");
    const draggableParcelle = JSON.parse(data);
    //Update de la row culture dans ModifAssolement.js
    this.props.moveParcelleToAssolement(draggableParcelle.uuidParcelle);

    this.setState({
      dragging: false,
    });
  };

  render() {
    const { theme, assolement } = this.props;
    const isCVI = this.props.idExploitation.exploitationFamilleCultures?.VITIC?.code === "CVI";
    return (
      <Box
        variant="outlined"
        className={styles.dropzone}
        onDragOver={this.dragOver}
        onDrop={this.handleDrop}
        onDragEnter={this.dragEnter}
        onDragLeave={this.dragLeave}
        style={
          this.state.dragging
            ? { backgroundColor: theme.palette.action.hover }
            : { backgroundColor: theme.palette.type === "light" ? grey["50"] : "#424242" }
        }
      >
        {isCVI && assolement?.typeCulture?.code === "VIGNES"
          ? assolement?.parcelles.map((item) => {
              return (
                <Tooltip title={`n°ilot : ${item.numIlot} - n°parcelle : ${item.numParcelle}`} arrow placement={"top"}>
                  <Chip
                    key={item.uuidParcelle}
                    label={`${item.nom} - ${item.surfaceCvi} ha`}
                    className={styles.chip}
                    icon={<FontAwesomeIcon icon="arrows-alt" />}
                    draggable="true"
                    onDragStart={(e) => {
                      this.dragStart(e, item);
                    }}
                  />
                </Tooltip>
              );
            })
          : assolement?.parcelles.map((item) => {
              return (
                <Tooltip title={`n°ilot : ${item.numIlot} - n°parcelle : ${item.numParcelle}`} arrow placement={"top"}>
                  <Chip
                    key={item.uuidParcelle}
                    label={`${item.nom} - ${item.surface} ha`}
                    className={styles.chip}
                    icon={<FontAwesomeIcon icon="arrows-alt" />}
                    draggable="true"
                    onDragStart={(e) => {
                      this.dragStart(e, item);
                    }}
                  />
                </Tooltip>
              );
            })}
      </Box>
    );
  }
}

DragAndDrop.propTypes = {
  parcelles: propTypes.array.isRequired,
  moveParcelleToAssolement: propTypes.func,
  moveParcelleIsoleeToAssolement: propTypes.func,
};
export default withTheme(connect(mapStateToProps, null)(withTranslation()(DragAndDrop)));
