import React from "react";
import { Field, Form, withFormik } from "formik";
import { TextField } from "formik-material-ui";
import { CircularProgress, IconButton, InputAdornment, Typography, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { checkPassword } from "../common/validators";

const mapStateToProps = (state) => ({
  config: state.config,
});

class PasswordForm extends React.Component {
  handleClickShowPassword = () => {
    const { values, setFieldValue } = this.props;
    setFieldValue("showPassword", !values.showPassword, false);
  };

  render() {
    const { t, values, isSubmitting, isValid } = this.props;

    return (
      <div className="d-flex align-items-center flex-column w-100">
        <Typography variant="h3">
          {t("register.confirm-email.form-title", "Saisissez votre nouveau mot de passe :")}
        </Typography>
        <Form className="d-flex flex-column align-items-center w-100">
          <Field
            component={TextField}
            name="password"
            type={values.showPassword ? "text" : "password"}
            label={t("register.password", "Mot de passe :")}
            helperText={t(
              "register.error.check-password",
              "Le mot de passe doit contenir au moins 8 caractères minimum, dont un chiffre, une lettre majuscule, une lettre minuscule et un caractère spécial"
            )}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={this.handleClickShowPassword} tabIndex="-1">
                    <FontAwesomeIcon icon={values.showPassword ? "eye" : "eye-slash"} size="sm" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="outlined"
            className="mt-2"
            required
          />
          <Button
            disabled={!isValid || isSubmitting}
            color="primary"
            variant="contained"
            type="submit"
            fullWidth
            className="mt-2"
          >
            {isSubmitting ? <CircularProgress size={24} /> : t("register.validate", "Valider")}
          </Button>
        </Form>
      </div>
    );
  }
}

const validate = (values, props) => {
  const { t } = props;
  const errors = {};

  if (!checkPassword(values.password)) {
    errors.password = t(
      "register.error.check-password",
      "8 caractères minimum, dont un chiffre, une lettre et un caractère spécial parmi # ? ! @ $ % ^ & * - . : = > < / ; |"
    );
  }

  return errors;
};

const mapPropsToValues = () => ({
  password: "",
  showPassword: false,
});

const handleSubmit = async (values, { props, setSubmitting }) => {
  await props.onSubmit(values);
  setSubmitting(false);
};

export default connect(mapStateToProps)(
  withTranslation()(
    withFormik({
      mapPropsToValues: mapPropsToValues,
      validate: validate,
      handleSubmit: handleSubmit,
    })(PasswordForm)
  )
);
