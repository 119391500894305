import network from "./external/network";

export const SET_SELECTED_ORGANISME = "SET_SELECTED_ORGANISME";
export const setSelectedOrganisme = (organisme) => ({ type: SET_SELECTED_ORGANISME, payload: organisme });

export const SET_ORGANISMES = "SET_ORGANISMES";
export const setOrganismes = (organismes) => ({ type: SET_ORGANISMES, payload: organismes });

export const loadOrganismes = () => async (dispatch) => {
  try {
    const organismes = await network.fetch(`/api/organismes`);
    dispatch(setOrganismes(organismes));
  } catch (e) {}
};
