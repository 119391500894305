import { withTranslation } from "react-i18next";
import React, { Fragment } from "react";
import {
  Box,
  Button,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { grey } from "@material-ui/core/colors";
import Moment from "react-moment";
import { withTheme } from "@material-ui/core/styles";
import { procedureStateCodes } from "../../../common/codes";

const mapStateToProps = (state) => ({
  config: state.config,
});

class ResultRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  toggleExpanded = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    const { t, exploitation, selectExploitation } = this.props;

    const certifiables = exploitation.demarches.filter((e) => e.etatDossier === "CERTIFIABLE").length,
      validateds = exploitation.demarches.filter((e) => e.etatDossier === "VALIDE").length,
      ongoingInputs = exploitation.demarches.filter((e) => e.etatDossier === "SAISIE_EN_COURS").length,
      waitForChecks = exploitation.demarches.filter((e) => e.etatDossier === "ATTENTE_VERIFICATION").length,
      { expanded } = this.state,
      shouldExpand = exploitation.demarches.length !== 0;
    return (
      <Fragment>
        <TableRow
          hover={shouldExpand}
          className="select-row"
          onClick={shouldExpand ? this.toggleExpanded : () => {}}
          style={{ cursor: shouldExpand ? "pointer" : "default" }}
        >
          <TableCell scope="row">
            {exploitation.demarches.length !== 0 && (
              <FontAwesomeIcon icon={expanded ? "chevron-up" : "chevron-down"} className="mr-1" />
            )}
            {exploitation.raisonSociale}
          </TableCell>
          <TableCell align="left">{exploitation.pacage}</TableCell>
          <TableCell align="left">{exploitation.siret}</TableCell>
          <TableCell align="left">{exploitation.adresse + " " + exploitation.adresse2}</TableCell>
          <TableCell align="left">{exploitation.nomCommune}</TableCell>
          <TableCell align="left">
            <Tooltip title={t("procedures.procedure-state.validated", "Validées")} arrow placement="left">
              <span className="mx-02">
                <FontAwesomeIcon
                  color={validateds > 0 ? this.props.theme.palette.success.main : grey[500]}
                  icon="award"
                  style={{ fontSize: 16 }}
                />
                <span className="ml-02">{validateds}&nbsp;</span>
              </span>
            </Tooltip>
            <Tooltip title={t("procedures.audit-result.certifiable", "Certifiables")} arrow placement="left">
              <span className="mx-02">
                <FontAwesomeIcon
                  color={certifiables > 0 ? this.props.theme.palette.success.main : grey[500]}
                  icon="award"
                  style={{ fontSize: 16 }}
                />
                <span className="ml-02">{certifiables}&nbsp;</span>
              </span>
            </Tooltip>
            <Tooltip title={t("procedures.audit-result.ongoing-input", "En cours de saisie")} arrow placement="left">
              <span className="mx-02">
                <FontAwesomeIcon
                  color={ongoingInputs > 0 ? this.props.theme.palette.primary.main : grey[500]}
                  icon="clipboard-list"
                  style={{ fontSize: 16 }}
                />
                <span className="ml-02">{ongoingInputs}&nbsp;</span>
              </span>
            </Tooltip>
            <Tooltip
              title={t("procedures.audit-result.wait-check", "En attente de vérification")}
              arrow
              placement="left"
            >
              <span className="mx-02">
                <FontAwesomeIcon
                  color={waitForChecks > 0 ? this.props.theme.palette.primary.main : grey[500]}
                  icon="hourglass-half"
                  style={{ fontSize: 15 }}
                />
                <span className="ml-02">{waitForChecks}&nbsp;</span>
              </span>
            </Tooltip>
          </TableCell>
          <TableCell align="left">
            <Button variant="contained" color="primary" onClick={() => selectExploitation(exploitation)}>
              <span>{t("change-exploitation.table-actions.select-exploitation", "Sélectionner")}</span>
              <FontAwesomeIcon className="ml-1" icon="chevron-circle-right" />
            </Button>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ padding: 0 }} colSpan={7}>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Box>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h5">{t("procedure.term.plural", "Démarches")}</Typography>
                      </TableCell>
                      <TableCell>{t("procedure.procedure-state.name", "Etat du dossier")}</TableCell>
                      <TableCell>{t("procedure.date-entree", "Date d'entrée")}</TableCell>
                      <TableCell>{t("procedure.date-audit", "Date d'audit")}</TableCell>
                      <TableCell>{t("procedure.date-certif", "Date de certification")}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {exploitation.demarches.map((demarche) => {
                      let state;
                      switch (demarche.etatDossier) {
                        case procedureStateCodes.SAISIE_EN_COURS:
                          state = t("procedure.procedure-state.ongoing-input", "En cours de saisie");
                          break;
                        case procedureStateCodes.ATTENTE_VERIFICATION:
                          state = t("procedures.audit-result.wait-check", "En attente de vérification");
                          break;
                        case procedureStateCodes.VERIFICATION_EN_COURS:
                          state = t("procedure.procedure-state.ongoing-check", "Vérification en cours");
                          break;
                        case procedureStateCodes.ATTENTE_CORRECTION:
                          state = t("procedure.procedure-state.wait-correct", "En attente de correction");
                          break;
                        case procedureStateCodes.VALIDE:
                          state = t("procedure.procedure-state.validated", "Validé");
                          break;
                        case procedureStateCodes.CERTIFIABLE:
                          state = t("procedure.procedure-state.certifiable", "Certifiable");
                          break;
                        case procedureStateCodes.NON_CERTIFIABLE:
                          state = t("procedure.procedure-state.non-certifiable", "Non certifiable");
                          break;
                        default:
                          state = "inconnu";
                          break;
                      }
                      return (
                        <TableRow key={demarche.idDemarche}>
                          <TableCell>
                            {demarche.libelle} ({demarche.idMillesime})
                          </TableCell>
                          <TableCell>{state}</TableCell>
                          <TableCell>
                            {!!demarche.dateEntree && <Moment date={demarche.dateEntree} format="L" />}
                          </TableCell>
                          <TableCell>
                            {!!demarche.dateAudit && <Moment date={demarche.dateAudit} format="L" />}
                          </TableCell>
                          <TableCell>
                            {!!demarche.dateCertification && <Moment date={demarche.dateCertification} format="L" />}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  }
}

export default withTheme(connect(mapStateToProps)(withTranslation()(ResultRow)));
