import React from "react";
import { Box, Typography } from "@material-ui/core";
import { withTranslation } from "react-i18next";

class Home extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <Box className="ml-2 w-100">
        <Typography variant="h3" className="mb-2">
          {t("home.title", "Accueil")}
        </Typography>
      </Box>
    );
  }
}

export default withTranslation()(Home);
