import React from "react";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Box, Button, CircularProgress, Divider, Tooltip, Typography } from "@material-ui/core";
import network from "../../../../actions/external/network";
import { toastr } from "react-redux-toastr";
import { connect } from "react-redux";
import { downloadBlob } from "../../../../scripts/utils";
import PrintButton from "./PrintButton";

const mapStateToProps = (state) => ({
  idExploitation: state.exploitation.selected.idExploitation,
  idMillesime: state.millesime.selected.idMillesime,
});

class Impressions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      demarches: [],
    };
  }

  componentDidMount() {
    this.loadDemarches();
  }

  loadDemarches = async () => {
    const { idExploitation, idMillesime } = this.props;
    try {
      const demarches = await network.fetch(`/api/editions/demarches/${idExploitation}?millesime=${idMillesime}`);
      this.setState({ demarches, loading: false });
    } catch (error) {}
  };

  printEdition = async (name, format) => {
    const params = new URLSearchParams({
      id_exploitation: this.props.idExploitation,
      id_millesime: this.props.idMillesime,
      format,
    });

    try {
      const response = await network.fetch(`/api/editions/${name}?${params}`);
      await downloadBlob(response);
    } catch (error) {
      toastr.error("Erreur", error.message);
    }
  };

  printCommentaires = async (name, format) => {
    const params = new URLSearchParams({
      id_exploitation: this.props.idExploitation,
      id_millesime: this.props.idMillesime,
      format,
    });

    try {
      const response = await network.fetch(`/api/editions/${name}/commentaires?${params}`);
      await downloadBlob(response);
    } catch (error) {
      toastr.error("Erreur", error.message);
    }
  };

  printArchive = async (name) => {
    const params = new URLSearchParams({
      id_exploitation: this.props.idExploitation,
      id_millesime: this.props.idMillesime,
    });

    try {
      const response = await network.fetch(`/api/editions/${name}/documents?${params}`);
      await downloadBlob(response);
    } catch (error) {
      toastr.error("Erreur", error.message);
    }
  };

  render() {
    const { t } = this.props;
    const { demarches, loading } = this.state;

    return loading ? (
      <Box className={"h-100"}>
        <CircularProgress />
      </Box>
    ) : (
      <Box className="page d-flex flex-column">
        {demarches.map((demarche, i) => {
          let PJButton = (
            <Button
              className="ml-1"
              disabled={demarche.nbPiecesJointes === 0}
              onClick={() => this.printArchive(demarche.code)}
              color={"primary"}
              variant={"contained"}
            >
              {t("attachment.term.plural", "Pièces jointes")}
            </Button>
          );
          if (demarche.nbPiecesJointes === 0) {
            PJButton = (
              <Tooltip title={t("attachment.not-found", "Pas de pièces jointes chargées sur cette démarche")}>
                <span>{PJButton}</span>
              </Tooltip>
            );
          }
          return (
            <Box key={i}>
              <Typography variant="h4">{demarche.libelle}</Typography>
              <div className={"d-flex align-items-center mt-1"}>
                {demarche.code !== "HVE4" && (
                  <PrintButton
                    text={t("report.term.singular", "Compte-rendu")}
                    onPrint={(format) => this.printEdition(demarche.code, format)}
                    pdf
                    xls
                  />
                )}
                {demarche.code === "HVE4" && (
                  <React.Fragment>
                    <PrintButton
                      text={t("report.term.singular", "Compte-rendu")}
                      onPrint={(format) => this.printEdition(demarche.code, format)}
                      xlsx
                    />
                    <div className="ml-1">
                      <PrintButton
                        text={"Commentaires"}
                        onPrint={(format) => this.printCommentaires(demarche.code, format)}
                        pdf
                      />
                    </div>
                  </React.Fragment>
                )}
                {PJButton}
              </div>

              {demarches.length - 1 !== i && <Divider className="my-2" />}
            </Box>
          );
        })}
      </Box>
    );
  }
}

export default connect(mapStateToProps, null)(withRouter(withTranslation()(Impressions)));
