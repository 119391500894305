import { SET_PARTNERS, SET_SELECTED_PARTNER } from "../actions/partners";

const partnersInitialState = {
  partners: [],
  selectedPartner: null,
};

const partners = (state = partnersInitialState, action) => {
  switch (action.type) {
    case SET_PARTNERS:
      return { ...state, partners: [...action.payload] };
    case SET_SELECTED_PARTNER:
      return { ...state, selectedPartner: action.payload };
    default:
      return state;
  }
};

export default partners;
