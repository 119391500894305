import React from "react";
import Menu from "../Main/Menu/Menu";
import { Paper, Typography, Box } from "@material-ui/core";
import SideMenu from "./SideMenu";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const mapStateToProps = (state) => ({
  app: state.app,
});

class Admin extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <Paper className="paper" square>
        <Menu isAdmin={true} />
        <Box className="page">
          <Typography variant="h1" className="pb-2">
            {t("admin.title", "Admin")}
          </Typography>
          <Box style={{ display: "flex" }}>
            <SideMenu />
            {this.props.children}
          </Box>
        </Box>
      </Paper>
    );
  }
}

export default withRouter(connect(mapStateToProps)(withTranslation()(Admin)));
