import { roles } from "../components/common/codes";
import network from "./external/network";
import { toastr } from "react-redux-toastr";
import i18next from "i18next";

export const SET_CONFIG = "SET_CONFIG";
export const setConfig = (config) => ({ type: SET_CONFIG, payload: config });

export const SET_DARKMODE = "SET_DARKMODE";
export const setDarkMode = (isDarkmode) => ({ type: SET_DARKMODE, payload: isDarkmode });

export const loadConfig = () => async (dispatch, getState) => {
  try {
    let config =
      [roles.CONSEILLER, roles.ADMIN_ORGANISME].includes(getState().auth.role) &&
      getState().auth.idOrganismePrincipal != null
        ? await network.fetch(`/api/organismes/${getState().auth.idOrganismePrincipal}/config`)
        : await network.fetch(`/api/config/`);

    dispatch(setConfig(config));
  } catch (error) {
    // cas où le jeton est périmé
    if (error.status === 401) {
      const config = await network.fetch(`/api/config/`);
      dispatch(setConfig(config));
    } else {
      toastr.error(i18next.t("error-load-config", "Erreur lors du chargement de la config"));
    }
  }
};
