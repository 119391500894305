import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { InputAdornment, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
import Percent from "../indicateurs/Percent";
import clsx from "clsx";
import styles from "./sousrubriquestyles.module.scss";
const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});
class FertiRecyclEauxIrrig extends React.Component {
  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };
  render() {
    const { indicateurs, computedFields } = this.props;
    const indicateursKeys = [
      "HVE4_FERTI_D_90",
      "HVE4_FERTI_D_91",
      "HVE4_FERTI_I_90",
      "HVE4_FERTI_I_91",
      "HVE4_FERTI_S_90",
      "HVE4_FERTI_S_91",
      "HVE4_FERTI_S_900",
      "HVE4_FERTI_D_92",
      "HVE4_FERTI_S_920",
    ];
    const indicateursToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateursKeys.includes(key)))
    );
    if (indicateursToDisplay.length > 0) {
      return (
        <React.Fragment>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell className={clsx(styles.tab_cell_no_borders)} />
                <TableCell className={clsx(styles.tab_cell_no_borders)}>
                  <div>Surface concernée</div>
                </TableCell>
                <TableCell className={clsx(styles.tab_cell_no_borders)}>
                  <div>Part de la surface concernée</div>
                </TableCell>
                <TableCell className={clsx(styles.tab_cell_no_borders)}>
                  <div>Points intermédiaires</div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={clsx(styles.tab_cell_no_borders)}>
                  <div>Système de recyclage total</div>
                </TableCell>
                <TableCell className={clsx(styles.tab_cell_no_borders)}>
                  <DecimalPositif
                    error={
                      (indicateurs["HVE4_FERTI_D_90"]?.valeur.value ?? 0) +
                        (indicateurs["HVE4_FERTI_D_91"]?.valeur.value ?? 0) >
                      (indicateurs["HVE4_CULT_D_69"]?.valeur.value ?? 0)
                    }
                    helperText={
                      (indicateurs["HVE4_FERTI_D_90"]?.valeur.value ?? 0) +
                        (indicateurs["HVE4_FERTI_D_91"]?.valeur.value ?? 0) >
                      (indicateurs["HVE4_CULT_D_69"]?.valeur.value ?? 0)
                        ? "La surface saisie doit être inférieure à la surface horticulture hors sol"
                        : ""
                    }
                    size={"small"}
                    indicateur={indicateurs["HVE4_FERTI_D_90"]}
                    key={"indicateur-" + indicateurs["HVE4_FERTI_D_90"]?.idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_FERTI_D_90"]?.libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_FERTI_D_90"]?.libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell className={clsx(styles.tab_cell_no_borders)}>
                  <Percent
                    size={"small"}
                    indicateur={indicateurs["HVE4_FERTI_I_90"]}
                    key={"indicateur-" + indicateurs["HVE4_FERTI_I_90"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_FERTI_I_90"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_FERTI_I_90"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell className={clsx(styles.tab_cell_no_borders)}>
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_FERTI_S_90"]}
                    key={"indicateur-" + indicateurs["HVE4_FERTI_S_90"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_FERTI_S_90"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_FERTI_S_90"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell colSpan={2} className={clsx(styles.tab_cell_no_borders)} />
              </TableRow>
              <TableRow>
                <TableCell className={clsx(styles.tab_cell_no_borders)}>
                  <div>Système de recyclage partiel</div>
                </TableCell>
                <TableCell className={clsx(styles.tab_cell_no_borders)}>
                  <DecimalPositif
                    error={
                      (indicateurs["HVE4_FERTI_D_90"].valeur.value ?? 0) +
                        (indicateurs["HVE4_FERTI_D_91"].valeur.value ?? 0) >
                      (indicateurs["HVE4_CULT_D_69"].valeur.value ?? 0)
                    }
                    helperText={
                      (indicateurs["HVE4_FERTI_D_90"].valeur.value ?? 0) +
                        (indicateurs["HVE4_FERTI_D_91"].valeur.value ?? 0) >
                      (indicateurs["HVE4_CULT_D_69"].valeur.value ?? 0)
                        ? "La surface saisie doit être inférieure à la surface horticulture hors sol"
                        : ""
                    }
                    size={"small"}
                    indicateur={indicateurs["HVE4_FERTI_D_91"]}
                    key={"indicateur-" + indicateurs["HVE4_FERTI_D_91"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_FERTI_D_91"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_FERTI_D_91"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell className={clsx(styles.tab_cell_no_borders)}>
                  <Percent
                    size={"small"}
                    indicateur={indicateurs["HVE4_FERTI_I_91"]}
                    key={"indicateur-" + indicateurs["HVE4_FERTI_I_91"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_FERTI_I_91"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_FERTI_I_91"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell className={clsx(styles.tab_cell_no_borders)}>
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_FERTI_S_91"]}
                    key={"indicateur-" + indicateurs["HVE4_FERTI_S_91"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_FERTI_S_91"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_FERTI_S_91"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell className={clsx(styles.tab_cell_no_borders)}>
                  <div>Part des surfaces horticulture et pépinière hors sol sur la SAU</div>
                </TableCell>
                <TableCell className={clsx(styles.tab_cell_no_borders)}>
                  <div>Nombre de points "Recyclage et traitement des eaux d'irrigations"</div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={3} className={clsx(styles.tab_cell_no_borders)} />
                <TableCell className={clsx(styles.tab_cell_no_borders)}>
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_FERTI_S_900"]}
                    key={"indicateur-" + indicateurs["HVE4_FERTI_S_900"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_FERTI_S_900"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_FERTI_S_900"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell className={clsx(styles.tab_cell_no_borders)}>
                  <Percent
                    size={"small"}
                    indicateur={indicateurs["HVE4_FERTI_D_92"]}
                    key={"indicateur-" + indicateurs["HVE4_FERTI_D_92"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_FERTI_D_92"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_FERTI_D_92"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
                <TableCell className={(clsx(styles.tab_cell_no_borders), "total-point")}>
                  <DecimalPositif
                    size={"small"}
                    indicateur={indicateurs["HVE4_FERTI_S_920"]}
                    key={"indicateur-" + indicateurs["HVE4_FERTI_S_920"].idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indicateurs["HVE4_FERTI_S_920"].libelleUnite != null && {
                        endAdornment: (
                          <InputAdornment position={"end"}>
                            {indicateurs["HVE4_FERTI_S_920"].libelleUnite}
                          </InputAdornment>
                        ),
                      }
                    }
                    computedFields={computedFields}
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </React.Fragment>
      );
    }
  }
}
export default compose(withTranslation(), connect(mapStateToProps))(FertiRecyclEauxIrrig);
