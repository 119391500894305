import React from "react";
import { Chip, MenuItem, TextField, Tooltip } from "@material-ui/core";
import Indicator from "../../Indicator";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import styles from "../style.module.scss";

class ListeMethodeAlternative extends React.Component {
  render() {
    const { indicateur, onChangeIndicator, sousRubriqueIndex, libelleEnglobantIndex, changeIndicateur } = this.props;

    const materielMethodeAlternative = [
      {
        code: "LUTTE_MECANIQUE",
        libelle:
          "Matériel de lutte mécanique contre les adventices : bineuse, système spécifique de binage sur le rang, système de guidage automatisé pour bineuses, désherbineuse, herse étrille, pailleuse et ramasseuses ou enrouleuses pour films organiques biodégradables, matériel spécifique de binage inter-rang (houe rotative,…)",
      },
      {
        code: "LUTTE_THERMIQUE",
        libelle: "Matériel de lutte thermique (échauffement létal), type bineuse à gaz, traitement vapeur",
      },
      {
        code: "LUTTE_BIOLOGIQUE",
        libelle:
          "Matériel de lutte contre les prédateurs ou permettant une lutte biologique :filets tissés anti-insectes, filets insects proof et matériel associé",
      },
      {
        code: "SPECIFIQUE",
        libelle:
          "Matériel spécifique pour l’implantation de couverts herbacés « entre rang » et de couverts de zone de compensation écologique",
      },
      {
        code: "ECLAIRCISSAGE",
        libelle:
          "Matériels d’éclaircissage mécanique (matériel de broyage spécifique et adapté, retrait de résidus,…) pour éviter les contaminations par les prédateurs",
      },
      {
        code: "EPAMPREUSE",
        libelle: "Epampreuse",
      },
      {
        code: "ENTRETIEN_MECANIQUE",
        libelle:
          "Matériel spécifique pour l’entretien par voie mécanique des couverts, del’enherbement inter-rangs et des zones de compensation écologique",
      },
      {
        code: "PULVERISATION",
        libelle: "Système de pulvérisation mixte avec traitement sur le rang et travailmécanique de l’inter-culture",
      },
      {
        code: "COUVERTURE_SOLS",
        libelle:
          "Matériel de couverture des sols et des conteneurs pour les cultures ornementales : paillage, mulchs, BRF (Bois Raméal Fragmenté), collerettes, disques,...",
      },
    ];

    const valueRendered = (
      <TextField
        fullWidth={false}
        select
        SelectProps={{
          multiple: true,
          renderValue: (selected) => (
            <div className="d-flex flex-wrap">
              {selected.map((value) => {
                const libelle = materielMethodeAlternative.find((materiel) => materiel.code === value)?.libelle;
                return (
                  <Tooltip key={value} title={libelle}>
                    <Chip key={value} label={libelle} className={styles.wrapperChip} />
                  </Tooltip>
                );
              })}
            </div>
          ),
        }}
        style={{ width: "25em" }}
        value={indicateur.valeur.value || []}
        onChange={(event) =>
          changeIndicateur(
            { ...indicateur, valeur: { value: event.target.value.filter((i) => i !== undefined) } },
            sousRubriqueIndex,
            libelleEnglobantIndex
          )
        }
      >
        {materielMethodeAlternative.map((materiel) => (
          <MenuItem
            key={materiel.code}
            value={materiel.code}
            style={{
              maxWidth: "80em",
            }}
          >
            <div
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "pre",
              }}
            >
              {materiel.libelle}
            </div>
          </MenuItem>
        ))}
      </TextField>
    );

    return (
      <Indicator indicateur={indicateur} onChange={onChangeIndicator}>
        {valueRendered}
      </Indicator>
    );
  }
}

export default compose(withTranslation())(ListeMethodeAlternative);
