import React from "react";
import { Field, Form, withFormik } from "formik";
import { TextField } from "formik-material-ui";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Box, Button, CircularProgress, IconButton, InputAdornment, Tooltip } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { checkPassword, isEmail } from "../../common/validators";
import { withTheme } from "@material-ui/core/styles";

const mapStateToProps = (state) => ({
  auth: state.auth,
});

class AccountForm extends React.Component {
  handleClickShowPassword = () => {
    const { values, setFieldValue } = this.props;
    setFieldValue("showPassword", !values.showPassword, false);
  };

  handleClickChangePassword = () => {
    const { values, setValues } = this.props;
    setValues({
      ...values,
      changePassword: !values.changePassword,
      password: !values.changePassword ? values.password : "",
    });
  };

  onSubmit = async (e) => {
    e.preventDefault();
    this.props.handleSubmit(e);
  };

  render() {
    const { t, values, isSubmitting, isValid } = this.props;

    return (
      <Form onSubmit={this.onSubmit} className="d-flex flex-column align-items-start">
        <Field
          component={TextField}
          name="email"
          type="email"
          label={t("register.email", "Email :")}
          helperText={
            this.props.auth.nextEmail != null && `Email en attente de confirmation : ${this.props.auth.nextEmail}`
          }
          variant="outlined"
          className="mb-1 mt-2"
          required
          fullWidth
        />
        {this.props.auth.login != null && (
          <Box className="d-flex align-items-start">
            <Field
              component={TextField}
              name="password"
              type={values.showPassword ? "text" : "password"}
              label={t("register.password", "Mot de passe :")}
              helperText={t(
                "register.error.check-password",
                "Le mot de passe doit contenir au moins 8 caractères minimum, dont un chiffre, une lettre majuscule, une lettre minuscule et un caractère spécial"
              )}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={this.handleClickShowPassword} tabIndex="-1">
                      <FontAwesomeIcon icon={values.showPassword ? "eye" : "eye-slash"} size="sm" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              disabled={!values.changePassword}
              variant="outlined"
              className="mb-1"
              fullWidth
            />
            <Tooltip title={values.changePassword ? t("confirm.cancel", "Annuler") : t("edit", "Modifier")}>
              <IconButton onClick={this.handleClickChangePassword}>
                <FontAwesomeIcon icon={values.changePassword ? "times" : "pencil-alt"} />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        <Field
          component={TextField}
          name="prenom"
          type="text"
          label={t("register.prenom", "Prénom :")}
          variant="outlined"
          className="mb-1"
          required
          fullWidth
        />
        <Field
          component={TextField}
          name="nom"
          type="text"
          label={t("register.nom", "Nom :")}
          variant="outlined"
          className="mb-1"
          required
          fullWidth
        />
        <Button
          disabled={!isValid || isSubmitting}
          color="primary"
          variant="contained"
          type="submit"
          className="mt-1"
          fullWidth
        >
          {isSubmitting ? <CircularProgress size={24} /> : t("register.validate", "Valider")}
        </Button>
      </Form>
    );
  }
}

const validate = (values, props) => {
  const { t } = props;
  const errors = {};

  if (!values.email) {
    errors.email = t("register.error.required", "Champ obligatoire");
  } else if (!isEmail(values.email)) {
    errors.email = t("register.error.invalid-email", "L'adresse email est invalide");
  }

  if (!values.prenom) {
    errors.prenom = t("register.error.required", "Champ obligatoire");
  }

  if (!values.nom) {
    errors.nom = t("register.error.required", "Champ obligatoire");
  }

  if (values.changePassword && !checkPassword(values.password)) {
    errors.password = t(
      "register.error.check-password",
      "Le mot de passe doit contenir au moins 8 caractères minimum, dont un chiffre, une lettre majuscule, une lettre minuscule et un caractère spécial"
    );
  }

  return errors;
};

const mapPropsToValues = (props) => ({
  email: props.auth.email,
  password: "",
  prenom: props.auth.prenom,
  nom: props.auth.nom,
  showPassword: false,
  changePassword: false,
});

const handleSubmit = async (values, { props, setSubmitting, setValues }) => {
  const { showPassword, changePassword, ...data } = values;
  const { email, prenom, nom } = await props.onSubmit(data);
  setValues({
    email,
    password: "",
    prenom,
    nom,
    showPassword: false,
    changePassword: false,
  });
  setSubmitting(false);
};

export default connect(mapStateToProps)(
  withRouter(
    withTheme(
      withTranslation()(
        withFormik({
          mapPropsToValues: mapPropsToValues,
          validate: validate,
          handleSubmit: handleSubmit,
        })(AccountForm)
      )
    )
  )
);
