import { TableHead, TableRow, TableCell, Checkbox, TableSortLabel } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { withStyles } from "@material-ui/styles";

class AdminTableHead extends React.Component {
  static columnsTypes = {
    ACTION: "ACTION",
  };

  handleRequestSort = (event, property) => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount } = this.props;

    return (
      <TableHead>
        <TableRow>
          {this.props.multipleSelection && (
            <TableCell padding="checkbox">
              <Checkbox
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                onChange={onSelectAllClick}
                inputProps={{ "aria-label": "select all desserts" }}
                color="primary"
              />
            </TableCell>
          )}
          {this.props.columns.map((column) => (
            <TableCell key={column.id} align="left" sortDirection={orderBy === column.id ? order : false}>
              {column.type !== AdminTableHead.columnsTypes.ACTION &&
                (column.ordering !== false ? (
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={(event) => this.handleRequestSort(event, column.id)}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <span className={classes.visuallyHidden}>
                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                      </span>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  column.label
                ))}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
}

AdminTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
  multipleSelection: PropTypes.bool.isRequired,
};

const StyledAdminTableHead = withStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}))(AdminTableHead);

export default StyledAdminTableHead;
