import React from "react";
import { Box, Typography, Paper, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { withTranslation } from "react-i18next";

class Error404 extends React.Component {
  render() {
    const { t } = this.props;

    return (
      <Paper className="paper">
        <Box className="d-flex flex-column justify-center align-items-center flex-fill">
          <Typography variant="h6" className="mb-3">
            {t("error404.title", "Erreur 404")}
          </Typography>
          <Button variant="contained" color="primary" component={Link} to="/">
            {t("error404.home", "Retour à l'accueil")}
          </Button>
        </Box>
      </Paper>
    );
  }
}

export default withTranslation()(Error404);
