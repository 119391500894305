import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Grid, IconButton, Tooltip } from "@material-ui/core";
import { connect } from "react-redux";
import Boolean from "../indicateurs/Boolean";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DecimalPositif from "../indicateurs/DecimalPositif";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class BiodivQualite extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const indicToWatch = [
      "HVE4_BIODIV_I_66",
      "HVE4_BIODIV_S_66",
      "HVE4_BIODIV_I_67",
      "HVE4_BIODIV_S_67",
      "HVE4_BIODIV_S_676",
    ];
    return indicToWatch.some((indic) => this.props.indicateurs[indic] !== nextProps.indicateurs[indic]);
  }

  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };
  render() {
    const { indicateurs, computedFields } = this.props;
    return (
      <React.Fragment>
        {<h3 className={"my-2"}>Options choisies</h3>}
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            <div className={"mr-3"}>{indicateurs["HVE4_BIODIV_I_66"].libelle}</div>
          </Grid>
          <Grid container item xs={4}>
            <Boolean
              indicateur={indicateurs["HVE4_BIODIV_I_66"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_I_66"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mb-1"}>
          <Grid container item xs={4}>
            <div className={"mr-3"}>
              {indicateurs["HVE4_BIODIV_I_67"].libelle}
              {indicateurs["HVE4_BIODIV_I_67"].messageAide != null && (
                <Tooltip
                  title={<span style={{ whiteSpace: "pre-line" }}>{indicateurs["HVE4_BIODIV_I_67"].messageAide}</span>}
                >
                  <IconButton
                    href="https://ecobiosoil.univ-rennes1.fr/page/protocole-participatif-test-beche-vers-de-terre"
                    target="_blank"
                  >
                    <FontAwesomeIcon icon="link" />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </Grid>
          <Grid container item xs={4}>
            <Boolean
              indicateur={indicateurs["HVE4_BIODIV_I_67"]}
              key={"indicateur-" + indicateurs["HVE4_BIODIV_I_67"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex flex-column justify-end mb-1"}>
          <div className={"d-flex justify-end mb-1"}>
            <Grid item xs={8}>
              <div className={"mr-3 bold"}>{indicateurs["HVE4_BIODIV_S_676"].libelle}</div>
            </Grid>
          </div>
          <div className={"d-flex justify-end mb-1 total-point"}>
            <Grid item xs={8}>
              <DecimalPositif
                size={"small"}
                indicateur={indicateurs["HVE4_BIODIV_S_676"]}
                key={"indicateur-" + indicateurs["HVE4_BIODIV_S_676"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                computedFields={computedFields}
              />
            </Grid>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(BiodivQualite);
