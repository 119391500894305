import React from "react";
import {
  Box,
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { grey } from "@material-ui/core/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, withRouter } from "react-router-dom";
import Moment from "react-moment";
import clsx from "clsx";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import withTheme from "@material-ui/styles/withTheme";
import { procedureStateCodes, roles } from "../../../../common/codes";
import styles from "./style.module.scss";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class Menu extends React.Component {
  render() {
    const {
      userRole,
      theme,
      t,
      demarche,
      demarcheOrganisme,
      exploitationDemarcheInfo,
      typesRubriques,
      typeRubriqueSelected,
      rubriqueSelected,
      showResults,
      onShowResults,
      setSousRubriqueSelected,
    } = this.props;
    let n = 0;
    const arrayRubriques = typesRubriques.flatMap((typeRubrique) => typeRubrique.rubriques);
    return (
      <Grid item xs={3} className="d-flex flex-column">
        <Card className="mb-2" style={{ height: "25rem" }}>
          <CardHeader
            title={
              <div className="d-flex justify-between align-items-start">
                <Typography variant={"h3"}>Gestion de la certification</Typography>
                <Tooltip title={t("edit", "Modifier")}>
                  <IconButton component={Link} to={`/demarches/${demarche.code}/infos`}>
                    <FontAwesomeIcon icon="pencil-alt" />
                  </IconButton>
                </Tooltip>
              </div>
            }
          />
          <CardContent>
            {demarcheOrganisme && (
              <Box>
                <img
                  src={demarcheOrganisme.logoBase64}
                  alt={demarcheOrganisme.libelle}
                  title={demarcheOrganisme.libelle}
                  style={{
                    marginBottom: "1rem",
                    maxHeight: "3rem",
                  }}
                />
              </Box>
            )}
            <Typography>
              Date d'entrée dans la certification :{" "}
              {exploitationDemarcheInfo.exploitationDemarcheMillesime.dateEntree && (
                <Moment date={exploitationDemarcheInfo.exploitationDemarcheMillesime.dateEntree} format="L" />
              )}
            </Typography>
            <Typography>
              Période auditée du{" "}
              {exploitationDemarcheInfo.exploitationDemarcheMillesime.periodeAuditeeDebut && (
                <Moment date={exploitationDemarcheInfo.exploitationDemarcheMillesime.periodeAuditeeDebut} format="L" />
              )}{" "}
              au{" "}
              {exploitationDemarcheInfo.exploitationDemarcheMillesime.periodeAuditeeFin && (
                <Moment date={exploitationDemarcheInfo.exploitationDemarcheMillesime.periodeAuditeeFin} format="L" />
              )}
            </Typography>
            <Typography>
              Date de l'audit :{" "}
              {exploitationDemarcheInfo.exploitationDemarcheMillesime.dateAudit && (
                <Moment date={exploitationDemarcheInfo.exploitationDemarcheMillesime.dateAudit} format="L" />
              )}
            </Typography>
            <Typography>
              Date de certification :{" "}
              {exploitationDemarcheInfo.exploitationDemarcheMillesime.dateCertification && (
                <Moment date={exploitationDemarcheInfo.exploitationDemarcheMillesime.dateCertification} format="L" />
              )}
            </Typography>
          </CardContent>
        </Card>
        <Card
          style={{
            position: "sticky",
            top: 0,
            overflowY: "auto",
          }}
        >
          <CardHeader title={<Typography variant={"h3"}>Rubriques</Typography>} />
          <div className="mt-1">
            {typesRubriques.map((typeRubrique, typeRubriqueIndex) => (
              <React.Fragment key={typeRubriqueIndex}>
                <div
                  className={"py-1 pl-1"}
                  style={{
                    backgroundColor: theme.palette.type === "dark" ? "#3b3b3b" : grey["200"],
                  }}
                >
                  <Typography variant={"h4"}>{typeRubrique.libelle}</Typography>
                </div>
                <div className={"d-flex flex-column align-items-stretch"}>
                  {typeRubrique.rubriques.map((rubrique, rubriqueIndex) => (
                    <React.Fragment key={rubriqueIndex}>
                      <ButtonBase
                        onClick={() => setSousRubriqueSelected(typeRubriqueIndex, rubriqueIndex)}
                        className={clsx(
                          "p-1",
                          "navigationButton",
                          "d-flex",
                          "justify-between",
                          typeRubriqueSelected === typeRubriqueIndex &&
                            rubriqueSelected === rubriqueIndex &&
                            styles.bold
                        )}
                        style={{
                          backgroundColor:
                            typeRubriqueSelected === typeRubriqueIndex && rubriqueSelected === rubriqueIndex
                              ? theme.palette.primary.main
                              : theme.palette.background.paper,
                        }}
                      >
                        <Typography>{rubrique.libelle}</Typography>
                        <Typography>
                          Page {(n += 1)} / {arrayRubriques.length}
                        </Typography>
                      </ButtonBase>
                      <Divider />
                    </React.Fragment>
                  ))}
                </div>
              </React.Fragment>
            ))}
            <div className={"d-flex flex-column align-items-stretch"}>
              <ButtonBase
                onClick={onShowResults}
                className={"p-1 navigationButton"}
                style={
                  showResults
                    ? {
                        backgroundColor: theme.palette.primary.main,
                        justifyContent: "start",
                      }
                    : userRole === roles.AGRICULTEUR &&
                      [
                        procedureStateCodes.SAISIE_EN_COURS,
                        procedureStateCodes.VERIFICATION_EN_COURS,
                        procedureStateCodes.ATTENTE_VERIFICATION,
                        procedureStateCodes,
                      ].includes(exploitationDemarcheInfo.exploitationDemarcheMillesime.etatDossier)
                    ? {
                        backgroundColor: theme.palette.action.disabled,
                        justifyContent: "start",
                      }
                    : {
                        backgroundColor: theme.palette.type === "dark" ? "#3b3b3b" : grey["200"],
                        justifyContent: "start",
                      }
                }
                disabled={
                  userRole === roles.AGRICULTEUR &&
                  [
                    procedureStateCodes.SAISIE_EN_COURS,
                    procedureStateCodes.VERIFICATION_EN_COURS,
                    procedureStateCodes.ATTENTE_VERIFICATION,
                  ].includes(exploitationDemarcheInfo.exploitationDemarcheMillesime.etatDossier)
                }
              >
                <Typography variant={"h4"} align={"left"} style={{ fontWeight: "bold" }}>
                  Résultats
                </Typography>
              </ButtonBase>
            </div>
          </div>
        </Card>
      </Grid>
    );
  }
}

export default compose(withTranslation(), connect(mapStateToProps), withRouter, withTheme)(Menu);
