import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Box, Tooltip, IconButton, Typography } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withTranslation } from "react-i18next";
import AdminTable from ".././Table/AdminTable";
import network from "../../../actions/external/network";
import { toastr } from "react-redux-toastr";
import { roles } from "../../common/codes";
import AdminTableHead from ".././Table/AdminTableHead";
import { OrganismeModal } from "./Organisme";
import confirm from "../../common/confirm";

const mapStateToProps = (state) => ({
  auth: state.auth,
});

class Organismes extends React.Component {
  config = {
    columns: [
      {
        id: "nom",
        label: "Nom",
      },
      {
        id: "adresse",
        label: "Adresse",
      },
      {
        id: "siteInternet",
        label: "Site web",
      },
      {
        id: "actions",
        label: "",
        type: AdminTableHead.columnsTypes.ACTION,
      },
    ],
  };
  constructor(props) {
    super(props);

    this.tableRef = React.createRef();

    this.state = {
      data: [],
      openModalOrganisme: false,
      idOrganismeModal: null,
    };
  }
  loadData = async ({ paramsUrl }) => {
    const { t } = this.props;
    try {
      const response = await network.fetch(`/api/organismes?${paramsUrl}`);
      this.setState({ data: response });
    } catch (error) {
      toastr.error(t("error", "Erreur"), t("admin.farms.load-error", "Erreur lors du chargement des données"));
    }
  };

  onSelect = (id) => {
    this.setState({
      openModalOrganisme: true,
      idOrganismeModal: id,
    });
  };

  onCreate = () => {
    this.setState({
      openModalOrganisme: true,
      idOrganismeModal: null,
    });
  };

  handleCloseModal = () => {
    this.setState({
      openModalOrganisme: false,
      idOrganismeModal: null,
    });
  };

  afterSubmitFromModal = () => {
    this.tableRef.current.loadData();
    this.handleCloseModal();
  };

  onDelete = async (ids, event) => {
    event.stopPropagation();
    const { t } = this.props;

    if (
      !(await confirm(t("admin.organizations.delete-confirm", "Êtes-vous sûr de vouloir supprimer cet organisme ?")))
    ) {
      return;
    }

    try {
      await Promise.all(
        ids.map((id) =>
          network.fetch(`/api/organismes/${id}`, {
            method: "DELETE",
          })
        )
      );

      this.tableRef.current.loadData();
      toastr.success("OK", t("admin.users-delete-success", "Suppression effectuée"));
    } catch (error) {
      toastr.error(
        t("error", "Erreur"),
        t("admin.users-delete-error", "Une erreur est survenue lors de la suppression")
      );
    }
  };

  getRows = () => {
    const { t } = this.props;

    return this.state.data.map((aData) => {
      const canEditOrRemove = this.props.auth.role === roles.SUPERADMIN;

      return {
        ...aData,
        actions: (
          <React.Fragment>
            <Tooltip title={t("admin.select", "sélectionner")}>
              <IconButton onClick={() => this.onSelect(aData.idOrganisme)}>
                <FontAwesomeIcon icon="pencil-alt" />
              </IconButton>
            </Tooltip>
            {canEditOrRemove && (
              <Tooltip title={t("admin.delete", "supprimer")}>
                <IconButton onClick={(event) => this.onDelete([aData.idOrganisme], event)}>
                  <FontAwesomeIcon icon="trash-alt" />
                </IconButton>
              </Tooltip>
            )}
          </React.Fragment>
        ),
      };
    });
  };

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <Box className="ml-2 w-100">
          <Typography variant="h3" className="mb-2">
            {t("admin.organizations.title", "Organismes")}
          </Typography>
          <AdminTable
            idColumn="idOrganisme"
            columns={this.config.columns}
            rows={this.getRows()}
            loadData={this.loadData}
            onCreate={this.onCreate}
            ref={this.tableRef}
            showPagination={false}
            canCreate={this.props.auth.role === roles.SUPERADMIN}
          />
        </Box>
        <OrganismeModal
          showModal={this.state.openModalOrganisme}
          onCloseModal={this.handleCloseModal}
          idOrganisme={this.state.idOrganismeModal}
          afterSubmit={this.afterSubmitFromModal}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(connect(mapStateToProps)(withTranslation()(Organismes)));
