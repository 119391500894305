import { SET_CODE_VERIFIER_AND_STATE, SET_PARTNER_INFOS } from "../actions/login";

const loginInitialState = {
  state: null,
  codeVerifier: null,
  partnerInfos: null,
};

const auth = (state = loginInitialState, action) => {
  switch (action.type) {
    case SET_PARTNER_INFOS:
      return {
        ...state,
        partnerInfos: action.partnerInfos,
      };
    case SET_CODE_VERIFIER_AND_STATE:
      return {
        ...state,
        state: action.state,
        codeVerifier: action.codeVerifier,
      };
    default:
      return state;
  }
};

export default auth;
