import React from "react";
import { Chip, ListSubheader, MenuItem, TextField, Tooltip } from "@material-ui/core";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import styles from "../../style.module.scss";
import network from "../../../../../actions/external/network";
import { toastr } from "react-redux-toastr";

class ListeConditionApplication extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      listeConditionApplication: [],
      loading: true,
    };
  }
  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const { t, name } = this.props;
    try {
      const [listeConditionApplication] = await Promise.all([network.fetch("/api/demarches/listeConditionAppliTrait")]);

      this.setState({
        listeConditionApplication:
          name === "HVE4_PHYTO_D_70"
            ? listeConditionApplication?.filter((el) => el.codeRubriqueCondition === "COND_RUB_1")
            : listeConditionApplication?.filter((el) => el.codeRubriqueCondition !== "COND_RUB_1"),
        loading: false,
      });
    } catch (error) {
      toastr.error(t("error", "Erreur"), t("admin.users.load-error", "Erreur lors du chargement des données"));
    }
  };
  render() {
    const { indicateur, sousRubriqueIndex, libelleEnglobantIndex, changeIndicateur } = this.props;
    const { listeConditionApplication } = this.state;

    const valueRendered = (
      <TextField
        size={"small"}
        fullWidth={false}
        select
        SelectProps={{
          MenuProps: {
            PaperProps: {
              style: {
                maxHeight: 500,
                maxWidth: 800,
              },
            },
          },
          multiple: true,
          renderValue: (selected) => (
            <div className="d-flex flex-wrap">
              {selected.map((value) => {
                const libelle = listeConditionApplication
                  .flatMap((item) => item.conditionApplication)
                  .find((item) => item.codeCondition === value)?.libelle;
                return (
                  <Tooltip title={libelle ?? ""} key={value}>
                    <Chip key={value} label={libelle} className={styles.wrapperChip} />
                  </Tooltip>
                );
              })}
            </div>
          ),
        }}
        style={{ width: "25em" }}
        value={indicateur.valeur.value || []}
        onChange={(event) =>
          changeIndicateur(
            { ...indicateur, valeur: { value: event.target.value.filter((i) => i !== undefined) } },
            sousRubriqueIndex,
            libelleEnglobantIndex
          )
        }
      >
        {listeConditionApplication
          .flatMap((item) => [
            {
              type: "head",
              title: item.libelleRubriqueConditionApplication,
            },
            ...item.conditionApplication.map((conditionApplication) => ({
              type: "item",
              conditionApplication,
            })),
          ])
          .map((elem, i) =>
            elem.type === "head" ? (
              <ListSubheader
                key={i}
                style={{ backgroundColor: "#f5f5f5", fontWeight: "bold", color: "rgba(0,0,0,0.87)" }}
              >
                {elem.title}
              </ListSubheader>
            ) : (
              <MenuItem key={i} value={elem.conditionApplication.codeCondition}>
                {elem.conditionApplication.libelle}
              </MenuItem>
            )
          )}
      </TextField>
    );

    return <React.Fragment>{valueRendered}</React.Fragment>;
  }
}

export default compose(withTranslation())(ListeConditionApplication);
