import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField as MUITextField,
  Typography,
} from "@material-ui/core";
import { Field, Form, withFormik } from "formik";
import React from "react";
import { withTranslation } from "react-i18next";
import { CheckboxWithLabel, Switch, TextField } from "formik-material-ui";
import CitySearch from "../../common/CitySearch";
import PropTypes from "prop-types";
import network from "../../../actions/external/network";
import { setSelectedExploitation } from "../../../actions/exploitation";
import { connect } from "react-redux";
import { isEmail } from "../../common/validators";
import { toastr } from "react-redux-toastr";
import { replaceEmptyStringByNull, replaceNullByEmptyString } from "../../../scripts/utils";
import { Alert, AlertTitle } from "@material-ui/lab";
import "./ModifExploitationForm.scss";

const mapStateToProps = (state) => ({
  exploitationSelected: state.exploitation.selected,
  organisme: state.organisme.selected,
  app: state.app,
});

const mapDispatchToProps = (dispatch) => ({
  updateExploitation: (newExploitation) => dispatch(setSelectedExploitation(newExploitation)),
});

class ModifExploitationForm extends React.Component {
  setPreviousInfos = async () => {
    const { setFieldValue, familleCultures, expInfosMillesimePrecedent, idExploitation, idMillesime } = this.props;
    Object.entries(expInfosMillesimePrecedent).forEach(([key, value]) => {
      setFieldValue(key, value);
    });
    const [expActivites, expFamilleCultures] = await Promise.all([
      network.fetch(`/api/exploitations/${idExploitation}/activities?millesime=${idMillesime - 1}`),
      network.fetch(`/api/exploitations/${idExploitation}/familles-cultures?millesime=${idMillesime - 1}`),
    ]);
    setFieldValue("idActivitePrincipale", expActivites.principal.idActivite, true);
    setFieldValue("idActiviteSecondaire", expActivites.secondaire.idActivite, true);
    familleCultures.forEach((fc) => setFieldValue(`exploitationFamilleCultures.${fc.codeNational}.checked`, false));
    expFamilleCultures.forEach((expFamilleCulture) => {
      setFieldValue(`exploitationFamilleCultures.${expFamilleCulture.codeNational}.checked`, true);
      if (expFamilleCulture.codeNational === "VITIC") {
        setFieldValue(
          `exploitationFamilleCultures.${expFamilleCulture.codeNational}.code`,
          expFamilleCulture.estCvi ? "CVI" : "SAU"
        );
      }
    });
  };

  componentDidMount = async () => {
    const { values } = this.props;
    this.fetchEntiteParentes(values.commune);
  };

  fetchEntiteParentes = async (commune) => {
    const { setFieldValue } = this.props;
    try {
      if (commune != null) {
        const [entiteDepartement, entiteRegion] = await Promise.all([
          network.fetch(`/api/entites/commune?idCommune=${commune.idcommune}`),
          network.fetch(`/api/entites/parente?idEntite=${commune.identite}`),
        ]);
        setFieldValue("departement", entiteDepartement.libelleentite, true);
        setFieldValue("region", entiteRegion.libelleentite, true);
      }
    } catch (e) {}
  };

  renderInformationsGenerales = () => {
    const {
      t,
      validateForm,
      setFieldValue,
      values,
      errors,
      touched,
      setFieldTouched,
      expInfosMillesimePrecedent,
      idMillesime,
      bassinViti,
    } = this.props;
    const changeCity = async ({ value }) => {
      setFieldTouched("commune", true, false);
      setFieldValue("commune", value, true);
      await this.fetchEntiteParentes(value);
      validateForm();
    };
    const changeBassinViti = (event) => {
      setFieldValue("idBassinViti", event.target.value, true);
      validateForm();
    };
    return (
      <div>
        <span className="align-self-start d-flex justify-between align-items-center mb-2">
          <div className="d-flex align-items-center">
            <Typography variant="h3" component="h2">
              {t("modif-exploitation.infos", "Informations générales")}
            </Typography>
            <Typography variant="body2" className="ml-1" style={{ fontSize: "smaller" }}>
              <i>informations inter-millésimes</i>
            </Typography>
          </div>
          {Object.keys(expInfosMillesimePrecedent).length > 0 && (
            <Button variant={"text"} onClick={this.setPreviousInfos}>
              Reprendre les données de {idMillesime - 1}
            </Button>
          )}
        </span>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <Field
              component={TextField}
              label={t("modif-exploitation.company-name", "Raison sociale")}
              name="raisonSociale"
              required
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Field
              component={TextField}
              label={t("modif-exploitation.siret-number", "Numéro SIRET")}
              name="siret"
              maxLength={14}
              required
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Field
              component={TextField}
              label={t("modif-exploitation.user-last-name", "Nom de l'exploitant")}
              name="nomExploitant"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Field
              component={TextField}
              label={t("modif-exploitation.user-first-name", "Prénom de l'exploitant")}
              name="prenomExploitant"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Field
              component={TextField}
              label={t("modif-exploitation.pacage", "N° Pacage")}
              name="pacage"
              maxLength={9}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Field component={TextField} label={t("modif-exploitation.address", "Adresse")} name="adresse" required />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Field component={TextField} label={t("modif-exploitation.address2", "Adresse2")} name="adresse2" />
          </Grid>
          <Grid item xs={12} lg={6}>
            <CitySearch
              label={t("modif-exploitation.city", "Commune")}
              name="commune"
              onChange={(...params) => changeCity(...params)}
              value={values.commune ?? ""}
              error={touched["commune"] && !!errors["commune"]}
              touched={touched["commune"]}
              helperText={touched["commune"] ? errors["commune"] : ""}
              required
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Field
              component={TextField}
              label={t("modif-exploitation.adresseMail", "Email")}
              name="adresseMail"
              required
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Field
              component={TextField}
              label={t("modif-exploitation.numeroPortable", "Numéro de téléphone portable")}
              name="numeroPortable"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Field
              component={TextField}
              label={t("modif-exploitation.siteInternet", "Site Internet de l'exploitation")}
              name="siteInternet"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Field
              component={TextField}
              inputProps={{ pattern: "[0-9]*" }}
              label={t("modif-exploitation.anneeCreation", "Année de création")}
              name="anneeCreation"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Field
              component={TextField}
              label={t("modif-exploitation.statutJuridique", "Statut juridique")}
              name="statutJuridique"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Field
              type="checkbox"
              component={CheckboxWithLabel}
              name="autorisationReferencement"
              color="primary"
              Label={{
                label: t(
                  "modif-exploitation.autorisationReferencement",
                  "Autorisation de référencement dans l'annuaire"
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Field
              inputProps={{ pattern: "[0-9]*" }}
              component={TextField}
              label={t("modif-exploitation.cvi", "Numéro CVI")}
              name="cvi"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <MUITextField
              fullWidth
              select={true}
              variant="outlined"
              value={values.idBassinViti ?? ""}
              name="idBassinViti"
              onChange={changeBassinViti}
              label={t("modif-exploitation.idBassinViti", "Bassin Viticole")}
            >
              {bassinViti !== undefined &&
                bassinViti.map((b) => (
                  <MenuItem key={b.idBassinViti} value={b.idBassinViti}>
                    {b.libelle}
                  </MenuItem>
                ))}
            </MUITextField>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Field
              disabled
              component={TextField}
              label={t("modif-exploitation.departement", "Département")}
              name="departement"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Field disabled component={TextField} label={t("modif-exploitation.region", "Région")} name="region" />
          </Grid>
          <Grid item xs={6} lg={2}>
            <FormControlLabel
              control={<Field component={Switch} color="primary" name="venteDirecte" type="checkbox" />}
              label={t("modif-exploitation.activity-direct-selling", "Vente directe")}
              labelPlacement="start"
            />
          </Grid>
        </Grid>
      </div>
    );
  };

  changeActivitePrincipale = (event) => {
    const { setFieldValue, validateForm, values } = this.props;
    switch (event.target.name) {
      case "idActiviteVegetalePrincipale": {
        if (event.target.value === values.idActiviteVegetaleSecondaire)
          setFieldValue("idActiviteVegetaleSecondaire", null, true);
        break;
      }
      case "idActiviteAnimalePrincipale": {
        if (event.target.value === values.idActiviteAnimaleSecondaire)
          setFieldValue("idActiviteAnimaleSecondaire", null, true);
        break;
      }
      default: {
        break;
      }
    }
    setFieldValue(
      "idsActivitesAutres",
      values.idsActivitesAutres.filter((i) => i !== event.target.value),
      true
    );
    setFieldValue(event.target.name, event.target.value, true);
    validateForm();
  };

  changeActiviteSecondaire = (event) => {
    const { setFieldValue, validateForm, values } = this.props;
    setFieldValue(
      "idsActivitesAutres",
      values.idsActivitesAutres.filter((i) => i !== event.target.value),
      false
    );
    setFieldValue(event.target.name, event.target.value, true);
    validateForm();
  };

  changeOtex = (event) => {
    const { setFieldValue, validateForm } = this.props;
    setFieldValue(event.target.name, event.target.value, true);
    validateForm();
  };

  renderActivitesAnimales = () => {
    const { t, values, activites, touched, errors, setFieldTouched } = this.props;
    const activitesAnimales = activites.filter((a) => a.typeActivite === "ANIMALE");
    const activitesAnimalesSecondaires = activitesAnimales.filter(
      (a) => a.idActivite !== values.idActiviteAnimalePrincipale
    );
    const activitesAnimalesAutres = activitesAnimalesSecondaires.filter(
      (a) => a.idActivite !== values.idActiviteAnimaleSecondaire
    );

    const OTEX = [
      {
        libelle: "Autre",
        code: "Autre",
      },
      {
        libelle: "4500",
        code: "4500",
      },
      {
        libelle: "4600",
        code: "4600",
      },
      {
        libelle: "4700",
        code: "4700",
      },
      {
        libelle: "4810",
        code: "4810",
      },
      {
        libelle: "4820",
        code: "4820",
      },
      {
        libelle: "4830",
        code: "4830",
      },
      {
        libelle: "4840",
        code: "4840",
      },
      {
        libelle: "8320",
        code: "8320",
      },
      {
        libelle: "8330",
        code: "8330",
      },
      {
        libelle: "8340",
        code: "8340",
      },
      {
        libelle: "8420",
        code: "8420",
      },
    ];

    return (
      <React.Fragment>
        <Typography variant="h3" component="h2" className="mb-2 align-self-start">
          {t("modif-exploitation.animalActivities", "Gestion des activités animales")}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={values.idActiviteAnimalePrincipale || values.idActiviteAnimaleSecondaire ? 3 : 4}>
            <MUITextField
              fullWidth
              select={true}
              variant="outlined"
              label={t("modif-exploitation.activity-primary", "Activité principale")}
              SelectProps={{
                onOpen: () => {
                  setFieldTouched("idActiviteAnimalePrincipale");
                },
              }}
              name={"idActiviteAnimalePrincipale"}
              value={values.idActiviteAnimalePrincipale}
              onChange={this.changeActivitePrincipale}
              error={!!errors.idActiviteAnimalePrincipale && touched.idActiviteAnimalePrincipale}
              helperText={touched.idActiviteAnimalePrincipale && errors.idActiviteAnimalePrincipale}
            >
              <MenuItem key={null} value={null}>
                <i>Aucune</i>
              </MenuItem>
              {activitesAnimales.map((a) => (
                <MenuItem key={a.idActivite} value={a.idActivite}>
                  {a.libelle}
                </MenuItem>
              ))}
            </MUITextField>
          </Grid>
          <Grid item xs={12} lg={values.idActiviteAnimalePrincipale || values.idActiviteAnimaleSecondaire ? 3 : 4}>
            <Field
              component={TextField}
              select
              variant="outlined"
              fullWidth
              label={"Activité secondaire"}
              name="idActiviteAnimaleSecondaire"
              onChange={this.changeActiviteSecondaire}
            >
              <MenuItem key={null} value={null}>
                <i>Aucune</i>
              </MenuItem>
              {activitesAnimalesSecondaires.map((a) => (
                <MenuItem key={a.idActivite} value={a.idActivite}>
                  {a.libelle}
                </MenuItem>
              ))}
            </Field>
          </Grid>
          <Grid item xs={12} lg={values.idActiviteAnimalePrincipale || values.idActiviteAnimaleSecondaire ? 3 : 4}>
            <Field
              component={TextField}
              select
              SelectProps={{ multiple: true }}
              variant="outlined"
              fullWidth
              label={t("modif-exploitation.activity-other", "Autres activités")}
              name="idsActivitesAutres"
            >
              {activitesAnimalesAutres.map((a) => (
                <MenuItem key={a.idActivite} value={a.idActivite}>
                  {a.libelle}
                </MenuItem>
              ))}
            </Field>
          </Grid>
          {(values.idActiviteAnimalePrincipale || values.idActiviteAnimaleSecondaire) && (
            <Grid item xs={12} lg={3}>
              <Field
                component={TextField}
                select
                variant="outlined"
                fullWidth
                label={"OTEX"}
                name="otex"
                onChange={this.changeOtex}
              >
                {OTEX.map((a) => (
                  <MenuItem key={a.code} value={a.code}>
                    {a.libelle}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          )}
          <Grid item xs={12} lg={6}>
            <Field
              component={TextField}
              multiline
              rows={3}
              label={t(
                "modif-exploitation.activity-engagement",
                "Engagement dans un autre mode de valorisation officielle"
              )}
              name="engagementValorisation"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Field
              component={TextField}
              multiline
              rows={3}
              label={t("modif-exploitation.activity-tracking", "Traçabilité / Source des données")}
              name="tracabilite"
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  renderActivitesVegetales = () => {
    const { t, values, activites, touched, errors, setFieldTouched } = this.props;
    const activitesVegetales = activites.filter((a) => a.typeActivite !== "ANIMALE");
    const activitesVegetalesSecondaires = activitesVegetales.filter(
      (a) => a.idActivite !== values.idActiviteVegetalePrincipale
    );
    const activitesVegetalesAutres = activitesVegetalesSecondaires.filter(
      (a) => a.idActivite !== values.idActiviteVegetaleSecondaire
    );
    return (
      <React.Fragment>
        <Typography variant="h3" component="h2" className="mb-2 align-self-start">
          {t("modif-exploitation.vegetalActivities", "Gestion des activité végétales")}
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={4}>
            <MUITextField
              required
              fullWidth
              select={true}
              variant="outlined"
              label={t("modif-exploitation.activity-primary", "Activité principale")}
              SelectProps={{
                onOpen: () => {
                  setFieldTouched("idActiviteVegetalePrincipale");
                },
              }}
              name={"idActiviteVegetalePrincipale"}
              value={values.idActiviteVegetalePrincipale}
              onChange={this.changeActivitePrincipale}
              error={!!errors.idActiviteVegetalePrincipale && touched.idActiviteVegetalePrincipale}
              helperText={touched.idActiviteVegetalePrincipale && errors.idActiviteVegetalePrincipale}
            >
              <MenuItem key={null} value={null}>
                <i>Aucune</i>
              </MenuItem>
              {activitesVegetales.map((a) => (
                <MenuItem key={a.idActivite} value={a.idActivite}>
                  {a.libelle}
                </MenuItem>
              ))}
            </MUITextField>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Field
              component={TextField}
              select
              variant="outlined"
              fullWidth
              label={t("modif-exploitation.activity-secondary", "Activités secondaires")}
              name="idActiviteVegetaleSecondaire"
              onChange={this.changeActiviteSecondaire}
            >
              <MenuItem key={null} value={null}>
                <i>Aucune</i>
              </MenuItem>
              {activitesVegetalesSecondaires.map((a) => (
                <MenuItem key={a.idActivite} value={a.idActivite}>
                  {a.libelle}
                </MenuItem>
              ))}
            </Field>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Field
              component={TextField}
              select
              SelectProps={{ multiple: true }}
              variant="outlined"
              fullWidth
              label={t("modif-exploitation.activity-other", "Autres activités")}
              name="idsActivitesAutres"
            >
              {activitesVegetalesAutres.map((a) => (
                <MenuItem key={a.idActivite} value={a.idActivite}>
                  {a.libelle}
                </MenuItem>
              ))}
            </Field>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Field
              component={TextField}
              multiline
              rows={3}
              label={t(
                "modif-exploitation.activity-engagement",
                "Engagement dans un autre mode de valorisation officielle"
              )}
              name="engagementValorisationVegetale"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Field
              component={TextField}
              multiline
              rows={3}
              label={t("modif-exploitation.activity-tracking", "Traçabilité / Source des données")}
              name="tracabiliteVegetale"
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  renderCultures = () => {
    const { t, values, familleCultures } = this.props;
    return (
      <React.Fragment>
        <Typography variant="h3" component="h2" className="mb-2 align-self-start">
          {t("modif-exploitation.cultures", "Personnalisation des indicateurs")}
        </Typography>
        <Grid container spacing={2} className="d-flex justify-between align-items-start">
          {familleCultures.map((f) => (
            <Grid item xs={6} lg={3} key={f.idFamilleCulture} className="d-flex flex-column">
              <Field
                type="checkbox"
                component={CheckboxWithLabel}
                name={`exploitationFamilleCultures.${f.codeNational}.checked`}
                color="primary"
                Label={{ label: f.libelle }}
              />
              {f.codeNational === "VITIC" && values.exploitationFamilleCultures.VITIC?.checked && (
                <Field
                  component={TextField}
                  select={true}
                  fullWidth={false}
                  label={t("modif-exploitation.init-surface", "Surface initialisée avec")}
                  name="exploitationFamilleCultures.VITIC.code"
                  className="mt-1"
                  required
                >
                  {[
                    {
                      libelle: "la SAU",
                      code: "SAU",
                    },
                    {
                      libelle: "la surface CVI",
                      code: "CVI",
                    },
                  ].map((c) => (
                    <MenuItem key={c.code} value={c.code}>
                      {c.libelle}
                    </MenuItem>
                  ))}
                </Field>
              )}
            </Grid>
          ))}
          <Grid item xs={6} lg={2}>
            <FormControlLabel
              control={<Field component={Switch} color="primary" name="irrigation" type="checkbox" />}
              label="Irrigation"
              labelPlacement="start"
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  renderComment = () => {
    const { t } = this.props;
    return (
      <Grid item xs={12} lg={12}>
        <Typography variant="h3" component="h2" className="mb-2 align-self-start">
          {t("modif-exploitation.comment", "Commentaire général")}
        </Typography>
        <Field
          component={TextField}
          multiline
          rows={6}
          name="commentaire"
          label={t("modif-exploitation.comment", "Commentaire général")}
        />
      </Grid>
    );
  };

  renderSubmit = () => {
    const { t, isSubmitting, isValid, isValidating, onCancel } = this.props;
    return (
      <span className="align-self-center">
        <Box className="my-2">
          <Button
            color="primary"
            variant="contained"
            className="mr-1"
            type="submit"
            disabled={isValidating || isSubmitting || !isValid}
          >
            {isValidating || isSubmitting ? (
              <CircularProgress size={24} />
            ) : (
              t("modif-exploitation.validate", "Valider")
            )}
          </Button>
          <Button type="button" variant="contained" onClick={onCancel} disabled={isSubmitting}>
            {t("modif-exploitation.cancel", "Annuler")}
          </Button>
          {!isValid && (
            <Alert severity="error" className="mt-1">
              <AlertTitle>
                <strong>{t("form-missing-data.title", "Impossible de valider")}</strong>
              </AlertTitle>
              {t(
                "form-missing-data.message",
                "Des données sont manquantes ou erronnées lors de la saisie du formulaire."
              )}
            </Alert>
          )}
        </Box>
      </span>
    );
  };

  render() {
    return (
      <Form>
        {this.renderInformationsGenerales()}
        <Divider className="my-4" />
        {this.renderActivitesAnimales()}
        <Divider className="my-4" />
        {this.renderActivitesVegetales()}
        <Divider className="my-4" />
        {this.renderCultures()}
        <Divider className="my-4" />
        {this.renderComment()}
        {this.renderSubmit()}
      </Form>
    );
  }
}

ModifExploitationForm.propTypes = {
  idExploitation: PropTypes.number,
  idMillesime: PropTypes.number.isRequired,
  form: PropTypes.shape({
    siret: PropTypes.string,
    raisonSociale: PropTypes.string,
    pacage: PropTypes.string,
    adresse: PropTypes.string,
    adresse2: PropTypes.string,
    commune: PropTypes.shape({
      idcommune: PropTypes.string,
      nom: PropTypes.string,
      codepostal: PropTypes.string,
    }),
    adresseMail: PropTypes.string,
    numeroPortable: PropTypes.string,
    venteDirecte: PropTypes.bool,
    irrigation: PropTypes.bool,
    engagementValorisation: PropTypes.string,
    tracabilite: PropTypes.string,
    commentaire: PropTypes.string,
    idActiviteAnimalePrincipale: PropTypes.number,
    idActiviteVegetalePrincipale: PropTypes.number,
    idActivitePrincipale: PropTypes.array,
    idActiviteAnimaleSecondaire: PropTypes.number,
    idActiviteVegetaleSecondaire: PropTypes.number,
    idActiviteSecondaire: PropTypes.array,
    idsActivitesAutres: PropTypes.array,
    exploitationFamilleCultures: PropTypes.object,
  }).isRequired,
  familleCultures: PropTypes.array,
  activites: PropTypes.array,
  afterSubmit: PropTypes.func,
  expInfosMillesimePrecedent: PropTypes.object,
};
export const modifExploitationFormDefault = {
  activites: [],
  familleCultures: [],
  form: {
    siret: "",
    raisonSociale: "",
    pacage: "",
    nomExploitant: "",
    prenomExploitant: "",
    adresse: "",
    adresse2: "",
    commune: {
      idcommune: "",
      nom: "",
      codepostal: "",
    },
    adresseMail: "",
    numeroPortable: "",
    venteDirecte: false,
    irrigation: false,
    engagementValorisation: "",
    tracabilite: "",
    commentaire: "",
    idActivitePrincipale: [],
    idActiviteAnimalePrincipale: "",
    idActiviteVegetalePrincipale: "",
    idActiviteSecondaire: [],
    idActiviteAnimaleSecondaire: "",
    idActiviteVegetaleSecondaire: "",
    idsActivitesAutres: [],
    exploitationFamilleCultures: {},
  },
  expInfosMillesimePrecedent: {},
};

ModifExploitationForm.defaultProps = modifExploitationFormDefault;

const mapPropsToValues = (props) => {
  return replaceNullByEmptyString(props.form, ["pacage", "numeroPortable", "nomExploitant", "prenomExploitant"]);
};

const handleSubmit = async (values, { props, setSubmitting }) => {
  try {
    const idOrganisme = "";
    setSubmitting(true);
    const exploitationFamilleCultures = Object.entries(values.exploitationFamilleCultures)
      .filter(([key, value]) => value.checked)
      .map(([key, value]) => ({
        idFamilleCulture: props.familleCultures.find((fc) => fc.codeNational === key).idFamilleCulture,
        idExploitation: props.idExploitation,
        idMillesime: props.idMillesime,
        estCvi: key !== "VITIC" ? null : value.code === "CVI",
      }));
    const {
      venteDirecte,
      irrigation,
      tracabilite,
      commentaire,
      engagementValorisation,
      commune,
      ...exploitationMain
    } = values;
    const URL = props.idExploitation
      ? `/api/exploitations/${props.idExploitation}?millesime=${props.idMillesime}`
      : `/api/exploitations?millesime=${props.idMillesime}${idOrganisme}`;
    const result = await network.fetch(URL, {
      method: props.idExploitation ? "PUT" : "POST",
      body: JSON.stringify(
        replaceEmptyStringByNull(
          {
            ...exploitationMain,
            idActiviteSecondaire: [values.idActiviteAnimaleSecondaire, values.idActiviteVegetaleSecondaire],
            idActivitePrincipale: [values.idActiviteAnimalePrincipale, values.idActiviteVegetalePrincipale],
            idCommune: commune.idcommune,
            nomCommune: commune.nom,
            exploitationFamilleCultures,
            exploitationInfo: {
              idMillesime: props.idMillesime,
              idExploitation: props.idExploitation,
              irrigation: values.irrigation,
              venteDirecte: values.venteDirecte,
              engagementValorisation: values.engagementValorisation,
              tracabilite: values.tracabilite,
              commentaire: values.commentaire,
              otex: values.otex,
            },
          },
          ["pacage", "numeroPortable"]
        )
      ),
    });
    if (props.exploitationSelected?.idExploitation === exploitationMain.idExploitation) {
      props.updateExploitation({
        ...result,
        ...exploitationMain,
        nomCommune: commune.nom,
        codePostal: commune.codepostal,
        irrigation: values.irrigation,
      });
    }
    toastr.success(
      props.t("saved", "Enregistré"),
      props.t("modif-exploitation.success-save", "L'exploitation a été enregistrée.")
    );
    setSubmitting(false);
    if (props.afterSubmit) {
      props.afterSubmit();
    }
  } catch (error) {
    toastr.error("Erreur", error.message);
  }
};

const validate = (values, props) => {
  const { t } = props,
    errors = {};
  if (!values.raisonSociale.length) {
    errors.raisonSociale = t("modif-exploitation.error.required", "Champ obligatoire");
  }

  if (!values.siret.length) {
    errors.siret = t("modif-exploitation.error.required", "Champ obligatoire");
  } else if (values.siret.length !== 14) {
    errors.siret = t("modif-exploitation.error.wrong-length-siret", "Le champ doit faire 14 caractères");
  }

  if (![0, 9].includes(values.pacage.length)) {
    errors.pacage = t("modif-exploitation.error.wrong-length-pacage", "Le champ doit faire 9 caractères");
  }

  if (!values.adresse.length) {
    errors.adresse = t("modif-exploitation.error.required", "Champ obligatoire");
  }
  if (!values.commune?.idcommune.length) {
    errors.commune = t("modif-exploitation.error.required", "Champ obligatoire");
  }

  if (!values.adresseMail?.length) {
    errors.adresseMail = t("modif-exploitation.error.required", "Champ obligatoire");
  } else if (!isEmail(values.adresseMail)) {
    errors.adresseMail = t("modif-exploitation.error.invalid-email", "L'adresse email est invalide");
  }
  if (!values.idActiviteAnimalePrincipale && !values.idActiviteVegetalePrincipale) {
    errors.idActiviteAnimalePrincipale = "Saisissez au moins une activité principale";
    errors.idActiviteVegetalePrincipale = "Saisissez au moins une activité principale";
  }
  if (values.exploitationFamilleCultures.VITIC.checked && values.exploitationFamilleCultures.VITIC.code === "") {
    errors.exploitationFamilleCultures = {
      VITIC: { code: t("modif-exploitation.error.required", "Champ obligatoire") },
    };
  }
  return errors;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withTranslation()(
    withFormik({
      mapPropsToValues: mapPropsToValues,
      validate: validate,
      validateOnMount: true,
      handleSubmit: handleSubmit,
    })(ModifExploitationForm)
  )
);
