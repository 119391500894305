import React from "react";
import { Box, Button, Grid, Tooltip } from "@material-ui/core";
import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import withTheme from "@material-ui/styles/withTheme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
  idExploitation: state.exploitation.selected,
});
class MenuHve4 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabSelected: "HVE4_SURFACE",
    };
  }

  render() {
    const {
      typesRubriques,
      typeRubriqueSelected,
      rubriqueSelected,
      clickNext,
      setSousRubriqueSelected,
      theme,
      saveResult,
    } = this.props;

    const {
      dateAudit,
      etatDossier,
      commentaireAuditeur,
      commentaireExploitant,
    } = this.props.commentairesSyntheseResultats;

    const isSynthese =
      typesRubriques[typeRubriqueSelected].rubriques[rubriqueSelected]?.codeNational === "HVE4_SYNTHESE" ?? false;
    let tr = typesRubriques;
    if (!this.props.idExploitation.irrigation) {
      tr[0].rubriques = typesRubriques[0]?.rubriques?.filter((r) => r.codeNational !== "HVE4_IRRIGATION");
    }

    return (
      <Grid container>
        <Grid item xs={7}>
          <Box className={"menu-rubriques"}>
            {tr.map((typeRubrique, typeRubriqueIndex) =>
              typeRubrique.rubriques.map((rubrique, rubriqueIndex) => (
                <Button
                  key={"rubrique-" + rubriqueIndex}
                  style={{
                    borderRadius: rubriqueIndex === 0 ? "10px 0 0" : "0",
                    padding: "0.8em",
                    fontWeight: "bold",
                    backgroundColor:
                      typeRubriqueSelected === typeRubriqueIndex && rubriqueSelected === rubriqueIndex
                        ? theme.palette.primary.main
                        : theme.palette.type === "light"
                        ? "#eee"
                        : theme.palette.background.default,
                    color: theme.palette.text.primary,
                  }}
                  onClick={() => setSousRubriqueSelected(typeRubriqueIndex, rubriqueIndex)}
                >
                  {rubrique.libelle}
                </Button>
              ))
            )}
          </Box>
        </Grid>
        <Grid item xs={5}>
          <Box className={"ml-2"}>
            <Tooltip title={"Enregistrer"} placement={"bottom"}>
              <Button
                variant="contained"
                color="primary"
                onClick={
                  isSynthese
                    ? () => saveResult({ dateAudit, etatDossier, commentaireAuditeur, commentaireExploitant })
                    : clickNext
                }
              >
                <FontAwesomeIcon icon="save" className="mr-05" />
                Enregistrer
              </Button>
            </Tooltip>
            <Button variant="contained" className="ml-1" onClick={() => this.props.history.goBack()}>
              <FontAwesomeIcon icon="times" className="mr-05" />
              Annuler
            </Button>
            <Button className="mr-2 ml-2" component={Link} to={`/demarches/HVE4/infos`}>
              <FontAwesomeIcon icon="pencil-alt" className="mr-05" />
              Modifier la démarche
            </Button>
          </Box>
        </Grid>
      </Grid>
    );
  }
}
export default compose(withRouter, withTheme, connect(mapStateToProps))(MenuHve4);
