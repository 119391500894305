import network from "./external/network";

export const loadPartners = () => async (dispatch, getState) => {
  dispatch(setPartners(await network.fetch(`/api/utilisateurs/${getState().auth.idUtilisateur}/partners`)));
};

export const SET_PARTNERS = "SET_PARTNERS";

export const setPartners = (partners) => ({ type: SET_PARTNERS, payload: partners });

export const SET_SELECTED_PARTNER = "SET_SELECTED_PARTNER";

export const setSelectedPartner = (selectedPartner) => ({ type: SET_SELECTED_PARTNER, payload: selectedPartner });
