import { DECREMENT_FETCHING, INCREMENT_FETCHING, RESET_FETCHING } from "../actions/external/network";

const initialState = {
  fetching: 0,
};

const network = (state = initialState, action) => {
  switch (action.type) {
    case INCREMENT_FETCHING:
      return { ...state, fetching: state.fetching + 1 };
    case DECREMENT_FETCHING:
      return { ...state, fetching: state.fetching - 1 };
    case RESET_FETCHING:
      return { ...state, fetching: 0 };
    default:
      return state;
  }
};

export default network;
