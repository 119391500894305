export const COMPUTED_FIELDS_SYNTHESE = {
  // PARTIE BIODIV
  HVE4_SYN_D_1: {
    columns: [],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 9,
      },
    }),
  },
  HVE4_SYN_D_2: {
    columns: [],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 5,
      },
    }),
  },
  HVE4_SYN_D_3: {
    columns: [],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 5,
      },
    }),
  },
  HVE4_SYN_D_4: {
    columns: [],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 6,
      },
    }),
  },
  HVE4_SYN_D_5: {
    columns: [],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 3,
      },
    }),
  },
  HVE4_SYN_D_6: {
    columns: [],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 1,
      },
    }),
  },
  HVE4_SYN_D_7: {
    columns: [],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 6,
      },
    }),
  },
  HVE4_SYN_D_8: {
    columns: [],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 1,
      },
    }),
  },
  HVE4_SYN_D_100: {
    columns: [],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 36,
      },
    }),
  },
  // PARTIE PHYTO
  HVE4_SYN_D_9: {
    columns: [],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 2,
      },
    }),
  },
  HVE4_SYN_D_10: {
    columns: [],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 10,
      },
    }),
  },
  HVE4_SYN_D_11: {
    columns: ["HVE4_PHYTO_D_350", "HVE4_PHYTO_D_351", "HVE4_PHYTO_D_354"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: roundFourDecimales(
          (10 * getValueOrZero(state, "HVE4_PHYTO_D_350")) / 100 +
            (10 * getValueOrZero(state, "HVE4_PHYTO_D_351")) / 100 +
            (10 * getValueOrZero(state, "HVE4_PHYTO_D_354")) / 100
        ),
      },
    }),
  },
  HVE4_SYN_D_12: {
    columns: ["HVE4_FERTI_D_26"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: roundFourDecimales((5 * getValueOrZero(state, "HVE4_FERTI_D_26")) / 100),
        },
      };
    },
  },
  HVE4_SYN_D_13: {
    columns: [],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: 3,
        },
      };
    },
  },
  HVE4_SYN_D_14: {
    columns: ["HVE4_PHYTO_D_65", "HVE4_PHYTO_D_66"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: roundFourDecimales(
            (6 * getValueOrZero(state, "HVE4_PHYTO_D_350")) / 100 +
              (3 * getValueOrZero(state, "HVE4_PHYTO_D_351")) / 100
          ),
        },
      };
    },
  },
  HVE4_SYN_D_15: {
    columns: [],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: 5,
        },
      };
    },
  },
  HVE4_SYN_D_16: {
    columns: ["HVE4_PHYTO_D_800", "HVE4_PHYTO_D_820", "HVE4_PHYTO_D_830"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: roundFourDecimales(
            (2 * getValueOrZero(state, "HVE4_PHYTO_D_800")) / 100 +
              (6 * (getValueOrZero(state, "HVE4_PHYTO_D_820") + getValueOrZero(state, "HVE4_PHYTO_D_830"))) / 100
          ),
        },
      };
    },
  },
  HVE4_SYN_D_17: {
    columns: ["HVE4_PHYTO_D_800", "HVE4_PHYTO_D_810", "HVE4_PHYTO_D_920"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: roundFourDecimales(
            (3 *
              (getValueOrZero(state, "HVE4_PHYTO_D_800") +
                getValueOrZero(state, "HVE4_PHYTO_D_810") +
                getValueOrZero(state, "HVE4_PHYTO_D_920"))) /
              100
          ),
        },
      };
    },
  },
  HVE4_SYN_D_18: {
    columns: ["HVE4_CULT_D_42", "HVE4_CULT_D_69", "HVE4_CULT_D_200"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value:
            getValueOrZero(state, "HVE4_CULT_D_200") > 0
              ? Math.max(
                  roundFourDecimales(
                    (10 * (getValueOrZero(state, "HVE4_CULT_D_42") - getValueOrZero(state, "HVE4_CULT_D_69"))) /
                      getValueOrZero(state, "HVE4_CULT_D_200") +
                      (7 * getValueOrZero(state, "HVE4_CULT_D_69")) / getValueOrZero(state, "HVE4_CULT_D_200")
                  ),
                  0
                )
              : 0,
        },
      };
    },
  },
  HVE4_SYN_D_200: {
    columns: [
      "HVE4_SYN_D_9",
      "HVE4_SYN_D_10",
      "HVE4_SYN_D_11",
      "HVE4_SYN_D_12",
      "HVE4_SYN_D_13",
      "HVE4_SYN_D_14",
      "HVE4_SYN_D_15",
      "HVE4_SYN_D_16",
      "HVE4_SYN_D_17",
      "HVE4_SYN_D_18",
    ],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: roundFourDecimales(
            COMPUTED_FIELDS_SYNTHESE.HVE4_SYN_D_200.columns.reduce(
              (sum, codeNational) => sum + getValueOrZero(state, codeNational),
              0
            )
          ),
        },
      };
    },
  },
  // PARTIE FERTI
  HVE4_SYN_D_19: {
    columns: ["HVE4_FERTI_D_1050"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: roundFourDecimales((8 * getValueOrZero(state, "HVE4_FERTI_D_1050")) / 100),
      },
    }),
  },
  HVE4_SYN_D_20: {
    columns: ["HVE4_FERTI_D_26"],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: roundFourDecimales((5 * getValueOrZero(state, "HVE4_FERTI_D_26")) / 100),
      },
    }),
  },
  HVE4_SYN_D_21: {
    columns: [],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 4,
      },
    }),
  },
  HVE4_SYN_D_22: {
    columns: ["HVE4_FERTI_D_1050"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: roundFourDecimales(
            (7 * getValueOrZero(state, "HVE4_FERTI_D_1050")) / 100 +
              (3 * getValueOrZero(state, "HVE4_FERTI_D_1050")) / 100
          ),
        },
      };
    },
  },
  HVE4_SYN_D_23: {
    columns: [],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: 10,
        },
      };
    },
  },
  HVE4_SYN_D_24: {
    columns: [],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: 4,
        },
      };
    },
  },
  HVE4_SYN_D_25: {
    columns: [
      "HVE4_FERTI_D_740_ZV",
      "HVE4_FERTI_D_740_hors_ZV",
      "HVE4_FERTI_D_761",
      "HVE4_FERTI_D_771",
      "HVE4_FERTI_D_781",
    ],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: roundFourDecimales(
            (4 * (getValueOrZero(state, "HVE4_FERTI_D_740_ZV") + getValueOrZero(state, "HVE4_FERTI_D_740_hors_ZV"))) /
              100 +
              (3 *
                (getValueOrZero(state, "HVE4_FERTI_D_761") +
                  getValueOrZero(state, "HVE4_FERTI_D_771") +
                  getValueOrZero(state, "HVE4_FERTI_D_781"))) /
                100
          ),
        },
      };
    },
  },
  HVE4_SYN_D_26: {
    columns: ["HVE4_FERTI_D_81"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: roundFourDecimales((6 * getValueOrZero(state, "HVE4_FERTI_D_81")) / 100),
        },
      };
    },
  },
  HVE4_SYN_D_27: {
    columns: ["HVE4_FERTI_D_92"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: roundFourDecimales((7 * getValueOrZero(state, "HVE4_FERTI_D_92")) / 100),
        },
      };
    },
  },
  HVE4_SYN_D_300: {
    columns: [
      "HVE4_SYN_D_19",
      "HVE4_SYN_D_20",
      "HVE4_SYN_D_21",
      "HVE4_SYN_D_22",
      "HVE4_SYN_D_23",
      "HVE4_SYN_D_24",
      "HVE4_SYN_D_25",
      "HVE4_SYN_D_26",
      "HVE4_SYN_D_27",
    ],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: roundFourDecimales(
            COMPUTED_FIELDS_SYNTHESE.HVE4_SYN_D_300.columns.reduce(
              (sum, codeNational) => sum + getValueOrZero(state, codeNational),
              0
            )
          ),
        },
      };
    },
  },
  // PARTIE IRRI
  HVE4_SYN_D_28: {
    columns: [],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 6,
      },
    }),
  },
  HVE4_SYN_D_29: {
    columns: [],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 2,
      },
    }),
  },
  HVE4_SYN_D_30: {
    columns: [],
    disabled: true,
    computedValue: (state) => ({
      valeur: {
        value: 6,
      },
    }),
  },
  HVE4_SYN_D_31: {
    columns: ["HVE4_FERTI_D_1050"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: 2,
        },
      };
    },
  },
  HVE4_SYN_D_32: {
    columns: [],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: 6,
        },
      };
    },
  },
  HVE4_SYN_D_33: {
    columns: [],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: 5,
        },
      };
    },
  },
  HVE4_SYN_D_34: {
    columns: ["HVE4_IRRI_D_73"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: roundFourDecimales((7 * getValueOrZero(state, "HVE4_IRRI_D_73")) / 100),
        },
      };
    },
  },
  HVE4_SYN_D_35: {
    columns: ["HVE4_IRRI_D_80"],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: roundFourDecimales(getValueOrZero(state, "HVE4_IRRI_D_80") / 100),
        },
      };
    },
  },
  HVE4_SYN_D_400: {
    columns: [
      "HVE4_SYN_D_28",
      "HVE4_SYN_D_29",
      "HVE4_SYN_D_30",
      "HVE4_SYN_D_31",
      "HVE4_SYN_D_32",
      "HVE4_SYN_D_33",
      "HVE4_SYN_D_34",
      "HVE4_SYN_D_35",
    ],
    disabled: true,
    computedValue: (state) => {
      return {
        valeur: {
          value: roundFourDecimales(
            COMPUTED_FIELDS_SYNTHESE.HVE4_SYN_D_400.columns.reduce(
              (sum, codeNational) => sum + getValueOrZero(state, codeNational),
              0
            )
          ),
        },
      };
    },
  },
  // FIN RUBRIQUE RECAPITULATIF
};

const getValueOrZero = (state, codeNational) => {
  return state.indicateurs[codeNational] && !isNaN(Number(state.indicateurs[codeNational].valeur.value))
    ? Number(state.indicateurs[codeNational].valeur.value)
    : 0;
};

const roundFourDecimales = (num) => {
  return Math.round((num + Number.EPSILON) * 10000) / 10000;
};

const exports = {
  COMPUTED_FIELDS_SYNTHESE: COMPUTED_FIELDS_SYNTHESE,
};
export default exports;
