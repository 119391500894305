import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import AccountForm from "./AccountForm";
import network from "../../../actions/external/network";
import { toastr } from "react-redux-toastr";
import { setUserInfos } from "../../../actions/auth";
import { Box, Typography, Card } from "@material-ui/core";
import Partners from "./Partners";
import { compose } from "redux";

const mapStateToProps = (state, ownProps) => ({
  auth: state.auth,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setUserInfos: (userInfos) => dispatch(setUserInfos(userInfos)),
});

class Account extends React.Component {
  handleValidateForm = async (formData) => {
    const { t } = this.props;
    try {
      const response = await network.fetch(`/api/utilisateurs/${this.props.auth.idUtilisateur}`, {
        method: "PUT",
        body: JSON.stringify(formData),
      });
      this.props.setUserInfos(response.userInfo);
      toastr.success("OK", response.message);
      return response.userInfo;
    } catch (error) {
      toastr.error(t("error", "Erreur"), error.message);
    }
  };

  render() {
    const { t } = this.props;

    return (
      <div>
        <Typography variant="h1" className="pb-2">
          {t("account.title", "Mon compte")}
        </Typography>
        <Box className="d-flex flex-column align-items-center">
          <Card className="w-30 p-2">
            <Typography variant="h3">{t("account.modify", "Modifier vos informations")}</Typography>
            <AccountForm onSubmit={this.handleValidateForm} />
          </Card>
          <Partners />
        </Box>
      </div>
    );
  }
}

export default compose(withTranslation(), connect(mapStateToProps, mapDispatchToProps), withRouter)(Account);
