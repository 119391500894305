import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class DefaultSousRubrique extends React.Component {
  render() {
    return <div></div>;
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(DefaultSousRubrique);
