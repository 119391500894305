import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";

class TexteMultiligne extends React.Component {
  render() {
    const {
      t,
      indicateur,
      sousRubriqueIndex,
      libelleEnglobantIndex,
      changeIndicateur,
      computedFields,
      inputProps,
      displayRules = null,
      valueAssolement = null,
    } = this.props;

    // Plutôt utiliser un indicateur en local qui prend la nouvelle valuer, procède au rafraichissement après le debounce et l'envoi avec la fonction plus tard
    // debounceTest = debounce((event) => {
    //   const { indicateur, sousRubriqueIndex, libelleEnglobantIndex, changeIndicateur } = this.props;
    //   console.log(event.target.value);
    //   changeIndicateur(
    //     { ...indicateur, valeur: { value: event.target.value } },
    //     sousRubriqueIndex,
    //     libelleEnglobantIndex
    //   );
    // }, 1)

    const inputStyle =
      inputProps != null
        ? inputProps
        : {
            multiline: true,
            fullWidth: false,
            style: {
              minWidth: "20rem",
              maxWidth: "25rem",
            },
            rows: 2,
            rowsMax: 4,
            fontWeight: indicateur.afficherGras ? "bold" : "normal",
          };

    const valeurCalculee = (
      <TextField
        size={this.props.size}
        {...inputStyle}
        disabled
        value={indicateur.valeurCalculee?.value}
        className={"mr-2"}
        label={t("hve3a.valeur-mesparcelles", "Valeur MesParcelles")}
      />
    );

    const valeurAssolement = (
      <TextField
        {...inputStyle}
        disabled
        size={this.props.size}
        fullWidth={false}
        style={{ maxWidth: "8rem" }}
        label={"Valeur assolement"}
        value={valueAssolement ?? ""}
        className={"mr-2"}
      />
    );

    const valueRendered = (
      <TextField
        size={this.props.size}
        {...inputStyle}
        value={indicateur.valeur.value ?? ""}
        disabled={computedFields.includes(indicateur.codeNational)}
        onChange={(event) =>
          changeIndicateur(
            { ...indicateur, valeur: { value: event.target.value } },
            sousRubriqueIndex,
            libelleEnglobantIndex
          )
        }
      />
    );

    return (
      <React.Fragment>
        {!!indicateur.valeurCalculee && valeurCalculee}
        {!!displayRules?.displayAssolement && valeurAssolement}
        {valueRendered}
      </React.Fragment>
    );
  }
}

export default compose(withTranslation())(TexteMultiligne);
