import React from "react";
import Indicator from "../../Indicator";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Chip, MenuItem, TextField, Tooltip } from "@material-ui/core";
import styles from "../style.module.scss";

class OADMT extends React.Component {
  render() {
    const { indicateur, onChangeIndicator, sousRubriqueIndex, libelleEnglobantIndex, changeIndicateur } = this.props;

    const OADMT = [
      {
        code: "RELIQUAT",
        libelle: "Reliquat azoté sortie hiver (analyses laboratoires)",
      },
      {
        code: "APM",
        libelle: "Azote Potentiellement Minéralisable (APM, analyses laboratoires)",
      },
      {
        code: "DIGITES",
        libelle: "DIGITES (mesure de la concentration en chlorophylle des organes végétaux)",
      },
      {
        code: "FARMSTAR",
        libelle: "FARMSTAR (traitement d’images satellite)",
      },
      {
        code: "GPNPILOT",
        libelle: "GPN Pilot (mesure de la réflectance du couvert végétal)",
      },
      {
        code: "JUBIL",
        libelle: "Jubil (mesure de la concentration en nitrates des jus de tiges)",
      },
      {
        code: "N-SENSOR",
        libelle: "N-SENSOR (mesure de la réflectance du couvert végétal)",
      },
      {
        code: "N-TESTER",
        libelle: "N-tester (mesure de la concentration en chlorophylle des organes végétaux)",
      },
      {
        code: "RAMSES",
        libelle: "Ramsès ou Ramsès II (mesure de la concentration en nitrates des jus de tiges)",
      },
      {
        code: "PLANITERRE",
        libelle: "PLANITERRE (analyses des effluents d’élevage et du sol)",
      },
      {
        code: "ANALYSES_FLUO",
        libelle: "Analyses fluorimètriques et analyses foliaires",
      },
      {
        code: "PROCESS2WINE",
        libelle: "Process2Wine",
      },
      {
        code: "STATION_FERTI",
        libelle: "Station de fertilisation utilisée en cultures hors sol",
      },
      {
        code: "EC_METRE",
        libelle: "Ec-mètre et ph-mètre utilisés en cultures hors sol",
      },
    ];

    const valueRendered = (
      <TextField
        fullWidth={false}
        select
        SelectProps={{
          multiple: true,
          renderValue: (selected) => (
            <div className="d-flex flex-wrap">
              {selected.map((value) => {
                const libelle = OADMT.find((materiel) => materiel.code === value)?.libelle;
                return (
                  <Tooltip key={value} title={libelle}>
                    <Chip key={value} label={libelle} className={styles.wrapperChip} />
                  </Tooltip>
                );
              })}
            </div>
          ),
        }}
        style={{ width: "25em" }}
        value={indicateur.valeur.value || []}
        onChange={(event) =>
          changeIndicateur(
            {
              ...indicateur,
              valeur: { value: event.target.value.filter((i) => i !== undefined) },
            },
            sousRubriqueIndex,
            libelleEnglobantIndex
          )
        }
      >
        {OADMT.map((oad) => (
          <MenuItem
            key={oad.code}
            value={oad.code}
            style={{
              maxWidth: "80em",
            }}
          >
            <div
              style={{
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "pre",
              }}
            >
              {oad.libelle}
            </div>
          </MenuItem>
        ))}
      </TextField>
    );

    return (
      <Indicator indicateur={indicateur} onChange={onChangeIndicator}>
        {valueRendered}
      </Indicator>
    );
  }
}

export default compose(withTranslation())(OADMT);
