import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import NumeriqueEntier from "../indicateurs/NumeriqueEntier";
import { Grid, InputAdornment } from "@material-ui/core";
import ListeConditionApplication from "../indicateurs/ListeConditionApplication";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class PhytoAppliTrait extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const indicToWatch = ["HVE4_PHYTO_I_70", "HVE4_PHYTO_S_70", "HVE4_PHYTO_D_70", "HVE4_PHYTO_D_71"];
    return indicToWatch.some((indic) => this.props.indicateurs[indic] !== nextProps.indicateurs[indic]);
  }

  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };

  render() {
    const { indicateurs, computedFields } = this.props;

    return (
      <React.Fragment>
        <div className={"d-flex align-items-center mt-2"}>
          <Grid container item xs={4}>
            {indicateurs["HVE4_PHYTO_I_70"].libelle}
          </Grid>
          <Grid container item xs={6}>
            <NumeriqueEntier
              size={"small"}
              indicateur={indicateurs["HVE4_PHYTO_I_70"]}
              key={"indicateur-" + indicateurs["HVE4_PHYTO_I_70"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_PHYTO_I_70"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_PHYTO_I_70"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mt-2"}>
          <Grid container item xs={4}>
            {indicateurs["HVE4_PHYTO_S_70"].libelle}
          </Grid>
          <Grid container item xs={6} className={"total-point"}>
            <NumeriqueEntier
              size={"small"}
              indicateur={indicateurs["HVE4_PHYTO_S_70"]}
              key={"indicateur-" + indicateurs["HVE4_PHYTO_S_70"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              valueInputProps={
                indicateurs["HVE4_PHYTO_S_70"].libelleUnite != null && {
                  endAdornment: (
                    <InputAdornment position={"end"}>{indicateurs["HVE4_PHYTO_S_70"].libelleUnite}</InputAdornment>
                  ),
                }
              }
              computedFields={computedFields}
            />
          </Grid>
        </div>
        <div className={"d-flex align-items-center mt-2"}>
          <h2>Description des équipements</h2>
        </div>
        <div className={"d-flex align-items-center mt-2"}>
          <span className={"mr-2"}> {indicateurs["HVE4_PHYTO_D_70"].libelle}</span>
          <ListeConditionApplication
            indicateur={indicateurs["HVE4_PHYTO_D_70"]}
            key={"indicateur-" + indicateurs["HVE4_PHYTO_D_70"].idIndicateur}
            changeIndicateur={this.handleChangeIndicateur}
            valueInputProps={
              indicateurs["HVE4_PHYTO_D_70"].libelleUnite != null && {
                endAdornment: (
                  <InputAdornment position={"end"}>{indicateurs["HVE4_PHYTO_D_70"].libelleUnite}</InputAdornment>
                ),
              }
            }
            name={"HVE4_PHYTO_D_70"}
            computedFields={computedFields}
          />
        </div>
        <div className={"d-flex align-items-center mt-2"}>
          <span className={"mr-2"}> {indicateurs["HVE4_PHYTO_D_71"].libelle}</span>
          <ListeConditionApplication
            indicateur={indicateurs["HVE4_PHYTO_D_71"]}
            key={"indicateur-" + indicateurs["HVE4_PHYTO_D_71"].idIndicateur}
            changeIndicateur={this.handleChangeIndicateur}
            valueInputProps={
              indicateurs["HVE4_PHYTO_D_71"].libelleUnite != null && {
                endAdornment: (
                  <InputAdornment position={"end"}>{indicateurs["HVE4_PHYTO_D_71"].libelleUnite}</InputAdornment>
                ),
              }
            }
            computedFields={computedFields}
            name={"HVE4_PHYTO_D_71"}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(PhytoAppliTrait);
