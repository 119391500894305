import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Grid, InputAdornment, Table, TableBody, TableCell, TableRow } from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
import clsx from "clsx";
import styles from "./sousrubriquestyles.module.scss";
import ScoreRubrique from "../indicateurs/ScoreRubrique";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class IrrigSynthese extends React.Component {
  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    if (newIndicateur.codeNational === "HVE4_IRRI_S_9") {
      changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
    }
  };

  render() {
    const { indicateurs, computedFields } = this.props;
    const indicateursKeys = [
      "HVE4_IRRI_S_1",
      "HVE4_IRRI_S_201",
      "HVE4_IRRI_S_3",
      "HVE4_IRRI_S_4",
      "HVE4_IRRI_S_5",
      "HVE4_IRRI_S_6",
      "HVE4_IRRI_S_74",
      "HVE4_IRRI_S_81",
    ];
    const indicateursToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateursKeys.includes(key)))
    );
    if (indicateursToDisplay.length > 0)
      return (
        <Grid container>
          <Grid item xs={7}>
            <Table
              style={{
                tableLayout: "fixed",
              }}
              className={"mt-3 mb-3"}
            >
              <TableBody>
                {indicateursKeys.map(
                  (indic, i) =>
                    indicateurs[indic] && (
                      <TableRow key={"row-" + i}>
                        <TableCell className={clsx(styles.tab_cell_border_top, styles.bold_content)}>
                          {indicateurs[indic].libelle}
                        </TableCell>
                        <TableCell className={clsx(styles.tab_cell_border_top)} align="center">
                          <DecimalPositif
                            size={"small"}
                            indicateur={indicateurs[indic]}
                            key={"indicateur-" + indicateurs[indic].idIndicateur}
                            changeIndicateur={this.handleChangeIndicateur}
                            valueInputProps={
                              indicateurs[indic].libelleUnite != null && {
                                endAdornment: (
                                  <InputAdornment position={"end"}>{indicateurs[indic].libelleUnite}</InputAdornment>
                                ),
                              }
                            }
                            computedFields={computedFields}
                          />
                        </TableCell>
                      </TableRow>
                    )
                )}
              </TableBody>
            </Table>
            <ScoreRubrique
              size={"small"}
              indicateur={indicateurs["HVE4_IRRI_S_9"]}
              key={"indicateur-" + indicateurs["HVE4_IRRI_S_9"].idIndicateur}
              changeIndicateur={this.handleChangeIndicateur}
              computedFields={computedFields}
            />
          </Grid>
        </Grid>
      );
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(IrrigSynthese);
