import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Admin from "../Admin/Admin";
import Home from "../Admin/Home";
import Utilisateurs from "../Admin/Utilisateur/Utilisateurs";
import Exploitations from "../Admin/Exploitations";
import { UtilisateurPage as Utilisateur } from "../Admin/Utilisateur/Utilisateur";
import Organismes from "../Admin/Organisme/Organismes";
import { AccesPage as Acces } from "../Admin/Acces/Acces";

class AdminRouter extends React.Component {
  render() {
    const { match } = this.props;

    return (
      <Switch>
        <Route exact path={`${match.path}/utilisateurs`}>
          <Admin>
            <Utilisateurs />
          </Admin>
        </Route>

        <Route
          exact
          path={`${match.path}/utilisateurs/:id`}
          children={({ match }) => (
            <Admin>
              <Utilisateur idUtilisateur={match.params.id === "create" ? null : match.params.id} />
            </Admin>
          )}
        />

        <Route exact path={`${match.path}/exploitations`}>
          <Admin>
            <Exploitations />
          </Admin>
        </Route>

        <Route exact path={`${match.path}/acces`}>
          <Admin>
            <Acces />
          </Admin>
        </Route>

        <Route exact path={`${match.path}/organismes`}>
          <Admin>
            <Organismes />
          </Admin>
        </Route>

        <Route exact path={`${match.path}/`}>
          <Admin>
            <Home />
          </Admin>
        </Route>

        <Route>404 admin</Route>
      </Switch>
    );
  }
}

export default withRouter(AdminRouter);
