import { SET_APP_INFO } from "../actions/app";

const appInitialState = {
  version: "",
  title: "",
  showBtnMPConnection: true,
  urlOAD: "",
  environnement: "test",
};

const app = (state = appInitialState, action) => {
  switch (action.type) {
    case SET_APP_INFO:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export default app;
