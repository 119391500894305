import React from "react";
import { withStyles } from "@material-ui/styles";
import { Toolbar } from "@material-ui/core";

import logo1 from "../../../resources/img/banner/1.jpg";
import logo2 from "../../../resources/img/banner/2.jpg";
import logo3 from "../../../resources/img/banner/3.jpg";
import logo4 from "../../../resources/img/banner/4.jpg";
import logo5 from "../../../resources/img/banner/5.jpg";
import logo6 from "../../../resources/img/banner/6.jpg";
import logo7 from "../../../resources/img/banner/7.jpg";
import logo8 from "../../../resources/img/banner/8.jpg";
import logo9 from "../../../resources/img/banner/9.jpg";
import logo10 from "../../../resources/img/banner/10.jpg";

const logos = [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo8, logo9, logo10];

class MenuToolbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      i: 0,
      interval: undefined,
    };
  }

  componentDidMount() {
    this.setState({
      interval: setInterval(() => {
        this.setState((previousState) => ({ ...previousState, i: previousState.i + 1 }));
      }, 60000),
    });
  }

  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  render() {
    const { children, classes, className } = this.props,
      { i } = this.state;
    return (
      <Toolbar
        classes={classes}
        className={className}
        style={{
          background: `url(${logos[i % logos.length]})`,
        }}
      >
        {children}
      </Toolbar>
    );
  }
}

export default withStyles((theme) => ({
  root: {
    background: `linear-gradient(45deg, ${theme.palette.secondary.main} 30%, ${theme.palette.third.main} 90%)`,
    fontWeight: "bold",
    height: "5rem",
    transition: "background 1.5s linear",
    "&::before": {
      content: "''",
      position: "absolute",
      left: "0",
      right: "0",
      top: "0",
      bottom: "0",
      background: `linear-gradient(45deg, ${theme.palette.secondary.main} 30%, ${theme.palette.third.main} 90%)`,
      opacity: 0.7,
    },
  },
}))(MenuToolbar);
