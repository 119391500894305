import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Grid, InputAdornment } from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});
class IrrigOutilsMesure extends React.Component {
  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };
  render() {
    const { indicateurs, getValueAssolement, computedFields } = this.props;

    const indicateurHorsHortiKeys = ["HVE4_IRRI_I_20", "HVE4_IRRI_I_21", "HVE4_IRRI_I_22"];

    const indicateursPointHorsHortiKeys = ["HVE4_IRRI_S_200", "HVE4_IRRI_D_203", "HVE4_IRRI_S_203"];

    const indicateurPointHortiKeys = ["HVE4_IRRI_S_23", "HVE4_IRRI_D_202", "HVE4_IRRI_S_202"];

    const indicateursHorsHortiToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateurHorsHortiKeys.includes(key)))
    );

    const indicateursPointHorsHortiToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateursPointHorsHortiKeys.includes(key)))
    );

    const indicateurPointHortiToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateurPointHortiKeys.includes(key)))
    );

    if (indicateursHorsHortiToDisplay.length > 0)
      return (
        <React.Fragment>
          <h2>Cultures HORS horticultures - pépinières</h2>
          <Grid container spacing={2} alignItems={"stretch"}>
            <Grid item xs={6}>
              {indicateursHorsHortiToDisplay.map((indic, i) => (
                <Grid container item xs={6} lg={12} spacing={2} key={"grid" + i}>
                  <Grid item xs={8} key={"libelle" + i}>
                    <div className={indic.afficherGras ? "mr-3 bold" : "mr-3"}>{indic.libelle}</div>
                  </Grid>
                  <Grid item xs={4} key={"input" + i}>
                    <DecimalPositif
                      size={"small"}
                      getValueAssolement={getValueAssolement}
                      indicateur={indic}
                      key={"indicateur-" + indic.idIndicateur}
                      changeIndicateur={this.handleChangeIndicateur}
                      valueInputProps={
                        indic.libelleUnite != null && {
                          endAdornment: <InputAdornment position={"end"}>{indic.libelleUnite}</InputAdornment>,
                        }
                      }
                      computedFields={computedFields}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
            {indicateursPointHorsHortiToDisplay.map((indic, i) => (
              <Grid item xs={2} key={"grid" + i}>
                <Grid container item xs={6} lg={12} spacing={2}>
                  <Grid item xs={12} key={"libelle" + i}>
                    <div className={indic.afficherGras ? "mr-3 bold" : "mr-3"}>{indic.libelle}</div>
                  </Grid>
                  <Grid item xs={12} key={"input" + i}>
                    <DecimalPositif
                      size={"small"}
                      getValueAssolement={getValueAssolement}
                      indicateur={indic}
                      key={"indicateur-" + indic.idIndicateur}
                      changeIndicateur={this.handleChangeIndicateur}
                      valueInputProps={
                        indic.libelleUnite != null && {
                          endAdornment: <InputAdornment position={"end"}>{indic.libelleUnite}</InputAdornment>,
                        }
                      }
                      computedFields={computedFields}
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>

          <h2>Horticultures - pépinières</h2>
          <Grid container item lg={12} spacing={2}>
            <Grid container item xs={6}>
              <Grid item xs={8}>
                <div className={indicateurs["HVE4_IRRI_D_201"].afficherGras ? "mr-3 bold" : "mr-3"}>
                  {indicateurs["HVE4_IRRI_D_201"].libelle}
                </div>
              </Grid>
              <Grid item xs={4}>
                <DecimalPositif
                  error={
                    indicateurs["HVE4_IRRI_D_201"] != null && indicateurs["HVE4_CULT_D_70"] != null
                      ? indicateurs["HVE4_IRRI_D_201"].valeur.value > indicateurs["HVE4_CULT_D_70"].valeur.value
                      : false
                  }
                  helperText={
                    indicateurs["HVE4_IRRI_D_201"] != null && indicateurs["HVE4_CULT_D_70"] != null
                      ? indicateurs["HVE4_IRRI_D_201"].valeur.value > indicateurs["HVE4_CULT_D_70"].valeur.value
                        ? "La valeur saisie ne doit pas dépasser la surface en horticulture et pépinière (hors sol et plein champ)"
                        : ""
                      : ""
                  }
                  size={"small"}
                  getValueAssolement={getValueAssolement}
                  indicateur={indicateurs["HVE4_IRRI_D_201"]}
                  key={"indicateur-" + indicateurs["HVE4_IRRI_D_201"].idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indicateurs["HVE4_IRRI_D_201"].libelleUnite != null && {
                      endAdornment: (
                        <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_D_201"].libelleUnite}</InputAdornment>
                      ),
                    }
                  }
                  computedFields={computedFields}
                />
              </Grid>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid container item xs={6}>
              <Grid item xs={8}>
                <div className={indicateurs["HVE4_IRRI_I_23"].afficherGras ? "mr-3 bold" : "mr-3"}>
                  {indicateurs["HVE4_IRRI_I_23"].libelle}
                </div>
              </Grid>
              <Grid item xs={4}>
                <DecimalPositif
                  size={"small"}
                  getValueAssolement={getValueAssolement}
                  indicateur={indicateurs["HVE4_IRRI_I_23"]}
                  key={"indicateur-" + indicateurs["HVE4_IRRI_I_23"].idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indicateurs["HVE4_IRRI_I_23"].libelleUnite != null && {
                      endAdornment: (
                        <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_I_23"].libelleUnite}</InputAdornment>
                      ),
                    }
                  }
                  computedFields={computedFields}
                />
              </Grid>
            </Grid>
            <Grid container item xs={6}>
              {indicateurPointHortiToDisplay.map((indic, i) => (
                <Grid item xs={4} key={"indic" + i}>
                  <DecimalPositif
                    size={"small"}
                    getValueAssolement={getValueAssolement}
                    indicateur={indic}
                    key={"indicateur-" + indic.idIndicateur}
                    changeIndicateur={this.handleChangeIndicateur}
                    valueInputProps={
                      indic.libelleUnite != null && {
                        endAdornment: <InputAdornment position={"end"}>{indic.libelleUnite}</InputAdornment>,
                      }
                    }
                    computedFields={computedFields}
                  />
                </Grid>
              ))}
            </Grid>
            <Grid item xs={8}></Grid>
            <Grid container item xs={4}>
              <Grid item xs={6}>
                <div className={indicateurs["HVE4_IRRI_S_201"].afficherGras ? "mr-3 bold" : "mr-3"}>
                  {indicateurs["HVE4_IRRI_S_201"].libelle}
                </div>
              </Grid>
              <Grid item xs={6} className={"total-point"}>
                <DecimalPositif
                  size={"small"}
                  getValueAssolement={getValueAssolement}
                  indicateur={indicateurs["HVE4_IRRI_S_201"]}
                  key={"indicateur-" + indicateurs["HVE4_IRRI_S_201"].idIndicateur}
                  changeIndicateur={this.handleChangeIndicateur}
                  valueInputProps={
                    indicateurs["HVE4_IRRI_S_201"].libelleUnite != null && {
                      endAdornment: (
                        <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_S_201"].libelleUnite}</InputAdornment>
                      ),
                    }
                  }
                  computedFields={computedFields}
                />
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      );
  }
}
export default compose(withTranslation(), connect(mapStateToProps))(IrrigOutilsMesure);
