import React from "react";
import { Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

class PrintButton extends React.Component {
  constructor(props) {
    super(props);

    this.anchorRef = React.createRef();
    this.formats = [];

    if (props.pdf) {
      this.formats.push({
        code: "pdf",
        libelle: "PDF",
      });
    }

    if (props.xls) {
      this.formats.push({
        code: "xls",
        libelle: "XLS",
      });
    }

    if (props.xlsx) {
      this.formats.push({
        code: "xlsx",
        libelle: "XLSX",
      });
    }

    this.state = {
      open: false,
    };
  }

  handleToggle = () => {
    this.setState((prevState) => ({
      open: !prevState.open,
    }));
  };

  handleClose = (event) => {
    if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
      return;
    }
    this.setState({ open: false });
  };

  handleMenuItemClick = (event, format) => {
    this.props.onPrint(format);
    this.setState({ open: false });
  };

  render() {
    return (
      <React.Fragment>
        <ButtonGroup color="primary" variant="contained" ref={this.anchorRef}>
          <Button onClick={this.handleToggle}>{this.props.text || ""}</Button>
          <Button size="small" onClick={this.handleToggle}>
            <FontAwesomeIcon icon="sort-down" />
          </Button>
        </ButtonGroup>
        <Popper open={this.state.open} anchorEl={this.anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList>
                    {this.formats.map((format) => (
                      <MenuItem key={format.code} onClick={(event) => this.handleMenuItemClick(event, format.code)}>
                        {format.libelle}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(PrintButton));
