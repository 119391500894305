import React from "react";
import { withTranslation } from "react-i18next";
import network from "../../../actions/external/network";
import TransferList from "./TransferList";
import { Typography, Button, Box } from "@material-ui/core";
import UserSearch from "../../common/UserSearch";
import { withRouter } from "react-router-dom";
import { toastr } from "react-redux-toastr";

class ParUtilisateur extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      utilisateur: null,
      listeExploitations: [],
      utilisateurExploitations: [],
      loading: false,
    };
  }

  componentDidMount() {
    if (this.props.idUtilisateur != null) {
      this.loadUtilisateur(this.props.idUtilisateur);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.utilisateur?.idUtilisateur !== this.state.utilisateur?.idUtilisateur) {
      this.loadUtilisateurExploitations();
    }
  }

  loadUtilisateur = async (idUtilisateur) => {
    const { t } = this.props;
    try {
      const utilisateur = await network.fetch(`/api/utilisateurs/${idUtilisateur}`);
      this.onChangeUtilisateur({ value: utilisateur });
    } catch (error) {
      toastr.error(
        t("error", "Erreur"),
        t("admin.acces.load-user-error", "Erreur lors du chargement de l'utilisateur")
      );
    }
  };

  onChangeUtilisateur = ({ value }) => {
    this.setState({ utilisateur: value });
  };

  onChangeSearchExploitation = async (search) => {
    if (search.length < 3) {
      this.setState({ listeExploitations: [] });
    } else {
      try {
        this.setState({ loading: true });
        const urlSearchParams = new URLSearchParams([
          ["search", search],
          ["searchFields", "raisonSociale"],
          ["searchFields", "siret"],
          ["searchFields", "pacage"],
          ["searchFields", "commune"],
          ["orderBy", "raisonSociale"],
        ]);
        const { data } = await network.fetch(`/api/exploitations?${urlSearchParams}`);
        this.setState({ listeExploitations: data, loading: false });
      } catch (error) {
        this.setState({ loading: false });
      }
    }
  };

  loadUtilisateurExploitations = async () => {
    if (!this.state.utilisateur) {
      this.setState({ utilisateurExploitations: [] });
    } else {
      try {
        const exploitations = await network.fetch(
          `/api/utilisateurs/${this.state.utilisateur.idUtilisateur}/exploitations`
        );
        this.setState({ utilisateurExploitations: exploitations });
      } catch (error) {}
    }
  };

  onAddExploitation = (idsExploitations) => {
    const exploitations = this.state.listeExploitations.filter((exploitation) =>
      idsExploitations.includes(exploitation.idExploitation)
    );
    this.setState((prevState) => ({
      utilisateurExploitations: [...prevState.utilisateurExploitations, ...exploitations],
    }));
  };

  onRemoveExploitation = (idsExploitations) => {
    const newSelection = this.state.utilisateurExploitations.filter(
      (exploitation) => !idsExploitations.includes(exploitation.idExploitation)
    );
    this.setState({ utilisateurExploitations: newSelection });
  };

  getLeftList = () => {
    return this.state.listeExploitations.map((exploitation) => ({
      key: exploitation.idExploitation,
      value: exploitation.raisonSociale,
      disabled:
        this.state.utilisateurExploitations.find((e) => e.idExploitation === exploitation.idExploitation) != null,
    }));
  };

  getRightList = () => {
    return this.state.utilisateurExploitations.map((exploitation) => ({
      key: exploitation.idExploitation,
      value: exploitation.raisonSociale,
    }));
  };

  onSubmit = async () => {
    const { t } = this.props;
    const idsExploitations = this.state.utilisateurExploitations.map((exploitation) => exploitation.idExploitation);

    try {
      await network.fetch(`/api/utilisateurs/${this.state.utilisateur.idUtilisateur}/exploitations`, {
        method: "PUT",
        body: JSON.stringify(idsExploitations),
      });

      toastr.success("OK", t("admin.access.save-success", "Accès enregistrés"));

      this.props.afterSubmit && this.props.afterSubmit();
    } catch (error) {
      toastr.error(t("error", "Erreur"), t("admin.access.save-error", "Erreur lors de l'enregistrement des accès"));
    }
  };

  render() {
    const { t, idUtilisateur } = this.props,
      { loading, utilisateur } = this.state;

    return (
      <React.Fragment>
        {idUtilisateur == null && (
          <Box className="d-flex">
            <UserSearch
              className="mb-1"
              style={{ width: 400 }}
              value={utilisateur}
              onChange={this.onChangeUtilisateur}
            />
          </Box>
        )}

        {utilisateur && (
          <React.Fragment>
            {idUtilisateur != null && (
              <Box className="d-flex mb-2">
                <Typography variant="h4">{`${utilisateur.prenom} ${utilisateur.nom}`}</Typography>
              </Box>
            )}
            <TransferList
              left={this.getLeftList()}
              right={this.getRightList()}
              onClickLeft={this.onAddExploitation}
              onClickRight={this.onRemoveExploitation}
              leftTitle={t("admin.access.search-farms", "Rechercher des exploitations")}
              onChangeLeftSearch={this.onChangeSearchExploitation}
              loading={loading}
            />
            <Box className="d-flex mt-2">
              <Button color="primary" variant="contained" onClick={this.onSubmit}>
                {t("validate", "Valider")}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(ParUtilisateur));
