import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import {
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
import NumeriqueEntier from "../indicateurs/NumeriqueEntier";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NumberInput from "../../../../common/NumberInput";
import Percent from "../indicateurs/Percent";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class PhytoSubsActives extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    const indicToWatch = [
      "HVE4_PHYTO_D_40",
      "HVE4_PHYTO_D_41",
      "HVE4_PHYTO_D_41_PLAFOND",
      "HVE4_PHYTO_D_41_PLANCHER",
      "HVE4_PHYTO_D_42",
      "HVE4_PHYTO_D_43",
      "HVE4_PHYTO_D_44",
      "HVE4_PHYTO_S_40",
      "HVE4_PHYTO_D_45",
      "HVE4_PHYTO_S_41",
    ];
    return indicToWatch.some((indic) => this.props.indicateurs[indic] !== nextProps.indicateurs[indic]);
  }

  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };

  renderInputIft = (codeNationalIndicateur, key) => {
    return (
      <React.Fragment>
        {codeNationalIndicateur?.valeurCalculee?.valeur?.value[key] != null && (
          <NumberInput
            size={"small"}
            disabled
            className="mb-1"
            label={"Valeur N MesParcelles"}
            value={codeNationalIndicateur?.valeurCalculee?.valeur?.value[key]}
          />
        )}
        <NumberInput
          size={"small"}
          label={key === "n" ? "Valeur N" : key === "n_1" ? "Valeur N-1" : key === "n_2" && "Valeur N-2"}
          value={codeNationalIndicateur?.valeur?.value[key] ?? ""}
          onChange={(value) =>
            this.handleChangeIndicateur({
              ...codeNationalIndicateur,
              valeur: {
                ...codeNationalIndicateur?.valeur,
                value: {
                  ...codeNationalIndicateur?.valeur.value,
                  [key]: value === "" || value == null ? null : Number(value),
                },
              },
            })
          }
        />
      </React.Fragment>
    );
  };
  render() {
    const { indicateurs, computedFields } = this.props;
    const indicateursKeys = [
      "HVE4_PHYTO_D_40",
      "HVE4_PHYTO_D_41",
      "HVE4_PHYTO_D_41_PLAFOND",
      "HVE4_PHYTO_D_41_PLANCHER",
      "HVE4_PHYTO_D_42",
      "HVE4_PHYTO_D_43",
      "HVE4_PHYTO_D_44",
      "HVE4_PHYTO_S_40",
      "HVE4_PHYTO_D_45",
      "HVE4_PHYTO_S_41",
    ];
    const indicateursToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateursKeys.includes(key)))
    );
    if (indicateursToDisplay.length > 0) {
      return (
        <React.Fragment>
          <div>
            <div className={"d-flex align-items-center"}>
              <div className={indicateurs["HVE4_PHYTO_D_40"].afficherGras ? "mr-3 bold" : "mr-3"}>
                {indicateurs["HVE4_PHYTO_D_40"].libelle}
              </div>
              <Tooltip
                title={<span style={{ whiteSpace: "pre-line" }}>{indicateurs["HVE4_PHYTO_D_40"].messageAide}</span>}
              >
                <IconButton>
                  <FontAwesomeIcon icon="info-circle" />
                </IconButton>
              </Tooltip>
              <NumeriqueEntier
                size={"small"}
                indicateur={indicateurs["HVE4_PHYTO_D_40"]}
                key={"indicateur-" + indicateurs["HVE4_PHYTO_D_40"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_PHYTO_D_40"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_PHYTO_D_40"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </div>
            <div className={"d-flex align-items-center mt-2"}>
              <Grid container>
                <Grid item>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow className={"d-flex justify-center"}>
                          <TableCell />
                          <TableCell style={{ textAlign: "center" }}>Quantité appliquée</TableCell>
                          <TableCell style={{ textAlign: "center" }}>Plafond</TableCell>
                          <TableCell style={{ textAlign: "center" }}>Plancher</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow key={1}>
                          <TableCell
                            style={{
                              minWidth: "10rem",
                              maxWidth: "15rem",
                            }}
                          >
                            <div>
                              Substances actives appliquées pendant une campagne sur les surfaces en horticulture et
                              pépinière (campagne n)
                            </div>
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {this.renderInputIft(indicateurs["HVE4_PHYTO_D_41"], "n")}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {this.renderInputIft(indicateurs["HVE4_PHYTO_D_41_PLAFOND"], "n")}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {this.renderInputIft(indicateurs["HVE4_PHYTO_D_41_PLANCHER"], "n")}
                          </TableCell>
                        </TableRow>
                        <TableRow key={2}>
                          <TableCell style={{ maxWidth: "10rem" }}>
                            <div>
                              Substances actives appliquées pendant une campagne sur les surfaces en horticulture et
                              pépinière (campagne n-1)
                            </div>
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {this.renderInputIft(indicateurs["HVE4_PHYTO_D_41"], "n_1")}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {this.renderInputIft(indicateurs["HVE4_PHYTO_D_41_PLAFOND"], "n_1")}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {this.renderInputIft(indicateurs["HVE4_PHYTO_D_41_PLANCHER"], "n_1")}
                          </TableCell>
                        </TableRow>
                        <TableRow key={3}>
                          <TableCell style={{ maxWidth: "10rem" }}>
                            <div>
                              Substances actives appliquées pendant une campagne sur les surfaces en horticulture et
                              pépinière (campagne n-2)
                            </div>
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {this.renderInputIft(indicateurs["HVE4_PHYTO_D_41"], "n_2")}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {this.renderInputIft(indicateurs["HVE4_PHYTO_D_41_PLAFOND"], "n_2")}
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            {this.renderInputIft(indicateurs["HVE4_PHYTO_D_41_PLANCHER"], "n_2")}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </div>
          </div>
          <div className={"d-flex align-items-center mt-2"}>
            <Grid container>
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow className={"d-flex justify-center total-point"}>
                        <TableCell style={{ textAlign: "center" }}>
                          Quantité totale de substances actives appliquées
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>Plafond retenu</TableCell>
                        <TableCell style={{ textAlign: "center" }}>Plancher retenu</TableCell>
                        <TableCell style={{ textAlign: "center" }}>Points</TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          Part de surface horticulture - pépinière sur la SAU
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>
                          Nombre de points "Quantité de substances actives appliquées"
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow key={1}>
                        <TableCell
                          style={{
                            textAlign: "center",
                            width: "16.66%",
                          }}
                        >
                          <DecimalPositif
                            size={"small"}
                            indicateur={indicateurs["HVE4_PHYTO_D_42"]}
                            key={"indicateur-" + indicateurs["HVE4_PHYTO_D_42"].idIndicateur}
                            changeIndicateur={this.handleChangeIndicateur}
                            valueInputProps={
                              indicateurs["HVE4_PHYTO_D_42"].libelleUnite != null && {
                                endAdornment: (
                                  <InputAdornment position={"end"}>
                                    {indicateurs["HVE4_PHYTO_D_42"].libelleUnite}
                                  </InputAdornment>
                                ),
                              }
                            }
                            computedFields={computedFields}
                          />
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "center",
                            width: "16.66%",
                          }}
                        >
                          <DecimalPositif
                            size={"small"}
                            indicateur={indicateurs["HVE4_PHYTO_D_43"]}
                            key={"indicateur-" + indicateurs["HVE4_PHYTO_D_43"].idIndicateur}
                            changeIndicateur={this.handleChangeIndicateur}
                            valueInputProps={
                              indicateurs["HVE4_PHYTO_D_43"].libelleUnite != null && {
                                endAdornment: (
                                  <InputAdornment position={"end"}>
                                    {indicateurs["HVE4_PHYTO_D_43"].libelleUnite}
                                  </InputAdornment>
                                ),
                              }
                            }
                            computedFields={computedFields}
                          />
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "center",
                            width: "16.66%",
                          }}
                        >
                          <DecimalPositif
                            size={"small"}
                            indicateur={indicateurs["HVE4_PHYTO_D_44"]}
                            key={"indicateur-" + indicateurs["HVE4_PHYTO_D_44"].idIndicateur}
                            changeIndicateur={this.handleChangeIndicateur}
                            valueInputProps={
                              indicateurs["HVE4_PHYTO_D_44"].libelleUnite != null && {
                                endAdornment: (
                                  <InputAdornment position={"end"}>
                                    {indicateurs["HVE4_PHYTO_D_44"].libelleUnite}
                                  </InputAdornment>
                                ),
                              }
                            }
                            computedFields={computedFields}
                          />
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "center",
                            width: "16.66%",
                          }}
                        >
                          <DecimalPositif
                            size={"small"}
                            indicateur={indicateurs["HVE4_PHYTO_S_40"]}
                            key={"indicateur-" + indicateurs["HVE4_PHYTO_S_40"].idIndicateur}
                            changeIndicateur={this.handleChangeIndicateur}
                            valueInputProps={
                              indicateurs["HVE4_PHYTO_S_40"].libelleUnite != null && {
                                endAdornment: (
                                  <InputAdornment position={"end"}>
                                    {indicateurs["HVE4_PHYTO_S_40"].libelleUnite}
                                  </InputAdornment>
                                ),
                              }
                            }
                            computedFields={computedFields}
                          />
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "center",
                            width: "16.66%",
                          }}
                        >
                          <Percent
                            size={"small"}
                            indicateur={indicateurs["HVE4_PHYTO_D_45"]}
                            key={"indicateur-" + indicateurs["HVE4_PHYTO_D_45"].idIndicateur}
                            changeIndicateur={this.handleChangeIndicateur}
                            valueInputProps={
                              indicateurs["HVE4_PHYTO_D_45"].libelleUnite != null && {
                                endAdornment: (
                                  <InputAdornment position={"end"}>
                                    {indicateurs["HVE4_PHYTO_D_45"].libelleUnite}
                                  </InputAdornment>
                                ),
                              }
                            }
                            computedFields={computedFields}
                          />
                        </TableCell>
                        <TableCell
                          style={{
                            textAlign: "center",
                            width: "16.66%",
                          }}
                          className={"total-point"}
                        >
                          <DecimalPositif
                            size={"small"}
                            indicateur={indicateurs["HVE4_PHYTO_S_41"]}
                            key={"indicateur-" + indicateurs["HVE4_PHYTO_S_41"].idIndicateur}
                            changeIndicateur={this.handleChangeIndicateur}
                            valueInputProps={
                              indicateurs["HVE4_PHYTO_S_41"].libelleUnite != null && {
                                endAdornment: (
                                  <InputAdornment position={"end"}>
                                    {indicateurs["HVE4_PHYTO_S_41"].libelleUnite}
                                  </InputAdornment>
                                ),
                              }
                            }
                            computedFields={computedFields}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(PhytoSubsActives);
