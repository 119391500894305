import React from "react";
import { Route, withRouter, Redirect } from "react-router-dom";
import PropTypes from "prop-types";

class ConditionalRoute extends React.Component {
  render() {
    const { path, children, match, conditions, ...rest } = this.props;
    const renderConditions = conditions ?? [];
    return (
      <Route
        {...rest}
        render={({ location }) => {
          const redirectCondition = renderConditions.find((condition) => !condition.test());
          if (redirectCondition != null) {
            const { redirectTo } = redirectCondition;
            return (
              <Redirect
                to={{
                  pathname: redirectTo,
                  search: redirectTo === "/login" ? "?redirect=" + location.pathname + location.search : "",
                }}
              />
            );
          } else {
            return children;
          }
        }}
      />
    );
  }
}

ConditionalRoute.propTypes = {
  children: PropTypes.node.isRequired,
  conditions: PropTypes.arrayOf(
    PropTypes.shape({
      test: PropTypes.func.isRequired,
      redirectTo: PropTypes.string,
    })
  ),
};

export default withRouter(ConditionalRoute);
