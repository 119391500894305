import { toastr } from "react-redux-toastr";
import i18next from "i18next";

const confirm = (message) => {
  return new Promise((resolve) => {
    toastr.confirm(message, {
      onOk: () => resolve(true),
      onCancel: () => resolve(false),
      okText: i18next.t("confirm.ok", "Valider"),
      cancelText: i18next.t("confirm.cancel", "Annuler"),
    });
  });
};

export default confirm;
