import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import {
  Grid,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
import NumeriqueEntier from "../indicateurs/NumeriqueEntier";
import Percent from "../indicateurs/Percent";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class PhytoRecycleTraitEaux extends React.Component {
  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };

  render() {
    const { indicateurs, computedFields } = this.props;

    const indicateursKeys = [
      "HVE4_PHYTO_D_100",
      "HVE4_PHYTO_D_101",
      "HVE4_PHYTO_D_102",
      "HVE4_PHYTO_D_103",
      "HVE4_PHYTO_D_1000",
      "HVE4_PHYTO_D_1001",
      "HVE4_PHYTO_I_100",
      "HVE4_PHYTO_I_102",
      "HVE4_PHYTO_I_103",
      "HVE4_PHYTO_S_100",
      "HVE4_PHYTO_S_102",
      "HVE4_PHYTO_S_103",
      "HVE4_PHYTO_S_1001",
      "HVE4_PHYTO_S_1002",
      "HVE4_PHYTO_S_1003",
      "HVE4_PHYTO_S_1004",
    ];

    const indicateursToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateursKeys.includes(key)))
    );
    if (indicateursToDisplay.length > 0) {
      return (
        <Grid container>
          <Grid item xs={12} className={"d-flex align-items-center mt-2"}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow className={"d-flex justify-center"}>
                    <TableCell style={{ width: "13em" }} />
                    <TableCell style={{ textAlign: "center" }}>
                      Volume annuel d'eau recyclé et traité avant tout rejet dans le milieu
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>Volume annuel d'eau utilisé</TableCell>
                    <TableCell style={{ textAlign: "center" }}>Part des eaux d'irrigation recyclée</TableCell>
                    <TableCell style={{ textAlign: "center" }}>Points intermédiaires</TableCell>
                    <TableCell style={{ textAlign: "center" }}>Part des surfaces des cultures sur la SAU</TableCell>
                    <TableCell style={{ textAlign: "center" }}>Points</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow key={1}>
                    <TableCell>Cultures hors sol hors horticulture</TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <DecimalPositif
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_D_101_1"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_D_101_1"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_D_101_1"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_D_101_1"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <DecimalPositif
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_D_100"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_D_100"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_D_100"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_D_100"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <Percent
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_I_100"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_I_100"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_I_100"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_I_100"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell>
                      <NumeriqueEntier
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_S_100"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_S_100"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_S_100"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_S_100"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <Percent
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_D_1000"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_D_1000"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_D_1000"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_D_1000"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <DecimalPositif
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_S_1001"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_S_1001"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_S_1001"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_S_1001"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={2} className={"d-flex justify-center"} style={{ backgroundColor: "#eeeeee" }}>
                    <TableCell style={{ width: "13em" }} />
                    <TableCell style={{ textAlign: "center" }}>Surfaces équipées</TableCell>
                    <TableCell style={{ textAlign: "center" }} colSpan={2}>
                      Part des surfaces équipées
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>Points intermédiaires</TableCell>
                    <TableCell style={{ textAlign: "center" }}>Part des surfaces des cultures sur la SAU</TableCell>
                    <TableCell style={{ textAlign: "center" }}>Points</TableCell>
                  </TableRow>
                  <TableRow key={3}>
                    <TableCell>Horticulture hors sol - système de recyclage et de traitement total</TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <DecimalPositif
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_D_102"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_D_102"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_D_102"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_D_102"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }} colSpan={2}>
                      <Percent
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_I_102"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_I_102"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_I_102"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_I_102"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <NumeriqueEntier
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_S_102"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_S_102"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_S_102"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_S_102"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <Percent
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_D_1001"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_D_1001"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_D_1001"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_D_1001"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <DecimalPositif
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_S_1002"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_S_1002"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_S_1002"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_S_1002"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={4}>
                    <TableCell>Horticulture hors sol - système de recyclage et de traitement partiel</TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <DecimalPositif
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_D_103"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_D_103"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_D_103"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_D_103"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }} colSpan={2}>
                      <Percent
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_I_103"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_I_103"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_I_103"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_I_103"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <NumeriqueEntier
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_S_103"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_S_103"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_S_103"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_S_103"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <Percent
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_D_1001"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_D_1001"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_D_1001"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_D_1001"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      <DecimalPositif
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_S_1003"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_S_1003"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_S_1003"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_S_1003"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow key={5}>
                    <TableCell colSpan={6} style={{ textAlign: "right" }}>
                      Nombre de points "Recyclage et traitement des eaux d'irrigation"
                    </TableCell>
                    <TableCell className={"total-point"}>
                      <DecimalPositif
                        size={"small"}
                        indicateur={indicateurs["HVE4_PHYTO_S_1004"]}
                        key={"indicateur-" + indicateurs["HVE4_PHYTO_S_1004"].idIndicateur}
                        changeIndicateur={this.handleChangeIndicateur}
                        valueInputProps={
                          indicateurs["HVE4_PHYTO_S_1004"].libelleUnite != null && {
                            endAdornment: (
                              <InputAdornment position={"end"}>
                                {indicateurs["HVE4_PHYTO_S_1004"].libelleUnite}
                              </InputAdornment>
                            ),
                          }
                        }
                        computedFields={computedFields}
                      />
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      );
    }
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(PhytoRecycleTraitEaux);
