import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import { Grid, InputAdornment } from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
  idExploitation: state.exploitation.selected,
});

class IrrigInfos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      irrigation: this.props.idExploitation.irrigation,
    };
  }

  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };

  render() {
    const { indicateurs, getValueAssolement, sousRubriqueIndex, libelleEnglobantIndex, computedFields } = this.props;

    const indicateursKeys = ["HVE4_IRRI_D_101", "HVE4_IRRI_D_102"];

    const indicateursToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateursKeys.includes(key)))
    );

    if (indicateursToDisplay.length > 0) {
      return (
        <React.Fragment>
          <span className="d-flex align-items-center">
            <FontAwesomeIcon icon="exclamation-triangle" />
            <h3 className="ml-1">
              Si vous n'êtes pas irrigant, pensez à désactiver "irrigation" dans les données générales
            </h3>
          </span>

          <div className={"d-flex align-items-center mb-1"}>
            <Grid item xs={4}>
              <div className="mr-3">{indicateurs["HVE4_IRRI_D_101"].libelle}</div>
            </Grid>
            <Grid item xs={6}>
              <DecimalPositif
                error={
                  indicateurs["HVE4_CULT_D_200"] != null
                    ? indicateurs["HVE4_IRRI_D_101"].valeur.value > indicateurs["HVE4_CULT_D_200"].valeur.value
                    : false
                }
                helperText={
                  indicateurs["HVE4_CULT_D_200"] != null
                    ? indicateurs["HVE4_IRRI_D_101"].valeur.value > indicateurs["HVE4_CULT_D_200"].valeur.value
                      ? "La valeur saisie ne doit pas dépasser la SAU"
                      : ""
                    : ""
                }
                size={"small"}
                indicateur={indicateurs["HVE4_IRRI_D_101"]}
                key={"indicateur-" + indicateurs["HVE4_IRRI_D_101"].code}
                changeIndicateur={this.handleChangeIndicateur}
                valueInputProps={
                  indicateurs["HVE4_IRRI_D_101"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_D_101"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
          </div>
          <div className={"d-flex align-items-center mb-1"}>
            <Grid item xs={4}>
              <div className="mr-3">{indicateurs["HVE4_IRRI_D_102"].libelle}</div>
            </Grid>
            <Grid item>
              <DecimalPositif
                error={indicateurs["HVE4_IRRI_D_102"].valeur.value > indicateurs["HVE4_IRRI_D_101"].valeur.value}
                helperText={
                  indicateurs["HVE4_IRRI_D_102"].valeur.value > indicateurs["HVE4_IRRI_D_101"].valeur.value
                    ? "La valeur saisie ne doit pas dépasser la surface irrigable"
                    : ""
                }
                size={"small"}
                indicateur={indicateurs["HVE4_IRRI_D_102"]}
                key={"indicateur-" + indicateurs["HVE4_IRRI_D_102"].idIndicateur}
                changeIndicateur={this.handleChangeIndicateur}
                getValueAssolement={getValueAssolement}
                sousRubriqueIndex={sousRubriqueIndex}
                libelleEnglobantIndex={libelleEnglobantIndex}
                valueInputProps={
                  indicateurs["HVE4_IRRI_D_102"].libelleUnite != null && {
                    endAdornment: (
                      <InputAdornment position={"end"}>{indicateurs["HVE4_IRRI_D_102"].libelleUnite}</InputAdornment>
                    ),
                  }
                }
                computedFields={computedFields}
              />
            </Grid>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(IrrigInfos);
