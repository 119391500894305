import React from "react";
import { compose } from "redux";
import { withTranslation } from "react-i18next";
import {
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { connect } from "react-redux";
import DecimalPositif from "../indicateurs/DecimalPositif";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NumberInput from "../../../../common/NumberInput";

const mapStateToProps = (state) => ({
  userRole: state.auth.role,
});

class FertiApportAzote extends React.Component {
  handleChangeIndicateur = (newIndicateur) => {
    const { changeIndicateur, sousRubriqueIndex, libelleEnglobantIndex } = this.props;
    changeIndicateur(newIndicateur, sousRubriqueIndex, libelleEnglobantIndex);
  };

  renderInputIft = (codeNationalIndicateur, key) => {
    return (
      <React.Fragment>
        {codeNationalIndicateur?.valeurCalculee?.valeur?.value[key] != null && (
          <NumberInput
            size={"small"}
            disabled
            className="mb-1"
            label={"Valeur N MesParcelles"}
            value={codeNationalIndicateur?.valeurCalculee?.valeur?.value[key]}
          />
        )}
        <NumberInput
          size={"small"}
          label={key === "n" ? "Valeur N" : key === "n_1" ? "Valeur N-1" : key === "n_2" && "Valeur N-2"}
          value={codeNationalIndicateur?.valeur?.value[key] ?? ""}
          onChange={(value) =>
            this.handleChangeIndicateur({
              ...codeNationalIndicateur,
              valeur: {
                ...codeNationalIndicateur?.valeur,
                value: {
                  ...codeNationalIndicateur?.valeur.value,
                  [key]: value === "" || value == null ? null : Number(value),
                },
              },
            })
          }
        />
      </React.Fragment>
    );
  };

  componentDidMount() {
    const { indicateurs } = this.props;
    if (indicateurs["HVE4_FERTI_D_20"].valeur.value === null)
      this.handleChangeIndicateur({
        ...indicateurs["HVE4_FERTI_D_20"],
        valeur: { value: 0 },
      });
  }

  render() {
    const { indicateurs, computedFields } = this.props;

    const NB_CAMPAGNES = [
      {
        code: 0,
        libelle: "1 campagne",
      },
      {
        code: 1,
        libelle: "2 campagnes",
      },
      {
        code: 2,
        libelle: "3 campagnes",
      },
    ];

    const indicateursKeys = [
      "HVE4_FERTI_D_20",
      "HVE4_FERTI_D_21",
      "HVE4_FERTI_D_21_plafond",
      "HVE4_FERTI_D_21_plancher",
      "HVE4_FERTI_D_22",
      "HVE4_FERTI_D_23",
      "HVE4_FERTI_D_24",
      "HVE4_FERTI_D_26",
      "HVE4_FERTI_S_25",
      "HVE4_FERTI_S_26",
    ];

    const indicateursToDisplay = Object.values(
      Object.fromEntries(Object.entries(indicateurs).filter(([key]) => indicateursKeys.includes(key)))
    );

    if (indicateursToDisplay.length > 1)
      return (
        <React.Fragment>
          <Grid container>
            <Grid item xs={12} className={"d-flex align-items-center mb-1"}>
              <div className="mr-3">{indicateurs["HVE4_FERTI_D_20"].libelle}</div>
              <Tooltip
                title={<span style={{ whiteSpace: "pre-line" }}>{indicateurs["HVE4_FERTI_D_20"].messageAide}</span>}
              >
                <IconButton>
                  <FontAwesomeIcon icon="info-circle" />
                </IconButton>
              </Tooltip>
              <TextField
                size={"small"}
                fullWidth={false}
                select
                value={indicateurs["HVE4_FERTI_D_20"].valeur.value || 0}
                style={{
                  fontWeight: "normal",
                  width: "20rem",
                }}
                onChange={(event) =>
                  this.handleChangeIndicateur({
                    ...indicateurs["HVE4_FERTI_D_20"],
                    valeur: { value: event.target.value },
                  })
                }
              >
                {NB_CAMPAGNES.map((element) => (
                  <MenuItem key={element.code} value={element.code}>
                    {element.libelle}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid
              container
              item
              xs={
                indicateurs["HVE4_FERTI_D_20"].valeur.value === 0
                  ? 6
                  : indicateurs["HVE4_FERTI_D_20"].valeur.value === 1
                  ? 6
                  : 8
              }
            >
              <TableContainer component={Paper}>
                <Table style={{ tableLayout: "fixed" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      {indicateurs["HVE4_FERTI_D_20"].valeur.value === 0 ? (
                        <TableCell align={"center"}>
                          <div>N</div>
                        </TableCell>
                      ) : indicateurs["HVE4_FERTI_D_20"].valeur.value === 1 ? (
                        <React.Fragment>
                          <TableCell align={"center"}>
                            <div>N</div>
                          </TableCell>
                          <TableCell align={"center"}>
                            <div>N-1</div>
                          </TableCell>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <TableCell align={"center"}>
                            <div>N</div>
                          </TableCell>
                          <TableCell align={"center"}>
                            <div>N-1</div>
                          </TableCell>
                          <TableCell align={"center"}>
                            <div>N-2</div>
                          </TableCell>
                        </React.Fragment>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <div>
                          Quantité d'N apporté pendant une campagne sur les surfaces en horticulture et pépinière
                        </div>
                      </TableCell>
                      {indicateurs["HVE4_FERTI_D_20"].valeur.value === 0 ? (
                        <TableCell>{this.renderInputIft(indicateurs["HVE4_FERTI_D_21"], "n")}</TableCell>
                      ) : indicateurs["HVE4_FERTI_D_20"].valeur.value === 1 ? (
                        <React.Fragment>
                          <TableCell>{this.renderInputIft(indicateurs["HVE4_FERTI_D_21"], "n")}</TableCell>
                          <TableCell>{this.renderInputIft(indicateurs["HVE4_FERTI_D_21"], "n_1")}</TableCell>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <TableCell>{this.renderInputIft(indicateurs["HVE4_FERTI_D_21"], "n")}</TableCell>
                          <TableCell>{this.renderInputIft(indicateurs["HVE4_FERTI_D_21"], "n_1")}</TableCell>
                          <TableCell>{this.renderInputIft(indicateurs["HVE4_FERTI_D_21"], "n_2")}</TableCell>
                        </React.Fragment>
                      )}
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <div>{indicateurs["HVE4_FERTI_D_21_plafond"].libelle}</div>
                      </TableCell>
                      {indicateurs["HVE4_FERTI_D_20"].valeur.value === 0 ? (
                        <TableCell>{this.renderInputIft(indicateurs["HVE4_FERTI_D_21_plafond"], "n")}</TableCell>
                      ) : indicateurs["HVE4_FERTI_D_20"].valeur.value === 1 ? (
                        <React.Fragment>
                          <TableCell>{this.renderInputIft(indicateurs["HVE4_FERTI_D_21_plafond"], "n")}</TableCell>
                          <TableCell>{this.renderInputIft(indicateurs["HVE4_FERTI_D_21_plafond"], "n_1")}</TableCell>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <TableCell>{this.renderInputIft(indicateurs["HVE4_FERTI_D_21_plafond"], "n")}</TableCell>
                          <TableCell>{this.renderInputIft(indicateurs["HVE4_FERTI_D_21_plafond"], "n_1")}</TableCell>
                          <TableCell>{this.renderInputIft(indicateurs["HVE4_FERTI_D_21_plafond"], "n_2")}</TableCell>
                        </React.Fragment>
                      )}
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <div>{indicateurs["HVE4_FERTI_D_21_plancher"].libelle}</div>
                      </TableCell>
                      {indicateurs["HVE4_FERTI_D_20"].valeur.value === 0 ? (
                        <TableCell>{this.renderInputIft(indicateurs["HVE4_FERTI_D_21_plancher"], "n")}</TableCell>
                      ) : indicateurs["HVE4_FERTI_D_20"].valeur.value === 1 ? (
                        <React.Fragment>
                          <TableCell>{this.renderInputIft(indicateurs["HVE4_FERTI_D_21_plancher"], "n")}</TableCell>
                          <TableCell>{this.renderInputIft(indicateurs["HVE4_FERTI_D_21_plancher"], "n_1")}</TableCell>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <TableCell>{this.renderInputIft(indicateurs["HVE4_FERTI_D_21_plancher"], "n")}</TableCell>
                          <TableCell>{this.renderInputIft(indicateurs["HVE4_FERTI_D_21_plancher"], "n_1")}</TableCell>
                          <TableCell>{this.renderInputIft(indicateurs["HVE4_FERTI_D_21_plancher"], "n_2")}</TableCell>
                        </React.Fragment>
                      )}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>

            <Grid container className={"mt-2"}>
              <Grid item xs={4}>
                <TableContainer component={Paper}>
                  <Table style={{ tableLayout: "fixed" }}>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <div>
                            Quantité totale appliquée sur {indicateurs["HVE4_FERTI_D_20"].valeur.value + 1} campagne
                            {indicateurs["HVE4_FERTI_D_20"].valeur.value > 0 && "s"}
                          </div>
                        </TableCell>
                        <TableCell>
                          <DecimalPositif
                            size={"small"}
                            indicateur={indicateurs["HVE4_FERTI_D_22"]}
                            key={"indicateur-" + indicateurs["HVE4_FERTI_D_22"].idIndicateur}
                            changeIndicateur={this.handleChangeIndicateur}
                            valueInputProps={
                              indicateurs["HVE4_FERTI_D_22"].libelleUnite != null && {
                                endAdornment: (
                                  <InputAdornment position={"end"}>
                                    {indicateurs["HVE4_FERTI_D_22"].libelleUnite}
                                  </InputAdornment>
                                ),
                              }
                            }
                            computedFields={computedFields}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <div>{indicateurs["HVE4_FERTI_D_23"].libelle}</div>
                        </TableCell>
                        <TableCell>
                          <DecimalPositif
                            size={"small"}
                            indicateur={indicateurs["HVE4_FERTI_D_23"]}
                            key={"indicateur-" + indicateurs["HVE4_FERTI_D_23"].idIndicateur}
                            changeIndicateur={this.handleChangeIndicateur}
                            valueInputProps={
                              indicateurs["HVE4_FERTI_D_23"].libelleUnite != null && {
                                endAdornment: (
                                  <InputAdornment position={"end"}>
                                    {indicateurs["HVE4_FERTI_D_23"].libelleUnite}
                                  </InputAdornment>
                                ),
                              }
                            }
                            computedFields={computedFields}
                          />
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <div>{indicateurs["HVE4_FERTI_D_24"].libelle}</div>
                        </TableCell>
                        <TableCell>
                          <DecimalPositif
                            size={"small"}
                            indicateur={indicateurs["HVE4_FERTI_D_24"]}
                            key={"indicateur-" + indicateurs["HVE4_FERTI_D_24"].idIndicateur}
                            changeIndicateur={this.handleChangeIndicateur}
                            valueInputProps={
                              indicateurs["HVE4_FERTI_D_24"].libelleUnite != null && {
                                endAdornment: (
                                  <InputAdornment position={"end"}>
                                    {indicateurs["HVE4_FERTI_D_24"].libelleUnite}
                                  </InputAdornment>
                                ),
                              }
                            }
                            computedFields={computedFields}
                          />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>

            <Grid item xs={7} className={"mt-2"}>
              <TableContainer component={Paper}>
                <Table style={{ tableLayout: "fixed" }}>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <div>Points</div>
                      </TableCell>
                      <TableCell>
                        <div>Surface horticulture - pépinière</div>
                      </TableCell>
                      <TableCell>
                        <div>Part des surfaces horticulture et pépinière sur la SAU</div>
                      </TableCell>
                      <TableCell>
                        <div className={"bold"}>Total points "quantité d'azote apportée"</div>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_S_25"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_S_25"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_S_25"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_S_25"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_CULT_D_70"]}
                          key={"indicateur-" + indicateurs["HVE4_CULT_D_70"]?.idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_CULT_D_70"]?.libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_CULT_D_70"]?.libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_D_26"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_D_26"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_D_26"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_D_26"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                      <TableCell className={"total-point"}>
                        <DecimalPositif
                          size={"small"}
                          indicateur={indicateurs["HVE4_FERTI_S_26"]}
                          key={"indicateur-" + indicateurs["HVE4_FERTI_S_26"].idIndicateur}
                          changeIndicateur={this.handleChangeIndicateur}
                          valueInputProps={
                            indicateurs["HVE4_FERTI_S_26"].libelleUnite != null && {
                              endAdornment: (
                                <InputAdornment position={"end"}>
                                  {indicateurs["HVE4_FERTI_S_26"].libelleUnite}
                                </InputAdornment>
                              ),
                            }
                          }
                          computedFields={computedFields}
                        />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </React.Fragment>
      );
  }
}

export default compose(withTranslation(), connect(mapStateToProps))(FertiApportAzote);
