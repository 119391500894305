import { toastr } from "react-redux-toastr";
import i18next from "i18next";
import { store } from "../../index";
import { logout } from "../auth";

export const INCREMENT_FETCHING = "INCREMENT_FETCHING";
export const DECREMENT_FETCHING = "DECREMENT_FETCHING";
export const RESET_FETCHING = "RESET_FETCHING";
const incrementFetching = () => ({ type: INCREMENT_FETCHING });
const decrementFetching = () => ({ type: DECREMENT_FETCHING });
export const resetFetching = () => ({ type: RESET_FETCHING });

class Network {
  fetch = async (url, params) => {
    if (process.env.NODE_ENV) {
      console.log("fetching data", url, params);
    }
    store.dispatch(incrementFetching());

    const { auth } = store.getState();

    let { method, headers, body, isAuth } = Object.assign(
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Accept-Language": i18next.language,
        },
        isAuth: false,
      },
      params
    );

    if (!isAuth && auth.isAuthenticated && auth.token) {
      headers["Authorization"] = `Bearer ${auth.token}`;
    }

    try {
      const response = await fetch(url, {
        method,
        headers,
        body,
      });
      if (!response.ok) {
        if (response.status === 401) {
          if (!isAuth) {
            store.dispatch(logout());
            toastr.error(
              i18next.t("error", "Erreur"),
              i18next.t("login.expired-session", "Votre session a expiré ; merci de vous reconnecter")
            );
          }
        }

        let errorMessage = response.statusText;

        try {
          const errorJson = await response.json();
          if (errorJson && errorJson.message) {
            errorMessage = errorJson.message;
          }
        } catch (error) {}

        const error = new Error(errorMessage);
        error.status = response.status;
        throw error;
      }

      if (isAuth) {
        return response;
      }

      const contentType = response.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        return await response.json();
      }

      return response;
    } catch (error) {
      throw error;
    } finally {
      store.dispatch(decrementFetching());
    }
  };
}

export default new Network();
