import React from "react";
import NumberInput from "../../../../../common/NumberInput";
import Indicator from "../../Indicator";
import { compose } from "redux";
import { withTranslation } from "react-i18next";

class OverrideAssolementValue extends React.Component {
  render() {
    const {
      indicateur,
      onChangeIndicator,
      sousRubriqueIndex,
      libelleEnglobantIndex,
      changeIndicateur,
      valueInputProps,
    } = this.props;

    const valueRendered = (
      <React.Fragment>
        <NumberInput
          label={"Valeur assolement"}
          fullWidth={false}
          value={indicateur.valeur.value ?? ""}
          style={{ maxWidth: "8rem" }}
          InputLabelProps={{
            style: { fontWeight: indicateur.afficherGras ? "bold" : "normal" },
          }}
          InputProps={valueInputProps}
          numberOfDecimals={2}
          disabled
        />
        <NumberInput
          fullWidth={false}
          value={indicateur.valeur.newValue ?? ""}
          style={{ maxWidth: "8rem" }}
          className="ml-2"
          InputProps={valueInputProps}
          numberOfDecimals={2}
          label={"Valeur déclarée"}
          onChange={(newValeur) =>
            changeIndicateur(
              {
                ...indicateur,
                valeur: { ...indicateur.valeur, newValue: newValeur === "" ? null : Number(newValeur) },
              },
              sousRubriqueIndex,
              libelleEnglobantIndex
            )
          }
        />
      </React.Fragment>
    );

    return (
      <Indicator indicateur={indicateur} onChange={onChangeIndicator}>
        {valueRendered}
      </Indicator>
    );
  }
}

export default compose(withTranslation())(OverrideAssolementValue);
