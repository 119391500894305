import { SET_AUTHENTICATION, SET_USER_INFOS, LOGOUT } from "../actions/auth";

const authInitialState = {
  isAuthenticated: false,
  token: null,
  role: null,
  idUtilisateur: null,
  login: null,
  email: null,
  nextEmail: null,
  prenom: null,
  nom: null,
  idTypeUtilisateur: null,
  idOrganismePrincipal: null,
  idOrganismeSecondaire: null,
  outrepasserAccesExploitation: false,
  accessCreationUpdateExploitation: false,
  accessCreationUtilisateur: false,
  accessAttributionDroit: false,
  idsEntites: [],
};

const auth = (state = authInitialState, action) => {
  switch (action.type) {
    case SET_AUTHENTICATION:
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token,
        idUtilisateur: action.payload.idUtilisateur,
      };
    case SET_USER_INFOS:
      return {
        ...state,
        role: action.payload.role,
        login: action.payload.login,
        email: action.payload.email,
        nextEmail: action.payload.nextEmail,
        nom: action.payload.nom,
        prenom: action.payload.prenom,
        idTypeUtilisateur: action.payload.idTypeUtilisateur,
        idOrganismePrincipal: action.payload.idOrganismePrincipal,
        idOrganismeSecondaire: action.payload.idOrganismeSecondaire,
        outrepasserAccesExploitation: action.payload.outrepasserAccesExploitation,
        accessCreationUpdateExploitation: action.payload.accessCreationUpdateExploitation,
        accessCreationUtilisateur: action.payload.accessCreationUtilisateur,
        accessAttributionDroit: action.payload.accessAttributionDroit,
        idsEntites: action.payload.idsEntites,
      };
    case LOGOUT:
      return authInitialState;
    default:
      return state;
  }
};

export default auth;
